// @ts-nocheck
/* eslint-disable */

import React, {JSX, useState} from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import CreateJournalForm from '@modules/Profile/components/journals/CreateJournalForm';
import {FilterJournals} from '@modules/Profile/components/journals/FilterJournals';
import {JournalContent} from '@modules/Profile/components/journals/JournalContent';
import {JournalEmptyContent} from '@modules/Profile/components/journals/JournalEmptyContent';
import {JournalTabs} from '@modules/Profile/components/journals/JournalTabs';
import {JOURNAL_EXERCISES_TYPE} from '@modules/Profile/config/journalsConstants';
import {_Journal, _JournalFilterParams, _JournalGroup} from '@modules/Profile/types/journal.model';
import {trans} from '@modules/Translations/util/i18n';

interface _ListJournalsProps {
  journals: Record<string, Record<string, _Journal[]>> | null;
  activeTab: string;
  journalGroups: _JournalGroup[] | null;
  filterCategoryId: string | null;
  filterTeamId: string | null;
  setActiveTab: (tab: string) => void;
  refresh: () => void;
  getJournalsList: (filterParams: _JournalFilterParams) => Promise<void>;
  exerciseView?: boolean;
}

export const ListJournals: React.FC<_ListJournalsProps> = ({
  journals,
  activeTab,
  journalGroups,
  filterCategoryId,
  filterTeamId,
  setActiveTab,
  refresh,
  getJournalsList,
  exerciseView = false,
}) => {
  const [isJournalFormOpen, setIsJournalFormOpen] = useState<boolean>(false);
  const [selectedJournal, setSelectedJournal] = useState<_Journal | null>(null);

  const handleCloseForm = (confirmed: boolean): void => {
    if (confirmed) {
      refresh?.();
    }
    setIsJournalFormOpen(false);
  };

  function listJournalsComponent(group: string): JSX.Element {
    if (!Object.keys(journals?.[group] ?? {})?.length) {
      return (
        <JournalEmptyContent
          activeTab={activeTab}
          filterCategoryId={filterCategoryId}
          filterTeamId={filterTeamId}
          onUpdateJournals={category => {
            if (category) {
              setActiveTab(category);
              refresh();
            }
          }}
        />
      );
    }
    return (
      <div className="flex flex-col gap-3">
        {Object.keys(journals?.[group] ?? {}).map(category => (
          <Section key={category} title={trans(`profile.journal.categories.${group}.${category}`)}>
            <JournalContent
              onEditJournal={journal => {
                setSelectedJournal(journal);
                setIsJournalFormOpen(true);
              }}
              onUpdate={refresh}
              data={
                journals?.[group]?.[category]?.map(journal => ({
                  ...journal,
                  title:
                    journal?.metadata?.type === JOURNAL_EXERCISES_TYPE &&
                    !exerciseView &&
                    journal.metadata.exerciseTitle ? (
                      <>
                        {!exerciseView &&
                          `${trans('profile_exercises.round', {
                            roundIndex: parseInt(journal.metadata.roundIndex, 10) + 1,
                          })}: `}
                        {trans(journal.metadata.exerciseTitle as string)}
                      </>
                    ) : undefined,
                })) ?? []
              }
            />
          </Section>
        ))}
      </div>
    );
  }

  return (
    <Section gap={5}>
      <JournalTabs
        tabs={
          journalGroups?.map(group => ({
            key: group.key,
            label: trans(group.title),
          })) ?? []
        }
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <FilterJournals
        getJournalsList={getJournalsList}
        selectedCategory={filterCategoryId}
        selectedCategoryGroup={activeTab}
      />
      {listJournalsComponent(activeTab)}
      {isJournalFormOpen && (
        <CreateJournalForm
          exerciseView={exerciseView}
          isOpen={isJournalFormOpen}
          onClose={handleCloseForm}
          selectedJournal={selectedJournal}
        />
      )}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {usePaymentResult} from '@modules/ContentEngine/components/items/payment/paymentResult';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Section} from '@modules/Core/components/layout/Section';
import {PaymentBilling} from '@modules/Core/components/platform/payments/billing/PaymentBilling';
import {
  _PaymentDetailsDialogInput,
  PaymentDetailsDialog,
} from '@modules/Core/components/platform/payments/dialogs/PaymentDetailsDialog';
import {PaymentInstitution} from '@modules/Core/components/platform/payments/institution/PaymentInstitution';
import {PaymentTabs} from '@modules/Core/components/platform/payments/PaymentTabs';
import {PaymentProfile} from '@modules/Core/components/platform/payments/profile/PaymentProfile';
import {PaymentWorkshopsVouchers} from '@modules/Core/components/platform/payments/workshopsVouchers/PaymentWorkshopsVouchers';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';

export type _PaymentMode = 'profile' | 'institution' | 'workshops' | 'billing';

interface _PaymentItemProps {
  initialTab?: _PaymentMode;
  checkoutId?: string;
}

export const PaymentItem: React.FC<_PaymentItemProps> = ({initialTab = 'profile', checkoutId = null}) => {
  const [currentProfile] = useProfile();
  const {currentInstitution, profiles} = useInstitution();
  const [mode, setMode] = useState<_PaymentMode>(initialTab);
  const [dialogInput, setDialogInput] = useState<_PaymentDetailsDialogInput | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const resultDialogInput = usePaymentResult(currentInstitution, checkoutId);

  useEffect(() => {
    setResultDialogOpen(resultDialogInput?.open ?? false);
  }, [resultDialogInput]);
  const onPrepareDialog = (input: _PaymentDetailsDialogInput): void => {
    setDialogInput(input);
    setDialogOpen(true);
  };

  return (
    <Section>
      {resultDialogInput?.open && (
        <Dialog
          {...resultDialogInput}
          confirmProps={{width: 'relative-col-width-3'}}
          open={resultDialogOpen}
          onClose={() => setResultDialogOpen(false)}
          image="/assets/img/design_images/payment-successful.png"
          imageProps={{style: {height: 349}}}
        />
      )}
      {dialogInput && (
        <PaymentDetailsDialog
          profile={currentProfile}
          institution={currentInstitution}
          profiles={profiles}
          open={dialogOpen}
          setOpen={setDialogOpen}
          {...dialogInput}
        />
      )}
      <PaymentTabs
        mode={mode}
        setMode={setMode}
        initialTab={initialTab}
        currentInstitution={currentInstitution}
        currentProfile={currentProfile}
      />
      <Section>
        {mode === 'profile' && (
          <PaymentProfile profile={currentProfile} institution={currentInstitution} onReady={onPrepareDialog} />
        )}

        {mode === 'institution' && (
          <PaymentInstitution profile={currentProfile} institution={currentInstitution!} onReady={onPrepareDialog} />
        )}

        {mode === 'workshops' && <PaymentWorkshopsVouchers />}

        {mode === 'billing' && <PaymentBilling goToTab={setMode} institution={currentInstitution} />}
      </Section>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {downloadProfileProductivityICS} from '@modules/Profile/services/servicesApi';
import {_ProfileWayOfWorkingProductivityTimes} from '@modules/Profile/types/service.model';

function getThreshold(matrix: _ProfileWayOfWorkingProductivityTimes, offset: number = 0): number {
  const [min, max] = getMinMaxOfMatrix(matrix);
  return calcThreshold(min, max, offset);
}

function calcThreshold(min: number, max: number, offset: number = 0): number {
  const result = min + Math.round((max - min) / 2) + offset;
  return result <= max ? result : max;
}

function getMinMaxOfMatrix(matrix: _ProfileWayOfWorkingProductivityTimes): [number, number] {
  let min: number | null = null;
  let max: number | null = null;

  Object.values(matrix).forEach(timeSlots => {
    Object.values(timeSlots).forEach(value => {
      // Assuming value is numerical for min/max calculation
      // Adjust this logic if your actual data structure is different
      if (min === null || value < min) {
        min = value > 0 || min === null ? value : min;
      }
      if (max === null || value > max) {
        max = value;
      }
    });
  });

  return [min ?? 0, max ?? 0]; // Assuming default min and max values as 0 if null
}

// Assuming previously defined types and utility functions
export function transformProductivityTimes(
  productivityRaw: _ProfileWayOfWorkingProductivityTimes
): Record<string, Record<string, number>> {
  const days = ['base.mo', 'base.tu', 'base.we', 'base.th', 'base.fr', 'base.sa', 'base.su'];
  const productivity: Record<string, Record<string, number>> = {};

  for (let offset = 0; offset < 2; offset++) {
    let count = 0;
    const threshold = getThreshold(productivityRaw, offset);

    for (let i = 0; i < days.length; i++) {
      if (productivityRaw.a0_early[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].early = productivityRaw.a0_early[i];
        count++;
      }
      if (productivityRaw.a1_morning[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].morning = productivityRaw.a1_morning[i];
        count++;
      }
      if (productivityRaw.a2_noon[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].noon = productivityRaw.a2_noon[i];
        count++;
      }
      if (productivityRaw.a3_afternoon[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].afternoon = productivityRaw.a3_afternoon[i];
        count++;
      }
      if (productivityRaw.a4_evening[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].evening = productivityRaw.a4_evening[i];
        count++;
      }
      if (productivityRaw.a5_night[i] >= threshold) {
        if (!productivity[days[i]]) productivity[days[i]] = {};
        productivity[days[i]].night = productivityRaw.a5_night[i];
        count++;
      }
    }

    if (count < 14) {
      break;
    }
  }
  return productivity;
}

export function compressTimes(times: Record<string, boolean>): number[][] {
  const map: Record<string, number[]> = {
    early: [8, 10],
    morning: [10, 12],
    noon: [12, 14],
    afternoon: [14, 18],
    evening: [18, 20],
    night: [20, 22],
  };

  const result: number[][] = [];
  Object.keys(times).forEach(key => {
    const current = map[key];
    const i = result.length;
    if (i > 0) {
      const last = result[i - 1];
      if (current[0] === last[1]) {
        result[i - 1][1] = current[1];
        return; // continue in forEach is achieved by returning early
      }
    }
    result.push(current);
  });

  return result;
}

export const downloadProductivityICS = async (): Promise<void> => {
  const response = await downloadProfileProductivityICS();

  if (!response?.data?.html) {
    logger.error('Failed to download the .ics file: No data found');
    return;
  }
  try {
    const file = new Blob([response.data.html], {type: 'text/calendar'});

    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(file);

    // Create an anchor tag (<a>) and trigger the download
    const link = document.createElement('a');
    link.href = fileURL;
    link.target = '_blank';
    link.setAttribute('download', 'productivity_calendar.ics'); // Name the downloaded file
    document.body.appendChild(link);
    link.click();

    if (!link) {
      logger.error('Failed to create an anchor tag for the .ics file');
      return;
    }

    // Clean up by removing the link element
    link.parentNode.removeChild(link);

    // Optionally, free up the blob URL if you are concerned about memory usage
    URL.revokeObjectURL(fileURL);
  } catch (error) {
    logger.error('Failed to download the .ics file:', error);
  }
};

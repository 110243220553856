// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_AppState} from '@modules/State/types/appState.model';
import {dispatchAction} from '@modules/State/util/util';
import {setLangAsync} from '../state/i18nSlice';

export function useLanguage(): {currentLanguage: string; setLanguage: (language: string) => void} {
  const language = useSelectState((state: _AppState) => state.i18n.lang);

  function onChangeLanguage(language: string): void {
    dispatchAction(setLangAsync(language));
  }

  return {currentLanguage: language, setLanguage: onChangeLanguage};
}

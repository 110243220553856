// @ts-nocheck
/* eslint-disable */

// import delete icon

import React from 'react';
import {Chip} from '@modules/Core/components/base/Chip';
import {SortableItem} from '@modules/Core/components/base/dnd/SortableItem';
import {IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';

interface _Props {
  item: any;
  onClick?: () => void;
  onDelete?: () => void;
  tooltip?: string;
}

export const SurveyDnDItem: React.FC<_Props> = ({tooltip, item, onClick, onDelete}) => {
  if (!item) {
    return null;
  }
  const isCustomField = item.id === item.label && item.id === item.key;

  return (
    <SortableItem id={item.key} key={item.key}>
      <ToolTip txt={tooltip}>
        <Chip
          label={item?.label || ''}
          color="light"
          onClick={onClick}
          onIconClick={onDelete}
          icon={isCustomField ? IconCancel : null}
        />
      </ToolTip>
    </SortableItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {clone} from 'lodash';
import {_ButtonProps} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {IconArrowBack} from '@modules/Core/components/base/Icons/Icons';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {logger} from '@modules/Core/util/Logger';
import {setProfileAlertsDoneAction} from '@modules/Profile/util/journalUtil';
import {trans} from '@modules/Translations/util/i18n';

export function useGlobalAlerts(onDone: () => void): {
  addAlert: (id: string, alert: _ProfileAlert) => void;
  addAlerts: (alerts: _ProfileAlert[]) => void;
  getCurrentAlert: () => _ProfileAlert | null;
  onNextPage: () => void;
  onPrevPage: () => void;
  resetAlerts: () => void;
  getEnrichedDialogConfig: () => _Dialog;
  currentIndex: number | null;
  setDialogConfig: (config: _Dialog) => void;
  currentAlertId: string | null;
  alerts: Record<string, _ProfileAlert>;
} {
  const [alerts, setAlerts] = useState<Record<string, _ProfileAlert>>({});
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  // Holds config for w/e dialog is currently being shown
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});

  // Idea is, this could be fired multiple times rapidly if multiple components are adding alerts
  // user will NOT notice this
  const reorderingRequired = useRef<boolean>(false);

  // Runs only to recalculates the order of dialogs when new ones are added.
  useEffect(() => {
    if (!reorderingRequired.current) {
      return;
    }
    // Order by prio, lower -> first
    const orderedAlerts = Object.values(alerts).sort((a, b) => a.priority - b.priority);

    setAlerts(
      orderedAlerts.reduce((acc, alert) => {
        return {
          ...acc,
          [alert.id]: alert,
        };
      }, {})
    );

    setCurrentIndex(0);
    reorderingRequired.current = false;

    const oldCurrentAlertId = currentIndex !== null ? Object.keys(alerts)[currentIndex] : null;
    const newCurrentAlertId = Object.keys(alerts)[0];
    // Reset config ONLY if the current alert has changed
    if (oldCurrentAlertId !== newCurrentAlertId) {
      setDialogConfig({});
    }
  }, [alerts]);

  const addAlert = (id: string, alert: _ProfileAlert): void => {
    if (alerts[id]) {
      return;
    }
    setAlerts(prev => {
      return {
        ...prev,
        [id]: alert,
      };
    });
    reorderingRequired.current = true;
  };

  const addAlerts = (newAlerts: _ProfileAlert[]): void => {
    newAlerts.forEach(alert => {
      addAlert(alert.id, alert);
    });
  };

  const resetAlerts = (): void => {
    setAlerts({});
    setCurrentIndex(null);
    setDialogConfig({});
  };

  const getCurrentAlert = (): _ProfileAlert | null => {
    if (currentIndex === null) {
      return null;
    }
    return alerts[Object.keys(alerts)[currentIndex]];
  };

  const onNextPage = (): void => {
    if (currentIndex === null) {
      return;
    }

    if (currentIndex === Object.keys(alerts).length - 1) {
      void handleDone();
      return;
    }

    setCurrentIndex(prev => {
      if (prev === null) {
        return null;
      }
      return prev + 1;
    });
    setDialogConfig({});
  };

  const onPrevPage = (): void => {
    if (currentIndex === 0 || currentIndex === null) {
      return;
    }

    setCurrentIndex(prev => {
      if (prev === null) {
        return null;
      }
      return prev - 1;
    });
    setDialogConfig({});
  };

  const handleDone = async (): Promise<void> => {
    const alertsIds = Object.keys(alerts ?? {});
    if (!alertsIds.length) {
      onDone?.();
      return;
    }
    await setProfileAlertsDoneAction(alertsIds);
    setAlerts({});
    onDone?.();
  };

  const getEnrichedDialogConfig = (): _Dialog => {
    const currentAlert = getCurrentAlert();
    if (!currentAlert) {
      return dialogConfig;
    }
    // We wanna add footerElements and footerElementsSuffix that will have next/prev buttons
    const justifyButtons = dialogConfig?.justifyButtons;
    const hasNext = currentIndex !== null && currentIndex < Object.keys(alerts).length - 1;
    const hasPrev = currentIndex !== null && currentIndex > 0;
    const buttons: _ButtonProps[] = clone(dialogConfig?.buttons ?? []);
    const toolbarElement = hasPrev ? <IconButton icon={IconArrowBack} onClick={onPrevPage} /> : null;
    // if (!buttons?.length) {
    if (hasNext) {
      buttons.push({
        label: trans('base.continue'),
        onClick: onNextPage,
      });
    } else {
      buttons.push({
        label: trans('gamification.dialog_confirm'),
        onClick: handleDone,
      });
    }
    // }

    return {
      ...dialogConfig,
      toolbarElement,
      buttons,
      showCloseButton: true,
      onClose: handleDone,
      justifyButtons: buttons?.length === 1 ? 'center' : justifyButtons,
    };
  };

  logger.info('dialogContentComponent', {dialogConfig, enriched: getEnrichedDialogConfig(), currentIndex, alerts});
  return {
    addAlert,
    addAlerts,
    getCurrentAlert,
    onNextPage,
    onPrevPage,
    resetAlerts,
    getEnrichedDialogConfig,
    currentIndex,
    setDialogConfig,
    currentAlertId: currentIndex !== null ? Object.keys(alerts)[currentIndex] : null,
    alerts,
  };
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {_ProfileAlert} from '@modules/Core/types/profile.model';

/**
 *
 * @param alerts
 * @returns {null}
 * @constructor
 */

interface _Props {
  alert: _ProfileAlert;
  updateCurrentConfig?: (config: _Dialog) => void;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
}

export const CETemplateAlert: React.FC<_Props> = ({alert, updateCurrentConfig}) => {
  const {metadata} = alert ?? {};

  useEffect(() => {
    updateCurrentConfig?.({
      ...(alert?.metadata?.dialogProps ?? {}),
    });
  }, [alert]);
  if (!metadata?.key) {
    return null;
  }

  return <ContentEngineContainer type={metadata.key} params={metadata.params} />;
};

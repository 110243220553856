// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect, useState} from 'react';
import {_ChosenSubscriptionProps} from '@modules/Core/components/platform/payments/paymentPlan/hooks/institutionPayment';
import {useTableHeaderActions} from '@modules/Core/components/base/table/actions/tableHeaderActions';
import {_InstitutionTableSelectValue} from '@modules/Core/components/platform/payments/institution/InstitutionPaymentTable';
import {AMBASSADOR_PLAN_ID} from '@modules/Core/config/constants';
import {useCancelSub} from '@modules/Core/hooks/payments/CancelSubscription';
import {useChangePlanSub} from '@modules/Core/hooks/payments/ChangePlan';
import {useReactivateSub} from '@modules/Core/hooks/payments/ReactivateSub';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {logger} from '@modules/Core/util/Logger';
import {hasLegacyMembership} from '@modules/Core/util/subscriptionUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {ONE_TIME_PAYMENT_PLAN_ID} from '@modules/Profile/config/membershipsConstants';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_InstitutionProfile} from '@modules/Profile/types/profile.model';
import {
  getToBeActivatedSubscription,
  hasDiscount,
  hasRunningOneTimePayment,
  isFreeSubscription,
  isSubscriptionToBeCancelled,
} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

export interface _ChosenSubscriptionProps {
  planId: string | null;
  billingInterval: string | null;
  toBeCancelled: boolean;
  isFree: boolean;
  toBeActivated: _Subscription | null;
  isSelfPayment: boolean;
  isTrial: boolean;
  hasOneTimePayment: boolean;
  isTrialReady: boolean;
  discountValue: number | null;
  currentSubscription?: _Subscription;
}

const defaultSubProps: _ChosenSubscriptionProps = {
  planId: null,
  billingInterval: null,
  toBeCancelled: false,
  isFree: false,
  toBeActivated: null,
  isSelfPayment: false,
  isTrial: false,
  hasOneTimePayment: false,
  isTrialReady: false,
  discountValue: null,
  currentSubscription: null,
};
export const useInstitutionPaymentActions = (
  institution: _Institution | null,
  plans: _PaymentPlan[],
  onUpgrade: (plan: _PaymentPlan) => void,
  onCancel?: () => void
): {
  actions: _InstitutionTableSelectValue[];
  profiles: Record<string, _InstitutionProfile>;
  chosenProfiles: string[];
  canSelectProfile: (profile: _InstitutionProfile, currentSubscriptionProps?: _ChosenSubscriptionProps) => boolean;
  onProfileSelected: (profileId: string) => void;
  onClearSelectedProfiles: () => void;
} => {
  const onChangePlan = useChangePlanSub();
  const onReactivatePlan = useReactivateSub();
  const onCancelSubscription = useCancelSub();

  const [profiles, setProfiles] = useState<Record<string, _InstitutionProfile>>({});

  const canSelectProfile = useCallback(
    (profile: _InstitutionProfile, currentSubscriptionProps?: _ChosenSubscriptionProps): boolean => {
      if (!currentSubscriptionProps?.planId || hasLegacyMembership(institution)) {
        return true;
      }

      const profileSub = profile.subscriptionData?.subscription;

      const profileSubIsCancelled = isSubscriptionToBeCancelled(profileSub);
      const profileSubIsFree = isFreeSubscription(profileSub);
      const profileSubToBeActivated = getToBeActivatedSubscription(profile);
      const profileSubIsSelfPayment = !!profileSub?.profile_id;
      const profileSubIsTrial = Boolean(profileSub?.pivot?.is_trial);
      const hasOneTimePayment = hasRunningOneTimePayment(profile);
      const profileSubIsTrialReady = !!profile?.trialAvailable;
      const discountValue = hasDiscount(profile) ? profile?.discount?.action_params?.value : null;

      return (
        profileSub?.plan_id === currentSubscriptionProps.planId &&
        profileSub?.type === currentSubscriptionProps.billingInterval &&
        profileSubIsCancelled === currentSubscriptionProps.toBeCancelled &&
        currentSubscriptionProps.isFree === profileSubIsFree &&
        currentSubscriptionProps?.toBeActivated?.plan_id === profileSubToBeActivated?.plan_id &&
        currentSubscriptionProps?.toBeActivated?.type === profileSubToBeActivated?.type &&
        currentSubscriptionProps.isSelfPayment === profileSubIsSelfPayment &&
        profileSubIsTrial === currentSubscriptionProps.isTrial &&
        currentSubscriptionProps.hasOneTimePayment === hasOneTimePayment &&
        currentSubscriptionProps.isTrialReady === profileSubIsTrialReady &&
        discountValue === currentSubscriptionProps.discountValue
      );
    },
    [institution]
  );
  const getAllowedActions = (currentSubscriptionProps: _ChosenSubscriptionProps): _InstitutionTableSelectValue[] => {
    const options: _InstitutionTableSelectValue[] = [];

    if (!currentSubscriptionProps?.planId || chosenProfiles.length === 0 || hasLegacyMembership(institution)) {
      return [];
    }

    const {billingInterval} = currentSubscriptionProps;
    const subscriptionToBeCancelled = currentSubscriptionProps.toBeCancelled;
    const subscriptionToBeChanged = subscriptionToBeCancelled && !!currentSubscriptionProps.toBeActivated;
    const {isFree} = currentSubscriptionProps;
    const isTrial = !!institution?.trialAvailable ?? currentSubscriptionProps.isTrialReady;
    const subscriptionIsTrial = currentSubscriptionProps.isTrial;
    const {hasOneTimePayment} = currentSubscriptionProps;
    // const canUseUnassigned = unassignedSubscriptions?.length > 0 && chosenProfiles?.length === 1;

    // TODO @Sherif unassigned subs
    const canUseUnassigned = false;
    const currentSubscriptionTitle = trans(`subscription.plans.${currentSubscriptionProps.planId}.title`);

    // No OTP Active and no trial active and no membership active -> option to enable otp
    if (!hasOneTimePayment && !isTrial && isFree) {
      options.push({
        label: trans('one_time_payment.enable_softfacts_check'),
        value: 'enable_softfacts_check',
        action: onUpgradeMultipleProfilesOneTimePaymentClicked,
      });
    }

    if (isFree) {
      // 1. Free users, show upgrade button
      options.push({
        label: isTrial ? trans('subscription.start_trial_now') : trans('subscription.upgrade_to_ambassador'),
        value: 'upgrade_to_ambassador',
        action: onUpgradeMultipleProfilesClicked,
      });
    } else if (!subscriptionToBeCancelled) {
      if (currentSubscriptionProps.isTrial) {
        // 3. Users with monthly subscription, show upgrade to yearly button + Cancel button
        // 4. Users with yearly subscription, show change to monthly button + Cancel button

        options.push({
          label: trans(subscriptionIsTrial ? 'subscription.cancel_trial' : 'subscription.cancel_subscription'),
          value: 'cancel_subscription',
          action: onCancelMultipleProfiles,
        });
      } else {
        // 3. Users with monthly subscription, show upgrade to yearly button + Cancel button
        // 4. Users with yearly subscription, show change to monthly button + Cancel button

        const params = {
          plan: currentSubscriptionTitle,
        };
        options.push({
          label: trans(`subscription.change_subscription_to_${billingInterval === 'monthly' ? 'yearly' : 'monthly'}`, {
            billingInterval: trans(`subscription.types.${billingInterval === 'monthly' ? 'yearly' : 'monthly'}`),
            ...params,
          }),
          value: 'change_subscription',
          action: onChangeMultipleProfiles,
        });

        options.push({
          label: trans('subscription.cancel_subscription', params),
          value: 'change_subscription',
          action: onCancelMultipleProfiles,
        });
      }
    } else if (subscriptionToBeChanged) {
      // 5. Users with subscription to be changed, show stay on $old_plan
      options.push({
        label: trans('subscription.reactivate_change'),
        value: 'reactivate_change',
        action: onReactivateMultipleProfiles,
      });
    } else {
      // 4. Users with subscription to be cancelled, show reactivate button
      options.push({
        label: trans('subscription.reactivate', {
          plan: currentSubscriptionTitle,
        }),
        value: 'reactivate_chang_multiple',
        action: onReactivateMultipleProfiles,
      });
    }

    if (canUseUnassigned && isFree) {
      // TODO @Sherif
      // options.push({
      //   label: trans('subscription.assign_open_memberships'),
      //   value: 'assign_open_memberships',
      //   action: onAssignMemberships,
      // });
    }

    return options;
  };

  const getCurrentProps = (
    chosenProfiles: string[],
    profileRows: Record<string, _InstitutionProfile>
  ): _ChosenSubscriptionProps => {
    const currentSubscription = profileRows?.[chosenProfiles?.[0]]?.subscriptionData?.subscription ?? null;
    const toBeCancelledSubscription =
      profileRows?.[chosenProfiles?.[0]]?.subscriptionData?.toBeCancelledSubscription ?? null;
    const toBeActivatedSubscription =
      profileRows?.[chosenProfiles?.[0]]?.subscriptionData?.toBeActivatedSubscription ?? null;

    if (!currentSubscription) {
      return defaultSubProps;
    }

    const profile = profileRows?.[chosenProfiles?.[0]] ?? null;

    const isFree = isFreeSubscription(currentSubscription);
    const toBeCancelled = !!toBeCancelledSubscription;
    const planId = currentSubscription.plan_id;
    const billingInterval = currentSubscription.type;

    const isSelfPayment = !!currentSubscription?.profile_id;

    const toBeActivated = toBeActivatedSubscription;
    const isTrial = Boolean(currentSubscription?.pivot?.is_trial);

    // If user chosen but no sub, then it's a one time payment
    const hasOneTimePayment = profile ? hasRunningOneTimePayment(profile) : false;

    const isTrialReady = !!profile?.trialAvailable;

    const discountValue = hasDiscount(profile) ? profile?.discount?.action_params?.value : null;

    return {
      isFree,
      toBeCancelled,
      planId,
      billingInterval,
      toBeActivated,
      isSelfPayment,
      isTrial,
      hasOneTimePayment,
      isTrialReady,
      discountValue,
      currentSubscription,
    };
  };
  const {
    currentProps,
    selectedRowsIds: chosenProfiles,
    onClearSelection: onClearSelectedProfiles,
    onRowSelected: onProfileSelected,
    actions,
    canSelectRow,
  } = useTableHeaderActions(
    profiles,
    getAllowedActions,
    getCurrentProps,
    canSelectProfile,
    defaultSubProps,
    trans('subscription.choose_members_same_plan')
  );

  // Set inst data ( Profiles)
  useEffect(() => {
    setProfiles(institution?.profiles ?? {});
  }, [institution]);

  function onReactivateMultipleProfiles() {
    if (!chosenProfiles?.length) {
      return;
    }
    void onReactivatePlan(
      institution?.id,
      currentProps.currentSubscription?.id,
      chosenProfiles,
      currentProps.currentSubscription,
      currentProps.toBeActivated,
      () => onClearSelectedProfiles()
    );
  }

  function onCancelMultipleProfiles() {
    void onCancelSubscription(
      institution?.id,
      currentProps.currentSubscription?.id,
      chosenProfiles,
      currentProps.isTrial,
      'institution',
      onCancel,
      () => onClearSelectedProfiles()
    );
  }

  function onChangeMultipleProfiles() {
    if (!chosenProfiles?.length) {
      return;
    }
    const billingInterval = currentProps.billingInterval === 'monthly' ? 'yearly' : 'monthly';
    const {planId} = currentProps;

    void onChangePlan(
      institution?.id,
      currentProps.currentSubscription?.id,
      'institution',
      billingInterval,
      chosenProfiles,
      planId,
      () => onClearSelectedProfiles()
    );
  }

  function onUpgradeMultipleProfilesClicked(): void {
    const plan = findPlan(AMBASSADOR_PLAN_ID);
    if (!plan) {
      return;
    }
    onUpgrade(plan);
  }

  function onUpgradeMultipleProfilesOneTimePaymentClicked(): void {
    const plan = findPlan(ONE_TIME_PAYMENT_PLAN_ID);
    if (!plan) {
      return;
    }
    onUpgrade(plan);
  }

  const findPlan = (planId: string): _PaymentPlan | null | undefined => plans.find(p => p.id === planId);

  return {
    actions,
    profiles,
    chosenProfiles,
    canSelectProfile: canSelectRow,
    onProfileSelected,
    onClearSelectedProfiles,
  };
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {BaseStep} from '../Base/BaseStep';

export const EmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [emails, setEmails] = useState<string[]>([]);

  const getValuesCallback = (): Record<string, any> => ({
    emails,
  });

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[emails]} options={options}>
      <Section title={options.title} description={options.description} descriptionVariant="body1">
        <Emails
          emails={emails}
          setEmails={setEmails}
          placeholder={options.placeholder}
          label={options.label}
          consentLabel={options.consentLabel}
        />
      </Section>
    </BaseStep>
  );
};

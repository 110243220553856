// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TeamMembersRolesMgmt} from '@modules/Core/components/platform/tables/TeamMembersRolesMgmt';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';

interface _TeamMembersItemProps {
  profiles: Record<string, _Profile>;
  teamId: string;
}

export const TeamMembersItem: React.FC<_TeamMembersItemProps> = ({teamId, profiles}) => {
  return <TeamMembersRolesMgmt teamId={teamId} teamMembers={profiles} />;
};

// @ts-nocheck
/* eslint-disable */

import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_ProfileExercise} from '@modules/Profile/types/exercises.model';

export const revokeSharedResultAction = async (id: string): Promise<_ProfileExercise | null> => {
  const method = 'share.result.invoke';
  const params = {
    id,
  };

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {LatestSoftwareUpdate} from '@modules/Core/components/base/latest-software-update/LatestSoftwareUpdate';
import {
  CE_TEMPLATE_ALERT_TYPE,
  PROFILE_ALERT_NEW_RELEASE,
  PROFILE_ALERT_TYPE_GAMIFICATION,
  PROFILE_ALERT_TYPE_SERVICE_ROUND_FINISHED,
} from '@modules/Core/config/constants';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {logger} from '@modules/Core/util/Logger';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {CETemplateAlert} from '@modules/Profile/components/alerts/CETemplateAlert';
import {ProfileAlertDialog} from '@modules/Profile/components/alerts/ProfileAlertDialog';
import ExerciseRoundFinishedDialog from '@modules/Profile/components/exercises/dialogs/ProfileExerciseRoundFinishedDialog';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {GamificationAlertDialog} from './GamificationAlertDialog';

/**
 *
 * @param alerts
 * @returns {null}
 * @constructor
 */

interface _Props {
  alert: _ProfileAlert;
  onDone?: () => void;
  updateCurrentConfig?: (config: _Dialog) => void;
}

export const ProfileAlert: React.FC<_Props> = ({alert, onDone, updateCurrentConfig}) => {
  const [showDialog, setShowDialog] = useState(false);
  const {navigate} = useAppNavigate();

  useEffect(() => {
    if (alert?.id) {
      setTimeout(() => setShowDialog(true), 1000);
    }
  }, [alert]);

  if (!alert) {
    return null;
  }

  const handleClose = async (confirmed: boolean, navigateTo: string | null): Promise<void> => {
    onDone?.();
    if (confirmed && navigateTo) {
      navigate(navigateTo);
    }
  };

  logger.debug('ProfileAlert', alert);
  let content;
  if (alert.key === CE_TEMPLATE_ALERT_TYPE) {
    content = <CETemplateAlert alert={alert} updateCurrentConfig={updateCurrentConfig} onClose={handleClose} />;
  } else if (alert.key === PROFILE_ALERT_TYPE_SERVICE_ROUND_FINISHED) {
    content = (
      <ExerciseRoundFinishedDialog
        updateCurrentConfig={updateCurrentConfig}
        open={showDialog}
        onClose={handleClose}
        service={alert?.metadata?.service}
        round={alert?.metadata?.round as number}
      />
    );
  } else if (alert.key === PROFILE_ALERT_NEW_RELEASE) {
    content = (
      <LatestSoftwareUpdate
        updateCurrentConfig={updateCurrentConfig}
        latestReleaseKey={currentProfile()?.latestSoftwareUpdateCE}
        open={showDialog}
        // setOpen={(open: boolean) => {
        //   setShowDialog(open);
        //   if (!open) handleClose(true, null);
        // }}

        onClose={handleClose}
      />
    );
  } else if (alert.key === PROFILE_ALERT_TYPE_GAMIFICATION) {
    content = (
      <GamificationAlertDialog
        type={alert.service}
        updateCurrentConfig={updateCurrentConfig}
        alert={alert}
        open={showDialog}
        onClose={handleClose}
      />
    );
  } else {
    content = (
      <ProfileAlertDialog
        updateCurrentConfig={updateCurrentConfig}
        alert={alert}
        open={showDialog}
        onClose={handleClose}
      />
    );
  }

  return <>{content}</>;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {TeamExercisesRoundRow} from '@modules/Team/components/dashboard/TeamExercisesRoundRow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  exercises: Record<string, _ShortProfile[]>;
  // each entry is service name, ordered by relevance
  relevance: string[];
  xpMetrics: Record<string, _ProfileTrackingStatisticEntry>;
}

const LIMIT = 4;
export const TeamExercisesItem: React.FC<_Props> = ({xpMetrics, exercises, relevance}) => {
  logger.debug('TeamExercisesItem', {exercises, relevance, xpMetrics});

  if (!exercises || Object.keys(exercises).length === 0) {
    return (
      <PlaceholderSection
        title={trans('team_gamification.exercises_missing.title')}
        content={trans('team_gamification.exercises_missing.description')}
        image={getDesignImage('your exercises.png')}
      />
    );
  }
  return (
    <div className="flex flex-col gap-3">
      {Object.entries(exercises ?? {})
        // lower -> first
        .sort(([a], [b]) => {
          if (!relevance.includes(a)) {
            return 1;
          }

          if (!relevance.includes(b)) {
            return -1;
          }
          return relevance.indexOf(a) - relevance.indexOf(b);
        })
        .map(([service, profiles]) => {
          return (
            <div key={service}>
              <TeamExercisesRoundRow xpMetric={xpMetrics?.[service]} service={service} profiles={profiles} />
            </div>
          );
        })
        .slice(0, LIMIT)}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {DatePickerContext} from '../DatePickerProvider';
import {addMonths} from '../utils';

const Months = (): JSX.Element => {
  const {selectedDates, showSelectedDate, changeSelectedDate, getFormattedDate, setView, options} =
    useContext(DatePickerContext);
  return (
    <div className="grid w-64 grid-cols-4">
      {[...Array(12)].map((_month, index) => {
        const month = getFormattedDate(new Date(selectedDates[0].getFullYear(), index), {month: 'short'});
        return (
          <span
            key={index}
            className={`hover:bg-gray-100 dark:hover:bg-gray-600 block flex-1 leading-9 border-0 rounded-lg cursor-pointer text-center  dark:text-white font-semibold text-sm text-gray-900 ${
              showSelectedDate &&
              selectedDates[0].getTime() > 0 &&
              getFormattedDate(selectedDates[0], {month: 'short'}) === month
                ? twMerge('bg-blue-700 text-white hover:bg-blue-600', options?.theme?.selected)
                : ''
            } ${twMerge(options?.theme?.text)}`}
            onClick={() => {
              changeSelectedDate('date', new Date(addMonths(selectedDates[0], index - selectedDates[0].getMonth())));
              setView('days');
            }}
          >
            {month}
          </span>
        );
      })}
    </div>
  );
};

export default Months;

// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '../util/Logger';

export type _ErrorState = Record<string, string | null>;

const errorSlice = createSlice({
  name: 'errors',
  initialState: {} satisfies _ErrorState,
  reducers: {
    submitError(state: _ErrorState, action) {
      const errorName = action?.payload?.name;

      if (!errorName) {
        logger.error('[ErrorSlice] Error name is missing');
        return;
      }

      logger.info(`[ErrorSlice] Submitting error ${errorName}...`);

      state[errorName] = action.payload.error;
    },
    resetError(state: _ErrorState, action) {
      const errorName = action?.payload?.name;

      if (!errorName) {
        logger.error('[ErrorSlice] Error name is missing');
        return;
      }

      logger.info(`[ErrorSlice] Resetting error ${errorName}.`);

      state[errorName] = null;
    },
  },
});

export const {submitError, resetError} = errorSlice.actions;
export default errorSlice;

// @ts-nocheck
/* eslint-disable */

import {UniqueIdentifier} from '@dnd-kit/core';
import {GEM_BAG_ID, GEMS_TYPE} from '@modules/Profile/hooks/profileValuesDnDActions';
import {_ProfileValuesEntry} from '@modules/Profile/types/service.model';

export const isGemBag = (id?: string | UniqueIdentifier): boolean => id === GEM_BAG_ID;
export const isGem = (id?: string | UniqueIdentifier): boolean => id?.toString()?.startsWith(GEMS_TYPE);

export const isCustomValue = (value: _ProfileValuesEntry): boolean => !!value.created_by;

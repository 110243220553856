// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconCancel} from './Icons/Icons';

export type _AlertSeverity = 'error' | 'warning' | 'info' | 'success';
export interface _Alert {
  color?: _AlertSeverity;
  label: string;
  startIcon?: boolean;
  endIcon?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  width?: string;
}

const autoHideDuration = 5000;

export const Alert: React.FC<_Alert> = ({
  color,
  label,
  startIcon = true,
  endIcon,
  onClose,
  onShow,
  width = 'w-1/2',
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback((): void => {
    setOpen(false);
    if (onClose) {
      setTimeout(() => onClose(), 300);
    }
  }, [onClose]);

  useEffect(() => {
    setOpen(true);
    if (onShow) {
      setTimeout(() => onShow(), 300);
    }
    setTimeout(() => handleClose(), autoHideDuration);
  }, [handleClose, onShow]);

  let bgColor = 'bg-white500';
  let iconColor;
  let textColor;

  if (color === 'danger') {
    bgColor = 'bg-red700';
    iconColor = 'fill-blue700';
    textColor = 'text-blue700';
  }
  if (color === 'warning') bgColor = 'bg-orange700';
  if (color === 'success') bgColor = 'bg-green700';
  // if (color === 'info') bgColor = 'bg-blue700';

  return (
    <div
      className={addCN(
        bgColor,
        'h-8 border-1 border-grey300 rounded-sm py-1 px-2',
        width,
        'flex flex-col gap-1 shrink-0 items-start justify-center mx-auto',
        'transition ease-in-out duration-300',
        open ? 'opacity-100' : 'opacity-0'
      )}
    >
      {/* content */}
      <div className="flex justify-between items-center self-stretch">
        {/* left side */}
        <div className="flex items-center gap-1 ">
          {startIcon ? <IconAlert color={iconColor} /> : null}
          <span className={addCN(textColor)}>{label}</span>
        </div>

        {/* right side */}
        {endIcon ? (
          <button
            onClick={handleClose}
            className={addCN(
              'border-[2px] rounded-sm',
              color ? 'border-white500' : 'border-grey300',
              'flex w-5 h-5 p-1 justify-center items-center gap-1'
            )}
          >
            <IconCancel color={color ? 'fill-white500' : undefined} />
          </button>
        ) : null}
      </div>
    </div>
  );
};

interface _IconAlert {
  color?: string;
}

const IconAlert: React.FC<_IconAlert> = ({color = 'fill-grey700'}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={addCN(color, 'transition')}
  >
    <path d="M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28848C12.2288 9.28848 12.4207 9.21108 12.5755 9.05628C12.7303 8.90148 12.8077 8.70966 12.8077 8.48081C12.8077 8.25197 12.7303 8.06015 12.5755 7.90533C12.4207 7.75053 12.2288 7.67313 12 7.67313C11.7711 7.67313 11.5793 7.75053 11.4245 7.90533C11.2697 8.06015 11.1923 8.25197 11.1923 8.48081C11.1923 8.70966 11.2697 8.90148 11.4245 9.05628C11.5793 9.21108 11.7711 9.28848 12 9.28848ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5766 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45271 3.248 8.29658C3.74667 7.14043 4.42342 6.13475 5.27825 5.27953C6.1331 4.4243 7.13834 3.74724 8.29398 3.24836C9.44959 2.74947 10.6844 2.50003 11.9983 2.50003C13.3122 2.50003 14.5473 2.74936 15.7034 3.24803C16.8596 3.7467 17.8652 4.42345 18.7205 5.27828C19.5757 6.13313 20.2527 7.13837 20.7516 8.29401C21.2505 9.44962 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8653 18.7217 18.7205C17.8669 19.5757 16.8616 20.2528 15.706 20.7517C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.87501 17.675 6.32501C16.125 4.77501 14.2333 4.00001 12 4.00001C9.76664 4.00001 7.87498 4.77501 6.32498 6.32501C4.77498 7.87501 3.99998 9.76667 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z" />
  </svg>
);

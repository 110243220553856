// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ServiceInformationCoachProps {
  service: string;
  teamId?: string;
}

export const ServiceInformationCoach: React.FC<_ServiceInformationCoachProps> = ({service, teamId = null}) => {
  const [serviceTitle, setServiceTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (service) {
      // These 2 keys are ok to create dynamically as they are being used in many other places.
      setServiceTitle(trans(`service.${service}`));
    }
  }, [service]);

  useEffect(() => {
    if (serviceTitle) {
      setMessage(trans('coaches.service_not_ready', {service: serviceTitle}));
    }
  }, [serviceTitle]);

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <img
          alt={serviceTitle}
          src="/assets/img/undraw/ontheoffice.svg"
          className="svg-inject img-fluid w-[300px] h-[150px]"
        />
        <p className="mb-auto mt-auto">{message}</p>
      </div>
      {can('team.communication:email', {
        team_id: teamId,
      }) && <div className="flex flex-row justify-end">{/* <SendEmailToTeamButton /> */}</div>}
    </>
  );
};

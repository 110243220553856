// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {GraphItem} from '@modules/ContentEngine/components/items/GraphItem';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Typography} from '@modules/Core/components/base/Typography';
import {STRIPPED_SERVICE_NAMES} from '@modules/Core/config/services';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {createMultiSolidGaugeChartData} from '@modules/Core/util/util/graphUtil';
import {ProfileResultChanges} from '@modules/Profile/components/services/result/common/ProfileResultChanges';
import {EXERCISES_SUPPORTED_FACETS} from '@modules/Profile/config/exercisesConstants';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {_ServiceResult, _ServiceResultChanges, _ServiceResultData} from '@modules/Profile/types/service.model';
import {startServiceNextRoundAction} from '@modules/Profile/util/exercisesActionsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceGraphItemProps {
  service: string;
  data: _ServiceResultData;
  serviceStatus: _ServiceStatus;
  storedResults: _ServiceResult[];
  changes: _ServiceResultChanges;
  profileExercises: _ProfileExerciseCE;
  profileData: _ServiceResultData | null;
  changesData: _ServiceResultChanges | null;

  available: boolean;

  isDevMode?: boolean;
  view: 'shared' | 'result';
}

interface _FacetItem {
  category: string;
  value: number;
}

export interface _GraphData {
  category: string;
  value: number;
}

export const ProfileResultGraph: React.FC<_ProfileServiceGraphItemProps> = ({
  service,
  data,
  serviceStatus,
  storedResults,
  changes,
  available,
  isDevMode,
  view,
  profileData,
  changesData,
}) => {
  const graphType = 'SolidGaugeMulti';

  // Graph data
  const [graphData, setGraphData] = useState<_GraphData[][]>([]);
  const [nextRoundReady, setNextRoundReady] = useState<boolean>(false);

  useEffect(() => {
    setNextRoundReady(!!serviceStatus?.next_round_ready);
  }, [serviceStatus]);

  useEffect(() => {
    if (!service || !profileData) {
      return;
    }

    let items: _FacetItem[] = [];

    storedResults?.forEach(result => {
      const resultData = result?.result?.result?.dimensions?.[STRIPPED_SERVICE_NAMES[service]];

      if (resultData) {
        items.push({
          category: trans(PROFILE_SERVICES_TITLES[service]),
          value: resultData.value,
        });

        items = addFacets(resultData.facets, items);
      }
    });

    items.push({
      category: trans(PROFILE_SERVICES_TITLES[service]),
      value: profileData?.value,
    });

    items = addFacets(profileData?.facets, items);

    setGraphData(createMultiSolidGaugeChartData(items, 5, trans(PROFILE_SERVICES_TITLES[service])));
  }, [service, profileData, storedResults, serviceStatus]);

  const addFacets = (
    facetsResult: Record<string, _ServiceResultData> | undefined,
    items: _FacetItem[]
  ): _FacetItem[] => {
    if (!facetsResult) {
      return [];
    }
    const facetsKeys = Object.keys(facetsResult ?? {}).filter(facet => EXERCISES_SUPPORTED_FACETS.includes(facet));

    facetsKeys.forEach(facet => {
      items.push({
        category: trans(`profile_modules.facets.${facet}.title_short`),
        value: facetsResult[facet].value,
      });
    });

    return items;
  };
  const onStartNewRound = async (): Promise<void> => {
    if (serviceStatus?.current_round === undefined) {
      return;
    }

    try {
      dispatchReactEvent('navigate.start');
      await startServiceNextRoundAction(service, serviceStatus.current_round + 1);
    } catch (e) {
      logger.error('ProfileServiceGraphItem', e);
    }
  };

  if (!service || !serviceStatus) {
    return null;
  }

  return (
    <div className="flex flex-row h-full items-center gap-3">
      <div className="flex justify-center items-center relative-col-width-4 relative-col-height-4">
        {graphData?.length && <GraphItem type={graphType} data={graphData} withWrapper={false} />}
      </div>

      <div className="flex flex-col items-start justify-center flex-1 gap-2">
        <Typography variant="h6">{trans('profile_modules.explanation')}</Typography>
        <Typography>{trans(`profile_modules.results.${service}.${profileData?.classification}`)}</Typography>

        <ProfileResultChanges
          currentRound={serviceStatus.current_round}
          nextRoundReady={nextRoundReady}
          service={service}
          changes={changesData}
          view={view}
        />

        <div className="flex flex-grow justify-end">
          {Boolean(nextRoundReady && available && view === 'result') && (
            <div className="relative-col-width-3">
              <Button
                onClick={() => {
                  void onStartNewRound();
                }}
                variant="primary"
              >
                {trans('profile_modules.finalise_cta')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

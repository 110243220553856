// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import {getPublicProfileRoute} from '@/_core/util/util';
import {_Institution} from '@modules/Institution/types/institution.model';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {_Team} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';
import './InstitutionGraph.scss';

const graphNodesTypes = {
  profile: {
    stroke: '#aaa',
    strokeWidth: 1.5,
    strokeOpacity: 0.6,
    shape: 'circle',
    fill: 'rgba(0,0,0,0)',
    width: 40,
    height: 40,
    r: 30,
    x: -20,
    y: -20,
    textAnchor: 'middle',
    textY: 20,
  },
  team: {
    stroke: '#aaa',
    strokeWidth: 1.5,
    strokeOpacity: 0.6,
    shape: 'circle',
    fill: 'rgba(0,0,0,0)',
    width: 40,
    height: 40,
    r: 30,
    x: -20,
    y: -20,
    textAnchor: 'middle',
    textY: 20,
  },
  institution: {
    stroke: '#aaa',
    strokeWidth: 1.5,
    strokeOpacity: 0.6,
    shape: 'circle',
    fill: 'rgba(0,0,0,0)',
    width: 40,
    height: 40,
    r: 30,
    x: -20,
    y: -20,
    textAnchor: 'middle',
    textY: 20,
  },
};

const TYPE_INSTITUTION = 'institution';
const TYPE_PROFILE = 'profile';
const TYPE_TEAM = 'team';

interface _InstitutionGraphProps {
  institution: _Institution | null;
  currentProfile: _Profile | null;
  profiles: Record<string, _Profile> | null;
  teams: _Team[] | null;
}

export const InstitutionGraph: React.FC<_InstitutionGraphProps> = ({institution, currentProfile, profiles, teams}) => {
  const {navigate} = useAppNavigate();

  const svgRef = useRef(null);
  const [data, setData] = useState({});
  const [width, setWidth] = useState<number>(1000);
  const [height, setHeight] = useState<number>(1000);

  useEffect(() => {
    createData();
  }, [institution, currentProfile, profiles, teams]);

  useEffect(() => {
    resetState();
    generateGraph();
  }, [data, width, height]);

  function createData() {
    let nodes = [];
    nodes.push(createInstitutionNodeData());
    nodes = nodes.concat(createTeamsNodeData());
    nodes = nodes.concat(createProfilesNodeData());

    const links = createLinks();

    nodes = nodes.filter(node => node);

    setData({
      nodes,
      links,
    });
  }

  function createLinks() {
    const connectedProfileIds: string[] = [];
    const links: Array<{source: string; target: string}> = [];

    if (teams) {
      (teams || []).forEach(team => {
        team?.profiles?.forEach((profileId: string) => {
          if (!Object.keys(profiles || {}).includes(profileId)) {
            return;
          }
          links.push({
            source: team.id,
            target: profileId,
          });
          links.push({
            source: institution.id,
            target: team.id,
          });

          connectedProfileIds.push(profileId);
        });
      });
    }

    if (profiles) {
      Object.keys(profiles || {}).forEach(profileId => {
        if (connectedProfileIds.includes(profileId)) {
          return;
        }
        links.push({
          source: institution.id,
          target: profileId,
        });
      });
    }
    return links;
  }

  function createInstitutionNodeData() {
    if (!institution) {
      return null;
    }
    return createNodeData(TYPE_INSTITUTION, institution.id, institution.name, '/img/svg/Organisation.svg');
  }

  function createTeamsNodeData() {
    if (!teams) {
      return null;
    }
    return teams.map(team => {
      return createNodeData(TYPE_TEAM, team.id, team.name, '/img/svg/Teams.svg');
    });
  }

  function createProfilesNodeData() {
    if (!profiles) {
      return null;
    }
    return Object.values(profiles).map(profile => {
      const photo = profile?.hasPhoto;
      return createNodeData(TYPE_PROFILE, profile.id, profile.fullName, getProfilePic(profile), photo);
    });
  }

  function createNodeData(type, id, name, image, photo = false) {
    return {
      type,
      id,
      name,
      image,
      photo,
    };
  }

  function updateSimulation(simulation, links, nodes) {
    // Update the position of the nodes and links on each tick of the simulation
    simulation.on('tick', () => {
      links
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      nodes.attr('transform', d => `translate(${d.x},${d.y})`);
    });
  }

  function resetState() {
    if (svgRef.current) {
      svgRef.current.innerHTML = '';
    }
  }

  function forceBoundingBox([x0, y0], [x1, y1]) {
    return function (alpha) {
      for (const node of data.nodes) {
        if (node.x < x0) node.x = x0;
        if (node.x > x1) node.x = x1;
        if (node.y < y0) node.y = y0;
        if (node.y > y1) node.y = y1;
      }
    };
  }

  function generateGraph() {
    if (!data.nodes || !data.links) {
      return;
    }
    const nodeCount = data.nodes.length;
    const chargeStrength = nodeCount > 20 ? -1000 : -4000; // decrease charge strength if node count is greater than 20
    const centerPoint = nodeCount >= 10 ? [500, 500] : [500, 300]; // shift the center point if node count is greater than 10

    // Get the center node (type institution)
    const centerNode = data.nodes.find(node => node.type === 'institution');

    // Set up the D3 force simulation
    const newSimulation = d3
      .forceSimulation(data.nodes)
      .force('charge', d3.forceManyBody().strength(chargeStrength))
      .force(
        'link',
        d3
          .forceLink(data.links)
          .id(d => d.id)
          .distance(50)
      )
      .force('center', d3.forceCenter(centerPoint[0], centerPoint[1]))
      .force(
        'x',
        d3.forceX().x(d => (d === centerNode ? width / 2 : d.x))
      )
      .force(
        'y',
        d3.forceY().y(d => (d === centerNode ? height / 2 : d.y))
      )
      .force('collision', d3.forceCollide().radius(50)) // Adding collision force
      .force('bounding-box', forceBoundingBox([0, 0], [width, height])) // Custom bounding box force
      .stop();

    // Run the simulation for a certain number of iterations without rendering it
    for (let i = 0; i < 300; ++i) {
      newSimulation.tick();
    }

    // Create the SVG element and append it to the DOM
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', [0, 0, width, height])
      .attr('width', '100%')
      .attr('height', '100%');

    // const linkGroup = svg.append('g') // Create a group for the links
    // const nodeGroup = svg.append('g') // Create a group for the nodes

    // Create a container group for nodes and links
    const container = svg.append('g');

    const linkGroup = container.append('g'); // Create a group for the links
    const nodeGroup = container.append('g'); // Create a group for the nodes

    const links = generateLinks(linkGroup);
    const nodes = generateNodes(nodeGroup);

    // Manually render the nodes and links positions after the simulation
    // courtesy of GPT4
    links
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y);

    nodes.attr('transform', d => `translate(${d.x},${d.y})`);

    updateSimulation(newSimulation, links, nodes);

    if (process.env.REACT_APP_INSTITUTION_GRAPH_FEATURE_FLAG === 'true') {
      const zoom = d3.zoom().on('zoom', event => {
        container.attr('transform', event.transform);
      });
      svg.call(zoom);
    }
  }

  function generateNodes(svg) {
    const nodes = svg.selectAll('g').data(data.nodes).join('g');

    nodes.each(function (d) {
      const nodeType = graphNodesTypes[d.type];
      const {type} = d;
      const {id} = d;
      const {name} = d;
      const {image} = d;
      const clipPathId = `${type}-${id}-clip-path`;
      const nodeId = `node-${id}`;

      const imageWidth = nodeType.r * 2; // Adjust the width here
      const imageHeight = nodeType.r * 2; // Adjust the height here
      const imageX = -imageWidth / 2;
      const imageY = -imageHeight / 2;
      // Create the clip path
      d3.select(this)
        .append('clipPath')
        .attr('id', clipPathId)
        .append(nodeType.shape)
        .attr('r', nodeType.r)
        .attr('cx', nodeType.x)
        .attr('cy', nodeType.y);

      d3.select(this)
        .append('circle')
        .attr('cx', imageX)
        .attr('cy', imageY)
        .attr('r', nodeType.r * 2)
        .attr('fill', '#D3DCE6')
        .attr('clip-path', `url(#${clipPathId})`);

      if (d.photo) {
        d3.select(this)
          .append('image')
          .attr('x', -60)
          .attr('y', -50)
          .attr('width', 80)
          .attr('height', 80)
          .attr('href', image)
          .attr('altr', name)
          .attr('fill', 'white')
          .attr('clip-path', `url(#${clipPathId})`);
      } else {
        d3.select(this)
          .append('image')
          .attr('x', imageX)
          .attr('y', imageY)
          .attr('width', nodeType.r)
          .attr('height', nodeType.r)
          .attr('href', image)
          .attr('altr', name)
          .attr('clip-path', `url(#${clipPathId})`)
          .attr('transform', `translate(-${nodeType.r / 7}, -${nodeType.r / 7})`)
          .attr('stroke', nodeType.stroke);
      }

      // Shape
      if (type === TYPE_PROFILE && name === 'Unknown') {
        const tooltip = d3Tip()
          .attr('class', 'd3-tip')
          .html(() => trans('institution.graph_unknown_user_tooltip'));

        d3.select(this).call(tooltip);

        d3.select(this)
          .append(nodeType.shape)
          .attr('id', nodeId)
          .attr('r', nodeType.r)
          .attr('cx', nodeType.x)
          .attr('cy', nodeType.y)
          .attr('fill', nodeType.fill)
          .attr('stroke', nodeType.stroke)
          .attr('stroke-width', nodeType.strokeWidth)
          .attr('stroke-opacity', nodeType.strokeOpacity)
          .attr('width', nodeType.width)
          .attr('height', nodeType.height)
          .attr('clip-path', `url(#${clipPathId})`)
          .classed('node-circle', true)
          .style('cursor', 'pointer')
          .on('click', e => handleClick(e, name))
          .on('mouseover', tooltip.show)
          .on('mouseout', tooltip.hide);
      } else {
        d3.select(this)
          .append(nodeType.shape)
          .attr('id', nodeId)
          .attr('r', nodeType.r)
          .attr('cx', nodeType.x)
          .attr('cy', nodeType.y)
          .attr('fill', nodeType.fill)
          .attr('stroke', nodeType.stroke)
          .attr('stroke-width', nodeType.strokeWidth)
          .attr('stroke-opacity', nodeType.strokeOpacity)
          .attr('width', nodeType.width)
          .attr('height', nodeType.height)
          .attr('clip-path', `url(#${clipPathId})`)
          .classed('node-circle', true)
          .style('cursor', 'pointer')
          .on('click', handleClick);
      }

      const text = d3
        .select(this)
        .append('text')
        .text(name)
        .attr('text-anchor', nodeType.textAnchor)
        .attr('y', nodeType.textY)
        .attr('font-size', 10)
        .attr('alignment-baseline', 'middle')
        .attr('dx', nodeType.x)
        .attr('fill', 'black');

      const bbox = text.node().getBBox();

      d3.select(this)
        .insert('rect', 'text')
        .attr('x', bbox.x - 10)
        .attr('y', bbox.y)
        .attr('width', bbox.width + 20)
        .attr('height', bbox.height)
        .attr('rx', 6)
        .attr('stroke', nodeType.stroke)
        .attr('fill', 'white');
    });

    return nodes;
  }

  function generateLinks(svg) {
    const links = svg.attr('stroke', '#aaa').attr('stroke-width', 1).selectAll('line').data(data.links).join('line');

    return links;
  }

  // Function to handle node click event
  function handleClick(e, name?: string) {
    const {type} = e.target.__data__;
    const {id} = e.target.__data__;
    switch (type) {
      case TYPE_INSTITUTION:
        return;
      case TYPE_TEAM:
        // as we do not have a team dashboard yet.
        // navigate(`/team/${id}`);
        return;
      case TYPE_PROFILE:
        if (name === 'Unknown') {
          return;
        }
        navigate(getPublicProfileRoute(id));

      default:
    }
  }

  return (
    <div className="institution-graph">
      <svg ref={svgRef} className="force-graph" />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {registerNavigateToCallback} from '@/_core/util/util';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useLocationEffect} from '@modules/Navigation/hooks/locationEffect';

export const LOCATION_CHANGED_APP_EVENT = 'location.changed';
export const PROFILE_RELOADED_APP_EVENT = 'profile.reloaded';
export const CONTENT_RELOADED_APP_EVENT = 'content.reloaded';
export const useOnInitNavigation = (): void => {
  useNavigationInit();
  useOnRouteChange();
};

const useNavigationInit = (): void => {
  const {navigate} = useAppNavigate();
  registerNavigateToCallback(navigate);
};

const useOnRouteChange = (): void => {
  useLocationEffect(location => {
    logger.debug('[useOnRouteChange] location', location);
    dispatchReactEvent(LOCATION_CHANGED_APP_EVENT, location);
  });
};

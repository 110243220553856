// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_ProfileOneTimeNotification} from '@modules/ContentEngine/types/core.model';
import OneTimeNotificationDialog from '@modules/Profile/components/dialogs/OneTimeNotificationDialog';

interface _Props {
  notifications: _ProfileOneTimeNotification[];
  onDone?: () => void;
}

export const ProfileOneTimeNotificationsFlow: React.FC<_Props> = ({onDone, notifications: originalNotifications}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = useState<_ProfileOneTimeNotification | null>(null);
  const [notifications, setNotifications] = useState<_ProfileOneTimeNotification[]>(originalNotifications);
  useEffect(() => {
    if (!originalNotifications?.length) {
      setCurrentNotification(null);
      setNotifications([]);
      return;
    }

    setCurrentNotification(originalNotifications[0]);
    setNotifications(originalNotifications);
  }, [originalNotifications]);

  useEffect(() => {
    if (currentNotification && !!currentNotification.value && currentNotification.value !== '0') {
      prepareNextNotification();
      return;
    }

    if (currentNotification && (!currentNotification.value || currentNotification.value === '0')) {
      setOpen(true);
    }
  }, [currentNotification]);

  const prepareNextNotification = (): void => {
    const nextNotifications = notifications.slice(1);

    if (!nextNotifications.length) {
      onDone?.();
      setCurrentNotification(null);
      return;
    }

    setCurrentNotification(nextNotifications[0]);
    setNotifications(nextNotifications);
  };

  if (!currentNotification) {
    return null;
  }

  return (
    <OneTimeNotificationDialog
      onDone={prepareNextNotification}
      notification={currentNotification}
      open={open}
      setOpen={setOpen}
    />
  );
};

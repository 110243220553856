// @ts-nocheck
/* eslint-disable */

import React, {FC} from 'react';
import {addCN} from '@/_core/util/cssHelper';

export type _TypographyVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body'
  | 'body-bold'
  | 'body2'
  | 'caption';

interface _Typography {
  id?: string;
  children?: React.ReactNode;
  variant?: _TypographyVariants;
  bold?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dangerouslySetInnerHTML?: {__html: string | TrustedHTML};
  breakLines?: boolean;
  breakWords?: boolean;
}

export const Typography: FC<_Typography> = ({
  id,
  children,
  variant,
  bold,
  className,
  style,
  dangerouslySetInnerHTML,
  breakLines,
  breakWords,
}) => (
  <span
    id={id}
    style={{...(style ?? {}), wordBreak: breakWords ? 'break-word' : ''}}
    className={addCN(bold ? 'font-bold' : '', `text-${variant}`, className, breakLines ? 'whitespace-pre-line' : '')}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </span>
);

Typography.defaultProps = {
  children: null,
  variant: 'body',
  bold: false,
  className: '',
  style: {},
  dangerouslySetInnerHTML: undefined,
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import ReactPlayer from 'react-player';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _VideoItemProps {
  url: string;
}

export const VideoItem: React.FC<_VideoItemProps> = ({url}) => {
  const {currentLanguage} = useLanguage();
  return (
    <ReactPlayer
      url={url}
      width="100%"
      config={{
        youtube: {
          playerVars: {
            cc_load_policy: 1, // This enables closed captions
            hl: currentLanguage,
            cc_lang_pref: currentLanguage,
          },
        },
      }}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  onSave: () => Promise<void>;
  switchMode: () => void;
  mode: 'view' | 'edit';
  view: 'shared' | 'result';
}

export const ProfileValuesActions: React.FC<_Props> = ({view, mode, onSave, switchMode}) => {
  if (view !== 'result') {
    return null;
  }
  return (
    <div className="flex flex-row w-full justify-end">
      {mode === 'view' && (
        <div className="relative-col-width-2">
          <Button onClick={switchMode}>{trans('base.edit')}</Button>
        </div>
      )}
      {mode === 'edit' && (
        <div className="relative-col-width-2">
          <Button onClick={onSave}>{trans('base.save')}</Button>
        </div>
      )}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

// import { AmCharts } from '../../../../AmCharts/AmCharts'

import React, {useEffect, useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {fuzzySearch} from '@modules/Core/util/searchUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _SearchInputProps {
  entries: any[];
  setFilteredEntries: (entries: any[]) => void;
  searchAttributes: string[];
  searchOptions?: {
    isCaseSensitive?: boolean;
    shouldSort?: boolean;
  };
  onSearch?: (searchString: string, filteredEntries: any[]) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

/**
 * Currently supports only fuzzy search
 * Can be further extended and customized (e.g label, placeholder etc) when needed
 * @constructor
 */

const FUZZY_THRESHOLD = 0.3;
export const SearchInput: React.FC<_SearchInputProps> = ({
  entries,
  setFilteredEntries,
  searchAttributes,
  searchOptions,
  onSearch = null,
  inputRef,
}) => {
  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    if (!searchString) {
      setFilteredEntries(entries);
      return;
    }

    const newFilteredData = fuzzySearch(entries, searchString, searchAttributes, searchOptions, FUZZY_THRESHOLD);

    setFilteredEntries(newFilteredData);
    onSearch?.(searchString, newFilteredData);
  }, [searchString, entries]);

  return (
    <Input
      elRef={inputRef}
      placeholder={trans('base.search')}
      value={searchString}
      onChange={e => setSearchString(e)}
    />
  );
};

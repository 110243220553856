// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_TypographyVariants} from '@modules/Core/components/base/Typography';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';

interface _Props {
  value?: number;
  width?: number;
  textVariant: _TypographyVariants;
}

export const XPItem: React.FC<_Props> = ({value, width = null, textVariant = null}) => {
  if (!value) return null;
  return <ExperienceCount experience={value} width={width} variant={textVariant} />;
};

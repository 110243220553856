// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {DOWNLOAD_PROFILE_PRODUCTIVITY_ICS} from '@modules/Profile/services/paths';

async function downloadProfileProductivityICS(): Promise<AxiosResponse> {
  return await httpClient.get(DOWNLOAD_PROFILE_PRODUCTIVITY_ICS);
}

export {downloadProfileProductivityICS};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {invokeAction} from '@modules/Core/util/actionsUtil';
import {useProfile} from '@modules/Profile/hooks/profile';
import {trans} from '@modules/Translations/util/i18n';

interface _FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  type: string;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  label?: string;
}

export const FeedbackDialog: React.FC<_FeedbackDialogProps> = ({
  open,
  onClose,
  type,
  title,
  description,
  confirmText,
  cancelText,
  label,
}) => {
  const [currentProfile] = useProfile();
  const snackbar = useSnackbar();

  const [feedback, setFeedback] = useState('');

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!feedback || !currentProfile?.id || !type) {
      return;
    }
    try {
      const response = await invokeAction(
        'feedback.submit',
        {
          content: feedback,
          type,
          profileId: currentProfile.id,
          url: window.location.href,
        },
        {
          showSnackbar: true,
        }
      );

      if (response?.status === ACTION_STATUS_SUCCESS) {
        onClose();
      }
    } catch (e) {}
    setFeedback('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        open={open}
        onClose={onClose}
        onConfirm={() => {}}
        title={title ?? trans('base.feedback')}
        confirmLabel={confirmText ?? trans('base.submit_feedback')}
        cancelLabel={cancelText ?? trans('base.close')}
        confirmProps={{type: 'submit'}}
        showCloseButton
      >
        <Section>
          {description && <Typography>{description}</Typography>}
          <TextArea
            rows={4}
            placeholder={label ?? trans('base.your_feedback')}
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </Section>
      </Dialog>
    </form>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {DatePickerContext} from '../DatePickerProvider';
import {addDays} from '../utils';

interface IDaysProps {
  start: number;
}

const Days = ({start}: IDaysProps): JSX.Element => {
  const {selectedDates, changeSelectedDate, showSelectedDate, getFormattedDate, options, selectedMonth, selectedYear} =
    useContext(DatePickerContext);

  const startOfWeek = (new Date(start).getDay() + 6) % 7;
  const weekDays = options.weekDays ?? [];
  const sortedWeekDays = weekDays.slice(startOfWeek).concat(weekDays.slice(0, startOfWeek));

  const isSelected = (current: number): boolean =>
    selectedDates.some((date: Date) => getFormattedDate(date) === getFormattedDate(current));

  const isToday = (current: number): boolean => new Date(current).toDateString() === new Date().toDateString();

  return (
    <>
      <div className="grid grid-cols-7 mb-1">
        {sortedWeekDays.map(day => (
          <span key={day} className="h-6 text-sm font-bold leading-6 text-center text-grey500 dow">
            {day}
          </span>
        ))}
      </div>
      <div className="grid w-64 grid-cols-7 gap-1">
        {[...Array(42)].map((_date, index) => {
          const current = addDays(start, index);
          const day = parseInt(getFormattedDate(current, {day: 'numeric'}), 10);
          const month = parseInt(getFormattedDate(current, {month: 'numeric'}), 10);
          const year = parseInt(getFormattedDate(current, {year: 'numeric'}), 10);
          const classNames = {
            currentMonth:
              month === selectedMonth && year === selectedYear
                ? twMerge('text-prussian700 cl-currentMonth', options?.theme?.text)
                : 'text-prussian100',
            today: isToday(current) ? twMerge('bg-grey100 cl-today', options?.theme?.today) : '',
            selected:
              showSelectedDate && isSelected(current)
                ? twMerge('bg-blue700 text-white hover:bg-blue500 cl-selected', options?.theme?.selected)
                : '',
            minDate:
              (options?.minDate && new Date(current) < options?.minDate) ??
              (options?.disabledDates && options.disabledDates.includes(new Date(current)))
                ? twMerge('text-grey500 cl-minDate', options?.theme?.disabledText)
                : '',
            maxDate:
              (options?.maxDate && new Date(current) > options?.maxDate) ??
              (options?.disabledDates && options.disabledDates.includes(new Date(current)))
                ? twMerge('text-grey500 cl-maxDate', options?.theme?.disabledText)
                : '',
          };
          return (
            <span
              key={index}
              className={twMerge(
                'hover:bg-grey100 block flex-1 border-0 rounded-lg cursor-pointer text-sm h-4 flex items-center justify-center',
                classNames.currentMonth,
                classNames.today,
                classNames.selected,
                classNames.minDate,
                classNames.maxDate
              )}
              onClick={() => {
                console.log('=> changeSelectedDate', current, new Date(current));
                changeSelectedDate(new Date(current));
              }}
            >
              {day}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default Days;

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';
import {INSTITUTION_DATA} from './paths';

async function getInstitutionData(loadingIndicator = true): Promise<any> {
  return await httpClient.get(INSTITUTION_DATA, loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {});
}

export {getInstitutionData};

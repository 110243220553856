// @ts-nocheck
/* eslint-disable */

export function isString(variable: unknown): boolean {
  return typeof variable === 'string' || variable instanceof String;
}

export function isArray(variable: unknown): boolean {
  return variable instanceof Array || Array.isArray(variable);
}

export function isObject(variable: unknown): boolean {
  return variable instanceof Object;
}

export function isObjectField(item: unknown): boolean {
  return !!(item && typeof item === 'object' && !Array.isArray(item));
}

export function formatFloatString(str: string): string {
  if (!str) return str;
  const num = parseFloat(str);

  // Check if the parsed number is a float
  if (Number.isInteger(num)) {
    return num.toString(); // Return the number as a string without decimal places
  }
  return num.toFixed(2); // Return the number with up to 2 decimal places
}

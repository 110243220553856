// @ts-nocheck
/* eslint-disable */

import React from 'react';
import './ProfileLockedExercisesItem.scss';
import {Section} from '@modules/Core/components/layout/Section';
import ToInitialSurveyButton from '@modules/Profile/components/services/ToInitialSurveyButton';
import {getLockedImageIllustration} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  view?: 'overlay' | 'card';
  initialSurveyDone?: boolean;
}
const langProps = {
  initialNotDone: {
    title: 'profile_modules.locked_exercises_initial_survey_title',
    description: 'profile_modules.locked_exercises_initial_survey_description',
  },
  featureLocked: {
    title: 'profile_modules.locked_exercises_feature_locked_title',
    description: 'profile_modules.locked_exercises_feature_locked_description',
  },
};

export const ProfileLockedExercisesItem: React.FC<_Props> = ({initialSurveyDone, view = 'overlay'}) => {
  const language = initialSurveyDone ? 'featureLocked' : 'initialNotDone';

  const {title} = langProps[language];
  const {description} = langProps[language];
  const buttonComponent = initialSurveyDone ? null : <ToInitialSurveyButton />;

  const content = (
    <>
      <img src={getLockedImageIllustration()} className="svg-inject img-fluid w-[205px]" alt="" />
      <div
        style={{marginLeft: view === 'overlay' ? '' : '24px'}}
        className={`flex flex-col justify-${view === 'overlay' ? 'center' : 'start'} items-${view === 'overlay' ? 'center' : 'start'}`}
      >
        <h6 className="no-exercise-title">{trans(title)}</h6>

        <p
          style={{textAlign: view === 'overlay' ? 'center' : 'start', width: view === 'overlay' ? '897px' : 'auto'}}
          className="no-exercise-subtitle"
        >
          <div dangerouslySetInnerHTML={{__html: trans(description)}} />
        </p>

        {buttonComponent}
      </div>
    </>
  );

  let wrapper;

  if (view === 'overlay') {
    wrapper = <div className="flex flex-col justify-center items-center">{content}</div>;
  } else if (view === 'card') {
    return (
      <Section
        image={getLockedImageIllustration()}
        title={trans('profile_modules.locked_exercises_initial_survey_title')}
        description={trans('profile_modules.locked_exercises_initial_survey_description')}
      >
        <div className="flex flex-row justify-start">
          <div>
            <ToInitialSurveyButton />
          </div>
        </div>
      </Section>
    );
    // wrapper = (
    //   <div
    //     className="flex flex-row justify-start items-center"
    //   >
    //     {content}
    //   </div>
    // );
  }
  return wrapper;
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_PaymentMode} from '@modules/ContentEngine/components/items/payment/PaymentItem';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {IconCreditCard, IconFlow, IconTag} from '@modules/Core/components/base/Icons/Icons';
import {_TabsItem, Tabs} from '@modules/Core/components/base/Tabs';
import {hasMembershipAccess} from '@modules/Core/util/rolesCanFunctions';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentItemProps {
  initialTab?: _PaymentMode;
  currentInstitution?: _Institution | null;
  currentProfile?: _Profile | null;
  mode: _PaymentMode;
  setMode: (mode: _PaymentMode) => void;
}

interface _OrderedTabsItem extends _TabsItem {
  order: number;
}

export const PaymentTabs: React.FC<_PaymentItemProps> = ({
  mode,
  setMode,
  initialTab,
  currentInstitution,
  currentProfile,
}) => {
  const [tabs, setTabs] = useState<_TabsItem[]>([]);

  useEffect(() => {
    const newTabs: _OrderedTabsItem[] = [];
    if (currentProfile) {
      newTabs.push({
        key: 'profile',
        displayValue: trans('base.you'),
        iconElement: <Avatar image={getProfilePic(currentProfile)} />,
        order: 0,
      });

      newTabs.push({
        key: 'workshops',
        displayValue: trans('subscription.workshops_and_vouchers'),
        iconElement: <IconTag />,
        order: 2,
      });
    }

    if (currentInstitution && hasMembershipAccess(currentInstitution)) {
      newTabs.push({
        key: 'institution',
        displayValue: trans('subscription.employees_and_teams'),
        iconElement: <IconFlow />,
        order: 1,
      });

      newTabs.push({
        key: 'billing',
        displayValue: trans('subscription.billing'),
        iconElement: <IconCreditCard />,
        order: 3,
      });
    }

    // Order tabs
    newTabs.sort((a, b) => a.order - b.order);
    setTabs(newTabs as _TabsItem[]);
  }, [currentProfile, currentInstitution]);

  useEffect(() => {
    if (!['profile', 'workshops'].includes(mode) && (!currentInstitution || !hasMembershipAccess(currentInstitution))) {
      setMode('profile');
    }
  }, [mode]);

  useEffect(() => {
    if (initialTab) {
      setMode(initialTab);
    }
  }, [initialTab]);

  return <Tabs tabs={tabs} activeTab={mode} setActiveTab={setMode} />;
};

// @ts-nocheck
/* eslint-disable */

import React, {createRef, useState} from 'react';
import {Chip} from '@modules/Core/components/base/Chip';
import {IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

interface _Props {
  emails: string[];
  setEmails: (emails: string[]) => void;
  helpLabel?: string;
  consentLabel?: string;
  placeholder?: string;
  label?: string;
  errorEmails?: Record<string, any>;
}

export const Emails: React.FC<_Props> = ({
  emails,
  setEmails,
  helpLabel,
  consentLabel,
  placeholder,
  label,
  errorEmails,
}) => {
  const ref = createRef<HTMLInputElement>();
  const [emailError, setEmailError] = useState<string | null>(null);

  function hasError(email: string): boolean {
    if (Object.keys(errorEmails ?? {})?.length > 0) {
      return Object.keys(errorEmails ?? {})?.findIndex(e => e === email) > -1;
    }
    return false;
  }

  const handleAddChip = (event: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const emailString: string =
      event.type === 'paste' ? (event.clipboardData || window.clipboardData).getData('text') : event.target.value;
    const emailList = emailString.split(/[\s,]+/);
    if (['paste', 'blur'].includes(event.type) || ['Enter', 'Space', ' ', ','].includes(event.key)) {
      const filteredEmails = emailList
        .map(email => {
          if (emails.includes(email)) {
            setEmailError(trans('validation.form_validation.duplicate_email'));
            return null;
          }
          const trimmedEmail = email.trim();
          if (trimmedEmail === '') {
            return null;
          }
          return checkEmail(trimmedEmail) ? trimmedEmail : null;
        })
        .filter(email => email !== null);
      if (filteredEmails.length > 0) {
        setEmails([...emails, ...filteredEmails]);
        event.target.value = '';
        setTimeout(() => {
          event.target.value = '';
        });
      }
    }
    // @Stefan: removed this as it caused the user to not be able to focus any other input.
    // ref.current?.focus();
  };

  const handleDeleteChip = (email: string) => {
    setEmails(emails => emails.filter(c => c !== email));
  };

  function checkEmail(email: string): boolean {
    if (emailRegex.test(email.trim())) {
      setEmailError('');
      return true;
    }
    setEmailError(trans('validation.form_validation.email'));
    return false;
  }

  return (
    <>
      <Section>
        <Input
          elRef={ref}
          value={null}
          label={label ?? ''}
          placeholder={placeholder ?? ''}
          onKeyDown={handleAddChip}
          onPaste={handleAddChip}
          onBlur={handleAddChip}
        />

        <div className="flex flex-wrap gap-2">
          {(emails || [])?.map(email => {
            const emailHasError = hasError(email);

            const chipComponent = (
              <Chip size="sm" key={email} label={email} icon={IconCancel} onClick={() => handleDeleteChip(email)} />
            );

            if (emailHasError) {
              return (
                <ToolTip key={`${email}_tooltip`} txt={errorEmails?.[email]?.message} plc="top">
                  {chipComponent}
                </ToolTip>
              );
            }
            return chipComponent;
          })}
        </div>
      </Section>

      {emailError && (
        <Typography variant="body" className="text-typography-danger">
          {emailError}
        </Typography>
      )}

      {consentLabel && (
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{__html: consentLabel}} />
        </Typography>
      )}
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {FC, useMemo} from 'react';
import {_ProfileValueAnimation} from '@modules/ContentEngine/components/items/profile/result/values/ProfileValuesItem';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {ValuesCard} from '@modules/Core/components/base/cards/ValueCard';
import {Draggable} from '@modules/Core/components/base/dnd/Draggable';
import {Droppable} from '@modules/Core/components/base/dnd/Droppable';
import {SortableItem} from '@modules/Core/components/base/dnd/SortableItem';
import {IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {logger} from '@modules/Core/util/Logger';
import {ValuesGem} from '@modules/Profile/components/services/result/values/ValuesGem';
import {GEMS_TYPE} from '@modules/Profile/hooks/profileValuesDnDActions';
import {_ProfileValuesEntry} from '@modules/Profile/types/service.model';
import {isCustomValue} from '@modules/Profile/util/profileValuesUtil';

interface _ProfileValuesDnDCardProps {
  value: _ProfileValuesEntry;
  source: 'all' | 'selected';
  onGemClick?: (id: string) => void;
  disabled?: boolean;
  // Delete is allowed only for custom values
  onDelete?: (id: string) => void;
  animations: _ProfileValueAnimation[];
}

export const ProfileValuesDnDCard: FC<_ProfileValuesDnDCardProps> = ({
  value,
  disabled,
  source,
  onGemClick,
  onDelete,
  animations,
}) => {
  const content = useMemo(() => {
    logger.debug('ProfileValuesDnDCard', {animations, value});
    const isCustom = isCustomValue(value);
    if (source === 'all') {
      return (
        <ValuesCard
          value={value}
          valueScoreComponent={isCustom ? <IconButton onClick={() => onDelete?.(value.id)} icon={IconTrash} /> : null}
        />
      );
    }

    return (
      <ValuesCard
        valueScoreComponent={
          <Droppable disabled={disabled} id={`${GEMS_TYPE}_${value.id}`}>
            <Draggable hasDragOverlay disabled={disabled} id={`${GEMS_TYPE}_${value.id}`} type={GEMS_TYPE}>
              <ValuesGem
                animation={animations.find(a => a.itemId === value.id)}
                points={value.points}
                onClick={onGemClick ? () => onGemClick(value.id) : undefined}
              />
            </Draggable>
          </Droppable>
        }
        value={value}
      />
    );
  }, [value, source, onGemClick, disabled, animations]);

  return (
    <SortableItem disabled={disabled} key={value.id} id={value.id}>
      {content}
    </SortableItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {generate} from '@modules/Core/components/base/helper';

interface _TextArea {
  placeholder?: string;
  value: string | null;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  defaultValue?: string;
  rows?: number;
  cols?: number;
  validationState?: 'danger' | 'warning' | 'success';
  disabled?: boolean;
  width?: string;
  readOnly?: boolean;
  dusk?: string;
  onClick?: () => void;
  required?: boolean;
  label?: string;
}

export const TextArea: React.FC<_TextArea> = ({
  placeholder,
  value,
  onChange,
  defaultValue,
  rows = 4,
  cols = 80,
  validationState,
  disabled,
  width = 'w-full',
  readOnly,
  dusk,
  onClick,
  required,
  label,
}) => {
  const [id] = useState(generate());
  let borderColor = 'border-grey300';
  if (validationState === 'danger') {
    borderColor = 'border-red700';
  }
  if (validationState === 'warning') {
    borderColor = 'border-orange700';
  }
  if (validationState === 'success') {
    borderColor = 'border-green700';
  }

  return (
    <div className={addCN('flex flex-col gap-1 w-full')}>
      {label && (
        <label htmlFor={id} className="text-body2-bold user-select-none pointer-events-none">
          {label}
          {required && <span className="required text-body2-bold">*</span>}
        </label>
      )}
      <textarea
        dusk={dusk}
        readOnly={readOnly}
        rows={rows}
        cols={cols}
        value={value ?? ''}
        defaultValue={defaultValue}
        placeholder={placeholder}
        style={{padding: '12px 16px'}}
        className={addCN(
          'textArea no-resize',
          width,
          'block transition border-1 rounded-sm',
          disabled ? 'bg-grey500' : 'bg-white500 hover:bg-grey100 focus:bg-white500',
          disabled
            ? 'text-typography-white placeholder-typography-white'
            : readOnly
              ? 'text-typography-caption placeholder-typography-caption'
              : 'text-typography-paragraph placeholder-typography-caption',
          borderColor,
          'focus:border-blue700 focus:outline-none'
        )}
        onChange={e => onChange(e)}
        onClick={onClick}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  IconSmileyExcited,
  IconSmileyHappy,
  IconSmileyNeutral,
  IconSmileySad,
  IconSmileyUpset,
} from '@modules/Core/components/base/Icons/Icons';

interface _Props {
  index: number;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const HowAreYouSmiley: React.FC<_Props> = ({size, index}) => {
  const feelingDescriptions = [IconSmileyExcited, IconSmileyHappy, IconSmileyNeutral, IconSmileySad, IconSmileyUpset];

  const Icon = feelingDescriptions[index];
  if (!Icon) return null; // Return nothing if icon is not found
  return <Icon size={size} />;
};

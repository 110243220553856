// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {OrganisationIndividualPerformanceCard} from '@modules/Statistics/components/institution/OrganisationIndividualPerformanceCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teams: Record<string, _InstitutionOverviewTeam>;
  chosenTeam: string | null;
  setChosenTeam: (team: string | null) => void;
  showPlaceholder: boolean;
}

export const OrganisationTeamsPerformanceSection: React.FC<_Props> = ({
  teams,
  chosenTeam,
  setChosenTeam,
  showPlaceholder,
}) => {
  return (
    <CustomizableSectionItem
      className="h-full"
      fullHeight
      width={6}
      title={trans('gamification.institution.teams_performance')}
      titlePrefix={
        <div className="relative-col-width-2">
          {!showPlaceholder && (
            <Select
              placeholder={trans('gamification.institution.select_team_label')}
              options={Object.entries(teams ?? {}).map(([key, team]) => ({
                label: team.name,
                value: key,
              }))}
              value={{
                value: chosenTeam,
              }}
              onChange={value => setChosenTeam(value.value)}
            />
          )}
        </div>
      }
    >
      {!showPlaceholder ? (
        <OrganisationIndividualPerformanceCard team={teams?.[chosenTeam ?? ''] ?? null} />
      ) : (
        <PlaceholderSection
          fullWidth
          direction="vertical"
          title={trans('institution_gamification.overview_individual_team_missing.title')}
          content={trans('institution_gamification.overview_individual_team_missing.description')}
          image={getDesignImage('power.png')}
        />
      )}
    </CustomizableSectionItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_AppState} from '@modules/State/types/appState.model';

export interface _FeatureFlagsState {
  flags: string[] | null;
}

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: {
    flags: null,
  } satisfies _FeatureFlagsState,
  reducers: {
    setFeatureFlags(state, action) {
      logger.info('[FeatureFlagsSlice] Updating feature flags.');
      state.flags = action.payload;
    },
  },
});

export const {setFeatureFlags} = featureFlagsSlice.actions;
export default featureFlagsSlice;

export const selectFeatureFlags = (state: _AppState): string[] => state.featureFlags.flags;

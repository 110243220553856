// @ts-nocheck
/* eslint-disable */

import {_FeatureFlags} from '@modules/FeatureFlags/types/featureFlags.model';
import {httpClient} from '@modules/Core/services/HttpClient';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {FEATURE_FLAGS} from '../paths';

const featureFlagsCache = new Map<string, _FeatureFlags>();

async function loadFeatureFlags(loadingIndicator = false): Promise<_FeatureFlags> {
  const institutionId = currentInstitution()?.id;
  if (!institutionId || !featureFlagsCache.has(institutionId)) {
    const data = await httpClient.get<_FeatureFlags>(
      FEATURE_FLAGS,
      loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {}
    );
    if (institutionId) {
      featureFlagsCache.set(institutionId, data);
    } else {
      return data;
    }
  }
  return featureFlagsCache.get(institutionId)!;
}

function clearFeatureFlagsCache() {
  featureFlagsCache.clear();
}

export {loadFeatureFlags, clearFeatureFlagsCache};

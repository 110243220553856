// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconArrowLeft, IconArrowRight} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {_DateRangeType} from '@modules/Core/util/dateUtil';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {ProfileExperienceCard} from '@modules/Statistics/components/general/ProfileExperienceCard';
import {ProfileHowAreYouMetricCard} from '@modules/Statistics/components/general/ProfileHowAreYouMetricCard';
import {OrganisationTeamsOverviewCard} from '@modules/Statistics/components/institution/OrganisationTeamsOverviewCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teams: Record<string, _InstitutionOverviewTeam>;
  showPlaceholder: boolean;

  chosenTeam: string | null;
  setChosenTeam: (value: string | null) => void;
  experienceMetric: _ProfileTrackingStatisticEntry;
  howAreYouMetric: _ProfileTrackingStatisticEntry;
  filter?: _DateRangeType;
}

const LIMIT = 4;

export const OrganisationTeamsOverviewListSection: React.FC<_Props> = ({
  teams,
  showPlaceholder,
  chosenTeam,
  setChosenTeam,
  experienceMetric,
  howAreYouMetric,
  filter,
}) => {
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    if (!chosenTeam) {
      setChosenTeam(Object.keys(teams ?? {})?.[0] ?? null);
    }
  }, [teams]);

  const hasNext = Object.keys(teams ?? {}).length > startIndex + LIMIT;
  const hasPrev = startIndex > 0;

  const onChange = (value: string) => {
    setChosenTeam(value);
  };

  return (
    <CustomizableSectionItem
      className="h-full"
      fullHeight
      width={6}
      title={trans('gamification.institution.teams_overview')}
    >
      {showPlaceholder ? (
        <PlaceholderSection
          fullWidth
          direction="vertical"
          title={trans('institution_gamification.overview_teams_missing.title')}
          content={trans('institution_gamification.overview_teams_missing.description')}
          image={getDesignImage('team_placeholder.png')}
        />
      ) : (
        <div className="flex flex-col gap-3 justify-between h-full">
          <div className="flex flex-col gap-3 flex-grow">
            <div className="flex flex-row w-full items-center justify-between">
              <Typography>
                {trans('gamification.time_period_overview', {
                  // Also used in 3 Dashboards flow.json
                  period: trans(`dashboard.filter.${filter}`),
                })}
              </Typography>
              <div className="flex flex-row flex-grow items-center justify-end gap-3">
                {!!howAreYouMetric && (
                  <ProfileHowAreYouMetricCard
                    label={trans('gamification.institution_emotion')}
                    metric={howAreYouMetric}
                  />
                )}
                {!!experienceMetric && (
                  <ProfileExperienceCard
                    titleVariant="h6"
                    fullWidth
                    label="gamification.institution_experience_short"
                    experiencePoints={experienceMetric.sum}
                  />
                )}
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-3">
                {Object.entries(teams ?? {})
                  .slice(startIndex, startIndex + LIMIT)
                  .map(([key, team]) => {
                    return (
                      <div className="w-full" key={key}>
                        <OrganisationTeamsOverviewCard onClick={() => onChange(key)} team={team} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {(hasPrev || hasNext) && (
            <div className="flex flex-row items-center justify-center w-full gap-3">
              <IconButton disabled={!hasPrev} icon={IconArrowLeft} onClick={() => setStartIndex(startIndex - LIMIT)} />
              <IconButton disabled={!hasNext} icon={IconArrowRight} onClick={() => setStartIndex(startIndex + LIMIT)} />
            </div>
          )}
        </div>
      )}
    </CustomizableSectionItem>
  );
};

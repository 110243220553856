// @ts-nocheck
/* eslint-disable */

import React, {useMemo, useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {ProfileSharedResultsHeader} from '@modules/Profile/components/services/sharedResults/ProfileSharedResultsHeader';
import {ProfileSharedResultsMissing} from '@modules/Profile/components/services/sharedResults/ProfileSharedResultsMissing';
import {ProfileSharedResultsWithOthersTable} from '@modules/Profile/components/services/sharedResults/ProfileSharedResultsWithOthersTable';
import {ProfileSharedResultsWithYouTable} from '@modules/Profile/components/services/sharedResults/ProfileSharedResultsWithYouTable';
import {_ProfileSharedResult} from '@modules/Profile/types/shared-results.model';

export const SHARED_WITH_YOU = 'sharedWithYou';
export const SHARED_WITH_OTHERS = 'sharedWithOthers';
export type SHARED_WITH_YOU_TYPE = typeof SHARED_WITH_YOU;
export type SHARED_WITH_OTHERS_TYPE = typeof SHARED_WITH_OTHERS;

interface _ProfileSharedResultsItemProps {
  initialTab?: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE;
  sharedWithYou: _ProfileSharedResult[];
  sharedWithOthers: _ProfileSharedResult[];
}

export const ProfileSharedResultsItem: React.FC<_ProfileSharedResultsItemProps> = ({
  initialTab,
  sharedWithYou,
  sharedWithOthers,
}) => {
  // Component state
  const [currentTab, setCurrentTab] = useState<SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE>(
    [SHARED_WITH_OTHERS, SHARED_WITH_YOU].includes(initialTab) ? initialTab : SHARED_WITH_YOU
  );

  const data = useMemo(
    () => (currentTab === SHARED_WITH_YOU ? sharedWithYou : sharedWithOthers),
    [currentTab, sharedWithYou, sharedWithOthers]
  );

  logger.debug('ProfileSharedResultsItem', {
    currentTab,
    sharedWithYou,
    sharedWithOthers,
    data,
    initialTab,
  });
  return (
    <Section>
      <ProfileSharedResultsHeader setCurrentTab={setCurrentTab} currentTab={currentTab} />
      {data?.length ? (
        currentTab === SHARED_WITH_YOU ? (
          <ProfileSharedResultsWithYouTable data={data} />
        ) : (
          <ProfileSharedResultsWithOthersTable data={data} />
        )
      ) : (
        <ProfileSharedResultsMissing currentTab={currentTab} />
      )}
    </Section>
  );
};

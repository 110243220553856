// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import LottieWrapper from '@modules/Core/components/wrappers/LottieWrapper';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';
import {trans} from '@modules/Translations/util/i18n';

interface _SurveyIntroPageProps {
  survey: any;
  question: _SurveyQuestion;
  hasMorePages: () => boolean;
  progress: number;
  parseQuestion: (question: string) => string;
  isLastQuestion: () => boolean;
  navigationButton: React.ReactNode;
  nextClicked: () => void;
}

export const SurveyIntroPage: React.FC<_SurveyIntroPageProps> = ({
  survey,
  question,
  hasMorePages,
  progress,
  parseQuestion,
  isLastQuestion,
  navigationButton,
  nextClicked,
}) => {
  function audio(): React.ReactNode {
    if (!question?.audio) {
      return null;
    }

    return (
      <div className="my-4">
        <audio controls>
          <source src={question?.audio} type="audio/mpeg" />
          {trans('base.no_audio_support')}
        </audio>
      </div>
    );
  }

  if (question?.type === 'seperator') {
    return null;
  }

  if (isLastQuestion()) {
    return <LastQuestionIntro />;
  }

  return (
    <>
      {hasMorePages() && <ProgressBar value={progress} />}
      <SurveyTitle title={parseQuestion(question?.title)} tooltip={question?.tooltip} titleVariant="body1" />
      {audio()}
      <ImageDescriptionComponent question={question} />
    </>
  );
};

interface _ImageDescriptionComponentProps {
  question: _SurveyQuestion;
}

const ImageDescriptionComponent: React.FC<_ImageDescriptionComponentProps> = ({question}) => {
  if (!question?.description && !question?.image) {
    return null;
  }

  let image;

  if (question?.image) {
    image = question?.image?.includes('://')
      ? question?.image
      : `/assets/img/illustrations_v2/surveys/${question?.image}.svg`;
  }

  return (
    <Section image={image}>
      <div
        className={`flex flex-col ${question?.image ? 'items-start' : 'items-center'} justify-center flex-grow gap-3`}
      >
        {question?.description && <Description question={question} />}
      </div>
    </Section>
  );
};

interface _ImageProps {
  question: _SurveyQuestion;
}

const Image: React.FC<_ImageProps> = ({question}) => {
  if (!question?.image) return null;

  if (!question?.image.includes('://')) {
    return (
      <img
        src={`/assets/img/illustrations_v2/surveys/${question?.image}.svg`}
        alt={question?.title}
        className="img-fluid m-3"
        style={{width: 300}}
      />
    );
  }

  return <img src={question?.image} alt={question?.title} className="img-fluid m-3" style={{maxHeight: '200px'}} />;
};

const LastQuestionIntro: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <div className="w-[400px] h-[400px]">
        <LottieWrapper lottieKey="survey chart animation" />
      </div>
      <p className="text-body1">{trans('profile_result.last_question.title')}</p>
      <p className="text-body">{trans('profile_result.last_question.message')}</p>
    </div>
  );
};

interface _DescriptionProps {
  question: _SurveyQuestion;
}

const Description: React.FC<_DescriptionProps> = ({question}) => {
  if (!question?.description) return null;

  return (
    <Typography
      variant="body1"
      style={{whiteSpace: 'pre-wrap'}}
      className={`${question?.type === 'radio' ? 'text-center' : 'text-left'}`}
      dangerouslySetInnerHTML={{__html: question?.description}}
    />
  );
};

interface _SurveyTitleProps {
  title: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body' | 'body2';
  tooltip?: string;
  withImage?: boolean;
}

const SurveyTitle: React.FC<_SurveyTitleProps> = ({withImage = false, title, titleVariant, tooltip}) => {
  return (
    <Section className="min-h-8 justify-center">
      <Typography
        variant={titleVariant}
        className={twMerge('text-typography-subheadings', withImage ? 'text-left' : 'text-center')}
      >
        {title}
        {tooltip && (
          <ToolTip html={tooltip} className="ml-1 mb-[-3px]">
            <IconInfo size="md" />
          </ToolTip>
        )}
      </Typography>
    </Section>
  );
};

SurveyTitle.defaultProps = {
  tooltip: undefined,
  titleVariant: 'h5',
};

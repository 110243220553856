// @ts-nocheck
/* eslint-disable */

// Type definition for the options parameter
interface LoggerOptions {
  level?: string;
}

const NO_OP: (...args: any[]) => void = () => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger {
  readonly debug: (...args: any[]) => void;

  readonly info: (...args: any[]) => void;

  readonly log: (...args: any[]) => void;

  readonly warn: (...args: any[]) => void;

  readonly error: (...args: any[]) => void;

  constructor(options?: LoggerOptions) {
    const {level} = options ?? {};

    const originalConsoleLoggers = {
      // eslint-disable-next-line no-console
      debug: console.debug.bind(console),
      // eslint-disable-next-line no-console
      info: console.info.bind(console),
      // eslint-disable-next-line no-console
      log: console.log.bind(console),
      // eslint-disable-next-line no-console
      warn: console.warn.bind(console),
      // eslint-disable-next-line no-console
      error: console.error.bind(console),
    };

    // no op fallbacks
    this.debug = NO_OP;
    this.info = NO_OP;
    this.log = NO_OP;
    this.warn = NO_OP;
    this.error = NO_OP;

    const ignoreList = [
      'Support for defaultProps will be removed',
      'A non-serializable value was detected in',
      'Each child in a list should have a unique',
    ];
    const isIgnored = (args: any[]): boolean => {
      for (const arg of args) {
        if (typeof arg === 'string' && ignoreList.some(warning => arg.includes(warning))) {
          return true;
        }
      }
      return false;
    };

    this.error = (...args) => {
      if (!isIgnored(args)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        originalConsoleLoggers.error(...args);
      }
    };
    // eslint-disable-next-line no-console
    console.error = this.error.bind(this);

    if (level === 'error') {
      return;
    }

    this.warn = (...args) => {
      if (!isIgnored(args)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        originalConsoleLoggers.warn(...args);
      }
    };
    // eslint-disable-next-line no-console
    console.warn = this.warn.bind(this);

    if (level === 'warn') {
      return;
    }

    this.log = originalConsoleLoggers.log.bind(originalConsoleLoggers);
    // eslint-disable-next-line no-console
    console.log = this.log.bind(this);

    this.debug = originalConsoleLoggers.debug.bind(originalConsoleLoggers);
    // eslint-disable-next-line no-console
    console.debug = this.debug.bind(this);

    this.info = originalConsoleLoggers.info.bind(originalConsoleLoggers);
    // eslint-disable-next-line no-console
    console.info = this.info.bind(this);
  }
}

export const logger = new ConsoleLogger({
  level: process.env.NEXT_PUBLIC_LOG_LEVEL,
});

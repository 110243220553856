// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {Typography} from '@modules/Core/components/base/Typography';

export interface _ActionsTableHeaderProps {
  selectedRowsIds: string[];
  // If not provided, the clear selection button will not be shown
  onClearSelectedRows?: () => void;
  // If not provided, the selected rows count label will not be shown
  selectedRowsLabel?: (count: number) => string;
  resetSelectionLabel?: string;

  actions: _ActionsTableHeaderSelectValue[];
  actionsLabel: string;
}

export interface _ActionsTableHeaderSelectValue extends _SelectValue {
  action: () => void;
}

export const ActionsTableHeader: React.FC<_ActionsTableHeaderProps> = ({
  selectedRowsIds,
  onClearSelectedRows = null,
  actions,
  actionsLabel,
  selectedRowsLabel = null,
  resetSelectionLabel,
}) => {
  const onActionChange = (value: _ActionsTableHeaderSelectValue): void => {
    value.action();
  };

  if (!actions || !selectedRowsIds?.length) {
    return null;
  }
  return (
    <div className="flex flex-row gap-3 items-center">
      <div className="flex-grow">
        <Select
          options={actions}
          onChange={onActionChange}
          value={{
            value: null,
            label: actionsLabel,
          }}
          placeholder={actionsLabel}
        />
      </div>

      {selectedRowsLabel && (
        <div className="flex-grow">
          <Typography>{selectedRowsLabel(selectedRowsIds?.length)}</Typography>
        </div>
      )}
      {onClearSelectedRows && (
        <div className="flex flex-row flex-grow justify-end">
          <div>
            <Button onClick={() => onClearSelectedRows()} variant="secondary" icon={IconCancel} iconPosition="right">
              {resetSelectionLabel}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useMemo, useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {RegisterLoginTabs} from '@modules/Core/components/platform/login/RegisterLoginTabs';
import {trans} from '@modules/Translations/util/i18n';

interface _Tab {
  key: string;
  displayValue: string;
}

export interface _RegisterLoginWrapperProps {
  activeTab: 'login' | 'register';
  onTabChange: (tab: 'login' | 'register') => void;
  showLoginTab?: boolean;
  showRegisterTab?: boolean;
  showLost: boolean;
  children: React.ReactNode;
}

export const RegisterLoginWrapper: React.FC<_RegisterLoginWrapperProps> = ({
  showRegisterTab = true,
  showLoginTab = true,
  onTabChange,
  activeTab,
  showLost,
  children,
  registrationDone,
}) => {
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    if (activeTab === 'register') {
      setTitle(registrationDone ? trans('register.confirm_email_new') : trans('base.welcome_to_softfact_register'));
      setSubtitle(registrationDone ? trans('register.confirm_email') : trans('login.register_subtitle'));
      setImage('/assets/img/design_images/high five.png');
    } else {
      setTitle(showLost ? trans('passwords.reset-headline') : trans('base.welcome_to_softfact_login'));
      setSubtitle(showLost ? trans('login.forget_password_subtitle') : trans('login.login_subtitle'));
      setImage(showLost ? '/assets/img/design_images/request password.png' : '/teamimage.png');
    }
  }, [trans, activeTab, showLost, registrationDone]);

  return (
    <Section image={image} imageHeight="h-[348px]" fullWidth gap={8}>
      <RegisterLoginTabs
        activeTab={activeTab}
        setActiveTab={onTabChange}
        showLoginTab={showLoginTab}
        showRegisterTab={showRegisterTab}
      />
      <Section title={title} description={subtitle} descriptionVariant="body1" gap={5}>
        {children}
      </Section>
    </Section>
  );
};

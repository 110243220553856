// @ts-nocheck
/* eslint-disable */

import {
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';

export const EXERCISES_SUPPORTED_FACETS = [
  'ownership',
  'leadership',
  'flexibility',
  'strength',
  'arousal',
  'persistence',
  'security_of_competence',
  'conviction_of_effect',
  'pause',
  'awareness',
  'honesty',
  'openness',
  'understanding',
  'integration',
  'structure',
  'style',
  'vision',
  'change_intention',
  'learning_ability',
  'learning_motivation',
];

export const statusLangProps: Record<string, string> = {
  [`${EXERCISE_STATUS_OPEN}`]: 'profile_modules.status_actions.ready',
  [`${EXERCISE_STATUS_STARTED}`]: 'profile_modules.status_actions.in_progress',
  [`${EXERCISE_STATUS_DONE}`]: 'profile_modules.status_actions.done',
};

// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Typography} from '@modules/Core/components/base/Typography';
import {IconButton} from './buttons/IconButton';
import {ExpandableContent} from './ExpandableContent';
import {IconZoomIn} from './Icons/Icons';
import {ToolTip} from './ToolTip';

interface _IndicatorProps {}

interface _Slider {
  title: string;
  subtitle?: string;
  bottomTitle?: string;
  bottomSubtitle?: string;
  leftText: string;
  leftTooltip?: string;
  rightTooltip?: string;
  rightText: string;
  rightIcon?: React.ElementType;
  leftIcon?: React.ElementType;
  components: Array<{id: string; progress: number; component: ReactElement; indicatorColor?: string}>;
  showExpand?: boolean;
  enableEndicator?: boolean;
  onTitleActionClick?: () => void;
  onIndicatorClick?: (id: string) => void;
}

export const Slider: React.FC<_Slider> = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    onTitleActionClick,
    title,
    subtitle,
    bottomTitle,
    bottomSubtitle,
    leftText,
    leftTooltip,
    rightTooltip,
    rightText,
    components,
    showExpand,
    enableEndicator,
    onIndicatorClick,
  } = props;

  return (
    <div className="flex flex-col items-start gap-5 w-full">
      {/* top text area */}

      <div className="flex w-full flex-col items-start gap-2">
        <div className="flex flex-row justify-between w-full">
          <Typography variant="h6">{title}</Typography>
          {onTitleActionClick && (
            <div>
              <IconButton onClick={onTitleActionClick} icon={IconZoomIn} />
            </div>
          )}
        </div>
        {subtitle ? <span className="">{subtitle}</span> : null}
      </div>

      <div className="sliderWrapper flex flex-col items-center gap-3 w-full">
        <div className="slider flex items-center gap-3 w-full">
          <div
            className={addCN(
              'sliderContent flex items-center justify-between gap-3 w-full',
              enableEndicator ? 'pb-5' : ''
            )}
          >
            <div className="flex justify-between items-center relative-col-width-1">
              <span className="text-body2">{leftText}</span>

              {props?.leftIcon ? (
                <ToolTip plc="top-right" txt={leftTooltip}>
                  <props.leftIcon />
                </ToolTip>
              ) : null}
            </div>

            <div className="relative relative-col-width-8">
              {/* track */}
              <div className="w-full h-2 bg-grey100 rounded-lg flex items-center justify-start" />
              {components.map(comp => (
                <CustomSlider
                  onIndicatorClick={onIndicatorClick ? () => onIndicatorClick?.(comp.id) : null}
                  key={comp.id}
                  progress={comp.progress}
                  component={comp.component}
                  color={comp.indicatorColor}
                  enableIndicator={enableEndicator}
                />
              ))}
            </div>

            <div className="flex justify-end items-center gap-1 relative-col-width-1">
              {props?.rightIcon ? (
                <ToolTip plc="top-left" txt={rightTooltip}>
                  <props.rightIcon />
                </ToolTip>
              ) : null}
              <span className="text-body2">{rightText}</span>
            </div>
          </div>
        </div>

        {showExpand && (
          <ExpandableContent>
            <div className="flex flex-col gap-2 relative-col-width-8 relative-col-start-2">
              {bottomTitle ? <p className="text-body1 text-typography-subheadings">{bottomTitle}</p> : null}
              {bottomSubtitle ? <p className="text-body">{bottomSubtitle}</p> : null}
            </div>
          </ExpandableContent>
        )}
      </div>
    </div>
  );
};

interface _CustomSlider {
  progress: number;
  component: ReactElement;
  color?: string;
  enableIndicator?: boolean;
  onIndicatorClick?: () => void;
}

const CustomSlider: React.FC<_CustomSlider> = ({onIndicatorClick, color, progress, component, enableIndicator}) => (
  <div
    className="absolute z-0 top-[2px]"
    style={{top: 0, transform: 'translate(-25%, -25%)', left: `calc(${progress}% - 12px)`}}
  >
    <div className="flex flex-col items-center">
      {enableIndicator ? (
        <div
          onClick={onIndicatorClick}
          className={addCN('h-6 w-0', color ?? ' bg-green700', onIndicatorClick && 'cursor-pointer')}
        />
      ) : null}

      {component}
    </div>
  </div>
);

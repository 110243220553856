// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_PaymentMode} from '@modules/ContentEngine/components/items/payment/PaymentItem';
import {Section} from '@modules/Core/components/layout/Section';
import {BillingAddress} from '@modules/Core/components/platform/payments/billing/BillingAddress';
import {BillingInformation} from '@modules/Core/components/platform/payments/billing/BillingInformation';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Subscription} from '@modules/Profile/types/memberships.model';

interface _PaymentBillingProps {
  institution?: _Institution | null;
  goToTab?: (tab: _PaymentMode) => void;
}

export const PaymentBilling: React.FC<_PaymentBillingProps> = ({institution, goToTab}) => {
  if (!institution) {
    return null;
  }

  const onGoToSubscriptions = (sub?: _Subscription | null): void => {
    goToTab?.('institution');
  };
  return (
    <Section>
      <BillingAddress institution={institution} />
      <BillingInformation institution={institution} onGoToSubscriptions={onGoToSubscriptions} />
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

// Importing necessary functions from Redux Toolkit
import {createSlice} from '@reduxjs/toolkit';
// Importing the logger for logging purposes
import {_LayoutOptions, _UIAction} from '@modules/Core/types/pageLayout.model';
import {generate} from '../components/base/helper';
import {logger} from '../util/Logger';

// Interface for the state of PageLayout
export interface _PageLayoutState {
  title: string | null;
  subTitle: string | null;
  actions: _UIAction[];
  softwareReleaseKey: string | null;
  options: _LayoutOptions;
}

// Creating the PageLayout slice with initial title and reducers to manage it
const pageLayoutSlice = createSlice({
  name: 'pageLayout',
  initialState: {
    title: null,
    subTitle: null,
    actions: [],
  } satisfies _PageLayoutState,
  reducers: {
    // Action to set the page title
    setTitle(state: _PageLayoutState, action) {
      logger.debug('[PageLayout] setTitle');
      state.title = action.payload;
    },
    // Action to clear the page title
    clearTitle(state: _PageLayoutState) {
      logger.debug('[PageLayout] clearTitle');
      state.title = '';
    },
    setActions(state: _PageLayoutState, action) {
      logger.debug('[PageLayout] setActions');
      // add uuid to each action
      action.payload.forEach(action => {
        action.uuid = generate();
      });
      state.actions = action.payload;
    },
    clearActions(state: _PageLayoutState) {
      logger.debug('[PageLayout] clearActions');
      state.actions = [];
    },
    setSubTitle(state: _PageLayoutState, action) {
      logger.debug('[PageLayout] setSubTitle');
      state.subTitle = action.payload;
    },
    clearSubTitle(state: _PageLayoutState) {
      logger.debug('[PageLayout] clearSubTitle');
      state.subTitle = '';
    },
    setSoftwareReleaseKey(state: _PageLayoutState, action) {
      logger.debug('[PageLayout] setShowSoftwareUpdate');
      state.softwareReleaseKey = action.payload;
    },
    clearSoftwareReleaseKey(state: _PageLayoutState) {
      logger.debug('[PageLayout] clearSoftwareReleaseKey');
      state.softwareReleaseKey = '';
    },
    setOptions(state: _PageLayoutState, action) {
      logger.debug('[PageLayout] setOptions');
      state.options = action.payload;
    },
    clearOptions(state: _PageLayoutState) {
      logger.debug('[PageLayout] clearOptions');
      state.options = {};
    },
  },
});

// Exporting the actions for use in components
export const {
  clearSoftwareReleaseKey,
  setSoftwareReleaseKey,
  setTitle,
  clearTitle,
  setActions,
  clearActions,
  setSubTitle,
  clearSubTitle,
  setOptions,
  clearOptions,
} = pageLayoutSlice.actions;

// Default export of the slice
export default pageLayoutSlice;

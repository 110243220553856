// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../HttpClient';
import {COUNTRY_LIST} from '../paths';

let countryListCache: any = null;

async function getCountryList(): Promise<any> {
  if (!countryListCache) {
    countryListCache = await httpClient.get(COUNTRY_LIST);
  }
  return countryListCache;
}

export {getCountryList};

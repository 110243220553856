// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentResultItemProps {
  hasMissingMemberships: boolean;
  surveyDone: number;
  totalMembers: number;
  missing: number;
  onStart?: () => void;
}

export const TeamDevelopmentAdminWaitItem: React.FC<_TeamDevelopmentResultItemProps> = ({
  surveyDone,
  totalMembers,
  missing,
  hasMissingMemberships,
  onStart,
}) => {
  return (
    <Section
      title={trans('development_result.wait_for_team_members_title')}
      description={
        hasMissingMemberships
          ? trans('development_result.potential_analysis_description_missing_memberships', {
              surveyDone,
              totalMembers,
              missing,
            })
          : trans('development_result.potential_analysis_description', {
              surveyDone,
              totalMembers,
            })
      }
      image="/assets/img/illustrations_v2/patience.png"
      actions={[
        {
          label: trans('base.start_now_anyway', {surveyDone}),
          width: 'relative-col-width-3',
          onClick: onStart,
          isInnerAction: true,
          type: 'button',
        },
      ]}
    />
  );
};

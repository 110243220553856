// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {ProfileServiceInformation} from '@modules/Profile/components/services/serviceInformation/ProfileServiceInformation';
import {TeamServiceInformation} from '@modules/Team/components/services/TeamServiceInformation';
import {BaseStep} from '../Base/BaseStep';

export const ServiceInformationStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [servicePrepared, setServicePrepared] = useState(false);

  useEffect(() => {
    logger.info('[ServiceInformationStep] useEffect servicePrepared', servicePrepared);
    if (servicePrepared) {
      options.onComplete();
    }
  }, [servicePrepared]);

  const onComplete = useCallback(() => {
    setServicePrepared(true);
  }, [setServicePrepared]);

  const isTeamService = !!options?.teamId;

  return (
    <BaseStep options={options}>
      {isTeamService ? (
        <TeamServiceInformation
          service={options?.service}
          teamId={options?.teamId}
          labelNextButton={options?.labelNextButton}
          isCoach={options?.isCoach}
          onNextClicked={() => onComplete()}
        />
      ) : (
        <ProfileServiceInformation
          service={options?.service}
          labelNextButton={options?.labelNextButton}
          isCoach={options?.isCoach}
          onNextClicked={() => onComplete()}
        />
      )}
    </BaseStep>
  );
};

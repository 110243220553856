// @ts-nocheck
/* eslint-disable */

import {Axios} from 'axios';
import {getStore} from '@modules/State/util/util';
import {DEFAULT_LANGUAGE} from '@modules/Translations/config/constants';

const LANGUAGE_HEADER_KEY = 'Language';
const languageEnricherInterceptor = (axiosInstance: Axios): void => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers[LANGUAGE_HEADER_KEY] = getStore()?.getState()?.i18n?.lang || DEFAULT_LANGUAGE;
      return config;
    },
    () => {}
  );
};
export default languageEnricherInterceptor;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCommunication, IconList, IconScrollable, IconTeam} from '@modules/Core/components/base/Icons/Icons';
import {_TableFilteringOptions, TableFilter} from '@modules/Core/components/base/table/TableFilter';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {SERVICE_TEAM_DEVELOPMENT} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamDevelopmentProfileAnswers} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  answers: _TeamDevelopmentProfileAnswers;
  module: string;
  teamId: string;
  profiles: Record<string, _TeamProfile>;
  view: 'list' | 'carousel';
  setView: (view: 'list' | 'carousel') => void;
  onFilter: (filteredProfiles: Record<string, _TeamProfile>) => void;
  showOnlyAnswered: boolean;
  setShowOnlyAnswered: (showOnlyAnswered: boolean) => void;
  context: _ContentEngineContext;
  showList: boolean;
  tutorialKey: string;
}

const filterOptions: _TableFilteringOptions = {
  enabled: true,
  searchAttributes: ['fullName', 'email', 'first_name', 'last_name'],
};

export const TeamDevelopmentResultTeamAnswersHeader: React.FC<_Props> = ({
  view,
  setView,
  profiles,
  teamId,
  module,
  answers,
  onFilter,
  context,
  setShowOnlyAnswered,
  showOnlyAnswered,
  showList,
  tutorialKey,
}) => {
  const onFilterChange = (filteredProfiles: _TeamProfile[]) => {
    const filteredProfilesMap = filteredProfiles.reduce<Record<string, _TeamProfile>>((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {});
    logger.debug('filteredProfilesMap', filteredProfilesMap);
    onFilter(filteredProfilesMap);
  };

  const onViewChange = (newView: 'list' | 'carousel') => {
    setView(newView);
  };

  /**
   answeredProfiles={result.moduleAnsweredProfiles}
   * <TeamSectionItem
   *         title={trans('development_result.result')}
   *         notificationType="TD"
   *         teamId={teamId}
   *         allProfiles={allProfiles}
   *         answeredProfiles={result.moduleAnsweredProfiles}
   *         context={context}
   *         journalKey={`team_development.${module}`}
   *         tutorialKey={tutorialKey}
   *         tutorialService={`${SERVICE_TEAM_DEVELOPMENT}:result:overview`}
   *       >
   */
  return (
    <TeamSectionItem
      tutorialService={`${SERVICE_TEAM_DEVELOPMENT}:result:teamAnswers`}
      tutorialKey={tutorialKey}
      teamId={teamId}
      context={context}
      journalKey={`team_development.${module}`}
      journalMetadata={{
        teamId,
      }}
      titleAsElement
      title={
        <div className="relative-col-width-6">
          <TableFilter data={Object.values(profiles)} setFilteredData={onFilterChange} options={filterOptions} />
        </div>
      }
      journalSuffix={
        <div className="flex flex-row justify-end items-center gap-3">
          {showList && (
            <>
              <ToolTip txt={trans('development_result.team_answers.mode_1')}>
                <IconButton
                  active={view === 'carousel'}
                  onClick={() => onViewChange('carousel')}
                  icon={IconScrollable}
                />
              </ToolTip>
              <ToolTip txt={trans('development_result.team_answers.mode_2')}>
                <IconButton active={view === 'list'} onClick={() => onViewChange('list')} icon={IconList} />
              </ToolTip>
            </>
          )}

          <ToolTip txt={trans('development_result.team_answers.show_all')}>
            <IconButton active={!showOnlyAnswered} onClick={() => setShowOnlyAnswered(false)} icon={IconTeam} />
          </ToolTip>
          <ToolTip txt={trans('development_result.team_answers.show_answers')}>
            <IconButton active={showOnlyAnswered} onClick={() => setShowOnlyAnswered(true)} icon={IconCommunication} />
          </ToolTip>
        </div>
      }
    >
      <div className="flex flex-row" />
    </TeamSectionItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {_ShortTeam, _Team} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileTeamsTableViewProps {
  renderTeam: (team: any) => JSX.Element;
  teams: _Team[] | _ShortTeam[];
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const ProfileTeamsTableView: React.FC<_ProfileTeamsTableViewProps> = ({
  open,
  onOpen,
  onClose,
  renderTeam,
  teams,
}) => {
  return (
    <>
      <div
        className="flex flex-col"
        onClick={e => {
          e.stopPropagation();
          if (teams?.length === 0) {
            return;
          }
          onOpen?.();
        }}
      >
        {(teams || [])?.length === 0 && '-'}
        {(teams || [])?.length === 1 && (
          <Typography className="cursor-pointer">1 {trans('organisation.team')}</Typography>
        )}
        {(teams || [])?.length > 1 && (
          <Typography className="cursor-pointer">
            {(teams || [])?.length} {} {trans('organisation.teams')}
          </Typography>
        )}
      </div>

      <Dialog
        size="sm"
        open={open}
        onClose={() => {
          onClose?.();
        }}
        showCloseButton
        title={trans('organisation.teams_overview')}
      >
        <div className="flex flex-col gap-2 w-full">
          {teams?.map((team, index) => <div key={team.id}>{renderTeam(team)}</div>)}
        </div>
      </Dialog>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {_FlowInput} from '@modules/FlowEngine/types/core.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';
import {FlowManager} from '@modules/FlowEngine/util/managers/flowManager';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useLanguage} from '@modules/Translations/hooks/language';

export const useInitStepComponent = (
  flowInput: _FlowInput | undefined,
  flowManager: FlowManager | null,
  currentStep: _FlowStep | undefined,
  onCurrentStepChanged?: (step: _FlowStep | undefined) => void
): React.ReactElement | null | undefined => {
  const [stepComponent, setStepComponent] = useState<React.ReactElement | null | undefined>(null);
  const [currentProfile] = useProfile();
  const {currentLanguage} = useLanguage();
  const previousInput = useRef<_FlowInput | undefined>(undefined);

  const loadCurrentStep = () => {
    if (!currentStep) {
      logger.error('[useInitStepComponent] currentStep is missing');
      return;
    }
    return flowManager?.loadStepComponent(currentStep);
  };

  useEffect(() => {
    if (onCurrentStepChanged) {
      onCurrentStepChanged(currentStep);
    }
    setStepComponent(loadCurrentStep());
  }, [currentStep, currentLanguage, currentProfile]);

  useEffect(() => {
    if (previousInput.current === flowInput) {
      return;
    }
    if (onCurrentStepChanged) {
      onCurrentStepChanged(currentStep);
    }
    logger.info('[useInitStepComponent] Reinitializing step component with new input', {flowInput});
    setStepComponent(loadCurrentStep());
    previousInput.current = flowInput;
  }, [flowInput]);

  return stepComponent;
};

// @ts-nocheck
/* eslint-disable */

import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {_Institution} from '@modules/Institution/types/institution.model';

export function hasMembershipAccess(institution: _Institution | null) {
  if (!institution) return false;
  return can('organisation.products:purchase', {institution_id: institution?.id});
}

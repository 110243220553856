// @ts-nocheck
/* eslint-disable */

import React, {useRef} from 'react';
import {getPublicProfileRoute} from '@/_core/util/util';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Switch} from '@modules/Core/components/base/inputs/Switch';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {copyObject} from '@modules/Core/util/util';
import {_SettingsEntry} from '@modules/FlowEngine/components/Steps/ProfileAccountDetailsStep';
import {_Profile} from '@modules/Profile/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  currentProfile: _Profile | null;
  settings: _SettingsEntry[];
  setSettings: (settings: _SettingsEntry[]) => void;
  onChangePrivacy: () => void;
}

const ProfilePrivacySettings: React.FC<_Props> = ({onChangePrivacy, currentProfile, setSettings, settings}) => {
  const hasChanged = useRef<boolean>(false);

  const handleChange = (i: number): void => {
    const newSettings = copyObject(settings)!;
    newSettings[i].value = !newSettings[i].value;
    setSettings(newSettings);
    hasChanged.current = true;
  };

  return (
    <div className="flex flex-col items-start gap-3 w-full">
      <div className="flex justify-between items-center w-full">
        <h5>{trans('settings.privacy.title')}</h5>
        <TextLink href={getPublicProfileRoute(currentProfile?.id)} label={trans('settings.see_what_others_see')} />
      </div>

      <div className="flex flex-col justify-center items-start gap-3">
        {settings.map((setting, i) => (
          <div key={setting.label} className="flex items-center gap-3">
            <span className="relative-col-width-4">{trans(setting.label)}</span>
            <div className="flex justify-end relative-col-width-1">
              <Switch active={setting.value} color="dark" onChange={() => handleChange(i)} />
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-end w-full">
        <Button
          disabled={!hasChanged.current}
          onClick={() => {
            onChangePrivacy();
          }}
          label={trans('settings.save_changes_button')}
          width="relative-col-width-2"
        />
      </div>
    </div>
  );
};

export default ProfilePrivacySettings;

// @ts-nocheck
/* eslint-disable */

import React, {ReactNode} from 'react';
import {_LeaderboardProfile} from '@modules/ContentEngine/components/items/gamification/LeaderboardItem';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {getAchievementImage} from '@modules/Core/util/imagesUtil';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';
import ProfileLevelImage from '@modules/Statistics/components/general/ProfileLevel';

export interface _BaseRowProps {
  profile: _LeaderboardProfile;
  index: number;
  children: ReactNode;
  currentProfileRef?: React.RefObject<HTMLDivElement>;
}

// TODO @SHERIF IMPORTANT!! Next time you add a new component, think of passing number+icon from CE_template
export const LeaderboardLevelRow: React.FC<_BaseRowProps> = ({profile, index, currentProfileRef}) => {
  return (
    <BaseRow profile={profile} index={index} currentProfileRef={currentProfileRef}>
      <div className="flex flex-row items-center gap-2">
        <ExperienceCount experience={profile.totalExperience} />
        <ProfileLevelImage level={profile.level} size={3} type="level" />
      </div>
    </BaseRow>
  );
};

export const LeaderboardExerciseRow: React.FC<_BaseRowProps> = ({profile, index, currentProfileRef}) => {
  return (
    <BaseRow profile={profile} index={index} currentProfileRef={currentProfileRef}>
      <div className="flex flex-row items-center gap-2">
        <img src={getAchievementImage('trophy', 'png')} className="h-3" />
        <Typography variant="body2">{profile.totalExperience}</Typography>
      </div>
    </BaseRow>
  );
};

export const LeaderboardSoftfactRow: React.FC<_BaseRowProps> = ({profile, index, currentProfileRef}) => {
  return (
    <BaseRow profile={profile} index={index} currentProfileRef={currentProfileRef}>
      <div className="flex flex-row items-center gap-2">
        <img src={getAchievementImage('softfacts', 'png')} className="h-3" />
        <Typography variant="body2">{profile.totalSoftfacts}</Typography>
      </div>
    </BaseRow>
  );
};

export const BaseRow: React.FC<_BaseRowProps> = ({profile, index, children, currentProfileRef}) => {
  return (
    <Card containerRef={currentProfileRef}>
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center gap-3 flex-grow">
          <Typography variant="h6">{index + 1}</Typography>
          <AvatarUser image={getProfilePic(profile)} label={profile.fullName} email={profile.position ?? '-'} />
        </div>
        {children}
      </div>
    </Card>
  );
};

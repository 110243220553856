// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {SoftFactTab} from '@modules/Core/components/base/softfacts/tab/SoftFactTab';
import {Typography} from '@modules/Core/components/base/Typography';
import {SERVICE_PROFILE_BASE_AND_ANALYSIS, SERVICE_STATUS_NOT_READY} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import ProfileUpgradeDialog from '@modules/Profile/components/exercises/dialogs/ProfileUpgradeDialog';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {moduleDescriptionLangProps, moduleTitleLangProps} from '@modules/Profile/config/servicesLangProps';
import {
  _ProfileServiceExercises,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
} from '@modules/Profile/types/exercises.model';
import {_ProfileModule, _ServiceStatus, _ServiceStatusStatus} from '@modules/Profile/types/modules.model';
import {getServiceIcon, getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  modules: _ProfileModule[];
  exercises: _ProfileServiceExercises;
  hasStartedJourney?: boolean;
}

interface _ModuleData {
  title: string;
  description: string;
  softFacts: _ServiceStatus[];
}

const sortedModules = ['mindset', 'collaboration', 'leadership'];

export const SoftFactDropDownV2: React.FC<_Props> = ({modules, hasStartedJourney = false, exercises}) => {
  const navigateToFlow = useNavigateToFlow();
  const [currentModules, setCurrentModules] = useState<Record<string, _ModuleData> | null>(null);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false);

  // Extract softfacts from modules and enrich with exercises data (relevant to get current progress etc..)
  useEffect(() => {
    if (modules) {
      /**
       * Convert modules to object
       */
      const newModules: Record<string, _ModuleData> = {};
      modules.forEach(module => {
        const {moduleKey} = module;
        const title = trans(moduleTitleLangProps[moduleKey]);
        const description = trans(moduleDescriptionLangProps[moduleKey]);
        let softFacts: _ServiceStatus[] = [];

        if (module.serviceStatuses?.length) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          softFacts = copyObject(module.serviceStatuses)!;

          softFacts.forEach(softFact => {
            const {service} = softFact;
            const exercise = exercises?.[service];
            if (exercise?.progress !== null && exercise?.progress !== undefined) {
              softFact.progress = exercise.progress;

              // Try to get latest exercise
              const currentRoundExercises = exercise.rounds?.[exercise.currentRound].exercises ?? [];
              // Find FIRST!! exercise with status open or started
              const latestOpenExercise = currentRoundExercises
                ?.sort((a, b) => a.position - b.position)
                ?.find(ex => ![EXERCISE_STATUS_DONE, EXERCISE_STATUS_LOCKED].includes(ex.status));

              softFact.nextExercise = latestOpenExercise?.position ?? undefined;
              logger.debug('trying to get latest open exercise for service', {
                service,
                exercise,
                latestOpenExercise,
                exercises: currentRoundExercises,
              });
            }
          });
        }

        newModules[moduleKey] = {
          title,
          description,
          softFacts,
        };
      });

      setCurrentModules(newModules);
    }
  }, [modules, exercises]);

  const handleClickSoftFact = (service: string, isLocked: boolean, status: _ServiceStatusStatus): void => {
    // For locked, show Upgrade dialog only if not ready
    if (isLocked && status === SERVICE_STATUS_NOT_READY) {
      setShowUpgradeDialog(true);
    } else {
      // Only for routing
      const flow = getSurveyFlow(service);
      if (!flow) {
        logger.error('No flow found for service', service);
        return;
      }
      navigateToFlow(flow.name);
    }
  };

  if (!currentModules) return null;

  logger.debug('ServiceStatus Debug:', {
    modules,
    exercises,
  });
  return (
    <div dusk="softfact-dropdown-container" className="flex flex-col gap-3 w-full">
      {/* todo@sherif: should be global dialog */}
      <ProfileUpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />

      {sortedModules.map((module, i) => (
        <FactsRow
          key={`dropdown-${module}`}
          data={currentModules[module]}
          handleClickSoftFact={handleClickSoftFact}
          hasStartedJourney={hasStartedJourney}
        />
      ))}
    </div>
  );
};

interface _FactsRowProps {
  data: _ModuleData;
  handleClickSoftFact: (service: string, isLocked: boolean, status: _ServiceStatusStatus) => void;
  hasStartedJourney: boolean;
}

const FactsRow: React.FC<_FactsRowProps> = ({data, handleClickSoftFact, hasStartedJourney}) => {
  const STATUS_ENUM: Record<string, string> = {
    first: 'first',
    start: 'start',
    next: 'next',
    started: 'progress',
    finished: 'finished',
    locked: 'locked',
    first_inner: 'first-inner',
    show: 'show',
  };

  const {softFacts, title, description} = data;

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex justify-start items-center">
        <Typography variant="h6">{title}</Typography>
      </div>

      <Typography variant="body1">{description}</Typography>

      <div className="flex flex-row gap-3 w-full justify-between">
        {softFacts.map(item => {
          const isFirst = item.service === SERVICE_PROFILE_BASE_AND_ANALYSIS && !hasStartedJourney;

          let badge;

          if (isFirst) {
            badge = STATUS_ENUM.first_inner;
          } else if (!item.available) {
            badge = item.status === SERVICE_STATUS_NOT_READY ? STATUS_ENUM.locked : STATUS_ENUM.show;
          } else if (item.available && item.status === SERVICE_STATUS_NOT_READY) {
            badge = STATUS_ENUM.start;
          } else {
            badge = STATUS_ENUM[item.status];
          }
          logger.debug('ServiceStatus Debug:', {
            status: item,
            badge,
          });

          return (
            <div key={item.id} className="relative-col-width-2-5">
              <SoftFactTab
                label={trans(PROFILE_SERVICES_TITLES[item.service])}
                icon={getServiceIcon(item.service)}
                onChange={(isLocked: boolean) => handleClickSoftFact(item.service, isLocked, item.status)}
                progress={item.progress}
                badge={badge}
                currentRound={item.current_round}
                currentRoundStatus={item.current_round_status}
                metric={item.metric}
                xpData={item.xpData}
                nextExercise={item.nextExercise}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

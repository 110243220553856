// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconPassword} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {trans} from '@modules/Translations/util/i18n';

interface _LoginProps {
  password: string | null;
  setPassword: (password: string | null) => void;
}

export const LoginPasswordInput: React.FC<_LoginProps> = ({password, setPassword}) => {
  return (
    <Input
      dusk="login-password"
      value={password}
      icon={IconPassword}
      placeholder={trans('login.password_placeholder')}
      label={trans('login.password_label')}
      required
      onChange={setPassword}
      type="password"
    />
  );
};

// @ts-nocheck
/* eslint-disable */

// Importing necessary components and hooks from React and MUI
import React, {useEffect, useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ExerciseData, _ExerciseDataEntry, _ProfileExercise} from '@modules/Profile/types/exercises.model';
import {startExerciseAction} from '@modules/Profile/util/exercisesActionsUtil';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const estimatedTimeValues = [
  {value: '1_week', label: 'profile_exercises.estimated_time.1_week'},
  {value: '2_weeks', label: 'profile_exercises.estimated_time.2_weeks'},
  {value: '3_weeks', label: 'profile_exercises.estimated_time.3_weeks'},
  {value: '1_month', label: 'profile_exercises.estimated_time.1_month'},
];

interface _StartExerciseDialogProps {
  open: boolean;
  onClose: (collectedData: Record<string, any> | null) => void;
  exerciseData: _ExerciseDataEntry | null;
  exerciseIndex: number;
  profileData: _ProfileExercise[];
  service: string;
  shortVersion?: boolean;
}

const StartExerciseDialog: React.FC<_StartExerciseDialogProps> = ({
  open,
  onClose,
  exerciseData,
  exerciseIndex,
  profileData,
  service,
  shortVersion = false,
}) => {
  const snackbar = useSnackbar();
  const [estimatedTime, setEstimatedTime] = useState<string | null>(null);

  const [exercise, setExercise] = useState<_ExerciseData | null>(null);

  useEffect(() => {
    setExercise(exerciseData?.exercises?.[exerciseIndex]);
  }, [exerciseData, exerciseIndex]);

  useEffect(() => {
    if (!profileData) {
      return;
    }
    const profileExerciseData = profileData?.[exerciseIndex]?.data;

    if (!profileExerciseData) {
      return;
    }

    const {startedAt, estimatedTime} = profileExerciseData;

    if (!startedAt || !estimatedTime) {
      setEstimatedTime(null);
      return;
    }

    setEstimatedTime(estimatedTime);
  }, [profileData, exerciseIndex]);
  // Callback function when the confirmation button is clicked
  const handleConfirmation = async (): Promise<void> => {
    if (!service || !profileData) {
      return;
    }

    if (!estimatedTime) {
      snackbar.danger(trans('profile_exercises.estimated_time.error'));
      return;
    }
    const collectedData = {
      estimatedTime,
      startedAt: new Date().toISOString(),
    };

    onClose(collectedData);

    const result = await startExerciseAction(
      profileData?.[exerciseIndex]?.service,
      exerciseData?.round?.round,
      exerciseIndex,
      collectedData
    );

    if (result) {
      reloadContentEngine();
    }
  };

  const Icon = getServiceIcon(service);

  return (
    <Dialog
      size="md"
      open={open}
      title={trans('profile_exercises.start_exercise')}
      showCloseButton
      onConfirm={() => {
        void handleConfirmation();
      }}
      confirmLabel={trans('profile_exercises.start_exercise')}
      onClose={() => onClose(null)}
      titleGap={3}
      contentGap={3}
    >
      <Section gap={2}>
        <div className="flex w-100 flex-row items-center" style={{backgroundColor: 'none'}}>
          {Icon && <Icon />}
          <Typography style={{textAlign: 'center', marginLeft: '8px'}} variant="body1">
            {trans(PROFILE_SERVICES_TITLES[service])}
          </Typography>
        </div>

        <Typography variant="h6">
          {trans(exerciseData?.round?.title ?? '')}: {trans(exercise?.intro?.title ?? '')}
        </Typography>
        {!shortVersion && (
          <>
            <Typography variant="body" bold>
              {trans(exercise?.intro?.goal ?? '')}
            </Typography>
            <Typography variant="body">
              <p dangerouslySetInnerHTML={{__html: trans(exercise?.intro?.description ?? '')}} />
            </Typography>
          </>
        )}
      </Section>
      <Section>
        <Typography variant="body" bold>
          {trans('profile_exercises.estimated_time.title')}
        </Typography>
        <EstimatedTimeValues estimatedTime={estimatedTime} setEstimatedTime={setEstimatedTime} />
      </Section>
      <Section>
        <Typography variant="body">{trans('profile_exercises.start_footer')}</Typography>
      </Section>
    </Dialog>
  );
};

interface _EstimatedTimeValues {
  estimatedTime: string;
  setEstimatedTime: (value: string) => void;
}

const EstimatedTimeValues: React.FC<_EstimatedTimeValues> = ({estimatedTime, setEstimatedTime}) => {
  return (
    <div className="flex flex-row gap-3">
      {estimatedTimeValues.map(option => (
        <div className="flex-grow">
          <RadioButton
            forSurvey
            label={trans(option.label)}
            active={estimatedTime === option.value}
            onChange={() => setEstimatedTime(option.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default StartExerciseDialog;

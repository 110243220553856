// @ts-nocheck
/* eslint-disable */

import React, {RefObject, useEffect, useRef} from 'react';
import {useUiStore} from '@modules/Core/util/logic/zustand';

export const getImage = (type: 'urs' | 'stefan' | 'sherif' | 'laura' = 'urs'): string => `/people/${type}.jpg`;

export function addCN(
  className?: string | boolean,
  a?: string | boolean,
  b?: string | boolean,
  c?: string | boolean,
  d?: string | boolean,
  e?: string | boolean,
  f?: string | boolean,
  g?: string | boolean,
  h?: string | boolean,
  i?: string | boolean
): string {
  return `${className ?? ''}${a ? ` ${a}` : ''}${b ? ` ${b}` : ''}${c ? ` ${c}` : ''}${d ? ` ${d}` : ''}${
    e ? ` ${e}` : ''
  }${f ? ` ${f}` : ''}${g ? ` ${g}` : ''}${h ? ` ${h}` : ''}${i ? ` ${i}` : ''}`;
}

export const GridHelper: React.FC = () => {
  // const isMobile = useUiStore(state => state.isMobile);
  const showGridHelper = useUiStore(state => state.showGridHelper);
  const isMobile = false;
  return showGridHelper ? (
    <div className={addCN('grid-helper-overlay h-full')}>
      <div className="grid-helper-column" />
      <div className="grid-helper-gap" />

      <div className="grid-helper-column" />
      <div className="grid-helper-gap" />

      <div className="grid-helper-column" />
      <div className="grid-helper-gap" />

      <div className="grid-helper-column" />
      <div className="grid-helper-gap" />

      <div className="grid-helper-column" />
      <div className="grid-helper-gap" />

      <div className="grid-helper-column" />

      {isMobile ? null : (
        <>
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
          <div className="grid-helper-gap" />

          <div className="grid-helper-column" />
        </>
      )}
    </div>
  ) : null;
};

// Recursive function to check if the target or its parent has the specified id
const hasParentWithId = (target: HTMLElement | null, id: string): boolean => {
  if (!target) return false;
  if (target.id === id) return true;
  if (target.parentElement) return hasParentWithId(target.parentElement, id);
  return false;
};

export const useClickOutOfBoundsHelper = (onClickOutSide: () => void, buttonId: string): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Function to detect click outside of element
    const handleClickOutside = (event: Event): void => {
      event.stopPropagation();

      const button = document.getElementById(buttonId);
      const target = event.target as HTMLElement;
      // Check if the clicked area is not the ref element and not the button.
      // without the recursive button check, the button would be considered outside of the ref element when opening
      if (button && ref.current && !ref.current.contains(event.target as Node) && !hasParentWithId(target, buttonId)) {
        if (onClickOutSide) {
          onClickOutSide();
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutSide, buttonId]); // Empty array ensures that effect is only run on mount and unmount
  return ref;
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {IconArrowRight, IconLocked} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {SERVICE_STATUS_NOT_READY} from '@modules/Core/config/services';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import ProfileUpgradeDialog from '@modules/Profile/components/exercises/dialogs/ProfileUpgradeDialog';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ProfileModule, _ServiceStatus, SERVICE_STATUS_FINISHED} from '@modules/Profile/types/modules.model';
import {getServiceIcon, getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  modules: _ProfileModule[];
}

interface _ModuleData {
  title: string;
  description: string;
  softFacts: _ServiceStatus[];
}

const LIMIT = 4;
// V2 of SoftFactDropDown
export const SoftfactsOverview: React.FC<_Props> = ({modules}) => {
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false);
  const [softFacts, setSoftFacts] = useState<_ServiceStatus[]>([]);
  const softfactsLoaded = useRef<boolean>(false);
  useEffect(() => {
    if (softfactsLoaded.current) {
      return;
    }

    if (!modules?.length) {
      return;
    }
    // flatten modules.serviceStatuses
    const newSoftFacts: _ServiceStatus[] = modules.reduce((acc, module) => {
      return acc.concat(module.serviceStatuses);
    }, []);

    // filter by serviceStatuses.status is NOT not_ready
    // sort by updatedAt, recent -> old, updatedAt is a string
    // only LIMIT items should show
    setSoftFacts(
      newSoftFacts
        ?.filter(item => ![SERVICE_STATUS_NOT_READY, SERVICE_STATUS_FINISHED].includes(item.status))
        // sort by .relevance, lower -> higher
        .sort((a, b) => {
          return a.relevance - b.relevance;
        })
        .slice(0, LIMIT)
    );
    softfactsLoaded.current = true;
  }, []);

  logger.debug('SoftfactsOverview', {softFacts});
  if (!softFacts?.length) {
    return (
      <PlaceholderSection
        title={trans('dashboard.missing_softfacts_title')}
        content={trans('dashboard.missing_softfacts_description')}
        image={getDesignImage('your_softfacts.png')}
      />
    );
  }
  return (
    <>
      <ProfileUpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />
      <div className="flex flex-col gap-3 flex-wrap">
        {softFacts.map((item, index) => {
          return <SoftfactsRow onShowUpgradeDialog={() => setShowUpgradeDialog(true)} item={item} />;
        })}
      </div>
    </>
  );
};

const SoftfactsRow: React.FC<{item: _ServiceStatus; onShowUpgradeDialog: () => void}> = ({
  item,
  onShowUpgradeDialog,
}) => {
  const navigateToFlow = useNavigateToFlow();

  const Icon = getServiceIcon(item.service);
  const handleClickSoftFact = (): void => {
    const {service} = item;
    // For locked, show Upgrade dialog only if not ready

    // Only for routing
    const flow = getSurveyFlow(service);
    if (!flow) {
      logger.error('No flow found for service', service);
      return;
    }
    navigateToFlow(flow.name);
  };
  return (
    <SectionRow
      key={item?.service}
      title={trans(PROFILE_SERVICES_TITLES[item.service])}
      icon={Icon && <Icon />}
      prefixContent={
        <div className="w-[56px]">
          <ProgressBar color="success" size="xs" value={item.progress} />
        </div>
      }
      buttonIcon={item.available ? IconArrowRight : IconLocked}
      buttonTooltip={!item?.available ? trans('profile_modules.upgrade_to_continue') : undefined}
      onButtonClick={() => {
        if (item?.available) {
          handleClickSoftFact();
        } else {
          onShowUpgradeDialog();
        }
      }}
      isButtonDisabled={!item?.available}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {changeCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {_Institution} from '@modules/Institution/types/institution.model';
import {appState, dispatchAction} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

export function currentInstitution(): _Institution | null {
  return appState().institution?.currentInstitution;
}

export function switchInstitution(institutionId: string): void {
  dispatchAction(changeCurrentInstitution(institutionId));
}

export function switchInstitutionFlow(): void {
  fireDialogFlow(FLOWS.SwitchOrganisation, null, {
    title: trans('institution.switch.title'),
    hideButtons: true,
    showCloseButton: true,
  });
}

export function concatTeamNames(teams: Array<{name: string}>, limit: number): string {
  const teamNames = teams?.map(team => team.name);
  const concatenatedNames = teamNames.slice(0, limit).join(', ');

  return teamNames.length > limit ? `${concatenatedNames} + ${teamNames.length - limit}` : concatenatedNames;
}

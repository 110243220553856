// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import {Chart, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactDOM from 'react-dom';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {trans} from '@modules/Translations/util/i18n';

Chart.register(ChartDataLabels);
Chart.register(...registerables);

interface _Props {
  width: number;
  height: number;
  data: number[];
  mainMotives: Record<string, string[]>;
  profileMotives: {
    motiveGroups: Record<string, Record<string, Record<string, number>>>;
  };
  teamProfiles: Record<string, {fullName: string}>;
}

interface ChartContext {
  chart: Chart;
  dataIndex: number;
  element: any;
  dataset: {
    data: number[];
  };
}

interface TooltipState {
  visible: boolean;
  x: number;
  y: number;
  content: string;
}

const Tooltip: React.FC<TooltipState> = ({visible, x, y, content}) => {
  if (!visible) return null;

  return (
    <div
      style={{
        position: 'absolute',
        left: x + 10,
        top: y + 10,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '8px 12px',
        borderRadius: '4px',
        fontSize: '14px',
        pointerEvents: 'none',
        zIndex: 1000,
        whiteSpace: 'pre-wrap',
      }}
    >
      {content}
    </div>
  );
};

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const TeamMotivesChart: React.FC<_Props> = ({
  width,
  height,
  data,
  mainMotives,
  profileMotives,
  teamProfiles,
}) => {
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const overlayContainer = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);
  const scaleFactor = useUiStore(state => state.scaleFactor);
  const [tooltip, setTooltip] = React.useState<TooltipState>({
    visible: false,
    x: 0,
    y: 0,
    content: '',
  });
  const [hoveredSlice, setHoveredSlice] = React.useState<{
    segmentIndex: number;
    profileIndex: number;
  } | null>(null);

  const drawCategorySeparators = () => {
    const overlay = overlayContainer.current;
    if (!overlay || !chartInstance.current) return;

    const ctx = overlay.getContext('2d');
    if (!ctx) return;

    const centerX = width / 2;
    const centerY = height / 2;
    const maxRadius = Math.min(height / 2 - 58, width / 2 - 58);

    // Draw separators for all 16 segments
    const segmentAngles = Array.from({length: 16}, (_, index) => (360 / 16) * index);

    ctx.beginPath();
    segmentAngles.forEach(angle => {
      const start = polarToCartesian(centerX, centerY, maxRadius, angle);

      ctx.moveTo(centerX, centerY);
      ctx.lineTo(start.x, start.y);
    });

    ctx.strokeStyle = 'rgba(180, 180, 180, 1.0)';
    ctx.lineWidth = 1; // Thinner lines for segment separators
    ctx.stroke();

    // Draw thicker lines for category boundaries (every 4th segment)
    ctx.beginPath();
    const categoryAngles = [0, 4, 8, 12].map(index => (360 / 16) * index);
    categoryAngles.forEach(angle => {
      const start = polarToCartesian(centerX, centerY, maxRadius, angle);

      ctx.moveTo(centerX, centerY);
      ctx.lineTo(start.x, start.y);
    });

    ctx.strokeStyle = 'rgba(180, 180, 180, 1.0)';
    ctx.lineWidth = 1;
    ctx.stroke();
  };

  const renderAvatars = (context: ChartContext) => {
    const motiveKey = context.chart.data.keys[context.dataIndex].toLowerCase();
    const [motiveGroup, motive] = motiveKey.split('.');
    const profiles = mainMotives[motiveKey] || [];

    // Sort profiles by their values in descending order
    const sortedProfiles = [...profiles].sort((a, b) => {
      const valueA = profileMotives.motiveGroups[motiveGroup][motive][a] || 0;
      const valueB = profileMotives.motiveGroups[motiveGroup][motive][b] || 0;
      return valueB - valueA;
    });

    const sectionAngle = (Math.PI * 2) / 16;
    const startAngle = (Math.PI * 2 * context.dataIndex) / 16 - Math.PI / 2;
    const centerAngle = startAngle + sectionAngle / 2;

    const radius = height / 2 + 20;

    const x = Math.cos(centerAngle) * radius + width / 2;
    const y = Math.sin(centerAngle) * radius + height / 2;

    const label = context.chart.data.labels[context.dataIndex];

    // Create tooltips with sorted profiles
    const tooltips = sortedProfiles.reduce<Record<string, string>>(
      (acc, profileId) => ({
        ...acc,
        [profileId]: `${teamProfiles[profileId]?.fullName || ''} ${Math.round(
          profileMotives.motiveGroups[motiveGroup][motive][profileId]
        )}%`,
      }),
      {}
    );

    return (
      <div
        style={{
          position: 'absolute',
          transform: `translate(${x}px, ${y}px) translate(-50%, -50%)`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
          fontSize: '12px',
        }}
      >
        <div
          style={{
            marginBottom: '8px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        >
          {label}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TeamProfileAvatarList profiles={sortedProfiles} teamProfiles={teamProfiles} tooltips={tooltips} limit={3} />
        </div>
      </div>
    );
  };

  const drawProfileSegments = () => {
    const overlay = overlayContainer.current;
    if (!overlay || !chartInstance.current) return;

    const ctx = overlay.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, width, height);

    const centerX = width / 2;
    const centerY = height / 2;
    const maxRadius = Math.min(height / 2 - 58, width / 2 - 58);

    const categoryColors = {
      future: '#F3C677',
      morale: '#F89377',
      self: '#7B1E7A',
      social: '#A8DADC',
    };

    const categoryColorsLight = {
      future: '#F5D68C',
      morale: '#F8B8A0',
      self: '#7B3F8A',
      social: '#A8D6DB',
    };

    const categoryColorsHighlight = {
      future: '#ffbb47',
      morale: '#ff724b',
      self: '#830681',
      social: '#7bdfe2',
    };

    let globalSliceIndex = 0;

    data.forEach((_, index) => {
      const motiveKey = chartInstance.current!.data.keys[index].toLowerCase();
      const [motiveGroup, motive] = motiveKey.split('.');
      const profiles = mainMotives[motiveKey] || [];

      // Sort profiles by their values in descending order
      const sortedProfiles = [...profiles].sort((a, b) => {
        const valueA = profileMotives.motiveGroups[motiveGroup][motive][a] || 0;
        const valueB = profileMotives.motiveGroups[motiveGroup][motive][b] || 0;
        return valueB - valueA;
      });

      const startAngle = (360 / 16) * index;
      const sliceAngle = 360 / 16;

      sortedProfiles.forEach((profileId, profileIndex) => {
        const profileValue = Math.round(profileMotives.motiveGroups[motiveGroup][motive][profileId]);
        if (!profileValue) return;

        const segmentWidth = sliceAngle / sortedProfiles.length;
        const segmentStart = startAngle + segmentWidth * profileIndex;
        const segmentEnd = segmentStart + segmentWidth;

        const radius = (maxRadius * profileValue) / 100;

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);

        const start = polarToCartesian(centerX, centerY, radius, segmentStart);
        const end = polarToCartesian(centerX, centerY, radius, segmentEnd);

        ctx.lineTo(start.x, start.y);
        ctx.arc(centerX, centerY, radius, ((segmentStart - 90) * Math.PI) / 180, ((segmentEnd - 90) * Math.PI) / 180);
        ctx.lineTo(centerX, centerY);

        // Simple red highlight on hover
        const isHovered = hoveredSlice?.segmentIndex === index && hoveredSlice?.profileIndex === profileIndex;
        if (isHovered) {
          ctx.fillStyle = categoryColorsHighlight[motiveGroup];
          ctx.strokeStyle = categoryColorsHighlight[motiveGroup];
        } else {
          const baseColor = globalSliceIndex % 2 === 0 ? categoryColors[motiveGroup] : categoryColorsLight[motiveGroup];
          ctx.fillStyle = baseColor;
          ctx.strokeStyle = baseColor;
        }

        ctx.lineWidth = 0;
        ctx.fill();
        ctx.stroke();

        globalSliceIndex++;
      });
    });

    // Draw the category separators on top
    drawCategorySeparators();
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const overlay = overlayContainer.current;
    if (!overlay || !chartInstance.current) return;

    const rect = overlay.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const centerX = width / 2;
    const centerY = height / 2;

    // Calculate distance from center and angle
    const dx = x - centerX;
    const dy = y - centerY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const maxRadius = Math.min(height / 2 - 58, width / 2 - 58);

    if (distance > maxRadius) {
      setTooltip(prev => ({...prev, visible: false}));
      return;
    }

    let angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    if (angle < 0) angle += 360;
    angle = (angle + 90) % 360;

    // Find which segment we're hovering over
    const segmentIndex = Math.floor((angle / 360) * 16);
    if (segmentIndex >= 0 && segmentIndex < data.length) {
      const motiveKey = chartInstance.current.data.keys[segmentIndex].toLowerCase();
      const [motiveGroup, motive] = motiveKey.split('.');
      const profiles = mainMotives[motiveKey] || [];

      // Sort profiles the same way as in drawProfileSegments
      const sortedProfiles = [...profiles].sort((a, b) => {
        const valueA = profileMotives.motiveGroups[motiveGroup][motive][a] || 0;
        const valueB = profileMotives.motiveGroups[motiveGroup][motive][b] || 0;
        return valueB - valueA;
      });

      const sliceAngle = 360 / 16;
      const segmentStart = sliceAngle * segmentIndex;
      const profileSliceWidth = sliceAngle / sortedProfiles.length;

      const profileIndex = Math.floor(((angle - segmentStart) % sliceAngle) / profileSliceWidth);

      if (profileIndex >= 0 && profileIndex < sortedProfiles.length) {
        setHoveredSlice({segmentIndex, profileIndex});

        const profileId = sortedProfiles[profileIndex];
        const value = Math.round(profileMotives.motiveGroups[motiveGroup][motive][profileId]);
        const name = teamProfiles[profileId]?.fullName || '';
        const label = chartInstance.current.data.labels[segmentIndex];

        setTooltip({
          visible: true,
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
          content: `${label}\n${name}: ${value}%`,
        });
      } else {
        setHoveredSlice(null);
        setTooltip(prev => ({...prev, visible: false}));
      }
    } else {
      setHoveredSlice(null);
      setTooltip(prev => ({...prev, visible: false}));
    }
  };

  const handleMouseLeave = () => {
    setHoveredSlice(null);
    setTooltip(prev => ({...prev, visible: false}));
  };

  useEffect(() => {
    const updateOffset = (chart: Chart): void => {
      const radius = height / 2;
      chart.options.plugins.datalabels.offset = radius - 40;
      chart.update();
    };

    const initChart = (ctx, params): void => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, params);
    };

    const ctx = chartContainer.current?.getContext('2d');
    if (ctx) {
      const data2 = {
        labels: [
          trans('motive_structure.career.title'),
          trans('motive_structure.development.title'),
          trans('motive_structure.selfrealization.title'),
          trans('motive_structure.selfoptimization.title'),
          trans('motive_structure.fun.title'),
          trans('motive_structure.power.title'),
          trans('motive_structure.achievement.title'),
          trans('motive_structure.independance.title'),
          trans('motive_structure.fairness.title'),
          trans('motive_structure.idealism.title'),
          trans('motive_structure.responsibility.title'),
          trans('motive_structure.goalinternalization.title'),
          trans('motive_structure.status.title'),
          trans('motive_structure.security.title'),
          trans('motive_structure.belonging.title'),
          trans('motive_structure.acceptance.title'),
        ],
        keys: [
          'future.career',
          'future.development',
          'future.selfrealization',
          'future.selfoptimization',
          'self.fun',
          'self.power',
          'self.achievement',
          'self.independance',
          'morale.fairness',
          'morale.idealism',
          'morale.responsibility',
          'morale.goalinternalization',
          'social.status',
          'social.security',
          'social.belonging',
          'social.acceptance',
        ],
        datasets: [
          {
            data,
            backgroundColor: [
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
            ],
          },
        ],
      };

      let updateFinished = false;

      const mmUpdatePlugin = {
        id: 'mmUpdate',
        afterDraw: (chart: any) => {
          if (!updateFinished) {
            updateFinished = true;
            updateOffset(chart);

            // Clear previous avatars
            const parent = chartContainer.current?.parentElement;
            if (parent) {
              Array.from(parent.children).forEach(child => {
                if (child instanceof HTMLDivElement) {
                  parent.removeChild(child);
                }
              });
            }

            // Render new avatars
            const meta = chart.getDatasetMeta(0);
            meta.data.forEach((element: any, index: number) => {
              const context = {
                chart,
                dataIndex: index,
                element,
                dataset: {
                  data: data2.datasets[0].data,
                },
              };

              const container = document.createElement('div');
              container.style.position = 'absolute';
              container.style.left = '0';
              container.style.top = '0';
              chartContainer.current?.parentElement?.appendChild(container);

              ReactDOM.render(renderAvatars(context), container);
            });
          }
        },
      };

      initChart(ctx, {
        type: 'polarArea',
        data: data2,
        plugins: [ChartDataLabels, mmUpdatePlugin],
        options: {
          animation: false,
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            mmUpdate: {},
            legend: {
              position: 'right',
              display: false,
              onClick: (e: MouseEvent) => e.stopPropagation(),
            },
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  let result = `${context.label}: ${context.formattedValue}%`;
                  if (context.datasetIndex === 1) {
                    result += ' Candidates Analysis';
                  }
                  return result;
                },
              },
            },
            datalabels: {
              font: {
                family: 'var(--font-karla), sans-serif',
              },
              color: 'black',
              formatter: (_value: any, _context: any) => {
                return '';
              },
              anchor: 'start',
              align: 'end',
              offset: 0,
            },
          },
          title: {
            display: false,
          },
          layout: {
            padding: {
              top: 50,
              bottom: 50,
              left: 100,
              right: 100,
            },
          },
          scales: {
            r: {
              grid: {
                color: ['gray'],
              },
              max: 100,
              min: 0,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 10,
              },
              angleLines: {
                display: true,
                color: ['gray'],
              },
              pointLabels: {
                display: false,
                font: {
                  size: 15,
                },
              },
            },
          },
          elements: {
            arc: {
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Make the main chart slightly transparent
            },
          },
        },
      });

      // After chart is initialized, draw the profile segments
      if (chartInstance.current) {
        drawProfileSegments();
      }
    }
  }, [data, profileMotives, mainMotives]);

  useEffect(() => {
    if (chartInstance.current) {
      drawProfileSegments();
    }
  }, [hoveredSlice, data, mainMotives, profileMotives]);

  return (
    <div className="relative mt-8" style={{width, height: height + 80}}>
      <canvas ref={chartContainer} width={width} height={height} />
      <canvas
        ref={overlayContainer}
        width={width}
        height={height}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          // cursor: 'pointer',
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
      <Tooltip {...tooltip} />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  SHARED_WITH_OTHERS,
  SHARED_WITH_OTHERS_TYPE,
  SHARED_WITH_YOU,
  SHARED_WITH_YOU_TYPE,
} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {logger} from '@modules/Core/util/Logger';
import {ThirdPersonFeedbackForOthersTable} from '@modules/Profile/components/services/thirdPersonFeedback/ThirdPersonFeedbackForOthersTable';
import {ThirdPersonFeedbackForYouTable} from '@modules/Profile/components/services/thirdPersonFeedback/ThirdPersonFeedbackForYouTable';
import {ThirdPersonFeedbackTablesHeader} from '@modules/Profile/components/services/thirdPersonFeedback/ThirdPersonFeedbackTablesHeader';
import {
  _ThirdPersonFeedbackForOther,
  _ThirdPersonFeedbackForSelf,
} from '@modules/Profile/types/third-person-feedback.model';

interface _ProfileSharedResultsItemProps {
  initialTab?: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE;
  sharedWithYou: _ThirdPersonFeedbackForSelf[];
  sharedWithOthers: _ThirdPersonFeedbackForOther[];
}

export const ThirdPersonFeedbackOverviewItem: React.FC<_ProfileSharedResultsItemProps> = ({
  initialTab,
  sharedWithYou,
  sharedWithOthers,
}) => {
  const initialTabLoaded = useRef<boolean>(false);
  // Component state
  const [currentTab, setCurrentTab] = useState<SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE>(
    [SHARED_WITH_OTHERS, SHARED_WITH_YOU].includes(initialTab) ? initialTab : SHARED_WITH_YOU
  );

  const data = useMemo(
    () => (currentTab === SHARED_WITH_YOU ? sharedWithYou : sharedWithOthers),
    [currentTab, sharedWithYou, sharedWithOthers]
  );

  useEffect(() => {
    if (initialTabLoaded.current) {
      return;
    }

    initialTabLoaded.current = true;
    setCurrentTab([SHARED_WITH_OTHERS, SHARED_WITH_YOU].includes(initialTab) ? initialTab : SHARED_WITH_YOU);
  }, [initialTab]);

  return (
    <div className="flex flex-col gap-5">
      <ThirdPersonFeedbackTablesHeader setCurrentTab={setCurrentTab} currentTab={currentTab} />
      {currentTab === SHARED_WITH_OTHERS ? (
        <ThirdPersonFeedbackForOthersTable data={data} />
      ) : (
        <ThirdPersonFeedbackForYouTable data={data} />
      )}
    </div>
  );
};

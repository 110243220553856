// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {
  IconArrowBack,
  IconArrowDoubleLeft,
  IconArrowDoubleRight,
  IconArrowForward,
} from '@modules/Core/components/base/Icons/Icons';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {ProfileDevModeSection} from '@modules/Dev/components/ProfileDevModeSection';
import {personalityTraitsLangProps} from '@modules/Profile/config/servicesLangProps';
import {trans} from '@modules/Translations/util/i18n';

const personalityOptions = ['l', 'm', 'h'];
/**
 * Looks like mmhll or smth like that
 * m - medium, h - high, l - low
 * Ordered O->C->E->A->N
 */

const dimensionsOrder = ['openMindedness', 'conscientiousness', 'extraversion', 'agreeableness', 'emotionalStability'];
interface _ProfilePersonalityResultDevModeProps {
  isDevMode: boolean;
  setIsDevMode: (isDevMode: boolean) => void;
  personalityResult: string | null;
  setPersonalityResult: (result: string) => void;
}

export const ProfilePersonalityResultDevMode: React.FC<_ProfilePersonalityResultDevModeProps> = ({
  isDevMode,
  setIsDevMode,
  personalityResult,
  setPersonalityResult,
}) => {
  const [traits, setTraits] = useState<string[]>(personalityResult ? personalityResult.split('') : Array(5).fill('h'));

  useEffect(() => {
    if (personalityResult) {
      setTraits(personalityResult.split(''));
    }
  }, [personalityResult]);

  const updateTraits = (newTraits: string[]) => {
    setTraits(newTraits);
    setPersonalityResult(newTraits.join(''));
  };

  const hasNext = () => !traits.every(trait => trait === 'h');
  const hasPrevious = () => !traits.every(trait => trait === 'l');

  const cycleNext = () => {
    const newTraits = [...traits];
    for (let i = newTraits.length - 1; i >= 0; i--) {
      const currentIndex = personalityOptions.indexOf(newTraits[i]);
      if (currentIndex < personalityOptions.length - 1) {
        newTraits[i] = personalityOptions[currentIndex + 1];
        break;
      } else {
        newTraits[i] = personalityOptions[0];
      }
    }
    updateTraits(newTraits);
  };

  const cyclePrevious = () => {
    const newTraits = [...traits];
    for (let i = newTraits.length - 1; i >= 0; i--) {
      const currentIndex = personalityOptions.indexOf(newTraits[i]);
      if (currentIndex > 0) {
        newTraits[i] = personalityOptions[currentIndex - 1];
        break;
      } else {
        newTraits[i] = personalityOptions[personalityOptions.length - 1];
      }
    }
    updateTraits(newTraits);
  };

  const goToFirst = () => updateTraits(Array(5).fill('l'));
  const goToLast = () => updateTraits(Array(5).fill('h'));

  logger.debug('ProfilePersonalityResultDevMode', {isDevMode, personalityResult});

  return (
    <ProfileDevModeSection isDevMode={isDevMode} setIsDevMode={setIsDevMode}>
      <div className="flex flex-col gap-2">
        <Typography>
          Each trait (O.C.E.A.N) can be either high, medium or low. This is the current combination of personality
          traits.
        </Typography>

        {traits.map((trait, index) => (
          <Select
            key={index}
            options={personalityOptions.map(option => ({
              value: option,
              label: option === 'h' ? 'High' : option === 'l' ? 'Low' : 'Medium',
            }))}
            label={trans(personalityTraitsLangProps[dimensionsOrder[index]])}
            onChange={selectedOption => {
              const newTraits = [...traits];
              newTraits[index] = selectedOption?.value as string;
              updateTraits(newTraits);
            }}
            value={{
              value: trait,
              label: trait === 'h' ? 'High' : trait === 'l' ? 'Low' : 'Medium',
            }}
          />
        ))}

        <div className="flex flex-row w-full">
          Cycle through all different combinations of personality traits. It is recommended to go to the first
          combination, then cycle through all combinations.
        </div>
        <div className="flex items-center justify-center gap-2 w-full">
          <ToolTip txt="Go to first combination">
            <IconButton onClick={goToFirst} icon={IconArrowDoubleLeft} />
          </ToolTip>
          <ToolTip txt="Go to previous combination">
            <IconButton onClick={cyclePrevious} icon={IconArrowBack} disabled={!hasPrevious()} />
          </ToolTip>
          <ToolTip txt="Go to next combination">
            <IconButton onClick={cycleNext} icon={IconArrowForward} disabled={!hasNext()} />
          </ToolTip>
          <ToolTip txt="Go to last combination">
            <IconButton onClick={goToLast} icon={IconArrowDoubleRight} />
          </ToolTip>
        </div>
      </div>
    </ProfileDevModeSection>
  );
};

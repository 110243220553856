// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {trans} from '@modules/Translations/util/i18n';

interface _ChecklistTaskDoneViewProps {
  values: any[];
  showCorrectAnswer?: boolean;
}

export const ChecklistTaskDoneView: React.FC<_ChecklistTaskDoneViewProps> = ({showCorrectAnswer, values = []}) => {
  const checkedValues = values.filter(option => option.checked);

  if (showCorrectAnswer) {
    return null;
  }
  if (checkedValues.length === 0) {
    return <div>{trans('profile_exercises.no_answer')}</div>;
  }

  return (
    <div className="flex flex-col">
      {checkedValues.map(option => (
        <div className="flex flex-row">
          <div className="flex justify-center">{option.is_default ? trans(option.text) : option.text}</div>
        </div>
      ))}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {BaseStep} from '@modules/FlowEngine/components/Base/BaseStep';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {refreshReduxState} from '@modules/State/util/util';
import {Survey} from '@modules/SurveyEngine/components/Survey';

export const SurveyStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  // const {loadRequest} = useLoader();
  const loadRequest = null;

  async function onFinish(): Promise<void> {
    await refreshReduxState(loadRequest);
    options.onComplete();
  }

  return (
    <BaseStep options={options}>
      <Survey
        type={options.surveyType}
        onFinish={async () => await onFinish()}
        surveyOptions={options?.surveyParams}
        showSkip={options?.showSkip}
        yourResultsButtonText={options.yourResultsButtonText}
      />
    </BaseStep>
  );
};

// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_Institution} from '@modules/Institution/types/institution.model';

export interface _InstitutionState {
  currentInstitution: _Institution | null;
}

const institutionSlice = createSlice({
  name: 'institution',
  initialState: {
    currentInstitution: null,
  } satisfies _InstitutionState,
  reducers: {
    setCurrentInstitution(state, action) {
      logger.info('[InstitutionSlice] Saving institution.');

      state.currentInstitution = action.payload;
    },
    resetCurrentInstitution(state, action) {
      logger.info('[InstitutionSlice] Resetting institution.');

      state.currentInstitution = null;
    },
  },
});

export const {setCurrentInstitution, resetCurrentInstitution} = institutionSlice.actions;
export default institutionSlice;

export function changeCurrentInstitution(institutionId: string | null = null, loadRequest: null | any = null) {
  return async (dispatch: any, getState: any) => {
    return null;
  };
}

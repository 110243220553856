// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {FeedbackDialog} from '@modules/Core/components/platform/dialogs/FeedbackDialog';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {trans} from '@modules/Translations/util/i18n';

export interface _ProfileFeedbackItemProps {
  feedbackText?: string;
  linkText?: string;
  feedbackKey?: string;
  dialogTitle?: string;
  dialogDescription?: string;
  dialogConfirmText?: string;
  reverse?: boolean;
  link?: string;
}

export const ProfileFeedbackItem: React.FC<_ProfileFeedbackItemProps> = ({
  feedbackKey,
  feedbackText: externalFeedbackText,
  linkText: externalLinkText,
  dialogDescription,
  dialogTitle,
  dialogConfirmText,
  reverse,
  link = null,
}) => {
  const {navigate} = useAppNavigate();
  const feedbackText = externalFeedbackText ?? trans('profile_result.feedback_intro');
  const linkText = externalLinkText ?? trans('profile_result.feedback_link');

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const onFeedbackClick = (): void => {
    if (link) {
      navigate(link, {}, '_blank');
      return;
    }
    setDialogOpen(true);
  };

  if (!feedbackKey) {
    return null;
  }
  return (
    <>
      <FeedbackDialog
        title={dialogTitle}
        description={dialogDescription}
        confirmText={dialogConfirmText}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        type={feedbackKey}
      />

      <div
        className={twMerge(
          'flex flex-row w-full items-center gap-0',
          reverse ? 'flex-row-reverse' : 'flex-row',
          reverse ? 'justify-end' : 'justify-start'
        )}
      >
        <Typography variant="body1" dangerouslySetInnerHTML={{__html: feedbackText}} />
        <TextLink variant="body1" onClick={onFeedbackClick} label={linkText} />
      </div>
    </>
  );
};

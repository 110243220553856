// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_TEMPLATE_SURVEY_XP} from '@modules/ContentEngine/config/constants';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';
import {ServiceInformationActions} from '@modules/Profile/components/services/serviceInformation/ServiceInformationActions';
import {ServiceInformationCoach} from '@modules/Profile/components/services/serviceInformation/ServiceInformationCoach';
import {ServiceInformationConsent} from '@modules/Profile/components/services/serviceInformation/ServiceInformationConsent';
import {isServiceStarted} from '@modules/Profile/util/serviceUtil';
import {IconClock} from '../../base/Icons/Icons';

interface _ServiceInformationCardProps {
  service: string;
  teamId?: string;
  onNextClicked?: () => void;
  isCoach?: boolean;

  title?: string | null;
  content?: string | ReactNode | null;
  time?: string | null;
  image?: string | null;
  showConsentDialog?: boolean;
  setShowConsentDialog?: (value: boolean) => void;
  actions?: _UIAction[];
  imageViewDirection?: 'horizontal' | 'vertical';
  alignActions?: 'center' | 'start';
  actionsDirection?: 'row' | 'column';
  contentAsHTML?: boolean;
}

export const ServiceInformationCard: React.FC<_ServiceInformationCardProps> = ({
  service,
  teamId,
  onNextClicked = null,
  isCoach = false,
  title,
  content,
  time,
  image,
  showConsentDialog,
  setShowConsentDialog,
  actions,
  alignActions,
  actionsDirection = 'column',
  imageViewDirection,
  contentAsHTML = true,
}) => {
  /**
   * Survey is done useEffect listener
   */
  useEffect(() => {
    const isReady = isServiceStarted(service, teamId);
    logger.info('ServiceInformation', {service, isReady, isCoach});
    if (isReady && isCoach) {
      onNextClicked?.();
    }
  }, [isCoach, service, teamId]);

  if (isCoach) {
    return <ServiceInformationCoach service={service} teamId={teamId} />;
  }

  return (
    <>
      <ServiceInformationConsent
        service={service}
        teamId={teamId}
        onNextClicked={onNextClicked}
        show={showConsentDialog}
        setShow={setShowConsentDialog}
      />
      <Section
        image={image}
        imageHeight="h-[348px]"
        title={title}
        imageViewDirection={imageViewDirection}
        gap={2}
        justifyImage="center"
        imageContainerWidth={4}
      >
        <Section gap={2}>
          <Typography>
            {contentAsHTML && (
              <div className="flex flex-col gap-2" dangerouslySetInnerHTML={{__html: content as string}} />
            )}
            {!contentAsHTML && content}
          </Typography>
          <div className="flex flex-row items-center justify-start gap-2">
            {time && (
              <div className="flex flex-row items-center justify-start gap-1">
                <IconClock size="lg" color="fill-blue700" />
                <div className="text-body1 text-prussian300">{time}</div>
              </div>
            )}
            <ContentEngineContainer
              type={CE_TEMPLATE_SURVEY_XP}
              params={{surveyType: service, width: 3, variant: 'body1'}}
            />
          </div>
        </Section>
        <ServiceInformationActions
          actions={actions}
          service={service}
          alignActions={alignActions}
          actionsDirection={actionsDirection}
        />
      </Section>
    </>
  );
};

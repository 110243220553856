// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {Divider} from '@modules/Core/components/base/Divider';
import {SoftFactFooter} from '@modules/Core/components/base/softfacts/tab/SoftFactTabFooter';
import {SoftfactTabHeader} from '@modules/Core/components/base/softfacts/tab/SoftFactTabHeader';
import {_ServiceStatusStatus, _ServiceStatusXPData} from '@modules/Profile/types/modules.model';
import {trans} from '@modules/Translations/util/i18n';
import {ToolTip} from '../../ToolTip';
import {SoftfactTabBody} from './SoftFactTabBody';

interface _SoftFactTab {
  label: string;
  icon: React.ElementType | null;
  active: boolean;
  onChange?: (isLocked: boolean) => void;
  badge?: string;
  progress?: number;
  currentRound?: number;
  currentRoundStatus?: _ServiceStatusStatus;
  metric: _ProfileTrackingStatisticEntry;
  xpData: _ServiceStatusXPData;
  nextExercise?: number;
}

export const SoftFactTab: React.FC<_SoftFactTab> = props => {
  const {nextExercise, xpData, metric, label, icon: Icon, active, onChange, badge, progress} = props;

  const isLocked = badge === 'locked' || badge === 'show';

  let border = active ? 'border-blue700' : 'border-grey300';
  let bg = 'bg-white500 hover:bg-grey100';
  if (isLocked) {
    border = 'border-grey300';
    bg = 'bg-grey100';
  }

  const handleClick = (): void => {
    if (onChange) onChange(isLocked);
  };

  const showFooter = !!props.currentRoundStatus && props.currentRound !== undefined && props.currentRound !== null;
  return (
    <ToolTip className="w-full" plc="top" txt={isLocked ? trans('profile_modules.upgrade_to_continue') : undefined}>
      <button
        onClick={handleClick}
        className={twMerge(
          'flex flex-col justify-center items-start',
          'py-2 px-3',
          'w-full',
          'gap-2',
          'transition h-full',
          'rounded-sm border-[2px]',
          'relative',
          'overflow-hidden',
          border,
          bg
        )}
      >
        <SoftfactTabHeader badge={badge} progress={progress} metric={metric} xpData={xpData} />

        <SoftfactTabBody badge={badge} minified={!showFooter} label={label} Icon={Icon} />

        {showFooter && (
          <>
            <Divider />
            <SoftFactFooter
              currentRoundStatus={props.currentRoundStatus}
              currentRound={props.currentRound}
              badge={badge}
              xpData={xpData}
              nextExercise={nextExercise}
            />
          </>
        )}
      </button>
    </ToolTip>
  );
};

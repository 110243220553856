// @ts-nocheck
/* eslint-disable */

import {_ContentEngineViews} from '@modules/ContentEngine/types/core.model';

export const contentEngineViews: Record<
  _ContentEngineViews,
  {
    direction: 'row' | 'col';
    wrap?: boolean;
    gap?: number;
    show?: boolean;
  }
> = {
  default: {
    direction: 'col',
    gap: 8,
    wrap: false,
    show: true,
  },
  rows: {
    direction: 'row',
    gap: 3,
    wrap: true,
    show: true,
  },
  standalone: {
    show: false,
  },
};

// @ts-nocheck
/* eslint-disable */

export function mapList(list: any) {
  return {
    key: list?.key,
    id: list?.id,
    label: list?.label,
    values: list?.items,
  };
}

export function isCustomSurveyDnDValue(item: any) {
  return !!item.id && item.id === item.label && item.id === item.key;
}

// @ts-nocheck
/* eslint-disable */

import {_Item} from '@modules/FlowEngine/components/Steps/SelectStep';

export const selectLabel = (item: _Item, labelSelector: string): string => {
  // selector can be prop1.prop2 ..
  const selector = labelSelector.split('.');
  let label: _Item | string = item;
  for (const s of selector) {
    if (typeof label === 'string') {
      break;
    }
    label = label[s] ?? {};
  }
  return label as string;
};

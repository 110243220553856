// @ts-nocheck
/* eslint-disable */

import {ElementType} from 'react';
import {
  IconCommunication,
  IconDifficult,
  IconHeartFiled,
  IconLearning,
  IconMindfulness,
  IconMotivation,
  IconProactivity,
  IconResponsibility,
  IconSelfEfficacy,
  IconTrust,
} from '@modules/Core/components/base/Icons/Icons';
import {
  SERVICE_PROFILE_POTENTIALS,
  SERVICE_TEAM_DEVELOPMENT,
  SERVICE_TEAM_REFLECTION,
} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {_TeamProfile} from '@modules/Team/types/team.model';

export function isTeamDevModule(service: string) {
  return service?.includes('collective_');
}

export function getTeamServiceImage(service: string): string {
  // For now, TA & TD have same image
  const imagesMap: Record<string, string> = {
    [SERVICE_TEAM_DEVELOPMENT]: '/assets/img/illustrations_v2/team_services/team_development.png',
    [SERVICE_PROFILE_POTENTIALS]: '/assets/img/illustrations_v2/team_services/team_development.png',
    [SERVICE_TEAM_REFLECTION]: '/assets/img/illustrations_v2/team_services/team_analysis.png',
    [`${SERVICE_TEAM_REFLECTION}_unfulfilled`]: '/assets/img/design_images/high five.png',
  };
  return imagesMap[service] ?? '';
}

export function getTeamDevelopmentModuleImage(subModule: string, subSubModuleIndex?: number): string | null {
  const imagesMap: Record<string, string> = {
    measure: 'status quo',
    measure2: 'check out',
    inspiration: 'man thinking',
    motivation: 'work on trust',
    team_session: 'team session',
    collectivetools: 'reflection',
  };

  const imagesIndexMap: Record<number, string> = {
    0: 'experiences',
    1: 'sincerity',
    2: 'team celebration',
    3: 'man thinking',
  };

  logger.debug('img test', {
    subModule,
    subSubModuleIndex,
    result: imagesIndexMap[subSubModuleIndex] ?? null,
    result2: imagesMap[subModule] ?? null,
  });
  let path;
  if (subModule === 'individualtools' && subSubModuleIndex !== undefined) {
    path = imagesIndexMap[subSubModuleIndex];
  } else {
    path = imagesMap[subModule] ?? null;
  }

  if (!path) {
    return null;
  }

  return `/assets/img/design_images/${path}.png`;
}

export function getTeamDevModuleIcon(moduleName: string): ElementType {
  switch (moduleName) {
    case 'trust':
      return IconTrust;
    case 'communication':
      return IconCommunication;
    case 'mindfulness':
      return IconMindfulness;
    case 'motivation':
      return IconMotivation;
    case 'selfefficacy':
      return IconSelfEfficacy;
    case 'learning':
      return IconLearning;
    case 'proactivity':
      return IconProactivity;
    case 'responsibility':
      return IconResponsibility;
    case 'emotionalintelligence':
      return IconHeartFiled;
    case 'resilience':
      return IconDifficult;
    default:
      return IconLearning;
  }
}

export const profileHasTeamDevAnswers = (profile: _TeamProfile): boolean => {
  return profile?.teamDevelopment?.answers?.length > 0;
};

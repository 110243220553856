// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';
import {createContentGroup} from '@modules/SurveyEngine/util/questionUtil';
import {SurveyAudioContent} from './SurveyAudioContent';
import {SurveyBlockContent} from './SurveyBlockContent';
import {SurveyImageContent} from './SurveyImageContent';
import {SurveyQuoteContent} from './SurveyQuoteContent';
import {SurveyTestimonialContent} from './SurveyTestimonialContent';
import {SurveyVideoContent} from './SurveyVideoContent';

interface _SurveyContentTypeProps {
  question: _SurveyQuestion;
}

export const SurveyContentType: React.FC<_SurveyContentTypeProps> = ({question}) => {
  function getContentGroups() {
    const contentGroups = createContentGroup(question);

    return contentGroups?.map((contentGroup, index) => {
      switch (contentGroup.type) {
        case 'audio':
          return <SurveyAudioContent key={index} contentGroup={contentGroup} />;
        case 'quote':
          return <SurveyQuoteContent key={index} contentGroup={contentGroup} />;
        case 'block':
          return <SurveyBlockContent key={index} contentGroup={contentGroup} />;
        case 'image':
          return <SurveyImageContent key={index} contentGroup={contentGroup} />;
        case 'video':
          return <SurveyVideoContent key={index} contentGroup={contentGroup} />;
        case 'testimonial':
          return <SurveyTestimonialContent key={index} contentGroup={contentGroup} />;
      }
    });
  }

  return <div className="flex flex-col">{getContentGroups()}</div>;
};

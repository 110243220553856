// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {
  IconCancel,
  IconCopy,
  IconLink,
  IconMore,
  IconSend,
  IconShare,
  IconShow,
  IconSurvey,
  IconTrash,
} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {_CustomSurvey, _CustomSurveyStatus} from '@modules/CustomSurveys/types/customSurvey.model';
import {
  closeSurveyAction,
  deleteSurveyAction,
  duplicateSurveyAction,
  publishSurveyAction,
} from '@modules/CustomSurveys/util/customSurveysFlowActions';
import {shareCustomSurveyFlow} from '@modules/CustomSurveys/util/dialogFlowsUtil';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  survey: _CustomSurvey;
  onChangeTab: (tab: 'draft' | 'published') => void;
}

const statusLangProps: Record<string, string> = {
  draft: 'surveys.status.draft',
  published: 'surveys.status.published',
  closed: 'surveys.status.closed',
};

const colors: Record<string, 'dark' | 'success'> = {
  draft: 'dark',
  published: 'success',
};
export const CustomSurveysOverviewCard: React.FC<_Props> = ({onChangeTab, survey}) => {
  const {currentLanguage} = useLanguage();
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();
  const onDuplicate = async (survey: _CustomSurvey): Promise<void> => {
    const newId = await duplicateSurveyAction(survey.id);

    if (newId) {
      snackbar.success(trans('surveys.duplicate_success'));
      reloadContentEngine();
      onChangeTab(_CustomSurveyStatus.DRAFT);
    } else {
      snackbar.danger(trans('base.error'));
    }
  };
  const onPublish = async (survey: _CustomSurvey): Promise<void> => {
    fireConfirmationDialog({
      title: trans('surveys.publish_confirm_title'),
      onConfirm: async () => {
        const success = await publishSurveyAction(survey.id);

        if (success) {
          snackbar.success(trans('surveys.publish_success'));
          reloadContentEngine();
          onChangeTab(_CustomSurveyStatus.PUBLISHED);
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onClose = async (survey: _CustomSurvey): Promise<void> => {
    fireConfirmationDialog({
      title: trans('surveys.close_confirm_title'),
      onConfirm: async () => {
        const success = await closeSurveyAction(survey.id);

        if (success) {
          snackbar.success(trans('surveys.close_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onEdit = (survey: _CustomSurvey): void => {
    navigateToFlow('CustomSurvey', {customSurveyId: survey.id});
  };
  const onDelete = async (survey: _CustomSurvey): Promise<void> => {
    fireConfirmationDialog({
      title: trans('surveys.delete_confirm_title'),
      onConfirm: async () => {
        const success = await deleteSurveyAction(survey.id);
        if (success) {
          snackbar.success(trans('surveys.delete_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onShare = async (survey: _CustomSurvey): Promise<void> => {
    // TODO: Could be its own popup flow
    // const success = await shareSurveyAction(survey.id);
    //
    // if (success) {
    //   snackbar.success(trans('surveys.delete_success'));
    // }
    // Fires a dialog flow
    shareCustomSurveyFlow(survey.id);
  };

  const getActions = (): Array<{label: string; onChange: () => void; icon: React.FC}> => {
    const result = [];

    if (![_CustomSurveyStatus.PUBLISHED, _CustomSurveyStatus.CLOSED].includes(survey.status)) {
      result.push({
        label: trans('surveys.actions.publish'),
        onChange: async () => await onPublish(survey),
        icon: IconSend,
      });
    }

    if (survey.status === _CustomSurveyStatus.DRAFT) {
      result.push({
        label: trans('surveys.actions.edit'),
        onChange: () => {
          onEdit(survey);
        },
        icon: IconSurvey,
      });
    }

    if (survey.status === _CustomSurveyStatus.PUBLISHED) {
      result.push({
        label: trans('surveys.actions.share'),
        onChange: () => {
          onShare(survey);
        },
        icon: IconShare,
      });

      if (survey.link) {
        result.push({
          label: trans('surveys.actions.copy_link'),
          onChange: () => {
            navigator.clipboard.writeText(survey.link);
            snackbar.success(trans('base.copied_successfully'));
          },
          icon: IconLink,
        });
      }
    }

    if (survey.status === _CustomSurveyStatus.PUBLISHED) {
      result.push({
        label: trans('surveys.actions.close'),
        onChange: () => {
          onClose(survey);
        },
        icon: IconCancel,
      });
    }

    if (survey.canSeeResults) {
      result.push({
        label: trans('surveys.actions.see_results'),
        onChange: () => {
          navigateToFlow('CustomSurvey', {customSurveyId: survey.id, seeResult: true});
        },
        icon: IconShow,
      });
    }

    result.push({
      label: trans('surveys.actions.duplicate'),
      onChange: () => {
        onDuplicate(survey);
      },
      icon: IconCopy,
    });
    result.push({
      label: trans('surveys.actions.delete'),
      onChange: () => {
        onDelete(survey);
      },
      icon: IconTrash,
    });
    return result;
  };
  return (
    <Card
      titleVariant="h5"
      titleClassName="w-full"
      titleSuffix={
        <div className="flex flex-row flex-grow justify-end">
          <Badge value={trans(statusLangProps[survey.status])} color={colors[survey.status]} size="fit" />
        </div>
      }
      title={survey.title?.[currentLanguage] ?? survey.title?.default ?? ''}
    >
      <div className="flex flex-row h-full w-full justify-between">
        <div className="flex flex-col w-full h-full gap-2">
          <AvatarUser label={survey.createdBy?.fullName} image={getProfilePic(survey.createdBy)} />

          <div className="flex flex-row flex-grow gap-1">
            {trans('surveys.questions_count', {
              count: survey.questions?.length,
            })}
            {survey.status === _CustomSurveyStatus.PUBLISHED && (
              <>
                <div>|</div>
                {trans('surveys.questions_answered_counts', {
                  count: survey.questionsAnsweredCount,
                })}

                <div>|</div>
                {trans('surveys.participants_count', {
                  count: survey.surveyAnswersCount,
                })}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          {Boolean(survey.canSeeResults) && (
            <Button
              variant="secondary"
              onClick={() =>
                navigateToFlow('CustomSurvey', {
                  customSurveyId: survey.id,
                  seeResult: true,
                })
              }
            >
              {trans('surveys.actions.see_results')}
            </Button>
          )}
          <ContextMenu triggerIcon={IconMore} menuItems={getActions()} />
        </div>
      </div>
    </Card>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Slider} from '@modules/Core/components/base/Slider';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {
  personalityTraitsExplanationLangProps,
  personalityTraitsLangProps,
} from '@modules/Profile/config/servicesLangProps';
import {_ProfilePersonalityTraitsResult} from '@modules/Profile/types/service.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceGraphItemProps {
  data: _ProfilePersonalityTraitsResult;
  profilePicture: string;
}

const order = ['openMindedness', 'conscientiousness', 'extraversion', 'agreeableness', 'emotionalStability'];

export const ProfilePersonalityTraitsItem: React.FC<_ProfileServiceGraphItemProps> = ({data, profilePicture}) => {
  logger.debug('ProfilePersonalityTraitsItem', {
    data,
  });

  return (
    <Section gap={5}>
      {Object.keys(data ?? {})
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map(key => (
          <Slider
            key={key}
            title={trans(personalityTraitsLangProps[key])}
            leftText={trans('profile_result.low')}
            rightText={trans('profile_result.high')}
            components={[
              {
                id: key,
                progress: (data[key].value / 5) * 100,
                component: <Avatar size="lg" image={profilePicture ?? getProfilePic(null)} indicator="success" />,
              },
            ]}
            bottomSubtitle={trans(personalityTraitsExplanationLangProps[key])}
            showExpand
          />
        ))}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_PaymentMode} from '@modules/ContentEngine/components/items/payment/PaymentItem';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Divider} from '@modules/Core/components/base/Divider';
import {Typography} from '@modules/Core/components/base/Typography';
import {Address} from '@modules/Core/components/platform/address/Address';
import {PaymentDetailsBillingInterval} from '@modules/Core/components/platform/payments/details/PaymentDetailsBillingInterval';
import {PaymentDetailsLegalText} from '@modules/Core/components/platform/payments/details/PaymentDetailsLegalText';
import {PaymentDetailsPrice} from '@modules/Core/components/platform/payments/details/PaymentDetailsPrice';
import {PaymentPlanTitle} from '@modules/Core/components/platform/payments/paymentPlan/PaymentPlanTitle';
import {PlanPerksComponent} from '@modules/Core/components/platform/payments/paymentPlan/PlanPerksComponent';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {_Address} from '@modules/Core/types/address.model';
import {_PaymentPlan, _PaymentPriceDetails} from '@modules/Core/types/payment.model';
import {isValidAddress} from '@modules/Core/util/addressUtil';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {fetchUpcomingPrice, onCreatePayLink} from '@modules/Core/util/payment/paymentEventHandlers';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';
import {_InstitutionProfile, _Profile} from '@modules/Profile/types/profile.model';
import {hasDiscount, isOneTimePaymentSubscription} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

export interface _PaymentDetailsDialogInput {
  newPlan: _PaymentPlan;
  mode: _PaymentMode;
  forProfileIds: string[];
}

export interface _PaymentDetailsDialogProps extends _PaymentDetailsDialogInput {
  open: boolean;
  setOpen: (open: boolean) => void;
  profile: _Profile | null;
  institution?: _Institution | null;
  profiles: Record<string, _InstitutionProfile>;
}

export const PaymentDetailsDialog: React.FC<_PaymentDetailsDialogProps> = ({
  open,
  setOpen,
  newPlan,
  mode,
  profile,
  forProfileIds,
  institution,
  profiles,
}) => {
  const snackbar = useSnackbar();

  const [address, setAddress] = useState<_Address | null>(null);
  const [billingInterval, setBillingInterval] = useState<_SubscriptionBillingInterval>('monthly');

  const [paymentDetails, setPaymentDetails] = useState<_PaymentPriceDetails | null>(null);

  useEffect(() => {
    setAddress(institution?.address ?? null);
  }, [institution, profile, mode]);

  useEffectAsync(async () => {
    if (!profile || !['profile', 'institution'].includes(mode) || !forProfileIds.length || !newPlan || !institution) {
      return;
    }
    const newPaymentDetails = await fetchUpcomingPrice(
      profile.id,
      billingInterval,
      newPlan.id,
      forProfileIds,
      institution.id,
      mode === 'profile' ? hasDiscount(profile) : hasDiscount(profiles[forProfileIds[0]])
    );

    setPaymentDetails(newPaymentDetails);
  }, [profile, billingInterval, newPlan, forProfileIds, mode]);

  const onConfirm = (e: React.FormEvent): void => {
    e.preventDefault();
    if (!profile || !['profile', 'institution'].includes(mode) || !forProfileIds.length) return;
    if (!address || !isValidAddress(address)) {
      snackbar.danger(trans('institution.please_fill_in_address'));
      return;
    }
    const isDiscount = mode === 'profile' ? hasDiscount(profile) : hasDiscount(profiles[forProfileIds[0]]);
    dispatchReactEvent('navigate.start');
    void onCreatePayLink(profile.id, billingInterval, newPlan.id, forProfileIds, address, mode, isDiscount);
  };
  return (
    <form onSubmit={onConfirm}>
      <Dialog
        size="md"
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {}}
        confirmLabel={trans('subscription.confirm_and_pay')}
        confirmProps={{type: 'submit', width: 'relative-col-width-3'}}
        showCloseButton
        title={
          <div className="flex flex-row items-center gap-2">
            <PaymentPlanTitle plan={newPlan} />
          </div>
        }
      >
        <div className="flex flex-col gap-3  scroll-auto">
          <PlanPerksComponent plan={newPlan} expandable />

          <Divider light />

          {!isOneTimePaymentSubscription(newPlan?.id) && (
            <>
              <Typography variant="body1">{trans('subscription.sections.billing_interval')}</Typography>
              <PaymentDetailsBillingInterval
                billingInterval={billingInterval}
                setBillingInterval={setBillingInterval}
                newPlan={newPlan}
              />
            </>
          )}

          <Typography variant="body1">{trans('subscription.sections.address')}</Typography>
          <Address address={address} setAddress={setAddress} required />
          <div className="flex flex-col justify-end">
            <div className="realtive-col-width-3">
              <PaymentDetailsPrice paymentDetails={paymentDetails} newPlan={newPlan} />
            </div>
          </div>
          <PaymentDetailsLegalText />
        </div>
      </Dialog>
    </form>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Tab} from '@modules/Core/components/base/Tab';
import {trans} from '@modules/Translations/util/i18n';

export interface _Tab {
  key: 'login' | 'register';
  displayValue: string;
}

export interface _RegisterLoginTabsProps {
  showLoginTab?: boolean;
  showRegisterTab?: boolean;
  activeTab: 'login' | 'register';
  setActiveTab: (tab: 'login' | 'register') => void;
}

export const RegisterLoginTabs: React.FC<_RegisterLoginTabsProps> = ({
  activeTab,
  setActiveTab,
  showLoginTab,
  showRegisterTab,
}) => {
  const [tabs, setTabs] = useState<_Tab[]>([]);

  useEffect(() => {
    const result: _Tab[] = [];
    if (showLoginTab) {
      result.push({
        key: 'login',
        displayValue: trans('base.login'),
      });
    }

    if (showRegisterTab) {
      result.push({
        key: 'register',
        displayValue: trans('register.register'),
      });
    }

    setTabs(result);
  }, [showLoginTab, showRegisterTab, trans]);

  return (
    <div className="flex flex-row justify-start w-full gap-3">
      {tabs.map(tab => (
        <div className="relative-col-width-2" key={tab.key}>
          <Tab
            label={tab.displayValue}
            active={activeTab === tab.key}
            onChange={() => setActiveTab(tab.key)}
            dusk={tab.key}
          />
        </div>
      ))}
    </div>
  );
};

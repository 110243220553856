// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {AvatarGroup} from '@modules/Core/components/base/avatar/AvatarGroup';
import {TeamProgressSlider} from '@modules/Core/components/base/TeamProgressSlider';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {TeamMembersModal} from '@modules/Team/components/Members/TeamMembersModal';

interface _Props {
  module: {
    progress: Record<string, number>;
  };
  profiles: Record<string, _Profile>;
  chosenProfiles: _Profile[];
  setChosenProfiles: (profiles: _Profile[]) => void;
  showModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
}

export const TeamDevelopmentProgressBar: React.FC<_Props> = ({
  showModal,
  setShowModal,
  chosenProfiles,
  setChosenProfiles,
  module,
  profiles,
}) => {
  const [progress, setProgress] = useState<Record<string, _Profile[]>>({});

  useEffect(() => {
    const newProgress: Record<string, _Profile[]> = {};

    for (const profileId of Object.keys(module?.progress || {})) {
      const profileProgress = Math.floor(module?.progress[profileId]);

      const profile = profiles?.[profileId];

      if (!profile) {
        continue;
      }

      if (!Object.keys(newProgress).includes(profileProgress.toString())) {
        newProgress[profileProgress] = [];
      }

      newProgress[Math.floor(profileProgress)].push(profile);
    }

    setProgress(newProgress);
  }, [module, profiles]);

  function onOpenProfilesList(toOpenProfiles: _Profile[]) {
    setShowModal(true);
    setChosenProfiles(toOpenProfiles);
  }

  const teamProgress =
    Object.values(module?.progress || {}).reduce((sum, value) => sum + value, 0) /
    Object.values(module?.progress || {}).length;

  return (
    <>
      <TeamMembersModal
        transparencyAccepted
        profiles={chosenProfiles}
        open={showModal}
        onClose={() => {
          setShowModal?.(false);
          setChosenProfiles([]);
        }}
      />
      <TeamProgressSlider
        value={teamProgress}
        leftComponent={Object.entries(progress || {}).map(([progress, progressProfiles], index) => {
          const MAX_PROGRESS = 100;
          // calculate left & top for the avatar using progress,
          const left = `${(parseInt(progress, 10) / MAX_PROGRESS) * 100}%`;
          if (progressProfiles.length === 0) {
            return null;
          }

          // 32 px width of avatar, 16 half of that
          if (progressProfiles?.length === 1) {
            return (
              <div
                className="absolute"
                style={{
                  left: `calc(${left} - 16px)`,
                  zIndex: 21,
                }}
                key={index}
              >
                <Avatar size="md" image={getProfilePic(progressProfiles[0])} />
              </div>
            );
          }

          return (
            <div className="absolute" style={{left: `calc(${left} - 32px)`, zIndex: 21}} key={index}>
              <AvatarGroup
                onBadgeClick={() => onOpenProfilesList(progressProfiles)}
                size="md"
                badge={`+${progressProfiles.length - 1}`}
              >
                <Avatar size="md" image={getProfilePic(progressProfiles[0])} />
              </AvatarGroup>
            </div>
          );
        })}
      />
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import {AbstractValidator} from './abstractValidator';

export class MinLengthValidator extends AbstractValidator {
  validate(value: string): boolean {
    return value?.length >= this.options.value;
  }
}

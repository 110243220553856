// @ts-nocheck
/* eslint-disable */

import React, {createContext, useContext, useState, useEffect, useRef} from 'react';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';

// Create a context for the loader
const LoaderContext = createContext<{
  startLoading: () => void;
  stopLoading: () => void;
  loadRequest: (callbackFn: any, params?: any[]) => any;
  resetLoading: () => void;
  loading: number;
  isLoading: boolean;
  showLoadingIndicator: boolean;
  setChildLoaderCount: React.Dispatch<React.SetStateAction<number>>;
  hasChildLoader: boolean;
}>({
  startLoading: () => {},
  stopLoading: () => {},
  loadRequest: () => {},
  resetLoading: () => {},
  loading: -1,
  isLoading: true,
  showLoadingIndicator: true,
  setChildLoaderCount: () => {},
  hasChildLoader: false,
});

interface _LoaderProviderProps {
  children: React.ReactNode;
}

// Create a provider component for the loader
export const LoaderProvider: React.FC<_LoaderProviderProps> = ({children}) => {
  const [count, setCount] = useState<number>(0);
  const [isNavigate, setIsNavigate] = useState<boolean>(true);
  const isNavigateRef = useRef<boolean>(isNavigate);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(true);
  const [loadingIndicatorStart, setLoadingIndicatorStart] = useState<number | null>(null);
  const [childLoaderCount, setChildLoaderCount] = useState<number>(0);
  const [loadNavigateCount, setLoadNavigateCount] = useState<number>(0);

  // add event listeners
  useEffect(() => {
    // loader
    addReactEventListener('loader-started', startLoading);
    addReactEventListener('loader-stopped', stopLoading);
    // navigate
    addReactEventListener('navigate.start', startNavigate);
    addReactEventListener('navigate.load', loadNavigate);
    addReactEventListener('navigate.done', stopNavigate);

    return () => {
      // loader
      removeReactEventListener('loader-started', startLoading);
      removeReactEventListener('loader-stopped', stopLoading);
      // navigate
      removeReactEventListener('navigate.start', startNavigate);
      removeReactEventListener('navigate.load', loadNavigate);
      removeReactEventListener('navigate.done', stopNavigate);
    };
  }, []);

  useEffect(() => {
    setIsLoading(isNavigate || loadNavigateCount > 0 || count === null || count > 0);
    logger.debug('navigate.logger isLoading', [isNavigate, loadNavigateCount > 0, count === null, count > 0]);
  }, [isNavigate, count, loadNavigateCount]);

  useEffect(() => {
    logger.debug('navigate.logger isNavigate', isNavigate);
    isNavigateRef.current = isNavigate;
  }, [isNavigate]);

  useEffect(() => {
    if (isLoading) {
      setLoadingIndicatorStart(Date.now());
      setShowLoadingIndicator(true);
    } else {
      setTimeout(() => {
        const timeDiff = Date.now() - (loadingIndicatorStart ?? 0);
        const minimumDelay = 500;
        if (timeDiff < minimumDelay) {
          setTimeout(() => {
            setShowLoadingIndicator(isLoading);
          }, minimumDelay - timeDiff);
        } else {
          setShowLoadingIndicator(isLoading);
        }
      }, 300);
    }
  }, [isLoading, showLoadingIndicator]);

  const startNavigate = (): void => {
    setIsNavigate(true);
  };

  const loadNavigate = (): void => {
    logger.debug('navigate.load', isNavigateRef.current);
    if (!isNavigateRef.current) {
      return;
    }
    setLoadNavigateCount(prevCount => (prevCount ?? 0) + 1);
  };

  const stopNavigate = (): void => {
    setIsNavigate(false);
    setLoadNavigateCount(prevCount => Math.max(0, (prevCount ?? 0) - 1));
  };

  const startLoading = (): void => {
    setCount(prevCount => (prevCount ?? 0) + 1);
  };

  const stopLoading = (): void => {
    setCount(prevCount => Math.max(0, (prevCount ?? 0) - 1));
  };

  const loadRequest = (callbackFn: any, params = []): void => callbackFn?.(...params);

  const resetLoading = (): void => {
    setIsNavigate(false);
    setCount(0);
  };

  const hasChildLoader = childLoaderCount !== null && childLoaderCount > 0;

  return (
    <LoaderContext.Provider
      value={{
        startLoading,
        stopLoading,
        loadRequest,
        resetLoading,
        loading: count ?? -1,
        isLoading,
        showLoadingIndicator,
        setChildLoaderCount,
        hasChildLoader,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

// Create the useLoader hook
export const useLoader = (): React.ContextType<typeof LoaderContext> => {
  const context = useContext(LoaderContext);

  if (context === undefined) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
};

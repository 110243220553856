// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';
import {convertKey} from '@modules/SurveyEngine/util/questionUtil';

interface _SurveyRadioTypeProps {
  question: _SurveyQuestion;
  onValueChosen: (question: any, value: any) => void;
  answers: any;
}

export const SurveyRadioType: React.FC<_SurveyRadioTypeProps> = ({question, onValueChosen, answers}) => (
  <>
    {question?.subQuestion && (
      <p className="text-center">
        <strong>{question?.subQuestion}</strong>
      </p>
    )}
    <div
      className={twMerge(
        'flex flex-row gap-3',
        Object.entries(question?.answers || {}).length === 2 ? 'justify-center' : null
      )}
    >
      {Object.entries(question?.answers || {})?.map(([key, val], index) => (
        <div key={key} className="relative-col-width-2">
          <RadioButton
            forSurvey
            border
            key={key}
            label={val}
            active={answers[convertKey(question?.key)] === key}
            onChange={value => onValueChosen(question, key)}
          />
        </div>
      ))}
    </div>
  </>
);

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconMore, IconNote, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import {Typography} from '@modules/Core/components/base/Typography';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {JournalTitle} from '@modules/Profile/components/journals/JournalTitle';
import {_Journal} from '@modules/Profile/types/journal.model';
import {deleteJournal} from '@modules/Profile/util/journalUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Content {
  data: _Journal[];
  onUpdate?: () => void;
  onEditJournal?: (journal: _Journal) => void;
}

export const JournalContent: React.FC<_Content> = ({data, onUpdate, onEditJournal}) => {
  const snackbar = useSnackbar();
  const dialog = useDialog();

  function onEditJournalClick(journal: _Journal): void {
    onEditJournal?.(journal);
  }

  async function onDeleteConfirmed(journalId: string): Promise<void> {
    const success = await deleteJournal(journalId);

    if (success) {
      snackbar.success(trans('profile.journal.delete_journal'));
      onUpdate?.();
    }
  }

  function onDeleteJournalClick(journalId: string): void {
    dialog.show({
      title: trans('profile.journal.delete_journal_title'),
      onConfirm: () => {
        void onDeleteConfirmed(journalId);
      },
    });
  }

  return (
    <div className="flex flex-col gap-3">
      {data.map(note => (
        <div key={note.id} className="flex flex-row gap-3 p-2 rounded-sm border-1 border-grey100">
          <div className="flex flex-col flex-grow">
            {note.title ? <JournalTitle title={note.title} /> : null}
            <div key={note.journal} className="flex flex-col gap-0">
              <Typography
                style={{
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              >
                {note.journal}
              </Typography>
              <div className={`flex flex-row justify-${note.date ? 'between' : 'end'}`}>
                {note.date ? <Typography>{formatDateTime(new Date(note.date))}</Typography> : null}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <ContextMenu
              triggerIcon={IconMore}
              menuItems={[
                {
                  label: trans('profile.journal.edit_note'),
                  onChange: () => onEditJournalClick(note),
                  icon: IconNote,
                },
                {
                  label: trans('profile.journal.delete_note'),
                  onChange: () => {
                    onDeleteJournalClick(note.id);
                  },
                  icon: IconTrash,
                },
              ]}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {ACTION_STATUS_ERROR, ACTION_STATUS_SUCCESS, DEFAULT_ERROR_MESSAGE} from '@modules/Core/config/constants';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {generate} from '../components/base/helper';
import {zustandApi} from './logic/zustand';
import {danger, success} from './snackbar';

export const actionGeneratePromotionCode = async ({
  title,
  description,
  ctaText,
  action = 'trial',
  code,
  withVoucher = false,
  actionParams = {},
  planId,
}) => {
  const method = 'admin.promotion.create';
  const params = {
    title,
    description,
    ctaText,
    action,
    code,
    withVoucher,
    actionParams: JSON.stringify(actionParams),
    planId,
    // Add any other required params here
  };

  try {
    const response = await performFlowAction({
      method,
      params,
    });

    const responseData = response?.data;

    if (responseData?.status === 'OK') {
      return responseData;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const invokeAction = async (
  method,
  params,
  {showSnackbar = true, refetchProfile = false, loadingIndicator = false} = {}
) => {
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile,
        loadingIndicator,
      }
    );

    const responseData = response?.data;

    if (showSnackbar) {
      if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.message) {
        success(responseData?.message);
      } else if (!responseData || responseData?.status === ACTION_STATUS_ERROR) {
        danger(responseData?.message ?? DEFAULT_ERROR_MESSAGE);
      }
    }
    return responseData;
  } catch (e) {
    return null;
  }
};

export function isActionSuccess(responseData: _FlowActionResult): boolean {
  return responseData?.status === ACTION_STATUS_SUCCESS;
}

export function isActionError(responseData: _FlowActionResult): boolean {
  return responseData?.status === ACTION_STATUS_ERROR;
}

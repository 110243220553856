// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {TodoList} from '@modules/Core/components/base/todo-list/TodoList';
import {logger} from '@modules/Core/util/Logger';
import {_TeamDevelopmentTask} from '@modules/Team/types/teamdevelopment.model';
import {
  addTeamDevelopmentTaskAction,
  deleteTeamDevelopmentTaskAction,
  updateTeamDevelopmentTaskAction,
} from '@modules/Team/util/teamDevUtil';
import {trans} from '@modules/Translations/util/i18n';

const variantLangProps: Record<
  string,
  {
    title: string;
    placeholder?: string;
  }
> = {
  needs: {
    title: 'development_result.team_answers.what_do_we_need',
    placeholder: 'development_result.team_answers.add_to_do_placeholder',
  },
  actions: {
    title: 'development_result.team_answers.how_can_we_do_this',
    placeholder: 'development_result.team_answers.add_to_do_placeholder_2',
  },
};

interface _Props {
  module: string;
  variant: 'needs' | 'actions';
  teamId: string;
  answers: _TeamDevelopmentTask[];
}

const variantGroupMap: Record<'needs' | 'actions', string> = {
  needs: 'team_development_{module}_todo_need',
  actions: 'team_development_{module}_todo_action',
};
export const TeamAnswersTodoList: React.FC<_Props> = ({module, answers, variant, teamId}) => {
  const [items, setItems] = useState<Array<{displayValue: string; [key: string]: any}>>([]);

  useEffect(() => {
    if (answers) {
      setItems(
        answers
          .sort((a, b) => a.position - b.position)
          .map(answer => ({
            displayValue: answer.value,
            ...answer,
          }))
      );
    }
  }, [answers]);

  const onChange = async (
    newItems: Array<{
      displayValue: string;
      [key: string]: any;
    }>,
    action: 'delete' | 'update' | 'create',
    actionData: any
  ): Promise<void> => {
    logger.debug('onChange', {newItems, items, action, actionData});
    setItems(newItems);

    switch (action) {
      case 'delete':
        void deleteTeamDevelopmentTaskAction(actionData.id, teamId);
        break;
      case 'update':
        const item = items.find(i => i.id === actionData.id);
        if (!item) return;
        void updateTeamDevelopmentTaskAction(item.id, item.status, actionData.value, item.position, teamId);
        break;
      case 'create':
        const newTask = await addTeamDevelopmentTaskAction(
          variantGroupMap[variant]?.replace('{module}', module),
          actionData.value,
          teamId
        );

        logger.debug('addTeamDevelopmentTaskAction', {newTask});

        if (newTask) {
          // replace last item in newItems with newTask
          const updatedNewItems = newItems.slice(0, newItems.length - 1);
          updatedNewItems.push({
            displayValue: newTask.value,
            ...newTask,
          });

          setItems(updatedNewItems);
        }
        break;
    }
  };

  logger.debug('TeamAnswersTodoList', {answers, variant, teamId, items});

  return (
    <TodoList
      deleteDialogTitle={trans('base.delete_question')}
      deleteDialogText={trans('base.do_you_really_want_to_delete')}
      title={trans(variantLangProps[variant].title)}
      placeholder={trans(variantLangProps[variant].placeholder)}
      items={items?.sort((a, b) => a.position - b.position) || []}
      onChange={onChange}
    />
  );
};

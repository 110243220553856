// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {IVisualizationPanelOptions} from 'survey-analytics/survey-analytics.types/visualizationPanel';
import {Model} from 'survey-core';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {logger} from '@modules/Core/util/Logger';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {useRegisterSurveyJSIcons} from '@modules/CustomSurveys/hooks/RegisterSurveyJSIcons';
import {_CustomSurvey, _CustomSurveyAnswer} from '@modules/CustomSurveys/types/customSurvey.model';
import 'survey-analytics/survey.analytics.min.css';
import 'survey-core/defaultV2.min.css';
import {mapBackendToSurveyCreator} from '@modules/CustomSurveys/util/customSurveyMapperUtil';
import './CustomSurveyResult.css';
import {plotlyCenteredResponsiveLayout} from '@modules/CustomSurveys/util/plotlyLayoutsUtil';

interface _Props {
  survey: _CustomSurvey;
  answers: _CustomSurveyAnswer[];
  language?: string;
}

export const CustomSurveyResult: React.FC<_Props> = ({language, survey, answers}) => {
  const {startLoading, stopLoading, isLoading} = useLoader();
  const [surveyModel, setSurveyModel] = useState<Model | null>(null);
  const [vizPanel, setVizPanel] = useState<any | null>(null);
  const [extraRefresh, setExtraRefresh] = useState<boolean>(false);
  const scaleFactor = useUiStore(state => state.scaleFactor);
  useRegisterSurveyJSIcons();
  useEffect(() => {
    if (!extraRefresh) {
      return;
    }
    setExtraRefresh(false);
    vizPanel?.refresh();
  }, [extraRefresh]);
  const onPlotCreatingCallback = useCallback(
    (visualizer, options) => {
      import('survey-analytics').then(mod => {
        const {PlotlySetup} = mod;
        PlotlySetup.onPlotCreating.add(function (visualizer, options) {
          if (!options?.layout) {
            return;
          }
          if (options.layout.alreadyModified) {
            logger.debug('Layout already modified, skipping...');
            return;
          }

          logger.debug('onPlotCreating', {
            visualizer,
            options,
            scaleFactor,
            height: options.height,
            newHeight: options.layout.height * (scaleFactor ?? 1),
          });

          logger.debug('Modifying layout for responsive chart... type:', visualizer.chartType);
          options.layout = plotlyCenteredResponsiveLayout(options, scaleFactor, visualizer.chartType);

          options.layout.alreadyModified = true;
        });
      });
    },
    [scaleFactor]
  );

  useEffect(() => {
    // TODO: Solve dynamic import to import just once, lazy() approach isn't working for some reason
    import('survey-analytics').then(mod => {
      const {PlotlySetup} = mod;
      PlotlySetup.onPlotCreating.add(onPlotCreatingCallback);
    });
    return () => {};
  }, [scaleFactor]);

  useEffect(() => {
    import('survey-analytics').then(mod => {
      const VB = mod.VisualizerBase;
      VB.customColors = [
        '#1eb496',
        '#4ec46c',
        '#F0B856',
        '#F5D0BE',
        '#F8DFB4',
        '#BACED0',
        '#97DDDF',
        '#B6E0E2',
        '#C5E7E8',
        '#1E3B48',
        '#2B5C7B',
        '#457DA0',
        '#273444',
        '#3C4858',
        '#A8B6C8',
        '#6D7681',
        '#C1CCDA',
        '#D3DCE6',
      ].concat(['#86e1fb', '#3999fb', '#ff6771', '#ffc152', '#aba1ff', '#7d8da5', '#cf37a6', '#4e6198']);
    });
    startLoading();

    return () => {};
  }, []);
  useEffect(() => {
    if (!surveyModel) {
      const surveyJson = mapBackendToSurveyCreator(survey, language);
      const model = new Model(surveyJson);
      setSurveyModel(model);
    }
  }, [survey, surveyModel, language]);

  useEffect(() => {
    if (surveyModel && !vizPanel) {
      const surveyResults =
        answers?.map(answer => {
          const answerResult: Record<string, any> = {};
          answer.answers?.forEach(answerItem => {
            const question = survey.questions.find(q => q.id === answerItem.custom_survey_question_id);
            if (question) {
              answerResult[question.options?.name] = answerItem.answer;
            }
          });
          return answerResult;
        }) || [];

      // Use the dynamic import here
      import('survey-analytics').then(mod => {
        const VP = mod.VisualizationPanel;
        const vizPanelOptions: IVisualizationPanelOptions = {
          survey: surveyModel,
          allowExperimentalFeatures: false,
          allowHideQuestions: false,
          allowHideEmptyAnswers: false,
          allowSelection: false,
        };
        const panel = new VP(surveyModel.getAllQuestions(), surveyResults, vizPanelOptions);

        panel.onAfterRender.add((sender, options) => {
          // HTMLCollection
          const elements = options?.htmlElement?.children as HTMLCollectionOf<HTMLElement>;
          logger.debug('onAfterRender', {sender, options, elements});
          // transform styles
          // Array.from(elements).forEach(element => adjustTransformForScale(element, 0.75));
        });
        panel.showToolbar = false;
        panel.locale = language ?? 'de';

        panel?.visualizers.map(v => {
          logger.debug('Visualizer', v);
          // Remove visualizer with _type = wordcloud, and if visualizer._type = wordcloud, set it to first visualzier of visualizers that is NOT wordcloud
          const wordCloudVisualizers = v.visualizers?.find(viz => viz._type === 'wordcloud');
          if (wordCloudVisualizers) {
            const newVisualizers = v.visualizers.filter(viz => viz._type !== 'wordcloud');
            v.visualizers = newVisualizers;
            logger.debug('Found wordcloud visualizer, removed it and set first visualizer as visualizer.', {
              newVisualizers,
            });
          }

          if (v.visualizer?._type === 'wordcloud') {
            v.visualizer = v.visualizers[0];
          }
        });
        setVizPanel(panel);

        stopLoading();
      });
    }
  }, [surveyModel, answers, survey, language]);

  useLayoutEffect(() => {
    if (vizPanel && !isLoading) {
      const element = document.getElementById('surveyVizPanel');
      if (element) {
        element.innerHTML = '';
        vizPanel.render(element);
        setTimeout(() => {
          // if id is not found, the panel is not rendered
          if (!document.getElementById('surveyVizPanel')) {
            return;
          }
          logger.debug('Refreshing vizPanel...');
          window.dispatchEvent(new Event('resize'));
          vizPanel.refresh();
          setExtraRefresh(true);
        }, 500);
      }
    }
  }, [vizPanel, isLoading]);

  return <div id="surveyVizPanel" className="survey-result-container" style={{width: '100%', height: '100%'}} />;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {ToolTip} from '@modules/Core/components/base/ToolTip';

interface _ProgressBar {
  value: number;
  color?: 'success' | 'dark' | 'brand';
  size?: 'sm' | 'md' | 'xs';
  diff?: number;
  progressionColor?: string;
  tooltip?: string;
}

export const ProgressBar: React.FC<_ProgressBar> = ({
  tooltip,
  progressionColor = null,
  diff,
  value,
  color,
  size = 'md',
}) => {
  let bgColor = 'bg-brand700';
  if (color === 'success') bgColor = 'bg-green700';
  if (color === 'dark') bgColor = 'bg-prussian700';

  let finalSize = 'h-3';
  if (size === 'sm') finalSize = 'h-2';
  if (size === 'xs') finalSize = 'h-1';

  const showDiff = !!diff;

  const progression = diff ?? 0;

  const progress = value - progression;

  // Show identical div with opacity 0.5 starting from progress up to +diff% -> NOT SUPPORTED YET

  let progressContent;
  if (tooltip) {
    progressContent = (
      <ToolTip
        color="!text-white500"
        backgroundColor={bgColor}
        style={{transition: 'width 0.4s', width: `${progress}%`}}
        plc="top-right"
        txt={tooltip}
      >
        <div
          className={addCN(
            bgColor,
            finalSize,
            ' rounded-tl-lg rounded-bl-lg',
            showDiff ? '' : 'rounded-tr-lg rounded-br-lg',
            'w-full'
          )}
        />
      </ToolTip>
    );
  } else {
    progressContent = (
      <div
        className={addCN(
          bgColor,
          finalSize,
          ' rounded-tl-lg rounded-bl-lg',
          showDiff ? '' : 'rounded-tr-lg rounded-br-lg'
        )}
        style={{transition: 'width 0.4s', width: `${progress}%`}}
      />
    );
  }
  return (
    <div className={addCN('relative flex w-full flex-row justify-start items-center rounded-lg bg-grey500')}>
      {progressContent}
      {showDiff && (
        <div
          className={addCN(
            progressionColor ?? bgColor,
            finalSize,
            ' rounded-br-lg rounded-tr-lg absolute',
            progress > 0 ? '' : 'rounded-tl-lg rounded-bl-lg'
          )}
          style={{transition: 'width 0.4s', width: `${progression}%`, opacity: 0.5, left: `${progress}%`}}
        />
      )}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconEmail} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {refreshReduxState} from '@modules/State/util/util';
import {
  DONE_STATUS,
  IN_PROGRESS_STATUS,
  NOT_AVAILABLE_STATUS,
  NOT_STARTED_STATUS,
} from '@modules/Team/hooks/teamMembersProgress';
import {trans} from '@modules/Translations/util/i18n';

const statusLangPropertyMap: Record<string, string> = {
  not_upgraded: 'service.status.not_upgraded',
  not_started: 'service.status.open',
  in_progress: 'service.status.in_progress',
  done: 'service.status.done',
};

interface _Props {
  status: typeof DONE_STATUS | typeof NOT_STARTED_STATUS | typeof NOT_AVAILABLE_STATUS | typeof IN_PROGRESS_STATUS;
  currentRound: number;
  progress: number;
  service: string;
  profileId: string;
  teamId: string;
  institutionId: string;
}

export const SoftfactStatusRow: React.FC<_Props> = ({
  institutionId,
  teamId,
  profileId,
  service,
  status,
  progress,
  currentRound,
}) => {
  const snackbar = useSnackbar();
  async function sendReminder() {
    const method = teamId ? 'team.reminder' : 'institution.reminder';
    const params = {
      teamId,
      institutionId,
      profileIds: [profileId],
      service,
    };

    try {
      const response = await performFlowAction({
        method,
        params,
      });

      if (response.data?.status === 'success') {
        snackbar.success(trans(response?.data?.message));

        await refreshReduxState();
      } else {
        snackbar.danger(trans(response?.data?.message));
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
  }
  const Icon = getServiceIcon(service);
  return (
    <div className="flex flex-row w-full items-center justify-between rounded-sm border-1 border-grey300 px-3 py-2">
      <div className="flex flex-col justify-center gap-1">
        <div className="flex flex-row gap-1 items-center">
          {Icon && <Icon />}
          <Typography variant="body1">{trans(PROFILE_SERVICES_TITLES[service])}:</Typography>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Typography
            variant="body-bold"
            className={
              status === 'done' ? 'text-green700' : status === 'in_progress' ? 'text-orange700' : 'text-grey500'
            }
          >
            {trans(statusLangPropertyMap[status])}
          </Typography>
          {status !== 'done' && (
            <>
              {' '}
              <Typography className="text-grey500">
                {trans('profile_exercises.round', {roundIndex: currentRound + 1})}
              </Typography>
            </>
          )}
        </div>
        {status !== 'done' && <ProgressBar value={progress} color="success" size="xs" />}
      </div>
      <div className="flex flex-row items-center flex-grow justify-end">
        <Button
          variant="light"
          width="relative-col-width-2"
          size="xs"
          icon={IconEmail}
          label={trans('base.send_reminder')}
          onClick={sendReminder}
        />
      </div>
      <div />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {success, warning, danger, info, show, _SnackbarSeverity} from '@modules/Core/util/snackbar';

export interface _SnackbarActions {
  success: (label: string) => void;
  warning: (label: string) => void;
  danger: (label: string) => void;
  info: (label: string) => void;
  show: (label: string, severity?: _SnackbarSeverity) => void;
}

const useSnackbar = (): _SnackbarActions => {
  return {
    success,
    warning,
    danger,
    info,
    show,
  };
};

export default useSnackbar;

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ActionMeta, SingleValue} from 'react-select';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {ADD_JOURNAL_XP_DUMMY_KEY} from '@modules/Gamification/config/constants';
import {getAllCategories} from '@modules/Profile/services/journalsApi';
import {_Journal} from '@modules/Profile/types/journal.model';
import {addNewJournal, updateJournal} from '@modules/Profile/util/journalUtil';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceKeyCount';
import {useTeams} from '@modules/Team/hooks/teams';
import {trans} from '@modules/Translations/util/i18n';

interface _CreateJournalFormProps {
  isOpen: boolean;
  onClose: (confirmed?: boolean) => void;
  onUpdateJournals?: (selectedGroupCategory: string, selectedCategory: string | null) => void;
  selectedJournal?: _Journal | null;
  selectedGroupCategory?: string | null;
  teamId?: string | null;
  filterCategoryId?: string | null;
  selectedGroup?: string | null;
  metadata?: Record<string, any> | null;
  exerciseView?: boolean;
}

interface _ShortJournal {
  id: string;
  category: string;
  journal: string;
}

const CreateJournalForm: React.FC<_CreateJournalFormProps> = ({
  isOpen,
  onClose,
  onUpdateJournals,
  selectedJournal,
  selectedGroupCategory,
  teamId,
  filterCategoryId,
  selectedGroup,
  metadata,
  exerciseView,
}) => {
  const [teams] = useTeams();
  const snackbar = useSnackbar();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [journalCategories, setJournalCategories] = useState<Record<string, _ShortJournal[]> | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(selectedJournal?.category_id ?? null);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState<string | null>(null);
  const [journalNote, setJournalNote] = useState<string | null>(selectedJournal?.journal ?? null);
  const [categoryDisabled, setCategoryDisabled] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);

  useEffect(() => {
    void getJournalCategories();
    if (isOpen) {
      setSelectedCategory(selectedJournal?.category_id ?? '');
      setJournalNote(selectedJournal?.journal ?? '');
      setSelectedTeam(selectedJournal?.team_id ?? '');
    }
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component is unmounted or the dialog is closed
    return () => clearInterval(intervalId);
  }, [isOpen, selectedJournal]);

  useEffect(() => {
    let selectedGroupName = '';
    let selectedCategoryName = '';
    if (selectedGroupCategory) {
      const selectedGroupCategoryNames = selectedGroupCategory.split('.');
      selectedGroupName = selectedGroupCategoryNames[0];
      selectedCategoryName = selectedGroupCategoryNames[1] !== 'undefined' ? selectedGroupCategoryNames[1] : '';
      const categoryId = getCategoryId(selectedGroupName, selectedCategoryName);
      setSelectedCategory(categoryId);
      setSelectedCategoryGroup(selectedGroupName);
    }
    if (teamId) {
      setSelectedTeam(teamId);
    }
    if (filterCategoryId) {
      setSelectedCategory(filterCategoryId);
    }
    if (selectedGroup) {
      if (!filterCategoryId) {
        const categoryId = getCategoryId(selectedGroup, '');
        setSelectedCategory(categoryId);
      }
      setSelectedCategoryGroup(selectedGroup);
    }
    // We disable ONLY when metadata and a filter are present.
    // As metadata is strictly connected to the journal category, we need to make sure that the category is selected.
    setCategoryDisabled((selectedJournal || selectedCategoryName) && !!metadata);
  }, [selectedGroupCategory, journalCategories, teamId, filterCategoryId, selectedGroup, selectedJournal]);

  async function getJournalCategories(): Promise<void> {
    try {
      const response = await getAllCategories();
      setJournalCategories(response?.data as Record<string, _ShortJournal[]>);
    } catch (err) {
      console.log('Error:', err);
    }
  }

  const getCategoryId = (selectedGroupName: string, selectedCategoryName: string): string | null => {
    if (journalCategories) {
      let category: _ShortJournal | undefined;
      if (selectedCategoryName) {
        category = journalCategories[selectedGroupName].find(cat => cat.category === selectedCategoryName);
      } else {
        category = journalCategories[selectedGroupName][0];
      }
      return category ? category.id : null;
    }
    return null;
  };

  const handleCategoryChange = (event: SingleValue<_SelectValue>, actionMeta: ActionMeta<_SelectValue>): void => {
    setSelectedCategory(event?.value as string);
    setSelectedTeam('');
    setSelectedCategoryGroup(event?.group as string);
  };

  const handleTeamChange = (event: SingleValue<_SelectValue>, actionMeta: ActionMeta<_SelectValue>): void => {
    setSelectedTeam(event?.value as string);
  };

  const handleNoteChange = (event: React.ChangeEvent<{value: string}>): void => {
    setJournalNote(event.target.value);
  };

  async function addNote(submitData: Record<string, string>): Promise<void> {
    const success = await addNewJournal(
      submitData.selectedCategory,
      submitData.selectedTeam,
      submitData.journalNote,
      metadata,
      selectedCategoryGroup
    );

    if (success) {
      onClose?.(true);
      if (onUpdateJournals) {
        onUpdateJournals(selectedCategoryGroup, selectedCategory);
      }

      snackbar.success(trans(exerciseView ? 'profile.journal_exercises.success' : 'profile.journal.success'));
      reloadContentEngine();
    }
  }

  async function updateNote(submitData: Record<string, string>): Promise<void> {
    if (!selectedJournal) {
      return;
    }
    const success = await updateJournal(selectedJournal.id, submitData.journalNote);
    if (success) {
      onClose?.(true);
      if (onUpdateJournals) {
        onUpdateJournals(selectedCategoryGroup, selectedCategory);
      }
      snackbar.success(trans('profile.journal.updated'));
    }
  }

  const handleSubmit = (): void => {
    if (!selectedCategory || !journalNote) return;
    const submitData = {
      selectedCategory,
      selectedTeam,
      journalNote,
    };
    if (selectedJournal) {
      void updateNote(submitData);
    } else {
      void addNote(submitData);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      dusk="journal_form_component"
      title={selectedJournal ? trans('profile.journal.edit_title') : trans('profile.journal.add_title')}
      titleSuffix={<ExperienceCount xpKey={ADD_JOURNAL_XP_DUMMY_KEY} />}
      showCloseButton
      footerVerticalAlign="center"
      buttons={[
        {
          label: selectedJournal ? trans('profile.journal.edit_btn') : trans('profile.journal.add_btn'),
          variant: 'primary',
          onClick: handleSubmit,
          disabled:
            !selectedCategory ||
            !journalNote ||
            ((selectedCategoryGroup === 'team_analysis' || selectedCategoryGroup === 'team_development') &&
              !selectedTeam),
        },
      ]}
      footerElements={
        <Typography className="justify-start" variant="body">
          {formatDateTime(currentTime)}
        </Typography>
      }
    >
      <Select
        options={Object.entries(journalCategories ?? {})
          .map(([group, groupCategories]) =>
            groupCategories.map(category => ({
              value: category.id,
              group,
              label: (
                <>
                  {groupCategories.length > 1 && `${trans(`profile.journal.groups.${group}`)} - `}
                  {trans(`profile.journal.categories.${group}.${category.category}`)}
                </>
              ),
            }))
          )
          .reduce((acc, val) => acc.concat(val), [])}
        onChange={handleCategoryChange}
        value={{
          value: selectedCategory,
          label: selectedCategory,
        }}
        disabled={categoryDisabled}
        label={trans('profile.journal.category_label')}
        placeholder={trans('profile.journal.select_category')}
      />
      {(selectedCategoryGroup === 'team_analysis' || selectedCategoryGroup === 'team_development') && (
        <Select
          label={trans('profile.journal.team_filter_label')}
          placeholder={trans('profile.journal.select_team')}
          options={teams?.map(team => {
            return {
              value: team.id,
              label: team.name,
            };
          })}
          onChange={handleTeamChange}
          value={{
            value: selectedTeam,
            label: teams?.find(team => team.id === selectedTeam)?.name,
          }}
        />
      )}
      <TextArea
        rows={8}
        value={journalNote}
        onChange={handleNoteChange}
        placeholder={trans('profile.journal.note_placeholder')}
        dusk="journalNote"
      />
    </Dialog>
  );
};

export default CreateJournalForm;

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {selectLabel} from '@modules/FlowEngine/util/helpers/itemSelectionUtil';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export interface _Item {
  id: string;

  [key: string]: any;
}

export const SelectStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [selectedItem, setSelectedItem] = useState<_Item | null>(null);
  const {selectedItem: previouslySelectedItem, items, labelSelector, hideButton, buttonLabel} = options;

  useEffect(() => {
    if (previouslySelectedItem) {
      setSelectedItem(previouslySelectedItem as _Item);
    }
  }, [previouslySelectedItem]);

  return (
    <BaseStep
      getValueCallbackFn={() => {
        return {
          selectedItem,
        };
      }}
      inputs={[selectedItem]}
      options={options}
    >
      <div className="flex flex-col gap-1">
        {items?.map(item => (
          <div>
            <RadioButton
              label={selectLabel(item, labelSelector)}
              // label={trans(option.label)}
              active={selectedItem?.id === item.id}
              onChange={() => setSelectedItem(item)}
            />
          </div>
        ))}
      </div>
      {!hideButton && !!selectedItem?.id && (
        <div className="flex flex-row justify-center">
          <div className="relative-col-width-2">
            <Button
              label={buttonLabel || trans('base.confirm')}
              onClick={() => {
                options?.onComplete?.();
              }}
            />
          </div>
        </div>
      )}
    </BaseStep>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Tab} from '@modules/Core/components/base/Tab';

export type _JournalTabs = Array<{
  key: string;
  label: string;
  component?: React.ElementType;
}>;

interface _Tabs {
  tabs: _JournalTabs;
  activeTab: string;
  setActiveTab: (prp: string) => void;
}

export const JournalTabs: React.FC<_Tabs> = ({tabs, activeTab, setActiveTab}) => {
  const handleTabState = (key: string): void => {
    if (activeTab !== key) setActiveTab(key);
  };

  return (
    <div className="flex gap-3">
      {tabs.map(tab => (
        <div key={tab.key} className="relative-col-width-2">
          <Tab
            key={tab.key}
            label={tab.label}
            active={activeTab === tab.key}
            onChange={() => handleTabState(tab.key)}
          />
        </div>
      ))}
    </div>
  );
};

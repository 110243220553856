// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  experiencePoints?: number;
  fullWidth?: boolean;
  label?: string;
  titleVariant?: _TypographyVariants;
}

export const ProfileExperienceCard: React.FC<_Props> = ({
  label = 'gamification.total_experience',
  fullWidth,
  experiencePoints,
  titleVariant = 'body',
}) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <img src={getStatisticImage('experiencelogo')} alt="XP" />

      <div className="flex flex-col w-full">
        <Typography bold variant={titleVariant}>
          {experiencePoints ?? '-'}
        </Typography>
        <Typography variant="body2">{trans(label)}</Typography>
      </div>
    </div>
  );
};

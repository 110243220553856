// @ts-nocheck
/* eslint-disable */

// import delete icon

import React, {useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {trans} from '@modules/Translations/util/i18n';

const defaultListId = 'defaultList';

interface _Props {
  onConfirm?: (newValue: any) => void;
  specialField: any;
  open: boolean;
  onClose: () => void;
  questionKey: string;
}

const questionLangPropsDict = {
  '10_Time Management': {
    placeholder: 'way_of_working.new_method_placeholder',
    label: 'way_of_working.new_method_label',
  },
  '7_Interaction': {
    placeholder: 'way_of_working.new_channel_placeholder',
    label: 'way_of_working.new_channel_label',
  },
};
export const NewValueDialog: React.FC<_Props> = ({questionKey, open, onConfirm, onClose, specialField}) => {
  const [newValue, setNewValue] = useState<string | null>(null);

  return (
    <Dialog
      showCloseButton
      open={open}
      onClose={onClose}
      onConfirm={() => {
        onConfirm?.(newValue);
        setNewValue(null);
      }}
      title={specialField?.label}
    >
      <Input
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onConfirm?.(newValue);
            setNewValue(null);
          }
        }}
        type="text"
        autoFocus
        className="w-100 my-2"
        name="newValueName"
        value={newValue}
        onChange={val => setNewValue(val)}
        placeholder={trans(questionLangPropsDict[questionKey]?.placeholder)}
        label={trans(questionLangPropsDict[questionKey]?.label)}
      />
    </Dialog>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Typography} from '@modules/Core/components/base/Typography';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {isMembershipSubscription, isOneTimePaymentSubscription} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentPlanTitleProps {
  plan: _PaymentPlan;
}

export const PaymentPlanTitle: React.FC<_PaymentPlanTitleProps> = ({plan}) => {
  if (!plan) {
    return null;
  }

  const isOneTimePayment = isOneTimePaymentSubscription(plan?.id);
  const isMembership = isMembershipSubscription(plan?.id);

  return (
    <>
      <Typography variant="h6">{trans(plan?.title)}</Typography>
      {isOneTimePayment && <Badge color="dark" size="fit" value={trans('subscription.one_time_payment')} />}

      {isMembership && <Badge color="dark" size="fit" value={trans('subscription.membership')} />}
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {trans} from '@modules/Translations/util/i18n';
import {IconCheck, IconPassword, IconShow} from '../../Icons/Icons';

interface _SoftFactTabBadge {
  badge: string;
}

export const SoftFactTabBadge: React.FC<_SoftFactTabBadge> = ({badge}) => {
  let text = trans('profile_modules.start');
  let badgeColor = 'bg-grey500';

  if (badge === 'first') {
    text = trans('profile_modules.start');
    badgeColor = 'bg-orange700';
  }

  if (badge === 'first-inner') {
    text = trans('profile_modules.start');
    badgeColor = 'bg-blue700';
  }

  if (badge === 'start') text = trans('profile_modules.start');

  if (badge === 'next') {
    text = trans('profile_modules.next');
    badgeColor = 'bg-blue700';
  }
  if (badge === 'progress') {
    text = trans('profile_modules.in_progress');
    badgeColor = 'bg-blue700';
  }

  if (badge === 'finished') {
    text = trans('profile_modules.finished');
    badgeColor = 'bg-green700';
  }

  let content = (
    <span
      className={addCN('text-caption !text-white500 rounded-sm justify-center items-center flex h-3 p-1', badgeColor)}
    >
      {text}
    </span>
  );

  if (badge === 'show') {
    content = (
      <div className="border-1 border-grey100 rounded-full w-3 h-3 flexCenterCenter">
        <IconShow size="sm" />
      </div>
    );
  }

  if (badge === 'locked') {
    content = (
      <div className="border-1 border-grey100 rounded-full w-3 h-3 flexCenterCenter">
        <IconPassword size="sm" />
      </div>
    );
  }

  return <div className={addCN('flex-col')}>{content}</div>;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Switch} from '@modules/Core/components/base/inputs/Switch';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {ProfileDevModeSection} from '@modules/Dev/components/ProfileDevModeSection';

interface _ProfileMotivesDevModeProps {
  isDevMode: boolean;
  setIsDevMode: (isDevMode: boolean) => void;
  motivesResult: boolean;
  setMotivesResult: (result: boolean) => void;
}

export const ProfileMotivesDevMode: React.FC<_ProfileMotivesDevModeProps> = ({
  isDevMode,
  setIsDevMode,
  motivesResult,
  setMotivesResult,
}) => {
  const handleChange = () => {
    setMotivesResult(!motivesResult);
  };

  logger.debug('ProfileMotivesDevMode', {isDevMode, motivesResult});

  return (
    <ProfileDevModeSection isDevMode={isDevMode} setIsDevMode={setIsDevMode}>
      <div className="flex flex-col gap-2">
        <Typography>
          Switching this on/off will make all motives as main motives, letting you see the different texts for each
          motive.
        </Typography>

        <div className="flex items-center justify-between">
          <Switch active={motivesResult} color="dark" onChange={handleChange} />
        </div>

        <Typography>{motivesResult ? 'Dev Mode' : 'Normal Mode'}</Typography>
      </div>
    </ProfileDevModeSection>
  );
};

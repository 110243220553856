// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileSatisfactionGraphItem} from '@modules/ContentEngine/components/items/profile/dashboard/ProfileSatisfactionGraphItem';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {useTeamSatisfactionPerformance} from '@modules/Team/hooks/development/teamSatisfactionPerformance';
import {_TeamDevelopmentSatisfactionPerformance} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teamId: string;
  data: _TeamDevelopmentSatisfactionPerformance;
}

export const TeamSatisfactionPerformance: React.FC<_Props> = ({teamId, data}) => {
  const {satPerfData, satisfaction, performance, newGraph} = useTeamSatisfactionPerformance(data);

  if (satPerfData?.length) {
    return <ProfileSatisfactionGraphItem data={satPerfData} />;
  }
  //
  // return (
  //   <div className="flex flex-row items-center justify-between flex-wrap">
  //     TODO
  //     <div className="relative-col-width-6">
  //       <Chart title={trans('base.team_satisfaction')} {...satisfaction} />
  //     </div>
  //     <div className="relative-col-width-6">
  //       <Chart title={trans('base.team_performance')} {...performance} />
  //     </div>
  //   </div>
  // );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {getSoftfactChallengesGoalsId} from '@modules/Core/util/idUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {ExerciseCardAdvanced} from '@modules/Profile/components/exercises/cards/ExerciseCardAdvanced';
import {
  _ProfileExercisesRound,
  _RoundData,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  roundIndex: number;
  round: _RoundData;
  currentRound: any;
  profileData: _ProfileExercisesRound;
}

export const ProfileExerciseRoundCard: React.FC<_Props> = ({service, roundIndex, round, currentRound, profileData}) => {
  const navigateToFlow = useNavigateToFlow();
  const {title, description, totalExperience} = round ?? {};

  // If round not found yet, no need for available shenanigans
  const {available} = profileData ?? {
    available: true,
  };

  const status = !profileData
    ? EXERCISE_STATUS_LOCKED
    : (profileData?.status as
        | typeof EXERCISE_STATUS_OPEN
        | typeof EXERCISE_STATUS_DONE
        | typeof EXERCISE_STATUS_STARTED
        | typeof EXERCISE_STATUS_LOCKED);

  const isLockedChallengesGoals = status === EXERCISE_STATUS_LOCKED && roundIndex <= currentRound;

  return (
    <ExerciseCardAdvanced
      card={{
        totalExperience,
        currentRound,
        round: roundIndex,
        roundProgress: profileData?.progress,
        type: status,
        available,
        content: [
          {
            title,
            description,
          },
        ],
        // Only show for open exercises
        action:
          [EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes(status) || isLockedChallengesGoals
            ? {
                title:
                  available && [EXERCISE_STATUS_OPEN, EXERCISE_STATUS_STARTED].includes(status)
                    ? trans('profile_exercises.see_all')
                    : null,
                trigger: () => {
                  if (available || [EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes(status)) {
                    navigateToFlow(FLOWS.ProfileExercises, {
                      round: roundIndex,
                      service,
                    });
                  }

                  // Challenges & goals not filled, go to soft fact result page
                  if (isLockedChallengesGoals) {
                    navigateToFlow(getSurveyFlow(service).name, {
                      scrollTarget: getSoftfactChallengesGoalsId(service),
                    });
                  }
                },
              }
            : null,
      }}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';
import {COMPONENT, COMPONENT_LOAD, ERROR} from '../paths';

async function getComponent(componentURL: string): Promise<any> {
  return await httpClient.get(`${COMPONENT}/${componentURL}`);
}

async function getError(componentURL: string): Promise<any> {
  return await httpClient.get(`${ERROR}/${componentURL}`);
}

/**
 * This loads a single component, different from 'getComponent' which loads a page
 */
async function loadComponent(componentName: string, body: any): Promise<any> {
  return await httpClient.post(COMPONENT_LOAD, {
    componentName,
    ...body,
  });
}

export {getComponent, getError, loadComponent};

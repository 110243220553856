// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';

export interface _Tab {
  label: string;
  active: boolean;
  onChange: (active: boolean) => void;
  disabled?: boolean;
  icon?: React.ElementType;
  // TODO ElementType is a bad idea I think, should revisit this
  iconElement?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  dusk?: string;
  className?: string;
  color?: string;
}

export const Tab: React.FC<_Tab> = ({
  label,
  active,
  onChange,
  disabled,
  icon: Icon,
  iconElement,
  iconPosition = 'left',
  iconColor,
  dusk,
  className,
  color,
}) => {
  const handleChange = (): void => {
    if (!disabled) onChange(!active);
  };

  return (
    <button
      onClick={handleChange}
      disabled={disabled}
      className={twMerge(
        'transition flex w-full h-8 p-1 justify-center items-center gap-1 shrink-0 border-1 rounded-xs',
        'bg-white500 hover:bg-grey100 disabled:bg-grey500',
        'border-grey300 disabled:border-grey300',
        `data-active:border-${color || 'blue700'}`,
        className
      )}
      dusk={dusk}
      data-ui={active ? 'active' : 'inactive'}
    >
      {iconPosition === 'left' && (
        <>
          {Icon && <Icon color={iconColor} />}
          {iconElement && iconElement}
        </>
      )}
      <span className={twMerge(disabled && '!text-white500', 'select-none')}>{label}</span>
      {iconPosition === 'right' && (
        <>
          {Icon && <Icon color={iconColor} />}
          {iconElement && iconElement}
        </>
      )}
    </button>
  );
};

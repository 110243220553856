// @ts-nocheck
/* eslint-disable */

import {trim} from 'lodash';
import {getLastItem} from '@modules/Core/util/arrays';
import {isArray, isString} from '@modules/Core/util/typesUtil';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';

// TODO @Sherif types
export function parseQuestion(survey, question, answers, currentLoop = null) {
  for (const entry of Object.entries(answers || {})) {
    const key = entry[0];
    let value = entry[1];
    let localKey = key.replaceAll('/^[^\\.]*\\./', '');

    // remove loop
    const loopMatch = localKey.match('/[.*]/') || [];
    localKey = localKey.replaceAll('/[.*]/', '');

    let loop = null;
    if (loopMatch.length > 0) {
      loop = loopMatch[0].replaceAll('/[][]/', '');
    }

    let referencedQuestion = null;

    // find question for current answer
    for (const refQuestion of Object.values(survey?.questions)) {
      if (convertKey(refQuestion.key) === key) {
        referencedQuestion = refQuestion;
        break;
      }
    }

    const matches = question.match('/{{[^}]*}}/');

    for (const match of matches || []) {
      question = question.replaceAll(match, convertKey(match));
    }

    if (
      referencedQuestion &&
      answers[key] &&
      referencedQuestion?.answers &&
      isString(answers[key]) &&
      referencedQuestion?.answers[answers[key]]
    ) {
      const answer = referencedQuestion?.answers[answers[key]];
      question = question.replaceAll(`{{${localKey}}}`, answer);
    } else if (loop === currentLoop || ['loop'].includes(localKey)) {
      try {
        if (isArray(value)) {
          // @TODO(?) @Sherif @Stefan: do this somehow
          // const property = PropertySynonym::find(value[0]);
          const property = null;
          value = 'something went wrong';
          if (property) {
            value = property?.name;
          }
        }
        question = question.replaceAll(`{{${localKey}}}`, value);
      } catch (e) {
        question = question.replaceAll(`{{${localKey}}}`, `ERROR: ${e}`);
      }
    }
  }
  return handleBR(question);
}

export function parseButtons(buttons) {
  const answers = [];

  for (const [key, value] of Object.entries(buttons)) {
    answers.push({
      value: key,
      text: value,
    });
  }
  return answers;
}

export function isIntroQuestion(question) {
  // @TODO(?) @Sherif @Stefan .. I think hte code in laravel is dead/broken
  return question?.type === 'intro';
}

export function convertKey(key: string): string {
  return key?.replace('.', '_');
}

/**
 * Special fields
 */
export function getSpecialField(answer: string): {type: string; label: string} | null {
  if (!answer) {
    return null;
  }
  // e.g. [text:Weitere]
  // e.g. [textarea:Weitere]
  const matches = answer.match('^\\[(text|textarea):(.+)\\]');
  if (matches) {
    return {
      type: matches[1],
      label: matches[2],
    };
  }
  return null;
}

export function isSpecialFieldAnswer(key: string): boolean {
  return key && !key.match(/[a-z]_\d+/i);
}

export function getSpecialFieldKey(key: string): string {
  return `${key}[extra]`;
}

export function createContentGroup(question: _SurveyQuestion | null): any[] | null {
  const contentGroup = [];
  if (!question) {
    return null;
  }
  // TODO Implement

  for (const el of question?.content) {
    let lastGroup = getLastItem(contentGroup);

    if (!lastGroup || lastGroup?.type !== el?.type) {
      contentGroup.push({
        type: el?.type,
        elements: [],
      });
    }
    lastGroup = getLastItem(contentGroup);

    lastGroup?.elements.push(el);
  }
  return contentGroup;
}

function handleBR(input) {
  if (!input) {
    return input;
  }
  let str = input;
  str = str?.replaceAll('\n', '');
  str = str?.replaceAll('/s+/', ' ');
  str = str?.replaceAll('<br>', '\n');
  return trim(str);
}

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../HttpClient';
import {SOFTFACTIONARY} from '../paths';

async function getSoftfactionary(): Promise<any> {
  return await httpClient.get(SOFTFACTIONARY);
}

export {getSoftfactionary};

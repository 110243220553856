// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {_DateRangeType} from '@modules/Core/util/dateUtil';
import {logger} from '@modules/Core/util/Logger';
import {TeamProfilesOverviewListSection} from '@modules/Team/components/dashboard/cards/TeamProfilesOverviewListSection';
import {TeamProfilesSingleViewSection} from '@modules/Team/components/dashboard/cards/TeamProfilesSingleViewSection';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';

interface _Props {
  profiles: Record<string, _TeamOverviewProfile>;
  experienceMetric: _ProfileTrackingStatisticEntry;
  howAreYouMetric: _ProfileTrackingStatisticEntry;
  teamId: string;
  filter?: _DateRangeType;
}

export const TeamProfilesOverviewItem: React.FC<_Props> = ({
  filter,
  teamId,
  experienceMetric,
  howAreYouMetric,
  profiles,
}) => {
  const [chosenProfile, setChosenProfile] = useState<string | null>(null);

  useEffect(() => {
    if (!chosenProfile) {
      setChosenProfile(Object.keys(profiles ?? {})?.[0] ?? null);
    }
  }, [profiles]);

  const showPlaceholder = !profiles || Object.keys(profiles).length === 0;

  logger.debug('TeamProfilesOverviewItem', {teamId, experienceMetric, profiles, chosenProfile});
  return (
    <div className="flex flex-row w-full justify-between gap-3">
      <TeamProfilesOverviewListSection
        showPlaceholder={showPlaceholder}
        experienceMetric={experienceMetric}
        howAreYouMetric={howAreYouMetric}
        filter={filter}
        profiles={profiles}
        teamId={teamId}
        chosenProfile={chosenProfile}
        setChosenProfile={setChosenProfile}
      />

      <TeamProfilesSingleViewSection
        profiles={profiles}
        chosenProfile={chosenProfile}
        setChosenProfile={setChosenProfile}
        showPlaceholder={showPlaceholder}
      />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import '../Team.css';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getProfilePic} from '@modules/Profile/util/profileUtil';

interface _Props {
  profiles: any;
  open: boolean;
  onClose: (open: boolean) => void;
  title?: string;
  transparencyAccepted: boolean;
}

export const TeamMembersModal: React.FC<_Props> = ({profiles, open, onClose, title, transparencyAccepted}) => {
  const [currentProfile] = useProfile();

  return (
    <Dialog open={open} showCloseButton onClose={onClose} title={title}>
      <div className="flex flex-col gap-3 items-start">
        {(profiles || []).map((profile, index) => {
          const showProfile = transparencyAccepted || profile?.id === currentProfile?.id;

          return (
            <AvatarUser
              image={showProfile ? getProfilePic(profile) : undefined}
              label={
                showProfile ? profile?.fullName : <div className="blurred-placeholder items-center p-2">XXXX XXXX</div>
              }
            />
          );
        })}
      </div>
    </Dialog>
  );
};

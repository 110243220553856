// @ts-nocheck
/* eslint-disable */

import {submitForm} from '@modules/Core/services/api/forms';
import {handleHttpError} from '@modules/Core/util/errors';
import {copyObject, getCurrentAPIBasePath, getCurrentFrontendBasePath} from '@modules/Core/util/util';
import {generate} from '../components/base/helper';
import {zustandApi} from './logic/zustand';
import {success} from './snackbar';

// TODO @Sherif types
export function onWrapperClick(e: any, navigate: any) {
  // search A tag in parents
  let element = e.target;
  while (element.nodeName !== 'A' && element.nodeName !== 'BODY' && element.nodeName !== 'I') {
    element = element.parentNode;
  }
  if (
    element.nodeName === 'A' &&
    element.attributes.href.value.substr(0, 1) !== '#' &&
    element.attributes.target?.value !== '_blank'
  ) {
    e.preventDefault();
    const newLocation = element.attributes.href.value.replace(getCurrentAPIBasePath(), '');
    navigate(newLocation);
  }
}

export async function onFormSubmit(e, navigate, isHomepage, setUrl) {
  e.preventDefault();
  const form = e.target;
  const formData = new FormData(form);
  const {method} = form;
  const submitter = e.nativeEvent?.submitter;
  let url = form.action;

  if (submitter?.type === 'submit' && submitter?.value) {
    formData.set(submitter?.name, submitter.value);
  }

  // env variable is used for local development
  url = url.replace(getCurrentFrontendBasePath(), getCurrentAPIBasePath());
  url = url.replace(getCurrentAPIBasePath(), `${getCurrentAPIBasePath()}/api`);
  setUrl?.(url);

  // Adds current url to the form data, can be used in backend to redirect
  formData.set('currentUrl', window.location);

  try {
    const response = await submitForm(url, method, copyObject(Object.fromEntries(formData)));
    if (response?.status === 200) {
      if (response?.data?.html) {
        const content = extractContent(response);
        return content;
      }
      if (response?.data?.message) {
        success(response.data.message as string);
      }
    }
  } catch (e) {
    handleHttpError(e);
  }
}

export function extractContent(response) {
  return response.data.html;
}

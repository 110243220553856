// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const ContentEngineStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const key = options?.key;
  const unauthorized = options?.unauthorized;

  if (!key) {
    return null;
  }

  logger.debug('ContentEngineStep', {key, options});
  return (
    <BaseStep options={options}>
      <ContentEngineContainer
        type={key}
        params={options?.params}
        unauthorized={unauthorized}
        dynamicParams={options?.dynamicParams}
      />
    </BaseStep>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCheck, IconEditText, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {trans} from '@modules/Translations/util/i18n';

interface _ChecklistTaskEditViewProps {
  allowCustomValues: boolean;
  values: any[];
  onChange: (val: any[]) => void;
}

export const ChecklistTaskEditView: React.FC<_ChecklistTaskEditViewProps> = ({allowCustomValues, onChange, values}) => {
  const [newOption, setNewOption] = useState<string | null>(null);
  const [chosenOption, setChosenOption] = useState<number | null>(null);

  const onDelete = (index: number): void => {
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange(newValues);
  };

  const onTextChange = (index: number, text: string): void => {
    const newValues = [...values];
    newValues[index] = {
      ...newValues[index],
      text,
    };
    onChange(newValues);
  };

  const onCheckChange = (index: number, checked: boolean): void => {
    const newValues = [...values];
    newValues[index] = {
      ...newValues[index],
      checked,
    };
    onChange(newValues);
  };

  const onAdd = (): void => {
    const newValues = [...values];
    newValues.push({
      checked: false,
      text: newOption,
      is_default: false,
    });
    onChange(newValues);
    setNewOption('');
  };

  const onConfirmChange = (event: any): void => {
    event?.stopPropagation();
    onChange(values);
    setChosenOption(null);
  };

  const onOptionChange = (index: number, event: any): void => {
    event?.stopPropagation();
    setChosenOption(index);
  };
  return (
    <div className="flex flex-col gap-1">
      {allowCustomValues && (
        <div className="flex">
          <Input
            value={newOption}
            onChange={val => setNewOption(val)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onAdd();
              }
            }}
            placeholder={trans('profile_exercises.new_answers')}
          />
        </div>
      )}
      {(values || []).map((option, optionIndex) => (
        <div className="flex flex-row items-center gap-1">
          <div>
            <CheckBox
              active={option.checked}
              onChange={checked => onCheckChange(optionIndex, checked)}
              label={option.is_default ? trans(option.text) : null}
            />
          </div>

          {!option?.is_default && (
            <Input
              readOnly={chosenOption !== optionIndex}
              value={option.text}
              onChange={val => onTextChange(optionIndex, val)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onConfirmChange(e);
                }
              }}
              iconPosition="right"
              iconElement={
                chosenOption === optionIndex ? (
                  <IconButton size="sm" icon={IconCheck} onClick={e => onConfirmChange(e)} />
                ) : (
                  <IconButton size="sm" onClick={e => onOptionChange(optionIndex, e)} icon={IconEditText} />
                )
              }
            />
          )}
          {!option.is_default && (
            <IconButton aria-label="delete" onClick={() => onDelete(optionIndex)} icon={IconTrash} />
          )}
        </div>
      ))}
    </div>
  );
};

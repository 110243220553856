// @ts-nocheck
/* eslint-disable */

import {useSelector} from 'react-redux';
import {navigateToUsingWindow} from '@/_core/util/util';
import {useAuthToken} from '@modules/Core/hooks/state/authToken';
import {_Profile} from '@modules/Profile/types/profile.model';
import {_AppState} from '@modules/State/types/appState.model';
import {trans} from '@modules/Translations/util/i18n';

export function useProfile(): [
  _Profile | null,
  (email: string, password: string, rememberMe: boolean) => Promise<any>,
  () => Promise<void>,
] {
  const currentProfileState = useSelector((state: _AppState) => state.profile?.currentProfile);
  const {fetchToken, resetToken} = useAuthToken();

  async function onLogin(email: string, password: string, rememberMe?: boolean): Promise<any> {
    const result = await fetchToken(email, password, rememberMe);
    return result;
  }

  async function onLogout(): Promise<void> {
    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirectTo');
    const message = params.get('message') ?? trans('login.logout_success');

    await resetToken();

    let suffix = '';
    suffix += redirectTo ? `${suffix ? '&' : '?'}redirectTo=${redirectTo}` : '';
    suffix += message ? `${suffix ? '&' : '?'}message=${message}` : '';

    navigateToUsingWindow(`/login${suffix}`);
  }

  return [currentProfileState, onLogin, onLogout];
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

export const ProfileRoundExerciseLocked: React.FC = () => {
  const navigateToFlow = useNavigateToFlow();

  return (
    <Section className="items-center">
      <img
        src="/assets/img/design_images/slow%20down.png"
        style={{
          width: '213.7px',
          height: '460px',
        }}
      />
      <p style={{fontSize: '1.5rem'}}>{trans('profile_exercises.no_access')}</p>
      <p style={{fontSize: '1rem'}}>{trans('profile_exercises.no_access_description')}</p>
      <Button width="relative-col-width-2" onClick={() => navigateToFlow(FLOWS.ProfileExercises)}>
        {trans('profile_exercises.go_to_exercises')}
      </Button>
    </Section>
  );
};

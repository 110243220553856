// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {_InstitutionTeamPerformer} from '@modules/Institution/types/institutionstatistics.model';
import {TopPerformerTeamCard} from '@modules/Statistics/components/institution/TopPerformerTeamCard';
import {_PerformerKey} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  performers: Record<_PerformerKey, _InstitutionTeamPerformer>;
}

export const topPerformersLengthToWidthDict = {
  1: 'relative-col-width-4',
  2: 'relative-col-width-6',
  3: 'relative-col-width-4',
  4: 'relative-col-width-3',
  5: 'relative-col-width-2-5',
  default: 'relative-col-width-2-5',
};

const LIMIT = 5;
export const OrganisationTopPerformersItem: React.FC<_Props> = ({performers}) => {
  if (!performers || Object.keys(performers).length === 0) {
    return (
      <PlaceholderSection
        title={trans('institution_gamification.top_performers_missing.title')}
        content={trans('institution_gamification.top_performers_missing.description')}
        image={getDesignImage('point_target.png')}
      />
    );
  }
  logger.debug('OrganisationTopPerformersItem', {performers});
  return (
    <div className="flex flex-row gap-3 w-full">
      {Object.keys(performers)
        .slice(0, LIMIT)
        .map(key => {
          return (
            <div
              className={
                topPerformersLengthToWidthDict[
                  Object.keys(performers).length > LIMIT ? LIMIT : Object.keys(performers).length
                ]
              }
              key={key}
            >
              <TopPerformerTeamCard performer={performers[key]} key={key} performerKey={key} />
            </div>
          );
        })}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  onAdd: () => void;
}

export const EmptyOverviewCard: React.FC<_Props> = ({onAdd}) => {
  return (
    <Card onClick={onAdd}>
      <div className={twMerge('flex p-3 flex-col justify-center items-center gap-2 h-full w-full')}>
        <IconButton color="no-background" icon={IconAdd} onClick={onAdd} />
        <Typography variant="body" className="text-center w-full">
          {trans('gamification.team_overview.add_new')}
        </Typography>
      </div>
    </Card>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileOneTimeNotification} from '@modules/ContentEngine/types/core.model';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {setOneTimeNotificationDone} from '@modules/Profile/util/profileActionsUtil';

interface _Props {
  notification: _ProfileOneTimeNotification;
  open: boolean;
  setOpen: (open: boolean) => void;
  onDone?: () => void;
}

const OneTimeNotificationDialog: React.FC<_Props> = ({notification, onDone, open, setOpen}) => {
  const onSetAsDone = async (): Promise<void> => {
    const success = await setOneTimeNotificationDone(notification.key, true);
    setOpen(false);
    onDone?.();
  };
  return (
    <Dialog
      open={open}
      onClose={onSetAsDone}
      onConfirm={onSetAsDone}
      title={notification.dialogProps.title}
      confirmLabel={notification.dialogProps.confirmLabel}
      cancelLabel={notification.dialogProps.cancelLabel}
      titleGap={5}
    >
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: notification.dialogProps.content,
        }}
      />
    </Dialog>
  );
};
export default OneTimeNotificationDialog;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {cancelSubscriptionStatus} from '@modules/Core/services/api/payment';
import {formatDate} from '@modules/Core/util/dates';
import {actionCancelSubscription} from '@modules/Core/util/payment/paymentActionsUtil';
import {changeCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {dispatchAction, refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

export function useCancelSub() {
  const snackbar = useSnackbar();
  const dialog = useDialog();

  async function onCancelSubscription(
    institutionId: string,
    subscriptionId: string,
    profileIds: string[],
    isTrial: boolean,
    mode: 'profile' | 'institution',
    onSuccess?: () => void,
    callback?: () => void
  ) {
    try {
      const response = await cancelSubscriptionStatus(subscriptionId, mode, profileIds);

      if (response?.data?.done) {
        snackbar.success(trans(response?.data?.message, response?.data?.params || {}, false) ?? '');
        await Promise.all([refreshReduxState(), dispatchAction(changeCurrentInstitution(institutionId))]);
        return;
      }

      dialog.show({
        title: trans(response?.data?.title, response?.data?.params),
        children: (
          <Typography
            dangerouslySetInnerHTML={{
              __html: trans(response?.data?.message, response?.data?.params || {}) || '',
            }}
          />
        ),
        onConfirm: async () => {
          const success = await doCancelSubscription(
            institutionId,
            profileIds,
            subscriptionId,
            isTrial,
            response?.data?.snackbar
          );

          callback?.();
          if (success && onSuccess) {
            onSuccess();
          }
        },
        confirmLabel: isTrial ? trans(response?.data?.confirmMessage) : trans('subscription.cancellation.confirm'),
        cancelLabel: isTrial ? trans(response?.data?.cancelMessage) : trans('subscription.cancellation.cancel'),
        confirmProps: {
          variant: 'secondary',
          width: 'relative-col-width-4',
        },
        cancelProps: {
          variant: 'primary',
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function doCancelSubscription(
    institutionId: string,
    profileIds: string[],
    subscriptionId: string,
    isTrial: boolean,
    snackbarConfirmationText: string
  ) {
    const response = await actionCancelSubscription({
      subscriptionId,
      profileIds,
    });

    if (response?.status === ACTION_STATUS_SUCCESS && isTrial) {
      snackbar.success(
        trans(snackbarConfirmationText, {
          date: formatDate(new Date(response?.data?.expiryDate)),
        })
      );
      return true;
    }
    if (
      response?.status === ACTION_STATUS_SUCCESS &&
      response.data?.subscriptions?.length > 0 &&
      response.data?.subscriptions?.[0]?.pivot?.date
    ) {
      snackbar.success(
        trans(snackbarConfirmationText, {
          date: formatDate(new Date(response.data.subscriptions[0].pivot.date)),
        })
      );
      return true;
    }
    return false;
  }

  return onCancelSubscription;
}

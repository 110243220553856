// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {PERSONAL_VALUES, PERSONAL_VALUES_NOTIFIED, PERSONAL_VALUES_TEAM} from '@modules/Profile/services/paths';

async function getAllPersonalValues(): Promise<AxiosResponse> {
  return await httpClient.get(PERSONAL_VALUES);
}

async function updateProfilePersonalValues(profileId: string, values, createdValue, changedValues) {
  return await httpClient.post(`${PERSONAL_VALUES}/${profileId}`, {
    values,
    createdValue,
    changedValues,
  });
}

async function updateTeamPersonalValues(teamId: string, values, diff) {
  return await httpClient.post(`${PERSONAL_VALUES_TEAM(teamId)}`, {
    values,
    diff,
  });
}

async function updateOrganisationChangesNotifiedProfiles(teamId, profileId) {
  return await httpClient.post(`${PERSONAL_VALUES_NOTIFIED(teamId)}`, {
    profileId,
  });
}

export {
  getAllPersonalValues,
  updateProfilePersonalValues,
  updateTeamPersonalValues,
  updateOrganisationChangesNotifiedProfiles,
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import {Canvg} from 'canvg';
import './PersonalityGraph.scss';
import {logger} from '@modules/Core/util/Logger';

// TODO @DeleteMe
async function convertSvgToPng(svg) {
  const canvas = document.createElement('canvas');
  canvas.width = 40;
  canvas.height = 40;
  const ctx = canvas.getContext('2d');
  const v = Canvg.fromString(ctx, svg);
  await v.render();
  return canvas.toDataURL();
}

function setScrollbar(root, chart, count) {
  let start;
  let end;

  start = 0;
  end = 10 / count;

  const scrollbarX = am5xy.XYChartScrollbar.new(root, {
    orientation: 'horizontal',
    height: 25,
    start,
    end,
  });

  scrollbarX.get('background').setAll({
    fill: am5.color(0x3c4858),
    cornerRadiusTR: 0,
    cornerRadiusBR: 0,
    cornerRadiusTL: 0,
    cornerRadiusBL: 0,
  });

  scrollbarX.startGrip.setAll({
    visible: false,
    opacity: 0,
  });

  scrollbarX.endGrip.setAll({
    visible: false,
    opacity: 0,
  });

  chart.set('scrollbarX', scrollbarX);
  chart.bottomAxesContainer.children.push(scrollbarX);
}

function createGuide(value, text, color, dashArray, yAxis, chart) {
  const guideDataItem = yAxis.makeDataItem({value});
  yAxis.createAxisRange(guideDataItem);
  guideDataItem.get('grid').setAll({
    forceHidden: false,
    strokeOpacity: 1,
    strokeDasharray: '',
    stroke: color,
    strokeDasharray: [10, 5],
  });
  const label = guideDataItem.get('label');
  chart.events.on('boundschanged', function () {
    label.set('x', label.get('x'));
  });
}

interface _Props {
  profiles: Array<{
    id: string;
    name: string;
    value: number;
    url: string;
    profileUrl: string;
  }>;
  currentProfileId: string;
  legends: {
    teamAverage: number;
  };
  labels: string[];
  state: {
    isPartOfAnalysis: boolean;
  };
}

const PersonalityGraph: React.FC<_Props> = props => {
  logger.debug('PersonalityGraph', props);

  const id = `chartdiv${1000 + Math.random() * 8999}`;
  const {isPartOfAnalysis} = props.state;

  useEffect(() => {
    const root = am5.Root.new(id);

    // root.setThemes([am5themes_Animated.new(root)]);

    const profiles = props.profiles?.map((p, i) => ({
      id: p.id,
      name: i,
      toolTip: `${p.name} (${p.value?.toFixed(2)})`,
      steps: p.value,
      pictureSettings: {
        src: p.url,
        urlTarget: p.profileUrl,
        value: p.value,
      },
    }));

    const withoutUser = profiles?.filter(p => props.currentProfileId !== p.id) ?? [];
    const filteredUser = profiles?.filter(p => props.currentProfileId === p.id) ?? [];

    const data = [...filteredUser, ...withoutUser];

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingTop: 50,
        layout: root.verticalLayout,
      })
    );

    setScrollbar(root, chart, data.length);

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set('visible', false);

    xRenderer.labels.template.set('visible', false);

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        paddingTop: 50,
        minZoomCount: 3,
        maxZoomCount: 10,
        categoryField: 'name',
        renderer: xRenderer,
      })
    );

    chart.zoomOutButton.set('forceHidden', true);

    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set('strokeDasharray', [3]);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        location: 0,
        baseValue: props.legends.teamAverage,
        min: 0,
        max: 5,
        renderer: yRenderer,
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'id',
        xAxis,
        yAxis,
        valueYField: 'steps',
        valueXField: 'toolTip',
        categoryXField: 'name',
        categoryYField: 'toolTip',
        sequencedInterpolation: true,
        calculateAggregates: true,
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, {
          dy: -30,
          pointerOrientation: 'vertical',
          labelText: '{categoryY}',
        }),
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      cornerRadiusBR: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusTL: 10,
      maxWidth: 30,
      fillOpacity: 0,
    });

    const circleTemplate = am5.Template.new({});

    series.bullets.push(function (root, series, dataItem) {
      const bulletContainer = am5.Container.new(root, {});
      const circle = bulletContainer.children.push(
        am5.Circle.new(
          root,
          {
            radius: 22,
          },
          circleTemplate
        )
      );

      const maskCircle = bulletContainer.children.push(am5.Circle.new(root, {radius: 22}));

      // only containers can be masked, so we add image to another container
      const imageContainer = bulletContainer.children.push(
        am5.Container.new(root, {
          mask: maskCircle,
        })
      );

      const image = imageContainer.children.push(
        am5.Picture.new(root, {
          templateField: 'pictureSettings',
          centerX: am5.p50,
          centerY: am5.p50,
          width: 45,
          height: 45,
          interactive: true,
        })
      );

      image.events.on('click', function (ev) {
        if (ev.target._settings.urlTarget) {
          window.location = ev.target._settings.urlTarget;
        }
      });

      let currentlyHovered;

      function handleHover(dataItem) {
        if (dataItem && currentlyHovered != dataItem) {
          handleOut();
          currentlyHovered = dataItem;
          const bullet = dataItem.bullets[0];
        }
      }

      function handleOut() {
        if (currentlyHovered) {
          const bullet = currentlyHovered.bullets[0];
        }
      }

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          cursor: 'pointer',
        })
      );
      cursor.lineX.set('visible', false);
      cursor.lineY.set('visible', false);

      cursor.events.on('cursormoved', function (ev) {
        const {dataItem} = series.get('tooltip');

        if (dataItem) {
          handleHover(dataItem);
        } else {
          handleOut();
        }
      });

      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: bulletContainer,
      });
    });

    // heatrule
    series.set('heatRules', [
      {
        dataField: 'valueY',
        min: am5.color(0xc0ccda),
        max: am5.color(0xe0e6ed),
        target: series.columns.template,
        key: 'fill',
      },
    ]);
    (async () => {
      for (const i in data) {
        const image = data[i].pictureSettings.src;
        if (image.includes('<svg')) {
          data[i].pictureSettings.src = await convertSvgToPng(image);
        }
      }
      series.data.setAll(data);
      xAxis.data.setAll(data);
    })();

    if (isPartOfAnalysis) {
      createGuide(props.legends.teamAverage, props.labels[0], '#3C4858', null, yAxis, chart);
      createGuide(data[0]?.pictureSettings.value, props.labels[1], '#68D391', null, yAxis, chart);
    } else {
      createGuide(props.legends.teamAverage, props.labels[0], '#3C4858', null, yAxis, chart);
    }

    return () => {
      root.dispose();
    };
  }, [id]);

  return <div className="c-amcharts" id={id} style={{width: '100%', height: '550px'}} />;
};

export default PersonalityGraph;

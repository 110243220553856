// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {SOFT_FACT_SUPPORT} from '@modules/Core/config/constants';
import {useLoader} from '@modules/Core/hooks/state/loader';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {
  onSendRequestToUpgradeEmail,
  onSendStartTrialRequestEmail,
} from '@modules/Core/util/payment/paymentEventHandlers';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {hasLegacyMembership} from '@modules/Core/util/subscriptionUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {
  hasRunningOneTimePayment,
  isFreePlan,
  isFreeSubscription,
  isOneTimePaymentSubscription,
} from '@modules/Profile/util/membershipsUtil';
import {isRequestSent} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

export const useProfilePaymentActions = (
  plan: _PaymentPlan,
  isCurrentPlan: boolean,
  profile: _Profile | null,
  currentSubscription: _Subscription | null,
  institution: _Institution | null,
  onChange: () => void
): _UIAction[] => {
  const snackbar = useSnackbar();
  const {loadRequest} = useLoader();
  const [actions, setActions] = useState<_UIAction[]>([]);

  useEffect(() => {
    const isOneTimePayment = isOneTimePaymentSubscription(plan?.id);

    if (isOneTimePayment) {
      setActions(extractOneTimePaymentActions());
      return;
    }

    setActions(extractMembershipActions());
  }, [plan, profile, institution, isCurrentPlan]);

  const extractOneTimePaymentActions = (): _UIAction[] => {
    const isRunningPlanOneTimePayment = hasRunningOneTimePayment(profile);
    const isLegacyInstitution = hasLegacyMembership(institution);
    const isTrial = Boolean(institution?.trialAvailable ?? profile?.trialAvailable);
    const isAdmin = can('organisation.products:purchase', {institution_id: institution?.id});
    const hasMembership = !isFreeSubscription(currentSubscription);

    // No actions for free plan, running OTP & running membership ( in case of legacy institution )
    if (isRunningPlanOneTimePayment || (isCurrentPlan && !isLegacyInstitution) || isTrial || hasMembership) {
      return [];
    }

    // Is OTP, while user has running membership or trial -> no action
    if (isTrial || hasMembership) {
      return [];
    }

    // No OTP + Legacy -> option to contact support
    if (isLegacyInstitution) {
      return [
        {
          label: trans('subscription.reach_out_otp'),
          link: `mailto:${SOFT_FACT_SUPPORT}`,
          type: 'secondary_button',
        },
      ];
    }

    // OTP + has no running membership + not admin -> request to upgrade
    if (!isRunningPlanOneTimePayment && !isAdmin) {
      return [
        {
          label: trans(
            isRequestSent('onetime_upgrade')
              ? 'subscription.request_upgrade_one_time_again'
              : 'subscription.request_upgrade_one_time'
          ),
          type: 'cta_button',
          icon: 'task_completer',
          width: isRequestSent('onetime_upgrade') ? 'relative-col-width-3' : 'relative-col-width-2',
          onClick: async () => await onSendRequestToUpgradeEmail(profile, plan, snackbar),
        },
      ];
    }

    // OTP + has no running membership + admin -> upgrade now
    if (!isRunningPlanOneTimePayment && isAdmin) {
      return [
        {
          label: trans('subscription.upgrade_now_admin'),
          type: 'cta_button',
          icon: 'task_completer',
          onClick: async () => await onBuyClicked(),
        },
      ];
    }

    return [];
  };

  const extractMembershipActions = (): _UIAction[] => {
    const isFreeMembershipPlan = isFreePlan(plan?.id);
    const isLegacyInstitution = hasLegacyMembership(institution);
    const isTrial = institution?.trialAvailable ?? profile?.trialAvailable;
    const isAdmin = can('organisation.products:purchase', {institution_id: institution?.id});

    // No actions for free plan, running OTP & running membership ( in case of legacy institution )
    if (isCurrentPlan && !isLegacyInstitution) {
      return [];
    }

    // No actions for free plan or legacy/prepaid institution
    if (isFreeMembershipPlan || isLegacyInstitution) {
      return [];
    }

    // Admin trial, show start trial button
    if (isAdmin && isTrial) {
      return [
        {
          label: trans('subscription.trial_available.title'),
          type: 'cta_button',
          onClick: async () => await onSendStartTrialRequestEmail(snackbar),
        },
      ];
    }

    // Not admin and trial -> send request to start trial
    if (!isAdmin && isTrial) {
      return [
        {
          label: trans(
            profile?.trialRequestSent
              ? 'subscription.trial_available.team_member_again_title'
              : 'subscription.trial_available.team_member_title',
            {
              name: institution?.owner?.name,
            }
          ),
          type: 'cta_button',
          width: profile?.trialRequestSent ? 'relative-col-width-3' : 'relative-col-width-2',
          onClick: async () => await onSendStartTrialRequestEmail(snackbar),
        },
      ];
    }

    // Admin and no trial -> upgrade membership
    if (isAdmin) {
      return [
        {
          label: trans('subscription.upgrade_now_admin'),
          type: 'cta_button',
          icon: 'task_completer',
          onClick: async () => await onBuyClicked(),
        },
      ];
    }

    // Not admin and not trial -> request to upgrade membership
    if (!isAdmin) {
      return [
        {
          label: trans(
            isRequestSent('membership_upgrade')
              ? 'subscription.request_upgrade_membership_again'
              : 'subscription.request_upgrade_membership',
            {
              name: institution?.owner?.name,
            }
          ),
          type: 'cta_button',
          icon: 'task_completer',
          width: 'relative-col-width-3',
          onClick: async () => await onSendRequestToUpgradeEmail(profile, plan, snackbar),
        },
      ];
    }
    return [];
  };

  async function onBuyClicked(): Promise<void> {
    if (can('organisation.products:purchase', {institution_id: institution?.id})) {
      onChange();
    }
  }

  return actions;
};

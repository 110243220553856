// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useState} from 'react';
import {useCreateItems} from '../../../hooks/createItems';
import '../SectionItem.scss';
import {_Section} from '@modules/Core/components/layout/Section';
import {_ContentEngineContext, _ContentEngineItem} from '../../../types/contentEngineItem.model';
import {twMerge} from 'tailwind-merge';

interface _PropItem {
  item: _ContentEngineItem;
}

export interface _Props extends _Section {
  id?: string;
  items?: Array<_ContentEngineItem | string>;
  direction: 'row' | 'column';
  gap: number;
  context: _ContentEngineContext;
  hide?: boolean;
}

export const GridItem: React.FC<_Props> = ({hide = false, context, id, items, direction = 'row', gap = 3}) => {
  const [contentEngineItems, setContentEngineItems] = useState<ReactNode | ReactNode[] | string | string[] | undefined>(
    []
  );
  const parsedContent = useCreateItems(contentEngineItems, context);

  useEffect(() => {
    if (hide) {
      return;
    }
    // if items is string, it means it is a text
    if (typeof items === 'string') {
      setContentEngineItems(items);
      return;
    }
    setContentEngineItems(items);
  }, [items, hide]);

  if (hide) {
    return null;
  }
  return (
    <div className={twMerge('w-full', 'flex', direction === 'column' ? 'flex-col' : 'flex-row', `gap-${gap}`)}>
      {parsedContent}
    </div>
  );
};

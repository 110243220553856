// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {AvatarGroup} from '@modules/Core/components/base/avatar/AvatarGroup';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profiles: string[];
  teamProfiles: Record<string, _TeamProfile>;
  limit?: number;
  avatarSize?: 'sm' | 'md' | 'lg';
  tooltips?: Record<string, string>;
}

export const TeamProfileAvatarList: React.FC<_Props> = ({avatarSize, profiles, limit = 4, teamProfiles, tooltips}) => {
  if (!profiles || profiles?.length === 0) {
    return null;
  }

  function profileComponent(profileId: string) {
    let content = <Avatar size={avatarSize} image={getProfilePic(teamProfiles?.[profileId])} />;
    if (tooltips) {
      content = <ToolTip txt={tooltips[profileId]}>{content}</ToolTip>;
    }
    return content;
  }

  if (profiles.length === 1) return profileComponent(profiles[0]);

  // badge is +5 for example
  return (
    <AvatarGroup
      badge={profiles?.length > limit ? `+${profiles.length - limit}` : null}
      badgeTooltip={trans('team_modules.team_members_count', {count: profiles.length})}
    >
      {profiles?.slice(0, limit).map(profileId => profileComponent(profileId))}
    </AvatarGroup>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  getIconByName,
  IconSmileyExcited,
  IconSmileyHappy,
  IconSmileyNeutral,
  IconSmileySad,
  IconSmileyUpset,
} from '@modules/Core/components/base/Icons/Icons';
import {getAchievementImage, getImage, getStatisticImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';

export type _ImageType = 'icon' | 'service-icon' | 'image' | 'smiley' | 'statistic' | 'achievement';

export function getImageByType(image: string, imageType: _ImageType, size: string) {
  const feelingDescriptions = [IconSmileyExcited, IconSmileyHappy, IconSmileyNeutral, IconSmileySad, IconSmileyUpset];
  let icon;
  if (['image', 'statistic', 'achievement'].includes(imageType)) {
    const path =
      imageType === 'image'
        ? getImage(image, 'png')
        : imageType === 'statistic'
          ? getStatisticImage(image, 'png')
          : getAchievementImage(image, 'png');
    icon = <img src={path} className={`h-${size ?? 3}`} />;
  } else if (imageType === 'service-icon') {
    const IconComponent = getServiceIcon(image);

    if (IconComponent) {
      icon = <IconComponent />;
    }
  } else if (imageType === 'smiley') {
    const imageIndex = parseInt(image, 10);
    logger.debug('smiley found', {
      image,
      imageIndex,
    });
    const Icon = feelingDescriptions[imageIndex - 1];
    if (!Icon) return null; // Return nothing if icon is not found
    icon = <Icon size={size} />;
  } else {
    const IconComponent = getIconByName(image);

    if (IconComponent) {
      icon = <IconComponent />;
    }
  }

  return icon;
}

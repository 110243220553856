// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _HtmlItemProps {
  html: string;
}

export const HtmlItem: React.FC<_HtmlItemProps> = ({html}) => {
  return <div dangerouslySetInnerHTML={{__html: html}} />;
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import ProfileSettings from '@modules/Profile/components/settings/ProfileSettings';
import {_Profile} from '@modules/Profile/types/profile.model';
import {hasActiveMembership} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export interface _SettingsEntry {
  key: string;
  label: string;
  value: boolean;
}

const settingsConfig = [
  {key: 'photo', label: 'settings.privacy.profile_photo', value: false},
  {key: 'description', label: 'settings.privacy.profile_description', value: false},
  {key: 'development_status', label: 'settings.privacy.development_status', value: false},
  {key: 'achievements', label: 'settings.privacy.achievements', value: false},
  {key: 'levels', label: 'settings.privacy.levels', value: false},
  {key: 'positions', label: 'settings.privacy.position', value: false},
];

export const ProfileAccountDetailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const dialog = useDialog();
  const [oldEmail, setOldEmail] = useState<string | null>(null);
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [changeEmailPassword, setChangeEmailPassword] = useState<string | null>(null);
  const [oldPassword, setOldPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState<string | null>(null);
  const [settings, setSettings] = useState<_SettingsEntry[]>([]);
  const [responsibleForOrgs, setResponsibleForOrgs] = useState<any[]>([]);
  const [responsibleForTeams, setResponsibleForTeams] = useState<any[]>([]);
  const originalDataLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (originalDataLoaded.current || !options.profile) {
      return;
    }
    const profile = options.profile as _Profile;
    setOldEmail(profile?.email);
    const newSettings = settingsConfig.map(setting => ({
      ...setting,
      value: Boolean(profile?.settings?.[setting.key]),
    })) as _SettingsEntry[];
    setSettings(newSettings);

    setResponsibleForOrgs(profile.responsibleForOrganisations);
    setResponsibleForTeams(profile.responsibleForTeams);

    originalDataLoaded.current = true;
  }, [options]);

  function getValues(): Record<string, any> {
    return {
      privacySettings: settings,
      oldEmail,
      newEmail,
      changeEmailPassword,
      oldPassword,
      newPassword,
      deleteAccountPassword,
    };
  }

  const onEvent = (actionName: string): void => {
    options.fireEvent(actionName);
  };

  const onChangeEmail = (): void => {
    onEvent('changeEmail');
  };

  const onChangePassword = (): void => {
    onEvent('resetPassword');
  };

  const onDeleteAccount = (): void => {
    const hasSubscription = hasActiveMembership(options.profile as _Profile);
    dialog.show({
      title: trans('profile.delete_confirm_popup.title'),
      children: (
        <Typography
          className="flex flex-col gap-3 !text-body1"
          dangerouslySetInnerHTML={{
            __html: hasSubscription
              ? trans('profile.delete_confirm_popup.description_with_subscription')
              : trans('profile.delete_confirm_popup.description_with_out_subscription'),
          }}
        />
      ),
      confirmLabel: trans('profile.delete_confirm_popup.confirm_btn'),
      cancelLabel: trans('profile.delete_confirm_popup.cancel_btn'),
      showCloseButton: true,
      onConfirm: () => {
        onEvent('deleteAccount');
      },
      onClose: () => {},
      onCancel: () => {},
    });
  };

  const onChangePrivacy = (): void => {
    onEvent('updatePrivacy');
  };

  return (
    <BaseStep
      getValueCallbackFn={getValues}
      inputs={[settings, oldEmail, newEmail, changeEmailPassword, oldPassword, newPassword, deleteAccountPassword]}
      options={options}
    >
      <ProfileSettings
        settings={settings}
        setSettings={setSettings}
        oldEmail={oldEmail}
        setOldEmail={setOldEmail}
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        changeEmailPassword={changeEmailPassword}
        setChangeEmailPassword={setChangeEmailPassword}
        oldPassword={oldPassword}
        setOldPassword={setOldPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        deleteAccountPassword={deleteAccountPassword}
        setDeleteAccountPassword={setDeleteAccountPassword}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        onDeleteAccount={onDeleteAccount}
        onChangePrivacy={onChangePrivacy}
        currentProfile={options.profile}
        active={options.active}
        responsibleForOrgs={responsibleForOrgs}
        responsibleForTeams={responsibleForTeams}
      />
    </BaseStep>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Label} from '@modules/Core/components/base/Label';

interface _CheckBox {
  active?: boolean;
  onChange?: (value: boolean) => void;
  size?: 'sm' | 'md' | 'lg';
  color?: 'light' | 'dark' | 'brand';
  disabled?: boolean;
  label?: string;
  icon?: React.ElementType;
  readOnly?: boolean;
}

export const CheckBox: React.FC<_CheckBox> = ({
  active,
  onChange,
  size = 'md',
  color = 'light',
  disabled,
  label,
  icon,
  readOnly,
}) => {
  let finSize = 'h-3 w-3';
  if (size === 'sm') finSize = 'h-2 w-2 min-h-2 min-w-2';
  if (size === 'md') finSize = 'h-3 w-3 min-h-3 min-w-3';
  if (size === 'lg') finSize = 'h-4 w-4 min-h-4 min-w-4';

  let divStyle = 'bg-white500 border-grey300';
  let iconColor = '';
  if (color === 'light') {
    divStyle = 'bg-white500 border-grey300';
    iconColor = 'fill-grey700';
  }
  if (color === 'dark') {
    divStyle = 'bg-white500 border-blue700';
    iconColor = 'fill-blue700';
  }
  if (color === 'brand') {
    divStyle = 'bg-white500 border-brand700';
    iconColor = 'fill-brand700';
  }
  if (disabled) {
    divStyle = 'bg-grey500 border-grey500';
    iconColor = 'fill-grey500';
  }

  const handleChange = (): void => {
    if (!disabled && !readOnly) onChange?.(!active);
  };

  // TODO: We should also add a hidden input field to handle form submission
  return (
    <div className="flex items-start gap-1" onClick={handleChange}>
      <div
        className={addCN(
          'flexCenterCenter transition gap-3 rounded-xs border-1',
          finSize,
          divStyle,
          disabled ? '' : 'cursor-pointer hover:bg-grey100'
        )}
      >
        <div className={addCN('transition pointer-events-none', active ? 'opacity-1' : 'opacity-0')}>
          {size === 'sm' ? <CheckMarkSm color={iconColor} /> : null}
          {size === 'md' ? <CheckMarkMd color={iconColor} /> : null}
          {size === 'lg' ? <CheckMarkLg color={iconColor} /> : null}
        </div>
      </div>
      {label ? <Label label={label} icon={icon} variant="body" bold={false} style={{paddingTop: '2px'}} /> : null}
    </div>
  );
};

interface _Props {
  color: string;
}

const CheckMarkLg: React.FC<_Props> = ({color = 'fill-brand700'}) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={addCN(color)}
  >
    <path d="M7.73334 15.5384L0.620544 8.42556L2.04618 6.99996L7.73334 12.6871L19.9538 0.466629L21.3795 1.89223L7.73334 15.5384Z" />
  </svg>
);

const CheckMarkMd: React.FC<_Props> = ({color = 'fill-brand700'}) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={addCN(color)}
  >
    <path d="M5.54999 11.6538L0.215393 6.31922L1.28462 5.25002L5.54999 9.5154L14.7154 0.350021L15.7846 1.41922L5.54999 11.6538Z" />
  </svg>
);

const CheckMarkSm: React.FC<_Props> = ({color = 'fill-brand700'}) => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={addCN(color)}>
    <path d="M4.36664 7.76919L0.810242 4.21279L1.52306 3.49999L4.36664 6.34357L10.4769 0.233322L11.1897 0.946122L4.36664 7.76919Z" />
  </svg>
);

// @ts-nocheck
/* eslint-disable */

// Importing necessary modules from Redux Toolkit and the logger utility
import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_ExercisesData} from '@modules/Profile/types/exercises.model';
import {_AppState} from '@modules/State/types/appState.model';

export interface _ExercisesState {
  exercisesJson: _ExercisesData | null;
}

// Define the exercisesSlice
const exercisesSlice = createSlice({
  name: 'exercises',
  initialState: {
    exercisesJson: null,
  } satisfies _ExercisesState,
  reducers: {
    // Reducer to set exercises
    setExercises(state, action) {
      logger.info('[ExercisesSlice] Updating exercises.');

      // Updating the exercisesJson state
      state.exercisesJson = action.payload;
    },
  },
});

// Exporting the reducer actions and the reducer itself
export const {setExercises} = exercisesSlice.actions;
export default exercisesSlice;

// Selector to retrieve exercises from the state
export const selectExercises = (state: _AppState): _ExercisesData | null => state.exercises.exercisesJson;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ProfileTrackingMetricValueEntry} from '@modules/ContentEngine/types/metrics.model';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {TeamIndividualPerformanceCard} from '@modules/Team/components/dashboard/cards/TeamIndividualPerformanceCard';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profiles: Record<string, _TeamOverviewProfile>;
  chosenProfile: string | null;
  setChosenProfile: (value: string | null) => void;
  showPlaceholder?: boolean;
}

export const TeamProfilesSingleViewSection: React.FC<_Props> = ({
  showPlaceholder,
  chosenProfile,
  setChosenProfile,
  profiles,
}) => {
  return (
    <CustomizableSectionItem
      className="h-full"
      fullHeight
      width={6}
      title={trans('gamification.team.individual_performance')}
      titlePrefix={
        !showPlaceholder && (
          <div className="relative-col-width-2">
            <Select
              placeholder={trans('team_dashboard.select_profile_label')}
              options={Object.entries(profiles ?? {}).map(([key, profile]) => ({
                label: profile.fullName,
                value: key,
              }))}
              value={{
                value: chosenProfile,
              }}
              onChange={value => setChosenProfile(value.value)}
            />
          </div>
        )
      }
    >
      {!showPlaceholder ? (
        <TeamIndividualPerformanceCard profile={profiles?.[chosenProfile ?? ''] ?? null} />
      ) : (
        <PlaceholderSection
          fullWidth
          direction="vertical"
          title={trans('team_gamification.overview_individual_profile_missing.title')}
          content={trans('team_gamification.overview_individual_profile_missing.description')}
          image={getDesignImage('power.png')}
        />
      )}
    </CustomizableSectionItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {navigateToFlow} from '@app/_core/util/util';
import {logger} from '@modules/Core/util/Logger';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useProfile} from '@modules/Profile/hooks/profile';
import {isLoggedIn} from '@modules/Profile/util/authUtil';
import {FLOWS} from '../config/flowNames';
import {useLoadFlowJson} from '../hooks/loadFlowJson';
import FlowComponent from './Base/FlowComponent';

// import {doLogout} from '../../Api/Services/LoginAPIService';

interface _Props {
  flowName: string;
  input?: any;
  stepName?: string;
  onFinish?: () => void;
  instanceKey?: string;
}

export const Flow: React.FC<_Props> = ({flowName, input, stepName, onFinish, instanceKey = 'default'}) => {
  const flowJson = useLoadFlowJson(flowName);
  const [currentProfile] = useProfile();
  const {currentUrl} = useAppNavigate();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (flowJson) {
      if (isLoggedIn() || flowJson?.type === 'unauthorized') {
        setReady(true);
      } else {
        logger.debug('Flow', 'User not logged in, redirecting to login page');
        navigateToFlow(FLOWS.Login, {redirectTo: currentUrl});
      }
    }
  }, [flowJson, currentProfile]);

  if (!ready) {
    return null;
  }

  if (!flowJson) {
    return <h1>No flow json</h1>;
  }

  return (
    <FlowComponent
      key={flowName}
      flowName={flowName}
      input={input}
      flowSchema={flowJson}
      stepName={stepName}
      onFinish={onFinish}
      instanceKey={instanceKey}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import {Montserrat} from 'next/font/google';
import {logger} from '@modules/Core/util/Logger';

const fontFamily = 'softfact, sans-serif';
// const textFontFamily = 'Montserrat, softfact, sans-serif';

// todo: Currently, this chart only allows for 3 data sets and 4 colors each.
const colorSets = [
  ['#8ACED0', '#F0B856', '#132E3E'], // darkest: teal, yellow, blue
  ['#97DDDF', '#F4C674', '#1E3B4B'],
  ['#B6E0E2', '#F5D08E', '#2B5C7B'],
  ['#C5E7E8', '#F8DFB4', '#457DA0'], // lightest: teal, yellow, blue
];

const mont = Montserrat({
  subsets: ['latin'],
});

export const initDonutGauge = (root, chartOptions, data) => {
  // Add data to the series
  const isAllZero = data => data.every(item => !item.value);

  const dataExists = data && data?.length > 0 && !isAllZero(data);
  // Set up number formatter - removes decimals 6.50 -> 7
  root.numberFormatter.set('numberFormat', '#.');

  // Set up the chart
  const chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      innerRadius: am5.percent(65), // adjust the inner radius to create a donut shape
      fontFamily,
    })
  );

  // const centerContainer = chart.seriesContainer.children.push(
  //   am5.Container.new(root, {
  //     width: am5.percent(100),
  //     height: am5.percent(100),
  //     layout: root.verticalLayout,
  //   })
  // );

  drawCenteredImageAndText(chartOptions, chart, root);
  // Create a PieSeries (which will be used as the gauge)
  const series = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'value',
      categoryField: 'category',
      alignLabels: false,
      fillField: 'color',
    })
  );
  series.labels.template.set('forceHidden', true);

  // Set the appearance of the series (donut sections)
  series.slices.template.setAll({
    stroke: am5.color(0xffffff),
    strokeWidth: 5,
    strokeOpacity: 0,
    width: am5.p100,
    tooltipText: dataExists ? '{category}' : '',
  });

  if (chartOptions?.colors) {
    if (dataExists) {
      series.slices.template.adapters.add('fill', (fill, target) =>
        am5.color(chartOptions.colors[series.slices.indexOf(target) % chartOptions.colors.length])
      );
    } else {
      // add only grey
      series.slices.template.set('fill', am5.color('#6D7681'));
    }
  }

  // Add data to the series
  series.data.setAll(dataExists ? data : [{value: 100, category: '', color: am5.color(0x6d7681)}]);

  // Create a background slice to show the full donut
  const bgSeries = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'value',
      categoryField: 'category',
      alignLabels: false,
    })
  );

  bgSeries.slices.template.setAll({
    fill: am5.color('#DFE5ED'), // grey color for the background slice
    fillOpacity: 0.08,
    strokeOpacity: 0,
    cornerRadius: 10,
    innerRadius: am5.percent(75),
  });

  // Animate chart and series in
  series.appear(1000, 100);
  bgSeries.appear(1000, 100);
};

export function drawCenteredImageAndText(chartOptions, chart, root, scaleFactor = 1) {
  if (!chartOptions) {
    return;
  }
  // Add the image
  let image;
  if (chartOptions.image) {
    image = chart.children.push(
      am5.Picture.new(root, {
        width: am5.percent(10),
        centerX: am5.percent(-450),
        centerY: am5.percent(-500),
      })
    );
    image.set('src', chartOptions.image);
  }

  // Add the text
  if (chartOptions.text) {
    logger.debug('Adding text to donut chart', {
      scaleFactor,
      text: chartOptions.text,
      image: chartOptions.image,
      root,
      chart,
    });
    const text = chart.children.push(
      am5.Label.new(root, {
        text: chartOptions.text,
        fontSize: 16 * scaleFactor,
        fontFamily: mont.style.fontFamily, // Apply Montserrat font
        fill: am5.color('#3C4858'),
        centerX: am5.percent(-460),
        // add 50 for each 0.1 in scaleFactor variable
        centerY: am5.percent(-150 - scaleFactor * 500),
        fontWeight: '700',
        fontStyle: 'normal',
        lineHeight: 1.3,
        width: am5.percent(10),
      })
    );
  }
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconTaskCompleter} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {PAYMENT_FLOW} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileUpgradeDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

// ExerciseDialog component
const ProfileUpgradeDialog: React.FC<_ProfileUpgradeDialogProps> = ({open, onClose}) => {
  const navigateToFlow = useNavigateToFlow();

  // Callback function when the confirmation button is clicked
  const handleConfirmation = async (): Promise<void> => {
    navigateToFlow(PAYMENT_FLOW);
    onClose(true);
  };

  return (
    <Dialog
      open={open}
      showCloseButton
      onClose={() => onClose(false)}
      buttons={[
        {
          label: trans('profile_modules.upgrade_dialog.cta'),
          variant: 'brand',
          onClick: () => {
            void handleConfirmation();
          },
          width: 'relative-col-width-3',
          iconElement: <IconTaskCompleter size="md" color="fill-white500" />,
        },
      ]}
      image="/assets/img/design_images/upgrade.svg"
      imageProps={{style: {width: 360, height: 360}}}
      justifyContent="center"
      justifyButtons="center"
      title={trans('profile_modules.upgrade_dialog.title')}
      titleVariant="h4"
    >
      <Typography variant="body1" className="text-center">
        {trans('profile_modules.upgrade_dialog.message')}
      </Typography>
    </Dialog>
  );
};

export default ProfileUpgradeDialog;

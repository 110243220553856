// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';

interface _Label {
  icon?: React.ElementType;
  label: string;
  size?: 'sm' | 'md' | 'lg';
  iconColor?: string;
  variant?: _TypographyVariants;
  bold?: boolean;
  style?: React.CSSProperties;
}

export const Label: React.FC<_Label> = ({
  size = 'sm',
  iconColor,
  icon: Icon,
  label,
  variant = 'body2',
  bold = true,
  style,
}) => {
  return (
    <div className="flex items-center gap-0">
      {Icon && <Icon size={size} color={iconColor} />}
      <Typography variant={variant} bold={bold} style={style}>
        {label}
      </Typography>
    </div>
  );
};

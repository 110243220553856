// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {formatDate} from '@modules/Core/util/dates';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ProfileSharedResult} from '@modules/Profile/types/shared-results.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileSharedResultsWithYouTableProps {
  data: _ProfileSharedResult[];
}

export const ProfileSharedResultsWithYouTable: React.FC<_ProfileSharedResultsWithYouTableProps> = ({data}) => {
  const navigateToFlow = useNavigateToFlow();
  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'relative-col-width-4-plus-gap'},
    {label: trans('base.shared_at'), key: 'created_at', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.analysis_type'), key: 'analysisType', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.action'), key: 'action', width: 'relative-col-width-2'},
  ];

  const renderRow = (row: _ProfileSharedResult): _TableDataItem => {
    return {
      name: <AvatarUser label={row.profile?.fullName} image={getProfilePic(row.profile)} />,
      created_at: <Typography>{formatDate(new Date(row.created_at))}</Typography>,
      analysisType: trans(PROFILE_SERVICES_TITLES[row.type]),
      action: (
        <TextLink
          label={trans('base.take_a_look')}
          onClick={() => {
            navigateToFlow(FLOWS.ProfileSharedResults, {
              profileId: row.profile.id,
              service: row.type,
              permissionId: row.id,
            });
          }}
        />
      ),
    };
  };

  return (
    <Table
      data={data}
      columns={columns}
      // hideBorders
      showTopBorder
      renderRow={renderRow}
      filterOptions={{
        enabled: true,
        searchAttributes: ['profile.fullName', 'created_at', 'type'],
      }}
    />
  );
};

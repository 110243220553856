// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {ProfileExercisesDevMode} from '@modules/Dev/components/ProfileExercisesDevMode';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {ProfileAlerts} from '@modules/Profile/components/alerts/ProfileAlerts';
import ExerciseFeedbackDialog from '@modules/Profile/components/exercises/dialogs/ProfileExerciseFeedbackDialog';
import StartExerciseDialog from '@modules/Profile/components/exercises/dialogs/ProfileExercisesStartDialog';
import StartNextRoundDialog from '@modules/Profile/components/exercises/dialogs/ProfileStartNextRoundDialog';
import {ProfileRoundExercises} from '@modules/Profile/components/exercises/round/ProfileRoundExercises';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {_ExerciseDataEntry, _ProfileExercisesRound} from '@modules/Profile/types/exercises.model';
import ProfileAddToCalendarDialog from '../dialogs/ProfileAddToCalendarDialog';

interface _Props {
  service: string;
  round: number;
  exerciseIndex: number | null;
  profileExercises: _ProfileExercisesRound;
}

export const ProfileRound: React.FC<_Props> = ({service, round, exerciseIndex, profileExercises}) => {
  // Exercise data
  const exercisesData = useExercises();
  const [exercises, setExercises] = useState<_ExerciseDataEntry | null>(null);

  // Dev mode
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [isDevMode, setIsDevMode] = useState<boolean>(false);
  const [devModeExerciseName, setDevModeExerciseName] = useState<string | null>(null);
  const [devModeExerciseIndex, setDevModeExerciseIndex] = useState<number | null>(null);

  // Dialogs
  const [showStartDialog, setShowStartDialog] = useState(false);
  const [showFinishedDialog, setShowFinishedDialog] = useState(false);
  const [showStartRoundDialog, setShowStartRoundDialog] = useState(false);
  const [showAddToCalendarDialog, setShowAddToCalendarDialog] = useState<boolean>(false);
  const [collectedData, setCollectedData] = useState<Record<string, any> | null>(null);

  // Used for dialogs
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState<number>(0);
  const [roundPrepared, setRoundPrepared] = useState(false);
  const [roundFinishAlerts, setRoundFinishAlerts] = useState<_ProfileAlert[]>([]);
  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  useEffect(() => {
    if (isDevMode) {
      onModeChanged('edit');
    }
  }, [isDevMode]);

  const onFeedbackGiven = async (): Promise<void> => {
    setShowFinishedDialog(false);
    if (roundFinishAlerts?.length) {
      setShowAlerts(true);
      return;
    }
    if (roundPrepared) {
      // setShowStartRoundDialog(true);
    }
  };

  const onModeChanged = (mode: 'view' | 'edit'): void => {
    if (isDevMode) {
      setMode('edit');
      return;
    }
    setMode(mode);
  };

  const onExerciseStartClick = async (newExerciseIndex: number): Promise<void> => {
    setShowStartDialog(true);
    setShowAddToCalendarDialog(false);
    setSelectedExerciseIndex(newExerciseIndex);
  };

  // TODO @Sherif fix type
  const onExerciseFinished = async (result: _FlowActionResult, newExerciseIndex: number): Promise<void> => {
    setShowFinishedDialog(true);
    setSelectedExerciseIndex(newExerciseIndex);
    setRoundPrepared(result?.roundPrepared as boolean);
    setRoundFinishAlerts((result?.alerts as _ProfileAlert[]) ?? []);
    setMode('view');
  };

  return (
    <>
      <ProfileExercisesDevMode
        isDevMode={isDevMode}
        setIsDevMode={setIsDevMode}
        devModeExerciseIndex={devModeExerciseIndex}
        setDevModeExerciseIndex={setDevModeExerciseIndex}
        devModeExerciseName={devModeExerciseName}
        setDevModeExerciseName={setDevModeExerciseName}
        availableExercises={exercisesData?.[service]?.exercises}
        service={service}
      />
      {/* {Boolean(showAlerts && roundFinishAlerts?.length > 0) && <ProfileAlerts alerts={roundFinishAlerts} />} */}
      <StartNextRoundDialog
        open={showStartRoundDialog}
        onClose={() => {
          setShowStartRoundDialog(false);
        }}
        service={service}
        profileData={profileExercises?.exercises}
        roundIndex={round}
      />
      <ExerciseFeedbackDialog
        open={showFinishedDialog}
        onClose={() => {
          void onFeedbackGiven();
        }}
        exerciseIndex={selectedExerciseIndex}
        service={service}
        roundIndex={round}
        profileData={profileExercises?.exercises}
        exerciseData={exercises}
      />
      <ProfileAddToCalendarDialog
        open={showAddToCalendarDialog}
        onClose={() => {
          setShowAddToCalendarDialog(false);
        }}
        exerciseIndex={selectedExerciseIndex}
        round={profileExercises?.exercises?.[selectedExerciseIndex]?.round}
        service={profileExercises?.exercises?.[selectedExerciseIndex]?.service}
        collectedData={collectedData}
      />
      <StartExerciseDialog
        service={service}
        open={showStartDialog}
        exerciseData={exercises}
        exerciseIndex={selectedExerciseIndex}
        profileData={profileExercises?.exercises}
        onClose={data => {
          setShowStartDialog(false);
          if (data) {
            setCollectedData(data);
            setShowAddToCalendarDialog(true);
            onModeChanged('edit');
          }
        }}
        shortVersion
      />
      <ProfileRoundExercises
        service={service}
        round={round}
        exerciseIndex={exerciseIndex}
        profileExercises={profileExercises}
        onExerciseFinished={onExerciseFinished}
        onExerciseStartClick={onExerciseStartClick}
        isDevMode={isDevMode}
        devModeExerciseName={devModeExerciseName}
        devModeExerciseIndex={devModeExerciseIndex}
        mode={mode}
        setMode={onModeChanged}
        exercises={exercises}
        setExercises={setExercises}
      />
    </>
  );
};

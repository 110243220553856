// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconArrowLeft, IconArrowRight} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {_DateRangeType} from '@modules/Core/util/dateUtil';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {inviteToTeamFlow} from '@modules/Institution/util/institutionDialogFlowsUtil';
import {EmptyOverviewCard} from '@modules/Statistics/components/general/EmptyOverviewCard';
import {ProfileExperienceCard} from '@modules/Statistics/components/general/ProfileExperienceCard';
import {ProfileHowAreYouMetricCard} from '@modules/Statistics/components/general/ProfileHowAreYouMetricCard';
import {TeamProfilesOverviewCard} from '@modules/Team/components/dashboard/cards/TeamProfilesOverviewCard';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profiles: Record<string, _TeamOverviewProfile>;
  teamId: string;
  chosenProfile: string | null;
  setChosenProfile: (value: string | null) => void;
  experienceMetric: _ProfileTrackingStatisticEntry;
  howAreYouMetric: _ProfileTrackingStatisticEntry;
  showPlaceholder?: boolean;
  filter?: _DateRangeType;
}

const LIMIT = 6;
export const TeamProfilesOverviewListSection: React.FC<_Props> = ({
  filter,
  showPlaceholder,
  howAreYouMetric,
  experienceMetric,
  chosenProfile,
  setChosenProfile,
  teamId,
  profiles,
}) => {
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    if (!chosenProfile) {
      setChosenProfile(Object.keys(profiles ?? {})?.[0] ?? null);
    }
  }, [profiles]);

  const hasNext = Object.keys(profiles ?? {}).length > startIndex + LIMIT;
  const hasPrev = startIndex > 0;

  const onProfileChange = (value: string) => {
    setChosenProfile(value);
  };

  const onAddTeamMember = () => {
    inviteToTeamFlow(teamId);
  };

  return (
    <CustomizableSectionItem
      className="h-full"
      fullHeight
      width={6}
      title={trans('gamification.team.profiles_overview')}
    >
      {showPlaceholder ? (
        <PlaceholderSection
          fullWidth
          direction="vertical"
          title={trans('team_gamification.overview_profiles_missing.title')}
          content={trans('team_gamification.overview_profiles_missing.description')}
          image={getDesignImage('team_placeholder.png')}
        />
      ) : (
        <div className="flex flex-col gap-3 justify-between h-full">
          <div className="flex flex-col gap-3 flex-grow">
            <div className="flex flex-row w-full items-center justify-between">
              <Typography>
                {trans('gamification.time_period_overview', {
                  // Also used in 3 Dashboards flow.json
                  period: trans(`dashboard.filter.${filter}`),
                })}
              </Typography>
              <div className="flex flex-row flex-grow items-center justify-end gap-3">
                {!!howAreYouMetric && (
                  <ProfileHowAreYouMetricCard label={trans('gamification.team_emotion')} metric={howAreYouMetric} />
                )}
                {!!experienceMetric && (
                  <ProfileExperienceCard
                    titleVariant="h6"
                    fullWidth
                    label="gamification.team_experience_short"
                    experiencePoints={experienceMetric.sum}
                  />
                )}
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-3 flex-wrap justify-between gap-y-3">
                {/* Render only the available profiles */}
                {Object.entries(profiles ?? {})
                  .slice(startIndex, startIndex + LIMIT)
                  .map(([key, profile]) => (
                    <div className="relative-col-width-2-75" key={key}>
                      <TeamProfilesOverviewCard onClick={() => onProfileChange(key)} key={key} profile={profile} />
                    </div>
                  ))}

                {/* Check if it's the last page, and add the ADD div */}
                {!hasNext && (
                  <div className="relative-col-width-2-75" key="add">
                    <EmptyOverviewCard onAdd={onAddTeamMember} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {(hasPrev || hasNext) && (
            <div className="flex flex-row items-center justify-center w-full gap-3">
              <IconButton disabled={!hasPrev} icon={IconArrowLeft} onClick={() => setStartIndex(startIndex - LIMIT)} />
              <IconButton disabled={!hasNext} icon={IconArrowRight} onClick={() => setStartIndex(startIndex + LIMIT)} />
            </div>
          )}
        </div>
      )}
    </CustomizableSectionItem>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _ExerciseImageProps {
  src: string | null;
  fullScreenClick?: boolean;
  style?: React.CSSProperties;
}

export const ExerciseImage: React.FC<_ExerciseImageProps> = ({src, fullScreenClick = true, style}) => {
  // Define the function that handles the click event
  const onImgClicked = (src: string): void => {
    if (!fullScreenClick) return; // If not clickable, do nothing

    // TODO @Sherif Wouldn't it be better to use a modal/popup here?
    // Create an image element and set its source to the clicked image's source
    const imgElement = document.createElement('img');
    imgElement.src = src;
    imgElement.style.width = '100%';
    imgElement.style.height = '100%';

    // Create a div to contain the image and style it for fullscreen
    const fullscreenDiv = document.createElement('div');
    fullscreenDiv.style.position = 'fixed';
    fullscreenDiv.style.top = '0';
    fullscreenDiv.style.left = '0';
    fullscreenDiv.style.width = '100vw';
    fullscreenDiv.style.height = '100vh';
    fullscreenDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    fullscreenDiv.style.display = 'flex';
    fullscreenDiv.style.justifyContent = 'center';
    fullscreenDiv.style.alignItems = 'center';
    fullscreenDiv.style.zIndex = '1000';
    fullscreenDiv.appendChild(imgElement);

    // Append the fullscreen div to the document body
    document.body.appendChild(fullscreenDiv);

    // Add an event listener to close the fullscreen view when the div is clicked
    fullscreenDiv.addEventListener('click', () => {
      document.body.removeChild(fullscreenDiv);
    });
  };

  if (!src) {
    return null;
  }
  // Render the image with the onClick event handler
  return (
    <img
      src={src}
      alt="Clickable"
      onClick={() => onImgClicked(src)}
      style={{cursor: fullScreenClick ? 'pointer' : 'default', ...style}}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';

const typeToPath: Record<string, string> = {
  profile_base_and_analysis: 'profile/result',
  motive_structure: 'profile/motive-structure',
  team_reflection: 'teams/{teamId}/reflection',
  profile_potentials: 'teams/{teamId}/development',
  team_development: 'teams/{teamId}/development/{module}/result/{initialTab}',
  team_reflection_partial: 'teams/{teamId}/reflection-partial',
  third_person_feedback: 'profile/third-person-feedback-self/{inviteId}',
};

export function useSurveyPath(type: string, params: Record<string, string> = {}): string | null {
  const [path, setPath] = useState(null);

  useEffect(() => {
    if (type) {
      setPath(createPath(type, params));
    }
  }, [type]);

  function createPath(type: string, params: Record<string, any>): string | null {
    let path = typeToPath[type];
    if (!path) {
      return path;
    }
    Object.keys(params).forEach(key => {
      path = path.replace(`{${key}}`, params[key]);
    });
    if (params.preview) {
      path += '?preview=true';
    }
    return path;
  }

  return path;
}

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconArrowLeft, IconArrowRight} from '@modules/Core/components/base/Icons/Icons';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {_ProfileExerciseStatus} from '@modules/Profile/types/exercises.model';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {TeamSoftfactsRow} from '@modules/Team/components/dashboard/TeamSoftfactsRow';
import {TeamSoftfactStatusDialog} from '@modules/Team/components/dashboard/TeamSoftfactStatusDialog';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {_TotalProgressData} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teamId: string;
  serviceStatuses: Record<string, _ShortProfile[]> | Record<string, _ShortTeam[]>;
  relevance: string[];
  xpMetrics: Record<string, _TotalProgressData>;
  resultType?: 'teams' | 'profiles';
  resultV2: Record<
    string,
    {
      progress: number;
      rounds: Array<{
        progress: number;
        exercises: Array<{
          profiles?: Array<{
            status: _ProfileExerciseStatus;
            profile: _ShortProfile;
          }>;
          teams?: Array<{
            status: _ProfileExerciseStatus;
            team: _ShortTeam;
          }>;
        }>;
      }>;
    }
  >;
}

const LIMIT = 3;

export const TeamSoftfactsOverviewItem: React.FC<_Props> = ({
  teamId,
  resultV2,
  xpMetrics,
  serviceStatuses,
  relevance,
  resultType = 'profiles',
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogService, setDialogService] = useState<string | null>(null);
  const [startIndex, setStartIndex] = useState(0);
  const [roundIndex, setRoundIndex] = useState(0);

  if (!serviceStatuses || Object.keys(serviceStatuses).length === 0) {
    return (
      <PlaceholderSection
        title={trans('team_gamification.softfacts_missing.title')}
        content={trans('team_gamification.softfacts_missing.description')}
        image={getDesignImage('your_softfacts.png')}
      />
    );
  }

  const onServiceClick = (service: string, roundIndex: number | string) => {
    setDialogService(service);
    setRoundIndex(roundIndex);
    setDialogOpen(true);
  };

  const sortedEntries = Object.entries(resultV2 ?? {})
    .filter(([service]) => resultV2[service]?.rounds?.length)
    .sort(([a], [b]) => {
      if (!relevance.includes(a)) {
        return 1;
      }
      if (!relevance.includes(b)) {
        return -1;
      }
      return relevance.indexOf(a) - relevance.indexOf(b);
    });

  const hasNext = sortedEntries.length > startIndex + LIMIT;
  const hasPrev = startIndex > 0;
  logger.debug('[TeamSoftfactsOverviewItem] serviceStatuses', {
    resultV2,
    length: sortedEntries?.length,
    startIndex,
  });
  return (
    <>
      <TeamSoftfactStatusDialog
        teamId={teamId}
        roundIndex={roundIndex}
        open={dialogOpen}
        setOpen={setDialogOpen}
        service={dialogService}
        profiles={dialogService ? serviceStatuses[dialogService] : []}
        xpMetric={dialogService ? xpMetrics[dialogService] : []}
        data={dialogService ? resultV2[dialogService] : {}}
        type={resultType}
      />
      <div className="flex flex-col gap-2">
        {sortedEntries.slice(startIndex, startIndex + LIMIT).map(([service, data]) => (
          <div key={service}>
            <TeamSoftfactsRow
              onOpenTeamBreakdown={onServiceClick}
              data={data}
              xpMetric={xpMetrics?.[service] ?? null}
              service={service}
              type={resultType}
            />
          </div>
        ))}
        {(hasPrev || hasNext) && (
          <div className="flex flex-row items-center justify-center w-full gap-3 mt-3">
            <IconButton disabled={!hasPrev} icon={IconArrowLeft} onClick={() => setStartIndex(startIndex - LIMIT)} />
            <IconButton disabled={!hasNext} icon={IconArrowRight} onClick={() => setStartIndex(startIndex + LIMIT)} />
          </div>
        )}
      </div>
    </>
  );
};

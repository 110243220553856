// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileWayOfWorkingProductivityTimes} from '@modules/Profile/types/service.model';
import {
  compressTimes,
  downloadProductivityICS,
  transformProductivityTimes,
} from '@modules/Profile/util/wayOfWorkingUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: _ProfileWayOfWorkingProductivityTimes;
}

export const ProfileWayOfWorkingProductivityTimes: React.FC<_Props> = ({data}) => {
  const onAddToCalendar = (): void => {
    void downloadProductivityICS();
  };
  return (
    <Card>
      <Typography>{trans('profile_result.productivity_profile_tooltip')}</Typography>
      {Object.entries(transformProductivityTimes(data)).map(([day, values]) =>
        compressTimes(values).map((time, index) => (
          <div
            className={addCN('flex flex-row gap-1 justify-between w-full p-1', index % 2 === 0 ? 'bg-grey50' : '')}
            key={index}
          >
            <Typography>{trans(day)}</Typography>
            <Typography>
              {trans('base.timespan', {
                from: time?.[0],
                to: time?.[1],
              })}
            </Typography>
          </div>
        ))
      )}

      <div className="flex flex-row justify-end w-full">
        <div className="relative-col-width-2">
          <Button label={trans('profile_exercises.calendar.cta')} onClick={onAddToCalendar} />
        </div>
      </div>
    </Card>
  );
};

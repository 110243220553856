// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {EXERCISES_SUPPORTED_FACETS} from '@modules/Profile/config/exercisesConstants';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceFacetsItemProps {
  service: string;
  data: Record<
    string,
    {
      classification: string;
    }
  >;
}

export const ProfileServiceFacetsItem: React.FC<_ProfileServiceFacetsItemProps> = ({service, data}) => {
  if (!service || !data) {
    return null;
  }

  return (
    <div className="flex flex-row gap-3">
      <div className="flex flex-col relative-col-width-7">
        <TabsItem
          tabs={Object.entries(data || {})
            .filter(([facet]) => EXERCISES_SUPPORTED_FACETS.includes(facet))
            .map(([facet, facetResult]) => ({
              name: facet,
              title: trans(`profile_modules.facets.${facet}.title`),
              content: (
                <div className="flex h-full pt-5">
                  {trans(`profile_modules.facets_result.${facet}.${facetResult.classification}`)}
                </div>
              ),
            }))}
        />
      </div>

      <div className="flex justify-center items-center relative-col-width-3">
        <img
          src="/assets/img/illustrations_v2/dimensions.png"
          className="svg-inject img-fluid max-w-[305px] w-[305px]"
          alt="dimension"
        />
      </div>
    </div>
  );
};

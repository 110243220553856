// @ts-nocheck
/* eslint-disable */

export const PROFILE_SERVICES_TITLES: Record<string, string> = {
  profile_base_and_analysis: 'profile_modules.services.profile_base_and_analysis.title',
  profile_analysis: 'profile_modules.services.profile_base_and_analysis.title',
  profile_roles: 'profile_modules.services.profile_roles.title',
  profile_values: 'profile_modules.services.profile_values.title',
  profile_way_of_working: 'profile_modules.services.profile_way_of_working.title',
  motive_structure: 'profile_modules.services.motive_structure.title',
  profile_communication: 'profile_modules.services.profile_communication.title',
  profile_trust: 'profile_modules.services.profile_trust.title',
  profile_motivation: 'profile_modules.services.profile_motivation.title',
  profile_responsibility: 'profile_modules.services.profile_responsibility.title',
  profile_proactivity: 'profile_modules.services.profile_proactivity.title',
  profile_self_efficacy: 'profile_modules.services.profile_self_efficacy.title',
  profile_resilience: 'profile_modules.services.profile_resilience.title',
  profile_mindfulness: 'profile_modules.services.profile_mindfulness.title',
  profile_learning: 'profile_modules.services.profile_learning.title',
  profile_emotional_intelligence: 'profile_modules.services.profile_emotional_intelligence.title',
  third_person_feedback: 'service.third_person_feedback',
};

export const PROFILE_SERVICES_DESCRIPTIONS: Record<string, string> = {
  profile_base_and_analysis: 'profile_modules.services.profile_base_and_analysis.description',
  profile_roles: 'profile_modules.services.profile_roles.description',
  profile_values: 'profile_modules.services.profile_values.description',
  profile_way_of_working: 'profile_modules.services.profile_way_of_working.description',
  motive_structure: 'profile_modules.services.motive_structure.description',
  profile_communication: 'profile_modules.services.profile_communication.description',
  profile_trust: 'profile_modules.services.profile_trust.description',
  profile_motivation: 'profile_modules.services.profile_motivation.description',
  profile_responsibility: 'profile_modules.services.profile_responsibility.description',
  profile_proactivity: 'profile_modules.services.profile_proactivity.description',
  profile_self_efficacy: 'profile_modules.services.profile_self_efficacy.description',
  profile_resilience: 'profile_modules.services.profile_resilience.description',
  profile_mindfulness: 'profile_modules.services.profile_mindfulness.description',
  profile_learning: 'profile_modules.services.profile_learning.description',
  profile_emotional_intelligence: 'profile_modules.services.profile_emotional_intelligence.description',
};

export const kickoffLangProps: Record<number, string> = {
  0: 'profile_modules.kickoff_0',
  1: 'profile_modules.kickoff_1',
  2: 'profile_modules.kickoff_2',
  3: 'profile_modules.kickoff_3',
};

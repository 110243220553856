// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {ProfileWayOfWorkingProductivityTimes} from '@modules/Profile/components/services/result/wayOfWorking/ProfileWayOfWorkingProductivityTimes';
import {
  _ProfileWayOfWorkingProductivityTimes,
  _TeamWayOfWorkingTimeManagementMethods,
} from '@modules/Profile/types/service.model';
import {TeamCommunicationChannels} from '@modules/Team/components/team_analysis/way_of_working/TeamCommunicationChannels';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  timeManagementMethods: _TeamWayOfWorkingTimeManagementMethods;
  productivityTimes: _ProfileWayOfWorkingProductivityTimes;
  profiles: string[];
  transparencyAccepted: boolean;
  allProfiles: Record<string, _TeamProfile>;
}

export const TeamWayOfWorkingTimeManagementItem: React.FC<_Props> = ({
  timeManagementMethods,
  productivityTimes,
  transparencyAccepted,
  allProfiles,
}) => {
  logger.debug('ProfileWayOfWorkingTimeManagementItem', {timeManagementMethods, productivityTimes});

  // should be dict of key = id, value = allProfiles[id]

  return (
    <div className="flex flex-row w-full gap-3">
      <Section className="relative-col-width-5">
        <TeamCommunicationChannels
          channels={timeManagementMethods}
          teamProfiles={allProfiles}
          transparencyAccepted={transparencyAccepted}
        />
      </Section>

      <Section title={trans('profile_result.productivity_profile')} fullWidth>
        <ProfileWayOfWorkingProductivityTimes data={productivityTimes} />
      </Section>
    </div>
  );
};

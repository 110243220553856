// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {Address} from '@modules/Core/components/platform/address/Address';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {_Address} from '@modules/Core/types/address.model';
import {isValidAddress} from '@modules/Core/util/addressUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_Institution} from '@modules/Institution/types/institution.model';
import {trans} from '@modules/Translations/util/i18n';

interface _BillingAddressProps {
  institution: _Institution | null;
}

export const BillingAddress: React.FC<_BillingAddressProps> = ({institution}) => {
  const snackbar = useSnackbar();

  const [address, setAddress] = useState<_Address | null>(institution?.address ?? null);
  const edited = useRef<boolean>(false);
  useEffect(() => {
    if (institution) {
      setAddress(institution.address);
    }
  }, [institution]);

  if (!institution) {
    return null;
  }

  const handleSave = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!address || institution.id === undefined || !isValidAddress(address)) {
      return;
    }

    const result = await performFlowAction(
      {
        method: 'institution.address.change',
        params: {
          institutionId: institution.id,
          address_1: address.street,
          postal_code: address.postcode,
          city: address.city,
          country: address.country,
        },
      },
      {
        refetchProfile: true,
        loadingIndicator: true, // TODO @Sherif Really?
      }
    );
    if (result.data?.status === ACTION_STATUS_SUCCESS) {
      snackbar.success(result?.data?.message!);
      reloadContentEngine();
    } else {
      snackbar.danger(trans('base.error'));
    }
  };
  return (
    <form onSubmit={handleSave}>
      <Section title={trans('institution.billing.billing_information.title')}>
        <Address
          onChange={() => {
            edited.current = true;
          }}
          address={address}
          setAddress={setAddress}
          required
        />

        <div className="flex flex-row justify-end">
          <div className="relative-col-width-2">
            <Button disabled={!edited.current} type="submit" label={trans('base.save')} />
          </div>
        </div>
      </Section>
    </form>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Divider} from '@modules/Core/components/base/Divider';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Section} from '@modules/Core/components/layout/Section';
import {LoginEmailInput} from '@modules/Core/components/platform/login/LoginEmailInput';
import {LoginPasswordInput} from '@modules/Core/components/platform/login/LoginPasswordInput';
import {trans} from '@modules/Translations/util/i18n';

interface _LoginProps {
  // Data
  email: string | null;
  setEmail: (email: string | null) => void;
  password: string | null;
  setPassword: (password: string | null) => void;
  rememberMe: boolean;
  setRememberMe: (rememberMe: boolean) => void;
  setLostPassword: (showLost: boolean) => void;
  // Actions
  onLogin: () => void;
}

export const Login: React.FC<_LoginProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  setLostPassword,
  onLogin,
}) => {
  function handleSubmit(event: any): void {
    event.preventDefault();
    onLogin();
  }

  return (
    <form onSubmit={handleSubmit} className="hide-required-stars">
      <Section gap={5}>
        <div className="flex flex-row gap-3 w-full">
          <LoginEmailInput email={email} setEmail={setEmail} />
          <LoginPasswordInput password={password} setPassword={setPassword} />
        </div>

        <div className="flex flex-row justify-between w-full">
          <CheckBox active={rememberMe} onChange={setRememberMe} label={trans('login.remember_me')} />
          <div className="relative-col-width-2">
            <Button dusk="login-btn" type="submit">
              {trans('base.login')}
            </Button>
          </div>
        </div>

        <Divider light />

        <div className="flex flex-row gap-1 w-full">
          <span>{trans('passwords.lost-password')}</span>
          <TextLink label={trans('passwords.lost-password_cta')} onClick={() => setLostPassword(true)} />
        </div>
      </Section>
    </form>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import SliderInput from '@modules/Core/components/base/SliderInput';
import {ProfileDevModeSection} from '@modules/Dev/components/ProfileDevModeSection';

export const ProfileResultCriteriaDevMode = ({maxScore = 10, profileData, setProfileData}) => {
  const [isDevMode, setIsDevMode] = useState(false);
  const [score, setScore] = useState(null);

  useEffect(() => {
    setScore(((profileData?.value || 0) / maxScore) * 100);
  }, [profileData]);

  return (
    <ProfileDevModeSection isDevMode={isDevMode} setIsDevMode={setIsDevMode}>
      <SliderInput
        label="Use the slider to change the value"
        value={profileData?.value || 0}
        onChange={value => setProfileData(value)}
        min={0}
        max={maxScore}
      />
    </ProfileDevModeSection>
  );
};

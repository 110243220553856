// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_PersonalityDimension} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamPersonalityWordsCloudItemProps {
  value: number;
  domain: _PersonalityDimension;
}

const order: _PersonalityDimension[] = [
  'openMindedness',
  'conscientiousness',
  'extraversion',
  'agreeableness',
  'emotionalStability',
];

const allWords: Record<_PersonalityDimension, Record<'main_1' | 'main_2', string[]>> = {
  agreeableness: {
    main_1: ['detachment', 'directness', 'emotional_distance'],
    main_2: ['compassion', 'courtesy', 'forbearance'],
  },
  conscientiousness: {
    main_1: ['chaos_tolerance', 'jumpiness', 'leisureliness'],
    main_2: ['neatness', 'organisation', 'reliability'],
  },
  emotionalStability: {
    main_1: ['anxiousness', 'dejection', 'unsteadiness_of_feelings'],
    main_2: ['calmness', 'confidence', 'relaxedness'],
  },
  extraversion: {
    main_1: ['adaptability', 'passivity', 'seclusion'],
    main_2: ['activity', 'assertiveness', 'sociability'],
  },
  openMindedness: {
    main_1: ['consistency', 'pragmatism', 'routine'],
    main_2: ['curiosity', 'imagination', 'sensitivity'],
  },
};

interface _WordCloudItem {
  domain: _PersonalityDimension;
  key: string;
  class: string;
  title: string;
  text: string;
  type: string;
}

export const TeamPersonalityWordsCloudItem: React.FC<_TeamPersonalityWordsCloudItemProps> = ({domain, value}) => {
  const [wordCloud, setWordCloud] = useState<_WordCloudItem[]>([]);

  useEffect(() => {
    if (!value || !domain) {
      return;
    }
    setKauaiWordcloud();
  }, [value, domain]);

  const setKauaiWordcloud = (): void => {
    const tempWordCloud: any[] = [];

    let main: 1 | 2 = 1;
    let middle = 0;
    let classSize: string | undefined;

    if (value > 3) {
      if (value < 3.5) {
        middle = 2;
      }
      main = 2;
    } else if (value > 2.5) {
      middle = 1;
    }

    const words = allWords[domain][`main_${main}`];

    words.forEach(word => {
      const langKey = `profile_result.kauai.${domain}.ranges.main_${main}.${word}`;
      classSize = middle === 0 ? 'text-[40px]' : 'text-[30px]';

      tempWordCloud.push({
        domain,
        key: word,
        class: classSize,
        title: `${langKey}.title`, // Localization to be handled if necessary
        text: `${langKey}.text`,
        type: 'main',
      });
    });

    if (middle !== 0) {
      classSize = 'text-[15px]';
      main = main === 1 ? 2 : 1;
      const secondWords = allWords[domain][`main_${main}`];

      secondWords.forEach(secondWord => {
        const langKey = `profile_result.kauai.${domain}.ranges.main_${main}.${secondWord}`;
        tempWordCloud.push({
          domain,
          key: secondWord,
          class: classSize,
          title: `${langKey}.title`,
          text: `${langKey}.text`,
          type: `middle_${middle}`,
        });
      });
    }

    // Shuffle function can be added if needed
    setWordCloud(tempWordCloud);
  };

  logger.debug('TeamPersonalityCloudItem', {
    wordCloud,
  });
  return (
    <>
      <div className="flex items-center gap-0 mb-3">
        <Typography variant="h5">{trans('project_team_result.what_describes_you')}</Typography>
        <ToolTip txt={trans('project_team_result.wordcloud_info')}>
          <IconInfo />
        </ToolTip>
      </div>

      <div className="flex flex-row items-center justify-center gap-3 w-full mb-8">
        {wordCloud?.map((word, index) => {
          return (
            <Typography key={index} className={word.class}>
              {trans(word.title)}
            </Typography>
          );
        })}
      </div>

      <Section image="/assets/img/design_images/your behaviour.png">
        <div className="flex flex-col gap-3">
          {wordCloud?.map((word, index) => {
            return (
              <div key={index} className="flex flex-col gap-2">
                <Typography variant="h6">{trans(word.title)}</Typography>

                <Typography variant="body1">{trans(word.text)}</Typography>
              </div>
            );
          })}
        </div>
      </Section>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {trans} from '@modules/Translations/util/i18n';

export const PaymentDetailsLegalText: React.FC = () => {
  return (
    <div
      className="flex flex-row justify-between w-100"
      dangerouslySetInnerHTML={{
        __html: trans('subscription.confirm_legal_text_short'),
      }}
    />
  );
};

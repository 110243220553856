// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {Cell} from '@tanstack/table-core';
import {addCN} from '@/_core/util/cssHelper';
import {_TableFilteringOptions, TableFilter} from '@modules/Core/components/base/table/TableFilter';
import {Divider} from '../Divider';
import {ReactTable} from './ReactTable';

const thContent = 'flex h-[40px] items-center gap-1 w-full pr-3';

interface _TableCell {
  columnData: Record<string, string | React.ElementType>;
  cellData: Cell<TData, TValue>;
  hideBorders?: boolean;
}

type TData = any;
type TValue = any;

const TableCell: React.FC<_TableCell> = ({columnData, cellData, hideBorders}) => {
  const currentCellData = cellData?.getValue();
  if (!currentCellData) return null;
  const dataToColumnData = {
    label: currentCellData,
    key: columnData.key,
    disabled: columnData.disabled,
    width: columnData.width,
  };
  return currentCellData ? <TableColumn data={dataToColumnData} hideBorders={hideBorders} /> : null;
};

interface _TableColumn {
  // todo@Sherif: I fail to type this properly. If string | ReactElement: Icon is red but data.label is ok. If string | React.ElementType its the other way around. Both are also wrong.
  data: Record<string, string | ReactElement>;
  isHeader?: boolean;
  hideBorders?: boolean;
  showTopBorder?: boolean;
}

// can be both - regular cell or header cell
const TableColumn: React.FC<_TableColumn> = ({data, isHeader = false, hideBorders, showTopBorder = false}) => {
  const isDisabled = data?.disabled;
  const {Icon} = data;

  return (
    <div
      className={addCN(
        'flex flex-col items-start gap-1 transition',
        hideBorders ? 'py-0' : '',
        showTopBorder && !isHeader ? 'border-t-1 pt-1 border-grey500' : ''
      )}
    >
      <div className={addCN(thContent, 'mt-2 mb-1')}>
        {Icon ? <Icon color={isDisabled && 'fill-white500'} /> : null}
        {data?.label ? (
          <span className={addCN(isHeader ? '' : 'w-full', isDisabled && 'text-typography-white')}>{data.label}</span>
        ) : null}
      </div>

      {!hideBorders && !isHeader ? <Divider light fill /> : null}
    </div>
  );
};

const getColumns = (
  columns: _TableColumns,
  hideBorders?: boolean,
  showTopBorder?: boolean
): Array<ColumnDef<TData, TValue>> =>
  columns.map(column => ({
    accessorKey: column.key,
    header: () => <TableColumn data={column} isHeader hideBorders={hideBorders} showTopBorder={showTopBorder} />,
    cell: (cell: Cell<TData, TValue>) => <TableCell cellData={cell} columnData={column} hideBorders={hideBorders} />,
    enableSorting: column.enableSorting || false, // Default to false if not specified
    sortingFn: column.sortingFn, // Pass custom sorting function if provided
    width: column?.width,
  }));

interface _Table {
  data: _TableData;
  columns: _TableColumns;
  showTopBorder?: boolean;
  hideBorders?: boolean;
  renderRow: (row: any) => _TableDataItem;
  filterOptions?: _TableFilteringOptions;
  header?: ReactNode;
  withHeaders?: boolean;
}

export type _TableData = Array<Record<string, string | number | object>>;
export type _TableDataItem = Record<string, string | number | object>;
export type _TableColumns = Array<Record<string, string | React.ElementType>>;

export const Table: React.FC<_Table> = ({
  data,
  columns,
  hideBorders,
  showTopBorder,
  renderRow,
  filterOptions,
  header,
  withHeaders = true,
}) => {
  const [processedData, setProcessedData] = useState<_TableData>([]);

  // Render row of each table and enrich it with the data
  useEffect(() => {
    const newData = data?.map(row => renderRow(row));

    setProcessedData(newData ?? []);
  }, [data]);

  const mapData = (data: any[]): _TableDataItem[] => data.map(row => renderRow(row));
  const onFilter = (filteredData: any[]): void => {
    setProcessedData(mapData(filteredData));
  };

  if (!processedData) return null;

  return (
    <>
      <TableFilter data={data} setFilteredData={onFilter} options={filterOptions} />
      {Boolean(header) && header}
      <ReactTable
        withHeaders={withHeaders}
        showTopBorder={showTopBorder}
        hideBorders={hideBorders}
        {...{data: processedData, columns: getColumns(columns, hideBorders, showTopBorder)}}
      />
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

export function getLocale(): string {
  return navigator.language;
}

export function formatMoney(priceCent: number): string {
  const locale = getLocale();

  const fmt = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  // Remove space between currency symbol and number
  const formattedPrice = fmt.format(priceCent / 100);
  return formattedPrice.replace(/\s/g, '');
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Divider} from '@modules/Core/components/base/Divider';
import {Typography} from '@modules/Core/components/base/Typography';
import {_PaymentPlan, _PaymentPriceDetails} from '@modules/Core/types/payment.model';
import {formatDate} from '@modules/Core/util/dates';
import {formatMoney} from '@modules/Core/util/locale';
import {isOneTimePaymentSubscription} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentDetailsPriceProps {
  paymentDetails: _PaymentPriceDetails | null;
  newPlan: _PaymentPlan | null;
}

export const PaymentDetailsPrice: React.FC<_PaymentDetailsPriceProps> = ({paymentDetails, newPlan}) => {
  if (!paymentDetails) {
    return null;
  }

  // PaymentDetails is a stripe Invoice object
  const priceBeforeTax = paymentDetails?.totalWithoutTax;
  const price = paymentDetails?.totalWithTax;
  const tax = paymentDetails?.tax;
  const quantity = paymentDetails?.quantity;
  const singlePrice = paymentDetails?.singlePrice;
  const priceAfterTrial = paymentDetails?.totalAfterTrial;
  const discount = paymentDetails?.discount ?? null;
  const discountValue = paymentDetails?.discountValue ?? null;

  const planTitle = trans(newPlan?.title);

  const params: Record<string, string | number | null> = {
    plan: planTitle,
    date: formatDate(new Date(paymentDetails?.trialEndsAt)),
    value: discountValue,
  };

  const trial = paymentDetails?.trial && !isOneTimePaymentSubscription(newPlan?.id ?? null);

  function priceEntry(
    text: string,
    value: number,
    entryParams: Record<string, string | number | null> = {},
    isPrice = true
  ): React.JSX.Element {
    return (
      <div className="flex flex-row justify-between w-full">
        <Typography>{trans(text, entryParams)}</Typography>{' '}
        <Typography>
          <b>{isPrice ? formatMoney(value) : value}</b>
        </Typography>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 justify-start">
      {priceEntry('subscription.quantity', quantity, params, false)}
      {priceEntry('subscription.single_price', singlePrice, params)}
      {priceEntry('subscription.subtotal', priceBeforeTax, params)}
      {discount !== null && priceEntry('subscription.discount', discount, params)}
      {priceEntry('subscription.vat', tax?.value, params)}
      <Divider light />
      {!trial && priceEntry('subscription.total', price, params)}
      {trial && priceEntry('subscription.total_after_trial', priceAfterTrial, params)}
    </div>
  );
};

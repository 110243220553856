// @ts-nocheck
/* eslint-disable */

import {create} from 'zustand';
import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {_PageLayoutProps} from '@modules/Core/types/routesTypes';
import {initNotifications} from '@modules/Notifications/components/TopBarNotifications';
import {_Notification} from '@modules/Notifications/util/notification.model';
import {_TutorialStep} from '@modules/Tutorials/components/TutorialCard';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';
import {initGlobalTutorial} from '@modules/Tutorials/util/initTutorials';

type _objectTypes = 'globalAlerts' | 'globalNotifications';

export interface _GlobalAlert {
  color?: 'success' | 'danger' | 'warning' | 'info';
  label: string;
  id: string;
  scope?: 'main' | 'popup';
}

interface _UiStore {
  isMobile: boolean;
  scaleFactor: number;

  showSideBar: boolean;
  showTopBar: boolean;
  showBreakpointHelper: boolean;
  showGridHelper: boolean;
  showDevMenu: boolean;
  showDevOutput: boolean;
  showDevComponents: boolean;
  devMenuExpanded: boolean;

  showUserMenu: boolean; // these are in the topBar. if one is activated, the others need to be deactivated
  showNotificationsMenu: boolean;
  showFlagDropdown: boolean;

  globalTutorial: undefined | _Tutorial; // todo: should go in a separate store
  globalDialog: undefined | _GlobalDialog; // todo: should go in a separate store
  globalAlerts: _GlobalAlert[]; // todo: should go in a separate store
  globalAlertsCount: number; // todo: should go in a separate store
  globalNotifications: _Notification[]; // todo: should go in a separate store
  globalNotificationsLastRead: number; // date
  pageLayoutProps?: _PageLayoutProps;

  actions: _UiStoreActions;
}

interface _UiStoreActions {
  resetUiStore: () => void;
  updateUiStore: (obj: Partial<_UiStore>) => void;

  addToGlobals: (objectType: _objectTypes, newObj: _GlobalAlert | _Notification) => void;
  updateGlobal: (objectType: _objectTypes, id: string, newObj: _GlobalAlert | _Notification) => void;
  removeFromGlobals: (objectType: _objectTypes, id: string) => void;
  clearGlobals: (objectType: _objectTypes) => void;

  addGlobalDialog: (dialog: _GlobalDialog) => void;
  removeGlobalDialog: (dialogId: string) => void;
  removeCurrentDialog: () => void;

  handleShowExclusiveUi: (obj: Partial<_UiStore>) => void;
}

const initUiStore = {
  isMobile: false,
  scaleFactor: 1,

  showSideBar: true,
  showTopBar: true,
  showBreakpointHelper: false,
  showGridHelper: false,
  showDevMenu: false,
  showDevOutput: false,
  showDevComponents: false,
  devMenuExpanded: false,

  showUserMenu: false,
  showFlagDropdown: false,
  showNotificationsMenu: false,

  globalAlerts: [],
  globalAlertsCount: 0,
  globalDialog: undefined,
  globalTutorial: initGlobalTutorial,
  globalNotifications: [...initNotifications],
  globalNotificationsLastRead: 0,
  pageLayoutProps: undefined,
};

export const useUiStore = create<_UiStore>()((set, get) => ({
  ...initUiStore,

  actions: {
    resetUiStore: () => set(() => ({...initUiStore})),
    updateUiStore: newObj => set(() => ({...newObj})),

    addToGlobals: (objectType, newObj) => {
      const current = get()[objectType] ?? [];
      set(() => ({[objectType]: [...current, newObj]}));
    },

    // removes an alert or notification from the global state
    removeFromGlobals: (objectType, alertId) => {
      const current = get()[objectType] ?? [];
      const newObj = current.filter(alert => alert.id !== alertId);
      set(() => ({[objectType]: newObj}));
    },

    addGlobalDialog: dialog => {
      if (dialog) set(() => ({globalDialog: dialog}));
    },

    updateGlobal: (objectType, id, newObj) => {
      const current = get()[objectType] ?? [];
      const newObjArr = current.map(alert => (alert.id === id ? newObj : alert));
      set(() => ({[objectType]: newObjArr}));
    },

    removeGlobalDialog: dialogId => {
      const current = get()?.globalDialog;

      if (current?.id === dialogId) set(() => ({globalDialog: undefined}));
    },
    removeCurrentDialog: () => {
      set(() => ({globalDialog: undefined}));
    },

    // resets the global alerts or notifications
    clearGlobals: objectType => set(() => ({[objectType]: []})),

    // hide other UI elements when one is shown
    handleShowExclusiveUi: newObj => {
      set(() => ({
        showUserMenu: false,
        showNotificationsMenu: false,
        showFlagDropdown: false,
        ...newObj, // then set the new object to show: true/false
      }));
    },
  },
}));

export const zustandApi = useUiStore.getState().actions;

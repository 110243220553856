// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  SHARED_WITH_OTHERS,
  SHARED_WITH_OTHERS_TYPE,
  SHARED_WITH_YOU,
  SHARED_WITH_YOU_TYPE,
} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {Tab} from '@modules/Core/components/base/Tab';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileSharedResultsHeaderProps {
  currentTab: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE;
  setCurrentTab: (tab: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE) => void;
}

export const ThirdPersonFeedbackTablesHeader: React.FC<_ProfileSharedResultsHeaderProps> = ({
  currentTab,
  setCurrentTab,
}) => {
  return (
    <Section>
      <div className="flex flex-row gap-3">
        <div className="relative-col-width-2">
          <Tab
            label={trans('profile.third_person_feedback.sent_to_title')}
            key={SHARED_WITH_YOU}
            active={currentTab === SHARED_WITH_YOU}
            onChange={active => {
              if (active) {
                setCurrentTab(SHARED_WITH_YOU);
              }
            }}
          />
        </div>

        <div className="relative-col-width-2">
          <Tab
            label={trans('profile.third_person_feedback.sent_by_title')}
            key={SHARED_WITH_OTHERS}
            active={currentTab === SHARED_WITH_OTHERS}
            onChange={active => {
              if (active) {
                setCurrentTab(SHARED_WITH_OTHERS);
              }
            }}
          />
        </div>
      </div>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';
import {GlobalTutorialCard} from '@modules/Tutorials/components/GlobalTutorialCard';
import {processDescriptionParams, TutorialCard} from '@modules/Tutorials/components/TutorialCard';
import {useTutorialFlow} from '@modules/Tutorials/hooks/prepareTutorial';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

export interface _TutorialFlowContainerProps {
  tutorial?: _Tutorial | null;
  onDone?: () => void;
  withScroll?: boolean;
  withTimeout?: boolean;
  open?: boolean;
  updateExternalDialogConfig?: (config: _Dialog) => void;
}

export const TutorialFlowContainer: React.FC<_TutorialFlowContainerProps> = ({
  open,
  withTimeout = false,
  withScroll,
  onDone,
  tutorial,
  updateExternalDialogConfig,
}) => {
  const {currentStep, totalSteps, finished, onFinish, type} = useTutorialFlow(tutorial, onDone, withTimeout);
  const lastLoadedStep = useRef<string | null | undefined>(null);
  logger.debug('TutorialFlowContainer', {type, currentStep, totalSteps, finished, tutorial, open, onDone});

  useEffect(() => {
    if (type !== 'dialog' || !updateExternalDialogConfig) {
      return;
    }

    if (lastLoadedStep.current !== currentStep?.elementId) {
      lastLoadedStep.current = currentStep?.elementId;
      updateExternalDialogConfig({
        ...currentStep,
        title: trans(currentStep?.title, processDescriptionParams(currentStep?.titleParams)),
        justifyTitle: 'center',
        titleVariant: 'h5',
      });
    }
  }, [type, updateExternalDialogConfig, currentStep]);

  logger.debug('TutorialFlowContainer', {type, currentStep, totalSteps, finished, tutorial, open});
  if (!currentStep || !open) {
    return null;
  }

  if (finished) {
    return null;
  }

  if (type === 'dialog') {
    const content = (
      <div className={twMerge('w-full', 'h-full', 'flex', 'flex', 'justify-center')}>
        <TutorialCard asDialog showTitle={false} simpleView data={currentStep} onClose={onFinish} />
      </div>
    );

    // will render as only content, dialog handled by parent
    if (updateExternalDialogConfig) {
      return content;
    }
    return (
      <Dialog showCloseButton open onClose={onFinish}>
        {content}
      </Dialog>
    );
  }

  return (
    <GlobalTutorialCard
      withScroll={withScroll}
      onClose={onFinish}
      elementId={currentStep.elementId}
      data={currentStep}
    />
  );
};

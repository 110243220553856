// @ts-nocheck
/* eslint-disable */

import React, {ElementType, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';
import {addCN} from '@/_core/util/cssHelper';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {Action} from '@modules/Core/components/layout/Action';
import {_ActionView, ActionList} from '@modules/Core/components/layout/ActionList';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';
import {CreateJournalButton} from '@modules/Profile/components/journals/CreateJournalButton';
import {_TutorialProps, StartTutorialButton} from '@modules/Profile/components/tutorials/StartTutorialButton';

export interface _Section {
  id?: string;
  children?: ReactNode | ReactNode[];
  title?: string | ReactNode;
  titleId?: string;
  subTitle?: string | ReactNode;
  description?: string;
  descriptionVariant?: _TypographyVariants;
  info?: string;
  journalKey?: string | null;
  titleIcon?: ElementType | null;
  titlePrefix?: ReactNode;
  journalSuffix?: ReactNode;
  // TODO @jonny
  // locked?: boolean;
  // lockPlaceholderItem?: _PropItem;
  actions?: _UIAction[];
  variant?: 'section' | 'group';
  className?: string;
  journalMetadata?: Record<string, any>;
  onJournalClose?: () => void;
  titleVariant?: _TypographyVariants;
  image?: string;
  imageViewDirection?: 'horizontal' | 'vertical';
  imagePosition?: 'start' | 'end';
  imagePadding?: string;
  imageHeight?: string;
  imageWidth?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  imageComponent?: ReactNode;
  locked?: boolean;
  lockedContentPlaceholder?: ReactNode;
  teamId?: string;
  gap?: number;
  imageGap?: string;
  containerGap?: number;
  justifyContent?: 'start' | 'center';
  justifyImage?: 'start' | 'center';
  tutorialProps?: _TutorialProps;
  titleAsElement?: boolean;
  // TODO @Sherif: This will be used for the new dashboards layout
  view?: 'card' | 'default';
  actionsView?: _ActionView;
  containerClassName?: string;
  imageContainerWidth?: number;
  headerClassName?: string;
  titleIconSize?: 'sm' | 'md' | 'lg';
}

const variantsGapMap = {
  section: 3,
  group: 8,
};

export const Section: React.FC<_Section> = ({
  id,
  titleId,
  children,
  title,
  subTitle,
  description,
  descriptionVariant = 'body',
  info = '',
  journalKey,
  actions,
  titleIcon,
  titlePrefix,
  variant = 'section',
  className = '',
  journalMetadata,
  onJournalClose,
  titleVariant = 'h5',
  image,
  imageViewDirection = 'horizontal',
  imagePosition = 'start',
  imagePadding,
  imageComponent,
  fullWidth = false,
  fullHeight = false,
  locked = false,
  lockedContentPlaceholder,
  imageWidth,
  imageHeight,
  teamId,
  journalSuffix,
  gap = 3,
  imageGap = 3,
  containerGap = 3,
  justifyContent = 'start',
  justifyImage = 'center',
  tutorialKey = null,
  tutorialProps = {},
  titleAsElement,
  view = 'default',
  actionsView = 'row',
  imageContainerWidth = 4,
  containerClassName,
  headerClassName,
  titleIconSize = 'lg',
}) => {
  const showHeader = !!title || !!info || !!titleIcon;

  const showHeaderContainer =
    !!showHeader || !!actions?.length || !!journalKey || !!tutorialKey || tutorialProps?.tutorialKey;
  const TitleIcon = titleIcon;
  const showRightSideHeader =
    !!titlePrefix || !!actions?.length || !!journalKey || !!tutorialKey || tutorialProps?.tutorialKey;
  // log sjowjeader
  logger.debug('showHeaderContainer', {
    showRightSideHeader,
    bool1: !!titlePrefix,
    bool2: !!actions?.length,
    bool3: !!journalKey,
    bool4: !!tutorialKey,
    bool5: !!tutorialProps?.tutorialKey,
    tutorialProps,
  });
  const showingVerticalImageView = Boolean((image ?? imageComponent) && imageViewDirection === 'vertical');

  const topbarActions = actions?.filter(action => !action.isInnerAction);
  const innerActions = actions?.filter(action => action.isInnerAction);
  let content = (
    <div
      id={id}
      className={addCN(
        `c-section flex flex-col gap-${containerGap}`,
        fullWidth ? 'w-full' : '',
        fullHeight ? 'h-full' : '',
        // container will be card
        view !== 'card' ? containerClassName : ''
      )}
    >
      {/* Title */}
      {showHeaderContainer && (
        <div
          className={addCN(
            'w-full flex flex-row items-center self-stretch',
            showingVerticalImageView && 'justify-center'
          )}
        >
          {showHeader && (
            <div
              className={addCN(
                'flex items-center gap-2 self-stretch',
                showingVerticalImageView && 'justify-center',
                showRightSideHeader ? '' : 'w-full',
                headerClassName ?? ''
              )}
            >
              {TitleIcon && <TitleIcon size={titleIconSize} />}
              {/* left side */}
              {title ? (
                titleAsElement ? (
                  title
                ) : (
                  <Typography id={titleId} variant={titleVariant}>
                    {title}
                  </Typography>
                )
              ) : null}
              {info ? (
                <ToolTip txt={info}>
                  <IconInfo size="lg" />
                </ToolTip>
              ) : null}
            </div>
          )}

          {/* right side */}
          {showRightSideHeader && (
            <div className="flex justify-end items-center flex-grow gap-3">
              {titlePrefix && titlePrefix}
              <ActionList actions={topbarActions} view={actionsView} />

              {tutorialProps?.tutorialKey && (
                <div>
                  <StartTutorialButton tooltipPlc="bottom" {...tutorialProps} />
                </div>
              )}
              {journalKey && (
                <div>
                  <CreateJournalButton
                    teamId={teamId}
                    icon
                    size="md"
                    selectedGroupCategory={journalKey}
                    metadata={journalMetadata}
                    onClose={onJournalClose}
                  />
                </div>
              )}

              {journalSuffix && journalSuffix}
            </div>
          )}
        </div>
      )}

      {subTitle ? <Typography variant="caption">{subTitle}</Typography> : null}
      <div
        className={twMerge(
          `flex flex-col gap-${gap ?? variantsGapMap[variant]} ${className}`,
          justifyContent === 'center' ? 'items-center' : ''
        )}
      >
        {description && (
          <div className="flex flex-col gap-2">
            <Typography
              className={justifyContent === 'center' ? 'text-center' : ''}
              variant={descriptionVariant}
              dangerouslySetInnerHTML={{__html: description}}
            />
          </div>
        )}
        {children}
        {innerActions?.length ? (
          <div className="flex gap-3">{innerActions?.map(action => <Action {...action} key={action.uuid} />)}</div>
        ) : null}
      </div>
    </div>
  );

  if (image ?? imageComponent) {
    const imageContent = imageComponent ?? <img src={image} alt="" className={twMerge(imageWidth, imageHeight)} />;
    content = (
      <div
        className={twMerge(
          `flex items-center flex-${imageViewDirection === 'horizontal' ? 'row' : 'col'} ${imagePosition === 'end' ? 'flex-row-reverse' : ''}`,
          fullWidth ? 'w-full' : '',
          `gap-${imageGap}`
        )}
      >
        <div
          className={addCN(
            'flex items-center',
            `relative-col-width-${imageContainerWidth}`,
            imagePadding,
            justifyImage === 'start' ? 'justify-start' : 'justify-center'
          )}
        >
          {imageContent}
        </div>
        <div
          className={`flex items-center relative-col-width-6 ${imageViewDirection === 'horizontal' ? (justifyImage === 'start' ? 'justify-start' : '') : 'justify-center'}`}
        >
          {content}
        </div>
      </div>
    );
  }

  if (view === 'card') {
    content = <Card width={containerClassName}>{content}</Card>;
  }

  if (locked) {
    return (
      <div className="lockable-content-wrapper">
        {content}
        {locked && (
          <div className="lockable-content-overlay">
            <span className="lockable-content-message">{lockedContentPlaceholder}</span>
          </div>
        )}
      </div>
    );
  }

  return content;
};

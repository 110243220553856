// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Label} from '@modules/Core/components/base/Label';

interface _Switch {
  active: boolean;
  onChange: (active: boolean) => void;
  size?: 'sm' | 'md' | 'lg';
  color?: 'light' | 'dark' | 'brand';
  disabled?: boolean;
  label?: string;
  icon?: React.ElementType;
}

export const Switch: React.FC<_Switch> = ({active, onChange, size = 'md', color = 'light', disabled, label, icon}) => {
  let trackSize = 'h-3 w-5';
  let knobSize = 'h-[12px] w-[12px]';
  if (size === 'sm') {
    trackSize = 'h-2 w-4';
    knobSize = 'h-[12px] w-[12px]';
  }
  if (size === 'md') {
    trackSize = 'h-3 w-5';
    knobSize = 'h-[16px] w-[16px]';
  }
  if (size === 'lg') {
    trackSize = 'h-4 w-7';
    knobSize = 'h-[24px] w-[24px]';
  }

  let borderColor = 'bg-white500 border-grey300';
  let bgColor = 'bg-white500';
  let iconColor = '';
  if (color === 'light') {
    borderColor = 'border-grey300';
    bgColor = 'bg-white500';
    iconColor = 'bg-grey700';
  }
  if (color === 'dark') {
    borderColor = 'border-blue700';
    bgColor = 'bg-white500';
    iconColor = 'bg-blue700';
    if (active) bgColor = 'bg-grey500';
  }
  if (color === 'brand') {
    borderColor = 'border-brand700';
    bgColor = 'bg-white500';
    iconColor = 'bg-brand700';
  }
  if (disabled) {
    borderColor = 'border-grey500';
    bgColor = 'bg-grey500';
    iconColor = 'bg-white500';
  }

  let knobPos = active ? 'left-[12px] top-[3px]' : 'left-[2px] top-[3px]';
  if (size === 'sm') knobPos = active ? 'left-[16px] top-[1px]' : 'left-[2px] top-[1px]';
  if (size === 'md') knobPos = active ? 'left-[20px] top-[3px]' : 'left-[2px] top-[3px]';
  if (size === 'lg') knobPos = active ? 'left-[26px] top-[3px]' : 'left-[3px] top-[3px]';

  const handleChange = (): void => {
    if (!disabled) onChange(!active);
  };

  return (
    <div className="flex gap-1">
      {/* track */}
      <div
        className={addCN(
          'relative rounded-xxl transition border-1',
          trackSize,
          bgColor,
          active ? 'bg-grey500' : `${borderColor} hover:bg-grey100`,
          disabled ? '' : 'cursor-pointer'
        )}
        onClick={handleChange}
      >
        {/* circle */}
        <div className={addCN('absolute transition-all rounded-full', knobSize, iconColor, knobPos)} />
      </div>
      {label ? <Label label={label} icon={icon} /> : null}
    </div>
  );
};

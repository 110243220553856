// @ts-nocheck
/* eslint-disable */

import React, {ReactNode} from 'react';
import {ExercisesCard} from '@modules/Core/components/base/cards/ExercisesCard';
import {Goals} from '@modules/Core/components/base/Goals';
import {_ExerciseCardAdvancedProps} from '@modules/Profile/components/exercises/cards/ExerciseCardAdvanced';
import {EXERCISE_STATUS_DONE, EXERCISE_STATUS_LOCKED} from '@modules/Profile/types/exercises.model';
import {trans} from '@modules/Translations/util/i18n';

interface _RoundViewProps extends _ExerciseCardAdvancedProps {
  titleContent: ReactNode;
  isRoundMissingChallengesAndGoals: boolean;
  available: boolean;
  totalExperience?: number;
}

export const RoundView: React.FC<_RoundViewProps> = ({
  totalExperience,
  card,
  titleContent,
  isRoundMissingChallengesAndGoals,
}) => {
  const {available, content, type, round, roundProgress} = card;

  if (round === undefined) {
    return null;
  }

  const isLocked = type === EXERCISE_STATUS_LOCKED || isRoundMissingChallengesAndGoals || !available;
  const isDone = type === EXERCISE_STATUS_DONE;

  return content?.map((c, i) => (
    <div className="flex flex-col h-full" key={c.title}>
      <ExercisesCard
        done={isDone}
        locked={isLocked}
        header={
          <>
            <div className="flex flex-row justify-between items-center w-full">
              <Goals
                className="items-center flex-grow"
                level={round + 1}
                xpMetrics={roundProgress}
                disabled={type === 'locked' || isRoundMissingChallengesAndGoals}
                hideProgressBar={!['started', 'open'].includes(type as string) || isRoundMissingChallengesAndGoals}
              />
              {i === 0 && titleContent}
            </div>
            {c.header && c.header}
          </>
        }
        title={c.translated ? c.title : trans(c.title)}
        contents={c.description && <span dangerouslySetInnerHTML={{__html: trans(c.description)}} />}
        action={card.action}
      />
    </div>
  ));
};

// @ts-nocheck
/* eslint-disable */

// add another one without ns
import {isArray} from '@modules/Core/util/typesUtil';
import {appState, getStore} from '@modules/State/util/util';

export function trans(word?: string | null, params = {}, returnPlaceholder = true): string {
  const t = appState().i18n.translations;
  const defaultValue = returnPlaceholder ? `NA__${word}__NA` : '';
  try {
    if (!word || !t) {
      return defaultValue;
    }
    const langKeySegments = word.split('.');
    const section = t[langKeySegments[0]];
    const rawResult = section[langKeySegments.slice(1).join('.')] as string;
    const result = replaceParams(rawResult, params);

    if (isArray(result)) {
      return result;
    }

    return result || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export function replaceParams(text: string, params: Record<string, string>): string {
  if (!text || !params) {
    return text;
  }
  for (const [name, value] of Object.entries(params)) {
    text = text.replaceAll(`:${name}`, value);
  }
  return text;
}

export function currentLanguage(): string {
  return getStore().getState().i18n.language;
}

export function isLangPropKey(key: string): boolean {
  // Will consist of section1.section2. ..., has to have at least 1 dot 2 segments
  // also, no spaces
  return key.split('.').length > 1 && !key.includes(' ');
}

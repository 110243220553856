// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {_ProfileAchievement, _ProfileLevel} from '@modules/Core/types/gamification.model';
import {logger} from '@modules/Core/util/Logger';
import {ProfileLevelAchievementCard} from '@modules/Profile/components/public/ProfileLevelAchievementCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profileAchievements: _ProfileAchievement[];
  profileLevels: _ProfileLevel[];
}

const LIMIT_PER_ROW = 3;
export const ProfileGamificationInfoItem: React.FC<_Props> = ({profileAchievements, profileLevels}) => {
  const sortedProfileLevels = profileLevels?.sort((a, b) => a.start - b.start) ?? [];

  const batchedLevels = sortedProfileLevels.reduce<_ProfileLevel[][]>((acc, level, index) => {
    const batchIndex = Math.floor(index / LIMIT_PER_ROW);
    if (!acc[batchIndex]) {
      acc[batchIndex] = [];
    }
    acc[batchIndex].push(level);
    return acc;
  }, []);

  const batchedAchievements = profileAchievements.reduce<_ProfileAchievement[][]>((acc, achievementKey, index) => {
    const batchIndex = Math.floor(index / LIMIT_PER_ROW);
    if (!acc[batchIndex]) {
      acc[batchIndex] = [];
    }
    acc[batchIndex].push(achievementKey);
    return acc;
  }, []);

  logger.debug('ProfileGamificationInfoItem', {
    batchedLevels,
    batchedAchievements,
    profileAchievements,
  });
  return (
    <>
      <Section title={trans('gamification.levels_achieved')}>
        {batchedLevels.map((levelBatch, batchIndex) => (
          <div key={batchIndex} className="flex flex-row gap-3 w-full">
            {levelBatch.map(level => (
              <div key={level.id} className="relative-col-width-3 h-full">
                <ProfileLevelAchievementCard
                  type="level"
                  title={trans(level.title)}
                  description={trans(level.description)}
                  image={level.image}
                />
              </div>
            ))}
          </div>
        ))}
      </Section>

      <Section title={trans('gamification.achievements_title')}>
        {batchedAchievements.map((achievementBatch, batchIndex) => (
          <div key={batchIndex} className="flex flex-row gap-3 w-full">
            {achievementBatch.map(achievement => {
              return (
                <div
                  key={`${achievement.category}_${achievement.milestone}`}
                  className="relative-col-width-3 mb-3 h-full"
                >
                  <ProfileLevelAchievementCard
                    type="achievement"
                    title={trans(achievement.config.title)}
                    description={trans(achievement.config.description)}
                    image={achievement.config.image}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </Section>
    </>
  );
};

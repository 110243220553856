// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _Indicator {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: 'enabled' | 'warning' | 'danger' | 'disabled' | 'success' | 'dark' | string;
}

export const Indicator: React.FC<_Indicator> = ({size = 'md', color = 'success'}) => {
  let finSize = 'h-0 w-0';
  if (size === 'xs') finSize = 'h-0 w-0';
  if (size === 'sm') finSize = 'h-1 w-1';
  if (size === 'md') finSize = 'h-2 w-2';
  if (size === 'lg') finSize = 'h-3 w-3';

  let bg = 'bg-green700';
  if (color === 'enabled') bg = 'bg-grey300';
  else if (color === 'warning') bg = 'bg-orange700';
  else if (color === 'danger') bg = 'bg-red700';
  else if (color === 'disabled') bg = 'bg-grey500';
  else if (color === 'dark') bg = 'bg-prussian700';
  else if (color === 'success') bg = 'bg-green700';
  else if (color) {
    if (color?.startsWith('#')) {
      bg = `bg-[${color}]`;
    } else {
      bg = `bg-${color}`;
    }
  } else {
    bg = 'bg-green700';
  }

  return <div className={addCN(bg, finSize, 'rounded-full')} />;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_TEMPLATE_XP} from '@modules/ContentEngine/config/constants';
import {_TypographyVariants} from '@modules/Core/components/base/Typography';

interface _Props {
  xpKey: string | null;
  width?: number | null;
  variant?: _TypographyVariants | null;
}

const ExperienceCount: React.FC<_Props> = ({xpKey, variant = 'body2', width = 2}) => {
  return <ContentEngineContainer type={CE_TEMPLATE_XP} params={{xpKey, textVariant: variant, width}} />;
};

export default ExperienceCount;

// @ts-nocheck
/* eslint-disable */

import {_TutorialStep} from '@modules/Tutorials/types/tutorial.model';

export const initGlobalTutorial: _TutorialStep = {
  title: 'Tutorial Name',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor indidunt ut labore et dolore magna aliqua...',
  progress: 50,
  direction: 'to-right',
  actions: [
    {label: 'Close', variant: 'secondary'},
    {label: 'Next', variant: 'primary'},
  ],
  url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
};

// @ts-nocheck
/* eslint-disable */

/**
 * Auth
 */
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const VERIFY_TOKEN = 'verify-account';
export const RESEND_TOKEN = 'email/resend';
export const SEND_RESET_PASSWORD_LINK = 'send-reset-password-link';
export const RESET_PASSWORD = 'reset-password';

/**
 * Profile
 */

export const NOTIFICATIONS_READ = 'profile/notifications/read';

/**
 * Platform
 */

export const COMPONENT = '';
export const COMPONENT_LOAD = 'component/load';
export const ERROR = 'error';

/**
 * Flows
 */
export const FLOW_ACTIONS = 'flow/actions';
export const FLOW_ACTION = 'flow/action';
export const FLOW_LOAD = 'flow/load';
export const FLOWS_LOAD = 'flows/load';

/**
 * Language
 */

// export const GET_TRANSLATIONS = 'lang';

/**
 * Payment
 */

export const CHECKOUT_SESSION = id => `checkout-session/${id}`;

export const START_TRIAL_REQUEST = 'start-trial-request';
export const SUBSCRIPTION_PLANS = 'subscription-plans';
export const CANCEL_SUBSCRIPTION_STATUS = 'cancel-subscription-status';
export const CHANGE_SUBSCRIPTION_STATUS = 'change-subscription-status';
/**
 * Projects
 */
export const PROJECT = 'project';
export const PROJECT_TYPE = 'project/type';
export const SEND_PROJECT_PAYMENT_EMAIL = projectId => `project/${projectId}/send-payment-email`;
export const PROFILE_CAN_SEE_PROJECT = (projectId, profileId) => `project/can-see/${projectId}/${profileId}`;
/**
 * Organization
 */
export const ORGANIZATION_PROFILES = 'organisation/profiles';

export const TEAM_DEV_MODULE_DATA = (teamId, module) =>
  `/teams/${teamId}/development-data${module ? `/${module}` : ''}`;

/**
 * Country
 */
export const COUNTRY_LIST = 'country/list';

/**
 * Softfactionary
 */
export const SOFTFACTIONARY = 'softfactionary';

/**
 * Addresses
 */

export const FILTER_ADDRESSES = 'addresses';

/**
 * Software Updates
 */

export const LATEST_SOFTWARE_UPDATE = 'software-updates/latest';

/**
 * Team Member Reminder
 */

export const TEAM_MEMBER_REMINDER = (teamId, profileId) => `teams/${teamId}/reminder/${profileId}`;

/**
 * Promotion codes
 */

export const PROMOTION_CODES = 'admin/promotion-codes';

/**
 * Roles & Permissions
 */

export const LOAD_RESOURCES = 'resources/load';

export const FEATURE_FLAGS = 'feature-flags';

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {ServiceAchievements} from '@modules/Profile/components/exercises/common/ServiceAchievements';
import {SingleExerciseRound} from '@modules/Profile/components/exercises/common/SingleExerciseRound';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {_FullExerciseDataEntry, _ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';

interface _ProfileServiceExercisesItemProps {
  service: string;
  profileExercises: _ProfileExerciseCE;
  serviceStatus: _ServiceStatus;
  showGoals?: boolean;
}

export const ProfileServiceExercisesItem: React.FC<_ProfileServiceExercisesItemProps> = ({
  service,
  profileExercises,
  serviceStatus,
  showGoals = false,
}) => {
  const exercisesData = useExercises();
  const [currentExerciseData, setCurrentExerciseData] = useState<_FullExerciseDataEntry | null>(null);

  useEffect(() => {
    if (!exercisesData?.[service]) {
      return;
    }

    setCurrentExerciseData(exercisesData[service]);
  }, [exercisesData, service]);

  if (!service) {
    return null;
  }

  return (
    <>
      {showGoals && (
        <ServiceAchievements service={service} profileExercises={profileExercises} serviceStatus={serviceStatus} />
      )}
      <SingleExerciseRound
        serviceStatus={serviceStatus}
        service={service}
        currentProfileExercises={copyObject(profileExercises)}
        currentExerciseData={currentExerciseData}
      />
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import './Tasks.scss';

interface _RichTextTaskEditViewProps {
  value: string | null;
  onChange: (val: string) => void;
}

export const RichTextTaskEditView: React.FC<_RichTextTaskEditViewProps> = ({value, onChange}) => {
  const [ReactQuill, setReactQuill] = useState<any>(null);

  // Dynamically import ReactQuill on client side
  useEffect(() => {
    import('react-quill')
      .then(module => {
        setReactQuill(() => module.default);
      })
      .catch(error => {
        console.error('Failed to load ReactQuill:', error);
      });
  }, []);

  return <div>{ReactQuill && <ReactQuill value={value} onChange={onChange} />}</div>;
};

// @ts-nocheck
/* eslint-disable */

import {EnhancedStore} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_AppState} from '@modules/State/types/appState.model';

/**
 * Trying to access the store directly causes problems
 * Redux official documentation recommends this method
 * @private
 */

// eslint-disable-next-line no-underscore-dangle
let _store: EnhancedStore<any, any, any> | null = null;

export function setStore(store: EnhancedStore<any, any, any>): void {
  _store = store;
}

export function getStore(): EnhancedStore<any, any, any> | null {
  return _store;
}

export function appState(): _AppState {
  return getStore()?.getState();
}

export async function dispatchAction(action: any): Promise<void> {
  await _store?.dispatch(action);
}

export async function refreshReduxState(loadRequest: any = null): Promise<void> {
  logger.debug('[ReduxUtil] Refreshing redux state.');
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {closeDialog, showDialog} from '@modules/Core/hooks/ui/dialog';

const CE_DIALOG_RELOAD_KEY = 'ce_reload';

interface _ContentEngineDialogProps {
  withLoadingIndicator?: boolean;
  dynamicParams?: Record<string, any>;
  unauthorized?: boolean;
  reloadKey?: string;
}

export function fireContentEngineDialog(
  templateKey: string,
  params: Record<string, any> = {},
  contentEngineProps: _ContentEngineDialogProps = {},
  dialogProps: _GlobalDialog = {}
): void {
  const dialogId = showDialog({
    children: React.createElement(ContentEngineContainer, {
      type: templateKey,
      reloadKey: contentEngineProps?.reloadKey ?? CE_DIALOG_RELOAD_KEY,
      dynamicParams: contentEngineProps?.dynamicParams,
      unauthorized: contentEngineProps?.unauthorized,
      withLoadingIndicator: contentEngineProps?.withLoadingIndicator,
      params,
    }),
    onClose: () => {
      closeDialog(dialogId);
    },
    showCloseButton: true,
    hideButtons: true,
    ...dialogProps,
  });
}

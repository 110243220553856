// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {
  _PageLayoutState,
  clearActions,
  clearOptions as clearStateOptions,
  clearSoftwareReleaseKey,
  clearTitle,
  setActions as setStateActions,
  setOptions as setStateOptions,
  setSoftwareReleaseKey,
  setSubTitle as setStateSubTitle,
  setTitle as setStateTitle,
} from '@modules/Core/state/pageLayoutSlice';
import {_LayoutOptions, _UIAction} from '@modules/Core/types/pageLayout.model';
import {copyObject} from '@modules/Core/util/util';
import {useSelectState} from '@modules/State/hooks/selectState';
import {dispatchAction} from '@modules/State/util/util';

export function usePageLayout(): {
  setActions: (actions: _UIAction[]) => void;
  setTitle: (title: string | null) => void;
  clearTitle: () => void;
  clearActions: () => void;
  setSubTitle: (subTitle: string | null) => void;
  clearSubTitle: () => void;
  title: string | null;
  subTitle: string | null;
  actions: _UIAction[];
  showSoftwareUpdate: (softwareReleaseKey: string) => void;
  hideSoftwareUpdate: () => void;
  softwareReleaseKey: string | null;
  devActions: _UIAction[];
  options: _LayoutOptions;
  setOptions: (options: _LayoutOptions) => void;
  clearOptions: () => void;
} {
  const pageLayoutState = useSelectState(state => state.pageLayout) as _PageLayoutState;

  const [title, setTitle] = useState<string | null>(null);
  const [actions, setActions] = useState<_UIAction[]>([]);
  const [subTitle, setSubTitle] = useState<string | null>(null);
  const [releaseKey, setReleaseKey] = useState<string | null>(null);
  const [devActions, setDevActions] = useState<_UIAction[]>([]);
  const [options, setOptions] = useState<_LayoutOptions>({});
  useEffect(() => {
    setTitle(pageLayoutState?.title);
    setSubTitle(pageLayoutState?.subTitle);
    setReleaseKey(pageLayoutState?.softwareReleaseKey);
    setOptions(pageLayoutState?.options || {});
    const allActions = pageLayoutState?.actions || [];
    setActions(allActions.filter(action => !action.isDevAction));
    setDevActions(allActions.filter(action => action.isDevAction));
  }, [pageLayoutState]);

  const onTitleChange = (title: string | null): void => {
    dispatchAction(setStateTitle(title));
  };

  const onActionsChange = (actions: _UIAction[]): void => {
    dispatchAction(setStateActions(actions));
  };

  const onClearTitle = (): void => {
    dispatchAction(clearTitle());
  };

  const onClearActions = (): void => {
    dispatchAction(clearActions());
  };

  const onSubTitleChange = (subTitle: string | null): void => {
    dispatchAction(setStateSubTitle(subTitle));
  };

  const onClearSubTitle = (): void => {
    dispatchAction(setStateSubTitle(null));
  };

  const showSoftwareUpdate = (newSoftwareReleaseKey: string): void => {
    dispatchAction(setSoftwareReleaseKey(newSoftwareReleaseKey));
  };

  const hideSoftwareUpdate = (): void => {
    dispatchAction(clearSoftwareReleaseKey());
  };

  const onOptionsChange = (options: _LayoutOptions): void => {
    options = copyObject(options);
    if (!options) {
      options = {};
    }

    if (!options.layout) {
      options.layout = 'default';
    }
    dispatchAction(setStateOptions(options));
  };

  const onClearOptions = (): void => {
    dispatchAction(clearStateOptions());
  };

  return {
    setActions: onActionsChange,
    setTitle: onTitleChange,
    clearTitle: onClearTitle,
    clearActions: onClearActions,
    setSubTitle: onSubTitleChange,
    clearSubTitle: onClearSubTitle,
    setOptions: onOptionsChange,
    clearOptions: onClearOptions,
    showSoftwareUpdate,
    hideSoftwareUpdate,
    options,
    title,
    subTitle,
    actions,
    softwareReleaseKey: releaseKey,
    devActions,
  };
}

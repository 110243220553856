// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';

interface _SoftfactionaryItemProps {
  title: string;
  description: string;
}

const SoftfactionaryEntry: React.FC<_SoftfactionaryItemProps> = ({title, description}) => {
  return (
    <div className="flex flex-row gap-3">
      <div className="relative-col-width-3">
        <Typography variant="h5">{title}</Typography>
      </div>
      <div className="relative-col-width-9">
        <Typography
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </div>
  );
};
export default SoftfactionaryEntry;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Divider} from '@modules/Core/components/base/Divider';
import {_PaymentDetailsDialogInput} from '@modules/Core/components/platform/payments/dialogs/PaymentDetailsDialog';
import {useProfilePayment} from '@modules/Core/components/platform/payments/paymentPlan/hooks/profilePayment';
import {PaymentPlan} from '@modules/Core/components/platform/payments/paymentPlan/PaymentPlan';
import {usePaymentPlans} from '@modules/Core/hooks/payments/PaymentPlans';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {logger} from '@modules/Core/util/Logger';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {
  isFreePlan,
  isMembershipSubscription,
  isOneTimePaymentSubscription,
} from '@modules/Profile/util/membershipsUtil';

interface _PaymentProfileProps {
  profile: _Profile | null;
  institution?: _Institution | null;
  onReady: (input: _PaymentDetailsDialogInput) => void;
}

export const PaymentProfile: React.FC<_PaymentProfileProps> = ({profile, institution = null, onReady}) => {
  const plans = usePaymentPlans();
  const {currentPlanIndex, currentSubscription, toBeActivatedSubscription, toBeCancelledSubscription} =
    useProfilePayment(plans, profile, institution);

  // return <PaymentPlans initialPlanId={initialPlanId} mode="profile" onPlanChosen={i => onReady(i)} {...props} />;
  const onChange = (plan: _PaymentPlan) => {
    logger.debug('PaymentProfile: onReady', plan);
    /**
     *  newPlan: _PaymentPlan;
     *   // TODO @Sherif types
     *   paymentDetails: any;
     *   mode: _PaymentMode;
     *   profile: _Profile | null;
     *   forProfileIds: string[];
     */
    if (!profile) {
      return;
    }
    onReady({
      newPlan: plan,
      mode: 'profile',
      forProfileIds: [profile.id],
    });
  };

  logger.debug('PaymentProfile: plans', plans);
  return (
    <>
      {(plans || [])
        .filter(
          plan => isMembershipSubscription(plan.id) || isFreePlan(plan.id) || isOneTimePaymentSubscription(plan.id)
        )
        .map((plan, index) => {
          return (
            <div className="flex flex-col gap-3" key={plan.id}>
              <PaymentPlan
                key={plan.id}
                plan={plan}
                index={index}
                onChange={() => onChange(plan)}
                profile={profile}
                institution={institution}
                currentSubscription={currentSubscription}
                currentPlanIndex={currentPlanIndex}
              />
              <Divider light />
            </div>
          );
        })}
    </>
  );
};

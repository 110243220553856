// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {usePathname} from 'next/navigation';
import {flowRoute} from '@/_core/util/util';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {downloadCertificate} from '@modules/Profile/util/exercisesUtil';
import {getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const trophiesMap: Record<number | 'final', any> = {
  1: {lottieKey: 'reward_0'},
  2: {lottieKey: 'reward_1'},
  3: {lottieKey: 'reward_2'},
  final: {lottieKey: 'exercise_completed_medal'},
};

interface _ExerciseRoundFinishedDialogProps {
  service: string;
  round?: number | null | 'final';
  updateCurrentConfig?: (config: _Dialog) => void;
  open: boolean;
  onClose: (confirmed: boolean, navigateTo?: string) => void;
}

const ExerciseRoundFinishedDialog: React.FC<_ExerciseRoundFinishedDialogProps> = ({
  service,
  round = null,
  updateCurrentConfig,
  open,
  onClose,
}) => {
  const location = usePathname();

  const handleDownload = async (): Promise<void> => {
    if (round === null || round === 'final') {
      return;
    }
    await downloadCertificate(service, round);
    onClose?.(false); // Close dialog after action
  };

  const goToResults = (): void => {
    const flowPath = flowRoute(getSurveyFlow(service)?.name);
    if (location !== flowPath && flowPath) {
      onClose?.(true, flowPath);
    } else {
      onClose?.(false);
    }
  };

  const successTrophy = round !== null ? trophiesMap[round] : null;

  useEffect(() => {
    // Update the global dialog configuration
    updateCurrentConfig?.({
      buttons:
        round !== 'final'
          ? [
              {
                label: trans('profile_exercises.download_certificate'),
                onClick: handleDownload,
                variant: 'secondary',
              },
            ]
          : null,
      lottieData: {
        lottieKey: successTrophy?.lottieKey,
        style: {
          height: '300px',
          width: '300px',
        },
        description:
          round !== 'final'
            ? trans('profile_exercises.congratulations_message', {round})
            : trans('profile_exercises.congratulations_final_message', {
                service: trans(PROFILE_SERVICES_TITLES[service]),
              }),
        title:
          round !== 'final'
            ? trans('profile_exercises.congratulations')
            : trans('profile_exercises.congratulations_final'),
      },
      title: trans('gamification.your_certificate'),
      justifyContent: 'center',
      justifyButtons: round !== 'final' ? 'between' : null,
      justifyTitle: 'center',
    });
  }, [round, updateCurrentConfig, open, onClose]);

  return null;
};

export default ExerciseRoundFinishedDialog;

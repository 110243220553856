// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {ProfileExercisesOverview} from '@modules/Profile/components/exercises/ProfileExercisesOverview';
import {BaseStep} from '../Base/BaseStep';

export const ProfileExercisesOverviewStep: React.FC<_BaseStepComponentOptions> = ({options}) => (
  <BaseStep options={options}>
    <ProfileExercisesOverview exercises={options.exercises} />
  </BaseStep>
);

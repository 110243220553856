// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCheck, IconEditText, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';

interface _Props {
  displayValue: string;
  onDelete: () => void;
  onChange?: (value: string) => void;
  onConfirm?: () => void;

  [key: string]: any;
}

export const TodoItem: React.FC<_Props> = ({onConfirm, onDelete, onChange, displayValue}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [value, setValue] = useState<string>(displayValue);

  const onChangeValue = (newValue: string): void => {
    setValue(newValue);
    onChange?.(newValue);
  };
  return (
    <div className="w-full flex flex-row gap-2 justify-between items-center">
      <div className="flex relative-col-width-10 items-center">
        {mode === 'view' ? (
          <Typography>{value}</Typography>
        ) : (
          <TextArea rows={2} onChange={e => onChangeValue(e.target.value)} value={value} />
        )}
      </div>
      <IconButton
        onClick={() => {
          if (mode === 'view') {
            setMode('edit');
          } else {
            setMode('view');
            onConfirm && onConfirm();
          }
        }}
        icon={mode === 'view' ? IconEditText : IconCheck}
      />
      <IconButton onClick={onDelete} icon={IconTrash} />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {LottieComponentProps} from 'lottie-react';
import dynamic from 'next/dynamic';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';

const Lottie = dynamic(async () => await import('lottie-react'), {ssr: false});

interface _Props extends LottieComponentProps {
  lottieKey: string;
}

const LottieWrapper: React.FC<_Props> = ({lottieKey, ...props}) => {
  const [animationData, setAnimationData] = useState<any>(null);

  // Dynamically import Lottie on client side
  useEffectAsync(async () => {
    // simulate delay
    const response = await fetch(`/lottie/${lottieKey}.json`);
    const data = await response.json();
    setAnimationData(data);
  }, [lottieKey]);

  return Lottie && animationData && <Lottie {...props} animationData={animationData} />;
};

export default LottieWrapper;

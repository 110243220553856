// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_TEMPLATE_TEAM_DEV_SUBMODULE_XP} from '@modules/ContentEngine/config/constants';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {copyObject} from '@modules/Core/util/util';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {useProfile} from '@modules/Profile/hooks/profile';
import {_Profile} from '@modules/Profile/types/profile.model';
import {TeamDevelopmentProgressBar} from '@modules/Team/components/team_development/common/TeamDevelopmentProgressBar';
import {getTeamDevelopmentModuleImage} from '@modules/Team/util/serviceUtil';
import {trans} from '@modules/Translations/util/i18n';

export const subModulesLangKeys: Record<'motivation' | 'inspiration' | 'collectivetools' | 'individual', string> = {
  motivation: 'development_result.module_overview.motivation',
  inspiration: 'development_result.module_overview.inspiration',
  collectivetools: 'development_result.module_overview.collectivetools',
};

interface _Props {
  teamId: string;
  profiles: Record<string, _Profile>;
  index: number;
}

export const TeamDevelopmentModuleCard: React.FC<_Props> = ({
  teamId,
  profiles,
  module,
  subModule,
  subSubModule,
  progress,
  xpMetrics,
  latestSubModuleProgress,
  isTeamSession = false,
  index,
}) => {
  const [moduleProgressData, setModuleProgressData] = useState(null);
  const [currentProfile] = useProfile();
  const navigateToFlow = useNavigateToFlow();

  const [chosenProfiles, setChosenProfiles] = useState<_Profile[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  useEffect(() => {
    if (isTeamSession) {
      return;
    }
    if (progress) {
      const subModuleProgress = copyObject(progress.subModules[subModule?.key]);

      for (const profileId in subModuleProgress) {
        if (subSubModule) {
          subModuleProgress[profileId] =
            subModuleProgress[profileId].progress === 100
              ? 100
              : subModuleProgress[profileId].subSubModules[subSubModule.key];
        } else {
          subModuleProgress[profileId] = subModuleProgress[profileId].progress;
        }
      }

      setModuleProgressData({progress: subModuleProgress});
    }
  }, [isTeamSession, progress, subModule?.key, subSubModule]);

  function getCardStyle(): string {
    if (isTeamSession) {
      return;
    }
    const subModuleState = subModule?.state;

    const subSubModuleState = subSubModule?.state;

    if (subModuleState === 'todo' && (!subSubModuleState || subSubModuleState === 'todo')) {
      // TODO @Sherif why not do this with tailwind?
      return 'highlight-border';
    }
  }

  function navigateToTeamSession() {
    const params = {
      teamId,
      module,
      seeResult: true,
      initialTab: 'teamAnswers',
    };

    navigateToFlow(FLOWS.TeamDevelopment, params);
  }

  function navigateToModule() {
    const params = {
      teamId,
      module,
    };

    if (subModule) {
      params.subModule = subModule?.key;
    }

    if (subSubModule) {
      params.subSubModule = subSubModule?.key;
    }

    navigateToFlow(FLOWS.TeamDevelopment, params);
  }

  function navigateToResult() {
    const params = {
      teamId,
      module,
      seeResult: true,
    };

    navigateToFlow(FLOWS.TeamDevelopment, params);
  }

  function imageComponent() {
    let key = null;
    if (!subModule && !isTeamSession) {
      return null;
    }
    if (isTeamSession) {
      key = 'team_session';
    } else {
      key = subSubModule ? subSubModule.key : subModule.key;
    }

    return (
      <img
        src={getTeamDevelopmentModuleImage(isTeamSession ? 'team_session' : subModule?.key, index)}
        // src={`/assets/img/undraw/team_dev/${key}.svg`}
        className="svg-inject img-fluid"
      />
    );
  }

  function progressComponent() {
    if (!moduleProgressData || isTeamSession) {
      return null;
    }

    const subModuleState = subModule?.state;

    if (subModuleState !== 'todo' && subModuleState !== 'done') {
      return null;
    }

    return (
      <TeamDevelopmentProgressBar
        setChosenProfiles={setChosenProfiles}
        setShowModal={setOpenModal}
        chosenProfiles={chosenProfiles}
        showModal={openModal}
        profiles={profiles}
        module={moduleProgressData}
      />
    );
  }

  function teamSessionButtonComponent() {
    if (latestSubModuleProgress === 100) {
      return (
        <Button onClick={navigateToTeamSession} dusk="open-btn">
          {trans('base.open')}
        </Button>
      );
    }

    return (
      <Typography className="bg-gradient-light rounded p-2">
        {trans('development_result.team_dev_v2.team_session_locked')}
      </Typography>
    );
  }

  function buttonComponent() {
    if (isTeamSession) {
      return teamSessionButtonComponent();
    }
    const subModuleState = subModule?.state;

    const currentProfileProgress = moduleProgressData?.progress[currentProfile?.id];

    if (!subModuleState) {
      return null;
    }

    const subSubModuleState = subSubModule ? subSubModule.state : null;

    if (subModuleState === 'todo-not-now') {
      return (
        <Typography className="bg-gradient-light rounded p-2">
          {trans('development_result.team_dev_v2.session_locked')}
        </Typography>
      );
    }

    if (subSubModuleState === 'todo-not-now') {
      return (
        <Typography className="bg-gradient-light rounded p-2">
          {trans('development_result.team_dev_v2.session_locked')}
        </Typography>
      );
    }

    if (subModuleState === 'todo' && (!subSubModuleState || subSubModuleState === 'todo')) {
      const title = !currentProfileProgress
        ? 'development_result.team_dev_v2.start_submodule'
        : 'development_result.team_dev_v2.continue_submodule';
      return (
        <Button onClick={navigateToModule} dusk="module-start-btn">
          {trans(title)}
        </Button>
      );
    }

    if (subModuleState === 'done' || subSubModuleState === 'done') {
      return (
        <Button variant="secondary" onClick={navigateToResult} dusk="module-result-btn">
          {trans('development_result.team_dev_v2.your_results')}
        </Button>
      );
    }

    return (
      <Typography className="bg-gradient-light rounded p-2">
        {trans('development_result.team_dev_v2.session_locked')}
      </Typography>
    );
  }

  function titleComponent() {
    let title = null;
    if (isTeamSession) {
      title = trans('development_result.team_dev_v2.team_session');
    } else {
      title = subSubModule ? subSubModule?.title : subModule?.title;
    }

    if (Object.keys(subModulesLangKeys).includes(subModule?.key)) {
      title = trans(subModulesLangKeys[subModule?.key], {
        module: trans(`profile.${module}`),
      });
    }

    return title;
  }

  return (
    <Card
      className={twMerge('flex flex-col items-center gap-5', getCardStyle())}
      titleVariant="h6"
      preChildren={
        <div className="flex flex-row w-full items-center justify-end">
          <ContentEngineContainer
            type={CE_TEMPLATE_TEAM_DEV_SUBMODULE_XP}
            params={{
              teamDevXPSubModule: subModule?.key,
            }}
          />
        </div>
      }
      title={titleComponent()}
    >
      {imageComponent()}
      <div className="w-[65%]">{progressComponent()}</div>

      <div className="flex flex-row justify-end items-center">{buttonComponent()}</div>
    </Card>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProfileServiceResultDevMode} from '@modules/Dev/components/ProfileServiceResultDevMode';
import {ServiceAchievements} from '@modules/Profile/components/exercises/common/ServiceAchievements';
import {ProfileResultGraph} from '@modules/Profile/components/services/result/common/ProfileResultGraph';
import {ProfileServiceNextRoundReady} from '@modules/Profile/components/services/result/common/ProfileServiceNextRoundReady';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {_ServiceResult, _ServiceResultChanges, _ServiceResultData} from '@modules/Profile/types/service.model';

interface _ProfileServiceGraphItemProps {
  service: string;
  data: _ServiceResultData;
  serviceStatus: _ServiceStatus;
  storedResults: _ServiceResult[];
  changes: _ServiceResultChanges;
  profileExercises: _ProfileExerciseCE;
  view?: 'shared' | 'result';
}

export const ProfileServiceGraphItem: React.FC<_ProfileServiceGraphItemProps> = ({
  service,
  data,
  serviceStatus,
  storedResults,
  changes,
  profileExercises,
  view = 'result',
}) => {
  // Graph data
  const [available, setAvailable] = useState<boolean>(false);

  // Dev mode
  const [isDevMode, setIsDevMode] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<_ServiceResultData | null>(null);
  const [changesData, setChangesData] = useState<_ServiceResultChanges | null>(null);

  useEffect(() => {
    if (!isDevMode) {
      setChangesData(changes);
    }
  }, [changes, isDevMode]);
  useEffect(() => {
    if (!isDevMode) {
      setProfileData(data);
    }
  }, [data, isDevMode]);
  useEffect(() => {
    setAvailable(profileExercises?.rounds?.[profileExercises?.currentRound]?.available);
  }, [profileExercises]);

  if (!service || !serviceStatus) {
    return null;
  }

  return (
    <>
      <ProfileServiceResultDevMode
        profileData={profileData}
        setProfileData={setProfileData}
        isDevMode={isDevMode}
        setIsDevMode={setIsDevMode}
        changes={changesData}
        setChanges={setChangesData}
      />
      <ServiceAchievements service={service} profileExercises={profileExercises} serviceStatus={serviceStatus} />

      {view === 'result' && <ProfileServiceNextRoundReady serviceStatus={serviceStatus} available={available} />}

      <ProfileResultGraph
        changesData={changesData}
        profileData={profileData}
        service={service}
        data={data}
        serviceStatus={serviceStatus}
        storedResults={storedResults}
        changes={changes}
        profileExercises={profileExercises}
        available={available}
        view={view}
      />
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {
  _CustomizableSectionItemProps,
  CustomizableSectionItem,
} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {IconLocked} from '@modules/Core/components/base/Icons/Icons';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {_DateRangeType} from '@modules/Core/util/dateUtil';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {CustomizableSectionContent} from '@modules/Statistics/components/sections/CustomizableSectionContent';
import {trans} from '@modules/Translations/util/i18n';

interface _Props extends _CustomizableSectionItemProps {
  statisticImage?: string;
  statisticsData?: _ProfileTrackingStatisticEntry;
  dataTitle?: string;
  dataTitle2?: string;
  statisticsData2?: _ProfileTrackingStatisticEntry;
  filter?: _DateRangeType;
  locked?: boolean;
}

export const CustomizableStatisticSectionItem: React.FC<_Props> = ({
  statisticImage,
  statisticsData,
  title,
  titleVariant = 'h6',
  dataTitle,
  dataTitle2,
  statisticsData2,
  filter: filteringType,
  locked,
  ...props
}) => {
  logger.debug('CustomizableStatisticSectionItem', {title, statisticImage, statisticsData, props});
  const scaleFactor = useUiStore(state => state.scaleFactor);

  const data1Component = (
    <div className="flex flex-col">
      <Typography variant="body1">{dataTitle}</Typography>
      <CustomizableSectionContent value={statisticsData} />
    </div>
  );

  const data2Component = dataTitle2 ? (
    <div className="flex flex-col">
      <Typography variant="body1">{dataTitle2}</Typography>
      <CustomizableSectionContent value={statisticsData2} />
    </div>
  ) : null;

  let imageScale = '_3x';
  if (scaleFactor < 1) {
    imageScale = '_2x';
  }
  logger.debug('CustomizableStatisticSectionItem', {
    data1Component,
    data2Component,
    data1period: statisticsData,
    data2period: statisticsData2,
    statisticsData,
    statisticsData2,
    filteringType,
  });
  return (
    <CustomizableSectionItem
      containerClassName="overflow-hidden relative"
      {...props}
      titleAsElement
      headerClassName="w-full"
      titleVariant={titleVariant}
      title={
        <TitleComponent
          filterProps={filteringType}
          image={statisticImage}
          title={title}
          variant={titleVariant}
          metricValue={statisticsData?.trend}
          locked={locked}
        />
      }
      tutorialKey={locked ? null : props.tutorialKey}
      tutorialService={locked ? null : props.tutorialService}
    >
      <div className={twMerge('w-full h-full', 'flex flex-col gap-3')}>
        {locked ? (
          <Typography variant="body1">{trans('gamification.locked_section')}</Typography>
        ) : (
          <>
            {data1Component && data2Component ? (
              <div className="flex flex-row gap-3 items-center">
                {data1Component}
                {data2Component}
              </div>
            ) : (
              <>{data1Component}</>
            )}
          </>
        )}

        <div className="absolute relative-col-width-1-5 -right-7 -bottom-2">
          <img
            src={getStatisticImage(`${statisticImage ?? ''}${imageScale}`)}
            style={{opacity: '0.2'}}
            className="w-auto h-auto"
          />
        </div>
      </div>
    </CustomizableSectionItem>
  );
};

const TitleComponent: React.FC<{
  image: string;
  title: string;
  variant: _TypographyVariants;
  metricValue: number;
  locked?: boolean;
}> = ({image, title, variant, metricValue, locked}) => {
  const changeType = metricValue > 0 ? '+' : metricValue < 0 ? '-' : 'N';
  return (
    <div className="flex flex-col gap-2 flex-grow">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row items-center gap-2 flex-grow">
          <img src={getStatisticImage(image)} />
          <Typography variant={variant}>{title}</Typography>
        </div>
        <div className="flex flex-row items-center gap-2">
          {locked ? <IconLocked /> : <StatisticLabelBadge change={metricValue} changeType={changeType} />}
        </div>
      </div>
    </div>
  );
};

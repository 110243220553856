// @ts-nocheck
/* eslint-disable */

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

const fontFamily = 'softfact, sans-serif';

export const initializeLinesChart = (root, chartOptions, {data, labels}) => {
  if (!data || !labels) return;

  const {convertByFactor, toPercentage, customYLabels, customYGridLines, minDate, maxDate} = chartOptions;

  // Create chart
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      layout: root.verticalLayout,
      pinchZoomX: false,
    })
  );

  // Create axes
  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: 'day', count: 1},
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
      tooltipDateFormats: {
        millisecond: 'HH:mm dd.MM.yy',
        second: 'HH:mm dd.MM.yy',
        minute: 'HH:mm dd.MM.yy',
        hour: 'HH:mm dd.MM.yy',
        day: 'dd.MM.yy',
        week: 'dd.MM.yy',
        month: 'MMM yyyy',
        year: 'yyyy',
      },
      min: minDate, // Set minimum date
      max: maxDate, // Set maximum date
    })
  );

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      min: 0,
      max: 100,
    })
  );

  // Helper function to get color value from CSS variable
  function getColorValue(color) {
    if (color.startsWith('var(--')) {
      const variableName = color.match(/var\(--(.*?)\)/)[1];
      return getComputedStyle(document.documentElement).getPropertyValue(`--${variableName}`).trim() || null;
    }
    return color;
  }

  // Function to create series
  function createSeries(name, field, color) {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name,
        xAxis,
        yAxis,
        valueYField: field,
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '[bold]{name}[/]\n{valueY}%',
        }),
      })
    );

    const colorValue = getColorValue(color);
    series.set('stroke', am5.color(colorValue));
    series.set('fill', am5.color(colorValue));

    // Add bullets
    series.bullets.push(function () {
      const bullet = am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get('stroke'),
          strokeWidth: 2,
          stroke: am5.color(0xffffff),
        }),
      });

      bullet.get('sprite').adapters.add('radius', (radius, target) => {
        const {dataItem} = target;
        return dataItem?.dataContext?.isPrediction ? 0 : radius;
      });

      return bullet;
    });

    return series;
  }

  // Create series for each label
  const seriesArray = labels.map(l => createSeries(l.label, l.accessor, l.color));

  // Create a series for top tooltips
  const topSeries = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: 'Measurement',
      xAxis,
      yAxis,
      valueYField: 'y',
      valueXField: 'date',
      tooltip: am5.Tooltip.new(root, {
        labelText: '[bold]{service}[/]',
      }),
      fill: am5.color(0x457da0),
    })
  );

  // Hide the strokes (lines) of the topSeries
  topSeries.strokes.template.set('visible', false);

  // Add bullets to topSeries
  topSeries.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 5,
        fill: am5.color(0x000000),
        fillOpacity: 0,
        strokeOpacity: 0,
      }),
    });
  });

  // Set data
  seriesArray.forEach(series => series.data.setAll(data));

  // Create top data (you may need to adjust this based on your actual data structure)
  const topData = data
    .filter((item, index) => !item.isPrediction)
    .map(item => ({
      date: item.date,
      y: 100, // Adjust as needed
      service: item.service,
    }));
  topSeries.data.setAll(topData);

  // Make stuff animate on load
  seriesArray.forEach(series => series.appear(1000));
  topSeries.appear(1000);
  chart.appear(1000, 100);

  // Add cursor
  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    })
  );

  cursor.lineY.set('visible', false);

  // Add legend
  const legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
    })
  );

  // Set legend data without showing topSeries
  legend.data.setAll(chart.series.values.filter(series => series !== topSeries));
};

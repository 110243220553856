// @ts-nocheck
/* eslint-disable */

export function getLastItem(array: any[]): any {
  if (!array?.length) {
    return null;
  }
  return array[array.length - 1];
}

export function arrayToMapByIndex(arr: any[]): Record<number, any> {
  return arr.reduce((map, item, index) => {
    map[index] = item;
    return map;
  }, {});
}

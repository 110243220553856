// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';
import {FlowManager} from '@modules/FlowEngine/util/managers/flowManager';

/**
 * This hook is responsible for reloading the current step whenever the flow state changes
 * @param flowManager
 * @param currentStep
 */
export function useInitCurrentStep(flowManager: FlowManager | null, currentStep?: _FlowStep): void {
  useEffect(() => {
    const reloadStep = (): any => flowManager?.initStep(currentStep);
    addReactEventListener('reload.step', reloadStep);
    return () => {
      removeReactEventListener('reload.step', reloadStep);
    };
  }, [flowManager, currentStep]);

  useEffectAsync(async () => {
    // added currentProfile here
    // to recalculate the showConditions of the step when profile is reloaded
    if (flowManager && currentStep) {
      logger.info('[useInitCurrentStep] Initializing current step', currentStep);
      flowManager.initStep(currentStep);
    }
  }, [currentStep?.stepName, flowManager]);
}

// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {getTeamById} from '@modules/Team/services/teamsApi';
import {_Team} from '@modules/Team/types/team.model';

export interface _TeamState {
  currentTeam: _Team | null;
}

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    currentTeam: null,
  } satisfies _TeamState,
  reducers: {
    setCurrentTeam(state, action) {
      logger.info('[TeamSlice] Saving team.');

      state.currentTeam = action.payload;
    },
    resetCurrentTeam(state, action) {
      logger.info('[TeamSlice] Resetting team.');

      state.currentTeam = null;
    },
  },
});

export const {setCurrentTeam, resetCurrentTeam} = teamSlice.actions;
export default teamSlice;

export function changeCurrentTeam(teamId: string | null = null, teams: _Team[] | null = null, loadRequest: any = null) {
  return async (dispatch: any, getState: any) => {
    logger.info('[TeamSlice] Changing current team.', {
      teamId,
      teams,
    });

    if (!teamId) {
      teamId = getState()?.team?.currentTeam?.id;
    }

    if (!teamId) {
      return;
    }

    async function fetchData(): Promise<any> {
      if (!teamId) {
        return;
      }
      const response = await getTeamById(teamId);

      if (response) {
        dispatch(setCurrentTeam(response?.data));
      }
    }

    if (loadRequest) {
      await loadRequest(fetchData);
    } else {
      await fetchData();
    }
  };
}

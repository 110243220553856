// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Section} from '@modules/Core/components/layout/Section';
import {SERVICE_TEAM_DEVELOPMENT} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {TeamSatisfactionPerformance} from '@modules/Team/components/dashboard/SatisfactionPerformance/TeamSatisfactionPerformance';
import {TeamDevelopmentOverviewTable} from '@modules/Team/components/team_development/overview/TeamDevelopmentOverviewTable';
import {_TeamDevelopmentSatisfactionPerformance} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentOverviewProps {
  teamId: string;
  preview: boolean;
  profiles: Record<string, _Profile>;
  modulesData: Record<string, any>;
  satisfactionPerformance: _TeamDevelopmentSatisfactionPerformance;
  context: _ContentEngineContext;
  tutorialKey?: string;
}

export const TeamDevelopmentOverview: React.FC<_TeamDevelopmentOverviewProps> = ({
  modulesData,
  profiles,
  teamId,
  preview,
  satisfactionPerformance,
  context,
  tutorialKey,
}) => {
  function tableComponent() {
    return (
      <TeamDevelopmentOverviewTable preview={preview} teamId={teamId} profiles={profiles} progressData={modulesData} />
    );
  }

  logger.debug('TeamDevelopmentOverview', {teamId, preview, profiles, modulesData, context, tutorialKey});

  return (
    <Section>
      <TeamSectionItem
        fullWidth
        teamId={teamId}
        tutorialKey={tutorialKey}
        tutorialService={`${SERVICE_TEAM_DEVELOPMENT}:overview`}
        context={context}
        title={trans('development_result.satisfaction_and_performance_title')}
        info={trans('development_result.satisfaction_and_performance_tooltip')}
        description={trans('development_result.satisfaction_and_performance_description')}
      >
        <TeamSatisfactionPerformance teamId={teamId} data={satisfactionPerformance} />
      </TeamSectionItem>

      {tableComponent()}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {useProfileGlobalDialog} from '@modules/Profile/providers/ProfileGlobalDialogProvider';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

interface _Props {
  tutorials: Record<string, _Tutorial>;
}

export const ProfileTutorials: React.FC<_Props> = ({tutorials}) => {
  const profileGlobalDialog = useProfileGlobalDialog();

  useEffect(() => {
    const openTutorials =
      copyObject(Object.values(tutorials ?? {}))?.filter(tutorial => !tutorial.completed && !tutorial.hideOnStart) ??
      [];
    logger.debug('addTutorials', {
      openTutorials,
      tutorials,
    });
    if (openTutorials.length > 0) {
      openTutorials.forEach(tutorial => {
        tutorial.id = tutorial.name;
      });
      profileGlobalDialog.addTutorials(openTutorials);
    }
  }, [tutorials]);

  return null;
};

// @ts-nocheck
/* eslint-disable */

import {AbstractValidator} from './abstractValidator';

export class LengthBetweenValidator extends AbstractValidator {
  validate(value: string): boolean {
    return value?.length >= this.options.value?.min && value?.length <= this.options.value?.max;
  }
}

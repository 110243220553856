// @ts-nocheck
/* eslint-disable */

import {navigateTo} from '@/_core/util/util';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';
import {_TutorialStep} from '@modules/Tutorials/types/tutorial.model';

export function prepareTutorialStep(
  step: _TutorialStep,
  index: number,
  totalSteps: number,
  onNext: () => void,
  onPrevious: () => void,
  onFinish: () => void
): _TutorialStep {
  return {
    ...step,
    progress: (index / totalSteps) * 100,
    actions: getStepActions(step, index, totalSteps, onNext, onPrevious, onFinish),
  };
}

export function getStepActions(
  step: _TutorialStep,
  index: number,
  totalSteps: number,
  onNext: () => void,
  onPrevious: () => void,
  onFinish: () => void
): _UIAction[] {
  const actions = step.actions ?? ([] as _UIAction[]);
  if (actions.length > 0) {
    return actions;
  }
  // return default actions

  // First step & not the last step -> next
  if (index === 0 && totalSteps > 1) {
    return [
      {
        label: trans('tutorials.next'),
        type: 'button',
        onClick: onNext,
      },
    ];
  }

  // previous step exists, previous
  if (index > 0) {
    actions.push({
      label: trans('tutorials.back'),
      type: 'secondary_button',
      onClick: onPrevious,
    });
  }

  // next step exists, next
  if (index < totalSteps - 1) {
    actions.push({
      label: trans('tutorials.next'),
      type: 'button',
      onClick: onNext,
    });
  } else if (index === totalSteps - 1) {
    const hasCTA = Boolean(step?.cta && step?.ctaLink);
    logger.debug('hasCTA', {
      step,
      hasCTA,
    });
    // Last step
    actions.push({
      label: trans(hasCTA ? step.cta : 'tutorials.finish'),
      type: 'button',
      onClick: hasCTA ? () => navigateTo(step.ctaLink) : onFinish,
    });
  }
  return actions;
}

export const isLastStep = (index: number, totalSteps: number): boolean => {
  return index === totalSteps - 1;
};

export const isFirstStep = (index: number): boolean => {
  return index === 0;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {logger} from '@modules/Core/util/Logger';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {concatTeamNames} from '@modules/Institution/util/institutionUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  teams: _InstitutionOverviewTeam[];
  xpMetric: _ProfileTrackingStatisticEntry;
}

const LIMIT = 3;
export const OrganisationSoftfactsRow: React.FC<_Props> = ({xpMetric, service, teams}) => {
  const Icon = getServiceIcon(service);

  logger.debug('ExercisesRoundRow', {service});

  const teamNames = concatTeamNames(teams, LIMIT);

  return (
    <SectionRow
      title={trans(PROFILE_SERVICES_TITLES[service])}
      subtitle={teamNames}
      icon={Icon && <Icon />}
      buttonIcon={null}
      prefixContent={
        !!xpMetric?.trend && (
          <StatisticLabelBadge
            change={xpMetric?.trend}
            changeType={xpMetric?.trend > 0 ? '+' : xpMetric?.trend < 0 ? '-' : 'N'}
          />
        )
      }
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {SvgRegistry} from 'survey-core';
import {
  IconAsterisk,
  IconCheckbox,
  IconCollapseSettings,
  IconCopy,
  IconDrag,
  IconExpandSettings,
  IconInfo,
  IconLongText,
  IconMore,
  IconRadio,
  IconRating,
  IconRedo,
  IconSingleLineInput,
  IconTrash,
  IconTune,
  IconUndo,
} from '@modules/Core/components/base/Icons/Icons';
import {iconToSvgString} from '@modules/Core/util/iconsUtil';

// Register icons
export const useRegisterSurveyJSIcons = () => {
  useEffect(() => {
    // StylesManager.applyTheme('default');
    // Toolbox
    SvgRegistry.registerIconFromSvg('checkbox', iconToSvgString(IconCheckbox));
    SvgRegistry.registerIconFromSvg('text', iconToSvgString(IconSingleLineInput));
    SvgRegistry.registerIconFromSvg('comment', iconToSvgString(IconLongText));
    SvgRegistry.registerIconFromSvg('rating', iconToSvgString(IconRating));
    SvgRegistry.registerIconFromSvg('radiogroup', iconToSvgString(IconRadio));
    // Toolbar
    SvgRegistry.registerIconFromSvg('expand-panel', iconToSvgString(IconExpandSettings));
    SvgRegistry.registerIconFromSvg('collapse-panel', iconToSvgString(IconCollapseSettings));
    SvgRegistry.registerIconFromSvg('undo', iconToSvgString(IconUndo));
    SvgRegistry.registerIconFromSvg('redo', iconToSvgString(IconRedo));
    // Settings
    SvgRegistry.registerIconFromSvg('description', iconToSvgString(IconInfo, 16, 32));
    SvgRegistry.registerIconFromSvg('description-hide', iconToSvgString(IconInfo, 16, 32));
    // Questions
    SvgRegistry.registerIconFromSvg('copy', iconToSvgString(IconCopy));
    SvgRegistry.registerIconFromSvg('delete', iconToSvgString(IconTrash));
    SvgRegistry.registerIconFromSvg('drag-area-indicator_24x16', iconToSvgString(IconDrag));
    SvgRegistry.registerIconFromSvg('duplicate_16x16', iconToSvgString(IconCopy, 16, 32));
    SvgRegistry.registerIconFromSvg('delete_16x16', iconToSvgString(IconTrash, 16, 32));
    SvgRegistry.registerIconFromSvg('required', iconToSvgString(IconAsterisk, 16, 32));
    SvgRegistry.registerIconFromSvg('settings_16x16', iconToSvgString(IconTune));

    // Button icon
    SvgRegistry.registerIconFromSvg('more', iconToSvgString(IconMore, 24, 32, 'fill-white700'));
  }, []);
};

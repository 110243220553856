// @ts-nocheck
/* eslint-disable */

import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';

export interface _ProfileModule {
  id: string;
  status: _ServiceStatusStatus;
  position: number;
  moduleKey: string;
  serviceStatuses: _ServiceStatus[];
}

export interface _ServiceStatus {
  id: string;
  status: _ServiceStatusStatus;
  service: string;
  position: number;
  progress: number;
  available: boolean;
  current_round?: number;
  next_round_ready?: boolean;
  current_round_status:
    | typeof SERVICE_STATUS_NOT_READY
    | typeof SERVICE_STATUS_READY
    | typeof SERVICE_STATUS_STARTED
    | typeof SERVICE_STATUS_FINISHED;
  current_round_progress?: number;
  updatedAt?: string;
  // Dynamically calculated before sending to frontend, helps order by relevance
  relevance?: number;
  // Latest metric
  metric?: _ProfileTrackingStatisticEntry;
  xpData: _ServiceStatusXPData;

  // Dynamically generated frontend
  nextExercise?: number;
}

export interface _ServiceStatusXPData {
  total: number;
  gained: number;
}

export type _ServiceStatusStatus =
  | typeof SERVICE_STATUS_NOT_READY
  | typeof SERVICE_STATUS_READY
  | typeof SERVICE_STATUS_STARTED
  | typeof SERVICE_STATUS_FINISHED;

export const SERVICE_STATUS_NOT_READY = 'not_ready';
export const SERVICE_STATUS_READY = 'ready';
export const SERVICE_STATUS_STARTED = 'started';
export const SERVICE_STATUS_FINISHED = 'finished';

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {
  _TeamDevelopmentSatisfactionPerformance,
  _TeamDevelopmentSatisfactionPerformanceDataEntry,
} from '@modules/Team/types/teamdevelopment.model';

export function useTeamSatisfactionPerformance(data: _TeamDevelopmentSatisfactionPerformance) {
  const [satisfaction, setSatisfaction] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [newGraph, setNewGraph] = useState(false);
  const [satPerfData, setSatPerfData] = useState<_TeamDevelopmentSatisfactionPerformanceDataEntry[]>([]);
  useEffect(() => {
    setData(data);
  }, [data]);

  function setData(data) {
    setSatisfaction({
      data: data?.satisfactionData,
      icon: data?.satisfactionIcon,
      status: data?.performanceStatus,
      diff: data?.satisfactionDiff,
    });

    setPerformance({
      data: data?.performanceData,
      icon: data?.performanceIcon,
      status: data?.performanceStatus,
      diff: data?.performanceDiff,
    });

    setNewGraph(data?.newGraph);
    setSatPerfData(data?.data);
  }

  return {satisfaction, performance, newGraph, satPerfData};
}

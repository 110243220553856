// @ts-nocheck
/* eslint-disable */

import React, {useContext} from 'react';
import {Button} from '../buttons/Button';
import {IconButton} from '../buttons/IconButton';
import {IconArrowLeft, IconArrowRight} from '../Icons/Icons';
import {DatePickerContext, Views} from './DatePickerProvider';
import {startOfYearPeriod} from './utils';

export const ButtonPrevMonth = (): JSX.Element => {
  const {changeCurrentMonth} = useContext(DatePickerContext);
  return <IconButton icon={IconArrowLeft} onClick={() => changeCurrentMonth('prev')} />;
};

export const ButtonSelectMonth = ({allowViewChange = true}: {allowViewChange: boolean}): JSX.Element => {
  const {selectedMonth, selectedYear, view, setView, getFormattedDate} = useContext(DatePickerContext);
  const selectedDate = new Date(selectedYear, selectedMonth - 1);

  const calculateView = (): Views => {
    if (view === 'days') return 'months';
    if (view === 'months') return 'years';
    if (view === 'years') return 'decades';
    return view;
  };

  const content = (
    <div className="flex text-body items-center">
      {view === 'days' && getFormattedDate(selectedDate, {month: 'long', year: 'numeric'})}
      {view === 'months' && getFormattedDate(selectedDate, {year: 'numeric'})}
      {view === 'years' && `${startOfYearPeriod(selectedDate, 10)}-${startOfYearPeriod(selectedDate, 10) + 9}`}
      {view === 'decades' && `${startOfYearPeriod(selectedDate, 100)}-${startOfYearPeriod(selectedDate, 100) + 90}`}
    </div>
  );

  if (allowViewChange) {
    return (
      <Button variant="light" onClick={() => setView(calculateView())}>
        {content}
      </Button>
    );
  }

  return content;
};

export const ButtonNextMonth = (): JSX.Element => {
  const {changeCurrentMonth} = useContext(DatePickerContext);
  return <IconButton icon={IconArrowRight} onClick={() => changeCurrentMonth('next')} />;
};

export const ButtonToday = (): JSX.Element => {
  const {changeCurrentMonth, options} = useContext(DatePickerContext);
  return <Button onClick={() => changeCurrentMonth('today')} label={options?.todayBtnText} />;
};

export const ButtonClear = (): JSX.Element => {
  const {changeSelectedDate, options} = useContext(DatePickerContext);
  return <Button variant="secondary" onClick={() => changeSelectedDate(null)} label={options?.clearBtnText} />;
};

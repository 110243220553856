// @ts-nocheck
/* eslint-disable */

import {useCallback} from 'react';
import {DragEndEvent} from '@dnd-kit/core';
import {_ProfileValueAnimation} from '@modules/ContentEngine/components/items/profile/result/values/ProfileValuesItem';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import {logger} from '@modules/Core/util/Logger';
import {_ProfileValuesEntry} from '@modules/Profile/types/service.model';
import {isGem, isGemBag} from '@modules/Profile/util/profileValuesUtil';
import {trans} from '@modules/Translations/util/i18n';

export const GEM_BAG_ID = 'gem_bag';
export const GEMS_TYPE = 'gems';
export const useProfileValuesDnDActions = (
  availablePoints: number,
  allValues: _ProfileValuesEntry[],
  setAllValues: (values: _ProfileValuesEntry[]) => void,
  profileValues: _ProfileValuesEntry[],
  setProfileValues: (values: _ProfileValuesEntry[]) => void,
  currentAnimations: _ProfileValueAnimation[],
  setCurrentAnimations: (animations: _ProfileValueAnimation[]) => void,
  disabled?: boolean
): [(event: DragEndEvent) => void, (valueId: string) => void] => {
  const dialog = useDialog();

  const createAnimation = (itemId: string): _ProfileValueAnimation => ({
    itemId,
    duration: isGemBag(itemId) ? 3 : 2,
    created_at: new Date().toISOString(),
  });

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    if (disabled) {
      return;
    }
    const newAnimations: _ProfileValueAnimation[] = [];
    // Possible scenarios:
    // 1. Dragging from all to selected or vice versa or in same list -> handled internally in DnDContext, here we just handle
    // cleaning up the state of the moved item, e.g: allValues should have no points
    // 2. Dragging from gem within a selected item to another item -> will be handled here
    // 3. Dragging from gem within a selected item to gem_bag or vice versa -> will be handled here

    // Scenario 1
    if (
      active?.id &&
      over?.id &&
      !isGem(active.id) &&
      !isGem(over?.id) &&
      !isGemBag(active.id) &&
      !isGemBag(over?.id)
    ) {
      // Set all values in allValues points = 0
      setAllValues(allValues.map(v => ({...v, points: 0})));
      // Set points of added value to 1 if in selected values
      const value = profileValues.find(v => v.id === active.id);
      if (value && availablePoints > 0) {
        setProfileValues(values => values.map(v => (v.id === active.id ? {...v, points: 1} : v)));
        newAnimations.push(createAnimation(over.id?.replace(`${GEMS_TYPE}_`, '') ?? ''));
      }
      // If value found in profileValues, show error if no points available
      if (value && availablePoints <= 0 && value.points <= 0) {
        showInsufficientPointsError();
      }
    }
    // Scenario 2
    if (isGem(active.id) && !isGemBag(over?.id)) {
      moveBetweenItems(active.id, over?.id);
      newAnimations.push(createAnimation(over?.id?.replace(`${GEMS_TYPE}_`, '') ?? ''));
      // Sometimes, the drop event happens on the parent component, might not have gem- prefix
      setCurrentAnimations(newAnimations);
      return;
    }

    // Scenario 3.1 - Dragging from gem within a selected item to gem_bag
    if (isGem(active.id) && isGemBag(over?.id)) {
      moveToGemBag(active.id);
      // animate gem bag
      newAnimations.push(createAnimation(over.id));
      // animate gem_bag
      setCurrentAnimations(newAnimations);
      return;
    }

    // Scenario 3.2 - Dragging from gem_bag to gem within a selected item
    if (isGemBag(active.id)) {
      moveFromGemBag(over?.id);
      // animate item
      newAnimations.push(createAnimation(over.id?.replace(`${GEMS_TYPE}_`, '') ?? ''));
      // animate gem_bag
      newAnimations.push(createAnimation(active.id));
    }

    setCurrentAnimations(newAnimations);
  };

  const moveBetweenItems = (fromItem: string, toItem: string): void => {
    const overValueId = toItem?.replace(`${GEMS_TYPE}_`, '');
    const activeValueId = fromItem.replace(`${GEMS_TYPE}_`, '');
    const activeValue = profileValues.find(v => v.id === activeValueId);
    const overValue = profileValues.find(v => v.id === overValueId);
    logger.debug('MOVE BETWEEN ITEMS', {activeValue, overValue});

    if (!activeValue || activeValue.points <= 0) {
      return;
    }

    if (activeValue && overValue && activeValue.id !== overValue.id) {
      setProfileValues(
        profileValues.map(v => {
          if (v.id === activeValueId) {
            return {...v, points: (v.points ?? 0) - 1};
          }
          if (v.id === overValueId) {
            return {...v, points: (v.points ?? 0) + 1};
          }
          return v;
        })
      );
    }
  };

  const moveToGemBag = (fromItem: string): void => {
    const activeValueId = fromItem.replace(`${GEMS_TYPE}_`, '');
    const activeValue = profileValues.find(v => v.id === activeValueId);
    if (!activeValue || (activeValue.points ?? 0) <= 0) {
      return;
    }

    if (activeValue) {
      setProfileValues(profileValues.map(v => (v.id === activeValueId ? {...v, points: (v.points ?? 0) - 1} : v)));
    }
  };

  const moveFromGemBag = (toItem: string): void => {
    if (availablePoints <= 0) {
      showInsufficientPointsError();
      return;
    }
    const overValueId = toItem?.replace(`${GEMS_TYPE}_`, '');
    const overValue = profileValues.find(v => v.id === overValueId);
    if (overValue) {
      setProfileValues(profileValues.map(v => (v.id === overValueId ? {...v, points: (v.points ?? 0) + 1} : v)));
    }
  };

  const onItemGemClick = useCallback(
    (valueId: string) => {
      if (disabled) {
        return;
      }

      if (availablePoints <= 0) {
        showInsufficientPointsError();
      }
      // If there are points available, add 1 to the value
      if (availablePoints > 0) {
        // Value clicked from personalValues, find value, add 1 point
        const value = profileValues.find(v => v.id === valueId);
        if (value) {
          setProfileValues(profileValues.map(v => (v.id === valueId ? {...v, points: (v.points ?? 0) + 1} : v)));
        }
      } else {
        // Show dialog
      }
    },
    [profileValues, availablePoints, disabled]
  );

  function showInsufficientPointsError(): void {
    dialog.show({
      title: trans('personal_values.zero_points.title'),
      children: trans('personal_values.zero_points.description'),
      showCloseButton: true,
      onClose: () => {},
      hideButtons: true,
    });
  }

  return [handleDragEnd, onItemGemClick];
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProfileResultDisclaimer} from '@modules/Profile/components/services/result/common/ProfileResultDisclaimer';
import {ProfileServiceNextRoundReady} from '@modules/Profile/components/services/result/common/ProfileServiceNextRoundReady';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {isServiceChallengesAndGoalsLocked} from '@modules/Profile/util/exercisesUtil';
import {isOldSoftfact} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceNextRoundReadyItemProps {
  serviceStatus: _ServiceStatus;
  profileExercises: _ProfileExerciseCE;
}

export const ProfileOldServiceChallengesGoalsReady: React.FC<_ProfileServiceNextRoundReadyItemProps> = ({
  serviceStatus,
  profileExercises,
}) => {
  // Graph data
  const [available, setAvailable] = useState<boolean>(false);
  const [challengeGoalsReady, setChallengeGoalsReady] = useState<boolean>(false);
  const [nextRoundReady, setNextRoundReady] = useState<boolean>(false);

  useEffect(() => {
    setNextRoundReady(!!serviceStatus?.next_round_ready);
  }, [serviceStatus]);

  useEffect(() => {
    setAvailable(profileExercises?.rounds?.[profileExercises?.currentRound]?.available);
    const currentRound = profileExercises?.currentRound || 0;
    const locked = isServiceChallengesAndGoalsLocked(profileExercises);

    setChallengeGoalsReady(locked && currentRound > 0);
  }, [profileExercises]);

  if (nextRoundReady && isOldSoftfact(serviceStatus?.service)) {
    return <ProfileServiceNextRoundReady serviceStatus={serviceStatus} available={available} />;
  }

  if (!serviceStatus) {
    return null;
  }

  if (!challengeGoalsReady) {
    return null;
  }

  return (
    <ProfileResultDisclaimer
      content={
        available
          ? trans('profile_exercises.next_round_ready_old_softfact', {
              round: serviceStatus.current_round! + 1,
            })
          : trans('profile_exercises.next_round_locked', {
              round: serviceStatus.current_round! + 1,
            })
      }
    />
  );
};

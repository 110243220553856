// @ts-nocheck
/* eslint-disable */

import React, {useMemo} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {_FlowNavigation} from '@modules/FlowEngine/types/core.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';

const SUPPORTED_TYPES: string[] = ['content_engine'];

interface _Props {
  currentStep?: _FlowStep;
  navigation: _FlowNavigation;
}

export const FlowHeaderNavigation: React.FC<_Props> = ({currentStep, navigation}) => {
  const content = useMemo(() => {
    if (!currentStep || !SUPPORTED_TYPES.includes(navigation?.type)) {
      return null;
    }

    const shouldShow = navigation.showCondition?.expression || navigation.showCondition === undefined;

    switch (navigation.type) {
      case 'content_engine':
        if (!navigation.key || !shouldShow) {
          return null;
        }

        // TODO @Stefan Make sure we wait for both navigation.done
        return <ContentEngineContainer type={navigation.key} params={navigation.params} />;

      default:
        return null;
    }
  }, [navigation, currentStep]);

  if (!navigation?.type || !content) {
    return null;
  }

  return <div style={{position: 'relative', zIndex: 1}}>{content}</div>;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Typography} from '../Typography';

interface _Props {
  icon?: React.ReactNode | null;
  title: string;
  contents: React.ReactNode | null;
  action: {title: string | null; trigger: () => void} | null;
  header?: string | React.ReactNode;
  locked?: boolean;
  done?: boolean;
  titleAsElement?: boolean;
}

export const ExercisesCard: React.FC<_Props> = ({
  titleAsElement,
  header,
  done,
  locked,
  title,
  contents,
  action,
  icon,
}) => {
  return (
    <div
      className={addCN(
        'h-full flex p-3 flex-col items-start gap-2 rounded-sm border-1 border-grey300 bg-white500 exercise-card-advanced',
        locked ? 'locked' : done ? 'done' : ''
      )}
    >
      {header && <div className="flex flex-row w-full">{header}</div>}
      <div className="flex flex-row gap-1 w-full items-start">
        {title ? titleAsElement ? title : <span className="font-bold">{title}</span> : null}
        {icon && <div className="flex flex-row flex-grow justify-end">{icon}</div>}
      </div>
      {contents && <span className="flex-grow">{contents}</span>}
      {action && <Typography variant="body">{action?.title}</Typography>}
    </div>
  );
};

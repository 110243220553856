// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

// Can be used for testing stuff out
export const EmptyStep: React.FC<_BaseStepComponentOptions> = ({options}) => (
  <BaseStep options={options}>
    <></>
  </BaseStep>
);

// @ts-nocheck
/* eslint-disable */

import {Model} from 'survey-core';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';

export const onTextMarkdown = (sender, options) => {
  if (options.name === 'title' && options.element.getType() === 'survey') {
    options.html = `<span class="text-h6">${options.text}</span>`;
  }
};

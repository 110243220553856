// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _Divider {
  direction?: 'horizontal' | 'vertical';
  fill?: boolean;
  light?: boolean;
  width?: number;
}

export const Divider: React.FC<_Divider> = ({direction = 'horizontal', fill = true, light = true, width = 168}) =>
  direction === 'horizontal' ? (
    <div
      className={addCN(fill ? 'w-full' : `w-[${width}px]`, 'h-[1px] shrink-0', light ? 'bg-grey500' : 'bg-grey700')}
    />
  ) : (
    <div
      className={addCN(fill ? 'h-full' : `h-[${width}px]`, 'w-[1px] shrink-0', light ? 'bg-grey500' : 'bg-grey700')}
    />
  );

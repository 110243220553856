// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SurveySeparator} from '@modules/SurveyEngine/components/SurveySeparator';
import {SurveyContentType} from '@modules/SurveyEngine/components/types/contentType/SurveyContentType';
import {SurveyButtonType} from '@modules/SurveyEngine/components/types/SurveyButtonType';
import {SurveyMultiSelectType} from '@modules/SurveyEngine/components/types/SurveyMultiSelectType';
import {SurveyMultiSelectDragDropType} from '@modules/SurveyEngine/components/types/SurveyMutliSelectDragDropType/SurveyMultiSelectDragDropType';
import {SurveyRadioRangeType} from '@modules/SurveyEngine/components/types/SurveyRadioRangeType';
import {SurveyRadioType} from '@modules/SurveyEngine/components/types/SurveyRadioType';
import {SurveyRangeDefinitionType} from '@modules/SurveyEngine/components/types/SurveyRangeDefinitionType';
import {SurveyTextareaType} from '@modules/SurveyEngine/components/types/SurveyTextareaType';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';

interface _SurveyQuestionProps {
  survey: any;
  question: _SurveyQuestion | null;
  onAnswer: (value: any) => void;
  onBackClicked: () => void;
  onNextClicked: () => void;
  answers: Record<string, any>;
  parseQuestion: (question: string) => string;
}

export const SurveyQuestion: React.FC<_SurveyQuestionProps> = ({
  survey,
  question,
  onAnswer,
  answers,
  parseQuestion,
  onBackClicked,
  onNextClicked,
}) => {
  switch (question?.type) {
    case 'seperator':
      return <SurveySeparator question={question} />;
    case 'content':
      return <SurveyContentType question={question} />;
    case 'text':
      return <SurveyTextareaType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'textarea':
      return <SurveyTextareaType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'multiselect':
      return <SurveyMultiSelectType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'multiselect_dnd':
      return <SurveyMultiSelectDragDropType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'radio':
      return <SurveyRadioType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'buttons':
    case 'option-buttons':
      return (
        <SurveyButtonType
          survey={survey}
          question={question}
          parseQuestion={parseQuestion}
          onValueChosen={onAnswer}
          answers={answers}
        />
      );
    case 'option-radio':
      return <SurveyRadioType question={question} onValueChosen={onAnswer} answers={answers} />;
    case 'range-definition':
      return <SurveyRangeDefinitionType question={question} onValueChosen={onAnswer} answers={answers} />;
    default:
      return null;
  }
};

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_InstitutionProfile, _Profile} from '@modules/Profile/types/profile.model';
import {useSelectState} from '@modules/State/hooks/selectState';
import {_Team} from '@modules/Team/types/team.model';

export function useInstitution(): {
  currentInstitution?: _Institution;
  profiles: Record<string, _InstitutionProfile>;
  teams: _Team[];
  allTeams: _Team[];
} {
  const currentInstitution = useSelectState(state => state.institution?.currentInstitution) as _Institution;

  const [profiles, setProfiles] = useState<Record<string, _InstitutionProfile>>({});
  const [teams, setTeams] = useState<_Team[]>([]);
  const [allTeams, setAllTeams] = useState<_Team[]>([]);

  useEffect(() => {
    setProfiles(currentInstitution?.profiles || {});
    setTeams(currentInstitution?.teams || []);
    setAllTeams(currentInstitution?.allTeams || []);
  }, [currentInstitution]);

  return {
    currentInstitution,
    profiles,
    teams,
    allTeams,
  };
}

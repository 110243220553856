// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {Section} from '@modules/Core/components/layout/Section';
import {_ProfileMotivesGroup, _ProfileMotivesGroupResult} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';
import {ProfileMotivesGroupCard} from '../../../profile/result/msa/ProfileMotivesGroupCard';
import {_ProfileMotiveTab} from '../../../profile/result/msa/ProfileMotivesItem';
import {TeamMotivesChart} from './TeamMotivesChart';

interface _Props {
  mainMotives: Record<string, any[]>;
  motiveGroups: Record<_ProfileMotivesGroup, _ProfileMotivesGroupResult[]>;
  profileMotives: Record<_ProfileMotivesGroup, Record<string, number>>;
  allProfiles?: string[];
}

const groupsLangKeys: Record<_ProfileMotiveTab, Record<string, string | any>> = {
  future: {
    title: 'motive_structure.future',
    description: 'motive_structure.future_intro',
    tabTitle: 'motive_structure.future_short',
    className: 'border-[#F3C677]', // hover:bg-sunrise100 data-active:border-sunrise500',
    // iconColor: 'fill-sunrise700',
  },
  self: {
    title: 'motive_structure.self',
    description: 'motive_structure.self_intro',
    tabTitle: 'motive_structure.self_short',
    className: 'border-[#F89377]', // hover:bg-blue100 data-active:border-blue500',
    // iconColor: 'fill-blue700',
  },
  morale: {
    title: 'motive_structure.morale',
    description: 'motive_structure.morale_intro',
    tabTitle: 'motive_structure.morale_short',
    className: 'border-[#7B1E7A]', // hover:bg-green100 data-active:border-green500',
    // iconColor: 'fill-green700',
  },
  social: {
    title: 'motive_structure.social',
    description: 'motive_structure.social_intro',
    tabTitle: 'motive_structure.social_short',
    className: 'border-[#A8DADC]', // hover:bg-teal100 data-active:border-teal500',
    // iconColor: 'fill-teal700',
  },
};

const motivesOrder = ['future', 'self', 'morale', 'social'];
const motiveGroupsOrder = [
  'career',
  'development',
  'selfrealization',
  'selfoptimization',
  'fun',
  'power',
  'achievement',
  'independance',
  'fairness',
  'idealism',
  'responsibility',
  'goalinternalization',
  'status',
  'security',
  'belonging',
  'acceptance',
];
export const TeamMotivesItem: React.FC<_Props> = ({profileMotives, mainMotives, motiveGroups, allProfiles = []}) => {
  const [activeGroup, setActiveGroup] = useState<_ProfileMotiveTab>('future');
  const [graphData, setGraphData] = useState<number[]>([]);

  useEffect(() => {
    const data = [];
    if (motiveGroups) {
      const sortedMotiveGroups = Object.values(motiveGroups)
        .flat()
        .sort((a, b) => motiveGroupsOrder.indexOf(a.title) - motiveGroupsOrder.indexOf(b.title));
      for (const motiveGroup of sortedMotiveGroups) {
        // we use the chart.js chart just for the layout, so we push 0 for each group
        data.push(0);
      }
    }

    setGraphData(data);
  }, [motiveGroups]);

  return (
    <Section>
      <Section
        className="max-w-full max-h-full"
        title={trans('motive_structure.motive_overview')}
        description={trans('team_modules.services.motive_structure.overview_intro')}
        descriptionVariant="body1"
      >
        <TeamMotivesChart
          width={1000}
          height={800}
          data={graphData}
          mainMotives={mainMotives}
          profileMotives={profileMotives}
          teamProfiles={allProfiles}
        />
      </Section>
      <Tabs
        tabs={Object.keys(groupsLangKeys)
          .sort((a, b) => motivesOrder.indexOf(a as _ProfileMotiveTab) - motivesOrder.indexOf(b as _ProfileMotiveTab))
          .map(key => ({
            key,
            displayValue: trans(groupsLangKeys[key as _ProfileMotiveTab].tabTitle),
            iconColor: groupsLangKeys[key as _ProfileMotiveTab].iconColor,
            className: groupsLangKeys[key as _ProfileMotiveTab].className,
          }))}
        activeTab={activeGroup}
        setActiveTab={(group: string) => setActiveGroup(group as _ProfileMotiveTab)}
      />

      {activeGroup && (
        <>
          <Section
            title={trans(groupsLangKeys[activeGroup].title)}
            description={trans(groupsLangKeys[activeGroup].description)}
            descriptionVariant="body1"
          />
          <div className="flex flex-row w-full gap-3 flex-wrap">
            {(motiveGroups[activeGroup as _ProfileMotivesGroup] || [])
              .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0))
              .map((group: _ProfileMotivesGroupResult) => (
                <div key={group.title} className="flex flex-col relative-col-width-5">
                  <ProfileMotivesGroupCard
                    isMain={false}
                    mainView
                    isTeam
                    profiles={mainMotives[`${group.group}.${group.title}`]}
                    motivesGroup={group}
                    profileMotives={profileMotives}
                    teamProfiles={allProfiles}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      {/*
      <pre>{JSON.stringify(profileMotives, null, 2)}</pre>
      <pre>{JSON.stringify(mainMotives, null, 2)}</pre>
      */}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {GraphItem} from '@modules/ContentEngine/components/items/GraphItem';
import {_ProfileTrackingMetricValueEntry} from '@modules/ContentEngine/types/metrics.model';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {createMultiSolidGaugeChartData} from '@modules/Core/util/util/graphUtil';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {_GraphData} from '@modules/Profile/components/services/result/common/ProfileResultGraph';
import {OrganisationTeamsOverviewCard} from '@modules/Statistics/components/institution/OrganisationTeamsOverviewCard';
import {EXPERIENCE_GAIN_METRIC} from '@modules/Statistics/config/constants';
import {supportedMetricsOrdered} from '@modules/Team/components/dashboard/cards/TeamIndividualPerformanceCard';
import {statisticsColors, statisticTitleLangProps} from '@modules/Team/config/statisticsConstants';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  team?: _InstitutionOverviewTeam;
}

export const OrganisationIndividualPerformanceCard: React.FC<_Props> = ({team}) => {
  const graphType = 'SolidGaugeMulti';
  const [graphData, setGraphData] = useState<_GraphData[][]>([]);

  const [experienceGainedMetric, setExperienceGainedMetric] = useState<_ProfileTrackingMetricValueEntry | null>(null);

  useEffect(() => {
    setExperienceGainedMetric(team?.metrics?.[EXPERIENCE_GAIN_METRIC] ?? null);
  }, [team]);
  useEffect(() => {
    let maxValue = 0;
    const metricsData =
      supportedMetricsOrdered.map(supportedMetricName => {
        const metricName = supportedMetricName;
        const metric = team?.metrics[metricName] ?? {};
        const value = metric?.sum ?? 0;
        if (value > maxValue) {
          maxValue = value;
        }
        return {
          category: trans(statisticTitleLangProps[metricName]),
          value,
        };
      }) ?? [];
    setGraphData(createMultiSolidGaugeChartData(metricsData, maxValue));
  }, [team]);
  if (!team) {
    return null;
  }

  return (
    <div className="flex flex-row gap-3 items-center w-full h-full">
      <OrganisationTeamsOverviewCard
        supportedMetrics={supportedMetricsOrdered}
        className="relative-col-width-3"
        team={team}
        view="expanded"
      />
      <div className="flex flex-row items-center justify-center relative-col-width-4 relative-col-height-4">
        <GraphItem
          type={graphType}
          chartOptions={{
            colors: statisticsColors,
            text: experienceGainedMetric?.sum ?? '0',
            image: getStatisticImage('experiencelogo_2x'),
            hideLabels: true,
            hideTooltips: true,
          }}
          data={graphData ?? []}
          withWrapper={false}
        />
      </div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {convertKey} from '@modules/SurveyEngine/util/questionUtil';

interface _SurveyTextareaTypeProps {
  question: any;
  onValueChosen: (question: any, value: any) => void;
  answers: any;
}

export const SurveyTextareaType: React.FC<_SurveyTextareaTypeProps> = ({question, onValueChosen, answers}) => (
  <div className="mx-auto survey-textarea w-full">
    <TextArea
      dusk="survey-textarea-input"
      label={question?.label}
      name={question?.key}
      type="textarea"
      placeholder={question?.placeholder}
      className="block w-100"
      cols={120}
      rows={8}
      onChange={e => onValueChosen(question, e.target.value)}
      value={answers[convertKey(question?.key)] || question?.default || ''}
    />
  </div>
);

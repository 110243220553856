// @ts-nocheck
/* eslint-disable */

import React, {useMemo, useState} from 'react';
import {
  DndContext as DnDKitContext,
  DndContextProps,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';
import {createPortal} from 'react-dom';
import {logger} from '@modules/Core/util/Logger';

/**
 * A wrapper, in case we need to change the library in the future
 * @constructor
 * @param props
 */
export interface _AppDndContextProps extends DndContextProps {
  onDragEnd?: (event: DragEndEvent) => void;
  items?: _AppDnDListWrapper[];
  lists?: _AppDnDListWrapper[];
  setLists?: (lists: _AppDnDListWrapper[]) => void;
  renderOverlay?: (active: any, activeList?: string | null) => React.ReactNode;
  itemIdSelector?: (item: any) => string;
  options?: any;
}

export interface _AppDnDItem {
  id: string;
  data: any;
}

export interface _AppDnDListWrapper {
  id: string;
  items: _AppDnDItem[];
  setItems: (items: _AppDnDItem[]) => void;
}

export interface _AppDnDEvent extends DragEvent {
  source?: {
    id: string;
    items: any[];
  };

  over?: {
    id: string;
    items: any[];
  };
}

export const DnDContext: React.FC<_AppDndContextProps> = ({
  onDragStart: onDragStartListener,
  onDragEnd: onDragEndListener,
  onDragOver: onDragOverListener,
  renderOverlay,
  itemIdSelector = (item: any) => item.id,
  options,
  ...props
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(
      PointerSensor,
      // Crucial, otherwise, click Events from whatever children injected will not work
      // https://github.com/clauderic/dnd-kit/issues/800
      options
    )
  );
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [activeListId, setActiveListId] = useState<string | null>(null);

  const findItemList = (itemId: string): string | null => {
    if (!props.lists) {
      return null;
    }
    // If list is empty, itemId will actually be the list id, hence the 2nd check
    return (
      props.lists.find(list => list.items.find(item => itemIdSelector(item) === itemId))?.id ??
      props.lists.find(list => list.id === itemId)?.id ??
      null
    );
  };

  const moveItemsInLists = (activeListId: string, overListId: string, activeItemId: string, overItemId: string) => {};

  const handleDragStart = ({active}: DragStartEvent) => {
    setActiveItemId(active.id as string);
    setActiveListId(findItemList(active.id as string));
    onDragStartListener?.(active);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const {active, over} = event;
    onDragOverListener?.({active, over});

    const activeList = findItemList(activeItemId);
    const overList = findItemList(over?.id);

    if (!activeList || !overList || activeList === overList) {
      return;
    }

    const activeItems = props.lists?.find(list => list.id === activeList)?.items;
    const overItems = props.lists?.find(list => list.id === overList)?.items;

    if (!activeItems || !overItems) {
      return;
    }

    const activeIndex = activeItems.findIndex(item => item.id === activeItemId);
    const overIndex = overItems.findIndex(item => item.id === over.id);

    const hasSetItems =
      props.lists?.find(list => list.id === activeList)?.setItems &&
      props.lists?.find(list => list.id === overList)?.setItems;
    const hasSetLists = props.setLists;
    if (!hasSetItems && hasSetLists) {
      // Peform same logic, with setLists((oldLists) => ..logic..)
      props.setLists(prevLists => {
        const activeListIndex = prevLists.findIndex(list => list.id === activeList);
        const overListIndex = prevLists.findIndex(list => list.id === overList);

        if (activeListIndex === -1 || overListIndex === -1) {
          return prevLists;
        }

        const activeItemIndex = prevLists[activeListIndex].items.findIndex(item => item.id === activeItemId);
        const overItemIndex = prevLists[overListIndex].items.findIndex(item => item.id === over.id);

        const newActiveItems = prevLists[activeListIndex].items.filter(item => item.id !== activeItemId);
        const newOverItems = [
          ...prevLists[overListIndex].items.slice(0, overItemIndex),
          prevLists[activeListIndex].items[activeItemIndex],
          ...prevLists[overListIndex].items.slice(overItemIndex, prevLists[overListIndex].items.length),
        ];

        const newLists = [...prevLists];
        newLists[activeListIndex] = {
          ...prevLists[activeListIndex],
          items: newActiveItems,
        };
        newLists[overListIndex] = {
          ...prevLists[overListIndex],
          items: newOverItems,
        };

        return newLists;
      });
    } else if (hasSetItems) {
      const newActiveItems = activeItems.filter(item => item.id !== activeItemId);
      const newOverItems = [
        ...overItems.slice(0, overIndex),
        activeItems[activeIndex],
        ...overItems.slice(overIndex, overItems.length),
      ];

      props.lists?.find(list => list.id === activeList)?.setItems(newActiveItems);
      props.lists?.find(list => list.id === overList)?.setItems(newOverItems);
    }
  };

  const handleDragEnd = (event: _AppDnDEvent) => {
    const activeList = findItemList(activeItemId);
    const overList = findItemList(event.over?.id!);

    if (!activeList || !overList || activeList !== overList) {
      onDragEndListener?.(event);
      return;
    }

    const activeItems = props.lists?.find(list => list.id === activeList)?.items;
    const overItems = props.lists?.find(list => list.id === overList)?.items;
    if (!activeItems || !overItems) {
      onDragEndListener?.(event);
      return;
    }

    const activeIndex = activeItems.findIndex(item => item.id === activeItemId);
    const overIndex = overItems.findIndex(item => item.id === event.over?.id);

    if (activeIndex !== overIndex) {
      const hasSetItems = props.lists?.find(list => list.id === activeList)?.setItems;
      const newOverItems = arrayMove(overItems, activeIndex, overIndex);

      if (hasSetItems) {
        props.lists?.find(list => list.id === overList)?.setItems(newOverItems);
      }
    }

    setActiveItemId(null);
    setActiveListId(null);
    onDragEndListener?.(event);
  };

  const overlay = useMemo(() => {
    if (!activeItemId && !activeListId) {
      return null;
    }
    const content = renderOverlay?.(activeItemId, activeListId);
    // render content with DragOverlay ONLY if content is not null, otherwise let dnd-kit handle it by
    // rendering the default overlay

    if (content) {
      return createPortal(<DragOverlay>{content}</DragOverlay>, document.body);
    }
  }, [activeItemId, activeListId]);

  return (
    <DnDKitContext
      {...props}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      // onDragCancel={onDragCancel}
      sensors={sensors}
    >
      {props.children}
      {overlay}
    </DnDKitContext>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';

interface _TextItemProps {
  text: string;
  variant: _TypographyVariants;
  asHtml?: boolean;
}

export const TextItem: React.FC<_TextItemProps> = ({text, variant, asHtml}) => {
  if (asHtml) {
    return <Typography variant={variant} dangerouslySetInnerHTML={{__html: text}} />;
  }
  return <Typography variant={variant}>{text}</Typography>;
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {Slider} from '@modules/Core/components/base/Slider';
import {Typography} from '@modules/Core/components/base/Typography';
import {wayOfWorkingLangProps} from '@modules/Profile/config/servicesLangProps';
import {TeamWayOfWorkingDialog} from '@modules/Team/components/team_analysis/way_of_working/TeamWayOfWorkingDialog';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamWayOfWorkingResult} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

export type _DimensionType = 'leading' | 'deciding' | 'disagreeing' | 'planning';

interface _Props {
  result: Record<_DimensionType, _TeamWayOfWorkingResult>;
  allProfiles: Record<string, _TeamProfile>;
}

interface _DialogData {
  criteriaKey: _DimensionType;
  result: _TeamWayOfWorkingResult;
  activeTab?: 'actual' | 'wanted';
  transparencyAccepted?: boolean;
}

const order = ['leading', 'deciding', 'disagreeing', 'planning'];

const MAX_SCORE = 10;

export const TeamWayOfWorkingDimensionsItem: React.FC<_Props> = ({transparencyAccepted, allProfiles, result}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<_DialogData | null>(null);
  const [resultData, setResultData] = useState<_TeamWayOfWorkingResult | null>(null);

  useEffect(() => {
    setResultData(result);
  }, [result]);

  const onPrepareDialog = (key: string, dialogData: _DialogData) => {
    setDialogData(dialogData);
    setDialogOpen(true);
  };

  return (
    <>
      <TeamWayOfWorkingDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        criteriaKey={dialogData?.criteriaKey}
        result={dialogData?.result}
        allProfiles={allProfiles}
        activeTab={dialogData?.activeTab}
        transparencyAccepted={transparencyAccepted}
      />
      {Object.keys(resultData ?? {})
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map((key, index) => {
          const currentKeyResult = result[key as _DimensionType];
          const actualAverage = currentKeyResult.actualValues.average;
          const wantedAverage = currentKeyResult.wantedValues.average;
          // TODO @Sherif move to dict
          const facetKey = actualAverage >= MAX_SCORE / 2 ? 'second_facet' : 'first_facet';
          const reflection = (
            <ul>
              <li>{trans(`way_of_working.${key}.${facetKey}.recommendation_team.0`)}</li>
              <li>{trans(`way_of_working.${key}.${facetKey}.recommendation_team.1`)}</li>
              <li>{trans(`way_of_working.${key}.${facetKey}.recommendation_team.2`)}</li>
            </ul>
          );

          const description = (
            <div className="flex flex-col items-start justify-center gap-2">
              <Typography>
                {actualAverage >= MAX_SCORE / 2
                  ? trans(wayOfWorkingLangProps[key].second_facet_description)
                  : trans(wayOfWorkingLangProps[key].first_facet_description)}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: trans('way_of_working.description'),
                }}
              />
            </div>
          );

          return (
            <Slider
              key={key}
              title={trans(wayOfWorkingLangProps[key].title)}
              leftText={trans(wayOfWorkingLangProps[key].first_facet_label)}
              rightText={trans(wayOfWorkingLangProps[key].second_facet_label)}
              leftTooltip={trans(wayOfWorkingLangProps[key].first_facet_tooltip)}
              rightTooltip={trans(wayOfWorkingLangProps[key].second_facet_tooltip)}
              leftIcon={IconInfo}
              rightIcon={IconInfo}
              bottomSubtitle={reflection}
              bottomTitle={trans('way_of_working.team_recommendation')}
              subtitle={description}
              enableEndicator
              onIndicatorClick={id =>
                onPrepareDialog(key, {
                  criteriaKey: key,
                  result: currentKeyResult,
                  activeTab: id,
                })
              }
              onTitleActionClick={() => onPrepareDialog(key, {criteriaKey: key, result: currentKeyResult})}
              components={[
                {
                  id: 'actual',
                  progress: (actualAverage / MAX_SCORE) * 100,
                  component: <div />,
                  indicatorColor: 'bg-black700',
                },
                {
                  id: 'wanted',
                  progress: (wantedAverage / MAX_SCORE) * 100,
                  component: <div />,
                  indicatorColor: ' bg-green700',
                },
              ]}
              showExpand
            />
          );
        })}
    </>
  );
};

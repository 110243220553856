// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import LottieWrapper from '@modules/Core/components/wrappers/LottieWrapper';

interface _Props {
  title: string;
  description: string;
  image: string;
}

export const GamificationAlertLevelUpViewCard: React.FC<_Props> = ({title, description, image}) => {
  return (
    <Section
      containerGap={2}
      title={title}
      image={image}
      imageComponent={
        <div className="min-w-[400px] min-h-[400px]">
          <LottieWrapper
            lottieKey={image}
            style={{
              height: '400px',
              width: '400px',
            }}
          />
        </div>
      }
      imageViewDirection="vertical"
      justifyContent="center"
      imageHeight="h-20"
    >
      <div className="flex flex-row items-center relative-col-width-4 justify-center">
        <Typography breakLines breakWords className="text-center">
          {description}
        </Typography>
      </div>
    </Section>
  );
};

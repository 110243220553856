// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {sessionConfig} from '@app/_core/util/util';
import {getCountryList} from '@modules/Core/services/api/country';
import {loadFeatureFlags} from '@modules/Core/services/api/featureFlagsApi';
import {setFeatureFlags} from '@modules/Core/state/featureFlagsSlice';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {getInstitutionData} from '@modules/Institution/services/InstitutionApi';
import {setCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {PROFILE_RELOADED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';
import {getProfile, trackProfile} from '@modules/Profile/services/profileApi';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {getCurrentTeams} from '@modules/Team/services/teamsApi';
import {setCurrentTeams} from '@modules/Team/state/TeamsSlice';
import {loadExercisesJSON} from '../services/exercisesApi';
import {getAllCategories} from '../services/journalsApi';
import {setExercises} from './exercisesSlice';

export interface _ProfileState {
  currentProfile: _Profile | null;
}

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    currentProfile: null,
  } satisfies _ProfileState,
  reducers: {
    setCurrentProfile(state: _ProfileState, action) {
      logger.info('[ProfileSlice] Saving profile.');

      state.currentProfile = action.payload;
    },
    resetCurrentProfile(state: _ProfileState, action) {
      logger.info('[ProfileSlice] Resetting profile.');

      state.currentProfile = null;
    },
  },
});

export const {setCurrentProfile, resetCurrentProfile} = profileSlice.actions;

export default profileSlice;

async function fetchData(dispatch: any, loadingIndicator: boolean): Promise<void> {
  const [profileResponse, teamsResponse, institutionsResponse, exercisesResponse, featureFlagsResponse] =
    await Promise.all([
      getProfile(loadingIndicator),
      getCurrentTeams(loadingIndicator),
      getInstitutionData(loadingIndicator),
      loadExercisesJSON(loadingIndicator, currentProfile()?.id),
      loadFeatureFlags(loadingIndicator),
    ]);

  if (profileResponse?.data?.profile && profileResponse.status === 200) {
    void trackProfile(profileResponse.data.profile);
    dispatch(setCurrentProfile(profileResponse.data.profile));
  }
  if (teamsResponse?.data && teamsResponse.status === 200) {
    dispatch(setCurrentTeams(teamsResponse.data));
  }
  if (exercisesResponse?.data && exercisesResponse.status === 200) {
    dispatch(setExercises(exercisesResponse.data));
  }
  if (institutionsResponse?.data && institutionsResponse.status === 200) {
    dispatch(setCurrentInstitution(institutionsResponse?.data));
  }
  if (featureFlagsResponse?.data && featureFlagsResponse.status === 200) {
    dispatch(setFeatureFlags(featureFlagsResponse.data));
  }
  // Pre-fetch some static data
  void getAllCategories();
  void getCountryList();
}

export function fetchProfile(loadingIndicator: boolean = true) {
  return async (dispatch: any) => {
    const authToken = sessionConfig('authToken');
    logger.info('[ProfileSlice] Fetching profile.', !!authToken);
    try {
      if (authToken) {
        await fetchData(dispatch, loadingIndicator);
        dispatchReactEvent('reload.step');
        dispatchReactEvent(PROFILE_RELOADED_APP_EVENT);
      }
    } catch (e) {
      logger.error('[ProfileSlice] Error fetching profile.', e);
    }
  };
}

export function updateProfile(profile: _Profile) {
  return async (dispatch: any) => {
    try {
      dispatch(setCurrentProfile(profile));
    } catch (e) {
      logger.error('[ProfileSlice] Error updating profile.', e);
    }
  };
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {addCN} from '@/_core/util/cssHelper';
import {IconArrowDownward, IconArrowUpward} from '@modules/Core/components/base/Icons/Icons';
import {_TableColumns, _TableData} from '@modules/Core/components/base/table/Table';
import {Pagination} from './Pagination';

interface _ReactTable {
  data: _TableData;
  columns: _TableColumns;
  withHeaders?: boolean;
  showTopBorder?: boolean;
  hideBorders?: boolean;
}

const LIMIT = 50;
export const ReactTable: React.FC<_ReactTable> = ({hideBorders, showTopBorder, data, columns, withHeaders = true}) => {
  const [sorting, setSorting] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: data?.length <= LIMIT,
    initialState: {
      pagination: {
        pageSize: LIMIT,
        pageIndex: 0,
      },
    },
    autoResetPageIndex: false,
  });

  const showPagination = table?.getPageCount() > 1;

  return (
    <div className="flex flex-col gap-5">
      <div>
        <table cellPadding="0" className="w-full">
          {withHeaders && (
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const index = header?.index;
                    const currentColumn = columns[index];
                    const width = currentColumn?.width;

                    return (
                      <th
                        className={addCN(
                          typeof width === 'string' ? width : '',
                          header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          showTopBorder ? 'border-t-1 pt-1 border-grey500' : '',
                          !hideBorders && 'border-b-1'
                        )}
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <div className="flex items-center justify-start">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <IconArrowUpward />,
                              desc: <IconArrowDownward />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {Boolean(showPagination) && <Pagination table={table} />}
    </div>
  );
};

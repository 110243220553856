// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  open: boolean;
  onClose: () => void;
  onItemCreated: (name: string, description: string) => void;
}

export const NewProfileValuesDialog: React.FC<_Props> = ({open, onClose, onItemCreated}) => {
  const [newValueName, setNewValueName] = useState<string | null>(null);
  const [newValueDescription, setNewValueDescription] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!newValueName || !newValueDescription) {
      return;
    }
    onItemCreated?.(newValueName, newValueDescription);
    onClose();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        open={open}
        onClose={onClose}
        cancelLabel={trans('personal_values.create_new_value.cancel_text')}
        confirmLabel={trans('personal_values.create_new_value.confirm_text')}
        confirmProps={{
          type: 'submit',
        }}
        onConfirm={() => {}}
        title={trans('personal_values.create_new_value.title')}
        showCloseButton
      >
        <Typography>{trans('personal_values.create_new_value.description')}</Typography>

        <Input
          type="text"
          placeholder={trans('personal_values.create_new_value.name_placeholder')}
          label={trans('personal_values.create_new_value.name_label')}
          name="newValueName"
          value={newValueName}
          onChange={val => setNewValueName(val)}
          required
        />

        <TextArea
          rows={8}
          placeholder={trans('personal_values.create_new_value.description_placeholder')}
          label={trans('personal_values.create_new_value.description_label')}
          value={newValueDescription}
          onChange={e => setNewValueDescription(e.target.value)}
          required
        />
      </Dialog>
    </form>
  );
};

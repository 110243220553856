// @ts-nocheck
/* eslint-disable */

export const MOTIVE_STRUCTURE_FLOW = 'motive-structure-analysis';
export const PAYMENT_FLOW = 'payment';
export const PERSONALITY_ANALYSIS_FLOW = 'personality-analysis';
export const ROLES_FLOW = 'roles';
export const TEAM_ANALYSIS_FLOW = 'team-analysis';
export const TEAM_DEVELOPMENT_FLOW = 'team-development';
export const THIRD_PERSON_FEEDBACK_FLOW = 'third-person-feedback';
export const VALUES_FLOW = 'values';
export const WAY_OF_WORKING_FLOW = 'way-of-working';
export const PROFILE_EXERCISES_FLOW = 'profile-exercises';

// New Soft Facts
export const INITIAL_SOFTFACTS_ANALYSIS_FLOW = 'initial-softfacts-analysis';

export const PROFILE_MOTIVATION_FLOW = 'profile-motivation';
export const PROFILE_COMMUNICATION_FLOW = 'profile-communication';
export const PROFILE_TRUST_FLOW = 'profile-trust';
export const PROFILE_RESPONSIBILITY_FLOW = 'profile-responsibility';
export const PROFILE_PROACTIVITY_FLOW = 'profile-proactivity';
export const PROFILE_SELF_EFFICACY_FLOW = 'profile-self-efficacy';
export const PROFILE_RESILIENCE_FLOW = 'profile-resilience';
export const PROFILE_MINDFULNESS_FLOW = 'profile-mindfulness';
export const PROFILE_LEARNING_FLOW = 'profile-learning';
export const PROFILE_EMOTIONAL_INTELLIGENCE_FLOW = 'profile-emotional-intelligence';

export const PROFILE_SATISFACTION_AND_PERFORMANCE_FLOW = 'profile-satisfaction-and-performance';

/**
 * New flow names structure -> not kebab case
 */

export const FLOWS: Record<string, string> = {
  ProfileExercises: 'ProfileExercises',
  ProfileDashboard: 'ProfileDashboard',
  ProfileJournals: 'ProfileJournals',
  ProfileSharedResults: 'ProfileSharedResults',
  ProfileSettings: 'ProfileSettings',
  ProfileInfo: 'ProfileInfo',
  ConfirmEmailChange: 'ConfirmEmailChange',
  ThirdPersonFeedback: 'ThirdPersonFeedback',
  InitialSoftfactsAnalysis: 'InitialSoftfactsAnalysis',
  SwitchOrganisation: 'SwitchOrganisation',
  ShareResult: 'ShareResult',
  Softfactionary: 'Softfactionary',
  Softfacts: 'Softfacts',
  Logout: 'Logout',
  Login: 'Login',
  TeamDevelopment: 'TeamDevelopment',
  TeamAnalysis: 'TeamAnalysis',
  TeamAnalysisTransparency: 'TeamAnalysisTransparency',
  TeamMembers: 'TeamMembers',
  NewTeam: 'NewTeam',
  NewOrganisation: 'NewOrganisation',
  InviteTeam: 'InviteTeam',
  LeaveTeam: 'LeaveTeam',
  Payment: 'Payment',
  OrganisationDashboard: 'OrganisationDashboard',
  OrganisationStructure: 'OrganisationStructure',
  OrganisationMembers: 'OrganisationMembers',
  SoftwareUpdate: 'SoftwareUpdate',
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Card} from '@modules/Core/components/base/cards/Card';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {_InstitutionTeamPerformer} from '@modules/Institution/types/institutionstatistics.model';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {_PerformerKey} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  performer: _InstitutionTeamPerformer;
  performerKey: _PerformerKey;
}

export const TopPerformerTeamCard: React.FC<_Props> = ({performer, performerKey}) => {
  const {metrics} = performer ?? ({} as Record<string, _ProfileTrackingStatisticEntry>);
  const {trend} = metrics?.userActivity ?? {};

  if (!performer.name) {
    return null;
  }
  logger.debug('[TeamTest]OrgIndividualPerformanceCard', {performer, performerKey});

  return (
    <Card>
      <div className="flex flex-row justify-between w-full">
        <Typography variant="body" bold>
          {performer.name}
        </Typography>
        {!!trend && <StatisticLabelBadge change={trend} changeType={trend > 0 ? '+' : trend < 0 ? '-' : 'N'} />}
      </div>

      <div className="flex flex-row items-center w-full gap-1">
        <TeamProfileAvatarList
          profiles={Object.keys(performer.profiles ?? {})}
          teamProfiles={performer.profiles ?? {}}
        />

        <Typography variant="body2">
          {trans('organisation.employee_count', {
            count: Object.keys(performer.profiles ?? {})?.length,
          })}
        </Typography>
      </div>
    </Card>
  );
};

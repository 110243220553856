// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Typography} from '@modules/Core/components/base/Typography';
import {Group} from '@modules/Core/components/layout/Group';
import {Section} from '@modules/Core/components/layout/Section';
import {
  _TeamDevelopmentProfileAnswers,
  _TeamDevelopmentProfileAnswersEntry,
} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

const langProps: Record<'motivation' | 'inspiration' | 'individual', string> = {
  motivation: 'development_result.motivation_flow',
  inspiration: 'development_result.module_overview.inspiration',
  individual: 'development_result.your_individual_tools',
};

interface _Props {
  answers: _TeamDevelopmentProfileAnswers;
  module: string;
  teamId: string;
  allProfiles: Record<string, _TeamDevelopmentProfileAnswers>;
  context: _ContentEngineContext;
}

export const TeamDevelopmentResultProfileAnswers: React.FC<_Props> = ({context, teamId, module, answers}) => {
  return (
    <Group>
      <TeamSectionItem
        notificationType="TD"
        context={context}
        title={trans('development_result.answers')}
        teamId={teamId}
        journalKey={`team_development.${module}`}
      />
      <AnswerSection type="inspiration" answers={answers.inspiration} module={module} />
      <AnswerSection type="motivation" answers={answers.motivation} module={module} />
      <AnswerSection type="individual" answers={answers.individual} module={module} />
    </Group>
  );
};

export const AnswerSection: React.FC<{
  module: string;
  answers: _TeamDevelopmentProfileAnswersEntry[];
  type: 'motivation' | 'inspiration' | 'individual';
}> = ({module, type, answers}) => {
  return (
    <Section
      title={trans(langProps[type], {
        moduleName: trans(`profile.${module}`),
        module: trans(`profile.${module}`),
      })}
      description={!answers?.length ? trans('development_result.no_answers_available') : null}
    >
      {answers.map(answer => (
        <>
          <Typography bold>{answer.question}</Typography>
          <Typography breakLines>{answer.answers}</Typography>
        </>
      ))}
    </Section>
  );
};

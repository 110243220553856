// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Divider} from '@modules/Core/components/base/Divider';
import {IconPaymentSettings} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {formatDate} from '@modules/Core/util/dates';
import {formatMoney} from '@modules/Core/util/locale';
import {_Institution} from '@modules/Institution/types/institution.model';
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_TO_BE_CANCELLED,
} from '@modules/Profile/config/membershipsConstants';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {isFreeSubscription} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _BillingInformationProps {
  institution: _Institution | null;
  onGoToSubscriptions: (sub?: _Subscription | null) => void;
}

export const BillingInformation: React.FC<_BillingInformationProps> = ({institution, onGoToSubscriptions}) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [licenses, setLicenses] = useState({});
  const [unassignedSubscriptions, setUnassignedSubscriptions] = useState([]);

  useEffect(() => {
    createData();
  }, [institution]);

  function createData() {
    if (!institution) {
      setSubscriptions([]);
      setLicenses({});
      return;
    }

    const licensesMap = Object.values(institution?.licenses || {}).reduce((map, license) => {
      map[license?.plan_id] = (map[license?.plan_id] || 0) + 1;
      return map;
    }, {});

    setLicenses(licensesMap);

    setSubscriptions(
      Object.values(institution?.subscriptions || {}).filter(
        sub => !isFreeSubscription(sub) && sub.profileIds?.length > 0 && !!sub.expiresAtMap
      )
    );

    setUnassignedSubscriptions(
      Object.values(institution?.subscriptions || {})
        .filter(sub => !isFreeSubscription(sub) && sub.details && sub?.status === SUBSCRIPTION_STATUS_ACTIVE)
        .filter(sub => {
          // Has to noe be set to be cancelled
          let hasActiveProfile = false;
          Object.values(sub?.details || {})?.[0].forEach(detail => {
            if (detail?.profile_sub_status !== SUBSCRIPTION_STATUS_TO_BE_CANCELLED) {
              hasActiveProfile = true;
            }
          });

          return hasActiveProfile;
        })
    );
  }

  function licensesHtml() {
    const elements = [];
    for (const license of Object.keys(licenses || {})) {
      elements.push(
        <div key="licenses" className="flex flex-row items-center justify-between gap-0 w-full">
          <Typography
            dangerouslySetInnerHTML={{
              __html: trans('institution.billing.license_overview', {
                count: licenses[license],
              }),
            }}
          />
          <ToolTip txt={trans('subscription.change_plan_tooltip')}>
            <IconButton onClick={() => onGoToSubscriptions?.(null)} icon={IconPaymentSettings} />
          </ToolTip>
        </div>
      );
    }

    return elements;
  }

  function unassignedSubscriptionHtml(sub, index) {
    const elements = [];

    const singlePrice = sub.price;

    for (const expirationDate of Object.keys(sub?.details || {})) {
      const number = sub?.details[expirationDate]?.length || 0;
      let amount = formatMoney(singlePrice * number);

      if (sub.type === 'yearly') {
        amount = formatMoney(singlePrice * number * 12);
      }

      const single = number === 1;
      elements.push(
        <div className="flex flex-row items-center justify-between gap-0 w-full">
          <Typography
            dangerouslySetInnerHTML={{
              __html: trans(
                single
                  ? 'institution.billing.unassigned_subscription.single'
                  : 'institution.billing.unassigned_subscription.plural',
                {
                  number,
                  subscription: trans(`subscription.plans.${sub.plan_id}.title`),
                  type: trans(`subscription.types.${sub.type}`),
                  dueDate: formatDate(new Date(expirationDate)),
                  amount,
                }
              ),
            }}
          />
          <ToolTip txt={trans('subscription.change_plan_tooltip')}>
            <IconButton onClick={() => onGoToSubscriptions?.(sub)} icon={IconPaymentSettings} />
          </ToolTip>
        </div>
      );
    }

    return elements;
  }

  function subscriptionHtml(sub, index) {
    const elements = [];

    // Object.keys(sub?.expiresAtMap || {})

    const singlePrice = sub.totalPrice / (sub.profileIds || []).length;

    for (const expirationDate of Object.keys(sub?.expiresAtMap || {})) {
      const count = sub?.expiresAtMap[expirationDate]?.length || 0;
      let amount = formatMoney(singlePrice * count);

      if (sub.type === 'yearly') {
        amount = formatMoney(singlePrice * count * 12);
      }

      elements.push(
        <div className="flex flex-row items-center justify-between gap-0 w-full">
          <Typography
            dangerouslySetInnerHTML={{
              __html: expirationDate
                ? trans('institution.billing.subscription_overview', {
                    count,
                    subscription: trans(`subscription.plans.${sub.plan_id}.title`),
                    billingInterval: trans(`subscription.types.${sub.type}`),
                    renewalDate: formatDate(new Date(expirationDate)),
                    amount,
                  })
                : trans('institution.billing.subscription_overview_prepaid', {
                    count,
                    subscription: trans(`subscription.plans.${sub.plan_id}.title`),
                    billingInterval: trans(`subscription.types.${sub.type}`),
                    amount,
                  }),
            }}
          />
          <ToolTip txt={trans('subscription.change_plan_tooltip')}>
            <IconButton onClick={() => onGoToSubscriptions?.(sub)} icon={IconPaymentSettings} />
          </ToolTip>
        </div>
      );
    }

    return elements;
  }

  function subscriptionComponent(sub, index) {
    return subscriptionHtml(sub, index);
  }

  function subscriptionsListComponent() {
    return (
      <>
        {(subscriptions || []).map((sub, index) => subscriptionComponent(sub, index))}
        {(unassignedSubscriptions || []).length > 0 && (
          <>
            <Divider light />
            {(unassignedSubscriptions || []).map((sub, index) => unassignedSubscriptionHtml(sub, index))}
          </>
        )}
      </>
    );
  }

  if (subscriptions?.length === 0 && Object.keys(licenses).length === 0 && unassignedSubscriptions?.length === 0) {
    return null;
  }
  return (
    <Section title={trans('institution.billing.your_company_subscriptions')}>
      {subscriptionsListComponent()}
      {licensesHtml()}
    </Section>
  );
};

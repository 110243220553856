// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconArrowRight, IconLocked} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_RoundData} from '@modules/Profile/types/exercises.model';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  round: _RoundData;
  service: string;
  onLockClick: () => void;
}

export const ExercisesRoundRow: React.FC<_Props> = ({onLockClick, service, round}) => {
  const navigateToFlow = useNavigateToFlow();
  const {title, available, description, exercises, progress, round: roundIndex} = round;

  function onGoToExercise(): void {
    if (roundIndex === null || roundIndex === undefined) {
      return;
    }
    navigateToFlow(FLOWS.ProfileExercises, {
      round: roundIndex,
      service,
      exerciseIndex: 0,
    });
  }

  const Icon = getServiceIcon(service);

  logger.debug('ExercisesRoundRow', {service, round});

  return (
    <SectionRow
      title={trans(PROFILE_SERVICES_TITLES[service])}
      subtitle={title && trans(title)}
      icon={Icon && <Icon />}
      prefixContent={
        <div className="w-[56px]">
          <ProgressBar color="success" size="xs" value={progress} />
        </div>
      }
      buttonIcon={available ? IconArrowRight : IconLocked}
      buttonTooltip={!available ? trans('profile_modules.upgrade_to_continue') : undefined}
      onButtonClick={() => {
        if (available) {
          onGoToExercise();
        } else {
          onLockClick?.();
        }
      }}
      isButtonDisabled={!available}
    />
  );
  // return (
  //   <div className="flex flex-row justify-bewteen w-full gap-2">
  //     <div className="flex flex-col w-full gap-1">
  //       <div className="flex flex-row items-center gap-1 w-full">
  //         {Icon && <Icon />}
  //         <Typography variant="body1">{trans(PROFILE_SERVICES_TITLES[service])}</Typography>
  //       </div>
  //       <Typography variant="body" className="w-full">
  //         {title && trans(title)}
  //       </Typography>
  //     </div>
  //
  //     <div className="flex flex-row justify-end gap-2 items-center">
  //       <div className="w-[56px]">
  //         <ProgressBar color="success" size="xs" value={progress} />
  //       </div>
  //       <ToolTip plc="top" txt={!available ? trans('profile_modules.upgrade_to_continue') : undefined}>
  //         <IconButton
  //           onClick={() => {
  //             if (available) {
  //               onGoToExercise();
  //             } else {
  //               onLockClick?.();
  //             }
  //           }}
  //           icon={available ? IconArrowRight : IconLocked}
  //         />
  //       </ToolTip>
  //     </div>
  //   </div>
  // );
};

// @ts-nocheck
/* eslint-disable */

import React, {FC} from 'react';
import {Accordion} from '@modules/Core/components/base/accordion/Accordion';
import {IconStarFilled} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileValuesEntry} from '@modules/Profile/types/service.model';
import {_TeamValuesEntry} from '@modules/Team/types/teamanalysis.model';

interface _ValuesCardProps {
  value: _ProfileValuesEntry | _TeamValuesEntry;
  valueScoreComponent?: React.ReactNode;
  showDescription?: boolean;
  children?: React.ReactNode;
}

export const ValuesCard: FC<_ValuesCardProps> = ({value, valueScoreComponent, showDescription = true, children}) => (
  <Accordion
    title={value.name}
    titleVariant="body"
    titleSuffix={valueScoreComponent}
    titlePrefix={(value as _TeamValuesEntry).highlighted ? <IconStarFilled /> : null}
  >
    {Boolean(showDescription) && (
      <Typography
        variant="body"
        className="whitespace-pre-wrap"
        dangerouslySetInnerHTML={{
          __html: value.description,
        }}
      />
    )}
    {children}
  </Accordion>
);

// @ts-nocheck
/* eslint-disable */

import {_ProfileTrackingStatistic, _ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {
  getCurrentMonthNumber,
  getCurrentWeekNumber,
  getCurrentYear,
  getLastMonthNumber,
} from '@modules/Core/util/dateUtil';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';

export function createMultiSolidGaugeChartData(items, full = 100, firstCategory: string | null = null) {
  if (!Array.isArray(items)) {
    throw new Error('Input items must be an array.');
  }

  // Group items by category
  const groupedItems = {};
  items.forEach(item => {
    if (!groupedItems[item.category]) {
      groupedItems[item.category] = [];
    }
    groupedItems[item.category].push(item);
  });

  // Find the longest array of items within a category
  const maxLength = Math.max(...Object.values(groupedItems).map(arr => arr.length));

  // Create the result structure
  const result = [];

  // Iterate over the range of maxLength to build each sub-array
  for (let i = 0; i < maxLength; i++) {
    const innerArray = Object.keys(groupedItems)
      .map(category => {
        const item = groupedItems[category][i];
        if (!item) {
          return null;
        }

        const innerResult = copyObject(item);
        innerResult.value = Math.round((innerResult.value * 100) / full);
        return innerResult;
      })
      .filter(item => item);

    result.push(innerArray);
  }

  if (firstCategory) {
    // Sort each array so that first item.category === firstCategory
    result.forEach(arr => {
      arr.sort((a, b) => {
        if (a.category === firstCategory) {
          return 1;
        }
        if (b.category === firstCategory) {
          return -1;
        }
        return 0;
      });
    });
  }

  return result;
}

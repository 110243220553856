// @ts-nocheck
/* eslint-disable */

import {_Profile} from '@modules/Profile/types/profile.model';

export function hasThirdPersonFeedbacks(profile: _Profile): boolean {
  const feedback360Data = profile?.thirdPersonFeedback;

  if (!feedback360Data) {
    return false;
  }

  return !!feedback360Data.sharedWithYou || !!feedback360Data.sharedWithOthers;
}

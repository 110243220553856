// @ts-nocheck
/* eslint-disable */

import React, {FC, useEffect, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {_ProfileValueAnimation} from '@modules/ContentEngine/components/items/profile/result/values/ProfileValuesItem';
import {Typography} from '@modules/Core/components/base/Typography';

interface _ValuesGemProps {
  points?: number;
  hidden?: boolean;
  animation?: _ProfileValueAnimation;
}

export const ValuesGemBag: FC<_ValuesGemProps> = ({animation, hidden, points}) => {
  const [animate, setAnimate] = useState<boolean>(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!animation) {
        setAnimate(false);
        return;
      }
      const animationStart = new Date(animation?.created_at).getTime();
      const animationEnd = animationStart + animation?.duration * 1000;
      const now = Date.now();
      const shouldAnimate = now >= animationStart && now <= animationEnd;
      setAnimate(shouldAnimate);
      if (!shouldAnimate) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [animation]);

  const imageName = animate ? 'gembag.gif' : 'gembag.png';
  return (
    <div
      className={addCN('flex flex-row gap-1 p-1 rounded items-center border-1 border-grey300', hidden && 'invisible')}
    >
      <img height={64} width={64} src={`/assets/animations/${imageName}`} />
      {points !== undefined && <Typography variant="body">{points}</Typography>}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {useFlowByName} from './flowByName';

/**
 * This hook listens for updates to the data of the connected step, and fires when changes detected.
 * @param {function} setValueCallbackFn
 * @param {number} index
 * @param {string} name
 * @param {string} flowName
 * @returns {void}
 */

export function useDidFlowUpdate(
  index: number,
  name: string,
  flowName: string,
  instanceKey: string,
  setValueCallbackFn?: (value: any) => void
): void {
  const flowState = useFlowByName(flowName, instanceKey);

  useEffect(() => {
    if (!flowState) {
      return;
    }

    const isCurrentStep = flowState.currentStep?.index === index;

    if (isCurrentStep && setValueCallbackFn) {
      setValueCallbackFn(flowState?.currentStep?.data?.[name]);
    }
  }, [flowState, index, name, setValueCallbackFn]);
}

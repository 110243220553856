// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconProfile, IconProfilePrivacy} from '@modules/Core/components/base/Icons/Icons';
import {_TabsItem, Tabs} from '@modules/Core/components/base/Tabs';
import {trans} from '@modules/Translations/util/i18n';

interface _SettingTabs {
  active: _ProfileAccountTab;
  setActive: (tab: _ProfileAccountTab) => void;
}

export type _ProfileAccountTab = 'account' | 'privacy';

export const SettingTabs: React.FC<_SettingTabs> = ({active, setActive}) => {
  const tabs: _TabsItem[] = [
    {
      key: 'account',
      displayValue: trans('settings.account_settings_title'),
      iconElement: <IconProfile />,
    },
    {
      key: 'privacy',
      displayValue: trans('settings.privacy_settings_title'),
      iconElement: <IconProfilePrivacy />,
    },
  ];

  return <Tabs tabs={tabs} activeTab={active} setActiveTab={setActive} />;
};

// @ts-nocheck
/* eslint-disable */

import {useDidUpdateEffect} from '@modules/Core/hooks/didUpdateEffect';
import {dispatchAction} from '@modules/State/util/util';
import {updateCurrentStepData} from '../state/flowSlice';
import {useFlowByName} from './flowByName';

export function useUpdateFlowData(
  index: number,
  name: string,
  flowName: string,
  instanceKey: string,
  getValueCallbackFn?: () => any,
  inputs?: any[]
): void {
  const flowState = useFlowByName(flowName, instanceKey);

  useDidUpdateEffect(() => {
    if (!flowState?.currentStep) {
      return;
    }
    const isCurrentStep = flowState.currentStep.index === index;
    if (isCurrentStep && getValueCallbackFn) {
      const dataObject = {
        [`${name}`]: getValueCallbackFn(),
      };
      dispatchAction(
        updateCurrentStepData({
          data: dataObject,
          name: `${flowName}_${instanceKey}`,
        })
      );
    }
  }, inputs ?? []);
}

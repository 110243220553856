// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useProfilePaymentActions} from '@modules/Core/components/platform/payments/paymentPlan/hooks/profilePaymentPlanActions';
import {PaymentPlanHeader} from '@modules/Core/components/platform/payments/paymentPlan/PaymentPlanHeader';
import {PlanPerksComponent} from '@modules/Core/components/platform/payments/paymentPlan/PlanPerksComponent';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';

interface _PaymentPlanProps {
  profile: _Profile | null;
  institution: _Institution | null;
  currentSubscription: _Subscription | null;

  currentPlanIndex: number | null;
  plan: _PaymentPlan;
  index: number;
  onChange: () => void;
}

export const PaymentPlan: React.FC<_PaymentPlanProps> = ({
  profile,
  institution = null,
  currentSubscription,
  plan,
  index,
  currentPlanIndex,
  onChange,
}) => {
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const actions = useProfilePaymentActions(plan, isCurrentPlan, profile, currentSubscription, institution, onChange);

  useEffect(() => {
    if (index === null || currentPlanIndex === null || index === undefined || currentPlanIndex === undefined) {
      setIsCurrentPlan(false);
      return;
    }
    setIsCurrentPlan(index === currentPlanIndex);
  }, [index, currentPlanIndex]);

  if (!plan) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 rounded">
      <PaymentPlanHeader
        isCurrentPlan={isCurrentPlan}
        institution={institution}
        currentSubscription={currentSubscription}
        profile={profile}
        plan={plan}
        actions={actions}
      />
      <PlanPerksComponent plan={plan} />
    </div>
  );
};

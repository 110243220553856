// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {SingleValue} from 'react-select';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useLocation} from '@modules/Navigation/hooks/location';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentProps {
  teams: _ShortTeam[];
  selectedTeamId?: string;
  // Future possible types:  'add_as_flow_id'
  actionHandlerType?: 'add_as_param';
  paramName?: string;
  onTeamChosen?: (teamId: string) => void;
}

const ProfileTeamSelectionItem: React.FC<_TeamDevelopmentProps> = ({
  actionHandlerType,
  teams,
  selectedTeamId: externalSelectedTeamId,
  paramName = 'teamId',
  onTeamChosen,
}) => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const location = useLocation();
  const {navigate} = useAppNavigate();

  useEffect(() => {
    if (!selectedTeamId) {
      setSelectedTeamId(externalSelectedTeamId ?? teams?.[0]?.id ?? null);
    }
  }, [externalSelectedTeamId, teams]);

  const handleTeamChange = (event: SingleValue<_SelectValue>): void => {
    setSelectedTeamId(event?.value as string);
    handleAction(event?.value as string);
  };

  const handleAction = (newTeamId: string) => {
    if (onTeamChosen) {
      onTeamChosen(newTeamId);
      return;
    }
    logger.debug('handleAction', {actionHandlerType});
    if (!actionHandlerType) {
      return;
    }

    switch (actionHandlerType) {
      case 'add_as_param':
        if (location?.searchAsDict?.[paramName] !== newTeamId) {
          const newSearchParams = copyObject(location.searchAsDict) ?? {};
          newSearchParams[paramName] = newTeamId;
          navigate(location.pathname, newSearchParams, null, true, false);
        }
        break;
      default:
    }
  };
  return (
    <div className="relative-col-width-2">
      <Select
        placeholder={trans('dashboard.your_teams_selection_placeholder')}
        options={
          teams?.map(team => {
            return {
              value: team.id,
              label: team.name,
            };
          }) ?? []
        }
        onChange={handleTeamChange}
        value={{
          value: selectedTeamId,
          label: teams?.find(team => team.id === selectedTeamId)?.name,
        }}
      />
    </div>
  );
};
export default ProfileTeamSelectionItem;

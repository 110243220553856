// @ts-nocheck
/* eslint-disable */

// import { AmCharts } from '../../../../AmCharts/AmCharts'

import React from 'react';
import {AmCharts} from '@modules/Core/components/charts/AmCharts';

interface _GraphItemProps {
  data: any;
  type: 'SolidGaugeMulti' | 'Lines';
  height?: number | string;
  width?: number | string;
  chartOptions?: any;
  withWrapper?: boolean;
}

export const GraphItem: React.FC<_GraphItemProps> = ({data, type, height, width, chartOptions, withWrapper = true}) => {
  if (!type) {
    return null;
  }

  const wrap = (content: any): JSX.Element => {
    if (withWrapper) {
      return <div style={{height, width}}>{content}</div>;
    }
    return content;
  };

  return wrap(<AmCharts type={type} data={data} chartOptions={chartOptions} />);
};

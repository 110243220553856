// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {trans} from '@modules/Translations/util/i18n';
import 'react-quill/dist/quill.snow.css';
import './Tasks.scss';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';

interface _RichTextTaskDoneViewProps {
  showCorrectAnswer?: boolean | null;
  value: string | null;
  correctAnswer: string | null;
}

export const RichTextTaskDoneView: React.FC<_RichTextTaskDoneViewProps> = ({
  showCorrectAnswer,
  value,
  correctAnswer,
}) => {
  if (value) {
    return (
      <div>
        <div dangerouslySetInnerHTML={{__html: value}} />
        {showCorrectAnswer && correctAnswer && (
          <div>
            <Typography variant="body1">{trans('profile_exercises.correct_answer')}</Typography>
            <div dangerouslySetInnerHTML={{__html: trans(correctAnswer)}} />
          </div>
        )}
      </div>
    );
  }

  if (showCorrectAnswer && correctAnswer) {
    return (
      <Section>
        <Typography variant="body1">{trans('profile_exercises.correct_answer')}</Typography>
        <div dangerouslySetInnerHTML={{__html: trans(correctAnswer)}} />
      </Section>
    );
  }

  if (showCorrectAnswer) {
    return null;
  }

  return <div>{trans('profile_exercises.no_answer')}</div>;
};

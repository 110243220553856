// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const SurveyResultStep: React.FC<_BaseStepComponentOptions> = ({options}) => (
  <BaseStep options={options}>
    TODO @Sherif after we add teams
    {/* <SurveyResult type={options?.type} surveyParams={options?.surveyParams} isCoach={options?.isCoach} /> */}
  </BaseStep>
);

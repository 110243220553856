// @ts-nocheck
/* eslint-disable */

import {_AppDnDListWrapper} from '@modules/Core/components/base/dnd/DnDContext';

/**
 * Wrapper around lists
 * @param id
 * @param items
 * @param setItems
 */
export const useDnDLists = (id: string, items: any[], setItems: (items: any[]) => void): _AppDnDListWrapper => ({
  id,
  items,
  setItems,
});

// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {useFlowByName} from '@modules/FlowEngine/hooks/flowByName';
import {_FlowOptions} from '@modules/FlowEngine/types/flow.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';

/**
 *
 * @param flowName
 * @param onFinishClicked
 * @returns {unknown[]}
 */
export function useFlowData(
  flowName: string,
  instanceKey: string,
  onFinishClicked: () => void
): [_FlowStep | undefined, _FlowOptions | undefined] {
  const flowState = useFlowByName(flowName, instanceKey);
  const [currentStep, setCurrentStep] = useState<_FlowStep | undefined>(undefined);
  const [steps, setSteps] = useState<Record<string, _FlowStep>>({});
  const [options, setOptions] = useState<_FlowOptions | undefined>(undefined);

  const prevCurrentStep = useRef<_FlowStep>();
  const prevSteps = useRef<Record<string, _FlowStep>>();
  const prevOptions = useRef<_FlowOptions>();

  useEffect(() => {
    // TODO do we need this check?
    // if (flowName !== flowState?.name) {
    //   return;
    // }
    // Check if the value has changed
    // TODO (Low Prio) @Stefan @Sherif Is this really how we should do it?
    if (JSON.stringify(prevCurrentStep.current) !== JSON.stringify(flowState?.currentStep)) {
      setCurrentStep(flowState?.currentStep);
      prevCurrentStep.current = flowState?.currentStep;
    }

    if (JSON.stringify(prevSteps.current) !== JSON.stringify(flowState?.steps)) {
      setSteps(flowState?.steps);
      prevSteps.current = flowState?.steps;
    }

    if (JSON.stringify(prevOptions.current) !== JSON.stringify(flowState?.options)) {
      setOptions(flowState?.options);
      prevOptions.current = flowState?.options;
    }
  }, [flowState?.currentStep, flowState?.steps, flowState?.options, flowState?.finished]);

  useEffect(() => {
    if (flowState?.finished) {
      onFinishClicked?.();
    }
  }, [flowState?.finished]);
  return [currentStep, options];
}

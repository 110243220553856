// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _ListItem {
  label?: string;
  icon?: React.ElementType;
  endIcon?: React.ElementType;
  nested?: boolean;
  disabled?: boolean;
  active?: boolean;
  onChange?: () => void;
  children?: React.ReactNode;
  suffix?: React.ReactNode;
  border?: boolean;
  lineClamp?: boolean;
}

export const ListItem: React.FC<_ListItem> = ({
  label,
  disabled,
  border,
  nested,
  active,
  onChange,
  suffix,
  lineClamp = false,
  icon: Icon,
  endIcon: EndIcon,
  children,
}) => {
  const [hover, setHover] = useState(false);

  let innerBgColor = 'bg-white500';
  if (hover) innerBgColor = 'bg-grey100';
  if (active) {
    innerBgColor = 'bg-brand700';
    // if (nested) innerBgColor = 'bg-white500';
  }
  if (disabled) innerBgColor = '';

  let iconColor: string | undefined;
  if (active) {
    iconColor = 'fill-white500';
    // if (nested) iconColor = 'fill-brand700';
  }
  if (disabled) iconColor = 'fill-white500';

  let textColor: string | undefined;
  if (disabled) textColor = '!text-white500';
  if (active) {
    textColor = '!text-white500';
    // if (nested) textColor = '!text-brand700';
  }

  let innerBorder;
  // if (active && nested) innerBorder = 'border-brand700 border-1';

  const handleChange = (): void => {
    if (!disabled && onChange) onChange();
  };

  return (
    <button
      onClick={handleChange}
      onPointerOver={(): void => setHover(true)}
      onPointerOut={(): void => setHover(false)}
      disabled={disabled}
      className={addCN(
        'flex w-full p-1 flex-col justify-center items-start gap-0 transition',
        disabled ? 'bg-grey500' : 'bg-white500'
      )}
    >
      <div
        className={addCN(
          'pointer-events-none select-none',
          'flex py-0 px-1 flex-row justify-between items-center gap-1 self-stretch rounded-xs transition',
          innerBgColor,
          innerBorder,
          border ? 'border-1 border-grey300' : ''
        )}
      >
        <div className="flex flex-row gap-1 items-center">
          {Icon && <Icon color={iconColor} />}
          {label && (
            <span className={addCN('transition', lineClamp ? 'line-clamp-1 text-left' : '', textColor)}>{label}</span>
          )}
          {children && <span className={addCN('transition', textColor)}>{children}</span>}
        </div>

        {EndIcon && <EndIcon color={iconColor} />}
        {suffix && <span className={addCN('transition', textColor)}>{suffix}</span>}
      </div>
    </button>
  );
};

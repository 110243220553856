// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {
  SHARED_WITH_OTHERS_TYPE,
  SHARED_WITH_YOU,
  SHARED_WITH_YOU_TYPE,
} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

/**
 * "type": "thirdPersonFeedbackHeader",
 *                             "variant": "{{var(variant)}}",
 *                             "sharedWithYouInvites": "{{data('profile.thirdPersonFeedback.sharedWithYouInvites')}}",
 *                             "sharedWithOthersInvites": "{{data('profile.thirdPersonFeedback.sharedWithOthersInvites')}}",
 *                             "feedbackId": "{{var(feedbackId)}}"
 */
interface _ThirdPersonFeedbackHeaderItemProps {
  variant: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE;
  sharedWithYouInvites: Record<string, any[]>;
  sharedWithOthersInvites: Record<string, any[]>;
  feedbackId: string;
}

export const ThirdPersonFeedbackHeaderItem: React.FC<_ThirdPersonFeedbackHeaderItemProps> = ({
  variant,
  feedbackId,
  sharedWithOthersInvites,
  sharedWithYouInvites,
}) => {
  const [feedbacks, setFeedbacks] = useState<any[]>([]);

  useEffect(() => {
    if (!feedbackId || !variant) {
      setFeedbacks([]);
      return;
    }

    const data = variant === SHARED_WITH_YOU ? sharedWithYouInvites : sharedWithOthersInvites;

    if (!data) {
      return;
    }

    setFeedbacks(
      (data[feedbackId] ?? []).filter(item => {
        return item?.status === 'DONE' && !!item?.values?._feedback_results;
      })
    );
  }, [variant, feedbackId, sharedWithOthersInvites, sharedWithYouInvites]);

  return (
    <>
      <p>{trans('profile.third_person_feedback.personality_feedback_description_1')}</p>
      <ul>
        <li>
          <Typography>{trans('profile.third_person_feedback.personality_title')}</Typography>
        </li>
        <li>
          <Typography>{trans('profile.third_person_feedback.roles_title')}</Typography>
        </li>
        <li>
          <Typography>{trans('profile.third_person_feedback.feedback_title')}</Typography>
        </li>
      </ul>
      <Typography
        dangerouslySetInnerHTML={{__html: trans('profile.third_person_feedback.personality_feedback_description_2')}}
      />

      <div className="flex flex-row flex-wrap gap-1">
        {feedbacks?.map((feedback, index) => (
          <ToolTip txt={feedback.profile.fullName}>
            <Avatar image={getProfilePic(feedback.profile)} />
          </ToolTip>
        ))}
      </div>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {generate} from '@modules/Core/components/base/helper';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {logger} from '@modules/Core/util/Logger';
import {uuid} from '@modules/Core/util/util';
import {getAllPersonalValues} from '@modules/Profile/services/profileValuesApi';
import {_ProfileValues, _ProfileValuesEntry} from '@modules/Profile/types/service.model';
import {isCustomValue} from '@modules/Profile/util/profileValuesUtil';

const MAX_POINTS = 10;

export function useProfileValues(
  profileId: string,
  data: _ProfileValues
): {
  profileValues: _ProfileValues;
  allValues: _ProfileValues;
  availablePoints: number;
  setProfileValues: (values: _ProfileValues) => void;
  setAllValues: (values: _ProfileValues) => void;
  originalProfileValues: _ProfileValues;
  customValues: _ProfileValues;
  onDeleteItem: (id: string) => void;
  onNewItemCreated: (name: string, description: string) => void;
} {
  // Profile values
  const [profileValues, setProfileValues] = useState<_ProfileValues>([]);
  // All values
  const [allValues, setAllValues] = useState<_ProfileValues>([]);

  const [filteredAllValues, setFilteredAllValues] = useState<_ProfileValues>([]);
  const [exposedProfileValues, setExposedProfileValues] = useState<_ProfileValues>([]);

  const [availablePoints, setAvailablePoints] = useState<number>(10);
  const [customValues, setCustomValues] = useState<_ProfileValues>([]);
  useEffect(() => {
    const points = exposedProfileValues?.reduce((acc, value) => acc + (value.points ?? 0), 0) ?? 0;

    setAvailablePoints(MAX_POINTS - points);
  }, [exposedProfileValues]);

  useEffect(() => {
    // Filter allValues to exclude values that are already in profileValues
    setFilteredAllValues(
      allValues
        .filter(value => !profileValues?.find(profileValue => profileValue.id === value.id))
        .map(value => ({...value, points: 0}))
    );
  }, [allValues, profileValues]);

  useEffect(() => {
    setCustomValues(
      filteredAllValues
        .filter(value => isCustomValue(value))
        .concat(exposedProfileValues?.filter(value => isCustomValue(value)))
    );
  }, [filteredAllValues, profileValues]);
  useEffect(() => {
    setExposedProfileValues(profileValues ?? []);
  }, [profileValues]);

  useEffect(() => {
    setProfileValues(data);
  }, [data]);

  useEffectAsync(async () => {
    void refreshAllValues();
  }, []);

  const refreshAllValues = async (): Promise<void> => {
    const response = await getAllPersonalValues();

    if (response.status === 200 && response.data) {
      setAllValues(response.data);
    }
  };

  const onNewItemCreated = (name: string, description: string): void => {
    const newValue: _ProfileValuesEntry = {
      name,
      description,
      created_by: profileId,
      id: uuid(),
      points: 0,
    };

    setFilteredAllValues((values: _ProfileValues) => [...values, newValue]);
  };

  const onDeleteItem = (id: string): void => {
    setFilteredAllValues(values => values.filter(value => value.id !== id));
  };
  return {
    profileValues: exposedProfileValues,
    allValues: filteredAllValues,
    setProfileValues: setExposedProfileValues,
    setAllValues: setFilteredAllValues,
    originalProfileValues: profileValues,
    availablePoints,
    customValues,
    onDeleteItem,
    onNewItemCreated,
  };
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {TeamDevelopmentResultSectionChart} from '@modules/Team/components/team_development/result/TeamDevelopmentResultSectionChart';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: Array<Array<{score: number}>>;
  text: string;
  graphTitle: string;
  description: string;
  individualData: {
    description: string;
  };
  teamData: {
    description: string;
  };
  individualChangeData: {
    description: string;
  };
  teamChangeData: {
    description: string;
  };
}

export const TeamDevelopmentResultSection: React.FC<_Props> = ({
  individualData,
  individualChangeData,
  teamData,
  teamChangeData,
  description,
  data,
  text,
  graphTitle,
}) => {
  logger.debug('TeamDevelopmentResultSection', {
    individualData,
    teamData,
    description,
    data,
    text,
    graphTitle,
    individualChangeData,
    teamChangeData,
  });

  const showChange = Boolean(individualChangeData?.description && teamChangeData?.description);
  return (
    <Section titleVariant="h6" title={graphTitle} descriptionVariant="body1" description={description} gap={8}>
      <div className="flex flex-row gap-3">
        <div className="relative-col-width-6">
          <TeamDevelopmentResultSectionChart chartData={data} title={graphTitle} />
        </div>
        <div className="relative-col-width-6 flex flex-col justify-center">
          <Section title={trans('development_result.explanation')} fullWidth>
            <Typography
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </Section>
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <div className="relative-col-width-6">
          <Section
            title={trans('development_result.your_individual_opinion')}
            description={individualData.description}
            titleVariant="h6"
          />
        </div>
        <div className="relative-col-width-6">
          <Section
            title={trans('development_result.your_team_opinion')}
            description={teamData.description}
            titleVariant="h6"
          />
        </div>
      </div>
      {showChange && (
        <div className="flex flex-row gap-3">
          <div className="relative-col-width-6">
            <Section
              title={trans('development_result.your_change')}
              description={individualChangeData.description}
              titleVariant="h6"
            />
          </div>
          <div className="relative-col-width-6">
            <Section
              title={trans('development_result.your_team_change')}
              description={teamChangeData.description}
              titleVariant="h6"
            />
          </div>
        </div>
      )}
    </Section>
  );
};

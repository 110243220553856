// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {getCountryList} from '@modules/Core/services/api/country';
import {_Address} from '@modules/Core/types/address.model';
import {trans} from '@modules/Translations/util/i18n';

interface _AddressProps {
  address: _Address | null;
  setAddress: (address: _Address | null) => void;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (address: _Address | null) => void;
}

export const Address: React.FC<_AddressProps> = ({address, setAddress, required, readOnly, disabled, onChange}) => {
  const [countryList, setCountryList] = useState([]);
  const [street, setStreet] = useState<string | null>('');
  const [city, setCity] = useState<string | null>('');
  const [postalCode, setPostalCode] = useState<string | null>('');
  const [country, setCountry] = useState<string | null>('');

  async function loadCountryList() {
    const result = await getCountryList();
    setCountryList(result.data);
  }

  useEffectAsync(async () => {
    if (countryList.length === 0) {
      void loadCountryList();
    }
  }, [countryList]);

  useEffect(() => {
    if (!address) {
      setCountry('de');
      setCity('');
      setPostalCode('');
      setStreet('');
      return;
    }

    if (street !== address?.street) {
      setStreet(address.street);
    }

    if (city !== address?.city) {
      setCity(address.city);
    }

    if (postalCode !== address?.postcode) {
      setPostalCode(address.postcode);
    }

    if (country !== address?.country) {
      if (address?.country) {
        setCountry(address.country);
      } else {
        setAddress({
          ...address,
          country,
        });
        setCountry('de');
      }
    }
  }, [address, countryList]);

  function streetComponent() {
    return (
      <Input
        label={trans('address.street_and_street_number')}
        placeholder={trans('address.street_and_street_number')}
        value={street}
        onChange={value => {
          setAddress({...address, street: value});
          onChange?.({...address, street: value});
        }}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  function postalCodeComponent() {
    return (
      <Input
        label={trans('address.zip_code')}
        placeholder={trans('address.zip_code')}
        value={postalCode}
        onChange={value => {
          setAddress({...address, postcode: value});
          onChange?.({...address, postcode: value});
        }}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  function cityComponent() {
    return (
      <Input
        placeholder={trans('address.city')}
        label={trans('address.city')}
        value={city}
        onChange={value => {
          setCity(value);
          setAddress({...address, city: value});
          onChange?.({...address, city: value});
        }}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  function countryComponent() {
    return (
      <Select
        label={trans('address.country')}
        disabled={!countryList || countryList.length === 0 || disabled || readOnly}
        required={required}
        onChange={value => {
          setAddress({...address, country: value.value});
          onChange?.({...address, country: value.value});
        }}
        value={{
          value: country,
          label: country ? countryList.find(option => option.value === country)?.label : '',
        }}
        options={countryList.map(option => ({
          value: option.value,
          label: option.label,
        }))}
      />
    );
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-row">{streetComponent()}</div>

      <div className="flex flex-row gap-3">
        {postalCodeComponent()}
        {cityComponent()}
      </div>
      {countryComponent()}
    </div>
  );
};

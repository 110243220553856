// @ts-nocheck
/* eslint-disable */

import React, {ElementType, ReactNode} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';

interface _SectionRowProps {
  title: string;
  subtitle?: string;
  icon?: ElementType;
  prefixContent?: ReactNode;
  buttonIcon: ElementType;
  buttonTooltip?: string;
  onButtonClick: () => void;
  isButtonDisabled?: boolean;
}

export const SectionRow: React.FC<_SectionRowProps> = ({
  title,
  subtitle,
  icon,
  prefixContent,
  buttonIcon,
  buttonTooltip,
  onButtonClick,
  isButtonDisabled = false,
}) => {
  return (
    <div className="flex flex-row justify-between w-full gap-2">
      <div className="flex flex-col w-full gap-1">
        <div className="flex flex-row items-center gap-1 flex-grow">
          {icon && icon}
          <Typography variant="body1">{title}</Typography>
          <div className="flex flex-row justify-end gap-2 items-center flex-grow">
            {prefixContent}
            <ToolTip plc="top" txt={buttonTooltip}>
              <IconButton onClick={onButtonClick} icon={buttonIcon} disabled={isButtonDisabled} />
            </ToolTip>
          </div>
        </div>
        {subtitle && (
          <Typography variant="body" className="w-full">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Action} from '@modules/Core/components/layout/Action';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const ContentStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  logger.debug('ContentStep', options);
  return (
    <BaseStep options={options}>
      <Section
        title={options.title}
        titleVariant={options.titleVaraint || 'h5'}
        image={options.image}
        description={options.description}
        descriptionVariant={options.descriptionVariant}
      >
        <div className={twMerge('flex flex-row', `justify-${options.buttonPosition || 'start'}`, 'gap-2')}>
          {Boolean(options.button_label) && (
            <div className="relative-col-width-2">
              <Button label={options.button_label} onClick={() => options.onComplete()} />
            </div>
          )}
          {Object.entries(options.actions || {})?.map(([key, action]) => (
            <Action
              label={action.label}
              onClick={() => {
                if (action.metadata?.eventName) {
                  // onClick is event name, set onClick to fire that event
                  options.fireEvent(action.metadata.eventName);
                }
              }}
              key={key}
            />
          ))}
        </div>
      </Section>
    </BaseStep>
  );
};

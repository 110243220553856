// @ts-nocheck
/* eslint-disable */

/**
 * Surveys
 */
export const SURVEY = 'survey/load';
export const SURVEY_SUBMIT = 'survey/submit';
export const SURVEY_FINALISE = 'survey/submit/finalise';
export const SURVEY_UPLOAD = 'survey/upload';

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ExpandableContent} from '@modules/Core/components/base/ExpandableContent';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _PlanPerksComponentProps {
  plan?: _PaymentPlan;
  variant?: _TypographyVariants;
  expandable?: boolean;
}

export const PlanPerksComponent: React.FC<_PlanPerksComponentProps> = ({
  plan,
  variant = 'body',
  expandable = false,
}) => {
  const PERKS_COUNT_LIMIT = 4;

  const [perks, setPerks] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  useEffect(() => {
    if (!plan?.perks) {
      return;
    }
    let newPerks = plan?.perks;
    let newHasMore = false;
    if (plan?.perks.length > PERKS_COUNT_LIMIT) {
      newPerks = plan?.perks.slice(0, PERKS_COUNT_LIMIT);
      newHasMore = true;
    }

    setPerks(newPerks);
    setHasMore(newHasMore);
  }, [plan]);

  return (
    <Section gap={2}>
      {(perks || [])
        .filter((perk, index) => index === 0)
        .map((perk, index) => (
          <Typography variant={variant}>
            <div dangerouslySetInnerHTML={{__html: trans(perk)}} />
          </Typography>
        ))}
      <ExpandableContent showExpander={expandable} text={trans('subscription.expand_for_all_features')} gap={2}>
        <>
          {(perks || [])
            .filter((perk, index) => index > 0)
            .map((perk, index) => (
              <Typography variant={variant}>
                <div dangerouslySetInnerHTML={{__html: trans(perk)}} />
              </Typography>
            ))}
          {hasMore && (
            <Typography key="showMore" variant={variant}>
              <TextLink label={trans('subscription.view_full_list')} />
            </Typography>
          )}
        </>
      </ExpandableContent>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';

interface _Group {
  children: React.ReactNode;
  direction?: 'row' | 'col';
  wrap?: boolean;
  gap?: number;
  show?: boolean;
}

export const Group: React.FC<_Group> = ({show, children, gap = 8, direction = 'col', wrap = false}) => {
  if (show !== null && show !== undefined && !show) return children;

  return (
    <div
      className={twMerge(
        'flex relative',
        direction === 'col' ? 'flex-col' : 'flex-row',
        wrap ? 'flex-wrap' : 'flex-nowrap',
        `gap-${gap}`
      )}
    >
      {children}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  alert: _ProfileAlert;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
  updateCurrentConfig?: (config: any) => void;
}

export const ProfileAlertDialog: React.FC<_Props> = ({alert, onClose, updateCurrentConfig}) => {
  const {metadata} = alert;

  const handleClose = async (confirmed: boolean): Promise<void> => {
    onClose(confirmed, metadata?.navigateTo as string | null);
  };

  useEffect(() => {
    if (!updateCurrentConfig) return;

    // Pass dialog configuration to the global dialog manager
    updateCurrentConfig({
      title: trans(metadata?.title as string | undefined),
      confirmLabel: trans(metadata?.confirmLabel as string | undefined),
      onConfirm: () => {
        void handleClose(true);
      },
      onClose: () => {
        void handleClose(false);
      },
      open: true,
      titleGap: 5,
    });
  }, [metadata]);

  return (
    <Typography
      variant="body1"
      dangerouslySetInnerHTML={{
        __html: trans(metadata?.content as string | undefined, metadata?.contentParams as string[] | undefined),
      }}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {isDevMode} from '@modules/Core/util/util';

interface _DevModeSectionProps {
  children: React.ReactNode;
}

export const DevModeSection: React.FC<_DevModeSectionProps> = ({children}) => {
  const showDevComponents = useUiStore(state => state.showDevComponents);
  if (!isDevMode() || !showDevComponents) {
    return null;
  }
  return (
    <div className="bg-typography-danger p-2 rounded-md mb-2">
      <div className="flex items-center space-x-2 mb-4">
        <IconInfo />
        <h6>This is only visible in dev mode</h6>
      </div>

      {children}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {TeamCommunicationChannelsRankingItem} from '@modules/Team/components/team_analysis/way_of_working/TeamCommunicationChannelsRankingItem';

interface _Props {
  channels: string[];
  teamProfiles: any;
  transparencyAccepted: boolean;
}

export const TeamCommunicationChannelsRanking: React.FC<_Props> = ({channels = [], teamProfiles, trans}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!channels) {
      setData([]);
      return;
    }

    createData();
  }, [channels]);

  function createData() {
    const newData = [];

    Object.entries(channels).forEach(([channelName, channelValues], index) => {
      const totalProfiles = Object.values(channelValues ?? {}).reduce((acc, curr) => acc + curr.length, 0);
      newData.push({
        label: trans(channelName),
        value: ((totalProfiles ?? 0) / Object.values(teamProfiles).length) * 100,
        icon: channelValues?.icon,
        key: channelName,
      });
    });

    setData(newData);
  }

  return (
    <>
      {Boolean(data?.length) && (
        <div className="w-full flex flex-col gap-2">
          {data.map((item, index) => (
            <TeamCommunicationChannelsRankingItem
              label={item.label}
              value={item.value}
              icon={item.icon}
              key={item.key}
            />
          ))}
        </div>
      )}
    </>
  );
};

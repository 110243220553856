// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCheck, IconTaskCompleter} from '@modules/Core/components/base/Icons/Icons';
import {Tab} from '@modules/Core/components/base/Tab';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {ProfileRoundExercise} from '@modules/Profile/components/exercises/round/ProfileRoundExercise';
import {ProfileRoundExerciseLocked} from '@modules/Profile/components/exercises/round/ProfileRoundExercisesLocked';
import {ProfileRoundExercisesMissingChallengesGoals} from '@modules/Profile/components/exercises/round/ProfileRoundExercisesMissingChallengesGoals';
import {JOURNAL_EXERCISES_TYPE} from '@modules/Profile/config/journalsConstants';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {
  _ExerciseData,
  _ExerciseDataEntry,
  _ProfileExercise,
  _ProfileExercisesRound,
  _ProfileExerciseTask,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {
  downloadCertificate,
  extractNameAndNumberFromExerciseId,
  serviceHasDynamicExercises,
} from '@modules/Profile/util/exercisesUtil';
import {getServiceCategory} from '@modules/Profile/util/journalUtil';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import './ProfileExercise.scss';

interface _Props {
  service: string;
  round: number;
  exerciseIndex: number | null;
  profileExercises: _ProfileExercisesRound;
  onExerciseFinished: (result: _FlowActionResult, exerciseIndex: number) => Promise<void>;
  onExerciseStartClick: (exerciseIndex: number) => Promise<void>;
  isDevMode: boolean;
  devModeExerciseName: string | null;
  devModeExerciseIndex: number | null;
  mode: 'view' | 'edit';
  setMode: (mode: 'view' | 'edit') => void;
  exercises: _ExerciseDataEntry | null;
  setExercises: (exercises: _ExerciseDataEntry | null) => void;
}

export const ProfileRoundExercises: React.FC<_Props> = ({
  service,
  round,
  exerciseIndex,
  profileExercises,
  onExerciseFinished,
  onExerciseStartClick,
  isDevMode,
  devModeExerciseName,
  devModeExerciseIndex,
  mode,
  setMode,
  exercises,
  setExercises,
}) => {
  // Exercise data
  const exercisesData = useExercises();

  // components state
  const [currentRoundExercises, setCurrentRoundExercises] = useState<
    _ExerciseData[] | Record<string, _ExerciseData> | null
  >(null);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const latestExerciseIndex = useRef<number>();
  const [latestJournalUpdate, setLatestJournalUpdate] = useState<string | null>(null);

  // Inner copy of profile exercises
  const [currentProfileExercises, setCurrentProfileExercises] = useState<_ProfileExercise[] | null>(null);
  const [hasNotLockedExercises, setHasNotLockedExercises] = useState(false);

  /**
   * Set exercises
   */
  useEffect(() => {
    if (!service || !exercisesData) {
      setExercises(null);
      return;
    }

    if (!currentProfileExercises) {
      return;
    }

    // For dynamic exercises, we have to get keys from profile exercises, and extract them.
    const exercisesDataCopy = copyObject(exercisesData[service]);
    const newCurrentExercises = exercisesDataCopy?.exercises as Record<string, _ExerciseData[]>;

    const newCurrentExerciseData: _ExerciseDataEntry = {
      exercises: [],
      rounds: [],
    };

    if (!exercisesDataCopy) {
      return;
    }

    const newExercises = [];
    if (serviceHasDynamicExercises(service)) {
      for (const exercise of currentProfileExercises || []) {
        const {name, index} = extractNameAndNumberFromExerciseId(exercise?.exercise_id) ?? {};

        if (!name || index === undefined) {
          continue;
        }

        if (!newCurrentExercises?.[name]?.[index]) {
          logger.error(`Exercise ${name} with index ${index} not found in exercises data`);
          continue;
        }
        newExercises.push(newCurrentExercises?.[name]?.[index]);
      }
      newCurrentExerciseData.exercises = newExercises;
      newCurrentExerciseData.round = exercisesDataCopy.rounds[round];
      newCurrentExerciseData.round.exercises = exercisesDataCopy.rounds[round].exercises;
    } else {
      if (isDevMode && currentProfileExercises[0]?.exercise_id) {
        const {name} = extractNameAndNumberFromExerciseId(currentProfileExercises[0]?.exercise_id)!;
        round = parseInt(name, 10);
      }

      newCurrentExerciseData.round = exercisesDataCopy.rounds[round];
      newCurrentExerciseData.exercises = newCurrentExercises?.[round];
    }

    setExercises(newCurrentExerciseData);
  }, [exercisesData, service, currentProfileExercises, round, isDevMode]);

  /**
   * Set current tab index & mode

   */
  useEffect(() => {
    if (exerciseIndex === null || exerciseIndex === undefined || !currentProfileExercises) {
      return;
    }

    // In case of redundant calls/refreshes
    if (latestExerciseIndex.current === exerciseIndex) {
      return;
    }

    setCurrentTabIndex(exerciseIndex);

    latestExerciseIndex.current = exerciseIndex;

    if (currentProfileExercises[exerciseIndex]?.status === EXERCISE_STATUS_STARTED) {
      setMode('edit');
    }
  }, [exerciseIndex, currentProfileExercises]);

  /**
   * Set current profile exercises
   */
  useEffect(() => {
    if (!profileExercises) {
      return;
    }

    let newExercises = copyObject(profileExercises?.exercises || [])!;

    if (!newExercises) {
      return;
    }
    // Non-special exercises can be viewed 3 in a row as they are statically calculated
    const devModeIndexValid =
      !serviceHasDynamicExercises(service) || (devModeExerciseIndex !== null && devModeExerciseIndex !== undefined);

    if (isDevMode && devModeIndexValid && devModeExerciseName !== null && devModeExerciseName !== undefined) {
      newExercises = newExercises.map(exercise => {
        const newExercise = copyObject(exercise)!;
        newExercise.exercise_id = `${service}_exercise_${devModeExerciseName}_${devModeExerciseIndex ?? 0}`;

        newExercise.data = newExercise.data || {};

        newExercise.data.tasks = (exercisesData?.[service]?.exercises as Record<string, _ExerciseData[]>)?.[
          devModeExerciseName
        ]?.[devModeExerciseIndex ?? 0]?.tasks;
        // for each task, init data
        newExercise.data.tasks =
          (newExercise?.data?.tasks
            ?.map(task => {
              const newTask = copyObject(task);
              if (!newTask) {
                return null;
              }
              newTask.data = (newTask.data ?? {}) as Record<string, any>;
              if (newTask.type === 'checklist') {
                newTask.data.options = (task?.options ?? []).map(option => ({
                  text: option,
                  checked: false,
                  is_default: true,
                }));
              }

              return newTask;
            })
            .filter(Boolean) as _ProfileExerciseTask[]) ?? [];

        newExercise.status = EXERCISE_STATUS_STARTED;

        return newExercise;
      });
    }

    setCurrentProfileExercises(newExercises);
    setHasNotLockedExercises(newExercises?.filter(exercise => exercise?.status !== EXERCISE_STATUS_LOCKED)?.length > 0);
  }, [profileExercises, service, round, exercisesData, devModeExerciseName, devModeExerciseIndex, isDevMode]);

  /**
   * Set current round exercises
   */
  useEffect(() => {
    if (!service || round === undefined || !exercises) {
      return;
    }
    // Check if the round exercises exists for current service
    if (exercises?.exercises) {
      const exerciseData = exercises?.exercises;
      setCurrentRoundExercises(exerciseData);
    }
  }, [round, exercises, service]);

  /**
   * Set current round exercises
   */
  useEffect(() => {
    if (!service || round === undefined || !exercises) {
      return;
    }
    // Check if the round exercises exists for current service
    if (exercises?.exercises) {
      const exerciseData = exercises?.exercises;
      setCurrentRoundExercises(exerciseData);
    }
  }, [round, exercises, service]);

  const handleCertificateDownload = async (): Promise<void> => {
    await downloadCertificate(service, round + 1);
  };

  const showExercises = currentProfileExercises && hasNotLockedExercises && currentRoundExercises;
  const Icon = getServiceIcon(service);
  return (
    <>
      {showExercises && (
        <Section
          title={trans('profile_exercises.round_title', {
            service: trans(PROFILE_SERVICES_TITLES[service]),
            round: round + 1,
          })}
          titleVariant="h6"
          titleIcon={Icon}
          journalKey={getServiceCategory(service)}
          journalMetadata={{
            service,
            roundIndex: round,
            exerciseIndex: currentTabIndex,
            exerciseTitle: (currentRoundExercises as _ExerciseData[])[currentTabIndex]?.intro?.title,
            type: JOURNAL_EXERCISES_TYPE,
          }}
          titlePrefix={
            profileExercises?.status === EXERCISE_STATUS_DONE && (
              <ToolTip txt={trans('profile_exercises.download_certificate')}>
                <IconButton
                  size="md"
                  onClick={() => {
                    void handleCertificateDownload();
                  }}
                  icon={IconTaskCompleter}
                />
              </ToolTip>
            )
          }
          onJournalClose={() => setLatestJournalUpdate(new Date().toISOString())}
          gap={5}
          containerGap={5}
        >
          <div className="flex flex-row gap-3">
            {Object.entries(currentRoundExercises || {}).map(([key, exercise], index) => (
              <div className="relative-col-width-2">
                <Tab
                  iconElement={
                    currentProfileExercises[index]?.status === EXERCISE_STATUS_DONE ? (
                      <div className="exercise-status-badge-circle">
                        <IconCheck color="fill-green700" />
                      </div>
                    ) : null
                  }
                  label={trans('profile_exercises.index', {index: index + 1})}
                  key={key}
                  active={currentTabIndex === index}
                  className={currentTabIndex === index ? 'border-green700' : ''}
                  color="green700"
                  onChange={active => {
                    if (active) {
                      if (currentProfileExercises[index]?.status === EXERCISE_STATUS_STARTED) {
                        setMode('edit');
                      } else {
                        setMode('view');
                      }
                      setCurrentTabIndex(index);
                    }
                  }}
                />
              </div>
            ))}
          </div>

          {Object.values(currentRoundExercises || {}).map(
            (exercise, exerciseIndex) =>
              exerciseIndex === currentTabIndex && (
                <ProfileRoundExercise
                  isDevMode={isDevMode}
                  latestJournalUpdate={latestJournalUpdate}
                  mode={mode}
                  setMode={setMode}
                  currentProfileExercises={currentProfileExercises}
                  setCurrentProfileExercises={setCurrentProfileExercises}
                  service={service}
                  round={round}
                  key={exercise?.intro?.title}
                  exercise={exercise}
                  exerciseIndex={exerciseIndex}
                  onExerciseFinished={onExerciseFinished}
                  onExerciseStartClick={onExerciseStartClick}
                />
              )
          )}
        </Section>
      )}

      {!currentProfileExercises ? (
        <ProfileRoundExerciseLocked />
      ) : !hasNotLockedExercises ? (
        <ProfileRoundExercisesMissingChallengesGoals service={service} />
      ) : null}
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useState} from 'react';

export function useProfileValuesState() {
  const [mode, setMode] = useState<'edit' | 'view'>('view');

  const onModeChange = (m: 'edit' | 'view'): void => {
    setMode(m);
  };

  const switchMode = (): void => {
    setMode(prev => (prev === 'edit' ? 'view' : 'edit'));
  };

  const setIsEditMode = (isEditMode: boolean): void => {
    setMode(isEditMode ? 'edit' : 'view');
  };

  return {
    mode,
    setMode: onModeChange,
    switchMode,
    setIsEditMode,
  };
}

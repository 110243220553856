// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  _ActionsTableHeaderProps,
  ActionsTableHeader,
} from '@modules/Core/components/base/table/actions/ActionsTableHeader';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {_TableFilteringOptions} from '@modules/Core/components/base/table/TableFilter';
import {Section} from '@modules/Core/components/layout/Section';

interface _ActionsTableProps {
  rows: _TableDataItem[];

  columns: _TableColumns;
  renderRow: (row: any) => _TableDataItem;

  headerProps: _ActionsTableHeaderProps;
  showTopBorder?: boolean;
  filterOptions?: _TableFilteringOptions;
}

export const ActionsTable: React.FC<_ActionsTableProps> = ({
  rows,
  headerProps,
  renderRow,
  columns,
  showTopBorder,
  filterOptions,
}) => {
  return (
    <Section>
      <Table
        data={rows}
        columns={columns}
        renderRow={renderRow}
        showTopBorder={showTopBorder}
        filterOptions={filterOptions}
        header={<ActionsTableHeader {...headerProps} />}
      />
    </Section>
  );
};

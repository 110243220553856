// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {_Institution} from '@modules/Institution/types/institution.model';
import {appState, dispatchAction} from '@modules/State/util/util';
import {changeCurrentTeam} from '@modules/Team/state/TeamSlice';

export function currentTeam(): _Institution | null {
  return appState().team?.currentTeam;
}

export function team(teamId: string) {
  return appState().teams?.currentTeams?.find(team => team.id === teamId);
}

export function setCurrentTeam(teamId: string) {
  dispatchAction(changeCurrentTeam(teamId));
}

// @ts-nocheck
/* eslint-disable */

import {_BaseStepComponentOptions} from '../types/step.model';
import {useDidFlowUpdate} from './didFlowUpdate';
import {useUpdateFlowData} from './updateFlowData';

export function useStep(
  options: _BaseStepComponentOptions,
  instanceKey: string,
  getValueCallbackFn?: () => void,
  setValueCallbackFn?: (value: any) => void,
  inputs?: any[]
): void {
  // When the local state updates, update the flow with the value of the callback
  useUpdateFlowData(options?.index, options?.name, options?.flowName, instanceKey, getValueCallbackFn, inputs);

  // When the flow updates, update the local state through the callback
  useDidFlowUpdate(options?.index, options?.name, options?.flowName, instanceKey, setValueCallbackFn);
}

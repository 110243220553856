// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {FeedbackDialog} from '@modules/Core/components/platform/dialogs/FeedbackDialog';
import {_PaymentDetailsDialogInput} from '@modules/Core/components/platform/payments/dialogs/PaymentDetailsDialog';
import {InstitutionPaymentTable} from '@modules/Core/components/platform/payments/institution/InstitutionPaymentTable';
import {useInstitutionPaymentActions} from '@modules/Core/components/platform/payments/paymentPlan/hooks/institutionPaymentActions';
import {FEEDBACK_TYPE_MEMBERSHIP_CANCELLED} from '@modules/Core/config/constants';
import {usePaymentPlans} from '@modules/Core/hooks/payments/PaymentPlans';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {changeCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {dispatchAction, refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentInstitutionProps {
  profile: _Profile | null;
  institution: _Institution | null;
  onReady: (input: _PaymentDetailsDialogInput) => void;
}

export const PaymentInstitution: React.FC<_PaymentInstitutionProps> = ({profile, institution, onReady}) => {
  const plans = usePaymentPlans();
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  // Handling actions that can be performed on selected profiles
  const {actions, profiles, chosenProfiles, onProfileSelected, canSelectProfile, onClearSelectedProfiles} =
    useInstitutionPaymentActions(
      institution,
      plans,
      (plan: _PaymentPlan) => {
        onReady({
          newPlan: plan,
          mode: 'institution',
          forProfileIds: chosenProfiles,
        });
      },
      () => setFeedbackDialogOpen(true)
    );

  const onFeedbackClose = (): void => {
    if (institution) {
      void Promise.all([refreshReduxState(), dispatchAction(changeCurrentInstitution(institution.id))]);
    }
    setFeedbackDialogOpen(false);
  };
  return (
    <>
      <FeedbackDialog
        type={FEEDBACK_TYPE_MEMBERSHIP_CANCELLED}
        open={feedbackDialogOpen}
        onClose={onFeedbackClose}
        description={trans('subscription.feedback.description')}
        title={trans('subscription.feedback.title')}
        label={trans('subscription.feedback.label')}
      />
      <InstitutionPaymentTable
        institution={institution}
        profiles={profiles}
        chosenProfiles={chosenProfiles}
        onProfileSelected={onProfileSelected}
        onClearSelectedProfiles={onClearSelectedProfiles}
        canSelectProfile={canSelectProfile}
        actions={actions}
      />
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingMetric} from '@modules/ContentEngine/types/metrics.model';
import {StatisticSectionContent} from '@modules/Statistics/components/sections/types/StatisticSectionContent';

export interface _CustomizableSectionItemProps {
  data?: _ProfileTrackingMetric;
}

export const CustomizableSectionContentItem: React.FC<_CustomizableSectionItemProps> = ({data}) => {
  // TODO: Menu and shenanigans
  return <StatisticSectionContent value={data} />;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_SERVICES_TEMPLATES} from '@modules/ContentEngine/config/constants';
import {SERVICE_PROFILE_MOTIVE_STRUCTURE} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useProfileService} from '@modules/Profile/hooks/profileService';
import {_Profile} from '@modules/Profile/types/profile.model';
import {SurveyHtmlWrapper} from '@modules/SurveyEngine/components/SurveyHtmlWrapper';

interface _ProfileResultProps {
  profile: _Profile;
  service: string;
  surveyDoneBefore: boolean;
}
// @Deprecated
export const ProfileResult: React.FC<_ProfileResultProps> = ({profile, service, surveyDoneBefore}) => {
  function motivesComponent() {
    return (
      <div>
        <SurveyHtmlWrapper type={SERVICE_PROFILE_MOTIVE_STRUCTURE} />
        <ContentEngineContainer type={CE_SERVICES_TEMPLATES[SERVICE_PROFILE_MOTIVE_STRUCTURE]} />
      </div>
    );
  }

  function body() {
    if (!profile) {
      return null;
    }

    let content = null;

    switch (service) {
      case SERVICE_PROFILE_MOTIVE_STRUCTURE:
        content = motivesComponent();
        break;
      default:
        content =
          "THIS COMPONENT IS DEPRECATED, IF YOU'RE SEEING THIS IT MEANS YOU NEED TO USE CE FOR THIS RESULT PAGE";
    }

    return content;
  }

  return <>{body()}</>;
};

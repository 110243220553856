// @ts-nocheck
/* eslint-disable */

import React, {FC, ReactNode, useState} from 'react';
import {addCN} from '@app/_core/util/cssHelper';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconExpandLess, IconExpandMore} from '@modules/Core/components/base/Icons/Icons';
import {Section} from '../layout/Section';
import {Button} from './buttons/Button';

interface _ExpandableContent {
  children?: ReactNode;
  showExpander?: boolean;
  text?: string;
  gap?: number;
}

export const ExpandableContent: FC<_ExpandableContent> = ({
  children = null,
  showExpander = true,
  text = null,
  gap = 3,
}) => {
  const [expanded, setExpanded] = useState(!showExpander);

  return (
    <Section fullWidth gap={0}>
      {showExpander && (
        <div className="flex w-full items-center justify-center">
          {text && (
            <Button
              variant="light"
              onClick={(): void => setExpanded(!expanded)}
              icon={expanded ? IconExpandLess : IconExpandMore}
            >
              {text}
            </Button>
          )}
          {!text && (
            <IconButton
              onClick={(): void => setExpanded(!expanded)}
              icon={expanded ? IconExpandLess : IconExpandMore}
            />
          )}
        </div>
      )}
      {/* bottom text area
        hacky to set max-h-1000px. But to have a smooth transition, a height needs to be set. This has a bug/feature sideeffect.
        When setting isExpanded to false, it seems, like the opacity will first animate
        and just then the height. This effect is because of the max-h-1000. Even if the element is way smaller, the transition
        still goes from 0-1000
      */}
      <div
        style={{transition: 'opacity 0.4s, max-height 0.4s'}}
        className={addCN(
          'flex w-full flex-col items-start gap-2',
          'w-80p m-auto',
          expanded ? 'visible max-h-[1000px]' : 'invisible max-h-[0px]'
        )}
      >
        <div className={showExpander ? `pt-${gap}` : ''}>{children}</div>
      </div>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_Tab, TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {TeamDevelopmentResultOverview} from '@modules/Team/components/team_development/result/TeamDevelopmentResultOverview';
import {TeamDevelopmentResultProfileAnswers} from '@modules/Team/components/team_development/result/TeamDevelopmentResultProfileAnswers';
import {TeamDevelopmentResultTeamAnswers} from '@modules/Team/components/team_development/result/TeamDevelopmentResultTeamAnswers';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {
  _TeamDevelopmentProfileAnswers,
  _TeamDevelopmentResult,
  _TeamDevelopmentSurveyData,
} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentResultItemProps {
  profileId: string;
  teamId: string;
  module?: string;
  submodule?: string;
  subSubmodule?: string;
  isPreview?: boolean;
  allProfiles: Record<string, _TeamProfile>;
  result: _TeamDevelopmentResult;
  surveyData: _TeamDevelopmentSurveyData;
  profileAnswers: _TeamDevelopmentProfileAnswers;
  initialTab?: 'overview' | 'profileResult' | 'teamAnswers';
  context: _ContentEngineContext;
  overviewTutorialKey: string;
  teamSessionTutorialKey: string;
}

export const TeamDevelopmentResultItem: React.FC<_TeamDevelopmentResultItemProps> = ({
  profileId,
  teamId,
  module,
  submodule,
  subSubmodule,
  isPreview = false,
  allProfiles,
  result,
  surveyData,
  profileAnswers,
  initialTab,
  context,
  teamSessionTutorialKey,
  overviewTutorialKey,
}) => {
  if (!module) {
    return null;
  }

  const tabs: _Tab[] = [];
  const tabsOrder: string[] = [];

  tabs.push({
    title: trans('development_result.development_overview'),
    name: 'overview',
    content: (
      <TeamDevelopmentResultOverview
        surveyData={surveyData}
        result={profileAnswers}
        teamId={teamId}
        profileId={profileId}
        module={module}
        allProfiles={allProfiles}
        context={context}
        tutorialKey={overviewTutorialKey}
      />
    ),
  });

  tabsOrder.push('overview');

  tabs.push({
    title: trans('development_result.team_answers.title'),
    name: 'teamAnswers',
    content: (
      <TeamDevelopmentResultTeamAnswers
        profiles={allProfiles}
        teamId={teamId}
        module={module}
        answers={profileAnswers}
        tutorialKey={teamSessionTutorialKey}
        context={context}
      />
    ),
  });

  tabsOrder.push('teamAnswers');

  if (!isPreview) {
    tabs.push({
      title: trans('development_result.answers'),
      name: 'profileResult',
      content: (
        <TeamDevelopmentResultProfileAnswers
          allProfiles={allProfiles}
          context={context}
          teamId={teamId}
          module={module}
          answers={profileAnswers}
        />
      ),
    });
    tabsOrder.push('profileResult');
  }

  return <TabsItem activeTab={initialTab ? tabsOrder.findIndex(tab => tab === initialTab) : 0} tabs={tabs} />;
};

// @ts-nocheck
/* eslint-disable */

/**
 * Mapper
 */
import {copyObject} from '@modules/Core/util/util';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';

export function mapQuestion(question: _SurveyQuestion): _SurveyQuestion | null {
  const questionCopy = copyObject(question);
  if (!questionCopy) return questionCopy;

  if (questionCopy.color) {
    try {
      questionCopy.color = JSON.parse(questionCopy.color);
    } catch (e) {
      questionCopy.color = undefined;
    }
  }

  return questionCopy;
}

// @ts-nocheck
/* eslint-disable */

/**
 * Institution Billing Info
 */

export const INSTITUTION_BILLING_INFO = (institutionId: string): string => `institution/${institutionId}/billing-info`;
export const INSTITUTION_CAN_JOIN = 'institution/can-join-institution';

/**
 * Institution
 */

export const INSTITUTION_DATA = 'institution';
export const INSTITUTION = 'institution';

/**
 * Institution Data Collection for AM Charts
 */

export const INSTITUTION_DATA_COLLECTION = 'institution/dataCollection';

/**
 * Institution Request Upgrade
 */

export const INSTITUTION_REQUEST_UPGRADE = 'institution/email/request-upgrade';

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';

interface _Title {
  title: string;
  icon?: React.ElementType;
}

export const JournalTitle: React.FC<_Title> = props => {
  const {title} = props;

  return (
    <div className="flex gap-1 items-center">
      {props?.icon ? <props.icon size="lg" /> : null}
      <Typography bold>{title}</Typography>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {DatePickerContext} from '../DatePickerProvider';
import {addYears, startOfYearPeriod} from '../utils';

const Decades = (): JSX.Element => {
  const {selectedDates, showSelectedDate, changeSelectedDate, setView, getFormattedDate, options} =
    useContext(DatePickerContext);
  return (
    <div className="grid w-64 grid-cols-4">
      {[...Array(12)].map((_year, index) => {
        const first = startOfYearPeriod(selectedDates[0], 100);
        const year = first - 10 + index * 10;
        return (
          <span
            key={index}
            className={`hover:bg-gray-100 dark:hover:bg-gray-600 block flex-1 leading-9 border-0 rounded-lg cursor-pointer text-center  dark:text-white font-semibold text-sm ${
              showSelectedDate &&
              selectedDates[0].getTime() > 0 &&
              Number(getFormattedDate(selectedDates[0], {year: 'numeric'})) === year
                ? twMerge('bg-blue-700 text-white hover:bg-blue-600', options?.theme?.selected)
                : ''
            } ${index == 0 || index == 11 ? twMerge('text-gray-500', options?.theme?.disabledText) : twMerge('text-gray-900', options?.theme?.text)}
                            ${options?.minDate && year < Number(getFormattedDate(options?.minDate, {year: 'numeric'})) ? twMerge('text-gray-500', options?.theme?.disabledText) : ''}
                            ${options?.maxDate && year > Number(getFormattedDate(options?.maxDate, {year: 'numeric'})) ? twMerge('text-gray-500', options?.theme?.disabledText) : ''}
                            `}
            onClick={() => {
              changeSelectedDate('date', new Date(addYears(selectedDates[0], year - selectedDates[0].getFullYear())));
              setView('years');
            }}
          >
            {year}
          </span>
        );
      })}
    </div>
  );
};

export default Decades;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Typography} from '@modules/Core/components/base/Typography';

interface _BodyProps {
  Icon: React.ElementType | null;
  label: string;
  minified?: boolean;
  badge?: string;
}

export const SoftfactTabBody: React.FC<_BodyProps> = ({badge, minified, Icon, label}) => {
  return (
    <>
      {Icon && <Icon size="md" />}
      <Typography variant="body" bold>
        {label}
      </Typography>

      <div
        className={twMerge(
          'absolute',
          'opacity-10',
          minified ? '-right-3 -bottom-3 relative-col-width-1' : '-right-7 -bottom-7 relative-col-width-1-5'
        )}
      >
        {Icon && <Icon size="w-full h-full" color={badge === 'finished' && 'fill-[#00CC7E]'} />}
      </div>
    </>
  );
};

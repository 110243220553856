// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {copyObject, uuid} from '@modules/Core/util/util';
import {DEFAULT_FLOW_OPTIONS, DEFAULT_STEP_OPTIONS} from '../../config/flowConstants';
import {_FlowInput, _FlowSchema} from '../../types/core.model';
import {_Flow} from '../../types/flow.model';
import {_FlowStep} from '../../types/step.model';
import {FlowEvaluator} from '../evaluator/flowEvaluator';

export function loadFlow(flowSchemaOriginal: _FlowSchema, flowInput: _FlowInput, flowEvaluator: FlowEvaluator): _Flow {
  if (!flowSchemaOriginal) {
    throw new Error('Flow schema is not defined');
  }

  let input = copyObject(flowInput) ?? {};

  const id = uuid();
  const flowSchema = copyObject(flowSchemaOriginal);

  logger.debug(`[FlowLoader] loadFlow with generated id: ${id}, flow: ${flowSchemaOriginal.name}`);
  // To create a copy

  if (!flowSchema) {
    throw new Error('[FlowLoader] Flow schema is not defined');
  }

  const {name} = flowSchema;

  const steps = loadSteps(name, flowSchema.steps, flowInput, flowEvaluator);

  let options = flowEvaluator.evaluateObjectFieldsInternal(flowSchema.options, undefined, flowInput, undefined, [
    'onFinish',
  ]);

  // Fill with default options for missing values
  options = {...DEFAULT_FLOW_OPTIONS, ...options};

  const {actions} = flowSchema;

  const expectedInput = flowSchema.input;

  if (expectedInput) {
    for (const key of Object.keys(expectedInput)) {
      // Starting with "?" means that the input is optional
      if ((input?.[key] === null || input?.[key] === undefined) && key[0] !== '?') {
        if (!input) {
          input = {};
        }
        // Check if default value is provided
        if (expectedInput[key].defaultValue === null || expectedInput[key].defaultValue === undefined) {
          throw new Error(`[FlowLoader] Flow ${name} expects input ${key} but it was not provided`);
        } else {
          input[key] = flowEvaluator.evaluateObjectFieldsInternal(
            {value: expectedInput[key].defaultValue},
            undefined,
            undefined,
            undefined
          )?.value;
        }
      }
    }
  }

  // Sets the index field in each step with the index of the step in the steps list
  // This index will practically be the "ID" of the step
  Object.keys(steps).forEach((stepName, index) => {
    steps[stepName].index = index;
    steps[stepName].isLast = index === Object.keys(steps).length - 1;
    steps[stepName].stepName = stepName;
  });

  return {
    id,
    name,
    steps,
    options,
    actions,
    input,
  };
}

function loadSteps(
  flowName: string,
  steps: Record<string, _FlowStep>,
  flowInput: _FlowInput,
  flowEvaluator: FlowEvaluator
): Record<string, _FlowStep> {
  const result: Record<string, _FlowStep> = {};

  for (const stepName of Object.keys(steps)) {
    result[stepName] = steps[stepName];
    result[stepName] = loadStep(flowName, result[stepName], flowInput, flowEvaluator);
  }
  return result;
}

function loadStep(flowName: string, step: _FlowStep, flowInput: _FlowInput, flowEvaluator: FlowEvaluator): _FlowStep {
  step.options = flowEvaluator.evaluateObjectFieldsInternal(step.options, undefined, flowInput);

  step.options = {...DEFAULT_STEP_OPTIONS, ...step.options};

  step.navigation = step.navigation
    ? flowEvaluator.evaluateObjectFieldsInternal(step.navigation ?? '', undefined, flowInput)
    : undefined;

  step.flowName = flowName;

  return step;
}

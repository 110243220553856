// @ts-nocheck
/* eslint-disable */

import React from 'react';

export const SurveyQuoteContent = ({contentGroup}) => (
  <>
    {(contentGroup?.elements || []).map((el, i) => (
      <blockquote className={`inline-block max-w-lg ${el?.class || ''}`} key={i}>
        {el?.quote}
        <cite className="block mt-1 text-sm opacity-75">-{el?.author}</cite>
      </blockquote>
    ))}
  </>
);

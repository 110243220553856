// @ts-nocheck
/* eslint-disable */

import {AbstractValidator} from './abstractValidator';

export class RequiredValidator extends AbstractValidator {
  validate(value: any): boolean {
    return !this.options.value || (value !== null && value !== undefined && value !== '');
  }
}

// @ts-nocheck
/* eslint-disable */

import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {startTrialRequest} from '@modules/Core/services/api/payment';
import {_Address} from '@modules/Core/types/address.model';
import {_PaymentPlan, _PaymentPriceDetails} from '@modules/Core/types/payment.model';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {actionCalculateSubscription, actionCreatePayLink} from '@modules/Core/util/payment/paymentActionsUtil';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

export async function onCreatePayLink(
  profileId: string,
  billingInterval: _SubscriptionBillingInterval,
  planId: string,
  profileIds: string[],
  address: _Address,
  type: 'profile' | 'institution',
  isDiscount: boolean
) {
  const result = await actionCreatePayLink(profileId, billingInterval, planId, profileIds, address, type, isDiscount);
  logger.debug('createPayLink', result);

  if (result?.status === ACTION_STATUS_SUCCESS && result?.data?.link) {
    window.location.href = result.data?.link;
  }
}

export async function onSendRequestToUpgradeEmail(
  profile: _Profile | null,
  plan: _PaymentPlan,
  snackbar: any
): Promise<void> {
  if (!profile) {
    return;
  }
  try {
    const response = await performFlowAction(
      {
        method: 'subscription.upgrade_request.send',
        params: {
          profileId: profile.id,
          planId: plan.id,
        },
      },
      {
        refetchProfile: true,
        loadingIndicator: true, // TODO @Sherif Really?
      }
    );

    if (response?.data?.status === ACTION_STATUS_SUCCESS) {
      snackbar.success(trans(response?.data?.message));
      reloadContentEngine();
    } else {
      snackbar.danger(trans(response?.data?.message));
    }
  } catch (e) {
    // handled by AuthTokenInterceptor => errorMessage()
  }
}

export async function onSendStartTrialRequestEmail(snackbar: any): Promise<void> {
  try {
    const response = await startTrialRequest();

    if (response.status === 200 && response?.data?.status === 'success') {
      snackbar.success(trans('email.email_sent_successfully'));
      reloadContentEngine();
    }
  } catch (e) {
    // handled by AuthTokenInterceptor => errorMessage()
  }
}

/**
 *   // TODO @Sherif types
 *   paymentDetails: any;
 *   mode: _PaymentMode;
 * @param billingInterval
 * @param institutionId
 * @param isDiscount
 */
export async function fetchUpcomingPrice(
  profileId: string,
  billingInterval: _SubscriptionBillingInterval,
  planId: string,
  profileIds: string[],
  institutionId: string,
  isDiscount: boolean
): Promise<_PaymentPriceDetails | null> {
  if (!profileId || !institutionId || !planId || !billingInterval) {
    return null;
  }
  const response = await actionCalculateSubscription({
    profileId,
    billingInterval,
    planId,
    profileIds,
    institutionId,
    isDiscount,
  });

  if (response?.status === ACTION_STATUS_SUCCESS) {
    return response?.data as _PaymentPriceDetails;
  }
  return null;
}

// @ts-nocheck
/* eslint-disable */

import React, {FC, useEffect, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {ValuesCard} from '@modules/Core/components/base/cards/ValueCard';
import {SortableItem} from '@modules/Core/components/base/dnd/SortableItem';
import {IconStarEmpty, IconStarFilled, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {ValuesGem} from '@modules/Profile/components/services/result/values/ValuesGem';
import {_TeamValuesEntry} from '@modules/Team/types/teamanalysis.model';

interface _ProfileValuesDnDCardProps {
  value: _TeamValuesEntry;
  onSelect?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
}

export const TeamValuesDnDCard: FC<_ProfileValuesDnDCardProps> = ({
  value,
  disabled = false,
  onSelect = null,
  onDelete = null,
}) => {
  const [description, setDescription] = useState<string | null>(null);

  useEffect(() => {
    setDescription(value.description);
  }, [value]);
  return (
    <SortableItem disabled={disabled} key={value.id} id={value.id}>
      <ValuesCard
        value={value}
        showDescription={disabled}
        valueScoreComponent={<ValuesGem className="justify-end" points={value.points} />}
      >
        {Boolean(!disabled) && (
          <>
            <TextArea value={description} onChange={e => setDescription(e.target.value)} />
            <div className="flex flex-row gap-1 justify-end w-full items-center">
              <IconButton onClick={onDelete} icon={IconTrash} />
              <IconButton onClick={onSelect} icon={value.highlighted ? IconStarFilled : IconStarEmpty} />
            </div>
          </>
        )}
      </ValuesCard>
    </SortableItem>
  );
};

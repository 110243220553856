// @ts-nocheck
/* eslint-disable */

import {navigateToFlow} from '@/_core/util/util';
import {httpClient} from '@modules/Core/services/HttpClient';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {LOGIN, LOGOUT} from '../paths';

export async function login(email: string, password: string, remember?: boolean): Promise<any> {
  return await httpClient.post(LOGIN, {
    email,
    password,
    remember,
  });

  // return window.loadRequest(async () =>
  //   await httpClientUnauthenticated.post(LOGIN, {
  //     email,
  //     password,
  //     remember,
  //   }),
  // );
}

export async function logout(): Promise<any> {
  return await httpClient.post(LOGOUT);
}

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';
import {CURRENT_TEAMS, TEAM} from './paths';

async function getCurrentTeams(loadingIndicator = true): Promise<any> {
  return await httpClient.get(CURRENT_TEAMS, loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {});
}

async function getTeamById(teamId: string): Promise<any> {
  return await httpClient.get(`${TEAM}/${teamId}`);
}

export {getCurrentTeams, getTeamById};

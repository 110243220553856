// @ts-nocheck
/* eslint-disable */

import {
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_POTENTIALS,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_TRUST,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_TEAM_DEVELOPMENT,
  SERVICE_TEAM_MOTIVES,
  SERVICE_TEAM_ROLES,
  SERVICE_TEAM_VALUES,
  SERVICE_TEAM_WAY_OF_WORKING,
  THIRD_PERSON_FEEDBACK,
} from '@modules/Core/config/services';
import {
  MOTIVE_STRUCTURE_FLOW,
  PERSONALITY_ANALYSIS_FLOW,
  PROFILE_COMMUNICATION_FLOW,
  PROFILE_EMOTIONAL_INTELLIGENCE_FLOW,
  PROFILE_LEARNING_FLOW,
  PROFILE_MINDFULNESS_FLOW,
  PROFILE_MOTIVATION_FLOW,
  PROFILE_PROACTIVITY_FLOW,
  PROFILE_RESILIENCE_FLOW,
  PROFILE_RESPONSIBILITY_FLOW,
  PROFILE_SATISFACTION_AND_PERFORMANCE_FLOW,
  PROFILE_SELF_EFFICACY_FLOW,
  PROFILE_TRUST_FLOW,
  ROLES_FLOW,
  TEAM_ANALYSIS_FLOW,
  TEAM_DEVELOPMENT_FLOW,
  THIRD_PERSON_FEEDBACK_FLOW,
  VALUES_FLOW,
  WAY_OF_WORKING_FLOW,
} from './flowNames';

export const surveyFlows: Record<string, {name: string; isTeam?: boolean}> = {
  [`${SERVICE_PROFILE_BASE_AND_ANALYSIS}`]: {name: PERSONALITY_ANALYSIS_FLOW},
  [`${SERVICE_PROFILE_ROLES}`]: {name: ROLES_FLOW},
  [`${SERVICE_PROFILE_WAY_OF_WORKING}`]: {name: WAY_OF_WORKING_FLOW},
  [`${SERVICE_PROFILE_VALUES}`]: {name: VALUES_FLOW},
  [`${SERVICE_PROFILE_MOTIVE_STRUCTURE}`]: {name: MOTIVE_STRUCTURE_FLOW},

  // New Soft Facts
  [`${SERVICE_PROFILE_MOTIVATION}`]: {name: PROFILE_MOTIVATION_FLOW},
  [`${SERVICE_PROFILE_COMMUNICATION}`]: {name: PROFILE_COMMUNICATION_FLOW},
  [`${SERVICE_PROFILE_TRUST}`]: {name: PROFILE_TRUST_FLOW},
  [`${SERVICE_PROFILE_RESPONSIBILITY}`]: {name: PROFILE_RESPONSIBILITY_FLOW},
  [`${SERVICE_PROFILE_PROACTIVITY}`]: {name: PROFILE_PROACTIVITY_FLOW},
  [`${SERVICE_PROFILE_SELF_EFFICACY}`]: {name: PROFILE_SELF_EFFICACY_FLOW},
  [`${SERVICE_PROFILE_RESILIENCE}`]: {name: PROFILE_RESILIENCE_FLOW},
  [`${SERVICE_PROFILE_MINDFULNESS}`]: {name: PROFILE_MINDFULNESS_FLOW},
  [`${SERVICE_PROFILE_LEARNING}`]: {name: PROFILE_LEARNING_FLOW},
  [`${SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE}`]: {name: PROFILE_EMOTIONAL_INTELLIGENCE_FLOW},

  // Satisfaction and Performance
  [`${SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE}`]: {name: PROFILE_SATISFACTION_AND_PERFORMANCE_FLOW},

  // Team Services
  [`${SERVICE_PROFILE_POTENTIALS}`]: {name: TEAM_DEVELOPMENT_FLOW, isTeam: true},
  [`${SERVICE_TEAM_DEVELOPMENT}`]: {name: TEAM_DEVELOPMENT_FLOW, isTeam: true},
  [`${SERVICE_TEAM_WAY_OF_WORKING}`]: {name: TEAM_ANALYSIS_FLOW, isTeam: true},
  [`${SERVICE_TEAM_VALUES}`]: {name: TEAM_ANALYSIS_FLOW, isTeam: true},
  [`${SERVICE_TEAM_ROLES}`]: {name: TEAM_ANALYSIS_FLOW, isTeam: true},
  [`${SERVICE_TEAM_MOTIVES}`]: {name: TEAM_ANALYSIS_FLOW, isTeam: true},
  [`${THIRD_PERSON_FEEDBACK}`]: {name: THIRD_PERSON_FEEDBACK_FLOW},
};

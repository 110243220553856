// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {MeasuringStrategy} from '@dnd-kit/core';
import {verticalListSortingStrategy} from '@dnd-kit/sortable';
import {addCN} from '@/_core/util/cssHelper';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {DnDContext} from '@modules/Core/components/base/dnd/DnDContext';
import {useDnDLists} from '@modules/Core/components/base/dnd/dndLists';
import {Draggable} from '@modules/Core/components/base/dnd/Draggable';
import {Droppable} from '@modules/Core/components/base/dnd/Droppable';
import {SortableContext} from '@modules/Core/components/base/dnd/SortableContext';
import {IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Group} from '@modules/Core/components/layout/Group';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {NewProfileValuesDialog} from '@modules/Profile/components/services/result/values/NewProfileValuesDialog';
import {ProfileValuesActions} from '@modules/Profile/components/services/result/values/ProfileValuesActions';
import {ProfileValuesDnDCard} from '@modules/Profile/components/services/result/values/ProfileValuesDnDCard';
import {ProfileValuesHeader} from '@modules/Profile/components/services/result/values/ProfileValuesHeader';
import {ValuesGem} from '@modules/Profile/components/services/result/values/ValuesGem';
import {ValuesGemBag} from '@modules/Profile/components/services/result/values/ValuesGemBag';
import {useProfileValues} from '@modules/Profile/hooks/profileValues';
import {useProfileValuesApiActions} from '@modules/Profile/hooks/profileValuesApiActions';
import {GEM_BAG_ID, GEMS_TYPE, useProfileValuesDnDActions} from '@modules/Profile/hooks/profileValuesDnDActions';
import {useProfileValuesState} from '@modules/Profile/hooks/profileValuesState';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {_ProfileValues} from '@modules/Profile/types/service.model';
import {isGem, isGemBag} from '@modules/Profile/util/profileValuesUtil';
import {trans} from '@modules/Translations/util/i18n';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';

interface _Props {
  data: _ProfileValues;
  journalKey: string;
  tutorialKey: string;
  tutorialService: string;
  profileId: string;
  serviceStatus: _ServiceStatus;
  profileExercises: _ProfileExerciseCE;
  view?: 'shared' | 'result';
  context: _ContentEngineContext;
}

export interface _ProfileValueAnimation {
  // Can be isGem() or isGemBag()
  itemId: string;
  created_at?: string;
  duration?: number;
}

const ALL_VALUES_ID = 'all';
const SELECTED_VALUES_ID = 'selected';
export const ProfileValuesItem: React.FC<_Props> = ({
  view = 'result',
  serviceStatus,
  profileExercises,
  profileId,
  data,
  journalKey,
  tutorialKey,
  tutorialService,
  context,
}) => {
  const {
    profileValues,
    allValues,
    setAllValues,
    setProfileValues,
    availablePoints,
    originalProfileValues,
    customValues,
    onNewItemCreated,
    onDeleteItem,
  } = useProfileValues(profileId, data);

  const {mode, switchMode, setMode, setIsEditMode} = useProfileValuesState();
  const [onSave] = useProfileValuesApiActions(
    allValues,
    profileValues,
    originalProfileValues,
    setIsEditMode,
    profileId
  );
  const [currentAnimations, setCurrentAnimations] = useState<_ProfileValueAnimation[]>([]);
  const isSharedView = view === 'shared';
  const isDisabled = mode === 'view' || isSharedView;
  const profileValuesDnDList = useDnDLists(ALL_VALUES_ID, allValues, setAllValues);
  const selectedValuesDnDList = useDnDLists(SELECTED_VALUES_ID, profileValues, setProfileValues);
  const [onDragEnd, onItemGemClick] = useProfileValuesDnDActions(
    availablePoints,
    allValues,
    setAllValues,
    profileValues,
    setProfileValues,
    currentAnimations,
    setCurrentAnimations,
    isDisabled
  );

  const [showNewDialog, setShowNewDialog] = useState<boolean>(false);

  const onCreateNewValue = () => {
    setShowNewDialog(true);
  };

  const showNewValueButton = mode === 'edit' && customValues?.length <= 0;

  useEffect(() => {
    // Switch to edit mode if values are still empty
    if (!isSharedView && (!data || data.length === 0)) {
      setMode?.('edit');
    }
  }, [isSharedView, data]);

  const getAnimationsByItemId = (itemId: string) => {
    return currentAnimations.filter(animation => animation.itemId === itemId);
  };

  logger.debug('ProfileValuesItem', {
    allValues,
    profileValues,
    customValues,
    availablePoints,
    isShareMode: isSharedView,
    view,
    currentAnimations,
  });
  return (
    <>
      {!isSharedView && (
        <NewProfileValuesDialog
          onItemCreated={onNewItemCreated}
          open={showNewDialog}
          onClose={() => setShowNewDialog(false)}
        />
      )}
      <Group>
        <ProfileValuesHeader
          mode={mode}
          tutorialKey={tutorialKey}
          tutorialService={tutorialService}
          tutorialProps={{
            tutorialKey,
            tutorials: context.tutorials,
            id: getTutorialTriggerId(tutorialService),
          }}
          journalKey={journalKey}
          serviceStatus={serviceStatus}
          profileExercises={profileExercises}
          view={view}
        />
        <Section>
          <DnDContext
            options={{
              activationConstraint: {
                distance: 10,
              },
            }}
            measuring={{droppable: {strategy: MeasuringStrategy.WhileDragging}}}
            onDragEnd={onDragEnd}
            lists={[profileValuesDnDList, selectedValuesDnDList]}
            renderOverlay={(activeItemId: string) => {
              if (isGemBag(activeItemId) || isGem(activeItemId)) {
                return (
                  <div className="relative w-full h-full flex justify-center items-center">
                    <ValuesGem className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center" />
                  </div>
                );
              }

              // if is item, find item
              if (activeItemId) {
                const item =
                  allValues.find(item => item.id === activeItemId) ??
                  profileValues.find(item => item.id === activeItemId);
                if (item) {
                  return <ProfileValuesDnDCard key={item.id} value={item} source="all" />;
                }
              }

              // Returning null signals the default overlay should be rendered
              return null;
            }}
          >
            <div className={addCN('flex flex-row gap-3', isSharedView ? 'justify-center' : '')}>
              {!isSharedView && (
                <div className="relative-col-width-5 flex flex-col">
                  {mode === 'edit' && (
                    <>
                      <SortableContext
                        items={allValues ?? []}
                        id={ALL_VALUES_ID}
                        strategy={verticalListSortingStrategy}
                      >
                        <Droppable disabled={isDisabled} id={ALL_VALUES_ID}>
                          <Section
                            fullWidth
                            className="min-h-[300px]"
                            title={trans('personal_values.individual_values_selection_list_title')}
                            // To keep same height to the other section
                            titlePrefix={<ValuesGemBag hidden />}
                            titleVariant="h6"
                          >
                            {allValues?.map(value => (
                              <ProfileValuesDnDCard
                                onDelete={onDeleteItem}
                                disabled={isDisabled}
                                key={value.id}
                                value={value}
                                source="all"
                              />
                            ))}
                          </Section>
                        </Droppable>
                      </SortableContext>
                      {showNewValueButton && (
                        <div className="flex flex-row justify-start">
                          <IconButton onClick={onCreateNewValue} icon={IconAdd} />
                        </div>
                      )}
                    </>
                  )}
                  {mode === 'view' && (
                    <div className="w-full h-full flex justify-center items-center">
                      <img src="/assets/img/design_images/your values.png" />
                    </div>
                  )}
                </div>
              )}
              <div className="relative-col-width-5 items-center">
                <SortableContext
                  disabled={isDisabled}
                  items={profileValues ?? []}
                  id={SELECTED_VALUES_ID}
                  strategy={verticalListSortingStrategy}
                >
                  <Droppable className="h-full" disabled={isDisabled} id={SELECTED_VALUES_ID}>
                    <Section
                      fullWidth
                      fullHeight
                      className="h-full"
                      title={trans('personal_values.your_values_title')}
                      titlePrefix={
                        !isSharedView ? (
                          <Droppable disabled={isDisabled} id={GEM_BAG_ID} accepts={[GEMS_TYPE]}>
                            <Draggable hasDragOverlay disabled={isDisabled} id={GEM_BAG_ID} type={GEMS_TYPE}>
                              <ValuesGemBag
                                animation={getAnimationsByItemId(GEM_BAG_ID)[0] ?? undefined}
                                points={availablePoints}
                              />
                            </Draggable>
                          </Droppable>
                        ) : null
                      }
                      titleVariant="h6"
                    >
                      {profileValues?.map(value => (
                        <ProfileValuesDnDCard
                          disabled={isDisabled}
                          onGemClick={onItemGemClick}
                          key={value.id}
                          value={value}
                          source="selected"
                          animations={getAnimationsByItemId(value.id)}
                        />
                      ))}
                    </Section>
                  </Droppable>
                </SortableContext>
              </div>
            </div>
            <ProfileValuesActions view={view} onSave={onSave} switchMode={switchMode} mode={mode} />
          </DnDContext>
        </Section>
      </Group>
    </>
  );
};

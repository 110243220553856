// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';

export const useProfilePayment = (
  plans: _PaymentPlan[],
  profile: _Profile | null,
  institution: _Institution | null
): {
  currentPlanIndex: number | null;
  currentSubscription: _Subscription | null;
  toBeActivatedSubscription: _Subscription | null;
  toBeCancelledSubscription: _Subscription | null;
} => {
  const [currentPlanIndex, setCurrentPlanIndex] = useState<number | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<_Subscription | null>(null);
  const [toBeCancelledSubscription, setToBeCancelledSubscription] = useState<_Subscription | null>(null);
  const [toBeActivatedSubscription, setToBeActivatedSubscription] = useState<_Subscription | null>(null);

  useEffect(() => {
    setCurrentSubscription(profile?.subscriptionData?.subscription ?? null);
    setToBeCancelledSubscription(profile?.subscriptionData?.toBeCancelledSubscription ?? null);
    setToBeActivatedSubscription(profile?.subscriptionData?.toBeActivatedSubscription ?? null);
  }, [profile]);

  useEffect(() => {
    const currentProfilePlanId = profile?.subscriptionData?.planId;
    if (!plans || !currentProfilePlanId) {
      setCurrentPlanIndex(null);
      return;
    }

    const index = plans.findIndex(plan => plan.id === currentProfilePlanId);

    setCurrentPlanIndex(index);
  }, [profile, plans]);

  return {
    currentPlanIndex,
    currentSubscription,
    toBeActivatedSubscription,
    toBeCancelledSubscription,
  };
};

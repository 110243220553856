// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../HttpClient';
import {
  CANCEL_SUBSCRIPTION_STATUS,
  CHANGE_SUBSCRIPTION_STATUS,
  CHECKOUT_SESSION,
  START_TRIAL_REQUEST,
  SUBSCRIPTION_PLANS,
} from '../paths';

let subscriptionPlansCache: any = null;

async function getCheckoutSession(id: string): Promise<any> {
  return await httpClient.get(CHECKOUT_SESSION(id));
}

async function getSubscriptionPlans(): Promise<any> {
  if (!subscriptionPlansCache) {
    subscriptionPlansCache = await httpClient.get(SUBSCRIPTION_PLANS, {
      headers: {
        'X-Show-Loader': true,
      },
    });
  }
  return subscriptionPlansCache;
}

async function cancelSubscriptionStatus(subscriptionId: string, type: string, chosenProfiles: string[]) {
  return await httpClient.post(`${CANCEL_SUBSCRIPTION_STATUS}/${subscriptionId}`, {
    type,
    chosenProfiles,
  });
}

async function changeSubscriptionStatus(subscriptionId: string, body: any): Promise<any> {
  return await httpClient.post(`${CHANGE_SUBSCRIPTION_STATUS}/${subscriptionId}`, body);
}

async function startTrialRequest(): Promise<any> {
  return await httpClient.post(START_TRIAL_REQUEST);
}

export {
  getCheckoutSession,
  getSubscriptionPlans,
  cancelSubscriptionStatus,
  changeSubscriptionStatus,
  startTrialRequest,
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {TeamDevelopmentModuleOverview} from '@modules/Team/components/team_development/modules_overview/TeamDevelopmentModuleOverview';
import {TeamDevelopmentOverview} from '@modules/Team/components/team_development/overview/TeamDevelopmentOverview';
import {_TeamDevelopmentSatisfactionPerformance} from '@modules/Team/types/teamdevelopment.model';

interface _TeamDevelopmentProps {
  teamId: string;
  profileId: string;
  module?: string;
  preview: boolean;
  profiles: Record<string, _Profile>;
  modulesData: Record<string, any>;
  satisfactionPerformance: _TeamDevelopmentSatisfactionPerformance;
  context: _ContentEngineContext;
  overviewTutorialKey?: string;
  moduleTutorialKey?: string;
}

const TeamDevelopmentOverviewItem: React.FC<_TeamDevelopmentProps> = ({
  profileId,
  teamId,
  module = null,
  preview = false,
  profiles,
  modulesData,
  satisfactionPerformance,
  context,
  overviewTutorialKey,
  moduleTutorialKey,
}) => {
  if (!teamId) {
    return null;
  }
  logger.debug('TeamDevelopmentOverviewItem', {teamId, profileId, module, preview, profiles, modulesData, context});

  // Main page
  if (!module) {
    return (
      <TeamDevelopmentOverview
        satisfactionPerformance={satisfactionPerformance}
        modulesData={modulesData}
        profiles={profiles}
        teamId={teamId}
        preview={preview}
        context={context}
        tutorialKey={overviewTutorialKey}
      />
    );
  }

  return (
    <TeamDevelopmentModuleOverview
      modulesData={modulesData}
      profiles={profiles}
      module={module}
      teamId={teamId}
      profileId={profileId}
      preview={preview}
      context={context}
      tutorialKey={moduleTutorialKey}
    />
  );
};
export default TeamDevelopmentOverviewItem;

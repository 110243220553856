// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {kebabize} from '@modules/Core/util/strings';
import {createPathFromFlowInput} from '@modules/FlowEngine/util/helpers/util';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';

export interface _NavigateParams {
  teamId?: string;

  [key: string]: any;
}

/**
 * @returns A function that navigates to a flow with the given name and params.
 */
export function useNavigateToFlow(): (flowName: string, params?: _NavigateParams, kebabized?: boolean) => void {
  const {navigate} = useAppNavigate();

  function navigateToFlow(
    flowName: string,
    params?: _NavigateParams,
    stepName: string | null = null,
    kebabized = false
  ): void {
    const kebabizedFlowName = kebabized ? flowName : kebabize(flowName);

    logger.info(`[NavigateToFlow] Navigating to flow: ${kebabizedFlowName}`, ' with params', params);

    let path;

    if (params?.teamId) {
      path = `/flow/team/${params.teamId}/${kebabizedFlowName}`;
      logger.info('[NavigateToFlow] Team flow detected, navigating to team flow page.');
      delete params.teamId;
    } else {
      path = `/flow/${kebabizedFlowName}`;
    }

    if (stepName) {
      const kebabizedStepName = kebabized ? stepName : kebabize(stepName);
      path = `${path}/${kebabizedStepName}`;
    }

    navigate(path, createPathFromFlowInput(params));
  }

  return navigateToFlow;
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {trans} from '@modules/Translations/util/i18n';

interface _ConsentDialogProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  type: 'profile' | 'team';
}

const ConsentDialog: React.FC<_ConsentDialogProps> = ({show, onConfirm, onCancel, type}) => {
  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="cookie-banner"
      dusk="consent-container"
      title={type === 'team' ? trans('consents.team_title') : trans('consents.profile_title')}
      titleVariant="body1"
      onConfirm={onConfirm}
      confirmLabel={trans('base.comply')}
      onCancel={onCancel}
      cancelLabel={trans('base.decline')}
      cancelProps={{
        variant: 'primary',
      }}
      size="lg"
      forceScrollDown
      justifyContent="start"
    >
      <div
        className="flex flex-col gap-3"
        dangerouslySetInnerHTML={{
          __html: type === 'team' ? trans('consents.team_consent') : trans('consents.profile_consent'),
        }}
      />
    </Dialog>
  );
};

export default ConsentDialog;

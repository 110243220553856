// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {Typography} from '@modules/Core/components/base/Typography';
import {convertKey} from '@modules/SurveyEngine/util/questionUtil';

export const SurveyRangeDefinitionType = ({question, onValueChosen, answers}) => {
  const questionKey = convertKey(question?.key);

  return (
    <table className="survey-range w-full">
      <thead>
        <tr>
          <th />
          {Object.keys(question?.answers2).map((answer2, key) => (
            <th className="py-2 text-center text-gray-800 label dark:text-gray-200" key={key}>
              <Typography>{question?.answers2[answer2]}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(question?.answers).map(key1 => {
          const answer1 = question?.answers[key1];
          const key1Index = Object.keys(question?.answers).indexOf(key1);

          return (
            <tr>
              <th className="py-2 pr-4 text-left">{answer1}</th>
              {Object.keys(question?.answers2).map(key2 => {
                const key2Index = Object.keys(question?.answers2).indexOf(key2);
                const key = `${key1Index}_${key2Index}`;
                const checked = answers[questionKey]?.[key];

                return (
                  <td
                  // onMouseOver="handleMouseOver(event, '{{ $key1 }}', '{{ $key2 }}')"
                  // onMouseDown="handleMouseClicked(event, '{{ $key1 }}', '{{ $key2 }}')"
                  >
                    <div className="flex flex-row justify-center">
                      <CheckBox
                        onChange={checked =>
                          onValueChosen(
                            question,
                            {
                              key1Index,
                              key2Index,
                              checked,
                            },
                            true
                          )
                        }
                        name={`${question?.key}[${key1}${key2}]`}
                        id={`${key1}_${key2}`}
                        active={checked}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

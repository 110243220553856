// @ts-nocheck
/* eslint-disable */

import {navigateToFlow} from '@/_core/util/util';
import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';

export const addTeamDevelopmentTaskAction = async (group: string, value: string, teamId: string) => {
  try {
    const response = await performFlowAction({
      method: 'team.development.task.add',
      params: {
        group,
        value,
        teamId,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data.task;
    }

    logger.error('Add Team Development Task Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Add Team Development Task Action encountered an error:', e);
    return null;
  }
};

export const updateTeamDevelopmentTaskAction = async (id, status, value, position, teamId: string) => {
  try {
    const response = await performFlowAction({
      method: 'team.development.task.update',
      params: {
        id,
        status,
        value,
        position,
        teamId,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data.task;
    }

    logger.error('Add Team Development Task Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Add Team Development Task Action encountered an error:', e);
    return null;
  }
};

export const deleteTeamDevelopmentTaskAction = async (id: string, teamId: string) => {
  try {
    const response = await performFlowAction({
      method: 'team.development.task.delete',
      params: {
        id,
        teamId,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data.task;
    }

    logger.error('Add Team Development Task Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Add Team Development Task Action encountered an error:', e);
    return null;
  }
};

export const startTeamDevModule = (teamId: string, module: string) => {
  navigateToFlow('TeamDevelopment', {
    teamId,
    module,
    subModule: 'measure',
  });
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Chip} from '@modules/Core/components/base/Chip';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {
  _ProfileWayOfWorkingMultiSelectionResult,
  _ProfileWayOfWorkingQuestionData,
} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: _ProfileWayOfWorkingMultiSelectionResult;
  channelTypes: string[];
  origin: 'communication_channels' | 'time_management_methods';
  showTitle?: boolean;
  questionData: _ProfileWayOfWorkingQuestionData;
}

export const ProfileWayOfWorkingMultiSelectResult: React.FC<_Props> = ({
  data,
  channelTypes,
  origin,
  questionData,
  showTitle = true,
}) => {
  const getTypeChannels = (type: string): string[] =>
    // return entries that have value = type
    Object.entries(data ?? {})
      .filter(([key, value]) => value === type)
      .map(([key, value]) => key);

  return (
    <div className="flex flex-row w-full gap-3">
      {channelTypes?.map((key, index) => (
        <div className="relative-col-width-5">
          <Card key={key} title={showTitle ? trans(`way_of_working.${origin}.values.${key}`) : null}>
            <div className="flex flex-row flex-wrap gap-1">
              {getTypeChannels(key).map((channel, index) => (
                <div>
                  <ToolTip txt={questionData?.options_description?.[channel]}>
                    <Chip label={trans(`way_of_working.${origin}.values.${channel}`, {}, false) || channel} />
                  </ToolTip>
                </div>
              ))}
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {JSX} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {_SurveyQuestion} from '@modules/SurveyEngine/types/survey.model';
import {trans} from '@modules/Translations/util/i18n';

interface _SurveySeparatorProps {
  question?: _SurveyQuestion;
}

const Image: React.FC<_SurveySeparatorProps> = ({question}) => {
  if (!question?.image) {
    return null;
  }
  if (!question?.image?.includes('://')) {
    return (
      <img
        src={`/assets/img/illustrations_v2/surveys/${question?.image}.svg`}
        alt={question?.title}
        className="img-fluid"
        style={{maxHeight: '350px'}}
      />
    );
  }
  return <img src={question?.image} alt={question?.title} className="img-fluid m-3" style={{maxHeight: '200px'}} />;
};

export const SurveySeparator: React.FC<_SurveySeparatorProps> = ({question}) => {
  if (!question) {
    return null;
  }

  return (
    <div className="text-center flex flex-col justify-center items-center gap-5">
      <Image question={question} />
      <div className="text-center flex flex-col justify-center items-center gap-2">
        <div className="flex gap-3 items-center">
          {question?.tooltip && <div className="w-3 h-2" />}
          <h5>{question?.title}</h5>
          {question?.tooltip && (
            <div className="w-3 h-2">
              <ToolTip html={question.tooltip} className="mt-[-2px]">
                <IconInfo size="md" />
              </ToolTip>
            </div>
          )}
        </div>
        {question?.description && <p>{question?.description}</p>}
      </div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
}

export const ProfileRoundExercisesMissingChallengesGoals: React.FC<_Props> = ({service}) => {
  const navigateToFlow = useNavigateToFlow();

  return (
    <Section className="items-center">
      <img
        src="/assets/img/design_images/slow%20down.png"
        style={{
          width: '213.7px',
          height: '460px',
        }}
      />
      <p className="text-body1">{trans('profile_exercises.no_access_challenges_goals')}</p>
      <p className="text-body">
        {trans('profile_exercises.no_access_challenges_goals_description', {
          softfact: trans(PROFILE_SERVICES_TITLES[service]),
        })}
      </p>
      <div className="relative-col-width-3">
        <Button onClick={() => navigateToFlow(getSurveyFlow(service)?.name)}>
          {trans('profile_exercises.go_to_exercises_service', {
            softfact: trans(PROFILE_SERVICES_TITLES[service]),
          })}
        </Button>
      </div>
    </Section>
  );
};

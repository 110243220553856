// @ts-nocheck
/* eslint-disable */

import {invokeAction} from '@modules/Core/util/actionsUtil';

export const setTutorialDoneAction = async (tutorialName: string): Promise<string | null> => {
  try {
    const response = await invokeAction('profile.tutorial.done', {tutorialName});

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {trans} from '@modules/Translations/util/i18n';

export const SurveyAudioContent = ({contentGroup}) => {
  return (
    <>
      {(contentGroup?.elements || []).map((el, i) => (
        <audio controls className={`inline w-full mx-auto my-4 ${el?.class || ''}`} key={i}>
          <source src={el?.url} type="audio/mpeg" />
          {trans('base.no_audio_support')}
        </audio>
      ))}
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {useCreateItems} from '@modules/ContentEngine/hooks/createItems';
import {_ContentEngineContext, _ContentEngineItem} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Tab} from '@modules/Core/components/base/Tab';
import {StartTutorialButton} from '@modules/Profile/components/tutorials/StartTutorialButton';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';

export interface _Tab {
  title: string;
  name: string;
  content: string | _ContentEngineItem | _ContentEngineItem[] | ReactNode;
}

interface _TabsItemProps {
  tabs: _Tab[];
  activeTab?: number;
  context?: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
  value?: number;
  setValue?: (value: number) => void;
}

export const TabsItem: React.FC<_TabsItemProps> = ({
  context,
  tutorialService,
  tutorialKey,
  tabs,
  activeTab = 0,
  value: externalValue,
  setValue: externalSetValue,
}) => {
  const initialTabLoaded = useRef<boolean>(false);

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (initialTabLoaded.current || !tabs || activeTab >= tabs.length || activeTab < 0) {
      return;
    }
    valueSetter(activeTab);
    initialTabLoaded.current = true;
  }, [activeTab, tabs]);

  function externalProvided() {
    const externalValueProvided = externalValue !== null && externalValue !== undefined;
    const externalSetValueProvided = externalSetValue !== null && externalSetValue !== undefined;
    return externalValueProvided && externalSetValueProvided;
  }

  function valueSetter(value: number): void {
    if (externalProvided()) {
      externalSetValue(value);
      return;
    }
    setValue(value);
  }

  function valueGetter(): number {
    if (externalProvided()) {
      return externalValue;
    }
    return value;
  }

  function handleChange(newValue: number): void {
    valueSetter(newValue);
  }

  return (
    <>
      <div className="flex flex-row max-w-full gap-3">
        {(tabs || []).map((tab, index) => (
          <div key={tab?.name} className="relative-col-width-2-5">
            <Tab
              label={tab?.title}
              key={tab?.name}
              active={valueGetter() === index}
              onChange={() => handleChange(index)}
            />
          </div>
        ))}
        {tutorialKey && (
          <div className="flex flex-row w-full justify-end items-center">
            <StartTutorialButton
              tutorials={context?.tutorials}
              tutorialKey={tutorialKey}
              id={getTutorialTriggerId(tutorialService)}
            />
          </div>
        )}
      </div>

      {(tabs || []).map((tab, index) => valueGetter() === index && <TabPanelItem tab={tab} key={tab?.name} />)}
    </>
  );
};

interface _TabPanelItemProps {
  tab: _Tab;
}

const TabPanelItem: React.FC<_TabPanelItemProps> = ({tab}) => useCreateItems(tab?.content);

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {ServiceResult} from '@modules/Profile/components/services/serviceInformation/ServiceResult';
import {BaseStep} from '../Base/BaseStep';

export const ServiceResultStep: React.FC<_BaseStepComponentOptions> = ({options}) => (
  <BaseStep options={options}>
    <ServiceResult
      service={options?.service}
      surveyParams={options?.surveyParams}
      teamId={options?.teamId}
      surveyDoneBefore={options?.isSurveyDoneBefore}
      preview={options?.preview}
    />
  </BaseStep>
);

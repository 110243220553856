// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {WrapperBadge} from '@modules/Core/components/base/badge/WrapperBadge';
import {RoleCard} from '@modules/Core/components/base/cards/RoleCard';
import {logger} from '@modules/Core/util/Logger';
import {profileRolesLangProps} from '@modules/Profile/config/servicesLangProps';
import {_ProfilePersonalityTraitsResult, _ProfileRoles} from '@modules/Profile/types/service.model';
import {getProfilePic, getProfileRoleIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: Record<string, any[]>;
  feedbackId: string | null;
  profileData: _ProfileRoles;
  profilePicture: string;
}

const socialOrder = [
  'critic',
  'social',
  'listener',
  'evaluator',
  'coordinator',
  'team_leader',
  'problem_solver',
  'task_completer',
  'task_motivator',
  'teamwork_support',
];

export const ThirdPersonFeedbackRolesItem: React.FC<_Props> = ({data, feedbackId, profilePicture, profileData}) => {
  const [feedbacks, setFeedbacks] = useState<_ProfilePersonalityTraitsResult[]>([]);

  useEffect(() => {
    if (!feedbackId || !data) {
      setFeedbacks([]);
      return;
    }

    setFeedbacks(
      (data[feedbackId] ?? []).filter(item => {
        return item?.status === 'DONE' && !!item?.values?._feedback_results;
      })
    );
  }, [data, feedbackId]);

  logger.debug('ThirdPersonFeedbackRolesItem: Rendering', {feedbacks, profileData, profilePicture});
  return (
    <div className="flex flex-row w-full justify-between flex-wrap">
      {socialOrder.map((key, index) => {
        const Icon = getProfileRoleIcon(key);

        return (
          <div className="flex flex-col relative-col-width-5 mb-3">
            <RoleCard
              icon={Icon}
              score={profileData?.[key]?.value ?? null}
              description={trans(profileRolesLangProps[key].text)}
              title={trans(profileRolesLangProps[key].title)}
              showExpander
              avatarComponent={<Avatar size="lg" image={profilePicture ?? getProfilePic(null)} indicator="success" />}
              renderAvatarComponent={({value, profilePicture}) => {
                return (
                  <WrapperBadge value={value}>
                    <Avatar size="lg" image={getProfilePic(null)} indicator="success" />
                  </WrapperBadge>
                );
              }}
              scores={(feedbacks ?? []).map((feedback, index) => {
                let value = feedback?.values?._feedback_results?.roles?.[key]?.value;
                // round to 2 decimal places
                value = Math.round(value * 100) / 100;
                return {
                  value,
                  profilePicture: getProfilePic(feedback?.profile),
                };
              })}
              expandedContents={[
                {
                  description: (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trans(profileRolesLangProps[key].description),
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        );
      })}
    </div>
  );
};

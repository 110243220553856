// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {isArray} from '@modules/Core/util/typesUtil';

export const SurveyBlockContent = ({contentGroup}) => (
  <>
    {(contentGroup?.elements || []).map((el, i) => (
      <div className={`my-4 content-block ${el?.class || ''}`} key={i}>
        {el?.title && (
          <p className="mb-0 font-weight-bold text-gray-900md:text-lg lg:text-xl dark:text-white">{el?.title}</p>
        )}

        {isArray(el?.content) &&
          el?.content.map((paragraph, i) => (
            <p className="mt-2" key={i}>
              {paragraph}
            </p>
          ))}

        {!isArray(el?.content) && <p className="mt-2">{el?.content}</p>}
      </div>
    ))}
  </>
);

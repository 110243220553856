// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useState} from 'react';
import {createDynamicItemComponent} from '../config/registeredItems';
import {_ContentEngineContext, _ContentEngineItem} from '../types/contentEngineItem.model';

export function useCreateItems(
  items: string | _ContentEngineItem | _ContentEngineItem[] | ReactNode,
  context?: _ContentEngineContext
): ReactNode | ReactNode[] {
  const [parsedContent, setParsedContent] = useState<ReactNode | ReactNode[]>();

  useEffect(() => {
    if (!items) {
      setParsedContent(null);
      return;
    }
    // Vanilla text
    if (typeof items === 'string') {
      setParsedContent(items);
      return;
    }
    // To be parsed items
    if (Array.isArray(items)) {
      setParsedContent((items || []).map(item => createDynamicItemComponent(item, context?.tutorials ?? {})));
      return;
    }

    const isReactComponent = React.isValidElement(items);
    if (isReactComponent) {
      setParsedContent(items);
      return;
    }

    if (typeof items === 'object') {
      setParsedContent(createDynamicItemComponent(items as _ContentEngineItem, context?.tutorials ?? {}));
      return;
    }

    setParsedContent(items);
  }, [items]);

  return parsedContent;
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_DimensionType} from '@modules/ContentEngine/components/items/teams/team_analysis/way_of_working/TeamWayOfWorkingDimensionsItem';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {AvatarGroup} from '@modules/Core/components/base/avatar/AvatarGroup';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {Slider} from '@modules/Core/components/base/Slider';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {logger} from '@modules/Core/util/Logger';
import {wayOfWorkingLangProps} from '@modules/Profile/config/servicesLangProps';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {TeamMembersModal} from '@modules/Team/components/Members/TeamMembersModal';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamWayOfWorkingResult} from '@modules/Team/types/teamanalysis.model';
import {groupProfilesByValue} from '@modules/Team/util/teamWayOfWorkingUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamWayOfWorkingDialogProps {
  open: boolean;
  onClose: () => void;
  criteriaKey: _DimensionType;
  result: _TeamWayOfWorkingResult;
  allProfiles: Record<string, _TeamProfile>;
  activeTab?: 'actual' | 'wanted';
  transparencyAccepted?: boolean;
}

const MAX_SCORE = 10;

export const TeamWayOfWorkingDialog: React.FC<_TeamWayOfWorkingDialogProps> = ({
  open,
  onClose,
  result,
  criteriaKey,
  allProfiles,
  activeTab: activeTabProp,
  transparencyAccepted,
}) => {
  const [activeTab, setActiveTab] = useState<'actual' | 'wanted'>('actual');
  const [chosenProfiles, setChosenProfiles] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    setActiveTab(activeTabProp ?? 'actual');
  }, [activeTabProp]);

  const onOpenProfilesList = (profileIds: string[]): void => {
    setChosenProfiles(profileIds);
    setShowModal(true);
  };

  function getGroupedProfilesComponent(groupedValues: Array<{profile: string; value: number}>) {
    let avatarComponent;
    if (groupedValues.length === 1) {
      avatarComponent = <Avatar image={getProfilePic(allProfiles[groupedValues[0].profile])} />;
    } else {
      avatarComponent = (
        <AvatarGroup
          onBadgeClick={() => onOpenProfilesList(groupedValues.map(value => value.profile))}
          size="md"
          badge={`+${groupedValues.length - 1}`}
        >
          <Avatar size="md" image={getProfilePic(allProfiles[groupedValues[0].profile])} />
        </AvatarGroup>
      );
    }

    return {
      id: groupedValues[0].profile,
      progress: (groupedValues[0].value / MAX_SCORE) * 100,
      component: avatarComponent,
      indicatorColor: 'bg-grey700',
    };
  }

  if (!criteriaKey) {
    return null;
  }

  const wantedValuesResult = result.wantedValues;
  const actualValuesResult = result.actualValues;
  const actualAverage = actualValuesResult.average;
  const wantedAverage = wantedValuesResult.average;

  let components = [];

  let groupedValues: Record<string, Array<{profile: string; value: number}>> = {};
  if (activeTab === 'actual') {
    groupedValues = groupProfilesByValue(result.actualValues.values);

    components = [
      {
        id: 'actual',
        progress: (actualAverage / MAX_SCORE) * 100,
        component: <div />,
        indicatorColor: 'bg-black700',
      },
    ];

    components = components.concat(
      Object.values(groupedValues).map((value, index) => getGroupedProfilesComponent(value))
    );
  } else {
    groupedValues = groupProfilesByValue(result.wantedValues.values);
    components = [
      {
        id: 'wanted',
        progress: (wantedAverage / MAX_SCORE) * 100,
        component: <div />,
        indicatorColor: ' bg-green700',
      },
    ];

    components = components.concat(
      Object.values(groupedValues).map((value, index) => getGroupedProfilesComponent(value))
    );
  }

  return (
    <>
      <TeamMembersModal
        transparencyAccepted={transparencyAccepted}
        profiles={chosenProfiles?.map(id => allProfiles[id]) ?? []}
        open={showModal}
        onClose={() => {
          setShowModal?.(false);
          setChosenProfiles([]);
        }}
      />
      <Dialog
        size="lg"
        showCloseButton
        onClose={onClose}
        open={open && !showModal}
        title={trans(wayOfWorkingLangProps[criteriaKey].title)}
      >
        <Tabs
          tabs={[
            {
              key: 'actual',
              displayValue: trans('way_of_working.actual_values'),
            },
            {
              key: 'wanted',
              displayValue: trans('way_of_working.wanted_values'),
              color: 'green500',
            },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Slider
          key={criteriaKey}
          leftText={trans(wayOfWorkingLangProps[criteriaKey].first_facet_label)}
          rightText={trans(wayOfWorkingLangProps[criteriaKey].second_facet_label)}
          leftTooltip={trans(wayOfWorkingLangProps[criteriaKey].first_facet_tooltip)}
          rightTooltip={trans(wayOfWorkingLangProps[criteriaKey].second_facet_tooltip)}
          leftIcon={IconInfo}
          rightIcon={IconInfo}
          enableEndicator
          components={components}
          showExpand={false}
        />
      </Dialog>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import {generate} from '@modules/Core/components/base/helper';
import {logger} from '@modules/Core/util/Logger';
import {zustandApi} from '@modules/Core/util/logic/zustand';

export type _SnackbarSeverity = 'success' | 'warning' | 'danger' | 'info';

export interface _SnackbarOptions {
  label: string;
  severity: _SnackbarSeverity;
}

const showSnackbar = (options: _SnackbarOptions): void => {
  const {label, severity} = options;
  zustandApi.addToGlobals('globalAlerts', {label, id: generate(), color: severity});
};

export const success = (label: string): void => {
  showSnackbar({label, severity: 'success'});
};

export const warning = (label: string): void => {
  showSnackbar({label, severity: 'warning'});
};

export const danger = (label: string): void => {
  showSnackbar({label, severity: 'danger'});
};

export const info = (label: string): void => {
  showSnackbar({label, severity: 'info'});
};

export const show = (label: string, severity: _SnackbarSeverity = 'success'): void => {
  logger.debug('show snackbartest', {label, severity});
  showSnackbar({label, severity});
};

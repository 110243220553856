// @ts-nocheck
/* eslint-disable */

import {logger} from '@sentry/utils';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {
  _CustomSurvey,
  _CustomSurveyAnswerSubmission,
  _CustomSurveyQuestionSubmission,
} from '@modules/CustomSurveys/types/customSurvey.model';
import {performFlowAction} from '@modules/FlowEngine/services/api';

// Function to publish a survey
export const publishSurveyAction = async (surveyId: string): Promise<boolean> => {
  const method = 'custom_survey.publish';
  const params = {surveyId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Publish Survey Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Publish Survey Action encountered an error:', e);
    return false;
  }
};

export const duplicateSurveyAction = async (surveyId: string): Promise<string | boolean> => {
  const method = 'custom_survey.duplicate';
  const params = {surveyId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data?.survey_id) {
      return responseData?.data?.survey_id;
    }
    logger.error('Publish Survey Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Publish Survey Action encountered an error:', e);
    return false;
  }
};

export const closeSurveyAction = async (surveyId: string): Promise<boolean> => {
  const method = 'custom_survey.close';
  const params = {surveyId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Publish Survey Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Publish Survey Action encountered an error:', e);
    return false;
  }
};

// Function to delete a survey
export const deleteSurveyAction = async (surveyId: string): Promise<boolean> => {
  const method = 'custom_survey.delete';
  const params = {surveyId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Delete Survey Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Delete Survey Action encountered an error:', e);
    return false;
  }
};

// Function to share a survey
export const shareSurveyAction = async (surveyId: string, shareWith: string[]): Promise<boolean> => {
  const method = 'custom_survey.share';
  const params = {surveyId, shareWith};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Share Survey Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Share Survey Action encountered an error:', e);
    return false;
  }
};

export const saveSurveyAction = async (body: _CustomSurvey, surveyId?: string): Promise<string | null> => {
  const method = 'custom_survey.save';
  const params = {
    surveyId,
    ...(body ?? {}),
  };

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Save Survey Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return responseData.data.survey_id;
    }
    logger.error('Submit Exercise Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Submit Exercise Action encountered an error:', e);
    return null;
  }
};

export const submitCustomSurveyAnswer = async (submission: _CustomSurveyQuestionSubmission): Promise<boolean> => {
  const method = 'custom_survey.submit';
  const params = {
    ...submission,
  };

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Submit Survey Answer Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      logger.debug('Survey answer submitted successfully:', responseData.data);
      return true;
    }
    logger.error('Submit Survey Answer Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Submit Survey Answer Action encountered an error:', e);
    return false;
  }
};

export const finalizeCustomSurveyAnswer = async (
  surveyId: string,
  answers: _CustomSurveyAnswerSubmission
): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'custom_survey.finalize',
        params: {
          surveyId,
          answers,
        },
      },
      {
        refetchProfile: true,
        unauthorized: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Finalize Survey Answer Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      logger.debug('Survey answer finalized successfully:', responseData.data);
      return true;
    }
    logger.error('Finalize Survey Answer Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Finalize Survey Answer Action encountered an error:', e);
    return false;
  }
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {Accordion} from '@modules/Core/components/base/accordion/Accordion';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Divider} from '@modules/Core/components/base/Divider';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {
  _ExerciseData,
  _ProfileExerciseStatus,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import SendReminder from '@modules/Team/components/common/SendEmailToTeam/SendReminder';
import {statusLangProps} from '@modules/Team/components/dashboard/TeamSoftfactsRow';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teamId: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  service: string;
  profiles?: _ShortProfile[]; // Made optional since we might not have profiles
  xpMetric: _ProfileTrackingStatisticEntry;
  roundIndex: number;
  type?: 'teams' | 'profiles';
  data: {
    progress: number;
    rounds: Array<{
      status: _ProfileExerciseStatus;
      progress: number;
      exercises: Array<{
        profiles?: Array<{
          status: _ProfileExerciseStatus;
          profile: _ShortProfile;
        }>;
        teams?: Array<{
          status: _ProfileExerciseStatus;
          team: _ShortTeam;
        }>;
        status: _ProfileExerciseStatus;
        progress: number;
      }>;
    }>;
  };
}

export const TeamSoftfactStatusDialog: React.FC<_Props> = ({
  roundIndex: initialRoundIndex,
  teamId,
  open,
  setOpen,
  data,
  xpMetric,
  service,
  type = 'profiles', // Default to 'profiles' if not specified
}) => {
  const exercisesData = useExercises();

  logger.debug('TeamSoftfactsRow', {service, xpMetric, data, exercisesData, initialRoundIndex});

  const getExercisesData = (service: string, roundIndex: number | string, exIndex: number): _ExerciseData => {
    const serviceExercises = exercisesData[service] ?? null;

    if (!serviceExercises) {
      return {};
    }

    return serviceExercises.exercises?.[roundIndex]?.[exIndex] ?? null;
  };

  return (
    <Dialog
      size="lg"
      showCloseButton
      open={open}
      onClose={() => setOpen?.(false)}
      title={trans(PROFILE_SERVICES_TITLES[service])}
    >
      {data?.rounds
        ?.filter(round => [EXERCISE_STATUS_STARTED, EXERCISE_STATUS_DONE].includes(round.status))
        ?.map((roundData, roundIndex) => {
          return (
            <Accordion
              key={roundIndex}
              expanded={roundIndex === initialRoundIndex}
              title={trans('profile_exercises.round', {
                roundIndex: Number(roundIndex) + 1,
              })}
              titleSuffix={
                <div className="flex flex-row w-full items-center gap-3 justify-end">
                  <div className="relative-col-width-1">
                    <ProgressBar value={roundData?.progress} color="success" size="xs" />
                  </div>
                  {!!xpMetric?.trend && (
                    <StatisticLabelBadge
                      change={xpMetric?.trend}
                      changeType={xpMetric?.trend > 0 ? '+' : xpMetric?.trend < 0 ? '-' : 'N'}
                    />
                  )}
                </div>
              }
            >
              <div className="flex flex-col gap-3  w-full">
                {roundData?.exercises.map((exerciseData, exIndex) => {
                  const exerciseProperties = getExercisesData(service, roundIndex, exIndex);
                  const status = exerciseData?.status;

                  // Depending on the type, use profiles or teams
                  const participants = type === 'profiles' ? exerciseData.profiles : exerciseData.teams;

                  // Calculate finished and total counts
                  const finishedCount = participants?.filter(
                    participant => participant.status === EXERCISE_STATUS_DONE
                  ).length;
                  const totalCount = participants?.length;

                  return (
                    <div key={exIndex} className="flex flex-col w-full gap-2">
                      <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex flex-col justify-center items-start gap-0 flex-grow">
                          <Typography variant="body">
                            {trans('profile_exercises.exercise', {
                              exerciseIndex: exIndex + 1,
                            })}
                          </Typography>
                          {exerciseProperties?.intro?.title && (
                            <Typography variant="body">{trans(exerciseProperties?.intro?.title ?? '')}</Typography>
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          {status === EXERCISE_STATUS_STARTED && type === 'profiles' && (
                            <SendReminder
                              teamId={teamId}
                              finishedCount={finishedCount}
                              totalCount={totalCount}
                              notificationType={`sf_${service}`}
                            />
                          )}
                        </div>
                      </div>
                      {participants?.map(participant => {
                        const participantStatus = participant.status;

                        return (
                          <div
                            key={participant?.profile?.id || participant?.team?.id}
                            className="flex flex-row w-full items-center justify-between"
                          >
                            {type === 'profiles' ? (
                              // For profiles, render AvatarUser
                              <AvatarUser
                                label={participant?.profile?.fullName}
                                image={getProfilePic(participant?.profile)}
                              />
                            ) : (
                              // For teams, render placeholder
                              <div>{participant?.team?.name}</div>
                            )}
                            <div className="flex flex-row items-center">
                              {![EXERCISE_STATUS_LOCKED].includes(participantStatus) && (
                                <Typography
                                  variant="body"
                                  className={
                                    participantStatus === EXERCISE_STATUS_STARTED
                                      ? 'text-orange700'
                                      : participantStatus === EXERCISE_STATUS_DONE
                                        ? 'text-green700'
                                        : 'text-grey500'
                                  }
                                >
                                  {trans(statusLangProps[participantStatus] ?? statusLangProps.default)}
                                </Typography>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {exIndex !== roundData.exercises.length - 1 && <Divider light fill />}
                    </div>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
    </Dialog>
  );
};

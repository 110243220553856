// @ts-nocheck
/* eslint-disable */

export const DEFAULT_STEP_OPTIONS = {
  showBackButton: true,
  showNextButton: true,
  showSaveButton: false,
  showFinishButton: true,
  showDisplayValue: true,
  showLoader: true,
  minHeight: '230px',
  loadOnStepChange: true,
};

export const DEFAULT_FLOW_OPTIONS = {
  showHeader: false,
  showFooter: true,
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_LayoutOptions, _UIAction} from '@modules/Core/types/pageLayout.model';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {_FlowEvent, _FlowTitle} from '@modules/FlowEngine/types/flow.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';

export const extractFlowTitle = (stepComponent: React.ReactElement | null | undefined): _FlowTitle | null | string => {
  let showTitle = true;
  if (stepComponent?.props.options?.stepOptions?.showTitle !== undefined) {
    showTitle = stepComponent.props.options?.stepOptions?.showTitle;
  }
  if (!showTitle || !stepComponent) {
    return null;
  }

  logger.debug('extractFlowTitle', {stepComponent});
  if (stepComponent?.props.options?.title) {
    return stepComponent.props.options?.title as _FlowTitle | string;
  }
  if (stepComponent?.props?.options?.stepOptions?.title) {
    return stepComponent.props.options.stepOptions.title as _FlowTitle | string;
  }
  if (stepComponent?.props.options?.flowTitle) {
    return stepComponent.props.options?.flowTitle as _FlowTitle | string;
  }
  if (stepComponent?.props.options?.stepOptions?.displayValue) {
    return `Step: ${stepComponent.props.options?.stepOptions?.displayValue}`;
  }
  return `Flow: ${stepComponent?.props.options?.flowName}`;
};

export const extractFlowLayoutOptions = (stepComponent: React.ReactElement | null | undefined): _LayoutOptions => {
  const flowLayoutOptions = stepComponent?.props.options?.flowLayoutOptions ?? ({} satisfies _LayoutOptions);
  const stepLayoutOptions = stepComponent?.props.options?.stepOptions?.layoutOptions ?? ({} satisfies _LayoutOptions);

  // Take flowLayoutOptions and override with stepLayoutOptions
  return {
    ...flowLayoutOptions,
    ...stepLayoutOptions,
  };
};

export const extractFlowEventsAsUIActions = (
  flowName: string,
  currentStep: _FlowStep,
  stepComponent: React.ReactElement | null | undefined,
  isFabEnabled: boolean = false
): _UIAction[] | null => {
  // Filter events for non-innerStepEvents
  const events = stepComponent?.props.options?.events as Record<string, _FlowEvent> | null;

  if (!events) {
    return null;
  }

  // Map events to UIActions
  const onEvent = (actionName: string): void => dispatchReactEvent('onStepEvent', {flowName, currentStep, actionName});

  const actions: _UIAction[] = [];

  Object.keys(events)
    .filter((key: string) => events[key].showCondition === undefined || events[key].showCondition)
    .filter((key: string) => !events[key].innerStepEvent)
    .forEach((key: string) => {
      const event = events[key];
      const fireEvent = stepComponent?.props.options?.fireEvent;

      const onClick = (actionName: string): void => {
        // Timeout needed here to wait for the setters in "onEvent" to finish
        if (onEvent) {
          onEvent(actionName);
          setTimeout(() => {
            fireEvent(actionName);
          });
        } else {
          fireEvent(actionName);
        }
      };

      const action: _UIAction = {
        type: event.type ?? 'button',
        label: event.label,
        icon: event.icon,
        width: event.width,
        iconPosition: event.iconPosition,
        origin: event.origin,
        onClick: () => onClick(key),
        metadata: {
          journalCategoryId: event.journalCategoryId,
          teamId: event.teamId,
          ...(event.metadata ?? {}),
        },
        source: event.source,
        isDevAction: event.isDevAction,
        selectProps: event.selectProps,
        position: event.position,
        fab: isFabEnabled ? event.fab : false,
      };
      actions.push(action);
    });

  return actions;
};

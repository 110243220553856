// @ts-nocheck
/* eslint-disable */

import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {invokeAction} from '@modules/Core/util/actionsUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {_ProfileExercise} from '@modules/Profile/types/exercises.model';

export const submitExerciseAction = async (
  service: string,
  roundIndex: number,
  exerciseIndex: number,
  tasksData
): Promise<_ProfileExercise | null> => {
  const method = 'exercises.submit';
  const params = {
    service,
    roundIndex,
    exerciseIndex,
    tasksData,
  };

  try {
    const response = await performFlowAction({
      method,
      params,
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }
    logger.error('Submit Exercise Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Submit Exercise Action encountered an error:', e);
    return null;
  }
};
export const finishExerciseAction = async (
  service: string,
  roundIndex: number,
  exerciseIndex: number
): Promise<_FlowActionResult | null> => {
  const method = 'exercises.finish';
  const params = {
    service,
    roundIndex,
    exerciseIndex,
  };

  try {
    const response = await performFlowAction({
      method,
      params,
    });

    const responseData = response?.data;

    if (responseData?.status === 'success') {
      return responseData.data;
    }
    logger.error('Finish Exercise Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Finish Exercise Action encountered an error:', e);
    return null;
  }
};
export const restartExerciseAction = async (
  service: string,
  roundIndex: number,
  exerciseIndex: number
): Promise<_ProfileExercise | null> => {
  try {
    const response = await performFlowAction({
      method: 'exercises.restart',
      params: {
        service,
        roundIndex,
        exerciseIndex,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }
    logger.error('Restart Exercise Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Restart Exercise Action encountered an error:', e);
    return null;
  }
};
export const startExerciseAction = async (
  service: string,
  roundIndex: number,
  exerciseIndex: number,
  collectedData = {}
): Promise<_ProfileExercise | null> => {
  try {
    const response = await performFlowAction(
      {
        method: 'exercises.start',
        params: {
          service,
          roundIndex,
          exerciseIndex,
          collectedData,
        },
      },
      {refetchProfile: true}
    );

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }
    logger.error('Start Exercise Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Start Exercise Action encountered an error:', e);
    return null;
  }
};
export const submitServiceData = async (service: string, keys: string[], values): Promise<Object | null> => {
  try {
    const response = await performFlowAction(
      {
        method: 'service.data.submit',
        params: {
          service,
          keys,
          values,
        },
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      reloadContentEngine();
      return responseData.data;
    }
    logger.error('Submit Service Data Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Submit Service Data Action encountered an error:', e);
    return null;
  }
};
/**
 * @param {Object} params - The parameters for the exercise round data submission.
 * @param {string} params.service - The service identifier.
 * @param {number} roundIndex - The index of the round being submitted.
 * @returns {Promise<Object|null>} - The response data from the backend or null in case of an error.
 */
export const submitExerciseDataAction = async (
  service: string,
  roundIndex: number,
  exerciseIndex: number,
  data
): Promise<Object | null> => {
  const method = 'exercises.data.submit'; // Backend method name
  const params = {
    service,
    exerciseIndex,
    roundIndex,
    data,
  };

  try {
    // Call the performFlowAction function with the method and parameters
    const response = await performFlowAction({
      method,
      params,
    });

    // Extract the response data
    const responseData = response?.data;

    // Check if the response indicates a successful operation
    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }

    // Log an error if the operation was not successful
    logger.error('Submit Exercise Data Action failed:', responseData?.error);
    return null;
  } catch (e) {
    // Log any errors encountered during the operation
    logger.error('Submit Exercise Data Action encountered an error:', e);
    return null;
  }
};
/**
 * @param {string} params.service - The service identifier.
 * @returns {Promise<Object|null>} - The response data from the backend or null in case of an error.
 * @param service
 * @param newRoundIndex
 */
export const startServiceNextRoundAction = async (service: string, newRoundIndex: number): Promise<Object | null> => {
  try {
    // Call the performFlowAction function with the method and parameters
    const response = await invokeAction(
      'service.round.start',
      {
        service,
        newRoundIndex,
      },
      {refetchProfile: true, loadingIndicator: true}
    );

    // Check if the response indicates a successful operation
    if (response?.status === ACTION_STATUS_SUCCESS && response?.data) {
      reloadContentEngine();
      return response.data;
    }

    // Log an error if the operation was not successful
    logger.error('Start Service Next Round Action failed:', {
      response,
    });
    return null;
  } catch (e) {
    // Log any errors encountered during the operation
    logger.error('Start Service Next Round Action encountered an error:', e);
    return null;
  }
};

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';

const LOAD_CONTENT = 'content';
const LOAD_CONTENT_UNAUTHORIZED = 'content/unauthorized';
export async function loadContent(
  type: string,
  params: Record<string, any> = {},
  loadingIndicator: boolean = true,
  unauthorized: boolean = false
): Promise<any> {
  return await httpClient.post(
    `${unauthorized ? LOAD_CONTENT_UNAUTHORIZED : LOAD_CONTENT}?type=${type}`,
    {params, key: type},
    loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {}
  );
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';

export const SurveyVideoContent = ({contentGroup}) => (
  <>
    {(contentGroup?.elements || []).map((el, i) => (
      <div className={`inline-block w-full my-4 rounded-lg shadow-lg embed-container ${el?.class || ''}`} key={i}>
        <iframe
          width="560"
          height="315"
          src={el?.url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ))}
  </>
);

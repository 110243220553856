// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Action} from '@modules/Core/components/layout/Action';
import {_UIAction} from '@modules/Core/types/pageLayout.model';

export type _ActionView = 'row' | 'context-menu';

interface _Props {
  actions: _UIAction[];
  view: _ActionView;
}

export const ActionList: React.FC<_Props> = ({actions, view}) => {
  // TODO: context-menu view
  return actions?.map(action => <Action {...action} key={action.uuid} />);
};

// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {logger} from '@modules/Core/util/Logger';
import {GET_TRANSLATIONS} from './paths';

type LanguageCache = Record<string, any>;

const languageCache: LanguageCache = {};

async function getTranslations(lang: string): Promise<AxiosResponse> {
  logger.info(`[LanguageAPIService] getTranslations: ${lang}`);
  if (!languageCache[lang]) {
    languageCache[lang] = await httpClient.get(GET_TRANSLATIONS, {
      params: {lang},
    });
  }
  return languageCache[lang];
}

export {getTranslations};

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../HttpClient';

async function submitForm(path: string, method: string, data: any): Promise<any> {
  switch (method.toUpperCase()) {
    case 'GET':
      return await httpClient.get(path, data);
    case 'POST':
      return await httpClient.post(path, data);
    case 'PUT':
      return await httpClient.put(path, data);
    case 'DELETE':
      return await httpClient.delete(path, data);
    default:
      return await httpClient.post(path, data);
  }
}

export {submitForm};

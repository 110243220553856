// @ts-nocheck
/* eslint-disable */

export const PAYMENT_CHECKOUT_SESSION_PENDING_STATUS = 'pending';
export const PAYMENT_CHECKOUT_SESSION_SUCCESS_STATUS = 'success';
export const PAYMENT_CHECKOUT_SESSION_FAILED_STATUS = 'failed';

export const BILLING_INTERVAL_MONTHLY = 'monthly';
export const BILLING_INTERVAL_YEARLY = 'yearly';
export const BILLING_INTERVALS = [BILLING_INTERVAL_MONTHLY, BILLING_INTERVAL_YEARLY];

export const FREE_PLAN_ID = 'free';
export const DEV_ONLY_PLAN_ID = 'dev-only';
export const WORKSHOPS_AND_COACHING_PLAN_ID = 'workshops_team_coaching';
export const ONE_TIME_PAYMENT_PLAN_ID = 'one_time_plan';

export const SUBSCRIPTION_STATUS_TO_BE_CANCELLED = 'to_be_cancelled';
export const SUBSCRIPTION_STATUS_TO_BE_ACTIVATED = 'to_be_activated';
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';

// @ts-nocheck
/* eslint-disable */

// import delete icon

import React, {useEffect, useRef, useState} from 'react';
import {MeasuringStrategy} from '@dnd-kit/core';
import {horizontalListSortingStrategy} from '@dnd-kit/sortable';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {DnDContext} from '@modules/Core/components/base/dnd/DnDContext';
import {Droppable} from '@modules/Core/components/base/dnd/Droppable';
import {SortableContext} from '@modules/Core/components/base/dnd/SortableContext';
import {IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {_SurveyMultiSelectTypeProps} from '@modules/SurveyEngine/components/types/SurveyMultiSelectType';
import {NewValueDialog} from '@modules/SurveyEngine/components/types/SurveyMutliSelectDragDropType/NewValueDialog';
import {SurveyDnDCard} from '@modules/SurveyEngine/components/types/SurveyMutliSelectDragDropType/SurveyDnDCard';
import {SurveyDnDItem} from '@modules/SurveyEngine/components/types/SurveyMutliSelectDragDropType/SurveyDnDItem';
import {convertKey, getSpecialField} from '@modules/SurveyEngine/util/questionUtil';
import {mapList} from '@modules/SurveyEngine/util/surveyDnDUtil';

const defaultListId = 'defaultList';
export const SurveyMultiSelectDragDropType: React.FC<_SurveyMultiSelectTypeProps> = ({
  question,
  answers,
  onValueChosen,
}) => {
  const [lists, setLists, onDrop] = useLists({question, answers, onValueChosen});
  const [newValueDialogOpen, setNewValuesDialogOpen] = useState(false);

  const items = lists.find(list => list.key === defaultListId)?.items || [];

  function doAddNewItem(newValue: string): void {
    if (!newValue) {
      return;
    }

    // if any list has this, return
    if (lists?.find(list => list.items?.find(item => item.label === newValue))) {
      return;
    }
    // If value exists don't add it again
    if (items.find(item => item.label === newValue)) {
      return;
    }

    const newItems = copyObject(items);

    const newItem = {
      id: newValue,
      label: newValue,
      key: newValue,
    };

    newItems.push(newItem);

    setLists(lists?.map(list => (list.key === defaultListId ? {...list, items: newItems} : list)));

    onValueChosen(question, {
      values: newItems,
      lists: lists.map(list => mapList(list)),
    });

    setNewValuesDialogOpen(false);
  }

  function handleNewItemClick(): void {
    setNewValuesDialogOpen(true);
  }

  function handleDeleteItemClick(itemKey: string): void {
    if (!itemKey) {
      return;
    }
    let newItems = items;
    // find item in all lists
    const list = lists.find(list => list.items.find(item => item.key === itemKey));
    if (list) {
      const newListItems = list.items.filter(item => item.key !== itemKey);
      setLists(lists.map(l => (l.key === list.key ? {...l, items: newListItems} : l)));
      if (list.key === defaultListId) {
        newItems = newListItems;
      }
    }

    onValueChosen(question, {
      values: newItems,
      lists: lists.map(list => mapList(list)),
    });
  }

  const specialFieldItem = items.find(item => getSpecialField(item?.label));
  const specialField = getSpecialField(specialFieldItem?.label);
  const dndContextLists = lists ?? [];
  return (
    <>
      <DnDContext
        renderOverlay={activeItemId => {
          const activeList = dndContextLists.find(list => list.items.find(item => item.key === activeItemId));
          if (!activeList) {
            return null;
          }
          const activeItem = activeList.items.find(item => item.key === activeItemId);

          if (!activeItem) {
            return null;
          }

          return <SurveyDnDItem item={activeItem} />;
        }}
        options={{
          activationConstraint: {
            distance: 10,
          },
        }}
        setLists={setLists}
        measuring={{droppable: {strategy: MeasuringStrategy.WhileDragging}}}
        onDragEnd={onDrop}
        lists={dndContextLists}
      >
        <div className="flex flex-col gap-5">
          <SortableContext items={items} id={defaultListId} strategy={horizontalListSortingStrategy}>
            <Droppable className="flex w-full" id={defaultListId}>
              <div className="flex flex-row flex-wrap items-center gap-2" dusk="drag-list-container">
                {(lists.find(list => list.id === defaultListId)?.items || [])
                  .filter(item => !getSpecialField(item?.label))
                  .map((item, index) => (
                    <SurveyDnDItem
                      tooltip={question?.options_description?.[item?.key]}
                      item={item}
                      onDelete={() => {
                        if (item.label !== item.key) {
                          return;
                        }
                        handleDeleteItemClick(item.key);
                      }}
                    />
                  ))}
                <IconButton onClick={handleNewItemClick} icon={IconAdd} />
              </div>
            </Droppable>
          </SortableContext>

          <div className="flex flex-row justify-between gap-0">
            {(lists || [])
              .filter(list => list.key !== defaultListId)
              .map(({label, items, key}, index) => {
                return (
                  <SurveyDnDCard
                    id={key}
                    label={label}
                    values={items}
                    getItemTooltip={itemKey => question?.options_description?.[itemKey]}
                    onItemDelete={item => {
                      if (item.label !== item.key) {
                        return;
                      }
                      handleDeleteItemClick(item.key);
                    }}
                  />
                );
              })}
          </div>
        </div>
      </DnDContext>

      {Boolean(specialFieldItem) && (
        <NewValueDialog
          questionKey={question.key}
          specialField={specialField}
          open={newValueDialogOpen}
          onClose={() => setNewValuesDialogOpen(false)}
          onConfirm={newValue => {
            doAddNewItem(newValue);
            setNewValuesDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

function useItems({question, answers}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!question?.answers) {
      setItems([]);
      return;
    }

    let newItems = createFromQuestion(question);

    newItems = importAnswers(question, answers, newItems);

    setItems(newItems);
  }, [question, answers]);

  function createFromQuestion(question) {
    return (Object.entries(question.answers) || []).map(([key, label], index) => {
      return {label, key, id: key};
    });
  }

  function importAnswers(question, answers, newItems) {
    const convertedKey = convertKey(question.key);
    const newItemsCopy = [];

    if (!answers[convertedKey]?.values) {
      return newItems;
    }

    const answerItemsKeys = answers[convertedKey].values?.map(item => item.key);

    for (const item of newItems) {
      if (answerItemsKeys.includes(item.key)) {
        newItemsCopy.push(item);
      }
    }

    const newItemsKeys = newItemsCopy.map(item => item.key);

    // Add items that are not in the question but are in the answer
    for (const item of answers[convertedKey]?.values ?? []) {
      if (answerItemsKeys.includes(item.key) && !newItemsKeys.includes(item.key)) {
        newItemsCopy.push(item);
      }
    }

    return newItemsCopy.map(item => {
      return {
        id: item.key,
        ...item,
      };
    });
  }

  return [items, setItems];
}

function useLists({question, answers, onValueChosen}) {
  const [lists, setLists] = useState([]);
  const [items] = useItems({question, answers});

  const itemsImported = useRef<boolean>(false);
  const listsImported = useRef<boolean>(false);

  useEffect(() => {
    if (itemsImported.current) {
      return;
    }

    if (!items.length || !lists.length) {
      return;
    }
    setLists(lists.concat([{key: defaultListId, id: defaultListId, items: items?.map(item => ({...item}))}]));
    itemsImported.current = true;
  }, [items, lists]);
  useEffect(() => {
    if (!question?.answers) {
      setLists([]);
      return;
    }

    if (listsImported.current) {
      return;
    }

    let newLists = createFromQuestion(question);

    newLists = importAnswers(question, answers, newLists);

    setLists(newLists);
    listsImported.current = true;
  }, [question, answers]);

  function createFromQuestion(question) {
    return (Object.entries(question.answers2) || []).map(([key, label], index) => {
      return {key, id: key, label, items: []};
    });
  }

  function importAnswers(question, answers, lists) {
    const convertedKey = convertKey(question.key);

    if (!answers[convertedKey]?.lists) {
      return lists;
    }

    for (const answerList of answers[convertedKey].lists) {
      const list = lists.find(list => list.key === answerList?.key);
      if (!list) {
        continue;
      }
      list.items = answerList?.values?.map(item => {
        return {
          id: item.key,
          ...item,
        };
      });
    }

    return lists;
  }

  const onDrop = () => {
    const newItems = lists?.find(list => list.key === defaultListId)?.items || [];
    onValueChosen(question, {
      values: newItems,
      lists: lists.map(list => mapList(list)),
    });
  };

  return [lists, setLists, onDrop];
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {_ProfileExercise} from '@modules/Profile/types/exercises.model';
import {startServiceNextRoundAction} from '@modules/Profile/util/exercisesActionsUtil';
import {getSurveyFlow, isOldSoftfact} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _StartNextRoundDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  roundIndex: number;
  profileData: _ProfileExercise[];
  service: string;
}

const StartNextRoundDialog: React.FC<_StartNextRoundDialogProps> = ({
  open,
  onClose,
  roundIndex,
  profileData,
  service,
}) => {
  const navigateToFlow = useNavigateToFlow();
  const isLegacySoftfact = service && isOldSoftfact(service);

  const onStartNewRound = async (): Promise<void> => {
    try {
      dispatchReactEvent('navigate.start');
      const result = await startServiceNextRoundAction(service, parseInt(roundIndex?.toString(), 10) + 1);
      if (!result) {
        return;
      }
      navigateToFlow(getSurveyFlow(service).name);
    } catch (e) {
      logger.error('ProfileServiceGraphItem', e);
    }
  };

  const handleConfirmation = async (): Promise<void> => {
    if (!service || !profileData) {
      return;
    }
    await onStartNewRound();
    onClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      showCloseButton
      confirmLabel={
        isLegacySoftfact
          ? trans('profile_exercises.dialogs.next_round.cta_legacy')
          : trans('profile_exercises.dialogs.next_round.cta')
      }
      onConfirm={() => {
        void handleConfirmation();
      }}
      confirmProps={{width: 'relative-col-width-3'}}
      justifyContent="center"
      justifyButtons="center"
      image="/assets/img/design_images/your_development.svg"
      imageProps={{style: {height: 348}}}
      title={
        isLegacySoftfact
          ? trans('profile_exercises.dialogs.next_round.title_legacy')
          : trans('profile_exercises.dialogs.next_round.title')
      }
    >
      <Typography
        variant="body1"
        className="text-center"
        dangerouslySetInnerHTML={{
          __html: isLegacySoftfact
            ? trans('profile_exercises.dialogs.next_round.message_legacy')
            : trans('profile_exercises.dialogs.next_round.message'),
        }}
      />
    </Dialog>
  );
};

export default StartNextRoundDialog;

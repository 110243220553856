// @ts-nocheck
/* eslint-disable */

import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {LOCATION_CHANGED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';
import {ProfileAlert} from '@modules/Profile/components/alerts/ProfileAlert';
import {useGlobalAlerts} from '@modules/Profile/providers/globalAlerts';
import {useGlobalTutorials} from '@modules/Profile/providers/globalTutorials';
import {TutorialFlowContainer} from '@modules/Tutorials/components/TutorialFlowContainer';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

interface _DialogContextProps {
  addTutorial: (tutorial: _Tutorial, switchView?: boolean, onClose?: () => void) => void;
  addAlerts: (alerts: _ProfileAlert[]) => void;
  addTutorials: (tutorials: _Tutorial[]) => void;
}

interface _DialogEntry {
  type: 'alert' | 'tutorial';
  data: _ProfileAlert | _Tutorial;
}

const GlobalProfileDialogContext = createContext<_DialogContextProps | undefined>(undefined);

export const ProfileGlobalDialogProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [currentView, setCurrentView] = useState<'alert' | 'tutorial' | null>(null);

  const {
    addAlerts: addAlertsInternal,
    getCurrentAlert,
    resetAlerts,
    getEnrichedDialogConfig,
    currentIndex,
    setDialogConfig,
    currentAlertId,
    alerts: allAlerts,
  } = useGlobalAlerts(() => {
    setCurrentView('tutorial');
  });

  const {
    addTutorials: addTutorialsInternal,
    getCurrentTutorial,
    resetTutorials,
    getEnrichedDialogConfig: getEnrichedTutorialDialogConfig,
    currentIndex: tutorialCurrentIndex,
    setDialogConfig: setTutorialDialogConfig,
    currentTutorialId,
    addTutorial: addTutorialInternal,
    tutorials: allTutorials,
  } = useGlobalTutorials(() => {
    setCurrentView(null);
  });

  useEffect(() => {
    if (Object.keys(allAlerts ?? {})?.length > 0) {
      setCurrentView('alert');
      return;
    }

    if (Object.keys(allTutorials ?? {})?.length > 0) {
      setCurrentView('tutorial');
      return;
    }

    setCurrentView(null);
  }, [allTutorials, allAlerts]);

  // Reset on route change
  useEffect(() => {
    const onRouteChange = (): void => {
      resetAlerts();
      resetTutorials();
    };

    addReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);

    return () => {
      removeReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);
    };
  }, [resetAlerts, resetTutorials]);

  const addTutorial = useCallback(
    (tutorial: _Tutorial, switchView?: boolean, onClose?: () => void): void => {
      if (!tutorial?.id) {
        return;
      }
      addTutorialInternal(tutorial.id, tutorial, onClose);

      if (!currentView || switchView || !currentAlertId) {
        setCurrentView('tutorial');
      }
    },
    [addTutorialInternal, currentAlertId, currentView, setCurrentView]
  );

  const addAlerts = useCallback(
    (alerts: _ProfileAlert[]): void => {
      addAlertsInternal(alerts);
      if (!alerts?.length) {
        return;
      }
      setCurrentView('alert');
    },
    [addAlertsInternal, setCurrentView]
  );

  const addTutorials = useCallback(
    (tutorials: _Tutorial[]): void => {
      addTutorialsInternal(tutorials);
      if (!currentView || !currentAlertId) {
        setCurrentView('tutorial');
      }
    },
    [addTutorialsInternal, currentAlertId, currentView, setCurrentView]
  );

  const getCurrentDialog = (): _DialogEntry | null => {
    if (currentView === 'alert') {
      return {
        type: 'alert',
        data: getCurrentAlert(),
      };
    }

    if (currentView === 'tutorial') {
      return {
        type: 'tutorial',
        data: getCurrentTutorial(),
      };
    }

    return null;
  };

  const dialogContentComponent = useMemo(() => {
    const currentDialog = getCurrentDialog();
    if (!currentDialog) {
      return null;
    }
    switch (currentDialog.type) {
      case 'alert':
        return <ProfileAlert alert={currentDialog.data as _ProfileAlert} updateCurrentConfig={setDialogConfig} />;
      case 'tutorial':
        return (
          <TutorialFlowContainer
            open
            withTimeout
            tutorial={currentDialog.data as _Tutorial}
            updateExternalDialogConfig={setTutorialDialogConfig}
            onDone={
              (currentDialog.data as _Tutorial)?.type === 'dialog'
                ? undefined
                : getEnrichedTutorialDialogConfig()?.onClose
            }
          />
        );
      default:
        return null;
    }
  }, [
    currentIndex,
    currentAlertId,
    tutorialCurrentIndex,
    currentTutorialId,
    currentView,
    getCurrentDialog,
    setDialogConfig,
    setTutorialDialogConfig,
    getEnrichedTutorialDialogConfig,
  ]);

  const contextValue = useMemo(() => ({addTutorial, addAlerts, addTutorials}), [addTutorial, addAlerts, addTutorials]);

  const currentDialog = getCurrentDialog();

  const showDialogForAlert = currentDialog?.type === 'alert' && !!currentDialog.data;
  const showDialogForTutorial =
    currentDialog?.type === 'tutorial' && (currentDialog.data as _Tutorial)?.type === 'dialog';

  const showTutorialRaw = currentDialog?.type === 'tutorial' && (currentDialog.data as _Tutorial)?.type !== 'dialog';

  const enrichedDialogConfig = currentView === 'alert' ? getEnrichedDialogConfig() : getEnrichedTutorialDialogConfig();

  logger.debug('ProfileGlobalDialogProvider', {
    currentDialog,
    currentView,
    currentAlertId,
    currentIndex,
    showDialogForTutorial,
    showDialogForAlert,
    enrichedDialogConfig,
    dialogContentComponent,
    showTutorialRaw,
  });

  return (
    <GlobalProfileDialogContext.Provider value={contextValue}>
      {children}
      {(showDialogForAlert || showDialogForTutorial) && (
        <Dialog
          size="md"
          {...enrichedDialogConfig}
          fixedHeight
          stickyFooter
          stickyHeader
          open={showDialogForAlert || showDialogForTutorial}
        >
          {dialogContentComponent}
        </Dialog>
      )}
      {showTutorialRaw && dialogContentComponent}
    </GlobalProfileDialogContext.Provider>
  );
};

export const useProfileGlobalDialog = (): _DialogContextProps => {
  const context = useContext(GlobalProfileDialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a ProfileGlobalDialogProvider');
  }
  return context;
};

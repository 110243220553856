// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {_ProfileLevel} from '@modules/Core/types/gamification.model';
import {getGamificationImage} from '@modules/Core/util/imagesUtil';
import {copyObject} from '@modules/Core/util/util';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {
  BaseRow,
  LeaderboardExerciseRow,
  LeaderboardLevelRow,
  LeaderboardSoftfactRow,
} from '@modules/Statistics/components/general/leaderboards/LeaderboardRows';
import {trans} from '@modules/Translations/util/i18n';

export interface _LeaderboardProfile extends _ShortProfile {
  level: _ProfileLevel;
  totalExperience: number;
  totalExercises: number;
  totalSoftfacts: number;
  position?: string;
}

export interface _LeaderboardItemProps {
  profiles: _LeaderboardProfile[];
  // Profile ids
  showLevels?: boolean;
  currentProfileId?: string;
  supportedMetrics: Record<string, _MetricTab>;
  tabsPrefix?: React.ReactNode;
}

interface _MetricTab {
  label: string;
  icon: string;
}

export const LeaderboardItem: React.FC<_LeaderboardItemProps> = ({
  supportedMetrics,
  currentProfileId,
  showLevels,
  profiles,
  tabsPrefix,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [tabs, setTabs] = useState<Record<string, _MetricTab>>({});
  const [order, setOrder] = useState<string[]>([]);

  useEffect(() => {
    const newTabs = copyObject(supportedMetrics) ?? {};

    if (showLevels) {
      // add as first element
      newTabs.levels = {
        label: trans('gamification.xp_points'),
        icon: 'achievements/xp',
      };
    }

    const firstTab = showLevels ? 'levels' : Object.keys(supportedMetrics)[0] ?? null;
    setActiveTab(firstTab);
    setTabs(newTabs);
    // ORDER SHALL BE SET !!
    setOrder(showLevels ? ['levels', ...Object.keys(supportedMetrics ?? {})] : Object.keys(supportedMetrics ?? {}));
  }, [supportedMetrics, showLevels]);

  return (
    <div className="flex flex-col gap-3">
      {order?.length > 1 && (
        <div className="sticky bg-white500 flex flex-col gap-3" style={{top: 0, zIndex: 1}}>
          {tabsPrefix}
          <Tabs
            tabs={order?.map(tabName => {
              const tab = tabs[tabName];
              return {
                key: tabName,
                displayValue: tab.label,
                iconElement: <img src={getGamificationImage(tab.icon, 'png')} className="w-3" />,
              };
            })}
            activeTab={activeTab}
            setActiveTab={(tab: string) => setActiveTab(tab)}
          />
        </div>
      )}
      {activeTab === 'levels' && (
        <LeaderboardWrapper
          profiles={profiles}
          profileRowComponent={LeaderboardLevelRow}
          orderBy={profile => profile.totalExperience}
          currentProfileId={currentProfileId}
        />
      )}

      {activeTab === 'exercisesCompleted' && (
        <LeaderboardWrapper
          profiles={profiles}
          profileRowComponent={LeaderboardExerciseRow}
          orderBy={profile => profile.totalExercises}
          currentProfileId={currentProfileId}
        />
      )}

      {activeTab === 'softfactsCompleted' && (
        <LeaderboardWrapper
          profiles={profiles}
          profileRowComponent={LeaderboardSoftfactRow}
          orderBy={profile => profile.totalSoftfacts}
          currentProfileId={currentProfileId}
        />
      )}
    </div>
  );
};

interface _WrapperProps {
  profiles: _LeaderboardProfile[];
  // profile ids order
  orderBy: (profile: _LeaderboardProfile) => number;
  profileRowComponent?: React.FC<{
    profile: _LeaderboardProfile;
    index: number;
    currentProfileRef?: React.RefObject<HTMLDivElement>;
    children?: React.ReactNode;
  }>;
  currentProfileId?: string;
}

const LeaderboardWrapper: React.FC<_WrapperProps> = ({currentProfileId, profiles, orderBy, profileRowComponent}) => {
  const currentProfileRef = useRef<HTMLDivElement>(null);
  const Component = profileRowComponent ?? BaseRow;

  useLayoutEffect(() => {
    if (currentProfileRef.current) {
      setTimeout(() => {
        // In case it changed
        if (!currentProfileRef.current) return;
        currentProfileRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      }, 500);
    }
  }, [currentProfileId, profiles]);
  if (!Component) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-col gap-3">
        {profiles
          ?.sort((a, b) => orderBy(b) - orderBy(a))
          ?.map((profile, index) => {
            const isCurrentProfile = profile?.id === currentProfileId;
            return (
              <Component
                profile={profile}
                index={index}
                key={profile.id}
                currentProfileRef={isCurrentProfile ? currentProfileRef : undefined}
              />
            );
          })}
      </div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {Typography} from '@modules/Core/components/base/Typography';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDate} from '@modules/Core/util/dates';
import {logger} from '@modules/Core/util/Logger';
import {actionReactivateSubscription} from '@modules/Core/util/payment/paymentActionsUtil';
import {changeCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {dispatchAction, refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

export function useReactivateSub() {
  const snackbar = useSnackbar();
  const dialog = useDialog();

  async function onReactivateCancelledSubscription(
    institutionId: string,
    subscriptionId: string,
    profileIds: string[],
    currentSubscription: _Subscription | null,
    toBeActivatedSubscription: _Subscription | null,
    callback?: () => void
  ) {
    const isBillingIntervalChange =
      !!toBeActivatedSubscription?.plan_id && toBeActivatedSubscription?.plan_id === currentSubscription?.plan_id;

    const message = isBillingIntervalChange
      ? 'subscription.confirm_change_reactivation.message'
      : 'subscription.confirm_reactivation.message';

    const title = isBillingIntervalChange
      ? 'subscription.confirm_change_reactivation.title'
      : 'subscription.confirm_reactivation.title';

    const params: Record<string, string> = {};

    if (isBillingIntervalChange) {
      params.expiry = formatDate(new Date(toBeActivatedSubscription?.pivot?.date));
      params.plan = trans(`subscription.types.${currentSubscription?.type}`);
      params.newPlan = trans(`subscription.types.${toBeActivatedSubscription?.type}`);
    } else {
      params.expiry = formatDate(new Date(currentSubscription?.pivot?.date));
      params.plan = trans(`subscription.plans.${currentSubscription?.plan_id}.title`);
      params.billingInterval = trans(`subscription.types.${currentSubscription?.type}`);
    }

    try {
      dialog.show({
        title: trans(title),
        children: (
          <Typography
            dangerouslySetInnerHTML={{
              __html: trans(message, params),
            }}
          />
        ),
        onConfirm: async () => {
          await doReactivateCancelledSubscription(institutionId, subscriptionId, profileIds);
          callback?.();
        },
      });
    } catch (e) {
      logger.error(e);
    }
  }

  async function doReactivateCancelledSubscription(
    institutionId: string,
    subscriptionId: string,
    profileIds: string[]
  ): Promise<void> {
    const response = await actionReactivateSubscription({
      profileIds,
      subscriptionId,
    });

    if (response?.status === ACTION_STATUS_SUCCESS) {
      snackbar.success(trans('subscription.reactivation_success'));
      await Promise.all([refreshReduxState(), dispatchAction(changeCurrentInstitution(institutionId))]);
    }
  }

  return onReactivateCancelledSubscription;
}

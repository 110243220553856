// @ts-nocheck
/* eslint-disable */

import {useMemo} from 'react';
import {usePathname, useSearchParams} from 'next/navigation';
import {queryToDict} from '@modules/Navigation/util/navigationUtil';

export function useLocation(): {pathname: string; search: string; searchAsDict: Record<string, string>} {
  const pathName = usePathname();
  const query = useSearchParams();

  return useMemo(
    () => ({
      pathname: pathName,
      search: query.toString(),
      searchAsDict: queryToDict(query.toString()),
    }),
    [pathName, query]
  );
}

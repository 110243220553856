// @ts-nocheck
/* eslint-disable */

export function getStatisticImage(name: string): string {
  if (!name) {
    return '';
  }
  // if ends with svg, remove
  if (name.endsWith('.png')) {
    name = name.slice(0, -4);
  }

  return `/assets/img/gamification/statistic/${name}.png`;
}

/**
 * @param name
 * @param extension
 */
export function getDesignImage(name: string, extension?: string): string {
  return getImage(`design_images/${name}`, extension);
}

export function getAchievementImage(name: string, extension?: string): string {
  return getImage(`gamification/achievements/${name}`, extension);
}

export function getLevelImage(name: string, extension?: string): string {
  return getImage(`gamification/levels/${name}`, extension);
}

export function getGamificationImage(name: string, extension?: string): string {
  return getImage(`gamification/${name}`, extension);
}

export function getImage(name: string, extension?: string): string {
  if (!name) {
    return '';
  }

  if (extension && !name.endsWith(extension)) {
    name = `${name}.${extension}`;
  }

  return `/assets/img/${name}`;
}

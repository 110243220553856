// @ts-nocheck
/* eslint-disable */

import React from 'react';

export const SurveyImageContent = ({contentGroup}) => (
  <>
    {(contentGroup?.elements || []).map((el, i) => (
      <div className="flex items-center justify-center" key={i}>
        <img
          src={el?.url}
          alt={el?.alt}
          className={`inline-block w-auto rounded-lg h-auto my-8 max-h-48 mx-auto ${el?.class || ''}`}
        />
      </div>
    ))}
  </>
);

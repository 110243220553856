// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';

/**
 * UseEffect for async functions
 * @param effect
 * @param deps
 */
export function useEffectAsync(effect: () => Promise<void>, deps: any[]): void {
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await effect();
    };
    void fetchData();
  }, deps);
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Typography} from '@modules/Core/components/base/Typography';
import {Button} from '../base/buttons/Button';
import {Section} from './Section';

interface _Props {
  title: string;
  content: string;
  image: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  titleVariant?: string;
  direction?: 'horizontal' | 'vertical';
  fullWidth?: boolean;
  asHtml?: boolean;
  alignImage?: 'start' | 'center';
}

export const PlaceholderSection: React.FC<_Props> = ({
  asHtml,
  title,
  content,
  image,
  buttonLabel,
  onButtonClick,
  titleVariant = 'h6',
  direction = 'horizontal',
  alignImage,
  fullWidth,
}) => {
  const alignedImageCalculated = alignImage ?? (direction === 'vertical' ? 'center' : null);
  return (
    <Section
      imageHeight="h-[235px]"
      justifyContent={alignedImageCalculated}
      fullWidth
      fullHeight
      imageViewDirection={direction}
      title={title}
      titleVariant={titleVariant}
      image={image}
    >
      <div className={twMerge('flex flex-col gap-2', direction === 'vertical' ? 'items-center' : 'items-start')}>
        <Typography
          className={direction === 'vertical' ? 'text-center  whitespace-pre-line max-w-[80%] break-words' : ''}
        >
          {asHtml ? (
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          ) : (
            content
          )}
        </Typography>
      </div>
      <>
        {buttonLabel && onButtonClick && (
          <div className="flex flex-row items-center">
            <Button width="relative-col-width-3" label={buttonLabel} onClick={onButtonClick} />
          </div>
        )}
      </>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileLevel} from '@modules/Core/types/gamification.model';
import {getAchievementImage, getLevelImage} from '@modules/Core/util/imagesUtil';

interface ExperienceCountProps {
  level: _ProfileLevel;
  size: number;
  type: 'level' | 'achievement';
}

export const ProfileLevelImage: React.FC<ExperienceCountProps> = ({type, level, size = 3}) => {
  if (!level?.image) {
    return null;
  }
  const image = type === 'level' ? getLevelImage(level.image, 'png') : getAchievementImage(level.image, 'png');
  return <img src={image} className={`h-${size}`} />;
};

export default ProfileLevelImage;

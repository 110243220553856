// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import {Chart, registerables} from 'chart.js';
import {trans} from '@modules/Translations/util/i18n';

Chart.register(...registerables);

interface _Props {
  chartData: Array<Array<{score: number}>>;
  title: string;
}

export const TeamDevelopmentResultSectionChart: React.FC<_Props> = ({chartData, title}) => {
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);
  const primaryColor = '#FF5C5C';
  const secondaryColor = '#0D222F';
  const height = 300;

  useEffect(() => {
    const initChart = (ctx: CanvasRenderingContext2D, params: any): void => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, params);
    };

    const ctx = chartContainer.current?.getContext('2d');
    if (ctx) {
      const data = {
        labels: [trans('development_result.first_measurement'), trans('development_result.second_measurement')],
        datasets: [
          {
            label: trans('base.you'),
            data: [chartData[0]?.[0]?.score || 0, chartData[1]?.[0]?.score || 0],
            backgroundColor: [primaryColor, primaryColor],
          },
          {
            label: trans('organisation.team'),
            data: [chartData[0]?.[1]?.score || 0, chartData[1]?.[1]?.score || 0],
            backgroundColor: [secondaryColor, secondaryColor],
          },
        ],
      };

      const config = {
        type: 'bar' as const,
        data,
        options: {
          color: '#000',
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: 'Level',
                color: '#000',
              },
              min: 0,
              max: 3,
              ticks: {
                stepSize: 1,
                color: '#000',
                callback(value: number) {
                  return value === 0 ? '' : value;
                },
              },
            },
            x: {
              ticks: {
                color: '#000',
              },
            },
          },
          plugins: {
            legend: {
              position: 'top' as const,
              display: false,
            },
            title: {
              display: true,
              text: title,
              color: '#000',
            },
            datalabels: {
              display: false,
            },
          },
        },
      };

      initChart(ctx, config);
    }
  }, [chartData, title]);

  return (
    <div className="w-full">
      <canvas ref={chartContainer} width="100%" />
    </div>
  );
};

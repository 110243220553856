// @ts-nocheck
/* eslint-disable */

import React, {useMemo} from 'react';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {ProfileOldServiceChallengesGoalsReady} from '@modules/ContentEngine/components/items/profile/result/common/ProfileOldServiceChallengesGoalsReady';
import {Section} from '@modules/Core/components/layout/Section';
import {_TutorialProps} from '@modules/Profile/components/tutorials/StartTutorialButton';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  mode: 'view' | 'edit';
  journalKey: string;
  tutorialProps: _TutorialProps;
  serviceStatus: _ServiceStatus;
  profileExercises: _ProfileExerciseCE;
  view: 'shared' | 'result';
}

export const ProfileValuesHeader: React.FC<_Props> = ({
  view,
  serviceStatus,
  profileExercises,
  mode,
  journalKey,
  tutorialProps,
}) => {
  const title = useMemo(() => {
    if (mode === 'edit') {
      return trans('profile_result.define_your_values');
    }
    return trans('personal_values.your_values_title');
  }, [mode]);

  const description = useMemo(() => {
    if (mode === 'edit') {
      return trans('personal_values.individual_values_selection.description');
    }

    return trans('profile_modules.services.profile_values.description');
  }, [mode]);

  const isShareMode = view === 'shared';
  return (
    <Section
      tutorialProps={tutorialProps}
      title={title}
      description={description}
      journalKey={journalKey}
      descriptionVariant="body1"
    >
      {Boolean(mode === 'view' && !isShareMode) && (
        <ProfileFeedbackItem
          dialogTitle={trans('profile_result.feedback.dialog_title')}
          dialogDescription={trans('profile_result.feedback.dialog_description')}
          dialogConfirmText={trans('profile_result.feedback.dialog_confirm_text')}
          feedbackKey="softfacts:profile_values:result"
        />
      )}
      {!isShareMode && (
        <ProfileOldServiceChallengesGoalsReady serviceStatus={serviceStatus} profileExercises={profileExercises} />
      )}
    </Section>
  );
};

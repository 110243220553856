// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProfileResultDisclaimer} from '@modules/Profile/components/services/result/common/ProfileResultDisclaimer';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {isOldSoftfact} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const MAX_ROUND_INDEX = 2;

interface _Props {
  serviceStatus: _ServiceStatus;
  available: boolean;
}

export const ProfileServiceNextRoundReady: React.FC<_Props> = ({serviceStatus, available}) => {
  const [nextRoundReady, setNextRoundReady] = useState<boolean>(false);

  useEffect(() => {
    setNextRoundReady(!!serviceStatus?.next_round_ready);
  }, [serviceStatus]);

  if (!nextRoundReady) {
    return null;
  }

  let title;
  const isLegacySF = isOldSoftfact(serviceStatus.service);

  if (serviceStatus.current_round! >= MAX_ROUND_INDEX) {
    title = isLegacySF ? 'profile_exercises.last_round_ready_old' : 'profile_exercises.last_round_ready';
  } else {
    title = isLegacySF ? 'profile_exercises.next_round_ready_old' : 'profile_exercises.next_round_ready';
  }

  return (
    <ProfileResultDisclaimer
      content={
        available
          ? trans(title, {
              round: serviceStatus.current_round! + 1,
              softfact: trans(PROFILE_SERVICES_TITLES[serviceStatus.service]),
            })
          : trans('profile_exercises.next_round_locked', {
              round: serviceStatus.current_round! + 1,
              softfact: trans(PROFILE_SERVICES_TITLES[serviceStatus.service]),
            })
      }
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';

interface _CardProps {
  title?: string;
  titlePrefix?: React.ReactNode;
  titleSuffix?: React.ReactNode;
  children?: React.ReactNode;
  titleVariant?: _TypographyVariants;
  className?: string;
  titleClassName?: string;
  width?: string;
  height?: string;
  gap?: number;
  padding?: number;
  onClick?: boolean;
  preChildren?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export const Card: React.FC<_CardProps> = ({
  onClick,
  titleClassName,
  className,
  title,
  titlePrefix,
  titleSuffix,
  children,
  titleVariant = 'body1',
  width,
  gap = 3,
  padding = 3,
  preChildren,
  containerRef,
  height,
}) => (
  <div
    ref={containerRef}
    className={addCN(
      'flex flex-col items-start rounded-sm border-1 border-grey300 bg-white500',
      `gap-${gap}`,
      `p-${padding}`,
      className ?? '',
      onClick ? 'cursor-pointer hover:bg-grey100' : '',
      width !== null && width !== undefined ? width : 'w-full',
      height !== null && height !== undefined ? height : 'h-full'
    )}
    onClick={onClick}
  >
    {preChildren}
    {(title ?? titlePrefix ?? titleSuffix) && (
      <div className={addCN('flex flex-row gap-1', titleClassName)}>
        {titlePrefix && titlePrefix}
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        {titleSuffix && titleSuffix}
      </div>
    )}

    {children}
  </div>
);

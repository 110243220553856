// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {PlanPerksComponent} from '@modules/Core/components/platform/payments/paymentPlan/PlanPerksComponent';
import {usePaymentPlans} from '@modules/Core/hooks/payments/PaymentPlans';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {goToMembershipCoachingSession} from '@modules/Core/util/routingUtil';
import {isWorkshopsCoachingPlan} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentWorkshopsVouchersProps {}

export const PaymentWorkshopsVouchers: React.FC<_PaymentWorkshopsVouchersProps> = () => {
  const plans = usePaymentPlans();
  const [workshopsCoachingPlans, setWorkshopsCoachingPlans] = useState<_PaymentPlan[]>([]);

  useEffect(() => {
    setWorkshopsCoachingPlans(plans.filter(p => isWorkshopsCoachingPlan(p)) ?? []);
  }, [plans]);

  return (
    <Section>
      {!!workshopsCoachingPlans.length && (
        <Section title={trans('subscription.workshops_and_team_coaching')}>
          {workshopsCoachingPlans.map(plan => (
            <Section title={trans(plan.title)} titleVariant="h6">
              <PlanPerksComponent key={plan.id} plan={plan} />
            </Section>
          ))}

          <div className="flex flex-row justify-end">
            <div className="relative-col-width-2">
              <Button onClick={() => goToMembershipCoachingSession()} label={trans('subscription.reach_out')} />
            </div>
          </div>
        </Section>
      )}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import {Action, Serializer} from 'survey-core';
import {ICreatorOptions, localization} from 'survey-creator-core';
import {SurveyInstanceCreatedEvent} from 'survey-creator-core/typings/creator-events-api';
import {SurveyCreator as SurveyCreatorExternal, SurveyCreatorComponent} from 'survey-creator-react';
import './SurveyCreator.css';
import './SurveyCreatorTabs.css';
import './SurveyCreatorSidebar.css';
import {twMerge} from 'tailwind-merge';
import {logger} from '@modules/Core/util/Logger';
import {SurveyJSJson} from '@modules/CustomSurveys/types/surveyJs.model';
import '@modules/CustomSurveys/components/answer/CustomSurveyAnswer.css';
import {useRegisterSurveyJSIcons} from '@modules/CustomSurveys/hooks/RegisterSurveyJSIcons';
import {onTextMarkdown} from '@modules/CustomSurveys/util/surveyJSEventsHandlersUtil';
import {StartTutorialButton} from '@modules/Profile/components/tutorials/StartTutorialButton';
import {trans} from '@modules/Translations/util/i18n';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {ReactElementFactory} from 'survey-react-ui';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';
import 'survey-creator-core/survey-creator-core.i18n';

interface SurveyCreatorProps {
  surveyId?: string;
  existingSurvey?: SurveyJSJson | null | undefined;
  onChange: (json: SurveyJSJson, surveyId?: string) => void;
  language?: string;
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

const allowedQuestionTypes = ['checkbox', 'radiogroup', 'text', 'comment', 'rating'];
const allowedActions = ['action-undo', 'action-redo'];
const SurveyCreator: React.FC<SurveyCreatorProps> = ({
  context,
  tutorialKey,
  tutorialService,
  language,
  existingSurvey,
  surveyId,
  onChange,
}) => {
  const [creator, setCreator] = useState<SurveyCreatorExternal | null>(null);
  const initiallyLoaded = useRef<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('designer');
  useChangeAfterElementColor();
  useRegisterSurveyJSIcons();

  // Register tutorial button
  useEffect(() => {
    logger.debug('locale serializer choice for checkboxes:', {
      kek: Serializer.getProperty('checkbox', 'choices'),
    });
    if (!tutorialKey || !tutorialService || !context?.tutorials) {
      return;
    }
    if (ReactElementFactory.Instance.isElementRegistered('tutorial-button')) {
      logger.debug('Tutorial button already registered');
      return;
    }
    ReactElementFactory.Instance.registerElement('tutorial-button', props => (
      <StartTutorialButton
        size="md"
        tutorials={context?.tutorials}
        tutorialKey={tutorialKey}
        id={getTutorialTriggerId(tutorialService)}
        withTooltip={false}
      />
    ));

    return () => {};
  }, [context, tutorialKey, tutorialService]);

  useEffect(() => {
    if (creator?.survey && language) {
      creator.survey.locale = language;
    }
  }, [language, creator]);
  // Set the locale for the survey creator
  useEffect(() => {
    localization.currentLocale = language ?? 'de';
    const currentLocale = localization.getLocale(language ?? 'de');
    currentLocale.ed.surveyPlaceHolder = '';
    const locale = localization.getLocale();
    locale.pv.rateType.labels = trans('surveys.softfact_rating');
    locale.pe.otherText = 'test other';
    logger.debug('Locale', {locale, currentLocale});
  }, [language]);

  // Setup survey logo and text type inputTypes to just text
  useEffect(() => {
    Serializer.removeProperty('survey', 'logo');
    Serializer.removeProperty('text', 'inputType');
  }, []);

  // Inits creator
  useEffect(() => {
    if (initiallyLoaded.current || existingSurvey === undefined || !language) {
      return;
    }
    const creatorOptions: ICreatorOptions = {
      showHeaderInEmptySurvey: true,
      showErrorOnFailedSave: false,
      isAutoSave: false,
      showPreviewTab: true,
      showJSONEditorTab: false,
      showSidebar: false,
      showSimulatorInPreviewTab: false,
      showInvisibleElementsInPreviewTab: false,
      themeBuilder: {
        allowInheritWidgets: false,
        isThemePaletteShortcut: false,
        showCustomClasses: false,
        showInheritedThemePlaceholder: false,
        showThemeSwitcher: false,
      },
      // Limit question types in the toolbox
      questionTypes: allowedQuestionTypes,
      // Hide search in the toolbox
      toolbox: {
        allowExpandMultipleCategories: true,
        keepAllCategoriesExpanded: true,
        showSearchBox: false,
      },
      pageEditMode: 'single',
      showPagesInPreviewTab: false,
    };

    const newCreator = initializeCreator(creatorOptions, language, existingSurvey);

    setCreator(newCreator);
    initiallyLoaded.current = true;
  }, [existingSurvey, language]);

  // Inits creator callbacks
  useEffect(() => {
    if (creator) {
      const handleSurveyChange = (sender, options): void => {
        const surveyJson = creator.JSON;

        if (options.name === 'rateType' && options.target.getType() === 'rating' && options.newValue === 'labels') {
          logger.debug('Survey JSON', {
            surveyJson,
            sender,
            options,
            reason: options.reason,
          });
          // options.target.rateValues = likeRtValues;
        }
        onChange(surveyJson as SurveyJSJson, surveyId);
      };

      const handleSurveyInstanceCreated = (sender: any, options: SurveyInstanceCreatedEvent) => {
        options.survey.showQuestionNumbers = false;
        options.survey.showCompleteButton = false;
        options.survey.onTextMarkdown.add(onTextMarkdown);
        options.survey.locale = language;
        // options.survey.onPropertyChanged.add(function (sender, options) {
        //   logger.debug('Property changed', {options, sender});
        // });
      };

      const handleTabChange = (_: any, options: any) => {
        setActiveTab(options.tabName);
      };

      const handlelikeRT = (sender, options) => {
        logger.debug('Question added', {
          type: options.question.getType(),
          options,
        });
        if (options.question.getType() === 'rating') {
          // All rating questions should have this callback on change
          if (options.question.rateType === 'labels') {
            options.question.rateValues = [
              {value: 1, text: trans('surveys.rating.0')},
              {value: 2, text: trans('surveys.rating.1')},
              {value: 3, text: trans('surveys.rating.2')},
              {value: 4, text: trans('surveys.rating.3')},
              {value: 5, text: trans('surveys.rating.4')},
            ];
          }
        }
      };

      creator.onModified.add(handleSurveyChange);
      creator.onActiveTabChanged.add(handleTabChange);
      creator.onSurveyInstanceCreated.add(handleSurveyInstanceCreated);
      creator.onQuestionAdded.add(handlelikeRT);

      return () => {
        creator.onModified.remove(handleSurveyChange);
        creator.onActiveTabChanged.remove(handleTabChange);
        creator.onSurveyInstanceCreated.remove(handleSurveyInstanceCreated);
        creator.onQuestionAdded.remove(handlelikeRT);
      };
    }
  }, [creator, surveyId, onChange, language]);

  function initializeCreator(
    options: ICreatorOptions,
    language: string | undefined,
    existingSurvey: SurveyJSJson | null | undefined
  ): SurveyCreatorExternal {
    const creator = new SurveyCreatorExternal(options);
    creator.locale = language;
    creator.toolbox.forceCompact = false;
    if (existingSurvey) {
      creator.text = JSON.stringify(existingSurvey);
    }

    const newAction = new Action({
      id: 'tutorial',
      visible: true,
      component: 'tutorial-button',
      location: 'start',
      tutorialKey,
      tutorials: context?.tutorials,
      tutorialService,
      tooltip: null,
      locTooltipName: null,
    });
    // Filter out actions that are not allowed

    while (creator.toolbarItems.length > 0) {
      const index = creator.toolbarItems.findIndex(
        item => !(item.innerItem?.id && allowedActions.includes(item.innerItem.id))
      );

      if (index === -1) break;

      creator.toolbarItems.splice(index, 1);
    }
    // push before undo button, action.innerItem.id = action-undo
    const undoButton = creator.toolbarItems.find(item => item.innerItem?.id === 'action-undo');
    if (undoButton) {
      creator.toolbarItems.splice(creator.toolbarItems.indexOf(undoButton), 0, newAction);
    } else {
      creator.toolbarItems.push(newAction);
    }

    creator.onDefineElementMenuItems.add((_, options) => {
      const acceptedItems = ['delete', 'isrequired', 'settings', 'duplicate'];

      if (allowedQuestionTypes.includes(options?.obj?.getType())) {
        // filter items using acceptedItems
        options.items = options.items.filter(item => acceptedItems.includes(item?.innerItem?.id));
        options.items.forEach(item => {
          item.innerItem.visible = true;
        });
      }
    });

    return creator;
  }

  logger.debug('SurveyCreator', {
    existingSurvey,
    activeTab,
    creator,
    locale: creator?.locale,
    surveyLocale: creator?.survey?.locale,
  });

  return (
    <div
      className={twMerge(
        'flex flex-col w-full survey-tabs-container',
        'mb-3',
        activeTab === 'test' && 'survey-answer-container',
        activeTab === 'designer' && 'survey-creator-container survey-sidebar-container'
      )}
    >
      {creator && (
        <SurveyCreatorComponent
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          creator={creator}
        />
      )}
    </div>
  );
};

export default SurveyCreator;

// To change the color of an image loaded in an :after pseudo-element, css is crazy man.
const useChangeAfterElementColor = () => {
  useEffect(() => {
    const changeAfterBackgroundColor = () => {
      const elements = document.querySelectorAll('.sv-action-bar-item__title');

      elements.forEach(element => {
        const computedStyle = window.getComputedStyle(element, ':after');
        const currentBackgroundImage = computedStyle.getPropertyValue('background-image');

        if (currentBackgroundImage && currentBackgroundImage !== 'none') {
          const newBackgroundImage = currentBackgroundImage.replace(/fill='[^']*'/, "fill='%23909090'");

          const styleElement = document.createElement('style');
          styleElement.textContent = `
            .sv-action-bar-item__title::after {
              background-image: ${newBackgroundImage} !important;
            }
          `;

          document.head.appendChild(styleElement);
        }
      });
    };

    changeAfterBackgroundColor();

    // Optional: Add event listener for dynamic content
    const observer = new MutationObserver(changeAfterBackgroundColor);
    observer.observe(document.body, {childList: true, subtree: true});

    return () => {
      observer.disconnect();
    };
  }, []);
};

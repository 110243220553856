// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {_DateRangeType} from '@modules/Core/util/dateUtil';
import {logger} from '@modules/Core/util/Logger';
import {OrganisationTeamsOverviewListSection} from '@modules/Institution/components/dashboard/OrganisationTeamsOverviewListSection';
import {OrganisationTeamsPerformanceSection} from '@modules/Institution/components/dashboard/OrganisationTeamsPerformanceSection';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';

interface _Props {
  teams: Record<string, _InstitutionOverviewTeam>;
  experienceMetric: _ProfileTrackingStatisticEntry;
  howAreYouMetric: _ProfileTrackingStatisticEntry;
  filter?: _DateRangeType;
}

export const OrganisationTeamsOverviewItem: React.FC<_Props> = ({experienceMetric, howAreYouMetric, filter, teams}) => {
  const [chosenTeam, setChosenTeam] = useState<string | null>(null);

  logger.debug('OrganisationTeamsOverviewItem', {teams, experienceMetric});

  useEffect(() => {
    if (!chosenTeam) {
      setChosenTeam(Object.keys(teams ?? {})?.[0] ?? null);
    }
  }, [teams]);

  const showPlaceholder = !teams || Object.keys(teams).length === 0;

  return (
    <div className="flex flex-row w-full justify-between gap-3">
      <OrganisationTeamsOverviewListSection
        chosenTeam={chosenTeam}
        setChosenTeam={setChosenTeam}
        teams={teams}
        experienceMetric={experienceMetric}
        howAreYouMetric={howAreYouMetric}
        filter={filter}
        showPlaceholder={showPlaceholder}
      />

      <OrganisationTeamsPerformanceSection
        teams={teams}
        chosenTeam={chosenTeam}
        setChosenTeam={setChosenTeam}
        showPlaceholder={showPlaceholder}
      />
    </div>
  );
};

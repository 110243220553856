// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {ProfileWayOfWorkingMultiSelectResult} from '@modules/Profile/components/services/result/wayOfWorking/ProfileWayOfWorkingMultiSelectResult';
import {ProfileWayOfWorkingProductivityTimes} from '@modules/Profile/components/services/result/wayOfWorking/ProfileWayOfWorkingProductivityTimes';
import {
  _ProfileWayOfWorkingMultiSelectionResult,
  _ProfileWayOfWorkingProductivityTimes,
  _ProfileWayOfWorkingQuestionData,
} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  timeManagement: _ProfileWayOfWorkingMultiSelectionResult;
  productivityTimes: _ProfileWayOfWorkingProductivityTimes;
  questionData: _ProfileWayOfWorkingQuestionData;
}

const channelTypes = ['l_0'];
const origin = 'time_management_methods';
export const ProfileWayOfWorkingTimeManagementItem: React.FC<_Props> = ({
  questionData,
  timeManagement,
  productivityTimes,
}) => {
  logger.debug('ProfileWayOfWorkingTimeManagementItem', {timeManagement, productivityTimes, questionData});

  return (
    <div className="flex flex-row w-full gap-1">
      <Section title={trans('way_of_working.time_management_methods.title')} fullWidth>
        <ProfileWayOfWorkingMultiSelectResult
          origin={origin}
          data={timeManagement}
          channelTypes={channelTypes}
          showTitle={false}
          questionData={questionData}
        />
      </Section>

      <Section title={trans('profile_result.productivity_profile')} fullWidth>
        <ProfileWayOfWorkingProductivityTimes data={productivityTimes} />
      </Section>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconBell} from '@modules/Core/components/base/Icons/Icons';
import {Badge} from '../../Core/components/base/badge/Badge';

interface _NotificationBadge {
  value?: number;
}

export const NotificationBadge: React.FC<_NotificationBadge> = ({value = 0}) => {
  return (
    <div className="relative h-4 w-4">
      <div className="absolute top-[0px] right-[0px]">{value > 0 && <Badge value={value?.toString()} size="sm" />}</div>
      <div className="absolute bottom-[0px] left-[0px]">
        <IconBell />
      </div>
    </div>
  );
};

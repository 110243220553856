// @ts-nocheck
/* eslint-disable */

import React, {ForwardedRef, forwardRef, useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {ButtonClear, ButtonNextMonth, ButtonPrevMonth, ButtonSelectMonth, ButtonToday} from './Buttons';
import {DatePickerContext} from './DatePickerProvider';
import {dayOfTheWeekOf, firstDateOfMonth} from './utils';
import Days from './Views/Days';
import Decades from './Views/Decades';
import Months from './Views/Months';
import Years from './Views/Years';

const DatePickerContent = forwardRef<HTMLDivElement>((_props, ref: ForwardedRef<HTMLDivElement>) => {
  const {selectedMonth, selectedYear, view, options} = useContext(DatePickerContext);

  const language = options?.language ? options?.language : 'en';

  const locale = new Intl.Locale(language);

  const weekStart = locale?.weekInfo?.firstDay || 1;
  const firstOfMonth = firstDateOfMonth(selectedYear, selectedMonth, 1);
  const start = dayOfTheWeekOf(firstOfMonth, weekStart, weekStart);

  return (
    <div
      ref={ref}
      className={twMerge(
        'inline-block bg-white rounded-lg shadow-lg w-full',
        options?.theme?.background,
        options?.datepickerClassNames
      )}
    >
      <div>
        {options?.title && (
          <div
            className={twMerge(
              'px-2 py-3 font-semibold text-center text-grey700 dark:text-white',
              options?.theme?.text
            )}
          >
            {options?.title}
          </div>
        )}
        <div className="flex justify-between mb-2">
          <ButtonPrevMonth />
          <ButtonSelectMonth allowViewChange={false} />
          <ButtonNextMonth />
        </div>
      </div>
      <div className="p-1">
        {view === 'days' && <Days start={start} />}
        {view === 'months' && <Months />}
        {view === 'years' && <Years />}
        {view === 'decades' && <Decades />}
      </div>
      {(options?.todayBtn ?? options?.clearBtn) && (
        <div className="flex mt-2 space-x-2">
          {options?.todayBtn && <ButtonToday />}
          {options?.clearBtn && <ButtonClear />}
        </div>
      )}
    </div>
  );
});
DatePickerContent.displayName = 'DatePickerContent';

export default DatePickerContent;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Tab} from '@modules/Core/components/base/Tab';

export interface _TabsItem {
  key: string;
  displayValue: string;
  iconElement?: React.ReactNode;
  icon?: React.ElementType;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  className?: string;
  color?: string;
}

interface _TabsProps {
  tabs: _TabsItem[];
  activeTab: string;
  setActiveTab: (key: string) => void;
  align?: 'center' | 'left' | 'right';
}

export const Tabs: React.FC<_TabsProps> = ({align = 'left', tabs, activeTab, setActiveTab}) => {
  return (
    <div
      className={twMerge(
        'flex flex-row w-full gap-3',
        align === 'center' ? 'justify-center' : align === 'left' ? 'justify-start' : 'justify-start'
      )}
    >
      {tabs.map(tab => (
        <div className="relative-col-width-3" key={tab.key}>
          <Tab
            label={tab.displayValue}
            active={activeTab === tab.key}
            iconElement={tab.iconElement}
            icon={tab.icon}
            iconPosition={tab.iconPosition}
            iconColor={tab.iconColor}
            onChange={() => setActiveTab(tab.key)}
            className={tab.className}
            color={tab.color}
          />
        </div>
      ))}
    </div>
  );
};

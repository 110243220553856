// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {BaseTask} from '@modules/Profile/components/exercises/tasks/BaseTask';
import {_ProfileExerciseStatus, _ProfileExerciseTask, _TaskData} from '@modules/Profile/types/exercises.model';
import 'react-quill/dist/quill.snow.css';
import './Tasks.scss';
import {RichTextTaskEditView} from '@modules/Profile/components/exercises/tasks/richtext/RichTextTaskEditView';
import {RichTextTaskDoneView} from '@modules/Profile/components/exercises/tasks/richtext/RichTextTaskDoneView';

interface _RichTextTaskProps {
  task: _TaskData;
  onChange?: (val: string) => void;
  profileAnswer?: _ProfileExerciseTask;
  exerciseStatus?: _ProfileExerciseStatus | null;
  showCorrectAnswer?: boolean | null;
  mode: 'edit' | 'view';
}

export const RichTextTask: React.FC<_RichTextTaskProps> = ({
  task,
  onChange,
  profileAnswer,
  exerciseStatus,
  showCorrectAnswer,
  mode,
}) => {
  const [value, setValue] = useState<string | null>(profileAnswer?.data as string);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);

  useEffect(() => {
    if (task?.correctAnswer) {
      setCorrectAnswer(task.correctAnswer);
    }
  }, [task]);

  useEffect(() => {
    if (profileAnswer?.data === value) {
      return;
    }

    setValue(profileAnswer?.data as string);
  }, [profileAnswer?.data]);

  const onValChange = (val: string): void => {
    setValue(val);
    onChange && onChange(val);
  };
  if (!task) {
    return null;
  }

  return (
    <BaseTask
      mode={mode}
      exerciseStatus={exerciseStatus}
      doneView={
        <RichTextTaskDoneView showCorrectAnswer={showCorrectAnswer} value={value} correctAnswer={correctAnswer} />
      }
      editView={<RichTextTaskEditView value={value} onChange={onValChange} />}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import ConsentDialog from '@modules/Core/components/platform/consent/ConsentDialog';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {useProfile} from '@modules/Profile/hooks/profile';

interface _ServiceInformationConsentProps {
  service: string;
  teamId: string;
  onNextClicked?: () => void;
  show: boolean;
  setShow: (value: boolean) => void;
}

export const ServiceInformationConsent: React.FC<_ServiceInformationConsentProps> = ({
  service,
  teamId,
  onNextClicked,
  show,
  setShow,
}) => {
  const [currentProfile] = useProfile();

  async function handleConsent(): Promise<void> {
    try {
      const method = 'service.consent';
      const params = {
        profileId: currentProfile?.id,
        service,
        teamId,
      };

      const response = await performFlowAction({method, params});

      if (response?.data?.status === 'success') {
        setShow(false);
        dispatchReactEvent('navigate.start');
        onNextClicked?.();
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
  }

  return (
    <ConsentDialog
      type={teamId ? 'team' : 'profile'}
      show={show}
      onCancel={() => setShow(false)}
      onConfirm={async () => await handleConsent()}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

export function groupProfilesByValue(
  values: Array<{value: number}>
): Record<number, Array<{value: number; profile: string}>> {
  return values.reduce((acc, value) => {
    acc[value.value] = acc[value.value] || [];
    acc[value.value].push(value);
    return acc;
  }, {});
}

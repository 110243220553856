// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {MembersRolesMgmt} from '@modules/Core/components/platform/tables/MembersRolesMgmt';
import {useTeamMembersRoleMgmtActions} from '@modules/Core/components/platform/tables/teamMembersRoleMgmtActions';
import {MANAGE_TEAM_OWNERSHIP, MANAGE_TEAM_ROLES_PERMISSION} from '@modules/Core/config/rolesConstants';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {_Profile} from '@modules/Profile/types/profile.model';
import {useTeamMembersProgress} from '@modules/Team/hooks/teamMembersProgress';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamMembersRolesMgmtProps {
  teamMembers: Record<string, _Profile>;
  teamId: string;
}

export const TeamMembersRolesMgmt: React.FC<_TeamMembersRolesMgmtProps> = ({teamId, teamMembers}) => {
  // // Platform

  const {members} = useTeamMembersProgress(teamMembers, teamId);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});
  const {actions, chosenProfiles, onClearSelectedProfiles, onProfileSelected} = useTeamMembersRoleMgmtActions(
    teamId,
    members,
    setDialogConfig,
    setShowDialog
  );

  return (
    <MembersRolesMgmt
      profiles={members}
      teamId={teamId}
      onProfileSelected={onProfileSelected}
      headerProps={{
        selectedRowsIds: chosenProfiles,
        onClearSelectedRows: onClearSelectedProfiles,
        selectedRowsLabel: (count: number) =>
          count === 1
            ? trans('subscription.user_selected')
            : trans('subscription.users_selected', {
                count,
              }),
        actions,
        actionsLabel: trans('base.actions'),
        resetSelectionLabel: trans('subscription.reset_selection'),
      }}
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      dialogConfig={dialogConfig}
      setDialogConfig={setDialogConfig}
      allowSelection={
        can(MANAGE_TEAM_ROLES_PERMISSION, {
          team_id: teamId,
        }) ||
        can(MANAGE_TEAM_OWNERSHIP, {
          team_id: teamId,
        })
      }
    />
  );
};

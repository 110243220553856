// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import EmailForm from './EmailForm';

import './SendReminder.scss';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {IconEmail} from '@modules/Core/components/base/Icons/Icons';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _SendReminderProps {
  teamId: string;
  finishedCount: number;
  totalCount: number;
  notificationType: string;
}

const SendReminder: React.FC<_SendReminderProps> = ({teamId, finishedCount, totalCount, notificationType}) => {
  const emailType = notificationType;

  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);

  const handleOpenEmailForm = (): void => {
    setIsEmailFormOpen(true);
  };

  const handleCloseEmailForm = (): void => {
    setIsEmailFormOpen(false);
  };

  return (
    <Typography>
      <div className="send-reminder p-1 gap-1 flex flex-row">
        {can('team.membership:remind', {team_id: teamId}) && finishedCount < totalCount ? (
          <>
            <div className="flex items-center justify-center">
              <IconEmail />
            </div>
            <TextLink onClick={handleOpenEmailForm} dusk="btn-send_reminder" label={trans('base.send_reminder')} />
            <EmailForm teamId={teamId} isOpen={isEmailFormOpen} onClose={handleCloseEmailForm} emailType={emailType} />
          </>
        ) : null}

        <span
          className={`${finishedCount === totalCount ? 'finished-counter' : 'pending-counter'} flex flex-row gap-1 items-center`}
        >
          <Avatar size="sm" image={getProfilePic(null)} />
          {`${finishedCount}/${totalCount}`}
        </span>
      </div>
    </Typography>
  );
};

export default SendReminder;

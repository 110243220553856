// @ts-nocheck
/* eslint-disable */

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {sessionConfig, setSessionConfig} from '@app/_core/util/util';
import {logger} from '@modules/Core/util/Logger';
import {_AppState} from '@modules/State/types/appState.model';
import {defaultLang, supportedLangs} from '../config/i18nConfig';
import {getTranslation} from '../services/i18nAPI';

export interface _I18nState {
  status: 'idle' | 'loading' | 'failed';
  lang: string | null;
  supportedLangs: string[];
  translations: Record<string, string>;
}

const initialState = {
  status: 'loading',
  lang: null,
  supportedLangs: {...supportedLangs},
  translations: {},
  // translations: translations_example,
};

export const setLangAsync = createAsyncThunk('i18n/setLangAsync', async (lang: string, {getState, dispatch}) => {
  const resolvedLang = lang;

  logger.info(`[i18nSlice] setLangAsync: ${resolvedLang}`);
  // Api call to get translations

  const translations = await getTranslation(resolvedLang);

  // const  translations = translations_example;

  setSessionConfig('language', resolvedLang);

  return translations;
});

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setLangAsync.pending, state => {
      if (Object.keys(state?.translations || {}).length === 0) {
        state.status = 'loading';
      }
    });

    builder.addCase(setLangAsync.fulfilled, (state, action) => {
      logger.debug('Setting translations!');
      state.translations = action.payload;
      state.lang = sessionConfig('language');
      state.status = 'idle';
    });
  },
});

export const selectTranslations = (state: _AppState): Record<string, string> => state.i18n.translations;
export default i18nSlice;

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';
import {EXERCISES_JSON} from '@modules/Profile/services/paths';

let exercisesCache: any = null;
let cachedProfileId: string | null = null;

async function loadExercisesJSON(loadingIndicator = true, profileId: string | null = null): Promise<any> {
  if (!exercisesCache || cachedProfileId !== profileId) {
    exercisesCache = await httpClient.get(EXERCISES_JSON, loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {});
    cachedProfileId = profileId;
  }
  return exercisesCache;
}

export {loadExercisesJSON};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  SHARED_WITH_OTHERS_TYPE,
  SHARED_WITH_YOU,
  SHARED_WITH_YOU_TYPE,
} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileSharedResultsItemProps {
  currentTab: SHARED_WITH_OTHERS_TYPE | SHARED_WITH_YOU_TYPE;
}

export const ProfileSharedResultsMissing: React.FC<_ProfileSharedResultsItemProps> = ({currentTab}) => {
  return (
    <Section image="/assets/img/design_images/soft fact analysis.png">
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: trans(
            currentTab === SHARED_WITH_YOU
              ? 'shared_results.share_results_intro_other'
              : 'shared_results.share_results_intro_you'
          ),
        }}
      />
    </Section>
  );
};

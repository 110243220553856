// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_SectionItemProps, SectionItem} from '@modules/ContentEngine/components/items/sections/SectionItem';

export interface _CustomizableSectionItemProps extends _SectionItemProps {
  enableResize?: boolean;
  enableDrag?: boolean;
}

export const CustomizableSectionItem: React.FC<_CustomizableSectionItemProps> = ({
  enableDrag,
  enableResize,
  view = 'card',
  actionsView = 'context-menu',
  titleVariant = 'h5',
  gap = 2,
  containerGap = 2,
  ...props
}) => {
  // TODO: Hnadle drag and resize
  return (
    <SectionItem
      containerGap={containerGap}
      titleVariant={titleVariant}
      gap={gap}
      view={view}
      actionsView={actionsView}
      fullWidth
      {...props}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {StartTutorialButton} from '@modules/Profile/components/tutorials/StartTutorialButton';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';

interface _Props {
  context?: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
  noBackground?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const TutorialsItem: React.FC<_Props> = ({context, tutorialService, tutorialKey, noBackground = true, size}) => {
  if (!tutorialKey) {
    return null;
  }
  return (
    <div className="flex flex-row w-full justify-end items-center">
      <StartTutorialButton
        noBackground={noBackground}
        tooltipPlc="bottom"
        tutorials={context?.tutorials}
        tutorialKey={tutorialKey}
        id={getTutorialTriggerId(tutorialService)}
        size={size}
      />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {FAB_FEATURE_FLAG, useFeatureFlags} from '@modules/Core/hooks/featureFlags';
import {usePageLayout} from '@modules/Core/hooks/state/pageLayout';
import {logger} from '@modules/Core/util/Logger';
import {isString} from '@modules/Core/util/typesUtil';
import {_FlowTitle} from '@modules/FlowEngine/types/flow.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';
import {
  extractFlowEventsAsUIActions,
  extractFlowLayoutOptions,
  extractFlowTitle,
} from '@modules/FlowEngine/util/helpers/helpers';
import {useLanguage} from '@modules/Translations/hooks/language';

export const useFlowPageLayout = (
  flowName: string,
  currentStep: _FlowStep | null | undefined,
  stepComponent: React.ReactElement | null | undefined,
  instanceKey: string
): void => {
  const {isFeatureEnabled} = useFeatureFlags();

  const {currentLanguage} = useLanguage();
  const {
    setTitle,
    setOptions,
    clearOptions,
    setActions,
    setSubTitle,
    clearTitle,
    clearActions,
    clearSubTitle,
    showSoftwareUpdate,
    hideSoftwareUpdate,
  } = usePageLayout();

  useEffect(() => {
    logger.debug('[useFlowPageLayout] currentStep', {currentStep, instanceKey});

    // if instanceKey is not default, do not update the page layout
    if (!instanceKey.includes('default')) {
      return;
    }

    createTitle();
    createActions();
    createOptions();
  }, [stepComponent, currentLanguage]);

  const createOptions = (): void => {
    if (!stepComponent || !currentStep || !flowName) {
      clearOptions();
      return;
    }
    setOptions(extractFlowLayoutOptions(stepComponent));
  };

  const createTitle = (): void => {
    let title = extractFlowTitle(stepComponent);

    if (!title) {
      clearTitle();
      clearSubTitle();
      hideSoftwareUpdate();
      return;
    }
    if (isString(title)) {
      setTitle(title as string);
      clearSubTitle();
      hideSoftwareUpdate();
      return;
    }

    // FlowTitle
    title = title as _FlowTitle;

    if (title?.text) {
      setTitle(title.text);
    }

    if (title.subtitle) {
      setSubTitle(title.subtitle);
    }

    if (title.type === 'software_update_dialog' && title.metadata?.softwareReleaseKey) {
      showSoftwareUpdate(title.metadata.softwareReleaseKey as string);
    } else {
      hideSoftwareUpdate();
    }
  };

  const createActions = (): void => {
    if (!currentStep || !stepComponent || !flowName) {
      clearActions();
      return;
    }
    const isFabEnabled = isFeatureEnabled(FAB_FEATURE_FLAG);
    const uiActions = extractFlowEventsAsUIActions(flowName, currentStep, stepComponent, isFabEnabled);

    logger.debug('[useFlowPageLayout] uiActions', {uiActions});
    if (!uiActions) {
      clearActions();
      return;
    }

    setActions(uiActions);
  };
};

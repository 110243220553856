// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {addCN} from '@/_core/util/cssHelper';
import {_TypographyVariants} from '@modules/Core/components/base/Typography';

interface _Props {
  label?: string;
  color?: 'brand' | 'default';
  onClick?: () => void;
  href?: string;
  target?: string;
  variant?: _TypographyVariants;
}

export const TextLink: React.FC<_Props> = ({
  label = 'Text link',
  color = 'brand',
  onClick,
  href,
  target = '_blank',
  variant = null,
}) => {
  let textClr = 'text-prussian300 hover:text-brand700';
  if (color === 'brand') textClr = 'text-brand700 hover:text-brand500';
  if (color === 'default') textClr = 'text-prussian300 hover:text-brand700';

  const handleClick = (): void => {
    if (href) window.open(href, target);
    if (onClick) onClick();
  };

  return (
    <button type="button" onClick={handleClick}>
      <span className={addCN(textClr, 'transition cursor-pointer', variant ? `text-${variant}` : '')}>{label}</span>
    </button>
  );
};

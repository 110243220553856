// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {getLockedImageIllustration, getProfilePic} from '@modules/Profile/util/profileUtil';
import {TeamAnswersProfiles} from '@modules/Team/components/team_development/result/TeamAnswersProfiles';
import {TeamAnswersTodoList} from '@modules/Team/components/team_development/result/TeamAnswersTodoList';
import {TeamDevelopmentResultTeamAnswersHeader} from '@modules/Team/components/team_development/result/TeamDevelopmentResultTeamAnswersHeader';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamDevelopmentProfileAnswers} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  answers: _TeamDevelopmentProfileAnswers;
  module: string;
  teamId: string;
  profiles: Record<string, _TeamProfile>;
  tutorialKey: string;
  context: _ContentEngineContext;
}

const SCROLL_THRESHOLD = 15;

export const TeamDevelopmentResultTeamAnswers: React.FC<_Props> = ({
  context,
  tutorialKey,
  profiles,
  teamId,
  module,
  answers,
}) => {
  const [chosenProfile, setChosenProfile] = useState<string | null>(null);
  const [view, setView] = useState<'list' | 'carousel'>('carousel');
  const [showOnlyAnswered, setShowOnlyAnswered] = useState<boolean>(false);
  const [filteredProfiles, setFilteredProfiles] = useState<Record<string, _TeamProfile>>(profiles);
  const loadedInitialProfiles = useRef<boolean>(false);
  const showList = Object.keys(profiles).length > SCROLL_THRESHOLD;

  useEffect(() => {
    if (loadedInitialProfiles.current) {
      return;
    }
    setFilteredProfiles(profiles);
    setChosenProfile(Object.keys(profiles)[0]);
    loadedInitialProfiles.current = true;
  }, [profiles]);

  const onChangeFilteredProfiles = (filteredProfiles: Record<string, _TeamProfile>) => {
    setFilteredProfiles(filteredProfiles);
  };

  if (!answers.showTeamAnswers) {
    return (
      <Section gap={8} image={getLockedImageIllustration()} imageViewDirection="vertical">
        <Typography>{trans('development_result.team_answers.not_finished_yet')}</Typography>
      </Section>
    );
  }

  const profile = chosenProfile?.length ? profiles[chosenProfile] : null;
  logger.debug('TeamDevelopmentResultTeamAnswers', {
    answers,
    module,
    teamId,
    profiles,
    view,
    showOnlyAnswered,
    profile,
  });

  return (
    <Section gap={5}>
      <>
        <TeamDevelopmentResultTeamAnswersHeader
          answers={answers}
          module={module}
          teamId={teamId}
          profiles={profiles}
          view={view}
          setView={setView}
          onFilter={onChangeFilteredProfiles}
          showOnlyAnswered={showOnlyAnswered}
          setShowOnlyAnswered={setShowOnlyAnswered}
          showList={showList}
          context={context}
          tutorialKey={tutorialKey}
        />
        <Section gap={5}>
          <TeamAnswersProfiles
            profiles={filteredProfiles}
            showOnlyAnswered={showOnlyAnswered}
            view={view}
            onSelectProfile={setChosenProfile}
            showList={showList}
          />
          {profile && (
            <Section>
              <AvatarUser size="md" label={profile?.fullName} image={getProfilePic(profile)} />
              {profile?.teamDevelopment?.answers ? (
                profile?.teamDevelopment.answers
                  .sort((a, b) => {
                    // check a.question_key and b.question_key, the one ending with _a comes first, then _b, then the rest
                    const aKey = a.question_key;
                    const bKey = b.question_key;
                    if (aKey.endsWith('_a') && bKey.endsWith('_b')) {
                      return 1;
                    }
                    if (aKey.endsWith('_b') && bKey.endsWith('_a')) {
                      return -1;
                    }
                    return aKey.localeCompare(bKey);
                  })
                  .map((answer, index) => (
                    <>
                      <Typography bold>{answer.question}</Typography>
                      <Typography breakLines>{answer.answers}</Typography>
                    </>
                  ))
              ) : (
                <Typography>
                  {trans('development_result.team_answers.no_answers_available', {
                    name: profile?.fullName,
                  })}
                </Typography>
              )}
            </Section>
          )}
        </Section>
        <Section
          title={trans('development_result.team_answers.whats_next')}
          description={trans('development_result.team_answers.whats_next_desc')}
        />

        <div className="flex flex-row gap-3 justify-between">
          <div className="relative-col-width-5">
            <TeamAnswersTodoList module={module} variant="needs" teamId={teamId} answers={answers?.todos?.need} />
          </div>
          <div className="relative-col-width-5">
            <TeamAnswersTodoList module={module} variant="actions" teamId={teamId} answers={answers?.todos?.action} />
          </div>
        </div>
      </>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {
  IconArrowBack,
  IconArrowDoubleLeft,
  IconArrowDoubleRight,
  IconArrowForward,
} from '@modules/Core/components/base/Icons/Icons';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {ProfileDevModeSection} from '@modules/Dev/components/ProfileDevModeSection';
import {_ServiceResultChanges, _ServiceResultData} from '@modules/Profile/types/service.model';

const classifications = ['low', 'medium', 'high'];
const changeTypes = {
  cbc: 'Change across categories (Move to different category, change value determines +ve or -ve)',
  cwc: 'Change within category (Remain in same category)',
};
const scoreChanges = ['negative', 'positive', 'neutral'];

interface _ProfileServiceResultDevModeProps {
  isDevMode: boolean;
  setIsDevMode: (isDevMode: boolean) => void;
  profileData: _ServiceResultData | null;
  setProfileData: (profileData: any) => void;
  changes: _ServiceResultChanges | null;
  setChanges: (changes: any) => void;
}

export const ProfileServiceResultDevMode: React.FC<_ProfileServiceResultDevModeProps> = ({
  isDevMode,
  setIsDevMode,
  profileData,
  setProfileData,
  changes,
  setChanges,
}) => {
  const [classification, setClassification] = useState<'low' | 'medium' | 'high' | null>(null);
  const [changeType, setChangeType] = useState<'cbc' | 'cwc' | null>(null);
  const [scoreChange, setScoreChange] = useState<'negative' | 'positive' | 'neutral' | null>(null);

  useEffect(() => {
    setChangeType(changes?.category_change ?? null);
    setScoreChange(changes?.score_change ?? null);
  }, [changes]);

  useEffect(() => {
    setClassification(profileData?.classification ?? null);
  }, [profileData]);

  const hasNext = () => {
    if (!classification || !changeType || !scoreChange) return true;
    const isLastClassification = classification === classifications[classifications.length - 1];
    const isLastChangeType = changeType === Object.keys(changeTypes)[Object.keys(changeTypes).length - 1];
    const isLastScoreChange = scoreChange === scoreChanges[scoreChanges.length - 1];
    return !(isLastClassification && isLastChangeType && isLastScoreChange);
  };

  const hasPrevious = () => {
    if (!classification || !changeType || !scoreChange) return false;
    const isFirstClassification = classification === classifications[0];
    const isFirstChangeType = changeType === Object.keys(changeTypes)[0];
    const isFirstScoreChange = scoreChange === scoreChanges[0];
    return !(isFirstClassification && isFirstChangeType && isFirstScoreChange);
  };

  const cycleNext = () => {
    if (!changeType || !scoreChange) {
      setChangeType('cbc');
      setScoreChange('negative');
      return;
    }

    const currentScoreChangeIndex = scoreChanges.indexOf(scoreChange);
    const currentChangeTypeIndex = Object.keys(changeTypes).indexOf(changeType);
    const currentClassificationIndex = classifications.indexOf(classification as string);

    if (currentScoreChangeIndex < scoreChanges.length - 1) {
      setScoreChange(scoreChanges[currentScoreChangeIndex + 1] as 'negative' | 'positive' | 'neutral');
    } else {
      setScoreChange(scoreChanges[0] as 'negative' | 'positive' | 'neutral');
      if (currentChangeTypeIndex < Object.keys(changeTypes).length - 1) {
        setChangeType(Object.keys(changeTypes)[currentChangeTypeIndex + 1] as 'cbc' | 'cwc');
      } else {
        setChangeType('cbc');
        if (currentClassificationIndex < classifications.length - 1) {
          setClassification(classifications[currentClassificationIndex + 1] as 'low' | 'medium' | 'high');
        } else {
          setClassification(classifications[0] as 'low' | 'medium' | 'high');
        }
      }
    }
  };

  const cyclePrevious = () => {
    if (!changeType || !scoreChange) {
      setChangeType('cwc');
      setScoreChange('neutral');
      return;
    }

    const currentScoreChangeIndex = scoreChanges.indexOf(scoreChange);
    const currentChangeTypeIndex = Object.keys(changeTypes).indexOf(changeType);
    const currentClassificationIndex = classifications.indexOf(classification as string);

    if (currentScoreChangeIndex > 0) {
      setScoreChange(scoreChanges[currentScoreChangeIndex - 1] as 'negative' | 'positive' | 'neutral');
    } else {
      setScoreChange(scoreChanges[scoreChanges.length - 1] as 'negative' | 'positive' | 'neutral');
      if (currentChangeTypeIndex > 0) {
        setChangeType(Object.keys(changeTypes)[currentChangeTypeIndex - 1] as 'cbc' | 'cwc');
      } else {
        setChangeType('cwc');
        if (currentClassificationIndex > 0) {
          setClassification(classifications[currentClassificationIndex - 1] as 'low' | 'medium' | 'high');
        } else {
          setClassification(classifications[classifications.length - 1] as 'low' | 'medium' | 'high');
        }
      }
    }
  };

  const goToFirst = () => {
    setClassification(classifications[0] as 'low' | 'medium' | 'high');
    setChangeType('cbc');
    setScoreChange('negative');
  };

  const goToLast = () => {
    setClassification(classifications[classifications.length - 1] as 'low' | 'medium' | 'high');
    setChangeType(Object.keys(changeTypes)[Object.keys(changeTypes).length - 1] as 'cbc' | 'cwc');
    setScoreChange(scoreChanges[scoreChanges.length - 1] as 'negative' | 'positive' | 'neutral');
  };

  useEffect(() => {
    if (classification && changeType && scoreChange) {
      setProfileData({...profileData, classification});
      setChanges({...changes, category_change: changeType, score_change: scoreChange});
    }
  }, [classification, changeType, scoreChange]);

  logger.debug('ProfileServiceResultDevMode', {isDevMode, profileData, changes});

  if (!classification) return null;

  return (
    <ProfileDevModeSection isDevMode={isDevMode} setIsDevMode={setIsDevMode}>
      <div className="flex flex-col gap-2">
        <Typography>
          Note: The initial results shown are the actual results. The changes you make here will be reflected in the
          profile results.
        </Typography>
        <Typography>
          Note2: The changes appear ONLY starting from round 2 onwards, as a result of comparing new results with the
          previous round.
        </Typography>

        <Select
          options={classifications.map(classification => ({
            value: classification,
            label: classification,
          }))}
          label="Select latest result (Shown under Explanation/Erklaerung)"
          onChange={selectedOption => setProfileData({...profileData, classification: selectedOption?.value})}
          value={{
            value: classification,
            label: classification,
          }}
        />
        {changeType && (
          <Select
            label="Select change type"
            options={Object.entries(changeTypes).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
            onChange={selectedOption => setChanges({...changes, category_change: selectedOption?.value})}
            value={{
              value: changeType,
              label: changeTypes[changeType],
            }}
          />
        )}
        {changeType && scoreChange && (
          <Select
            label="Select score change"
            options={scoreChanges.map(scoreChange => ({
              value: scoreChange,
              label: scoreChange,
            }))}
            onChange={selectedOption => setChanges({...changes, score_change: selectedOption?.value})}
            value={{
              value: scoreChange,
              label: scoreChange,
            }}
          />
        )}

        <div className="flex flex-row w-full">
          Cycle through all different combinations of classification, change type and score change It is recommended to
          go to first combination, then cycle through all combinations
        </div>
        <div className="flex items-center justify-center gap-2 w-full">
          <ToolTip txt="Go to first combination">
            <IconButton onClick={goToFirst} icon={IconArrowDoubleLeft} />
          </ToolTip>
          <ToolTip txt="Go to previous combination">
            <IconButton onClick={cyclePrevious} icon={IconArrowBack} disabled={!hasPrevious()} />
          </ToolTip>
          <ToolTip txt="Go to next combination">
            <IconButton onClick={cycleNext} icon={IconArrowForward} disabled={!hasNext()} />
          </ToolTip>
          <ToolTip txt="Go to last combination">
            <IconButton onClick={goToLast} icon={IconArrowDoubleRight} />
          </ToolTip>
        </div>
      </div>
    </ProfileDevModeSection>
  );
};

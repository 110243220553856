// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {LoginEmailInput} from '@modules/Core/components/platform/login/LoginEmailInput';
import {LoginPasswordInput} from '@modules/Core/components/platform/login/LoginPasswordInput';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {resetPassword} from '@modules/Core/services/api/register';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export const ResetPasswordStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const snackbar = useSnackbar();

  // Login & Register shared state
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  async function forgetPassword(): Promise<void> {
    try {
      const response = await resetPassword(email, password, options.token as string);
      if (response.data === 'passwords.reset') {
        snackbar.success(trans('passwords.reset'));
        options.onComplete();
      } else {
        snackbar.danger(trans(response.data as string));
      }
    } catch (e) {
      snackbar.danger(trans('base.unexpected_error'));
    }
  }

  return (
    <BaseStep options={options}>
      <Section image="/assets/img/design_images/request password.png" imageHeight="h-[348px]" fullWidth gap={8}>
        <Section
          title={trans('passwords.reset-headline')}
          description={trans('passwords.reset-explanation')}
          descriptionVariant="body1"
          gap={5}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              void forgetPassword();
            }}
            className="hide-required-stars"
          >
            <Section gap={5}>
              <div className="flex flex-row gap-3 w-full">
                <LoginEmailInput email={email} setEmail={setEmail} />
                <LoginPasswordInput password={password} setPassword={setPassword} />
              </div>

              <div className="flex justify-end">
                <div className="relative-col-width-2">
                  <Button type="submit">{trans('passwords.reset-btn')}</Button>
                </div>
              </div>
            </Section>
          </form>
        </Section>
      </Section>
    </BaseStep>
  );
};

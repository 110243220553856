// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {ProfileRound} from '@modules/Profile/components/exercises/round/ProfileRound';
import {BaseStep} from '../Base/BaseStep';

export const ProfileRoundStep: React.FC<_BaseStepComponentOptions> = ({options}) => (
  <BaseStep options={options}>
    <ProfileRound
      round={parseInt(options.round as string, 10)}
      service={options.service}
      exerciseIndex={
        options.exerciseIndex !== null && options.exerciseIndex !== undefined
          ? parseInt(options.exerciseIndex as string, 10)
          : null
      }
      profileExercises={options.profileExercises}
    />
  </BaseStep>
);

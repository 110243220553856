// @ts-nocheck
/* eslint-disable */

import {onLogout} from '@modules/Profile/util/authUtil';
import {generate} from '../components/base/helper';
import {logger} from './Logger';
import {zustandApi} from './logic/zustand';
import {danger} from './snackbar';

export function errorMessage(error: any): string {
  // Show just first error if there are multiple
  if (error.response.data.errors) {
    return Object.keys(error.response.data.errors).map(key => error.response.data.errors[key][0])[0];
  }
  if (error.response.data.message) {
    return error.response.data.message;
  }
  switch (error.response.status) {
    case 401:
      return 'You\'ve landed on a page you don\'t have access to. You should have one though? Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
    case 403:
      return 'You\'ve landed on a page you don\'t have access to. You should have one though? Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
    case 404:
      return 'The requested resource could not be found. Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
    case 500:
      return 'An unexpected error occurred. Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
    case 422:
      return 'An unexpected error occurred. Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
    default:
      return 'An unexpected error occurred. Please contact us at <a href="mailto:support@softfact.works" style="color:#000000; text-decoration:underline">support@softfact.works</a> and explain briefly what you wanted to do. Then we will take care of it.';
  }
}

export function handleHttpError(error: any): void {
  if (!error) {
    return;
  }
  const isSessionExpired = error.response.status === 401 && !error.request.responseURL.endsWith('/api/login');
  if (isSessionExpired) {
    // logout only if not already on /logout
    if (!window.location.pathname.startsWith('/logout')) {
      onLogout().catch(logger.debug);
    }
  } else {
    danger(errorMessage(error));
  }
}

// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {useSelectState} from '@modules/State/hooks/selectState';

export const FAB_FEATURE_FLAG = 'fab';

export function useFeatureFlags(): {isFeatureEnabled: (feature: string) => boolean; flags: string[] | undefined} {
  const flags = useSelectState(state => state.featureFlags.flags);

  const isFeatureEnabled = (feature: string): boolean => {
    const result = flags?.includes(feature);
    logger.info(`[FeatureFlags] Checking feature flag: ${feature}, flags: ${JSON.stringify(flags)},result: ${result}`);
    return result;
  };

  return {
    isFeatureEnabled,
    flags,
  };
}

// @ts-nocheck
/* eslint-disable */

import {Axios} from 'axios';
import {sessionConfig} from '@app/_core/util/util';
import {handleHttpError} from '../../util/errors';

/**
 * Adds auth token for each request
 * @param axiosInstance
 */
const authTokenInterceptor = (axiosInstance: Axios): void => {
  axiosInstance.interceptors.request.use(
    config => {
      const authToken = sessionConfig('authToken');
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    error => {
      handleHttpError(error);
    }
  );

  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      handleHttpError(error);
      return await Promise.reject(error);
    }
  );
};
export default authTokenInterceptor;

// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect} from 'react';
// import {addReactEventListener, removeReactEventListener} from '@modules/Core';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {useLocation} from '@modules/Navigation/hooks/location';
import {_Location} from '../types/location.model';

/**
 * @description This hook is used to listen to path changes and calls the callback function on location change
 * @param callback
 */
export function useLocationEffect(callback: (location: _Location) => void): any {
  const location = useLocation();
  useEffect(() => {
    if (callback) {
      callback(location);
    }
  }, [location]);

  const reloadLocation = useCallback(() => {
    if (callback) {
      callback(location);
    }
  }, [callback]);

  useEffect(() => {
    addReactEventListener('reloadLocation', reloadLocation);
    return () => {
      removeReactEventListener('reloadLocation', reloadLocation);
    };
  }, [reloadLocation]);

  return location;
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {addCN} from '@app/_core/util/cssHelper';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';
import {Typography} from '../base/Typography';

interface _LoaderProps {
  children?: React.ReactNode;
  mainLoader?: boolean;
}

const Loader: React.FC<_LoaderProps> = ({children, mainLoader}) => {
  const {showLoadingIndicator, setChildLoaderCount, hasChildLoader} = useLoader();
  const {currentLanguage} = useLanguage();
  const ready = currentLanguage !== null;
  const showLoader = !ready || (showLoadingIndicator && (!mainLoader || !hasChildLoader));
  const [showRefreshNote, setShowRefreshNote] = useState(false);
  const [showWaitNote, setShowWaitNote] = useState(false);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    if (!mainLoader) {
      setChildLoaderCount(count => count + 1);
      return () => {
        setChildLoaderCount(count => count - 1);
      };
    }
  }, []);

  useEffect(() => {
    if (showLoader) {
      setTimeoutIds([
        setTimeout(() => setShowWaitNote(true), 10000),
        setTimeout(() => setShowRefreshNote(true), 60000),
      ]);
    } else {
      setShowWaitNote(false);
      setShowRefreshNote(false);
      timeoutIds.forEach(id => clearTimeout(id));
    }
  }, [showLoader]);

  return (
    <>
      <div style={{display: !showLoader ? 'none' : 'unset'}}>
        <div
          className={addCN('flex flex-col justify-center items-center w-full', mainLoader ? 'h-[100vh]' : 'h-[200px]')}
        >
          <div className="w-[100px] h-[100px]">
            <img width="100" height="100" src="/assets/img/loader.webp" alt="loader" />
          </div>
        </div>
        {(showWaitNote || showRefreshNote) && mainLoader && (
          <Typography
            className="absolute bottom-10 w-full text-center"
            dangerouslySetInnerHTML={{__html: showRefreshNote ? trans('base.loader_note', {}, false) : trans('base.loader_note_wait', {}, false) ?? ''}}
          />
        )}
      </div>

      {children && ready && (
        <div
          style={{display: showLoader ? 'none' : 'unset'}}
          className="w-full"
          dusk="child-container"
          key={currentLanguage}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Loader;

// @ts-nocheck
/* eslint-disable */

import React, {ElementType, useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {getIconByName, IconTip} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {logger} from '@modules/Core/util/Logger';
import {useProfileGlobalDialog} from '@modules/Profile/providers/ProfileGlobalDialogProvider';
import {trans} from '@modules/Translations/util/i18n';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

export interface _TutorialProps {
  tutorials?: Record<string, _Tutorial>;
  tutorialKey?: string;
  icon?: boolean;
  size?: 'sm' | 'md' | 'lg';
  onClose?: () => void;
  id?: string;
  withTooltip?: boolean;
  noBackground?: boolean;
  tooltipPlc?: 'top' | 'bottom' | 'left' | 'right';
  iconOverride?: ElementType;
}

export const StartTutorialButton: React.FC<_TutorialProps> = ({
  tooltipPlc,
  noBackground = true,
  id,
  onClose,
  size = 'lg',
  icon = true,
  tutorialKey,
  tutorials,
  iconOverride = null,
  withTooltip = true,
}) => {
  const profileGlobalDialog = useProfileGlobalDialog();

  const [open, setOpen] = useState<boolean>(false);
  const [tutorial, setTutorial] = useState<_Tutorial | undefined>(undefined);
  // True only before user interacts with the tutorial button
  const [withScroll, setWithScroll] = useState<boolean>(true);
  const [forceRender, setForceRender] = useState<number>(0);

  useEffect(() => {
    const isOpen = Boolean(tutorial && open);

    if (isOpen) {
      profileGlobalDialog.addTutorial(
        {
          ...tutorial,
          id: tutorial?.name,
        },
        true,
        () => {
          setOpen(false);
        }
      );
    }
  }, [tutorial, open, forceRender]);

  useEffect(() => {
    setTutorial(tutorials?.[tutorialKey]);
  }, [tutorials, tutorialKey]);

  const handleOpenForm = (): void => {
    setOpen(true);
    // True only before user interacts with the tutorial button
    setWithScroll(true);
    // If already open
    if (open) {
      setForceRender(prev => prev + 1);
    }
  };

  if (!tutorial) {
    return null;
  }

  return (
    <ButtonComponent
      tooltipPlc={tooltipPlc}
      noBackground={noBackground}
      id={id}
      icon={icon ?? false}
      size={size}
      handleOpenForm={handleOpenForm}
      withTooltip={withTooltip}
      iconOverride={iconOverride}
    />
  );
};

interface _ButtonComponentProps {
  id?: string;
  icon: boolean;
  size: 'sm' | 'md' | 'lg';
  handleOpenForm: () => void;
  withTooltip?: boolean;
  noBackground?: boolean;
  tooltipPlc?: 'top' | 'bottom' | 'left' | 'right';
  iconOverride?: ElementType;
}

const ButtonComponent: React.FC<_ButtonComponentProps> = ({
  tooltipPlc,
  noBackground = true,
  withTooltip,
  id,
  icon,
  size,
  handleOpenForm,
  iconOverride,
}) => {
  let content;
  if (icon) {
    logger.debug('ButtonComponent iconOverride', {iconOverride});
    const IconType = getIconByName(iconOverride) ?? IconTip;
    content = (
      <IconButton
        color="no-background"
        id={id}
        dusk="tutorial_btn"
        size={size}
        onClick={handleOpenForm}
        icon={IconType}
        radius={size === 'md' ? 'lg' : null}
      />
    );
  } else {
    content = (
      <Button id={id} icon={IconTip} dusk="tutorial_btn" onClick={handleOpenForm} variant="primary">
        {trans('profile.tutorial_tooltip')}
      </Button>
    );
  }
  if (withTooltip) {
    return (
      <ToolTip txt={trans('profile.tutorial_tooltip')} plc={tooltipPlc}>
        {content}
      </ToolTip>
    );
  }

  return content;
};

// @ts-nocheck
/* eslint-disable */

import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {invokeAction} from '@modules/Core/util/actionsUtil';
import {logger} from '@modules/Core/util/Logger';

export async function setOneTimeNotificationDone(key: string, value: boolean): Promise<boolean> {
  try {
    const response = await invokeAction('profile.value-change', {
      key,
      value,
    });

    if (response?.status === ACTION_STATUS_SUCCESS) {
      return true;
    }

    return false;
  } catch (e) {
    logger.error('Error setting one time notification done', e);
    return false;
  }
}

export async function closePromptAction(id: string): Promise<boolean> {
  try {
    const response = await invokeAction('profile.prompt.close', {
      promptId: id,
    });

    if (response?.status === ACTION_STATUS_SUCCESS) {
      return true;
    }

    return false;
  } catch (e) {
    logger.error('Error setting one time notification done', e);
    return false;
  }
}

// @ts-nocheck
/* eslint-disable */

import {SERVICE_TEAM_DEVELOPMENT} from '@modules/Core/config/services';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {appState} from '@modules/State/util/util';
import {team} from '@modules/Team/util/teamUtil';

export function isTransparencyAccepted(team) {
  return team?.transparencyAccepted || false;
}

export async function removeMemberFromTeam(selectedProfileId, teamId) {
  if (!selectedProfileId) {
    return;
  }

  const method = 'team.remove';
  const params = {
    profileIds: [selectedProfileId],
    teamId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
        loadingIndicator: true,
      }
    );

    if (response?.data?.status === 'success') {
      reloadContentEngine();
      return true;
    }

    return false;
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function archiveTeam(teamId) {
  const method = 'team.archive';
  const params = {
    teamId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
        loadingIndicator: true,
      }
    );

    if (response?.data?.status === 'success') {
      return true;
    }

    return false;
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function sendEmailToAllTeamMembers(teamId, message, emailType) {
  if (!teamId) {
    return;
  }

  const method = 'team.email-to-all-members';
  const params = {
    teamId,
    message,
    emailType,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
        loadingIndicator: true,
      }
    );

    if (response?.data?.status === 'success') {
      return true;
    }

    return false;
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function leaveMemberFromTeam(profileId, teamId) {
  if (!profileId || !teamId) {
    return;
  }

  const method = 'leave.team';
  const params = {
    profileId,
    teamId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
        loadingIndicator: true,
      }
    );

    if (response?.data?.status === 'success') {
      return true;
    }

    return false;
  } catch (e) {
    logger.error(e);
    return false;
  }
}

/**
 * Team Project Functionalities
 */

export function getPotentialSurveyDoneCount(teamId: string) {
  const currentTeam = team(teamId);

  if (!currentTeam) {
    return 0;
  }

  return currentTeam?.potentialAnalysisDoneCount?.done;
}

export function getTeamDevNextSubModule(teamId, moduleName, subModuleName = null, subSubModuleName = null) {
  const result = {};

  const currentTeam = team(teamId);

  const project = currentTeam?.runningProjects?.[SERVICE_TEAM_DEVELOPMENT];

  if (!project) {
    logger.error('[FlowEvaluator] Project not found');
    return null;
  }

  const subModulesOrder = project?.subModules_order;

  if (!subModulesOrder) {
    logger.error('[FlowEvaluator] Module order not found');
    return null;
  }

  const module = subModulesOrder[moduleName];

  if (!module) {
    logger.error(`[FlowEvaluator] Module ${moduleName} not found`);
    return null;
  }

  result.module = moduleName;

  if (subModuleName) {
    result.subModule = subModuleName;
    const subModuleIndex = Object.keys(module.subModules).findIndex(key => key === subModuleName);
    if (subModuleIndex === -1) {
      logger.error(`[FlowEvaluator] SubModule ${subModuleName} not found`);
      return null;
    }

    const subSubModuleIndex = subSubModuleName
      ? module.subModules[subModuleName].subSubModules.findIndex(key => key === subSubModuleName)
      : -1;

    const isLastSubSubModule =
      subSubModuleName && subSubModuleIndex === module.subModules[subModuleName].subSubModules?.length - 1;

    if (!subSubModuleName || isLastSubSubModule) {
      if (subModuleIndex === Object.keys(module.subModules[subModuleName]).length - 1) {
        logger.error(`[FlowEvaluator] SubSubModule ${subSubModuleName} is last module`);
        return null;
      }

      // If last subModule in collective tools we want to go to team result
      if (subModuleName === 'collectivetools') {
        return {
          module: moduleName,
          seeResult: true,
          initialTab: 'teamAnswers',
        };
      }

      result.subModule = Object.keys(module.subModules)[subModuleIndex + 1];

      // If there is no subSubModule for the new subModule, return result, otherwise get the first subSubModule
      if (module.subModules[result.subModule].subSubModules?.length > 0) {
        result.subSubModule = module.subModules[result.subModule].subSubModules[0];
      }

      return result;
    }
    // SubSubModule

    if (subSubModuleIndex === -1) {
      logger.error(`[FlowEvaluator] SubSubModule ${subSubModuleName} not found`);
      return null;
    }

    result.subSubModule = module.subModules[subModuleName].subSubModules[subSubModuleIndex + 1];
  }

  return result;
}

export function getTeamDevNextSubModuleName(teamId, moduleName, subModuleName = null, subSubModuleName = null) {
  const currentTeam = team(teamId);
  const project = currentTeam?.runningProjects?.[SERVICE_TEAM_DEVELOPMENT];

  if (!project) {
    logger.error('[FlowEvaluator] Project not found');
    return null;
  }

  const subModulesOrder = project?.subModules_order;

  if (!subModulesOrder) {
    logger.error('[FlowEvaluator] Module order not found');
    return null;
  }

  const module = subModulesOrder[moduleName];

  if (!module) {
    logger.error(`[FlowEvaluator] Module ${moduleName} not found`);
    return null;
  }

  if (subModuleName) {
    const subModuleIndex = Object.keys(module.subModules).findIndex(key => key === subModuleName);

    if (subModuleIndex === -1) {
      logger.error(`[FlowEvaluator] SubModule ${subModuleName} not found`);
      return null;
    }

    const subSubModuleIndex = subSubModuleName
      ? module.subModules[subModuleName].subSubModules.findIndex(key => key === subSubModuleName)
      : -1;

    const isLastSubSubModule =
      subSubModuleName && subSubModuleIndex === module.subModules[subModuleName].subSubModules?.length - 1;

    if (!subSubModuleName || isLastSubSubModule) {
      const nextSubModuleName = Object.keys(module.subModules)[subModuleIndex + 1];

      // if it has subsubmodules, return name of first

      if (module.subModules[nextSubModuleName].subSubModules?.length > 0) {
        return module.subModules[nextSubModuleName].subSubModules[0];
      }

      return nextSubModuleName;
    }

    if (subSubModuleIndex === -1) {
      logger.error(`[FlowEvaluator] SubSubModule ${subSubModuleName} not found`);
      return null;
    }

    return module.subModules[subModuleName].subSubModules[subSubModuleIndex + 1];
  }

  return null;
}

/**
 * Surveys Functionalities
 */

export function isTransparencyAcceptedProfile() {
  // Note: This is different to the function in TeamsUtil
  // This function only checks the profile state
  // The function in TeamsUtil checks the the team state
  const {
    profile: {currentProfile},
    team,
  } = appState();
  const currentTeamId = team?.currentTeam?.id;
  return currentProfile[`team_transparency_accepted_${currentTeamId}`];
}

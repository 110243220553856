// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {generate} from '@modules/Core/components/base/helper';
import {IconSmile} from '@modules/Core/components/base/Icons/Icons';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';

const paddingToButton = 4;

interface _ContextMenu {
  triggerIcon?: React.ElementType;
  menuItems: Array<{label: string; onChange: () => void; icon?: React.ElementType}>;
  onClose?: () => void;
}

export const ContextMenu: React.FC<_ContextMenu> = ({triggerIcon = IconSmile, menuItems, onClose}) => {
  const [id] = useState(generate());
  const [contextMenuActive, setContextMenuActive] = useState(false);

  const handleClose = (): void => {
    setContextMenuActive(false);
    if (onClose) onClose();
  };

  return (
    <div className="relative w-fit" id={id}>
      <IconButton onClick={() => setContextMenuActive(!contextMenuActive)} icon={triggerIcon} />
      {contextMenuActive ? <ContextMenuComponent onClose={handleClose} buttonId={id} items={menuItems} /> : null}
    </div>
  );
};

interface _ContextMenuComponent {
  buttonId: string;
  items: Array<{label: string; onChange: () => void}>;
  onClose: () => void;
}

export const ContextMenuComponent: React.FC<_ContextMenuComponent> = ({buttonId, items, onClose}) => {
  const [top, setTop] = useState<undefined | number>();

  const handleClickOutside = (): void => {
    if (onClose) onClose();
  };

  const ref = useClickOutOfBoundsHelper(handleClickOutside, buttonId);

  useEffect(() => {
    const recursiveFind = (): void => {
      // finds the corresponding button element by id and places the context menu 4px below it
      const el = document.getElementById(buttonId);

      if (el) {
        const rect = el.getBoundingClientRect();
        const newTop = rect.height + paddingToButton;
        if (newTop !== top) setTop(newTop);
      } else setTimeout(recursiveFind, 100);
    };
    recursiveFind();
  }, [buttonId, top]);

  return top ? (
    <div
      className="absolute z-[10000] flex flex-col items-start rounded-b-sm border-1 border-grey300 overflow-hidden shadow-2 bg-white500 mt-1 min-w-[232px]"
      style={{top, right: 0}}
      ref={ref}
    >
      {/* Menu */}
      {items.map(item => (
        <ListItem
          key={item.label}
          {...item}
          onChange={() => {
            item?.onChange?.();
            if (onClose) onClose();
          }}
        />
      ))}
    </div>
  ) : null;
};

// @ts-nocheck
/* eslint-disable */

import axios from 'axios';
import {logger} from '../util/Logger';
import {getCurrentAPIBasePath} from '../util/util';
import interceptors from './interceptors/interceptors';

export const httpClient = axios.create({
  baseURL: `${getCurrentAPIBasePath()}/api/`,
});

export const httpClientUnauthenticated = axios.create({
  baseURL: `${getCurrentAPIBasePath()}/api/`,
});

logger.info(`[HttpClient] Current API Base Path: ${getCurrentAPIBasePath()}`);

// Inits interceptors
interceptors.forEach(interceptor => {
  interceptor(httpClient);
});

// Inits interceptors
interceptors.forEach(interceptor => {
  interceptor(httpClientUnauthenticated);
});

// export default {httpClient, httpClientUnauthenticated};

// export httpClient to window
// TODO @Stefan: Do we need this?
// @ts-expect-error
// window.httpClient = httpClient;

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {_SettingsEntry} from '@modules/FlowEngine/components/Steps/ProfileAccountDetailsStep';
import ProfileAccountSettings from '@modules/Profile/components/settings/ProfileAccountSettings';
import ProfilePrivacySettings from '@modules/Profile/components/settings/ProfilePrivacySettings';
import {_ProfileAccountTab, SettingTabs} from '@modules/Profile/components/settings/SettingsTabs';
import {_Profile} from '@modules/Profile/types/profile.model';

interface _Props {
  currentProfile: _Profile | null;
  active: _ProfileAccountTab;

  newEmail: string | null;
  setNewEmail: (newEmail: string) => void;
  oldEmail: string | null;
  setOldEmail: (oldEmail: string) => void;
  changeEmailPassword: string | null;
  setChangeEmailPassword: (changeEmailPassword: string) => void;

  oldPassword: string | null;
  setOldPassword: (oldPassword: string) => void;
  newPassword: string | null;
  setNewPassword: (newPassword: string) => void;

  deleteAccountPassword: string | null;
  setDeleteAccountPassword: (deleteAccountPassword: string) => void;

  onChangeEmail: () => void;
  onChangePassword: () => void;
  onDeleteAccount: () => void;
  onChangePrivacy: () => void;

  settings: _SettingsEntry[];
  setSettings: (settings: _SettingsEntry[]) => void;

  responsibleForOrgs: any[];
  responsibleForTeams: any[];
}

const ProfileSettings: React.FC<_Props> = ({
  currentProfile,
  active,
  newEmail,
  newPassword,
  oldEmail,
  setNewEmail,
  setNewPassword,
  oldPassword,
  setOldPassword,
  deleteAccountPassword,
  setDeleteAccountPassword,
  changeEmailPassword,
  setChangeEmailPassword,
  onDeleteAccount,
  onChangeEmail,
  onChangePassword,
  settings,
  setSettings,
  onChangePrivacy,
  responsibleForTeams,
  responsibleForOrgs,
}) => {
  const [activeTab, setActiveTab] = useState<_ProfileAccountTab>(active);
  const activeTabLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (!activeTabLoaded.current) {
      setActiveTab(active ?? 'account');
      activeTabLoaded.current = true;
    }
  }, [active]);

  const showPrivacy = activeTab === 'privacy';
  const showAccount = activeTab === 'account';
  return (
    <Section>
      <SettingTabs active={activeTab} setActive={setActiveTab} />
      {showAccount && (
        <ProfileAccountSettings
          onChangeEmail={onChangeEmail}
          onChangePassword={onChangePassword}
          onDeleteAccount={onDeleteAccount}
          oldEmail={oldEmail}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          changeEmailPassword={changeEmailPassword}
          setChangeEmailPassword={setChangeEmailPassword}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          deleteAccountPassword={deleteAccountPassword}
          setDeleteAccountPassword={setDeleteAccountPassword}
          responsibleForOrgs={responsibleForOrgs}
          responsibleForTeams={responsibleForTeams}
        />
      )}
      {showPrivacy && (
        <ProfilePrivacySettings
          onChangePrivacy={onChangePrivacy}
          settings={settings}
          setSettings={setSettings}
          currentProfile={currentProfile}
        />
      )}
    </Section>
  );
};

export default ProfileSettings;

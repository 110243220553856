// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Avatar} from './Avatar';

interface _AvatarUser {
  label?: string;
  email?: string;
  alignLabel?: 'left' | 'right';
  image?: string;
  icon?: React.ElementType;
  lineClamp?: boolean;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  bold?: boolean;
}

export const AvatarUser: React.FC<_AvatarUser> = ({
  label,
  email,
  alignLabel = 'right',
  image,
  icon,
  lineClamp = false,
  onClick,
  size = 'sm',
  className,
  bold,
}) => {
  const labelRender = (
    <div className={addCN('flex flex-col items-start justify-center', alignLabel === 'left' ? 'rtl' : 'ltr')}>
      <span className={addCN(lineClamp ? 'line-clamp-1' : '', bold ? 'font-bold' : '')}>{label}</span>
      <span className={addCN(lineClamp ? 'line-clamp-1' : '', bold ? 'font-bold' : '')}>{email}</span>
    </div>
  );

  // TODO: onClick should usually only be used in the context of a clickable element (a, button, or atleast cursor pointer)
  return (
    <div
      onClick={onClick}
      className={addCN(
        'flex flex-row gap-1',
        email ? ' items-start' : 'items-center',
        onClick ? 'cursor-pointer' : '',
        className
      )}
    >
      {(label ?? email) && alignLabel === 'left' ? labelRender : null}

      {image ? <Avatar size={size} image={image} /> : null}
      {icon ? <Avatar size={size} icon={icon} /> : icon}

      {(label ?? email) && alignLabel === 'right' ? labelRender : null}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

export const statisticsColors = ['#00CC7E', '#132E3E', '#457DA0'];

export const colorsClasses = ['bg-[#00CC7E]', 'bg-[#132E3E]', 'bg-[#457DA0]'];

export const statisticTitleLangProps: Record<string, string> = {
  experienceGain: 'gamification.experience_points_gained',
  softfactsCompleted: 'gamification.softfacts_completed',
  exercisesCompleted: 'gamification.exercise_completed',
  userActivity: 'gamification.time_spent_on_development',
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {SingleValue} from 'react-select';
import {_SelectValue} from '@modules/Core/components/base/inputs/Select';
import {logger} from '@modules/Core/util/Logger';
import {_JournalSelectorData, JournalSelector} from '@modules/Profile/components/journals/JournalSelector';
import {getAllCategories} from '@modules/Profile/services/journalsApi';
import {_JournalCategory, _JournalFilterParams} from '@modules/Profile/types/journal.model';
import {useTeams} from '@modules/Team/hooks/teams';
import {trans} from '@modules/Translations/util/i18n';

interface _FilterJournalsProps {
  getJournalsList: (filterParams: _JournalFilterParams) => Promise<void>;
  selectedCategoryGroup?: string | null;
  selectedCategory?: string | null;
}

export const FilterJournals: React.FC<_FilterJournalsProps> = ({
  getJournalsList,
  selectedCategoryGroup,
  selectedCategory: selectedCategoryOuter,
}) => {
  const [teams] = useTeams();
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [journalCategories, setJournalCategories] = useState<Record<string, _JournalCategory[]> | null>(null);

  const [filteringData, setFilteringData] = useState<_JournalSelectorData[]>([]);

  useEffect(() => {
    const newFilteringData: _JournalSelectorData[] = [];
    newFilteringData.push(addOrderFilterData());
    const topicsFilter = addTopicsFilterData();
    if (topicsFilter) {
      newFilteringData.push(topicsFilter);
    }

    const teamFilter = addTeamFilterData();

    if (teamFilter) {
      newFilteringData.push(teamFilter);
    }

    setFilteringData(newFilteringData.filter(Boolean));
  }, [selectedCategoryGroup, journalCategories]);

  useEffect(() => {
    setSelectedOrder('');
    setSelectedCategory(selectedCategoryOuter ?? '');
    setSelectedTeam('');
  }, [selectedCategoryGroup, selectedCategoryOuter]);

  useEffect(() => {
    void getJournalCategories();
    const filterParams = {
      orderBy: selectedOrder,
      filterCategoryId: selectedCategory,
      filterTeamId: selectedTeam,
    };
    void getJournalsList(filterParams);
  }, [selectedOrder, selectedCategory, selectedTeam, selectedCategoryGroup]);

  const addOrderFilterData = (): _JournalSelectorData =>
    createFilterData('order', trans('profile.journal.order_filter_label'), [
      {
        value: 'created_at',
        label: trans('profile.journal.order_filters.created_at'),
      },
      {
        value: 'updated_at',
        label: trans('profile.journal.order_filters.updated_at'),
      },
    ]);

  const addTopicsFilterData = (): _JournalSelectorData | null => {
    if (
      selectedCategoryGroup === 'how_are_you_doing' ||
      selectedCategoryGroup === 'performance_and_satisfaction' ||
      !selectedCategoryGroup
    ) {
      return null;
    }
    const options: _SelectValue[] = [];

    if (journalCategories?.[selectedCategoryGroup]) {
      journalCategories?.[selectedCategoryGroup]?.forEach(category => {
        options.push({
          value: category.id,
          label: trans(`profile.journal.categories.${selectedCategoryGroup}.${category.category}`),
        });
      });
    }
    return createFilterData('topic', trans('profile.journal.topic_filter_label'), options);
  };

  const createFilterData = (key: string, placeholder: string, options: _SelectValue[]): _JournalSelectorData => ({
    key,
    placeholder,
    options,
  });

  async function getJournalCategories(): Promise<void> {
    try {
      const response = await getAllCategories();
      setJournalCategories(response?.data as Record<string, _JournalCategory[]>);
    } catch (err) {
      console.log('Error:', err);
    }
  }

  const handleSelectorChange = (value: SingleValue<_SelectValue>, selectType: string): void => {
    switch (selectType) {
      case 'order':
        setSelectedOrder(value?.value as string | null);
        break;
      case 'topic':
        setSelectedCategory(value?.value as string | null);
        break;
      case 'team':
        setSelectedTeam(value?.value as string | null);
        break;
    }
  };

  function addTeamFilterData(): _JournalSelectorData | null {
    if (
      selectedCategoryGroup === 'soft_facts' ||
      selectedCategoryGroup === 'how_are_you_doing' ||
      selectedCategoryGroup === 'performance_and_satisfaction'
    ) {
      return null;
    }

    const options: _SelectValue[] = [];

    teams?.forEach(team => {
      options.push({
        value: team.id,
        label: team.name,
      });
    });
    return createFilterData('team', trans('profile.journal.team_filter_label'), options);
  }

  logger.info('FilterJournals.tsx: FilterJournals', {
    selectedOrder,
    selectedCategory,
    selectedTeam,
    journalCategories,
    filteringData,
  });

  return <JournalSelector data={filteringData} handleSelectorChange={handleSelectorChange} />;
};

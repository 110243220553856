// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {Typography} from '@modules/Core/components/base/Typography';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {formatMoney} from '@modules/Core/util/locale';
import {BILLING_INTERVALS} from '@modules/Profile/config/membershipsConstants';
import {_SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentDetailsBillingIntervalProps {
  billingInterval: string;
  setBillingInterval: (billingInterval: _SubscriptionBillingInterval) => void;
  newPlan: _PaymentPlan;
}

export const PaymentDetailsBillingInterval: React.FC<_PaymentDetailsBillingIntervalProps> = ({
  billingInterval,
  setBillingInterval,
  newPlan,
}) => {
  return (
    <div className="flex flex-col gap-3">
      {BILLING_INTERVALS.map(option => (
        <RadioButton
          variant="with-disc"
          border={false}
          label={
            <div className="flex flex-col items-start gap-2">
              <Typography>
                <b>
                  {formatMoney(newPlan?.price?.[option])}/{trans('subscription.types.monthly_abbreviated')}
                </b>
              </Typography>
              <div className="flex flex-row flex-wrap">
                <Typography>{trans(`subscription.types.${option}_price`)}</Typography>,&nbsp;
                <Typography>{trans('subscription.types.notice_period')}</Typography>
              </div>
            </div>
          }
          active={billingInterval === option}
          onChange={() => setBillingInterval(option)}
        />
      ))}
    </div>
  );
};

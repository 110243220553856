// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {logger} from '@modules/Core/util/Logger';

interface _Chip {
  label: string;
  color?: 'light' | 'dark' | 'brand';
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  active?: boolean;
  onClick?: () => void;
  icon?: React.ElementType;
  onIconClick?: () => void;
  className?: string;
  iconPosition?: 'left' | 'right';
}

export const Chip: React.FC<_Chip> = props => {
  const {label, color = 'light', disabled, size, active, onClick, className, iconPosition} = props;

  let finSize = 'h-6';
  if (size === 'sm') finSize = 'h-fit';
  if (size === 'md') finSize = 'h-6';
  if (size === 'lg') finSize = 'h-7';

  let border = 'border-grey300';
  if (color === 'dark') border = 'border-blue700';
  if (color === 'light') border = active ? 'border-blue700' : 'border-grey300';
  if (color === 'brand') border = 'border-brand500';

  if (disabled) border = 'border-grey500';

  let bgColor = 'bg-white500 hover:bg-grey100';
  if (disabled) bgColor = 'bg-grey500';

  const icon = props?.icon ? (
    <props.icon
      onClick={e => {
        if (disabled) return;
        // Stop propagation if icon is clicked ONLY when click for icon is provided, otherwise whole chip clickable
        if (props.onIconClick) {
          e.stopPropagation();
          props.onIconClick();
        }
      }}
      color={disabled && 'fill-white500'}
    />
  ) : null;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={addCN(
        className,
        'inline-flex transition py-1 px-2 items-center gap-1 rounded-lg border-1 w-fit',
        bgColor,
        finSize,
        border
      )}
    >
      {iconPosition === 'left' && icon}
      <span className={addCN(disabled && 'text-white500')}>{label}</span>
      {iconPosition === 'right' && icon}
    </button>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {ServiceInformationCard} from '@modules/Core/components/platform/services/ServiceInformationCard';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {getServiceImage} from '@modules/Profile/util/profileUtil';
import {SurveyNavigation} from '@modules/SurveyEngine/components/navigation/SurveyNavigation';
import {SurveyIntroPage} from '@modules/SurveyEngine/components/SurveyIntroPage';
import {SurveyQuestion} from '@modules/SurveyEngine/components/SurveyQuestion';
import {useSurvey} from '@modules/SurveyEngine/hooks/survey';
import {_SurveyParams} from '@modules/SurveyEngine/types/survey.model';
import {parseQuestion as utilParseQuestion} from '@modules/SurveyEngine/util/questionUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _SurveyProps {
  type: string;
  showSkip: boolean;
  onFinish: () => Promise<void>;
  surveyOptions: _SurveyParams;
  yourResultsButtonText: string;
}

export const Survey: React.FC<_SurveyProps> = ({type, showSkip, onFinish, surveyOptions, yourResultsButtonText}) => {
  const dialog = useDialog();
  const {
    survey,
    question,
    answers,
    onNextClicked,
    onBackClicked,
    onFinishClicked,
    onSkipSectionClicked,
    hasMorePages,
    progress,
    onAnswer,
    onSkipClicked,
    skipQuestion,
    isFirstQuestion,
    isLastQuestion,
  } = useSurvey(type, onFinish, surveyOptions);

  useEffect(() => {
    if (onSkipSectionClicked && question?.type === 'seperator') {
      const timer = setTimeout(() => {
        dialog.show({
          image: getDesignImage('save_time', 'png'),
          imageProps: {
            className: 'h-[300px]',
          },
          justifyContent: 'center',
          showCloseButton: true,
          titleGap: 2,
          title: trans('survey.already_answered_today.popup.title'),
          titleVariant: 'h4',
          justifyTitle: 'center',
          justifyButtons: 'center',
          children: trans('survey.already_answered_today.popup.description'),
          confirmLabel: trans('survey.skip_section'),
          onConfirm: () => setTimeout(onSkipSectionClicked, 300),
        });
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [question, onSkipSectionClicked, dialog]);

  function parseQuestion(q: string): string {
    if (q && answers) {
      return utilParseQuestion(survey, q, answers, null);
    }
    return q;
  }

  if (!survey) {
    return null;
  }

  if (question?.type === 'intro') {
    return (
      <ServiceInformationCard
        title={question?.title}
        content={question?.description}
        time={trans(`products.${survey?.type}.time`, null, false)}
        image={getServiceImage(survey?.type)}
        service={survey?.type}
        actions={[
          {
            label: trans('survey.lets_go'),
            type: 'button',
            onClick: () => onNextClicked?.(),
          },
        ]}
      />
    );
  }

  return (
    <div className={question?.class ?? ''} dusk="question-container">
      <div dusk="options-container" className={`flex flex-col gap-5 ${question?.type === 'welcome' ? 'h-full' : ''}`}>
        <SurveyIntroPage
          survey={survey}
          question={question}
          nextClicked={onNextClicked}
          parseQuestion={parseQuestion}
          progress={progress}
          hasMorePages={hasMorePages}
          isLastQuestion={isLastQuestion}
        />

        <SurveyQuestion
          survey={survey}
          question={question}
          parseQuestion={parseQuestion}
          onAnswer={onAnswer}
          onBackClicked={onBackClicked}
          onNextClicked={onNextClicked}
          answers={answers}
        />

        <SurveyNavigation
          survey={survey}
          question={question}
          onFinishClicked={onFinishClicked}
          nextClicked={onNextClicked}
          backClicked={onBackClicked}
          onSkipSectionClicked={onSkipSectionClicked}
          isSeparator={question?.type === 'seperator'}
          showSkip={showSkip && !isLastQuestion()}
          onSkipClicked={onSkipClicked}
          isFirstQuestion={isFirstQuestion}
          isLastQuestion={isLastQuestion}
          yourResultsButtonText={yourResultsButtonText}
          skipQuestion={skipQuestion}
          variant={question?.type === 'seperator' ? 'center' : 'right'}
          surveyType={survey?.type}
        />
      </div>
    </div>
  );
};

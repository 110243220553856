// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute} from '@/_core/util/util';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  description: string;
  setDescription: (value: string) => void;
  onChanges?: () => void;
}

export const ProfileDescription: React.FC<_Props> = ({description, setDescription, onChanges}) => {
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.target.value);
    onChanges?.();
  };

  return (
    <Section title={trans('profile.description')}>
      <TextArea value={description} onChange={handleInput} placeholder={trans('profile.description_label')} />
      <Typography
        dangerouslySetInnerHTML={{
          __html: trans('base.profile_description_consent', {
            route: flowRoute(FLOWS.ProfileSettings, null, {
              active: 'privacy',
            }),
          }),
        }}
      />
    </Section>
  );
};

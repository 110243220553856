// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {kebabize} from '@modules/Core/util/strings';
import {surveyFlows} from '@modules/FlowEngine/config/surveyFlows';
import {_NavigateParams} from '@modules/FlowEngine/hooks/navigateToFlow';
import {currentLanguage} from '@modules/Translations/util/i18n';

// Should be extended with params to match the one in laravel, for now this works
export function liveRoute(name: string): string {
  const subdomain = currentLanguage() === 'de' ? 'de.' : '';

  return `https://${subdomain}softfact.works/${name}`;
}

export function flowRoute(flowName: string, teamId?: string | null, params = {}, origin?: string): string | null {
  const flow = kebabize(flowName);

  let result: string | null;

  if (teamId) {
    result = `/flow/team/${teamId}/${flow}`;
  } else {
    result = `/flow/${flow}`;
  }

  if (params && Object.keys(params).length > 0) {
    result = `${result}?${new URLSearchParams(params).toString()}`;
  }

  if (origin) {
    // Ignore for now
    // result = `/${origin}${result}`;
  }

  return result;
}

export function profileSurveyFlowRoute(service: string): string | null {
  const flow = surveyFlows[service]?.name;
  if (!flow) {
    logger.error('Flow not found for service', service);
    return null;
  }
  return `/flow/${flow}`;
}

export function getLocationParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export function getPublicProfileRoute(profileId?: string): string {
  if (!profileId) {
    return '';
  }
  return flowRoute('PublicProfile', null, {
    profileId,
  });
}

export function getTeamMembersRoute(teamId: string): string {
  return `/teams/${teamId}/members`;
}

// function navigateToFlow(flowName: string, params?: NavigateParams, kebabized = false): void {
let navigateToCallback: any;
let navigateToFlowCallback: (flowName: string, params?: _NavigateParams, kebabized?: boolean) => void;

export const registerNavigateToCallback = (cb: any) => {
  navigateToCallback = cb;
};

export const registerNavigateToFlowCallback = (
  cb: (flowName: string, params?: _NavigateParams, kebabized?: boolean) => void
) => {
  navigateToFlowCallback = cb;
};

export const navigateTo = (options: any): void => {
  if (navigateToCallback) {
    navigateToCallback(options);
  } else {
    console.error('navigateTo callback is not registered');
  }
};

export const navigateToFlow = (flowName: string, params?: _NavigateParams, kebabized = false): void => {
  if (navigateToFlowCallback) {
    navigateToFlowCallback(flowName, params, kebabized);
  } else {
    console.error('navigateToFlow callback is not registered');
  }
};

export const navigateToFlowUsingWindow = (flowName: string, params?: _NavigateParams, kebabized = false): void => {
  const newLocation = flowRoute(flowName, null, params);
  navigateToUsingWindow(newLocation);
};

export function navigateToUsingWindow(newLocation: any): void {
  window.location = newLocation;
}

export function isOnServer(): boolean {
  return typeof window === 'undefined';
}

export function sessionConfig(key: string): string | null {
  if (isOnServer()) {
    return null;
  }
  return localStorage.getItem(key);
}

export function setSessionConfig(key: string, value: string): void {
  if (isOnServer()) {
    return;
  }
  localStorage.setItem(key, value);
}

export function removeSessionConfig(key: string): void {
  if (isOnServer()) {
    return;
  }
  localStorage.removeItem(key);
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {createDynamicItemComponent} from '@modules/ContentEngine/config/registeredItems';
import {_ContentEngineItem} from '@modules/ContentEngine/types/contentEngineItem.model';
import {logger} from '@modules/Core/util/Logger';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

interface _Props {
  item: _ContentEngineItem;
  tutorials?: Record<string, _Tutorial>;
  dynamicParams?: Record<string, any>;
}

export const BaseContentEngineItem: React.FC<_Props> = ({dynamicParams, tutorials = [], item}) => {
  logger.debug('ContentEngineContainer: Rendering', {
    tutorials,
    dynamicParams,
  });
  return createDynamicItemComponent(item, tutorials, dynamicParams);
};

// @ts-nocheck
/* eslint-disable */

import {initDonutGauge} from './initDonutGauge';
import {initializeLinesChart} from './initializeLinesChart';
import {initializePolarAreaChart} from './initializePolarAreaChart';
import {initSolidGaugeMulti} from './initSolidGaugeMulti';

export const initAmCharts = {
  initSolidGaugeMulti,
  initializeLinesChart,
  initializePolarAreaChart,
  initDonutGauge,
};

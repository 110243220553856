// @ts-nocheck
/* eslint-disable */

// import delete icon

import React from 'react';
import {horizontalListSortingStrategy} from '@dnd-kit/sortable';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Droppable} from '@modules/Core/components/base/dnd/Droppable';
import {SortableContext} from '@modules/Core/components/base/dnd/SortableContext';
import {SurveyDnDItem} from '@modules/SurveyEngine/components/types/SurveyMutliSelectDragDropType/SurveyDnDItem';

interface _Props {
  id: string;
  label: string;
  values: any[];
  onItemDelete: (item: any) => void;
  getItemTooltip?: (itemKey: string) => string;
}

export const SurveyDnDCard: React.FC<_Props> = ({id, label, values, onItemDelete, getItemTooltip}) => {
  return (
    <SortableContext items={values} id={id} strategy={horizontalListSortingStrategy}>
      <Droppable className="flex w-full" id={id}>
        <Card title={label} className="min-h-[300px]">
          {/*    color: question?.color ? `var(--${question?.color[index]})` : '', */}
          <div
            id={`drop-list-container-${id}`}
            dusk="drop-list-container"
            className="flex flex-row flex-wrap gap-3 w-full h-full"
          >
            {(values || [])?.map((item, index) => (
              <SurveyDnDItem
                tooltip={getItemTooltip?.(item?.key) ?? null}
                item={item}
                onDelete={() => {
                  onItemDelete?.(item);
                }}
              />
            ))}
          </div>
        </Card>
      </Droppable>
    </SortableContext>
  );
};

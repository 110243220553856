// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {addDays} from 'date-fns';
import {logger} from '@modules/Core/util/Logger';
import {_ProfileSatisfactionPerformanceResult} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';
import {GraphItem} from '../../GraphItem';

interface _ProfileSatisfactionGraphItemProps {
  data: _ProfileSatisfactionPerformanceResult[];
}

interface _GraphDataPoint {
  date: number;
  satisfaction: number | null;
  performance: number | null;
  service?: string;
  value: number;
  isPrediction: boolean;
  satisfactionColor?: string;
  performanceColor?: string;
}

interface _GraphData {
  labels: Array<{label: string; accessor: string; color: string}>;
  data: _GraphDataPoint[];
}

export const ProfileSatisfactionGraphItem: React.FC<_ProfileSatisfactionGraphItemProps> = ({data}) => {
  const graphType = 'Lines';
  const [graphData, setGraphData] = useState<_GraphData>({labels: [], data: []});

  useEffect(() => {
    const newData: _GraphDataPoint[] = [];

    (data || []).forEach(({performance, satisfaction}) => {
      if (!performance.value || !satisfaction.value) {
        return;
      }

      newData.push({
        date: new Date(performance.created_at).getTime(),
        satisfaction: Math.round(((satisfaction.value - 1) * 1000) / 4) / 10 || null,
        performance: Math.round(((performance.value - 1) * 1000) / 4) / 10 || null,
        service: trans(`service.${performance.service}`),
        value: 100,
        isPrediction: !satisfaction.is_measurement,
      });
    });

    setGraphData({
      labels: [
        {label: trans('base.performance'), accessor: 'performance', color: 'var(--color-brand700)'},
        {label: trans('base.satisfaction'), accessor: 'satisfaction', color: 'var(--color-blue700)'},
      ],
      data: newData,
    });
  }, [data]);

  return (
    data && (
      <GraphItem
        type={graphType}
        data={graphData}
        chartOptions={{
          hideXAxisLabel: false,
          hideYAxisLabel: false,
          customYLabels: [0, 25, 50, 75, 100],
          hideXGrid: false,
          hideYGrid: false,
          customYGridLines: [0, 25, 50, 75, 100],
          toPercentage: true,
          minDate: addDays(new Date(data[0].performance.created_at), 1).getTime(),
          maxDate: addDays(new Date(data[data.length - 1].performance.created_at), 0).getTime(),
        }}
        height={300}
      />
    )
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconHome} from '@modules/Core/components/base/Icons/Icons';
import {Section} from '@modules/Core/components/layout/Section';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profileId: string;
}

export const CustomSurveyNotAvailablePage: React.FC<_Props> = ({profileId}) => {
  const navigateToFlow = useNavigateToFlow();
  return (
    <Section
      imageViewDirection="vertical"
      image="/assets/img/design_images/not_found.png"
      title={trans('surveys.not_available_title')}
      description={trans('surveys.not_available_description')}
      justifyContent="center"
    >
      {Boolean(!profileId) && (
        <div className="flex justify-center items-center">
          <Button
            variant="brand"
            width="relative-col-width-3"
            label={trans('surveys.try_out_softfact')}
            onClick={() => navigateToFlow('Login', {type: 'register'})}
          />
        </div>
      )}
      {Boolean(profileId) && (
        <div className="flex justify-center items-center">
          <Button
            width="relative-col-width-3"
            label={trans('surveys.take_me_home')}
            onClick={() => navigateToFlow('ProfileDashboard')}
            icon={IconHome}
            iconPosition="left"
          />
        </div>
      )}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _ContentArea {
  label: string;
  buttonLabel?: string;
  children: React.ReactNode;
  onSubmit?: () => void;
}

export const ContentArea: React.FC<_ContentArea> = ({label, buttonLabel, children, onSubmit}) => (
  <form
    onSubmit={e => {
      e.preventDefault();
      onSubmit?.();
    }}
  >
    <div className="flex flex-col gap-3">
      <h5>{label}</h5>
      {children}
    </div>
  </form>
);

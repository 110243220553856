// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {
  _LeaderboardProfile,
  LeaderboardItem,
} from '@modules/ContentEngine/components/items/gamification/LeaderboardItem';
import ProfileTeamSelectionItem from '@modules/ContentEngine/components/items/profile/teams/ProfileTeamSelectionItem';
import {logger} from '@modules/Core/util/Logger';

export interface _LeaderboardTeam {
  id: string;
  profiles: string[];
  name: string;
}

interface _Props {
  teams: Record<string, _LeaderboardTeam>;
  allProfiles: _LeaderboardProfile[];
  // Profile ids
  showLevels?: boolean;
  currentProfileId?: string;
  supportedMetrics: Record<string, _MetricTab>;
}

interface _MetricTab {
  label: string;
  icon: string;
}

export const InstitutionLeaderboardItem: React.FC<_Props> = ({
  supportedMetrics,
  currentProfileId,
  showLevels,
  teams,
  allProfiles,
}) => {
  const [currentTeamId, setCurrentTeamId] = useState<string | null>(null);

  useEffect(() => {
    if (teams) {
      setCurrentTeamId(prevState => {
        if (prevState === null) {
          return Object.keys(teams)[0];
        }
        return prevState;
      });
    }
  }, [teams]);

  const currentTeam = teams?.[currentTeamId ?? ''];

  const currentTeamProfiles = currentTeam?.profiles.map(profileId => {
    return allProfiles.find(profile => profile.id === profileId);
  });

  logger.debug('InstitutionLeaderboardItem', {currentTeamProfiles, currentTeam, currentTeamId});
  return (
    <div className="flex flex-col gap-3">
      <LeaderboardItem
        tabsPrefix={
          <div className="flex flex-row w-full items-center justify-end" style={{zIndex: 2}}>
            <ProfileTeamSelectionItem teams={Object.values(teams)} onTeamChosen={setCurrentTeamId} />
          </div>
        }
        profiles={currentTeamProfiles ?? []}
        supportedMetrics={supportedMetrics}
        showLevels={showLevels}
        currentProfileId={currentProfileId}
      />
    </div>
  );
};

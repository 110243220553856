// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {resendToken, verifyToken} from '@modules/Core/services/api/register';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';
import {Divider} from '../../base/Divider';

interface _RegisterConfirmationProps {
  // Basic data
  email: string | null;

  // Actions
  onLogin: (login: boolean) => void;
}

export const RegisterConfirmation: React.FC<_RegisterConfirmationProps> = ({email, onLogin}) => {
  const snackbar = useSnackbar();

  const [otp, setOtp] = useState<string | null>(null);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    void sendActivationToken();
  }

  async function sendActivationToken() {
    if (!otp) {
      return;
    }

    try {
      dispatchReactEvent('navigate.start', {step: 'activationToken', direction: 'next'});
      const tokenVerificationResponse = await verifyToken(email, otp);

      if (
        tokenVerificationResponse.status === 200 &&
        tokenVerificationResponse.data &&
        !tokenVerificationResponse?.data?.error &&
        tokenVerificationResponse?.data['success.message']
      ) {
        onLogin(true);
        return;
      }
      snackbar.danger(trans(tokenVerificationResponse?.data?.error));
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
    dispatchReactEvent('navigate.done');
  }

  async function resendActivationToken() {
    if (!email) {
      return;
    }
    try {
      dispatchReactEvent('navigate.start', {step: 'resendActivationToken', direction: 'next'});
      const resendActivationResponse = await resendToken(email);

      if (resendActivationResponse && resendActivationResponse.status === 200) {
        snackbar.success(trans('auth.resend_activation_success'));
      }
    } catch (e) {
      logger.error('error', e);
    }
    dispatchReactEvent('navigate.done');
  }

  return (
    <form onSubmit={e => onSubmit(e)} className="hide-required-stars">
      <Section gap={5}>
        <Input
          value={otp}
          onChange={e => setOtp(e)}
          name="activation_token"
          label={trans('auth.activation_token')}
          placeholder={trans('auth.activation_token_placeholder')}
          required
          autoFocus
        />

        <div className="flex flex-row justify-end">
          <div className="relative-col-width-2">
            <Button dusk="verify-btn" type="submit">
              {trans('auth.activation_token_button')}
            </Button>
          </div>
        </div>

        <Divider light />

        <div className="flex flex-row gap-1 w-full">
          <TextLink
            dusk="resend-activation-btn"
            label={trans('auth.resend_activation_button')}
            onClick={async () => await resendActivationToken()}
          />
        </div>
      </Section>
    </form>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {FC, ReactNode} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Typography} from '@modules/Core/components/base/Typography';
import './RoleCard.scss';
import {logger} from '@modules/Core/util/Logger';
import {ExpandableContent} from '../ExpandableContent';
import {Carousel} from '@modules/Core/components/base/carousel/Carousel';
import {twMerge} from 'tailwind-merge';

type _expandedContents = Array<{
  title?: string;
  description: string | ReactNode;
}>;

interface _expandedContentActions {
  title: string;
  buttons: Array<{
    label: string;
    onClick: () => void;
  }>;
}

interface _RoleCard {
  title?: string;
  icon: FC;
  description?: string;
  showExpander?: boolean;
  avatarComponent?: ReactNode;
  expandedContents?: _expandedContents;
  scores?: Array<{
    value: number;
    profilePicture: string;
  }>;
  expandedContentActions?: _expandedContentActions;
  score: number;
  renderAvatarComponent?: (data: {value: number; profilePicture: string}) => ReactNode;
  indicators?: Array<{
    value: number;
    color: string;
  }>;
}

const MAX_SCORE = 5;
export const RoleCard: FC<_RoleCard> = ({
  title,
  icon,
  score,
  description,
  showExpander,
  avatarComponent,
  expandedContents,
  expandedContentActions,
  scores,
  renderAvatarComponent,
  indicators = [],
}) => {
  return (
    <div className="flex p-3 flex-col items-start gap-3 rounded-sm border-1 border-grey300 bg-white500 w-full h-full">
      {/* card content */}
      <div className="flex flex-col items-start gap-3 self-stretch">
        <Typography variant="h6">{title}</Typography>

        {/* icons */}
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex items-center gap-2">
            <IconsDiv Icon={icon} score={score} indicators={indicators} />
          </div>
          {avatarComponent ?? null}
        </div>

        <Typography>{description}</Typography>

        {Boolean(scores?.length) && <Carousel renderItem={renderAvatarComponent} items={scores} limit={7} />}

        <ExpandableContent showExpander={showExpander}>
          <ExpandedContent expandedContents={expandedContents} expandedContentActions={expandedContentActions} />
        </ExpandableContent>
      </div>
    </div>
  );
};

interface _ExpandedContent {
  expandedContents?: _expandedContents;
  expandedContentActions?: _expandedContentActions;
}

const ExpandedContent: React.FC<_ExpandedContent> = ({expandedContents, expandedContentActions}) => (
  <div className="flex flex-col items-start gap-5">
    {expandedContents
      ? expandedContents.map((d, i) => (
          <div key={d.title} className="flex flex-col items-start gap-3 self-stretch">
            <Typography bold>{d.title}</Typography>
            <Typography>{d.description}</Typography>
          </div>
        ))
      : null}

    {expandedContentActions ? (
      <div className="flex flex-col gap-3">
        <Typography bold>{expandedContentActions.title}</Typography>

        <div className="flex flex-row gap-3">
          {expandedContentActions.buttons.map((b, i) => (
            <Button key={b.label} {...b} />
          ))}
        </div>
      </div>
    ) : null}
  </div>
);

const IconsDiv: React.FC<{
  Icon: FC;
  score: number;
  index: number;
  indicators: Array<{value: number; color: string}>;
}> = ({Icon, score, indicators}) => {
  const fullColoredIconsCount = Math.floor(score);
  const partialIconVisible = (score % 1) * 100;
  const greyIconsCount = MAX_SCORE - Math.ceil(score);

  return (
    <div className="icons-container gap-2 relative">
      {[...Array(fullColoredIconsCount)].map((_, index) => (
        <Icon key={`brand-${index}`} color="fill-brand500" size="lg" />
      ))}
      {partialIconVisible > 0 && (
        <div className="relative" style={{width: '32px', height: '32px'}}>
          {' '}
          {/* Adjust size as needed */}
          <div
            className="absolute"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Icon color="fill-grey700" size="lg" />
          </div>
          <div
            className="absolute"
            style={{
              width: `${partialIconVisible}%`,
              height: '100%',
              zIndex: 1,
              overflow: 'hidden',
            }}
          >
            <Icon color="fill-brand500" size="lg" />
          </div>
        </div>
      )}

      {[...Array(greyIconsCount)].map((_, index) => (
        <Icon key={`grey-${index}`} color="fill-grey700" size="lg" />
      ))}

      {indicators?.map(indicator => {
        return (
          <div
            style={{
              width: '1px',
              left: `${(indicator.value / MAX_SCORE) * 100}%`,
              color: indicator.color,
            }}
            /**
                top: -5px;
                left: 0;
                margin-left: 0 !important;
                margin-right: 0 !important;
                width: {{ $youPercentage }}%;
                padding:3px;
                border-right:2px dashed;
                color:#68D391;
                height:60px;
*/
            className={twMerge(
              'absolute',
              'overflow-hidden',
              'top-0',
              'left-0',
              'ml-0',
              'mr-0',
              'border-r-2',
              'border-dashed',
              'h-[50px]'
            )}
          />
        );
      })}
    </div>
  );
};

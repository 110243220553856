// @ts-nocheck
/* eslint-disable */

export function kebabize(str: string): string {
  return str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCase(str: string | null): string {
  if (!str) return '';
  return str.replace(/([-_][a-z])/gi, $1 => $1.toUpperCase().replace('-', '').replace('_', ''));
}

export function pascalCase(str: string): string {
  return capitalize(camelCase(str));
}

export function dictAsPathVariables(dict: Record<string, string | number | boolean>): string {
  if (!dict) return '';

  const queryString = Object.entries(dict)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return `?${queryString}`;
}

export function concatNames(names: string[], delimiter: string = ', '): string {
  return names.filter(Boolean).join(delimiter);
}

export function areUrlsEqual(url1: string, url2: string): boolean {
  // Helper function to parse query parameters into an object
  const parseQueryParams = (url: string): Record<string, string> => {
    const queryParams: Record<string, string> = {};
    const queryString = url.split('?')[1];

    if (!queryString) return queryParams; // No query parameters to parse

    const params = new URLSearchParams(queryString);
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams;
  };

  // Extract the base URL (before the "?")
  const baseUrl1 = url1.split('?')[0];
  const baseUrl2 = url2.split('?')[0];

  // Compare base URLs
  if (baseUrl1 !== baseUrl2) {
    return false;
  }

  // Parse and compare query parameters
  const params1 = parseQueryParams(url1);
  const params2 = parseQueryParams(url2);

  // Check if both objects have the same number of keys
  if (Object.keys(params1).length !== Object.keys(params2).length) {
    return false;
  }

  // Check if each key-value pair matches
  for (const key in params1) {
    if (params1[key] !== params2[key]) {
      return false;
    }
  }

  return true;
}

// Example usage:
const url1 = '/flow/profile-exercises?service=profile_resilience&round=0';
const url2 = '/flow/profile-exercises?round=0&service=profile_resilience';

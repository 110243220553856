// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconArrowLeft, IconArrowRight} from '@modules/Core/components/base/Icons/Icons';

export interface _Props {
  renderItem: (data: any) => React.ReactNode;
  items: any[];
  limit?: number;
}

export const Carousel: React.FC<_Props> = ({renderItem, items, limit = 3}) => {
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const newCurrentItems = items?.slice(currentIndex, currentIndex + limit);
    setCurrentItems(newCurrentItems);
  }, [items, limit, currentIndex]);

  const onPrevious = (): void => {
    if (currentIndex === 0) {
      return;
    }

    const newIndex = Math.max(0, currentIndex - limit);
    setCurrentIndex(newIndex);
  };

  const onNext = (): void => {
    if (currentIndex + limit >= items?.length) {
      return;
    }

    const newIndex = Math.min(items.length - 1, currentIndex + limit);
    setCurrentIndex(newIndex);
  };

  const showButtons = items?.length > limit;
  return (
    <div className="flex flex-row gap-3 items-center flex-grow w-full">
      {Boolean(showButtons && currentIndex > 0) && (
        <div className={twMerge('flex flex-row items-center justify-start')}>
          <IconButton disabled={currentIndex === 0} onClick={onPrevious} icon={IconArrowLeft} />
        </div>
      )}

      {currentItems?.map((item, index) => {
        return renderItem(item);
      })}

      {Boolean(showButtons && currentIndex + limit < items?.length) && (
        <div className={twMerge('flex flex-row items-center ml-auto')}>
          <IconButton disabled={currentIndex + limit >= items?.length} onClick={onNext} icon={IconArrowRight} />
        </div>
      )}
    </div>
  );
};

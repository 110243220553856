// @ts-nocheck
/* eslint-disable */

export const SUPER_ADMIN_ROLE = 'Super-Admin';
export const TEAM_OWNER_ROLE = 'Team-Owner';
export const TEAM_ADMIN_ROLE = 'Team-Admin';
export const TEAM_MEMBER_ROLE = 'Team-Member';
export const INSTITUTION_OWNER_ROLE = 'Org-Owner';
export const INSTITUTION_ADMIN_ROLE = 'Org-Admin';
export const INSTITUTION_MEMBER_ROLE = 'Org-Member';
export const MARKETING_ADMIN_ROLE = 'Marketing-Admin';
export const SALES_ADMIN_ROLE = 'Sales-Admin';
export const TEAM_COACH_ROLE = 'Team-Coach';
export const INSTITUTION_COACH_ROLE = 'Org-Coach';

export const rolesLanguageDict = {
  [SUPER_ADMIN_ROLE]: 'roles.super_admin',
  [TEAM_OWNER_ROLE]: 'roles.team_owner',
  [TEAM_ADMIN_ROLE]: 'roles.team_admin',
  [TEAM_MEMBER_ROLE]: 'roles.team_member',
  [INSTITUTION_OWNER_ROLE]: 'roles.organisation_owner',
  [INSTITUTION_ADMIN_ROLE]: 'roles.organisation_admin',
  [INSTITUTION_MEMBER_ROLE]: 'roles.organisation_member',
  [MARKETING_ADMIN_ROLE]: 'roles.marketing_admin',
  [SALES_ADMIN_ROLE]: 'roles.sales_admin',
  [INSTITUTION_COACH_ROLE]: 'roles.organisation_coach',
  [TEAM_COACH_ROLE]: 'roles.team_coach',
};
// TODO @Sherif stop using this and move to using types from model.ts
export const TEAM_ROLES = [TEAM_OWNER_ROLE, TEAM_ADMIN_ROLE, TEAM_MEMBER_ROLE];
export const ORG_ROLES = [INSTITUTION_OWNER_ROLE, INSTITUTION_ADMIN_ROLE, INSTITUTION_MEMBER_ROLE];
export const MANAGE_TEAM_ROLES_PERMISSION = 'team.roles:manage';
export const MANAGE_TEAM_OWNERSHIP = 'team.roles:transfer_ownership';
export const MANAGE_INST_OWNERSHIP = 'organisation.roles:transfer_ownership';
export const MANAGE_INST_ROLES_PERMISSION = 'organisation.roles:manage';

export const rolesLangDict: Record<string, string> = {
  [TEAM_ADMIN_ROLE]: 'roles.team_admin',
  [TEAM_MEMBER_ROLE]: 'roles.team_member',
  [TEAM_OWNER_ROLE]: 'roles.team_owner',
  [INSTITUTION_ADMIN_ROLE]: 'roles.organisation_admin',
  [INSTITUTION_MEMBER_ROLE]: 'roles.organisation_member',
  [INSTITUTION_OWNER_ROLE]: 'roles.organisation_owner',
};

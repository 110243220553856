// @ts-nocheck
/* eslint-disable */

import React, {ElementType, useEffect, useState} from 'react';
import {IconEasy, IconEnergySource} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {ProfilePersonalityResultDevMode} from '@modules/Dev/components/ProfilePersonalityResultDevMode';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceGraphItemProps {
  /**
   * Looks like mmhll or smth like that
   * m - medium, h - high, l - low
   * Ordered O->C->E->A->N
   */
  data: string;
}

export const ProfilePersonalityTraitsDetailsItem: React.FC<_ProfileServiceGraphItemProps> = ({data}) => {
  // Graph data
  const [score, setScore] = useState<string | null>(null);
  const [isDevMode, setIsDevMode] = useState<boolean>(false);

  useEffect(() => {
    setScore(data);
  }, [data]);

  return (
    <Section>
      <ProfilePersonalityResultDevMode
        personalityResult={score}
        setPersonalityResult={value => {
          setScore(value);
        }}
        isDevMode={isDevMode}
        setIsDevMode={setIsDevMode}
      />
      <div className="flex flex-row items-center gap-3">
        <img
          src="/assets/img/undraw/personality-analysis-traits.svg"
          alt="personality-analysis-traits"
          className="relative-col-width-4"
        />

        <Section titleVariant="h6" title={trans('profile_result.kauai.summary.title')}>
          <Typography>{trans(`profile_result.kauai.summary.result.${score}.behavior`)}</Typography>
        </Section>
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title={trans('profile_result.kauai.headlines.energy_sources')}
          Icon={IconEnergySource}
          description={trans(`profile_result.kauai.summary.result.${score}.energy.sources`)}
        />

        <InternalSection
          title={trans('profile_result.kauai.headlines.easy')}
          Icon={IconEasy}
          description={trans(`profile_result.kauai.summary.result.${score}.potential.easy`)}
        />
      </div>
    </Section>
  );
};

interface _InternalSectionProps {
  title: string;
  description: string;
  Icon: ElementType;
}

export const InternalSection: React.FC<_InternalSectionProps> = ({title, description, Icon}) => (
  <div className="w-1/2">
    <Section
      titleVariant="h6"
      title={
        <div className="flex flex-col gap-2">
          <Icon color="fill-brand500" size="lg" />
          {title}
        </div>
      }
    >
      <Typography>{description}</Typography>
    </Section>
  </div>
);

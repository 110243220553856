// @ts-nocheck
/* eslint-disable */

const getWidth = (scaleFactor: number) => {
  const widthAtDefault = 550; // Default width
  const widthPerQuarter = 140; // Decrease width by this amount per 25% decrement

  // Ensure scaleFactor is within the range [0, 1]
  if (scaleFactor < 0) scaleFactor = 0;
  if (scaleFactor > 1) scaleFactor = 1;

  // Calculate the number of 25% decrements
  const decrements = Math.floor((1 - scaleFactor) / 0.25);

  // Calculate the new width
  const newWidth = widthAtDefault - decrements * widthPerQuarter;

  // Ensure the new width is not less than 0
  return Math.max(newWidth, 0);
};

export const plotlyCenteredResponsiveLayout: any = (options, scaleFactor, graphType) => ({
  font: {
    size: 8,
  },
  autosize: true,
  showlegend: false,
  height: options.layout.height,
  width: getWidth(scaleFactor),
  margin: getMargin(scaleFactor, graphType),
  paper_bgcolor: '#F7F7F7',
  plot_bgcolor: '#F7F7F7',
  texttemplate: '%{text:.10s}',
  yaxis: {
    automargin: true,
  },
  xaxis: {
    automargin: true,
  },
});

const getMargin = (scaleFactor: number, graphType: string) => {
  const specialGraphs = ['bullet'];
  if (!specialGraphs.includes(graphType)) {
    return {
      l: 75,
      r: 75,
      b: 50,
      t: 50,
      pad: 4,
    };
  }

  return {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 4,
  };
};

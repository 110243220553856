// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Action} from '@modules/Core/components/layout/Action';
import {PaymentPlanHeaderPrice} from '@modules/Core/components/platform/payments/paymentPlan/PaymentPlanHeaderPrice';
import {PaymentPlanTitle} from '@modules/Core/components/platform/payments/paymentPlan/PaymentPlanTitle';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {logger} from '@modules/Core/util/Logger';
import {_Institution} from '@modules/Institution/types/institution.model';
import {FREE_PLAN_ID} from '@modules/Profile/config/membershipsConstants';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {
  hasRunningOneTimePayment,
  isMembershipSubscription,
  isOneTimePaymentSubscription,
} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

// TODO @sheriF TYPES
interface _PaymentPlanHeaderProps {
  profile: _Profile | null;
  institution: _Institution | null;
  currentSubscription: _Subscription | null;
  plan: _PaymentPlan;
  isCurrentPlan: boolean;
  actions: _UIAction[];
}

export const PaymentPlanHeader: React.FC<_PaymentPlanHeaderProps> = ({
  profile,
  institution,
  plan,
  currentSubscription,
  isCurrentPlan,
  actions,
}) => {
  if (!plan) {
    return null;
  }

  const isTrial = currentSubscription?.pivot?.is_trial;

  const isOneTimePayment = isOneTimePaymentSubscription(plan?.id);
  const isMembership = isMembershipSubscription(plan?.id);
  const isFree = plan?.id === FREE_PLAN_ID;

  return (
    <div className="flex flex-row items-center gap-2">
      <PaymentPlanTitle plan={plan} />
      <div className="flex flex-row justify-start ml-0">
        {((isCurrentPlan && (!isFree || !hasRunningOneTimePayment(profile))) ||
          (isOneTimePayment && hasRunningOneTimePayment(profile))) && (
          <Badge size="fit" value={trans(isTrial ? 'subscription.current_trial' : 'subscription.current_plan')} />
        )}
      </div>
      <PaymentPlanHeaderPrice
        institution={institution}
        currentSubscription={currentSubscription}
        profile={profile}
        plan={plan}
        isCurrentPlan={isCurrentPlan}
      />
      <div className="flex-grow flex flex-row justify-end">
        {actions?.map(action => <Action key={action.label} {...action} />)}
      </div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {FC, useEffect, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {_ProfileValueAnimation} from '@modules/ContentEngine/components/items/profile/result/values/ProfileValuesItem';
import {IconDiamond} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';

interface _ValuesGemProps {
  points?: number;
  onClick?: () => void;
  className?: string;
  animation?: _ProfileValueAnimation;
}

export const ValuesGem: FC<_ValuesGemProps> = ({animation, className, points, onClick}) => {
  const [animate, setAnimate] = useState<boolean>(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!animation) {
        setAnimate(false);
        return;
      }
      const animationStart = new Date(animation?.created_at).getTime();
      const animationEnd = animationStart + animation?.duration * 1000;
      const now = Date.now();
      const shouldAnimate = now >= animationStart && now <= animationEnd;
      setAnimate(shouldAnimate);
      if (!shouldAnimate) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [animation]);

  return (
    <div className={addCN('flex flex-row w-full gap-1', className)} onClick={onClick}>
      <div className={addCN(animate && 'animate-spin-twice')}>
        <IconDiamond />
      </div>
      {points !== undefined && <Typography variant="body">{points}</Typography>}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ValuesCard} from '@modules/Core/components/base/cards/ValueCard';
import {ValuesGem} from '@modules/Profile/components/services/result/values/ValuesGem';
import {_TeamValuesEntry} from '@modules/Team/types/teamanalysis.model';

interface _Props {
  item: _TeamValuesEntry;
}

export const TeamValuesChangeChipComponent: React.FC<_Props> = ({item}) => {
  return <ValuesCard value={item} valueScoreComponent={<ValuesGem className="justify-end" points={item.points} />} />;
};

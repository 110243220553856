// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const ComponentStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [component, setComponent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const {componentName, params} = options;

    if (!componentName) {
      return;
    }

    setComponent(React.createElement(componentName, params));
  }, []);

  return (
    <BaseStep options={options}>
      <>{component}</>
    </BaseStep>
  );
};

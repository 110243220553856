// @ts-nocheck
/* eslint-disable */

import {_Profile} from '@modules/Profile/types/profile.model';
import {appState} from '@modules/State/util/util';

export function getProfileSalutation(currentProfile: _Profile): string | null {
  const currentLang = appState().i18n?.lang ?? 'de';
  const name = currentProfile?.first_name;

  const salutation = currentProfile?.salutation?.[currentLang];

  if (!salutation) {
    return null;
  }

  return `${salutation}, ${name}`;
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {FeelingsIcon} from '@modules/Core/components/base/lottie/FeelingsIcon';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {addProfileFeeling} from '@modules/Profile/services/profileApi';
import {trans} from '@modules/Translations/util/i18n';
import {getHowAreYouItemId} from '@modules/Tutorials/util/tutorialIdUtil';

export type _Feeling = 'excited' | 'happy' | 'neutral' | 'upset' | 'sad';
const feelingDescriptions: Record<_Feeling, string> = {
  excited: 'dashboard.feelings.excited',
  happy: 'dashboard.feelings.happy',
  neutral: 'dashboard.feelings.neutral',
  upset: 'dashboard.feelings.upset',
  sad: 'dashboard.feelings.sad',
};

interface _Props {
  todaysFeeling: number | string;
}

export const HowAreYouItem: React.FC<_Props> = ({todaysFeeling}) => {
  const [activeFeeling, setActiveFeeling] = useState<number | null>(null);
  const [feelingLotties] = useState<string[]>(['excited', 'happy', 'neutral', 'upset', 'sad']);

  useEffect(() => {
    if (todaysFeeling !== null && todaysFeeling !== undefined) setActiveFeeling(parseInt(todaysFeeling.toString(), 10));
  }, [todaysFeeling]);

  async function handleClick(newIndex: number): Promise<void> {
    const oldIndex = activeFeeling;
    try {
      setActiveFeeling(parseInt(newIndex.toString(), 10));
      const response = await addProfileFeeling(newIndex.toString());
      const newValue = response?.data?.value;
      if (newValue === undefined || newValue === null) {
        // If null, is reset
        setActiveFeeling(oldIndex);
      }
      reloadContentEngine();
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
      setActiveFeeling(oldIndex);
    }
  }

  return (
    <div className="flex flex-col gap-3 items-start w-full">
      <div className="flex justify-start lg:justify-center items-start flex-wrap lg:flex-nowrap gap-3 w-full">
        {feelingLotties.map((key, i) => (
          <div key={key} id={getHowAreYouItemId(key as _Feeling)} className="relative-col-width-2-5-no-gap">
            <FeelingsIcon
              label={trans(feelingDescriptions[key])}
              key={key}
              lottieKey={key}
              active={activeFeeling === i + 1}
              onClick={(): void => {
                void handleClick(i + 1);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

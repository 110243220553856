// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {logger} from '@modules/Core/util/Logger';
import {useProfileGlobalDialog} from '@modules/Profile/providers/ProfileGlobalDialogProvider';

interface _Props {
  alerts: _ProfileAlert[];
}

export const ProfileAlerts: React.FC<_Props> = ({alerts}) => {
  const profileGlobalDialog = useProfileGlobalDialog();

  useEffect(() => {
    logger.debug('ProfileAlerts', alerts);
    profileGlobalDialog.addAlerts(alerts);
  }, [alerts]);

  return null;
  // return <ProfileAlert alert={currentAlert} onDone={handleDone} />;
};

// @ts-nocheck
/* eslint-disable */

import React, {useMemo} from 'react';
import InnerHTML from 'dangerously-set-html-content';

interface _AppInnerHTMLProps {
  html: string;
  id?: string;
  onClick?: () => void;
  onFormSubmit?: () => void;
  className?: string;
}

export const AppInnerHTML: React.FC<_AppInnerHTMLProps> = ({html, id, onClick, onFormSubmit, className, ...props}) => {
  const innerHTMLComponent = useMemo(() => {
    if (!html) {
      return null;
    }
    // create simple checksum for html
    const htmlChecksum = html.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
    return (
      <InnerHTML
        key={htmlChecksum}
        className={className}
        id={id}
        onSubmit={onFormSubmit}
        onClick={onClick}
        html={html}
        {...props}
      />
    );
  }, [html, id, onClick, onFormSubmit, className, props]);

  return <>{innerHTMLComponent}</>;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {isArray} from '@modules/Core/util/typesUtil';
import {convertKey, getSpecialField, getSpecialFieldKey} from '@modules/SurveyEngine/util/questionUtil';

export interface _SurveyMultiSelectTypeProps {
  question: any;
  onValueChosen: (question: any, value: any, extra?: boolean) => void;
  answers: any;
}

export const SurveyMultiSelectType: React.FC<_SurveyMultiSelectTypeProps> = ({question, onValueChosen, answers}) => {
  const questionKey = convertKey(question?.key);

  return (
    <div className="survey-multiselect">
      {Object.keys(question?.answers || {}).map(key => {
        // @php($specialField = $getSpecialField($answer))

        const specialField = getSpecialField(question?.answers[key]);

        if (specialField) {
          let value = '';
          const specialFieldKey = getSpecialFieldKey(questionKey);
          if (Object.keys(answers).includes(specialFieldKey)) {
            value = answers[specialFieldKey];
          }

          return (
            <label className="mt-4">
              <span className="inline-block mb-4">{specialField?.label}</span>
              {specialField?.type === 'text' && (
                <Input value={value} onChange={val => onValueChosen(question, val, true)} />
              )}

              {specialField?.type === 'textarea' && (
                <textarea className="w-full" name={`${questionKey}[extra]`} rows="4">
                  {value}
                </textarea>
              )}
            </label>
          );
        }
        let checked = false;
        if (Object.keys(answers).includes(questionKey) && isArray(answers[questionKey])) {
          checked = answers[questionKey].includes(key);
        }
        return (
          <div className="d-flex justify-content-center mb-4 w-100">
            <CheckBox
              name={`${question?.key}[]` + ` :label=${key}`}
              value={key}
              center={Object.keys(question?.answers).length <= 1}
              className="mb-0 align-items-center"
              active={checked}
              onChange={() => onValueChosen(question, key)}
              label={question?.answers[key]}
            />
          </div>
        );
      })}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {liveRoute} from '@/_core/util/util';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';
import {Section} from '../../layout/Section';

interface _TOSConsentProps {
  newsLetter: boolean;
  setNewsLetter: (value: boolean) => void;
  isTeamInvite: boolean;
  isInvite: boolean;
}

const TOSConsent: React.FC<_TOSConsentProps> = ({newsLetter, setNewsLetter, isTeamInvite, isInvite}) => {
  function inviteLegalComponent() {
    if (isTeamInvite) {
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans('register.invite_legal_consent'),
          }}
        />
      );
    }
    if (isInvite) {
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans('register.invite_legal_consent_short'),
          }}
        />
      );
    }

    return null;
  }

  return (
    <Section gap={5}>
      <Typography>
        {trans('register.tos_link_content_1')}
        <TextLink
          href={liveRoute('terms-and-conditions')}
          label={trans('register.tos_form_tos_link')}
          target="_blank"
        />{' '}
        {trans('register.tos_link_content_2')}{' '}
        <TextLink href={liveRoute('privacy-policy')} label={trans('register.tos_form_privacy_link')} target="_blank" />{' '}
        {trans('register.tos_link_content_3')}
      </Typography>

      {inviteLegalComponent()}

      <CheckBox label={trans('register.subscribe_to_newsletter')} active={newsLetter} onChange={setNewsLetter} />
    </Section>
  );
};

export default TOSConsent;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import HtmlWrapper from '@modules/Core/components/wrappers/HtmlWrapper';
import {useSurveyPath} from '@modules/SurveyEngine/hooks/surveyPath';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _SurveyHtmlWrapperProps {
  type: string;
  params: Record<string, string>;
}

export const SurveyHtmlWrapper: React.FC<_SurveyHtmlWrapperProps> = ({type, params}) => {
  const path = useSurveyPath(type, params);
  const {currentLanguage} = useLanguage();

  return <>{path && <HtmlWrapper key={currentLanguage + path} type={path} />}</>;
};

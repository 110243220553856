// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {logger} from '@modules/Core/util/Logger';
import {CustomSurveyAnswer} from '@modules/CustomSurveys/components/answer/CustomSurveyAnswer';
import {CustomSurveyNotAvailablePage} from '@modules/CustomSurveys/components/answer/CustomSurveyNotAvailablePage';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _Props {
  survey: _CustomSurvey;
  profileId?: string;
}

export const AnswerCustomSurveyItem: React.FC<_Props> = ({profileId, survey}) => {
  logger.debug('AnswerCustomSurvey', {survey, profileId});
  const {currentLanguage} = useLanguage();

  if (!survey) {
    return <CustomSurveyNotAvailablePage profileId={profileId} />;
  }

  return <CustomSurveyAnswer profileId={profileId} survey={survey} language={currentLanguage} />;
};

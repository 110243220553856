// @ts-nocheck
/* eslint-disable */

import {_Feeling} from '@modules/ContentEngine/components/items/profile/dashboard/HowAreYouItem';

export function getUpgradeButtonId(origin: string = 'profile'): string {
  return getPlatformElementId(origin, 'upgrade-button');
}

export function getHowAreYouItemId(feeling: _Feeling): string {
  return `profile-dashboard:how-are-you:${feeling}`;
}

export function getSidebarItemId(value: string): string {
  return `sidebar:${value}`;
}

export function getTutorialTriggerId(service: string): string {
  return `tutorial-trigger:${service}`;
}

// Looks smth like: sidebar:design-system:button, profile-dashboard:how-are-you:good
export function getPlatformElementId(origin: string, element: string, ...ids: string[]): string {
  if (!ids.length) return `${origin}:${element}`;
  return `${origin}:${ids.join('-')}:${element}`;
}

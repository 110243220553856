// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {BaseContentEngineItem} from '@modules/ContentEngine/components/base/BaseContentEngineItem';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const ContentEngineItemStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  return (
    <BaseStep options={options}>
      <BaseContentEngineItem item={options.item} />
    </BaseStep>
  );
};

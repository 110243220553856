// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_Profile} from '@modules/Profile/types/profile.model';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';

interface _TeamMembersMiniItemProps {
  profiles: Record<string, _Profile>;
  limit?: number;
  avatarSize?: 'sm' | 'md' | 'lg';
}

export const TeamMembersminiItem: React.FC<_TeamMembersMiniItemProps> = ({avatarSize, limit = 4, profiles}) => {
  return (
    <TeamProfileAvatarList
      avatarSize={avatarSize}
      limit={limit}
      profiles={Object.keys(profiles)}
      teamProfiles={profiles}
    />
  );
};

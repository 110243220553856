// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Goals} from '@modules/Core/components/base/Goals';
import {Section} from '@modules/Core/components/layout/Section';
import {ProfileExerciseRoundCard} from '@modules/Profile/components/exercises/round/ProfileExerciseRoundCard';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {_ProfileExerciseCE, _RoundData, EXERCISE_STATUS_DONE} from '@modules/Profile/types/exercises.model';
import {createExerciseData} from '@modules/Profile/util/exercisesUtil';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  profileExercises: _ProfileExerciseCE;
}

export const ProfileServiceExercisesCard: React.FC<_Props> = ({service, profileExercises}) => {
  const exercises = useExercises();

  // Loaded from exercisesJSON
  const [rounds, setRounds] = useState<_RoundData[]>([]);
  const [allDone, setAllDone] = useState(false);

  const {currentRound} = profileExercises || {};

  useEffect(() => {
    if (!service) {
      return;
    }
    setRounds(createExerciseData(service, exercises?.[service], profileExercises)?.rounds);
  }, [exercises, service, profileExercises]);

  useEffect(() => {
    // If all done, set allDone to true
    if (profileExercises?.rounds?.length) {
      setAllDone(profileExercises?.rounds?.every(round => round?.status === EXERCISE_STATUS_DONE));
    }
  }, [profileExercises]);

  if (!rounds?.length || !profileExercises?.rounds?.[currentRound]) {
    return null;
  }
  return (
    <Section
      title={trans(PROFILE_SERVICES_TITLES[service])}
      titleIcon={getServiceIcon(service)}
      titlePrefix={allDone ? <Goals level="final" hideProgressBar /> : null}
    >
      <div className="flex gap-2">
        {(rounds || []).map((round, index) => (
          <div className="flex w-full">
            <ProfileExerciseRoundCard
              key={`${round.round}_service_${service}`}
              round={round}
              profileData={profileExercises?.rounds?.[index] ?? null}
              service={service}
              currentRound={currentRound}
              roundIndex={index}
            />
          </div>
        ))}
      </div>
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {_Tutorial, _TutorialStep, _TutorialType} from '@modules/Tutorials/types/tutorial.model';
import {setTutorialDoneAction} from '@modules/Tutorials/util/tutorialActionsUtil';
import {prepareTutorialStep} from '@modules/Tutorials/util/tutorialStepUtil';

export const useTutorialFlow = (
  tutorial?: _Tutorial | null,
  onDone?: () => void,
  withTimeout = false
): {
  currentStep: _TutorialStep | null;
  totalSteps: number;
  onNext: () => void;
  onPrevious: () => void;
  onFinish: () => void;
  finished: boolean;
  type?: 'global' | 'dialog';
} => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null);
  const [steps, setSteps] = useState<_TutorialStep[] | null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<_TutorialStep | null>(null);
  const lastLoadedTutorialName = useRef<string | null>(null);

  // Prepares actions for current step (like previous, next etc...).
  useEffect(() => {
    if (currentStepIndex === null || !steps?.[currentStepIndex]) {
      setCurrentStep(null);
      return;
    }

    const htmlElement = document.getElementById(steps[currentStepIndex].elementId);
    logger.debug('Element Position:', htmlElement?.getBoundingClientRect());
    setCurrentStep(
      prepareTutorialStep(steps[currentStepIndex], currentStepIndex, steps.length, onNext, onPrevious, onFinish)
    );
  }, [steps, currentStepIndex]);

  useEffect(() => {
    logger.debug('prepareTutorial TEST, VALUES CHANGED!!!!', {
      steps,
      currentStepIndex,
    });
  }, [steps, currentStepIndex, lastLoadedTutorialName.current]);

  // Memoize filterSteps using useCallback to ensure it has current references
  const filterSteps = useCallback(() => {
    const filteredSteps = Object.values(tutorial?.steps ?? {}).filter(step => document.getElementById(step.elementId));
    logger.debug('TutorialFlowContainer prepareTutorial before', {
      filteredSteps,
      tutorial,
      currentStep,
      steps,
      currentStepIndex,
      finished,
      lastLoadedTutorialName: lastLoadedTutorialName.current,
    });

    if (
      tutorial &&
      (tutorial.name !== lastLoadedTutorialName.current ||
        (filteredSteps.length && filteredSteps.length !== steps?.length))
    ) {
      logger.debug('TutorialFlowContainer prepareTutorial in', {
        filteredSteps,
        tutorial,
        currentStep,
        steps,
        currentStepIndex,
        finished,
        lastLoadedTutorialName: lastLoadedTutorialName.current,
      });

      setSteps(prev => {
        logger.debug('prepareTutorial within setSteps', {prev, filteredSteps});
        return filteredSteps;
      });
      setCurrentStepIndex(0);
      setFinished(false);
      lastLoadedTutorialName.current = tutorial.name;
    }
  }, [tutorial, currentStep, steps, currentStepIndex, finished, lastLoadedTutorialName]);

  // Use useLayoutEffect to run filterSteps and set up the timeout
  useLayoutEffect(() => {
    filterSteps();
    const timeoutId = setTimeout(
      () => {
        filterSteps();
      },
      withTimeout ? 2500 : 0
    );
    return () => clearTimeout(timeoutId); // Clean up the timeout on unmount or dependency change
  }, [filterSteps, withTimeout]);

  const onNext = () => {
    if (currentStepIndex === null) {
      return;
    }
    if (steps && currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const onPrevious = () => {
    if (currentStepIndex === null) {
      return;
    }
    if (steps && currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const onFinish = (): void => {
    // We don't block if request fails
    if (tutorial?.name) {
      void setTutorialDoneAction(tutorial?.name);
      onDone?.();
    }
    setFinished(true);
  };

  logger.debug('TutorialFlowContainer prepareTutorial', {tutorial, currentStep, steps, currentStepIndex, finished});

  return {
    currentStep,
    totalSteps: steps ? steps.length : 0,
    onNext,
    onPrevious,
    onFinish,
    finished,
    type: tutorial?.type ?? ('global' as _TutorialType),
  };
};

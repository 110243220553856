// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {closeDialog, showDialog} from '@modules/Core/hooks/ui/dialog';
import {logger} from '@modules/Core/util/Logger';
import {Flow} from '@modules/FlowEngine/components/Flow';
import {_FlowInput} from '@modules/FlowEngine/types/core.model';

const DIALOG_FLOW_INSTANCE_KEY = 'popup';

export function fireDialogFlow(
  flowName: string,
  onFinish?: (() => void) | null,
  dialogProps: _GlobalDialog = {},
  flowInput: _FlowInput = {}
): void {
  const dialogId = showDialog({
    children: React.createElement(Flow, {
      flowName,
      instanceKey: DIALOG_FLOW_INSTANCE_KEY,
      onFinish: () => {
        closeDialog(dialogId);
        onFinish?.();
      },
      input: flowInput,
    }),
    ...dialogProps,
  });
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ChecklistTaskDoneView} from '@modules/Profile/components/exercises/tasks/checklist/ChecklistTaskDoneView';
import {ChecklistTaskEditView} from '@modules/Profile/components/exercises/tasks/checklist/ChecklistTaskEditView';
import {_ProfileExerciseStatus, _ProfileExerciseTask, _TaskData} from '@modules/Profile/types/exercises.model';
import {BaseTask} from '../BaseTask';

interface _ChecklistTaskProps {
  task: _TaskData;
  onChange?: (val: string | string[]) => void;
  profileAnswer?: _ProfileExerciseTask;
  exerciseStatus?: _ProfileExerciseStatus | null;
  showCorrectAnswer?: boolean;
  mode: 'edit' | 'view';
}

export const ChecklistTask: React.FC<_ChecklistTaskProps> = ({
  showCorrectAnswer,
  task,
  onChange,
  profileAnswer,
  exerciseStatus,
  mode,
}) => {
  const [values, setValues] = useState<string[]>([]);
  /**
   * If true, user can add new options
   */
  const [allowCustomValues, setAllowCustomValues] = useState(false);

  useEffect(() => {
    setAllowCustomValues(!!task?.allow_custom_values);
  }, [task]);

  useEffect(() => {
    const options = profileAnswer?.data?.options;

    if (!options) {
      setValues([]);
      return;
    }

    setValues(options);
  }, [profileAnswer]);

  if (!task) {
    return null;
  }

  const onChangeValues = newValues => {
    setValues(newValues);
    onChange({
      options: newValues,
    });
  };

  return (
    <BaseTask
      mode={mode}
      exerciseStatus={exerciseStatus}
      doneView={<ChecklistTaskDoneView showCorrectAnswer={showCorrectAnswer} values={values} />}
      editView={
        <ChecklistTaskEditView allowCustomValues={allowCustomValues} values={values} onChange={onChangeValues} />
      }
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Card} from '@modules/Core/components/base/cards/Card';
import {logger} from '@modules/Core/util/Logger';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';

interface _DataEntry {
  status: string;
  values: {
    _feedback_results: {
      feedback: string;
    };
  };
  profile: _ShortProfile;
}

interface _ThirdPersonFeedbackQualitativeFeedbackItemProps {
  // TODO @Sherif types
  data: Record<
    string,
    {
      status: string;
      values: _DataEntry[];
    }
  >;
  feedbackId: string | null;
  singleFeedback?: _DataEntry;
}

interface _FeedbackEntry {
  feedback: string;
  profile: _ShortProfile;
}

export const ThirdPersonFeedbackQualitativeFeedbackItem: React.FC<_ThirdPersonFeedbackQualitativeFeedbackItemProps> = ({
  data,
  feedbackId,
  singleFeedback,
}) => {
  const [feedbacks, setFeedbacks] = useState<_FeedbackEntry[]>([]);

  useEffect(() => {
    if ((!feedbackId || !data) && !singleFeedback) {
      setFeedbacks([]);
      return;
    }

    // Showing group feedbacks
    if (!singleFeedback) {
      setFeedbacks(
        (data[feedbackId] ?? [])
          .filter(item => {
            return item?.status === 'DONE' && !!item?.values?._feedback_results?.feedback;
          })
          .map(item => {
            return {
              feedback: item?.values?._feedback_results?.feedback,
              profile: item?.profile,
            };
          })
      );
    } else {
      // Showing single feedback
      setFeedbacks([
        {
          feedback: singleFeedback?.values?._feedback_results?.feedback,
          profile: singleFeedback?.profile,
        },
      ]);
    }
  }, [data, feedbackId, singleFeedback]);

  logger.debug('ThirdPersonFeedbackPersonalityQualitativeFeedbackItem', {
    data: data?.[feedbackId ?? ''],
    feedbackId,
    feedbacks,
  });
  return (
    <div className="flex flex-row w-full gap-3 flex-wrap">
      {feedbacks?.map((feedback, index) => (
        <div className="flex flex-col relative-col-width-5">
          <Card titlePrefix={<AvatarUser image={getProfilePic(null)} />}>{feedback.feedback}</Card>
        </div>
      ))}
    </div>
  );
};

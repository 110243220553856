// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileMotivesGroupResult} from '@modules/Profile/types/service.model';
import {getMotiveGroupImage} from '@modules/Profile/util/profileUtil';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileMotivesGroupCardProps {
  motivesGroup: _ProfileMotivesGroupResult;
  isMain?: boolean;
  mainView?: boolean;
}

export const ProfileMotivesGroupCard: React.FC<_ProfileMotivesGroupCardProps> = ({
  mainView = false,
  isMain = false,
  isTeam = false,
  motivesGroup,
  profileMotives,
  profiles = [],
  teamProfiles = [],
}) => {
  const {value, title, group} = motivesGroup ?? {};

  const tooltips = (profiles || []).reduce(
    (acc, profileId) => ({
      ...acc,
      [profileId]: `${teamProfiles[profileId]?.fullName || ''} ${Math.round(profileMotives.motiveGroups[group][title][profileId])}%`,
    }),
    {} as Record<string, string>
  );

  return (
    <Card>
      <img src={getMotiveGroupImage(title)} className="img-fluid p-5 mx-auto h-[280px]" />
      <Typography variant="h6">
        {isMain && `${trans('motive_structure.main')}: `}
        {trans(`motive_structure.${title}.title`)} ({Math.round(value)}%)
      </Typography>
      {!mainView && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(`motive_structure.${title}.interpretation`),
          }}
        />
      )}
      {profiles.length > 0 && (
        <div className="flex flex-row gap-3">
          <TeamProfileAvatarList profiles={profiles} teamProfiles={teamProfiles} tooltips={tooltips} limit={25} />
        </div>
      )}
      {mainView && !isTeam && (
        <div className="flex flex-col gap-3">
          <Typography bold>{trans(`motive_structure.do_things`)}</Typography>
          <Typography
            dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.handlungsergebniserwartung`)}}
          />
          <Typography bold>{trans(`motive_structure.chance`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.chance`)}} />
          <Typography bold>{trans(`motive_structure.block`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.block`)}} />
        </div>
      )}
      {mainView && isTeam && (
        <div className="flex flex-col gap-3">
          <Typography bold>{trans(`motive_structure_team.do_things`)}</Typography>
          <Typography
            dangerouslySetInnerHTML={{__html: trans(`motive_structure_team.${title}.handlungsergebniserwartung`)}}
          />
          <Typography bold>{trans(`motive_structure_team.chance`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure_team.${title}.chance`)}} />
          <Typography bold>{trans(`motive_structure_team.block`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure_team.${title}.block`)}} />
        </div>
      )}
    </Card>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Action} from '@modules/Core/components/layout/Action';
import {_UIAction} from '@modules/Core/types/pageLayout.model';

interface _Props {
  actions: _UIAction[];
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
}

export const ActionsItem: React.FC<_Props> = ({actions, justify = 'center'}) => {
  return (
    <div className={twMerge('flex flex-row', 'w-full', `justify-${justify}`)}>
      {actions.map(action => (
        <div key={action.uuid} className="ml-2">
          <Action {...action} />
        </div>
      ))}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {ProfileDevModeSection} from '@modules/Dev/components/ProfileDevModeSection';
import {_ExerciseData} from '@modules/Profile/types/exercises.model';
import {serviceHasDynamicExercises} from '@modules/Profile/util/exercisesUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileDevModeSectionProps {
  isDevMode: boolean;
  setIsDevMode: (isDevMode: boolean) => void;
  devModeExerciseIndex?: number | null;
  setDevModeExerciseIndex?: (index: number | null) => void;
  devModeExerciseName: string | null;
  setDevModeExerciseName?: (name: string) => void;
  availableExercises?: Record<string, _ExerciseData[]> | _ExerciseData[][];
  service?: any;
}

export const ProfileExercisesDevMode: React.FC<_ProfileDevModeSectionProps> = ({
  isDevMode,
  setIsDevMode,
  devModeExerciseIndex,
  setDevModeExerciseIndex,
  devModeExerciseName,
  setDevModeExerciseName,
  availableExercises,
  service,
}) => {
  const isShowingDevMode = isDevMode && availableExercises?.[devModeExerciseName ?? ''];
  return (
    <ProfileDevModeSection isDevMode={isDevMode} setIsDevMode={setIsDevMode}>
      {isShowingDevMode ? <div>Showing dev mode exercises</div> : <div>Showing original exercises</div>}
      <div className="flex flex-col">
        <Select
          value={devModeExerciseName}
          onChange={e => {
            setDevModeExerciseName && setDevModeExerciseName(e.value as string);
            setDevModeExerciseIndex && setDevModeExerciseIndex(null);
          }}
          options={Object.keys(availableExercises ?? {}).map((exercise, index) => ({
            value: exercise,
            label: exercise,
          }))}
        />

        {Boolean(serviceHasDynamicExercises(service) && !!availableExercises?.[devModeExerciseName]) && (
          <Select
            value={devModeExerciseIndex}
            onChange={e => setDevModeExerciseIndex(e.value as number)}
            options={availableExercises[devModeExerciseName]?.map((exercise, index) => ({
              value: index,
              label: trans(exercise?.intro?.title),
            }))}
          />
        )}
      </div>
    </ProfileDevModeSection>
  );
};

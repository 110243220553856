// @ts-nocheck
/* eslint-disable */

import {Axios} from 'axios';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';

const loaderInterceptor = (axiosInstance: Axios): void => {
  axiosInstance.interceptors.request.use((config): any => {
    logger.debug('[loaderInterceptor] request', config.url, config.data);
    if (config.headers['X-Show-Loader']) {
      dispatchReactEvent('loader-started', {url: config.url});
    }
    return config;
  });
  axiosInstance.interceptors.response.use(
    response => {
      if (response.config.headers['X-Show-Loader']) {
        dispatchReactEvent('loader-stopped', {url: response.config.url});
      }
      return response;
    },
    async error => {
      if (error.config?.headers['X-Show-Loader']) {
        dispatchReactEvent('loader-stopped', {url: error.config.url});
      }
      return await Promise.reject(error);
    }
  );
};
export default loaderInterceptor;

// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {reloadProfileStateWithPromise} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {FLOW_ACTION, FLOW_ACTION_UNAUTHORIZED, FLOWS_LOAD, FLOWS_LOAD_UNAUTHORIZED} from './paths';

const flowsCache = new Map<string, any>();

async function loadFlows(authorized: boolean): Promise<any> {
  const institutionId = currentInstitution()?.id;

  if (!institutionId || !flowsCache.has(institutionId)) {
    const data = await httpClient.get(authorized ? FLOWS_LOAD : FLOWS_LOAD_UNAUTHORIZED);
    if (institutionId) {
      flowsCache.set(institutionId, data);
    } else {
      return data;
    }
  }
  return flowsCache.get(institutionId)!;
}

function clearFlowsCache() {
  flowsCache.clear();
}

async function performFlowAction(
  body: any,
  {
    refetchProfile = false,
    loadingIndicator = false,
    unauthorized = false,
  }: {refetchProfile?: boolean; loadingIndicator?: boolean; unauthorized?: boolean} = {}
): Promise<AxiosResponse<_FlowActionResult>> {
  if (loadingIndicator) {
    // dispatchReactEvent('navigate.start', body);
  }
  const result = await httpClient.post(
    `${unauthorized ? FLOW_ACTION_UNAUTHORIZED : FLOW_ACTION}?method=${body.method}`,
    body,

    loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {}
  );
  if (refetchProfile && !unauthorized) {
    logger.debug('[performFlowAction] refetchProfile', body.method);
    await reloadProfileStateWithPromise();
  }
  return result;
}

export {performFlowAction, loadFlows, clearFlowsCache};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileLevelProgressionItem} from '@modules/ContentEngine/components/items/gamification/ProfileLevelProgressionItem';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileGamification} from '@modules/Core/types/gamification.model';
import {getAchievementImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  title: string;
  titleIcon?: string;
  image: string;
  xpMetrics?: _ProfileGamification | null | undefined;
  description?: string | null | undefined;
  type: 'achievement' | 'level';
  progressPosition?: 'horizontal' | 'vertical' | 'hidden';
  progression: number;
}

export const GamificationAlertCard: React.FC<_Props> = ({
  progression,
  progressPosition = 'horizontal',
  type,
  title,
  titleIcon,
  image,
  xpMetrics,
  description,
}) => {
  const showProgress = xpMetrics !== null && xpMetrics !== undefined;
  const showDescription = description !== null && description !== undefined;

  const showProgressHorizontal = progressPosition === 'horizontal' && showProgress;
  const showProgressVertical = progressPosition === 'vertical' && showProgress;

  const xpTillNextLevel = xpMetrics?.totalLevelExperienceGoal - xpMetrics?.totalLevelExperiencePoints;
  logger.debug('GamificationAlertCard', {type, xpMetrics});
  return (
    <Card titleVariant="body" padding={2} height="" className="py-2 px-3">
      <div className="flex flex-row items-center w-full justify-between">
        {trans('gamification.metric_progress', {metric: title})}
        <div className="flex flex-row items-center gap-0">
          {!!xpTillNextLevel && (
            <Typography>{trans('gamification.points_needed', {points: xpTillNextLevel})}</Typography>
          )}
          {!!titleIcon && <img src={getAchievementImage(titleIcon, 'png')} className="w-3" />}
        </div>
      </div>
      <div className="flex flex-row w-full items-center gap-1">
        {type === 'level' && !!image && <img src={image} className="w-5" />}

        {showProgressHorizontal && (
          <ProfileLevelProgressionItem progression={progression} type={type} minified gamification={xpMetrics} />
        )}

        {showDescription && <Typography variant="body">{description}</Typography>}
      </div>
      {showProgressVertical && (
        <ProfileLevelProgressionItem progression={progression} type={type} minified gamification={xpMetrics} />
      )}
    </Card>
  );
};

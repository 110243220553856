// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {SoftFactTabBadge} from '@modules/Core/components/base/softfacts/tab/SoftFactTabBadge';
import {_ServiceStatusXPData} from '@modules/Profile/types/modules.model';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';

interface _HeaderProps {
  badge: string;
  progress?: number;
  metric: _ProfileTrackingStatisticEntry;
  xpData: _ServiceStatusXPData;
}

export const SoftfactTabHeader: React.FC<_HeaderProps> = ({xpData, metric, badge, progress}) => {
  const changeType = metric?.trend > 0 ? '+' : metric?.trend < 0 ? '-' : 'N';
  const showTotalXP = xpData?.total > 0 && ['start', 'finished', 'locked', 'first', 'first-inner'].includes(badge);
  const xpToShow = badge === 'finished' ? xpData?.gained : xpData?.total;

  return (
    <div
      className={twMerge(
        'flex flex-row items-center w-full',
        (badge === 'progress' && !!metric?.trend) || showTotalXP ? 'justify-between' : 'justify-end'
      )}
    >
      {showTotalXP && <ExperienceCount experience={xpToShow} />}
      {badge === 'progress' ? <StatisticLabelBadge change={metric?.trend} changeType={changeType} /> : null}
      {badge && <SoftFactTabBadge badge={badge} />}
    </div>
  );
};

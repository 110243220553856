// @ts-nocheck
/* eslint-disable */

import {_ShortProfile} from '@modules/Profile/types/profile.model';

export interface _CustomSurvey {
  id: string;
  title: Record<string, string>;
  description: Record<string, string>;
  status: _CustomSurveyStatus;
  created_at: string;
  questions: _CustomSurveyQuestion[];
  createdBy: _ShortProfile;
  pages: Array<{name: string; order: number}>;
  completedCount?: number;
  questionsAnsweredCount?: number;
  surveyAnswersCount?: number;
  canSeeResults?: boolean;
  link?: string;
}

export interface _CustomSurveyQuestion {
  title: string;
  type: string;
  options: any;
  meta: any;
  page: number;
  order: number;
}

export enum _CustomSurveyStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  CLOSED = 'closed',
}

export interface _CustomSurveyQuestionSubmission {
  surveyId: string;
  questionId: string;
  answer: any; // The type might be more specific based on your answer structure
}

export interface _CustomSurveyAnswerSubmission {
  answers: _CustomSurveyQuestionSubmission[];
}

export interface _CustomSurveyQuestionAnswer {
  id: string;
  answer: string | any;
  created_at: string;
  updated_at: string;
  custom_survey_answer_id: string;
  custom_survey_question_id: string;
}

export interface _CustomSurveyAnswer {
  id: string;
  custom_survey_id: string;
  respondent_id: string;
  status: _CustomSurveyAnswerStatus;
  started_at: string | null;
  completed_at: string | null;
  created_at: string;
  updated_at: string;

  // Relationships
  answers?: _CustomSurveyQuestionAnswer[];
}

export enum _CustomSurveyAnswerStatus {
  NOT_STARTED = 'not_started',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
}

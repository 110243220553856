// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {SURVEY, SURVEY_FINALISE, SURVEY_SUBMIT, SURVEY_UPLOAD} from './paths';

// TODO @Sherif types

async function getSurvey({
  type,
  module,
  subModule,
  subSubModule,
  projectId,
  teamId,
  skipIntro,
}: {
  type: string;
  module: string | null;
  subModule: string | null;
  subSubModule: string | null;
  projectId: string | null;
  teamId: string | null;
  skipIntro: boolean | null;
}): Promise<AxiosResponse> {
  let path = `${SURVEY}/${type}`;
  const queryParams = [];

  if (module) {
    path += `/${module}`;
  }

  if (subModule) {
    path += `/${subModule}`;
  }

  if (subSubModule) {
    path += `/${subSubModule}`;
  }

  if (projectId) {
    queryParams.push(`projectId=${projectId}`);
  }

  if (teamId) {
    queryParams.push(`teamId=${teamId}`);
  }

  if (skipIntro) {
    queryParams.push(`skipIntro=${skipIntro}`);
  }

  if (queryParams.length > 0) {
    path += `?${queryParams.join('&')}`;
  }

  return await httpClient.get(path);
}

async function submitSurvey(answer, body) {
  return await httpClient.post(SURVEY_SUBMIT, {
    answer,
    ...body,
  });
}

async function finaliseSurvey(
  body,
  module: string | null,
  subModule: string | null,
  subSubModule: string | null,
  inviteId: string | null
) {
  dispatchReactEvent('navigate.start', body);
  return await httpClient.post(SURVEY_FINALISE, {
    module,
    subModule,
    subSubModule,
    inviteId,
    ...body,
  });
}

async function uploadSurvey(body: any) {
  return await httpClient.post(SURVEY_UPLOAD, body);
}

export {getSurvey, submitSurvey, finaliseSurvey, uploadSurvey};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {ProgressBar} from './ProgressBar';

interface _TeamProgressSlider {
  leftComponent?: React.ReactNode;
  value?: number;
  rightComponent?: React.ReactNode;
}

// TODO @Sherif Team Progress
export const TeamProgressSlider: React.FC<_TeamProgressSlider> = ({
  leftComponent = (
    <>
      {/* <AvatarGroup size="md" badge="+3">
      <>
        <Avatar size="md" initials="UM" indicator="success" />
        <Avatar size="md" initials="LP" indicator="success" />
      </>
    </AvatarGroup> */}
    </>
  ),
  value = 0,
  rightComponent = (
    <>
      {/* <AvatarGroup size="md" badge="+3">
      <Avatar size="md" image={getImage()} indicator="success" />
</AvatarGroup> */}
    </>
  ),
}) => (
  <div className={addCN('relative flex w-full h-4 justify-between items-start shrink-0')} style={{zIndex: 2}}>
    {leftComponent ?? null}

    <div className="absolute-50 w-full z-1">
      <ProgressBar value={value} color="success" size="sm" />
    </div>

    {rightComponent ?? null}
  </div>
);

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {UniqueIdentifier} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

interface _Props {
  id: UniqueIdentifier;
  children: React.ReactNode;
  disabled?: boolean;
  hasDragOverlay?: boolean;
}

export const SortableItem: React.FC<_Props> = ({id, disabled, children, hasDragOverlay}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id,
    disabled,
  });

  const style = {
    transform: hasDragOverlay ? 'unset' : CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const TextStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [text, setText] = useState<string>('');

  const initialValueLoaded = useRef<boolean>(false);
  useEffect(() => {
    if (initialValueLoaded.current) {
      return;
    }
    setText(options?.text || '');
    initialValueLoaded.current = true;
  }, [options]);

  const getValuesCallback = (): Record<string, any> => ({
    text,
  });

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[text]} options={options}>
      <Section
        title={options.title}
        titleVariant={options.titleVaraint || 'h5'}
        image={options.image}
        description={options.description}
        descriptionVariant={options.descriptionVariant}
      >
        <Input onChange={v => setText(v)} value={text} placeholder={options.placeholder} label={options.label} />
      </Section>
    </BaseStep>
  );
};

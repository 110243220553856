// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  _ProfileTrackingMetric,
  _ProfileTrackingMetricValue,
  _ProfileTrackingMetricValueEntry,
  _ProfileTrackingStatisticEntry,
} from '@modules/ContentEngine/types/metrics.model';
import {logger} from '@modules/Core/util/Logger';
import {StatisticSectionContent} from './types/StatisticSectionContent';

export interface _CustomizableSectionItemProps {
  value?: _ProfileTrackingStatisticEntry;
}

export const CustomizableSectionContent: React.FC<_CustomizableSectionItemProps> = ({value}) => {
  const typesComponents: Record<string, React.FC<any>> = {
    // text: 'Text',
    // graph: 'Graph',
    statistic: StatisticSectionContent,
    // content: 'Content',
  };
  const Component = typesComponents.statistic;

  logger.debug('CustomizableSectionContent', {value});
  return <Component value={value} type={value?.type} />;
};

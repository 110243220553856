// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {renderToStaticMarkup} from 'react-dom/server';

export function iconToSvgString(
  IconComponent: React.FC<any>,
  size?: number,
  viewBox?: number,
  color?: string,
  componentSize?: string
): string {
  const svgString = renderToStaticMarkup(<IconComponent size={componentSize ?? 'sm'} color={color} />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDoc.getElementsByTagName('svg')?.[0];
  // Change width, height to 16 and viewBox to 0 0 32 32
  if (size !== undefined && size !== null) {
    svgElement.setAttribute('width', size.toString());
    svgElement.setAttribute('height', size.toString());
  }

  if (viewBox !== undefined && viewBox !== null) {
    svgElement.setAttribute('viewBox', `0 0 ${viewBox} ${viewBox}`);
  }
  return svgElement.outerHTML;
}

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {TeamDevelopmentOverviewTable} from '@modules/Team/components/team_development/overview/TeamDevelopmentOverviewTable';
import {_ProfileTeamDevData} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentProps {
  teamData: _ProfileTeamDevData;
  showPlaceholder?: boolean;
}

const ProfileTeamDevelopmentItem: React.FC<_TeamDevelopmentProps> = ({showPlaceholder, includeHeader, teamData}) => {
  if (!teamData?.team?.id) {
    return null;
  }

  if (showPlaceholder) {
    return (
      <div className="flex flex-col gap-2">
        <Typography bold variant="body">
          {trans('products.team_development.title')}
        </Typography>
        <Typography>{trans('dashboard.team_dev_placeholder')}</Typography>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography bold variant="body">
        {trans('dashboard.team_dev_table_title')}
      </Typography>
      <TeamDevelopmentOverviewTable
        withHeaders={false}
        teamId={teamData.team.id}
        profiles={teamData.profiles}
        progressData={teamData.modules}
      />
    </div>
  );
};
export default ProfileTeamDevelopmentItem;

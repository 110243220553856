// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {getPublicProfileRoute} from '@/_core/util/util';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {_PersonalityDimension, _ProfilePersonalityTraitsResult} from '@modules/Profile/types/service.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {TeamResultLegend} from '@modules/Team/components/team_analysis/common/TeamResultLegend';
import PersonalityGraph from '@modules/Team/components/team_analysis/personality/PersonalityGraph';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamPersonalityTraitsItemProps {
  data: Record<string, _ProfilePersonalityTraitsResult>;
  allProfiles: Record<string, _TeamProfile>;
  domain: _PersonalityDimension;
  average: number;
  profileId: string;
}

const dimensionsExplanationLangProps = {
  openMindedness: 'profile_result.kauai.openMindedness.explanation',
  conscientiousness: 'profile_result.kauai.conscientiousness.explanation',
  extraversion: 'profile_result.kauai.extraversion.explanation',
  agreeableness: 'profile_result.kauai.agreeableness.explanation',
  emotionalStability: 'profile_result.kauai.emotionalStability.explanation',
};

export const TeamPersonalityTraitsItem: React.FC<_TeamPersonalityTraitsItemProps> = ({
  average,
  domain,
  data,
  allProfiles,
  profileId,
}) => {
  return (
    <Section fullWidth>
      <Typography dangerouslySetInnerHTML={{__html: trans(dimensionsExplanationLangProps[domain])}} />
      <div className="mt-8 mb-5">
        <TeamResultLegend />
      </div>
      <PersonalityGraph
        profiles={Object.entries(data || {}).map(([key, value]) => ({
          id: key,
          name: allProfiles[key]?.fullName ?? '',
          url: getProfilePic(allProfiles[key]),
          profileUrl: getPublicProfileRoute(key),
          value: value.domains?.[domain]?.value,
        }))}
        legends={{teamAverage: parseFloat(average)}}
        state={{isPartOfAnalysis: true}}
        labels={[trans('development_result.team_average'), trans('base.you')]}
        currentProfileId={profileId}
      />
    </Section>
  );
};

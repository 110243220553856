// @ts-nocheck
/* eslint-disable */

import React, {ReactNode} from 'react';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import './ExerciseCard.scss';
import {ExercisesCard} from '@modules/Core/components/base/cards/ExercisesCard';
import {IconCheck, IconLocked} from '@modules/Core/components/base/Icons/Icons';
import {RoundView} from '@modules/Profile/components/exercises/cards/RoundView';
import {
  _ProfileExerciseStatus,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';
import {trans} from '@modules/Translations/util/i18n';
import {twMerge} from 'tailwind-merge';

export interface _ExerciseCardAdvancedProps {
  card: {
    currentRound?: number;
    content: Array<{
      title: string;
      description: string;
      header?: string | React.ReactNode;
      translated?: boolean;
      titleAsElement?: boolean;
    }>;
    action: {
      title: string | null;
      trigger: () => void;
    } | null;
    type?: _ProfileExerciseStatus;
    round?: number;
    available: boolean;
    roundProgress?: number;
    totalExperience?: number;
  };
}

export const ExerciseCardAdvanced: React.FC<_ExerciseCardAdvancedProps> = ({card}) => {
  const {totalExperience, content, action, type, round, available} = card;
  const isRound = Boolean(round !== null && round !== undefined);

  const handleClick = (): void => {
    if (action?.trigger) action.trigger();
  };

  const isRoundMissingChallengesAndGoals = isRound && type === EXERCISE_STATUS_LOCKED && round <= card?.currentRound;
  const isLockedExercise = type === EXERCISE_STATUS_LOCKED && !isRound;
  const isDone = type === EXERCISE_STATUS_DONE;
  const isLockedRound = type === EXERCISE_STATUS_LOCKED && isRound;
  const titleContentAsHeader = !!totalExperience && !isRound;
  let titleContent: ReactNode = null;
  if (type === EXERCISE_STATUS_LOCKED || isRoundMissingChallengesAndGoals) titleContent = <IconLocked />;
  if (type === EXERCISE_STATUS_STARTED) titleContent = <ProgressBadge />;
  if (type === EXERCISE_STATUS_DONE)
    titleContent = (
      <div className="exercise-status-badge-circle">
        <IconCheck color="fill-green700" size="sm" />
      </div>
    );

  if (totalExperience) {
    titleContent = (
      <div
        className={twMerge(
          'flex flex-row gap-2 justify-end items-center',
          titleContentAsHeader ? 'w-full' : 'flex-grow'
        )}
      >
        <ExperienceCount experience={totalExperience} />
        {titleContent}
      </div>
    );
  }

  // No membership
  if (!available && type !== EXERCISE_STATUS_DONE) titleContent = <IconLocked />;

  return (
    <ToolTip
      className="w-full"
      txt={
        !available && !['done', 'started'].includes(type as string)
          ? trans('profile_modules.upgrade_to_continue')
          : isLockedExercise
            ? trans('profile_exercises.edit_exercises_tooltip')
            : isRoundMissingChallengesAndGoals
              ? trans('profile_exercises.edit_exercises_round_tooltip')
              : isLockedRound
                ? trans('profile_exercises.locked_round_tooltip')
                : null
      }
    >
      {/* careful: adding "progress" to css will add unintended styling -> only add locked if exists */}
      <div onClick={handleClick} className="h-full w-full">
        {!isRound ? (
          content.map((c, i) => (
            <ExercisesCard
              done={isDone}
              locked={isLockedExercise || isRoundMissingChallengesAndGoals || !available}
              icon={titleContentAsHeader ? null : titleContent}
              header={titleContentAsHeader ? titleContent : c.header && c.header}
              title={c.translated ? c.title : trans(c.title)}
              contents={c.description && <span dangerouslySetInnerHTML={{__html: trans(c.description)}} />}
              action={action}
              titleAsElement={c.titleAsElement}
            />
          ))
        ) : (
          <RoundView
            available={available}
            card={card}
            titleContent={titleContent}
            isRoundMissingChallengesAndGoals={isRoundMissingChallengesAndGoals}
          />
        )}
      </div>
    </ToolTip>
  );
};

const ProgressBadge: React.FC = () => {
  return (
    <div className="flex justify-end items-center" style={{minWidth: 0, height: '100%'}}>
      <div
        className="truncate"
        style={{
          color: '#F9F9F9', // text color for truncate
          fontSize: '0.6875rem', // text size for truncate
          background: '#132E3E',
          padding: 6,
          borderRadius: 8,
        }}
      >
        <span
          style={{
            color: '#F9F9F9',
            fontSize: '0.6875rem',
            whiteSpace: 'pre',
          }}
        >
          {trans('profile_modules.in_progress')}
        </span>
      </div>
    </div>
  );
};

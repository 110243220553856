// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCancel, IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';

interface _Props {
  content: string;
}

export const ProfileResultDisclaimer: React.FC<_Props> = ({content}) => {
  const [show, setShow] = useState<boolean>(true);

  if (!show) {
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-start bg-grey50 text-white px-2 py-1 rounded-sm gap-1">
      <IconInfo color="fill-blue700" />
      <Typography
        className="flex flex-grow text-typography-headings"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <IconButton onClick={() => setShow(false)} color="outline" icon={IconCancel} />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {LottieComponentProps} from 'lottie-react';
import {twMerge} from 'tailwind-merge';
import {addCN} from '@/_core/util/cssHelper';
import {_ButtonProps, Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconCancel, IconExpandMore} from '@modules/Core/components/base/Icons/Icons';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';
import Loader from '../layout/Loader';
import LottieWrapper from '../wrappers/LottieWrapper';
import {GlobalAlert} from './GlobalAlert';

export interface _Dialog {
  id?: string;

  image?: string;
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  lottieData?: {width: string; height: string} & LottieComponentProps;

  title?: string | React.ReactNode;
  titleVariant?: _TypographyVariants;
  titleSuffix?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
  open?: boolean;

  onCancel?: () => void;
  onConfirm?: () => void;

  buttons?: _ButtonProps[] | null;
  hideButtons?: boolean;

  confirmLabel?: string;
  cancelLabel?: string;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  dusk?: string;
  confirmProps?: _ButtonProps;
  cancelProps?: _ButtonProps;

  containerGap?: number;
  titleGap?: number;
  contentGap?: number;

  footerElements?: React.ReactNode;
  footerElementsSuffix?: React.ReactNode;

  justifyContent?: 'start' | 'end' | 'center';
  justifyButtons?: 'start' | 'end' | 'center';
  justifyTitle?: 'start' | 'end' | 'center';

  forceScrollDown?: boolean;
  toolbarElement?: React.ReactNode;
  fixedHeight?: boolean;
  stickyHeader?: boolean;
  footerVerticalAlign?: 'start' | 'end' | 'center';
}

export const Dialog: React.FC<_Dialog> = props => {
  const {showCloseButton, onClose, size = 'md', open, confirmProps = {}} = props;

  const opacityTransition = 'transition ease-in-out duration-300';
  const [opacity, setOpacity] = useState<string>(`opacity-0 ${opacityTransition}`);

  useEffect(() => {
    if (open) {
      setOpacity(`opacity-100 ${opacityTransition}`);
    } else {
      setOpacity(`opacity-0 ${opacityTransition}`);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const sizeMap = {
    sm: {
      height: 'max-h-[80vh]',
      width: 'w-[400px]',
      fixedHeight: 'h-[654px]',
    },
    md: {height: 'max-h-[90vh]', width: 'w-[744px]', fixedHeight: 'h-[800px]'},
    lg: {
      height: 'max-h-[90vh]',
      width: 'w-[1000px]',
      fixedHeight: 'h-[878px]',
    },
  };
  const {width} = sizeMap[size] || {};

  logger.debug('Dialog sheriftest', {size, width});
  const height = props.fixedHeight ? sizeMap[size]?.fixedHeight : sizeMap[size]?.height;

  // @Jonny: FYI I added the top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 classes to center the dialog
  // Feel free to remove it and do it your way
  return (
    <div className={addCN('fixed top-[0px] left-[0px] z-[1000] w-full h-full', opacity)}>
      <Background />

      <div className={addCN('absolute-50 rounded-sm bg-white500 justify-center flex', width)}>
        <DialogContent
          {...props}
          height={height}
          closeButton={showCloseButton && onClose && <IconButton onClick={onClose} icon={IconCancel} size="lg" />}
        />
      </div>
      <div
        className={addCN('absolute rounded-sm bg-white500 justify-center flex', width)}
        style={{
          left: '50%',
          bottom: 0,
          transform: 'translate(-100%, 0)',
        }}
      >
        <div className="m-auto">
          <GlobalAlert />
        </div>
      </div>
    </div>
  );
};

const Background: React.FC = () => <div className="w-full h-full bg-prussian500 opacity-80" />;

interface _DialogContentProps extends _Dialog {
  height: string;
  closeButton?: React.ReactNode;
}

const DialogContent: React.FC<_DialogContentProps> = ({
  height,
  image,
  imageProps,
  lottieData: {width: lottieWidth, height: lottieHeight, ...lottieProps} = {},
  title,
  titleVariant = 'h6',
  buttons,
  hideButtons,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  children,
  confirmProps: {width: confirmWidth, ...confirmProps} = {},
  cancelProps: {width: cancelWidth, ...cancelProps} = {},
  containerGap = 5,
  titleGap = 3,
  contentGap = 3,
  footerElements = null,
  footerElementsSuffix = null,
  justifyContent = null,
  justifyButtons = null,
  closeButton,
  forceScrollDown = false,
  titleSuffix,
  toolbarElement,
  justifyTitle = 'between',
  footerVerticalAlign = 'end',
}) => {
  const showButtons =
    (!hideButtons && buttons?.length) ?? onCancel ?? onConfirm ?? footerElements ?? footerElementsSuffix;
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollRequired, setScrollRequired] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLDivElement; // Explicitly type the event target as an HTMLDivElement
    const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight;
    if (bottom) {
      setScrollRequired(false);
    }
  };

  const scrollToBottom = (): void => {
    const content = contentRef.current;
    if (content) {
      content.scroll({top: content.scrollHeight, behavior: 'smooth'});
    }
  };

  const getJustify = (inputJustify: string | undefined | null, type: 'justifyButtons' | 'justifyContent') => {
    if (inputJustify) {
      return inputJustify;
    }

    if (image) {
      return 'center';
    }

    // As per design system definition
    if (!closeButton && (buttons?.length ?? 0) < 2) {
      return 'center';
    }

    return type === 'justifyButtons' ? 'end' : 'start';
  };

  const justifyButtonsCalculated = getJustify(justifyButtons, 'justifyButtons');
  const justifyContentCalculated = getJustify(justifyContent, 'justifyContent');
  return (
    <Loader>
      <div
        className={addCN(`relative flex flex-col gap-${containerGap} w-full`, height, 'p-5')}
        onScroll={handleScroll}
        ref={contentRef}
      >
        {!!toolbarElement && <div className="absolute top-[16px] left-[16px]">{toolbarElement}</div>}
        {closeButton && <div className={twMerge('absolute top-[16px] right-[16px]')}>{closeButton}</div>}
        {image && (
          <div className={addCN(`flex flex-col w-full items-${justifyContent}`, closeButton ? 'mt-5' : 'mt-2')}>
            <img src={image} {...imageProps} alt="title" />
          </div>
        )}
        {title && (
          <div className={twMerge('flex flex-row w-full', ` justify-${justifyTitle}`, closeButton ? 'mt-5' : 'mt-2')}>
            <div className={twMerge('flex min-h-[40px] items-center')}>
              <Typography
                variant={titleVariant}
                className={twMerge(justifyContentCalculated === 'center' ? 'text-center' : '')}
              >
                {title}
              </Typography>
            </div>
            {titleSuffix && titleSuffix}
          </div>
        )}
        {children && (
          <div
            className={addCN(
              `flex flex-col gap-${titleGap} w-full flex-grow items-${justifyContentCalculated} h-[80%] justify-between`,
              'overflow-y-auto',
              'overflow-x-hidden'
            )}
          >
            {Boolean(children ?? Object.keys(lottieProps).length > 0) && (
              <div
                className={twMerge(
                  `flex flex-col gap-${contentGap} w-full flex-grow`,
                  twMerge(justifyContentCalculated === 'center' ? 'text-center' : '')
                )}
              >
                {Object.keys(lottieProps).length > 0 && (
                  <div className="flex flex-col gap-5 w-full items-center justify-center flex-grow">
                    <div style={{width: lottieWidth, height: lottieHeight}}>
                      <LottieWrapper {...lottieProps} />
                    </div>
                    <div className="flex flex-col gap-2 w-full items-center">
                      <Typography variant="h5">{lottieProps.title}</Typography>

                      <div className="flex flex-row items-center relative-col-width-4 justify-center">
                        <Typography breakLines breakWords className="text-center">
                          {lottieProps.description}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
                {children}
              </div>
            )}
          </div>
        )}
        {scrollRequired && (
          <Button variant="light" onClick={scrollToBottom} icon={IconExpandMore}>
            {trans('base.scroll_down')}
          </Button>
        )}
        {!scrollRequired && showButtons && (
          <div
            className={twMerge(
              `flex-grow flex flex-row w-full justify-${justifyButtonsCalculated} gap-3`,
              ` items-${footerVerticalAlign}`
            )}
          >
            {footerElements && <div className="flex-grow">{footerElements}</div>}
            {onCancel && (
              <div className={cancelWidth ?? 'relative-col-width-2'}>
                <Button
                  label={cancelLabel ?? trans('base.cancel')}
                  variant="secondary"
                  onClick={onCancel}
                  {...cancelProps}
                />
              </div>
            )}

            {onConfirm && (
              <div className={confirmWidth ?? 'relative-col-width-2'}>
                <Button label={confirmLabel ?? trans('base.confirm')} onClick={onConfirm} {...confirmProps} />
              </div>
            )}

            {buttons?.length
              ? buttons.map(({width, label, onClick, ...buttonProps}) => (
                  <div className={width ?? 'relative-col-width-2'}>
                    <Button label={label} onClick={onClick} {...buttonProps} />
                  </div>
                ))
              : null}
            {footerElementsSuffix && <div className="flex-grow">{footerElementsSuffix}</div>}
          </div>
        )}
      </div>
    </Loader>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {formatDate} from '@modules/Core/util/dates';
import {formatMoney} from '@modules/Core/util/locale';
import {hasLegacyMembership} from '@modules/Core/util/subscriptionUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Subscription, _SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {
  getToBeActivatedSubscription,
  isFreePlan,
  isSubscriptionToBeCancelled,
} from '@modules/Profile/util/membershipsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _PaymentPlanHeaderPriceProps {
  institution: _Institution | null;
  currentSubscription: any;
  profile: _Profile | null;
  plan: _PaymentPlan;
  isCurrentPlan: boolean;
}

export const PaymentPlanHeaderPrice: React.FC<_PaymentPlanHeaderPriceProps> = ({
  plan,
  currentSubscription,
  profile,
  institution,
  isCurrentPlan,
}) => {
  if (!plan || hasLegacyMembership(institution) || !isCurrentPlan || isFreePlan(plan.id)) {
    return null;
  }

  const billingInterval = currentSubscription?.type as _SubscriptionBillingInterval;

  return (
    <div className="flex flex-col justify-center items-start">
      <Typography variant="caption">
        <div className="flex flex-col items-start justify-end">
          <b>
            {formatMoney(plan?.price?.[billingInterval] ?? 0)}/{trans('subscription.types.monthly_abbreviated')}
          </b>
          <small>
            {trans(`subscription.types.${billingInterval}_price`)}, {trans('subscription.types.notice_period')}
          </small>
          <SubscriptionCancelDate
            isCurrentPlan={isCurrentPlan}
            currentSubscription={currentSubscription}
            profile={profile}
          />
        </div>
      </Typography>
    </div>
  );
};

interface _SubscriptionCancelDateProps {
  isCurrentPlan: boolean;
  currentSubscription: _Subscription | null;
  profile: _Profile | null;
}

const SubscriptionCancelDate: React.FC<_SubscriptionCancelDateProps> = ({
  isCurrentPlan,
  currentSubscription,
  profile,
}) => {
  if (!isCurrentPlan) {
    return null;
  }

  const isToBeCancelled = isSubscriptionToBeCancelled(currentSubscription);
  const toBeActivatedSubscription = getToBeActivatedSubscription(profile);
  const isTrial = currentSubscription?.pivot?.is_trial;

  const date = currentSubscription?.pivot?.is_trial
    ? currentSubscription?.pivot?.expires_at
    : currentSubscription?.pivot?.date;

  if (
    toBeActivatedSubscription &&
    toBeActivatedSubscription?.plan_id === currentSubscription?.plan_id &&
    isToBeCancelled
  ) {
    return (
      <div className="flex flex-col align-items-center">
        <div className="to_be_cancelled_plan">
          {trans('subscription.to_be_changed_plan', {
            date: formatDate(new Date(currentSubscription?.pivot?.date)),
            oldBillingInterval: trans(`subscription.types.${currentSubscription?.type}_short`),
            newBillingInterval: trans(`subscription.types.${toBeActivatedSubscription?.type}_short`),
          })}
        </div>
      </div>
    );
  }

  if (isToBeCancelled) {
    return (
      <div className="flex flex-col items-center">
        <div>
          {trans(isTrial ? 'subscription.to_be_cancelled_trial' : 'subscription.to_be_cancelled_plan', {
            date: formatDate(new Date(date ?? '')),
          })}
        </div>
      </div>
    );
  }
};

// @ts-nocheck
/* eslint-disable */

export class AbstractValidator {
  options: any;

  constructor(options: any) {
    this.options = {
      value: options,
    };
  }

  validate(value: any): boolean {
    throw new TypeError('Must override validate method');
  }
}

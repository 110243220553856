// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';

interface _Props {
  label: string;
  value: number;
  icon: string;
}

export const TeamCommunicationChannelsRankingItem: React.FC<_Props> = ({label, value, icon}) => {
  logger.debug('TeamCommunicationChannelsRankingItem', {label, value, icon});
  return (
    <div className="w-full flex flex-row gap-2 items-center">
      <div className="relative-col-width-2">
        <Typography>{label}</Typography>
      </div>

      <div className="relative-col-width-10">
        <ProgressBar value={value} color="dark" />
      </div>
    </div>
  );
};

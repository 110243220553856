// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_AppState} from '@modules/State/types/appState.model';
import {_Flow} from '../types/flow.model';

/**
 * This hook returns the flow object from redux by name.
 * @param flowName
 */
export function useFlowByName(flowName: string, instanceKey: string): _Flow | null {
  return useSelectState((state: _AppState) => state.flow[`${flowName}_${instanceKey}`]);
}

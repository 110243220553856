// @ts-nocheck
/* eslint-disable */

import {
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_WAY_OF_WORKING,
} from '@modules/Core/config/services';

export const moduleDescriptionLangProps: Record<string, string> = {
  collaboration: 'profile_modules.modules.collaboration.description',
  leadership: 'profile_modules.modules.leadership.description',
  mindset: 'profile_modules.modules.mindset.description',
};

export const moduleTitleLangProps: Record<string, string> = {
  collaboration: 'profile_modules.modules.collaboration.title',
  leadership: 'profile_modules.modules.leadership.title',
  mindset: 'profile_modules.modules.mindset.title',
};

export const productTitlesLangKeys = {
  [`${SERVICE_PROFILE_BASE_AND_ANALYSIS}`]: 'organisation.team_analysis_parts_title.team_profile_base_and_analysis',
  [`${SERVICE_PROFILE_ROLES}`]: 'organisation.team_analysis_parts_title.team_roles',
  [`${SERVICE_PROFILE_VALUES}`]: 'organisation.team_analysis_parts_title.team_values',
  [`${SERVICE_PROFILE_WAY_OF_WORKING}`]: 'organisation.team_analysis_parts_title.team_way_of_working',
  [`${SERVICE_PROFILE_MOTIVE_STRUCTURE}`]: 'organisation.team_analysis_parts_title.motive_structure',
};

export const personalityTraitsLangProps: Record<string, string> = {
  agreeableness: 'profile_result.kauai.agreeableness.title',
  conscientiousness: 'profile_result.kauai.conscientiousness.title',
  extraversion: 'profile_result.kauai.extraversion.title',
  emotionalStability: 'profile_result.kauai.emotionalStability.title',
  openMindedness: 'profile_result.kauai.openMindedness.title',
};

export const personalityTraitsExplanationLangProps: Record<string, string> = {
  agreeableness: 'profile_result.kauai.agreeableness.personal_explanation',
  conscientiousness: 'profile_result.kauai.conscientiousness.personal_explanation',
  extraversion: 'profile_result.kauai.extraversion.personal_explanation',
  emotionalStability: 'profile_result.kauai.emotionalStability.personal_explanation',
  openMindedness: 'profile_result.kauai.openMindedness.personal_explanation',
};

export const wayOfWorkingLangProps: Record<
  string,
  {
    title: string;
    first_facet_label: string;
    first_facet_tooltip: string;
    first_facet_description: string;
    first_facet_reflection: string;
    second_facet_label: string;
    second_facet_tooltip: string;
    second_facet_description: string;
    second_facet_reflection: string;
    equal_facet_description: string;
    equal_facet_reflection: string;
  }
> = {
  deciding: {
    title: 'way_of_working.deciding.title',
    first_facet_label: 'way_of_working.deciding.first_facet.label',
    first_facet_tooltip: 'way_of_working.deciding.first_facet.description_tooltip',
    first_facet_description: 'way_of_working.deciding.first_facet.description',
    first_facet_reflection: 'way_of_working.deciding.first_facet.reflection_individual',
    second_facet_label: 'way_of_working.deciding.second_facet.label',
    second_facet_tooltip: 'way_of_working.deciding.second_facet.description_tooltip',
    second_facet_description: 'way_of_working.deciding.second_facet.description',
    second_facet_reflection: 'way_of_working.deciding.second_facet.reflection_individual',
    equal_facet_description: 'way_of_working.deciding.equal_facet.description',
    equal_facet_reflection: 'way_of_working.deciding.equal_facet.reflection_individual',
  },
  disagreeing: {
    title: 'way_of_working.disagreeing.title',
    first_facet_label: 'way_of_working.disagreeing.first_facet.label',
    first_facet_tooltip: 'way_of_working.disagreeing.first_facet.description_tooltip',
    first_facet_description: 'way_of_working.disagreeing.first_facet.description',
    first_facet_reflection: 'way_of_working.disagreeing.first_facet.reflection_individual',
    second_facet_label: 'way_of_working.disagreeing.second_facet.label',
    second_facet_tooltip: 'way_of_working.disagreeing.second_facet.description_tooltip',
    second_facet_description: 'way_of_working.disagreeing.second_facet.description',
    second_facet_reflection: 'way_of_working.disagreeing.second_facet.reflection_individual',
    equal_facet_description: 'way_of_working.disagreeing.equal_facet.description',
    equal_facet_reflection: 'way_of_working.disagreeing.equal_facet.reflection_individual',
  },
  leading: {
    title: 'way_of_working.leading.title',
    first_facet_label: 'way_of_working.leading.first_facet.label',
    first_facet_tooltip: 'way_of_working.leading.first_facet.description_tooltip',
    first_facet_description: 'way_of_working.leading.first_facet.description',
    first_facet_reflection: 'way_of_working.leading.first_facet.reflection_individual',
    second_facet_label: 'way_of_working.leading.second_facet.label',
    second_facet_tooltip: 'way_of_working.leading.second_facet.description_tooltip',
    second_facet_description: 'way_of_working.leading.second_facet.description',
    second_facet_reflection: 'way_of_working.leading.second_facet.reflection_individual',
    equal_facet_description: 'way_of_working.leading.equal_facet.description',
    equal_facet_reflection: 'way_of_working.leading.equal_facet.reflection_individual',
  },
  planning: {
    title: 'way_of_working.planning.title',
    first_facet_label: 'way_of_working.planning.first_facet.label',
    first_facet_tooltip: 'way_of_working.planning.first_facet.description_tooltip',
    first_facet_description: 'way_of_working.planning.first_facet.description',
    first_facet_reflection: 'way_of_working.planning.first_facet.reflection_individual',
    second_facet_label: 'way_of_working.planning.second_facet.label',
    second_facet_tooltip: 'way_of_working.planning.second_facet.description_tooltip',
    second_facet_description: 'way_of_working.planning.second_facet.description',
    second_facet_reflection: 'way_of_working.planning.second_facet.reflection_individual',
    equal_facet_description: 'way_of_working.planning.equal_facet.description',
    equal_facet_reflection: 'way_of_working.planning.equal_facet.reflection_individual',
  },
};

export const profileRolesLangProps: Record<
  string,
  {
    title: string;
    description: string;
    text: string;
    easy: string;
    hard: string;
  }
> = {
  critic: {
    title: 'profile_result.roles.critic.title',
    text: 'profile_result.roles.critic.text',
    description: 'profile_result.roles.critic.description',
    easy: 'profile_result.roles.critic.easy',
    hard: 'profile_result.roles.critic.hard',
  },
  social: {
    title: 'profile_result.roles.social.title',
    text: 'profile_result.roles.social.text',
    description: 'profile_result.roles.social.description',
    easy: 'profile_result.roles.social.easy',
    hard: 'profile_result.roles.social.hard',
  },
  listener: {
    title: 'profile_result.roles.listener.title',
    text: 'profile_result.roles.listener.text',
    description: 'profile_result.roles.listener.description',
    easy: 'profile_result.roles.listener.easy',
    hard: 'profile_result.roles.listener.hard',
  },
  evaluator: {
    title: 'profile_result.roles.evaluator.title',
    text: 'profile_result.roles.evaluator.text',
    description: 'profile_result.roles.evaluator.description',
    easy: 'profile_result.roles.evaluator.easy',
    hard: 'profile_result.roles.evaluator.hard',
  },
  coordinator: {
    title: 'profile_result.roles.coordinator.title',
    text: 'profile_result.roles.coordinator.text',
    description: 'profile_result.roles.coordinator.description',
    easy: 'profile_result.roles.coordinator.easy',
    hard: 'profile_result.roles.coordinator.hard',
  },
  problem_solver: {
    title: 'profile_result.roles.problem_solver.title',
    text: 'profile_result.roles.problem_solver.text',
    description: 'profile_result.roles.problem_solver.description',
    easy: 'profile_result.roles.problem_solver.easy',
    hard: 'profile_result.roles.problem_solver.hard',
  },
  team_leader: {
    title: 'profile_result.roles.team_leader.title',
    text: 'profile_result.roles.team_leader.text',
    description: 'profile_result.roles.team_leader.description',
    easy: 'profile_result.roles.team_leader.easy',
    hard: 'profile_result.roles.team_leader.hard',
  },
  task_completer: {
    title: 'profile_result.roles.task_completer.title',
    text: 'profile_result.roles.task_completer.text',
    description: 'profile_result.roles.task_completer.description',
    easy: 'profile_result.roles.task_completer.easy',
    hard: 'profile_result.roles.task_completer.hard',
  },
  task_motivator: {
    title: 'profile_result.roles.task_motivator.title',
    text: 'profile_result.roles.task_motivator.text',
    description: 'profile_result.roles.task_motivator.description',
    easy: 'profile_result.roles.task_motivator.easy',
    hard: 'profile_result.roles.task_motivator.hard',
  },
  teamwork_support: {
    title: 'profile_result.roles.teamwork_support.title',
    text: 'profile_result.roles.teamwork_support.text',
    description: 'profile_result.roles.teamwork_support.description',
    easy: 'profile_result.roles.teamwork_support.easy',
    hard: 'profile_result.roles.teamwork_support.hard',
  },
  passive: {
    title: 'profile_result.roles.passive.title',
    text: 'profile_result.roles.passive.text',
    description: 'profile_result.roles.passive.description',
    easy: 'profile_result.roles.passive.easy',
    hard: 'profile_result.roles.passive.hard',
  },
  attention_seeker: {
    title: 'profile_result.roles.attention_seeker.title',
    text: 'profile_result.roles.attention_seeker.text',
    description: 'profile_result.roles.attention_seeker.description',
    easy: 'profile_result.roles.attention_seeker.easy',
    hard: 'profile_result.roles.attention_seeker.hard',
  },
  power_seeker: {
    title: 'profile_result.roles.power_seeker.title',
    text: 'profile_result.roles.power_seeker.text',
    description: 'profile_result.roles.power_seeker.description',
    easy: 'profile_result.roles.power_seeker.easy',
    hard: 'profile_result.roles.power_seeker.hard',
  },
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileGamification} from '@modules/Core/types/gamification.model';

interface _Props {
  title: string;
  image: string;
  xpMetrics?: _ProfileGamification | null | undefined;
  description?: string | null | undefined;
}

export const XPGainsCard: React.FC<_Props> = ({title, image, description}) => {
  const showDescription = description !== null && description !== undefined;

  return (
    <Card titleVariant="body" padding={2} className="py-2 px-3" gap={1}>
      {title}
      <div className="flex flex-row w-full items-center gap-1">
        {!!image && <img src={image} className="w-4" />}

        {showDescription && <Typography variant="body">{description}</Typography>}
      </div>
    </Card>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_SERVICES_SHARED_RESULTS_TEMPLATES, CE_SERVICES_TEMPLATES} from '@modules/ContentEngine/config/constants';

interface _ProfileResultItemProps {
  profileId: string;
  service: string;
  resultType: 'normal' | 'shared' | '360';
}

export const ProfileResultItem: React.FC<_ProfileResultItemProps> = ({profileId, service, resultType = 'normal'}) => {
  if (resultType === 'normal') {
    return <ContentEngineContainer type={CE_SERVICES_TEMPLATES[service]} />;
  }

  if (resultType === 'shared') {
    return <ContentEngineContainer type={CE_SERVICES_SHARED_RESULTS_TEMPLATES[service]} params={{profileId}} />;
  }

  return null;
};

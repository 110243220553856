// @ts-nocheck
/* eslint-disable */

import {AMBASSADOR_PLAN_ID} from '@modules/Core/config/constants';
import {_PaymentPlan} from '@modules/Core/types/payment.model';
import {
  FREE_PLAN_ID,
  ONE_TIME_PAYMENT_PLAN_ID,
  SUBSCRIPTION_STATUS_TO_BE_ACTIVATED,
  SUBSCRIPTION_STATUS_TO_BE_CANCELLED,
} from '@modules/Profile/config/membershipsConstants';
import {_Subscription} from '@modules/Profile/types/memberships.model';
import {_Profile} from '@modules/Profile/types/profile.model';

export function isSubscriptionToBeCancelled(subscription: _Subscription | null): boolean {
  return subscription?.pivot?.profile_sub_status === SUBSCRIPTION_STATUS_TO_BE_CANCELLED;
}

export function isSubscriptionToBeActivated(subscription?: _Subscription | null): boolean {
  return subscription?.pivot?.profile_sub_status === SUBSCRIPTION_STATUS_TO_BE_ACTIVATED;
}

export function isFreeSubscription(subscription?: _Subscription | null): boolean {
  return subscription?.plan_id === FREE_PLAN_ID;
}

export function isFreePlan(planId: string): boolean {
  return planId === FREE_PLAN_ID;
}

export function isWorkshopsCoachingPlan(plan: _PaymentPlan): boolean {
  return plan.type === 'workshop';
}

export function isDevSubscription(subscription: _Subscription): boolean {
  return subscription?.planId === 'dev-only';
}

export const hasActiveMembership = (profile: _Profile | null, source?: 'profile' | 'institution' | null): boolean => {
  const hasMembership =
    !isFreeSubscription(profile?.subscriptionData?.subscription) || isDevSubscription(profile?.subscriptionData);

  const hasOneTimePayment = hasRunningOneTimePayment(profile);

  // Only membership is relevant for institution source, if no source -> also institution
  if (source === 'institution' || !source) {
    return hasMembership;
  }

  // One time payment considered only for profile source
  return hasMembership || hasOneTimePayment;
};

export function getToBeActivatedSubscription(profile: _Profile | null): _Subscription | null {
  if (!profile?.subscriptionData?.toBeActivatedSubscription) return null;

  return profile?.subscriptionData?.toBeActivatedSubscription;
}

export function getProfileActivePlan(profile: _Profile): string | null {
  const activeSubscription = profile?.subscriptionData?.subscription;

  return activeSubscription?.plan_id;
}

export function hasNonStartedTrial(profile: _Profile): boolean {
  const activeSubscription = profile?.subscriptionData?.subscription;

  return Boolean(activeSubscription?.pivot?.is_trial) && !profile?.subscribed_to_newsletter;
}

export function hasRunningOneTimePayment(profile: _Profile | null): boolean {
  return !!profile?.subscriptionData?.licenses?.length;
}

// TODO types
export function isOneTimePaymentCheckoutSession(checkoutSession: any): boolean {
  return checkoutSession?.planId === ONE_TIME_PAYMENT_PLAN_ID;
}

export function isOneTimePaymentSubscription(planId: string | null): boolean {
  return planId === ONE_TIME_PAYMENT_PLAN_ID;
}

export function isMembershipSubscription(planId: string | null): boolean {
  return planId === AMBASSADOR_PLAN_ID;
}

export function hasDiscount(profile: _Profile | null): boolean {
  return !!profile?.discount;
}

//
// export const actionUnassignSubscription = async ({subscriptionId:, institutionId, profileId}) => {
//   const method = 'subscription.unassign'; // Replace with the actual method name if different
//   const params = {
//     subscriptionId,
//     institutionId,
//     profileId,
//     // Add any other required params here
//   };
//
//   try {
//     const response = await performFlowAction(
//       {
//         method,
//         params,
//       },
//     );
//
//     const responseData = response?.data;
//
//     if (responseData?.status === 'OK') {
//       return responseData;
//     }
//     logger.error('Unassign Subscription Action failed:', responseData?.error);
//     return null;
//   } catch (e) {
//     logger.error('Unassign Subscription Action encountered an error:', e);
//     return null;
//   }
// };

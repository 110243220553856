// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  onSave: () => Promise<void>;
  switchMode: () => void;
  mode: 'view' | 'edit';
  hasChanges: boolean;
  onAdoptChanges: () => void;
}

export const TeamValuesActions: React.FC<_Props> = ({onAdoptChanges, hasChanges, mode, onSave, switchMode}) => {
  return (
    <div className="flex flex-row w-full justify-end">
      {Boolean(hasChanges) && (
        <div className="relative-col-width-2">
          <Button onClick={onAdoptChanges}>{trans('personal_values.adopt_changes')}</Button>
        </div>
      )}
      {Boolean(!hasChanges && mode === 'view') && (
        <div className="relative-col-width-2">
          <Button onClick={switchMode}>{trans('base.edit')}</Button>
        </div>
      )}
      {Boolean(!hasChanges && mode === 'edit') && (
        <div className="relative-col-width-2">
          <Button onClick={onSave}>{trans('base.save')}</Button>
        </div>
      )}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {InstitutionMembersRolesMgmt} from '@modules/Core/components/platform/tables/OrganisationMembersRoleMgmt';
import {_Profile} from '@modules/Profile/types/profile.model';

interface _TeamMembersItemProps {
  profiles: Record<string, _Profile>;
  institutionId: string;
}

export const OrganisationMembersItem: React.FC<_TeamMembersItemProps> = ({institutionId, profiles}) => {
  return <InstitutionMembersRolesMgmt institutionId={institutionId} orgMembers={profiles} />;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileExerciseStatus} from '@modules/Profile/types/exercises.model';

interface _BaseTaskProps {
  exerciseStatus?: _ProfileExerciseStatus | null;
  editView: React.ReactNode;
  doneView: React.ReactNode;
  mode: 'edit' | 'view';
}

export const BaseTask: React.FC<_BaseTaskProps> = ({exerciseStatus, editView, doneView, mode}) => {
  if (mode === 'edit') {
    return editView;
  }

  return doneView;
};

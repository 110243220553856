// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ServiceResultChanges} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

const MAX_ROUND_INDEX = 2;

interface _Props {
  service: string;
  changes: _ServiceResultChanges | null;
  nextRoundReady: boolean;
  currentRound: number;
  view: 'shared' | 'result';
}

export const ProfileResultChanges: React.FC<_Props> = ({view, currentRound, nextRoundReady, service, changes}) => {
  let content;

  if (nextRoundReady && view === 'shared') {
    content = trans('profile_modules.what_to_change_next_round_ready', {round: currentRound + 2});
  } else if (changes?.category_change && changes?.score_change) {
    content = trans(`profile_modules.services.${service}.${changes?.category_change}_${changes?.score_change}`);
  }

  if (!content) {
    return null;
  }

  return (
    <>
      <Typography variant="h6">{trans('profile_modules.what_to_change')}</Typography>
      <Typography>{content}</Typography>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

export interface _ProfileExercise {
  available: boolean;
  service: string;
  round: number;
  position: number;
  exercise_id: string;
  status: _ProfileExerciseStatus;
  created_at: string;
  updated_at: string;
  data: _ProfileExerciseData;
  previous_answers: Record<
    string,
    {
      tasks: _ProfileExerciseTask[];
    }
  >;
}

export type _ProfileExerciseStatus =
  | typeof EXERCISE_STATUS_OPEN
  | typeof EXERCISE_STATUS_DONE
  | typeof EXERCISE_STATUS_STARTED
  | typeof EXERCISE_STATUS_LOCKED;

export interface _ProfileServiceExercises {
  exercises: Record<string, _ProfileExerciseCE>;
}

export interface _ProfileExerciseCE {
  rounds: _ProfileExercisesRound[];
  currentRound: number;
  relevance: number;
}

export interface _ProfileExercisesRound {
  status: _ProfileExerciseStatus;
  available: boolean;
  progress: number;
  exercises: _ProfileExercise[];
}

export interface _ProfileExerciseData {
  tasks: _ProfileExerciseTask[];
  collectedData?: Record<string, any>;
  feedback?: string;
  feedbackStars?: number;
  estimatedTime?: string;
  startedAt?: string;
}

export interface _ProfileExerciseTask {
  data?: Record<string, any> | string | string[];
  type: _TaskType;
  position: number;
  // Can sometimes exist
  correctAnswer?: string;
  options?: string[];
}

export interface _ExerciseDataEntry {
  // Is record for profile_roles and motive_structure
  // for all else is an array
  exercises: _ExerciseData[] | Record<string, _ExerciseData>;
  rounds: _RoundData[];
  round?: _RoundData;
}

export type _ExercisesData = Record<string, _FullExerciseDataEntry>;

export interface _FullExerciseDataEntry {
  // Is record for profile_roles and motive_structure
  // for all else is an array
  exercises: _ExerciseData[][] | Record<string, _ExerciseData[]>;
  rounds: _RoundData[];
  round?: _RoundData;
}

export interface _RoundData {
  title: string;
  description: string;
  exercises: string[];
  round: number;
  progress: number;
  status: _ProfileExerciseStatus;
  available?: boolean;
  totalExperience?: number;
}

export interface _ExerciseData {
  intro: {
    title: string;
    goal: string;
    description: string;
    image?: string;
    allowFullScreen?: boolean;
  };
  tasks: _TaskData[];
  totalExperience: number;
}

export interface _TaskData {
  type: _TaskType;
  title: string;
  description?: string;
  placeholder?: string;
  position: number;
  correctAnswer?: string;
  allow_custom_values?: boolean;
}

export const EXERCISE_STATUS_OPEN = 'open';
export const EXERCISE_STATUS_DONE = 'done';
export const EXERCISE_STATUS_STARTED = 'started';
export const EXERCISE_STATUS_LOCKED = 'locked';

export const EXERCISE_TASK_TYPE_TEXT = 'text';
export const EXERCISE_TASK_TYPE_CHECKLIST = 'checklist';
export type _TaskType = typeof EXERCISE_TASK_TYPE_TEXT | typeof EXERCISE_TASK_TYPE_CHECKLIST;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Indicator} from '@modules/Core/components/base/Indicator';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';

export const TeamResultLegend: React.FC = () => {
  return (
    <div className="w-full flex flex-row gap-3 justify-start items-center">
      <div className="flex flex-row gap-1 items-center">
        <Indicator color="dark" />
        <Typography>{trans('development_result.team_average')}</Typography>
      </div>
      <div className="flex flex-row gap-1 items-center">
        <Indicator color="success" />
        <Typography>{trans('base.you')}</Typography>
      </div>
    </div>
  );
};

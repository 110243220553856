// @ts-nocheck
/* eslint-disable */

import Fuse, {IFuseOptions} from 'fuse.js';

export function fuzzySearch(
  values: any[],
  searchValue: string,
  searchAttributes: string[],
  options?: IFuseOptions<any>,
  scoreThreshold: number = 0.3
): any[] {
  if (!searchValue) {
    return values;
  }
  // Ensure options include scores
  const searchOptions: IFuseOptions<any> = {
    ...options,
    includeScore: true,
    keys: searchAttributes,
    threshold: scoreThreshold,
  };

  // Create an instance of Fuse
  const fuse = new Fuse(values, searchOptions);

  // Perform the search
  return fuse.search(searchValue).map(result => result.item);
}

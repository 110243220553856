// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {LoginEmailInput} from '@modules/Core/components/platform/login/LoginEmailInput';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {resetPasswordLink} from '@modules/Core/services/api/register';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

interface _LoginResetPasswordProps {
  email: string | null;
  setEmail: (email: string | null) => void;
  setLostPassword: (showLost: boolean) => void;
}

export const LoginResetPassword: React.FC<_LoginResetPasswordProps> = ({email, setEmail, setLostPassword}) => {
  const snackbar = useSnackbar();

  async function forgetPassword(): Promise<void> {
    if (!email) {
      // TODO Snackbar
      return;
    }
    try {
      await resetPasswordLink(email);
      setLostPassword(false);
      snackbar.success(trans('passwords.reset-maybe-successful-msg'));
    } catch (e) {
      snackbar.danger(trans('base.unexpected_error'));
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        void forgetPassword();
      }}
      className="hide-required-stars"
    >
      <Section gap={5}>
        <LoginEmailInput email={email} setEmail={setEmail} />

        <div className="flex justify-end">
          <div className="relative-col-width-2">
            <Button type="submit">{trans('passwords.reset-btn')}</Button>
          </div>
        </div>
      </Section>
    </form>
  );
};

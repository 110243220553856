// @ts-nocheck
/* eslint-disable */

import {useState} from 'react';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {getSubscriptionPlans} from '@modules/Core/services/api/payment';
import {_PaymentPlan} from '@modules/Core/types/payment.model';

export function usePaymentPlans(): _PaymentPlan[] {
  const [plans, setPlans] = useState<_PaymentPlan[]>([]);

  const {loadRequest} = useLoader();

  useEffectAsync(async () => {
    void loadRequest(async () => {
      const response = await getSubscriptionPlans();
      if (response?.data) {
        setPlans(response.data as _PaymentPlan[]);
      }
    });
  }, []);

  return plans;
}

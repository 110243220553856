// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {changeSubscriptionStatus} from '@modules/Core/services/api/payment';
import {logger} from '@modules/Core/util/Logger';
import {actionChangePlan} from '@modules/Core/util/payment/paymentActionsUtil';
import {changeCurrentInstitution} from '@modules/Institution/state/InstitutionSlice';
import {_SubscriptionBillingInterval} from '@modules/Profile/types/memberships.model';
import {dispatchAction, refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

export function useChangePlanSub() {
  const snackbar = useSnackbar();
  const dialog = useDialog();

  async function onChangePlan(
    institutionId: string,
    subscriptionId: string,
    type: 'profile' | 'institution',
    billingInterval: _SubscriptionBillingInterval,
    profileIds: string[],
    planId: string,
    callback?: () => void
  ) {
    const response = await changeSubscriptionStatus(subscriptionId, {
      type,
      profileIds,
      planId,
      billingInterval,
    });
    try {
      dialog.show({
        title: trans(response?.data?.title, response?.data?.params),
        children: (
          <Typography
            dangerouslySetInnerHTML={{
              __html: trans(response?.data?.message, response?.data?.params || {}),
            }}
          />
        ),
        onConfirm: async () => {
          await doChangePlan(institutionId, profileIds, subscriptionId, planId, billingInterval);
          callback?.();
        },
        confirmLabel: trans('subscription.change_plan.confirm'),
        confirmProps: {
          width: 'relative-col-width-4',
        },
        cancelLabel: trans('subscription.change_plan.cancel'),
      });
    } catch (e) {
      logger.error(e);
    }
  }

  async function doChangePlan(
    institutionId: string,
    profileIds: string[],
    subscriptionId: string,
    newPlanId: string,
    newBillingInterval: _SubscriptionBillingInterval
  ) {
    const response = await actionChangePlan({
      profileIds,
      subscriptionId,
      institutionId,
      newPlanId,
      newBillingInterval,
    });

    if (response?.status === ACTION_STATUS_SUCCESS) {
      snackbar.success(trans('subscription.plan_change_success'));
      await Promise.all([refreshReduxState(), dispatchAction(changeCurrentInstitution(institutionId))]);
    }
  }

  return onChangePlan;
}

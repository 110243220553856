// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  name?: string;
  children?: React.ReactNode;
  show?: boolean;
  title?: string;
  showLockedPlaceholder?: boolean;
}

export const PublicProfilePermissionedSection: React.FC<_Props> = ({
  name = null,
  showLockedPlaceholder = true,
  title,
  show = false,
  children,
}) => {
  if (!show && !showLockedPlaceholder) return null;
  return (
    <Section fullWidth title={title}>
      {Boolean(show) && children}
      {Boolean(!show && showLockedPlaceholder) && (
        <Typography>
          {trans('profile.privacy_settings.locked', {
            name,
          })}
        </Typography>
      )}
    </Section>
  );
};

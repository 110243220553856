// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {logger} from '@sentry/utils';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {
  updateOrganisationChangesNotifiedProfiles,
  updateTeamPersonalValues,
} from '@modules/Profile/services/profileValuesApi';
import {_TeamValues, _TeamValuesEntry} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

export const useTeamValues = (
  teamId: string,
  data: _TeamValues
): {
  isReady: boolean;
  teamValues: _TeamValuesEntry[];
  setTeamValues: (teamValues: _TeamValuesEntry[]) => void;
  onDelete: (id: string) => void;
  onHighlight: (id: string) => void;
  notifiedProfiles: string[];
  updateNotifiedProfiles: (profileId: string) => Promise<void>;
} => {
  const dialog = useDialog();
  const snackbar = useSnackbar();
  const [teamValues, setTeamValues] = useState<_TeamValuesEntry[]>([]);
  const [isReady, setIsReady] = useState(false);
  const loaded = useRef<boolean>(false);
  const [notifiedProfiles, setNotifiedProfiles] = useState<string[]>([]);
  useEffect(() => {
    if (!data) {
      setTeamValues([]);
      return;
    }

    // if (loaded.current) {
    //   return;
    // }

    setTeamValues(mapDataToValues);
    setIsReady(true);
    loaded.current = true;
  }, [data]);

  const mapDataToValues = () => {
    logger.debug('TEAMVALUES BEFORE MAPPING', {
      data,
      result: Object.values(data.confirmed),
    });
    return Object.values(data.confirmed)
      .sort((a, b) => a.position - b.position)
      .map((v, index) => {
        return {
          position: index,
          ...v,
        };
      });
  };

  const onDelete = (id: string): void => {
    setTeamValues(values => {
      return values?.map(value => {
        if (value.id === id) {
          return {
            ...value,
            deleted: true,
          };
        }
        return value;
      });
    });
  };

  const onHighlight = (id: string) => {
    setTeamValues(values => {
      return values?.map(value => {
        if (value.id === id) {
          return {
            ...value,
            highlighted: !value.highlighted,
          };
        }
        return value;
      });
    });
  };

  async function updateNotifiedProfiles(profileId: string) {
    try {
      const response = await updateOrganisationChangesNotifiedProfiles(teamId, profileId);
      setNotifiedProfiles(response?.data?.values);
    } catch (e) {}
  }

  async function updateTeamValues(successCallbackFn, shouldShowConfirmPopup) {
    // Perform deep comparison
    const originalValues = mapDataToValues();
    const changesExist = !_.isEqual(teamValues, originalValues);

    if (!changesExist) {
      successCallbackFn?.();
      return;
    }
    if (!shouldShowConfirmPopup) {
      await doUpdateTeamValues(successCallbackFn);
      return;
    }
    dialog.show({
      title: trans('personal_values.team_save_confirm'),
      onConfirm: () => {
        void doUpdateTeamValues(successCallbackFn);
      },
    });
  }

  async function doUpdateTeamValues(successCallbackFn) {
    try {
      const selectedItemsObject = {};
      for (let i = 0; i < teamValues.length; i++) {
        selectedItemsObject[teamValues[i].id] = teamValues[i];
      }
      // Set position = index
      Object.values(selectedItemsObject).forEach((value, index) => {
        value.position = index;
      });

      await updateTeamPersonalValues(teamId, selectedItemsObject, data.diff);

      reloadContentEngine();
      successCallbackFn?.();
      snackbar.success(trans('personal_values.save_confirmed.title'));
    } catch (e) {
      logger.error({e});
      snackbar.danger(trans('base.error'));
    }
  }

  return {
    isReady,
    teamValues: teamValues?.filter(value => !value.deleted) ?? [],
    setTeamValues,
    onDelete,
    onHighlight,
    notifiedProfiles,
    updateNotifiedProfiles,
    updateTeamValues,
  };
};

// @ts-nocheck
/* eslint-disable */

// Define the dummy keys for the gamification concept
export const SHARE_RESULT_XP_DUMMY_KEY = 'share_result';
export const SHARE_RESULT_VIEW_XP_DUMMY_KEY = 'share_result_view';

// DIY Survey
export const PUBLISH_CUSTOM_SURVEY_XP_DUMMY_KEY = 'publish_custom_survey';
export const ANSWER_CUSTOM_SURVEY_XP_DUMMY_KEY = 'answer_custom_survey';

// Team Development
export const FINISH_TEAM_DEV_MODULE_XP_DUMMY_KEY = 'finish_team_dev_module';
export const FINISH_TEAM_DEV_TEAM_SESSION_XP_DUMMY_KEY = 'finish_team_dev_team_session';

// Softfacts
export const SOFTFACTS_FINISHED_XP_DUMMY_KEY = 'softfacts_finished';
export const ROLES_ENABLEMENT_XP_DUMMY_KEY = 'roles_enablement';

// Exercise Rounds
export const FINISH_EXERCISE_ROUND_XP_DUMMY_KEY = 'finish_exercise_round';

// Profile
export const ADD_JOURNAL_XP_DUMMY_KEY = 'add_journal';

// Team level (Team gets X if all team does Y)
export const TEAM_ALL_FINISH_TEAM_WAY_OF_WORKING_XP_DUMMY_KEY = 'team_all_finish_way_of_working';
export const TEAM_ALL_FINISH_POTENTIAL_ANALYSIS_XP_DUMMY_KEY = 'team_all_finish_potential_analysis';
export const TEAM_ALL_FINISH_TEAM_DEVELOPMENT_MODULE_XP_DUMMY_KEY = 'team_all_finish_team_development_module';
export const TEAM_ALL_FINISH_SOFTFACT_XP_DUMMY_KEY = 'team_all_finish_softfact';

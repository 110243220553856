// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileTeamDevData} from '@modules/Team/types/teamdevelopment.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamDevelopmentProps {
  data: Record<string, _ProfileTeamDevData>;
  showPlaceholder?: boolean;
}

const OrganisationTeamDevelopmentItem: React.FC<_TeamDevelopmentProps> = ({showPlaceholder, data}) => {
  if (showPlaceholder) {
    return (
      <div className="flex flex-col gap-2">
        <Typography bold variant="body">
          {trans('products.team_development.title')}
        </Typography>
        <Typography>{trans('dashboard.team_dev_placeholder')}</Typography>
      </div>
    );
  }
  const {nextModule = null} = data ?? {};

  return (
    <div className="flex flex-col gap-2">
      <Typography bold variant="body">
        {trans('dashboard.team_dev_table_title')}
      </Typography>
      {/* <TeamDevelopmentOverviewTable */}
      {/*  withHeaders={false} */}
      {/*  teamId={data.team.id} */}
      {/*  profiles={data.profiles} */}
      {/*  progressData={data.modules} */}
      {/* /> */}
      JSONDATA: {JSON.stringify(data ?? {})}
    </div>
  );
};
export default OrganisationTeamDevelopmentItem;

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {logger} from '@modules/Core/util/Logger';
import {InstitutionGraph} from '@modules/Institution/components/dashboard/InstitutionGraph';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {_Team} from '@modules/Team/types/team.model';

interface _OrganisationGraphItemProps {
  profiles: Record<string, _Profile>;
  teams: _Team[];
  institutionId: string;
}

export const OrganisationGraphItem: React.FC<_OrganisationGraphItemProps> = ({institutionId, profiles, teams}) => {
  return (
    <InstitutionGraph
      institution={currentInstitution()}
      currentProfile={currentProfile()}
      profiles={profiles}
      teams={teams}
    />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {HowAreYouSmiley} from '@modules/Core/components/base/lottie/HowAreYouSmiley';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  metric: _ProfileTrackingStatisticEntry;
  label: string;
}

/**
 * 'feelings.excited' => 'Excited',
 *   'feelings.happy' => 'Happy',
 *   'feelings.neutral' => 'Neutral',
 *   'feelings.sad' => 'Sad',
 *   'feelings.upset' => 'Upset',
 */
const langProps = [
  'dashboard.feelings.excited',
  'dashboard.feelings.happy',
  'dashboard.feelings.neutral',
  'dashboard.feelings.sad',
  'dashboard.feelings.upset',
];
export const ProfileHowAreYouMetricCard: React.FC<_Props> = ({metric, label}) => {
  const roundedAverage = Math.round(metric.average);
  if (!metric?.count) {
    return null;
  }
  return (
    <div className="flex flex-row items-center gap-1">
      <HowAreYouSmiley size="xxl" index={roundedAverage} />

      <div className="flex flex-col flex-grow">
        <Typography bold variant="h6">
          {trans(langProps[roundedAverage])}
        </Typography>
        <Typography variant="body2">{label}</Typography>
      </div>
    </div>
  );
};

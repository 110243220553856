// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {logger} from '@modules/Core/util/Logger';
import {CustomSurveyResult} from '@modules/CustomSurveys/components/result/CustomSurveyResult';
import {_CustomSurvey, _CustomSurveyAnswer} from '@modules/CustomSurveys/types/customSurvey.model';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _Props {
  survey: _CustomSurvey;
  answers: _CustomSurveyAnswer[];
}

export const CustomSurveyResultItem: React.FC<_Props> = ({answers, survey}) => {
  logger.debug('CustomSurveyResult', {survey, answers});
  const {currentLanguage} = useLanguage();

  if (!answers?.length) {
    return 'TODO: No answers found.';
  }

  return <CustomSurveyResult language={currentLanguage} survey={survey} answers={answers} />;
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconHome} from '@modules/Core/components/base/Icons/Icons';
import {Section} from '@modules/Core/components/layout/Section';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  errorType: 'feature_not_enabled' | string;
  withButton?: boolean;
  profileId?: string;
}

export const ErrorItem: React.FC<_Props> = ({withButton = true, profileId, errorType}) => {
  const langProps: Record<string, {title: string; description: string}> = {
    feature_not_enabled: {
      title: trans('errors.feature_not_enabled.title'),
      description: trans('errors.feature_not_enabled.description'),
    },
    default: {
      title: null,
      description: trans('errors.default_error_message'),
    },
  };
  const lang = langProps[errorType] || langProps.default;
  const navigateToFlow = useNavigateToFlow();

  return (
    <Section
      imageViewDirection="vertical"
      title={lang.title ?? null}
      description={lang.description}
      justifyContent="center"
      image="/assets/img/design_images/not_found.png"
    >
      {withButton && (
        <>
          {Boolean(!profileId) && (
            <div className="flex justify-center items-center">
              <Button
                variant="brand"
                width="relative-col-width-3"
                label={trans('surveys.try_out_softfact')}
                onClick={() => navigateToFlow('Login', {type: 'register'})}
              />
            </div>
          )}
          {Boolean(profileId) && (
            <div className="flex justify-center items-center">
              <Button
                width="relative-col-width-3"
                label={trans('surveys.take_me_home')}
                onClick={() => navigateToFlow('ProfileDashboard')}
                icon={IconHome}
                iconPosition="left"
              />
            </div>
          )}
        </>
      )}
    </Section>
  );
};

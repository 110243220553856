// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {ProfileSoftfactionaryItem} from '@modules/ContentEngine/components/items/profile/common/ProfileSoftfactionaryItem';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Typography} from '@modules/Core/components/base/Typography';
import {Group} from '@modules/Core/components/layout/Group';
import {Section} from '@modules/Core/components/layout/Section';
import {SERVICE_TEAM_DEVELOPMENT} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {TeamDevelopmentResultSection} from '@modules/Team/components/team_development/result/TeamDevelopmentResultSection';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamDevelopmentProfileAnswers, _TeamDevelopmentSurveyData} from '@modules/Team/types/teamdevelopment.model';
import {startTeamDevModule} from '@modules/Team/util/teamDevUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  result: _TeamDevelopmentProfileAnswers;
  teamId: string;
  profileId: string;
  module?: string;
  surveyData: _TeamDevelopmentSurveyData;
  allProfiles: Record<string, _TeamProfile>;
  context?: _ContentEngineContext;
  tutorialKey: string;
}

export const TeamDevelopmentResultOverview: React.FC<_Props> = ({
  allProfiles,
  result,
  surveyData,
  teamId,
  profileId,
  module,
  context,
  tutorialKey,
}) => {
  logger.debug('TeamDevelopmentResultOverview', {allProfiles, result, surveyData, teamId, profileId, module});

  let moduleScores;
  let performanceScores;
  let satisfactionScores;

  if (result?.texts?.scores) {
    moduleScores = [
      [{score: result.texts.scores.user[module].score}, {score: result.texts.scores.team[module].score}],
      [{score: result.texts.scores.user[module].new_score}, {score: result.texts.scores.team[module].new_score}],
    ];

    performanceScores = [
      [{score: result.texts.scores.user.performance.score}, {score: result.texts.scores.team.performance.score}],
      [
        {score: result.texts.scores.user.performance.new_score},
        {score: result.texts.scores.team.performance.new_score},
      ],
    ];

    satisfactionScores = [
      [{score: result.texts.scores.user.satisfaction.score}, {score: result.texts.scores.team.satisfaction.score}],
      [
        {score: result.texts.scores.user.satisfaction.new_score},
        {score: result.texts.scores.team.satisfaction.new_score},
      ],
    ];
  }

  logger.debug('TeamDevelopmentResultOverview', {moduleScores, performanceScores, satisfactionScores, surveyData});

  // if(!result?.individual?.length){
  //   return 'TODO PLACEHOLDER';
  // }

  const showResultContent = !!moduleScores && !!performanceScores && !!satisfactionScores;
  return (
    <Group>
      <TeamSectionItem
        title={trans('development_result.result')}
        notificationType="TD"
        teamId={teamId}
        allProfiles={allProfiles}
        answeredProfiles={result.moduleAnsweredProfiles}
        context={context}
        journalKey={`team_development.${module}`}
        tutorialKey={tutorialKey}
        tutorialService={`${SERVICE_TEAM_DEVELOPMENT}:result:overview`}
      >
        {showResultContent && (
          <>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: surveyData?.moduleClarification,
              }}
            />
            <ProfileSoftfactionaryItem />
            <ProfileFeedbackItem feedbackKey={`team:team_development:result:${module}`} />
          </>
        )}
      </TeamSectionItem>

      {showResultContent ? (
        <>
          <TeamDevelopmentResultSection
            data={moduleScores}
            text={surveyData.moduleExplainer}
            graphTitle={trans(`profile.${module}`)}
            description={surveyData.moduleExplanation}
            individualData={{
              description: result?.texts?.individual?.[module],
            }}
            teamData={{
              description: result?.texts?.team?.[module],
            }}
            individualChangeData={{
              description: result?.texts?.individual_change?.[module],
            }}
            teamChangeData={{
              description: result?.texts?.team_change?.[module],
            }}
          />

          <TeamDevelopmentResultSection
            data={performanceScores}
            text={result?.texts?.performanceExplainer}
            graphTitle={trans(`development_result.performance`)}
            description={result?.texts?.performanceExplanation}
            individualData={{
              description: result?.texts?.individual?.performance,
            }}
            teamData={{
              description: result?.texts?.team?.performance,
            }}
            individualChangeData={{
              description: result?.texts?.individual_change?.performance,
            }}
            teamChangeData={{
              description: result?.texts?.team_change?.performance,
            }}
          />

          <TeamDevelopmentResultSection
            data={satisfactionScores}
            text={result?.texts?.satisfactionExplainer}
            graphTitle={trans(`development_result.satisfaction`)}
            description={result?.texts?.satisfactionExplanation}
            individualData={{
              description: result?.texts?.individual?.satisfaction,
            }}
            teamData={{
              description: result?.texts?.team?.satisfaction,
            }}
            individualChangeData={{
              description: result?.texts?.individual_change?.satisfaction,
            }}
            teamChangeData={{
              description: result?.texts?.team_change?.satisfaction,
            }}
          />
        </>
      ) : (
        <Section image="/assets/img/design_images/check-in.png" imageViewDirection="vertical">
          <Typography>
            {trans('development_result.start_module_description', {
              module: trans(`development_result.${module}`),
            })}
          </Typography>
          <div className="flex flex-row justify-center items-center">
            <Button onClick={() => startTeamDevModule(teamId, module)} width="relative-col-width-2">
              {trans('development_result.start_module')}
            </Button>
          </div>
        </Section>
      )}
    </Group>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_Team} from '@modules/Team/types/team.model';

export function useTeams(): [_Team[]] {
  const currentTeamState = useSelectState(state => state.teams.currentTeams) as _Team[];
  return [currentTeamState];
}

// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {getCurrentTeams} from '@modules/Team/services/teamsApi';
import {_Team} from '@modules/Team/types/team.model';

export interface _TeamsState {
  currentTeams: _Team[];
}

const initialState: _TeamsState = {
  currentTeams: [],
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setCurrentTeams(state, action) {
      logger.info('[TeamsSlice] Saving teams.');
      state.currentTeams.length = 0;
      if (action.payload) {
        state.currentTeams.push(...action.payload);
      }
    },
    resetCurrentTeams(state) {
      logger.info('[TeamsSlice] Resetting teams.');
      return initialState;
    },
  },
});

export const {setCurrentTeams, resetCurrentTeams} = teamsSlice.actions;
export default teamsSlice;

export function fetchCurrentTeams() {
  return async (dispatch: any) => {
    logger.info('[TeamsSlice] Updating current teams.');
    const response = await getCurrentTeams();

    if (response?.data) {
      return dispatch(setCurrentTeams(response.data));
    }

    return null;
  };
}

// @ts-nocheck
/* eslint-disable */

import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';

export const initializePolarAreaChart = (root, chartOptions, {data, labels}) => {
  // Create a Radar chart
  const chart = root.container.children.push(
    am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      startAngle: chartOptions.startAngle ?? 0,
      endAngle: chartOptions.endAngle ?? 360,
      innerRadius: chartOptions.innerRadius ?? am5.percent(20),
    })
  );

  // Create axes
  const xRenderer = am5radar.AxisRendererCircular.new(root, {minGridDistance: 30});
  const xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
    })
  );

  const yRenderer = am5radar.AxisRendererRadial.new(root, {minGridDistance: 30});
  const yAxis = chart.yAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'category',
      renderer: yRenderer,
    })
  );

  yAxis.data.setAll(data);

  // Create series
  const series = chart.series.push(
    am5radar.RadarColumnSeries.new(root, {
      xAxis,
      yAxis,
      valueXField: 'value',
      categoryYField: 'category',
      clustered: false,
    })
  );

  // Adjust the column series to format labels
  series.columns.template.setAll({
    tooltipText: '{category}: {valueX}%',
    cornerRadiusTL: 15,
    cornerRadiusTR: 15,
  });

  // Configure series labels to appear outside the chart
  series.columns.template.set('tooltipY', am5.percent(100));
  series.columns.template.set('tooltipText', '{category}: {valueX}%');

  series.columns.template.setAll({tooltipText: '{category}: {valueX}', cornerRadiusTL: 15, cornerRadiusTR: 15});
  series.data.setAll(data);

  // Add cursor
  chart.set('cursor', am5radar.RadarCursor.new(root, {}));
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Badge} from './Badge';

interface _NotificationBadge {
  value?: string | number;
  children?: React.ReactNode;
}

export const WrapperBadge: React.FC<_NotificationBadge> = props => {
  const {value, children} = props;

  return (
    <div className="relative h-6 w-6">
      <div className="absolute top-[0px] right-[0px] z-20">
        {value !== undefined ? <Badge value={value.toString()} size="md" /> : null}
      </div>
      <div className="absolute bottom-[0px] left-[0px]">{children}</div>
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';

export function shareCustomSurveyFlow(surveyId: string): void {
  fireDialogFlow(
    'ShareCustomSurvey',
    null,
    {
      hideButtons: true,
      showCloseButton: true,
    },
    {
      surveyId,
    }
  );
}

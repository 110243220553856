// @ts-nocheck
/* eslint-disable */

import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {generate} from '@modules/Core/components/base/helper';
import {logger} from '@modules/Core/util/Logger';
import {zustandApi} from '@modules/Core/util/logic/zustand';

export interface _DialogActions {
  show: (options: _GlobalDialog) => string;
}

export const showDialog = (options: _GlobalDialog): string => {
  const id = generate();
  zustandApi.addGlobalDialog({
    id,
    open: true,
    ...options,
    onClose: () => {
      options.onClose?.();
      zustandApi.removeGlobalDialog(id);
    },
    onCancel: () => {
      options.onCancel?.();
      zustandApi.removeGlobalDialog(id);
    },
    onConfirm: () => {
      options.onConfirm?.();
      zustandApi.removeGlobalDialog(id);
    },
  });
  return id;
};

export const closeDialog = (id: string): void => {
  zustandApi.removeGlobalDialog(id);
};

export const closeCurrentDialog = (): void => {
  zustandApi.removeCurrentDialog();
};

export const useDialog = (): _DialogActions => {
  return {
    show: (options: _GlobalDialog) => showDialog(options),
  };
};

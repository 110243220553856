// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SoftfactsOverview} from '@modules/Core/components/base/softfacts/SoftfactsOverview';
import {_ProfileModule} from '@modules/Profile/types/modules.model';

interface _Props {
  modules: _ProfileModule[];
}

export const ProfileSoftfactsOverviewItem: React.FC<_Props> = ({modules}) => {
  if (!modules?.length) return null;

  return <SoftfactsOverview modules={modules} />;
};

// @ts-nocheck
/* eslint-disable */

import React, {useRef} from 'react';
import {flowRoute} from '@/_core/util/util';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconCycle} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine, reloadProfileState} from '@modules/Core/util/eventsUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {saveProfilePhoto} from '@modules/Profile/services/profileApi';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

// TODO @Sherif: handle the file upload, currently input isn't firing with event just value, fix that
interface _Props {
  photo: string;
  setPhoto?: (value: string) => void;
  view?: 'public' | 'private';
  showHeader?: boolean;
  children?: React.ReactNode;
}

// TODO @Sherif: move this to a flow action, easier for now to leave this here
export const Photo: React.FC<_Props> = ({children, showHeader = true, photo, setPhoto, view = 'private'}) => {
  const snackbar = useSnackbar();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onSaveProfilePhoto = async (newFile: File): Promise<void> => {
    if (!newFile || view === 'public') {
      return;
    }
    const formData = new FormData();
    formData.append('photo', newFile);

    const result = await saveProfilePhoto(formData);

    if (result?.status === 200) {
      snackbar.success(trans('profile.saved'));
      reloadContentEngine();
      reloadProfileState();
      setPhoto?.(getProfilePic(result?.data?.profile));
    } else {
      snackbar.danger(trans('base.error'));
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (view === 'public') {
      return;
    }
    const newImage = event.target?.files?.[0];

    if (newImage) {
      void onSaveProfilePhoto(newImage);
    }
  };

  const openFilePicker = (): void => {
    if (view === 'public') {
      return;
    }

    if (!inputFileRef.current) {
      return;
    }
    inputFileRef.current.click();
  };

  return (
    <Section title={showHeader ? trans('profile.photo') : null}>
      <div className="flex flex-col items-center gap-3">
        <img src={photo} alt="avatar" className="rounded-full w-12 h-12" />

        {Boolean(view !== 'public') && (
          <>
            <Input
              hidden
              id="avatar-image-upload"
              dusk="avatar-image-upload"
              type="file"
              accept="image/*"
              elRef={inputFileRef}
              onChange={(val, e) => handleOnChange(e)}
            />
            <Typography
              dangerouslySetInnerHTML={{
                __html: trans('base.profile_with_out_photo_consent', {
                  route: flowRoute(FLOWS.ProfileSettings, null, {
                    active: 'privacy',
                  }),
                }),
              }}
            />

            <label htmlFor="avatar-image-upload">
              <div className="relative-col-width-2">
                <Button
                  label={trans('base.change')}
                  asButton
                  onClick={() => openFilePicker()}
                  type="button"
                  icon={IconCycle}
                />
              </div>
            </label>
          </>
        )}
      </div>
      {children}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {
  _ServiceStatusStatus,
  _ServiceStatusXPData,
  SERVICE_STATUS_NOT_READY,
} from '@modules/Profile/types/modules.model';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  badge?: string;
  currentRound?: number;
  currentRoundStatus?: _ServiceStatusStatus;
  xpData: _ServiceStatusXPData;
  nextExercise?: number;
}

export const SoftFactFooter: React.FC<_Props> = ({nextExercise, xpData, currentRoundStatus, currentRound, badge}) => {
  let expText = null;
  let text = null;
  switch (badge) {
    case 'locked':
    case 'start':
    case 'first':
    case 'first-inner':
    case 'next':
      text = trans('profile_modules.softfact_getstarted');
      break;
    case 'finished':
      text = trans('profile_modules.softfact_completed');
      break;
    case 'show':
    case 'progress':
      text =
        currentRoundStatus !== SERVICE_STATUS_NOT_READY ? (
          <>
            {trans('profile_exercises.round', {roundIndex: Math.min((currentRound ?? 0) + 1, 3)})}{' '}
            {nextExercise !== null &&
              nextExercise !== undefined &&
              trans('profile_exercises.exercise', {exerciseIndex: nextExercise + 1})}
          </>
        ) : (
          (text = trans('profile_modules.softfact_getstarted'))
        );
      if (xpData) {
        expText = trans('gamification.gained_vs_total_exp', {
          gained: xpData?.gained,
          total: xpData?.total,
        });
      }

      break;
    default:
      text = trans('profile_modules.softfact_getstarted');
      break;
  }
  return (
    <div className="flex flex-row w-full justify-between">
      <Typography variant="body2">{text}</Typography>
      {!!expText && <ExperienceCount experience={expText} />}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TodoItem} from '@modules/Core/components/base/todo-list/TodoItem';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {logger} from '@modules/Core/util/Logger';

interface _Props {
  title: string;
  placeholder?: string;
  items: Array<{displayValue: string; [key: string]: any}>;
  onChange: (
    items: Array<{displayValue: string; [key: string]: any}>,
    action?: 'delete' | 'update' | 'create',
    actionData?: any
  ) => void;
  deleteDialogTitle?: string;
  deleteDialogText?: string;
}

export const TodoList: React.FC<_Props> = ({
  deleteDialogTitle,
  deleteDialogText,
  onChange,
  title,
  placeholder,
  items,
}) => {
  const [newValue, setNewValue] = useState<string | null>(null);
  const onAdd = (): void => {
    if (!newValue) {
      return;
    }
    const newItems = [...items, {displayValue: newValue}];
    setNewValue('');
    onChange(newItems, 'create', {value: newValue});
  };

  const onDelete = (index: number): void => {
    fireConfirmationDialog({
      title: deleteDialogTitle,
      children: deleteDialogText,
      onConfirm: () => {
        const newItems = items.filter((_, i) => i !== index);

        onChange(newItems, 'delete', {id: items[index].id});
      },
    });
  };

  const onItemChange = (index: number, value: string): void => {
    logger.debug('onItemChange', {index, value});
    const newItems = items.map((item, i) => (i === index ? {...item, displayValue: value} : item));
    onChange(newItems, null, null);
  };

  const onConfirmItemChange = (index: number, value: string): void => {
    logger.debug('onItemChange', {index, value});
    const newItems = items.map((item, i) => (i === index ? {...item, displayValue: value} : item));
    onChange(newItems, 'update', {id: items[index].id, value});
  };

  return (
    <Card title={title}>
      <Input
        value={newValue}
        onChange={setNewValue}
        placeholder={placeholder}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onAdd();
          }
        }}
      />

      {(items || []).map((item, index) => (
        <TodoItem
          key={item.id || index}
          displayValue={item.displayValue}
          onChange={value => onItemChange(index, value)}
          onDelete={() => onDelete(index)}
          onConfirm={() => onConfirmItemChange(index, items[index].displayValue)}
        />
      ))}
    </Card>
  );
};

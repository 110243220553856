// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';
import {setTutorialDoneAction} from '@modules/Tutorials/util/tutorialActionsUtil';

export function useGlobalTutorials(onDone: () => void): {
  addTutorial: (id: string, tutorial: _Tutorial, onClose?: () => void) => void;
  addTutorials: (tutorials: _Tutorial[]) => void;
  getCurrentTutorial: () => _Tutorial | null;
  onNextPage: () => void;
  onPrevPage: () => void;
  resetTutorials: () => void;
  getEnrichedDialogConfig: () => _Dialog;
  currentIndex: number | null;
  setDialogConfig: (config: _Dialog) => void;
  currentTutorialId: string | null;
  tutorials: Record<string, _Tutorial>;
} {
  const [tutorials, setTutorials] = useState<Record<string, _Tutorial>>({});
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  // Holds config for w/e dialog is currently being shown
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});

  // Idea is, this could be fired multiple times rapidly if multiple components are adding tutorials
  // user will NOT notice this
  const reorderingRequired = useRef<boolean>(false);

  // Runs only to recalculates the order of dialogs when new ones are added.
  useEffect(() => {
    if (!reorderingRequired.current) {
      return;
    }

    setCurrentIndex(0);
    reorderingRequired.current = false;

    const oldCurrentTutorialId = currentIndex !== null ? Object.keys(tutorials)[currentIndex] : null;
    const newCurrentTutorialId = Object.keys(tutorials)[0];
    // Reset config ONLY if the current alert has changed
    if (oldCurrentTutorialId !== newCurrentTutorialId) {
      setDialogConfig({});
    }
  }, [tutorials]);

  const addTutorial = (id: string, alert: _Tutorial, onClose?: () => void): void => {
    logger.debug('addTutorial fn', {tutorials, id, alert});
    if (tutorials[id]) {
      return;
    }
    setTutorials(prev => {
      logger.debug('TutorialFlowContainer: handleDone added', {
        newTutorials: {
          ...prev,
          [id]: {
            ...alert,
            onClose,
          },
        },
      });

      return {
        ...prev,
        [id]: {
          ...alert,
          onClose,
        },
      };
    });
    reorderingRequired.current = true;
  };

  const addTutorials = (newAlerts: _Tutorial[]): void => {
    logger.debug('addTutorials', newAlerts);
    newAlerts.forEach(alert => {
      addTutorial(alert.id, alert);
    });
  };

  const resetTutorials = (): void => {
    setTutorials({});
    setCurrentIndex(null);
    setDialogConfig({});
  };

  const getCurrentTutorial = (): _Tutorial | null => {
    if (currentIndex === null) {
      return null;
    }
    return tutorials[Object.keys(tutorials)[currentIndex]];
  };

  const onNextPage = (): void => {
    if (currentIndex === null) {
      return;
    }

    if (currentIndex === Object.keys(tutorials).length - 1) {
      void handleDone();
      return;
    }

    setCurrentIndex(prev => {
      if (prev === null) {
        return null;
      }
      return prev + 1;
    });
    setDialogConfig({});
  };

  const onPrevPage = (): void => {
    if (currentIndex === 0 || currentIndex === null) {
      return;
    }

    setCurrentIndex(prev => {
      if (prev === null) {
        return null;
      }
      return prev - 1;
    });
    setDialogConfig({});
  };

  const handleDone = async (): Promise<void> => {
    const tutorialsIds = Object.keys(tutorials ?? {});
    if (!tutorialsIds.length) {
      onDone?.();
      return;
    }
    // TODO
    for (const tutorialId of tutorialsIds) {
      const tutorial = tutorials[tutorialId];
      if (tutorial) {
        logger.debug('TutorialFlowContainer: handleDone', {tutorial});
        await setTutorialDoneAction(tutorial.name);

        if (tutorial.onClose) {
          tutorial.onClose();
        }
      }
    }
    setTutorials({});
    onDone?.();
  };

  const getEnrichedDialogConfig = (): _Dialog => {
    const currentTutorial = getCurrentTutorial();
    return {
      ...dialogConfig,
      showCloseButton: true,
      onClose: handleDone,
      onConfirm: currentTutorial?.type !== 'dialog' ? handleDone : undefined,
      onCancel: handleDone,
      hideButtons: currentTutorial?.type !== 'dialog',
      cancelLabel: trans('tutorials.finish'),
      cancelProps: {
        variant: 'primary',
      },
      containerGap: 2,
      justifyButtons: currentTutorial?.type === 'dialog' ? 'center' : null,
    };
  };

  logger.info('dialogContentComponent', {
    dialogConfig,
    enriched: getEnrichedDialogConfig(),
    currentIndex,
    getCurrentTutorial: getCurrentTutorial(),
  });
  return {
    addTutorial,
    addTutorials,
    getCurrentTutorial,
    onNextPage,
    onPrevPage,
    resetTutorials,
    getEnrichedDialogConfig,
    currentIndex,
    setDialogConfig,
    currentTutorialId: currentIndex !== null ? Object.keys(tutorials)[currentIndex] : null,
    tutorials,
  };
}

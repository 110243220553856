// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {_CustomSurvey, _CustomSurveyQuestion} from '@modules/CustomSurveys/types/customSurvey.model';
import {SurveyJSJson} from '@modules/CustomSurveys/types/surveyJs.model';

const modelFields = ['title', 'type', 'options', 'meta'];

const supportedLangs = ['de', 'en'];

function getMultiLangText(text: string | object, lang: string): string {
  if (typeof text === 'object') {
    const language = supportedLangs.find(lang => text?.[lang]);
    return text?.[language] || text?.default;
  }
  return text;
}

export function mapSurveyCreatorToBackend(surveyCreatorObject?: SurveyJSJson): _CustomSurvey {
  if (!surveyCreatorObject) return {} as _CustomSurvey;

  const {locale} = surveyCreatorObject;
  // survey title & description + questions.title should use surveyCreatorObject.locale,
  // and set en, de, default to the same value
  const survey: _CustomSurvey = {
    id: surveyCreatorObject.id ?? '',
    title: getMultiLangText(surveyCreatorObject.title, locale),
    description: getMultiLangText(surveyCreatorObject.description, locale),
    questions: surveyCreatorObject.elements?.map((element, index) => {
      const question: _CustomSurveyQuestion = {
        title: element.title || '',
        type: element.type,
        options: {},
        meta: {},
        page: 0,
        order: index,
      };

      // Extract everything else to options
      Object.keys(element).forEach(key => {
        if (!modelFields.includes(key)) {
          question.options[key] = element[key];
        }
      });

      // If 'meta' exists in the element, use it directly
      if ('meta' in element && typeof element.meta === 'object') {
        question.meta = element.meta;
      }

      return question;
    }),
  };

  return survey;
}

export function mapBackendToSurveyCreator(customSurvey: _CustomSurvey | null, currentLanguage: string): SurveyJSJson {
  if (!customSurvey) return {} as SurveyJSJson;
  logger.debug('mapBackendToSurveyCreator', customSurvey);
  const surveyJS: SurveyJSJson = {
    id: customSurvey.id,
    title: getMultiLangText(customSurvey.title, currentLanguage),
    description: getMultiLangText(customSurvey.description, currentLanguage),
    pages: customSurvey.pages?.map(page => ({name: page.name})) ?? [],
  };

  customSurvey.questions?.forEach(question => {
    const pageIndex = question.page;
    if (!surveyJS.pages[pageIndex]) {
      surveyJS.pages[pageIndex] = {elements: []};
    }

    // For title (looks like :{$langKey:'textValue'}) we need to extract the textValue

    const element: any = {
      ...question.options,
      title: getMultiLangText(question.title, currentLanguage),
      type: question.type,
      meta: question.meta,
    };

    const {options} = question;

    // For each options.choices.text (looks like :{$langKey:'textValue'}) we need to extract the textValue
    if (options?.choices) {
      logger.debug('options.choices', options.choices);
      options.choices = options.choices.map((choice: any) => {
        if (typeof choice.text === 'object') {
          // Set all languages to text of current language.
          const newText = {
            default: choice.text[currentLanguage] ?? choice.text.default,
            de: choice.text[currentLanguage] ?? choice.text.default,
            en: choice.text[currentLanguage] ?? choice.text.default,
          };
          return {
            ...choice,
            text: newText,
          };
        }

        return choice;
      });
      logger.debug('options.choices after', options.choices);
    }

    if (options?.otherText && typeof options.otherText === 'object') {
      options.otherText = {
        default: options.otherText[currentLanguage] ?? options.otherText.default,
        de: options.otherText[currentLanguage] ?? options.otherText.default,
        en: options.otherText[currentLanguage] ?? options.otherText.default,
      };
    }

    element.choices = options.choices;

    if (!surveyJS.pages[pageIndex].elements) {
      surveyJS.pages[pageIndex].elements = [];
    }

    surveyJS.pages[pageIndex].elements.push(element);
  });

  return surveyJS;
}

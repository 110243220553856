// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconSearch} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {fuzzySearch} from '@modules/Core/util/searchUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _TableFilterProps {
  data: any[];
  setFilteredData?: (data: any[]) => void;
  options?: _TableFilteringOptions;
}

export interface _TableFilteringOptions {
  enabled?: boolean;
  searchAttributes?: string[];
}

export const TableFilter: React.FC<_TableFilterProps> = ({data, options, setFilteredData}) => {
  // Component state
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (options?.enabled) {
      const newResults = fuzzySearch(data, searchString, options.searchAttributes ?? [], {
        isCaseSensitive: false,
      });

      if (setFilteredData) {
        setFilteredData(newResults);
      }
    }
  }, [searchString]);

  if (!options?.enabled) {
    return null;
  }

  return (
    <Input
      value={searchString}
      onChange={e => setSearchString(e)}
      placeholder={trans('base.search')}
      icon={IconSearch}
    />
  );
};

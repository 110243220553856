// @ts-nocheck
/* eslint-disable */

import {LengthBetweenValidator} from './validators/lengthBetweenValidator';
import {MinLengthValidator} from './validators/minLengthValidator';
import {RequiredValidator} from './validators/requiredValidator';

const RegisteredValidators: Record<string, any> = {
  required: RequiredValidator,
  minLength: MinLengthValidator,
  lengthBetween: LengthBetweenValidator,
};
export default RegisteredValidators;

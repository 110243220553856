// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {TeamSectionItem} from '@modules/ContentEngine/components/items/TeamSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Section} from '@modules/Core/components/layout/Section';
import {SERVICE_TEAM_DEVELOPMENT} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {useTeamDevModuleData} from '@modules/Team/hooks/development/teamDevModuleData';
import {trans} from '@modules/Translations/util/i18n';
import {TeamDevelopmentModuleCard} from '../common/TeamDevelopmentModuleCard';

// TODO @Stefan do we want to have this info in the survey?
const INDIVIDUAL_SUB_MODULE_KEYS = ['individualtools'];
const TEAM_SUB_MODULE_KEYS = ['collectivetools'];
const CHECK_IN_SUB_MODULE_KEYS = ['measure'];
const CHECK_OUT_SUB_MODULE_KEYS = ['measure2'];
const START_MODULE_KEYS = ['inspiration', 'motivation'];

const SUB_MODULES_GROUP_KEYS: Record<string, string[]> = {
  check_in: CHECK_IN_SUB_MODULE_KEYS,
  check_out: CHECK_OUT_SUB_MODULE_KEYS,
  individual_tools: INDIVIDUAL_SUB_MODULE_KEYS,
  collective_tools: TEAM_SUB_MODULE_KEYS,
  get_started: START_MODULE_KEYS,
};

interface _Props {
  teamId: string;
  profileId: string;
  module: string;
  profiles: Record<string, _Profile>;
  modulesData: Record<string, any>;
  context?: _ContentEngineContext;
  tutorialKey?: string;
}

export const TeamDevelopmentModuleOverview: React.FC<_Props> = ({
  modulesData,
  profiles,
  profileId,
  teamId,
  module,
  tutorialKey,
  context,
}) => {
  const {subModules, progressData} = useTeamDevModuleData(modulesData);

  function teamSessionSubModuleComponent(latestSubModuleProgress) {
    return (
      <div className="relative-col-width-3" dusk="submodule-box-component">
        <TeamDevelopmentModuleCard
          teamId={teamId}
          module={module}
          isTeamSession
          progress={progressData}
          latestSubModuleProgress={latestSubModuleProgress}
          profiles={profiles}
        />
      </div>
    );
  }

  function subModuleComponent(subModule: string, subSubModule?: string, subSubModuleIndex?: number) {
    return (
      <div className="relative-col-width-3" dusk="submodule-box-component">
        <TeamDevelopmentModuleCard
          teamId={teamId}
          module={module}
          subModule={subModule}
          subSubModule={subSubModule}
          progress={progressData}
          profiles={profiles}
          index={subSubModuleIndex}
        />
      </div>
    );
  }

  function subModulesGroupComponent(key, title = null) {
    const subModuleKeys = SUB_MODULES_GROUP_KEYS[key];

    let latestSubModuleProgress = null;
    const subModulesComponents = (subModuleKeys || []).map((subModuleKey, subModuleIndex) => {
      if (Object.keys(subModules[subModuleKey].subSubModules || {}).length === 0) {
        latestSubModuleProgress = progressData.subModules[subModuleKey][profileId]?.progress;

        return subModuleComponent(subModules[subModuleKey], null, subModuleIndex);
      }

      return Object.keys(subModules[subModuleKey].subSubModules).map((subSubModuleKey, subSubModuleIndex) => {
        latestSubModuleProgress =
          progressData.subModules[subModuleKey][profileId].subSubModules[subSubModuleKey]?.progress;
        return subModuleComponent(
          subModules[subModuleKey],
          subModules[subModuleKey].subSubModules[subSubModuleKey],
          subSubModuleIndex
        );
      });
    });

    if (key === 'collective_tools') {
      subModulesComponents.push(teamSessionSubModuleComponent(latestSubModuleProgress));
    }

    return (
      <Section title={trans(`development_result.team_dev_v2.titles.${key}`)} titleVariant="h5">
        <div className={twMerge('flex flex-row gap-3 flex-wrap')}>{subModulesComponents}</div>
      </Section>
    );
  }

  if (!subModules || Object.keys(subModules).length === 0) {
    return null;
  }

  logger.debug('TeamDevelopmentModuleOverview', {subModules, context, tutorialKey});
  return (
    <TeamSectionItem
      teamId={teamId}
      context={context}
      gap={8}
      tutorialKey={tutorialKey}
      tutorialService={`${SERVICE_TEAM_DEVELOPMENT}:moduleOverview`}
      journalKey={`team_development.${module}`}
    >
      {subModulesGroupComponent('check_in', '')}
      {subModulesGroupComponent('get_started')}
      {subModulesGroupComponent('individual_tools')}
      {subModulesGroupComponent('collective_tools')}
      {subModulesGroupComponent('check_out')}
    </TeamSectionItem>
  );
};

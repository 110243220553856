// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export interface _DroppableProps {
  id: string;
  children: React.ReactNode;
  accepts?: string[];
  disabled?: boolean;
  className?: string;
}

export const Droppable: React.FC<_DroppableProps> = ({className, id, children, accepts, disabled}) => {
  const {setNodeRef} = useDroppable({
    id,
    disabled,
    data: {accepts},
  });

  return (
    <div ref={setNodeRef} className={className}>
      {children}
    </div>
  );
};

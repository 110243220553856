// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute} from '@/_core/util/util';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconEmail, IconPassword} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {ContentArea} from '@modules/Profile/components/settings/ContentArea';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  newEmail: string | null;
  setNewEmail: (newEmail: string) => void;
  oldEmail: string | null;
  changeEmailPassword: string | null;
  setChangeEmailPassword: (changeEmailPassword: string) => void;

  oldPassword: string | null;
  setOldPassword: (oldPassword: string) => void;
  newPassword: string | null;
  setNewPassword: (newPassword: string) => void;

  deleteAccountPassword: string | null;
  setDeleteAccountPassword: (deleteAccountPassword: string) => void;

  onChangeEmail: () => void;
  onChangePassword: () => void;
  onDeleteAccount: () => void;

  responsibleForOrgs: any[];
  responsibleForTeams: any[];
}

const ProfileAccountSettings: React.FC<_Props> = ({
  newEmail,
  newPassword,
  setNewEmail,
  oldEmail,
  setNewPassword,
  oldPassword,
  setOldPassword,
  deleteAccountPassword,
  setDeleteAccountPassword,
  changeEmailPassword,
  setChangeEmailPassword,
  onDeleteAccount,
  onChangeEmail,
  onChangePassword,
  responsibleForTeams,
  responsibleForOrgs,
}) => {
  return (
    <div className="flex flex-col gap-8 items-start justify-start">
      <ContentArea onSubmit={onChangeEmail} label={trans('settings.change_email')}>
        <ChangeEmail
          oldEmail={oldEmail}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          currentPassword={changeEmailPassword}
          setCurrentPassword={setChangeEmailPassword}
        />
      </ContentArea>

      <ContentArea onSubmit={onChangePassword} label={trans('settings.change_password')}>
        <ChangePassword
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
        />
      </ContentArea>

      <ContentArea
        onSubmit={onDeleteAccount}
        label={trans('settings.delete_account')}
        buttonLabel={trans('settings.delete_account')}
      >
        <DeleteAccount
          responsibleForTeams={responsibleForTeams}
          responsibleForOrgs={responsibleForOrgs}
          deleteAccountPassword={deleteAccountPassword}
          setDeleteAccountPassword={setDeleteAccountPassword}
        />
      </ContentArea>
    </div>
  );
};

export default ProfileAccountSettings;

interface _ChangeEmailProps {
  oldEmail: string;
  newEmail: string;
  setNewEmail: (newEmail: string) => void;
  currentPassword: string;
  setCurrentPassword: (currentPassword: string) => void;
  onChangeEmail: () => void;
}

const ChangeEmail: React.FC<_ChangeEmailProps> = ({
  oldEmail,
  newEmail,
  setNewEmail,
  currentPassword,
  setCurrentPassword,
}) => {
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-3">
          <Input
            value={oldEmail ?? ''}
            icon={IconEmail}
            label={trans('settings.old_email_label')}
            placeholder={trans('settings.old_email_placeholder')}
            width="relative-col-width-5"
            readOnly
          />
          <Input
            value={newEmail}
            onChange={setNewEmail}
            icon={IconEmail}
            label={trans('settings.new_email_label')}
            placeholder={trans('settings.new_email_placeholder')}
            width="relative-col-width-5"
          />
        </div>

        <Input
          value={currentPassword}
          onChange={setCurrentPassword}
          icon={IconPassword}
          label={trans('settings.current_password_label')}
          placeholder={trans('settings.current_password_placeholder')}
          width="relative-col-width-5"
          type="password"
        />
      </div>
      <div className="flex justify-end">
        <Button
          label={trans('settings.save_changes_button')}
          width="relative-col-width-2"
          disabled={!newEmail || !currentPassword}
          type="submit"
        />
      </div>
    </>
  );
};

interface _ChangePasswordProps {
  oldPassword: string;
  setOldPassword: (oldPassword: string) => void;
  newPassword: string;
  setNewPassword: (newPassword: string) => void;
}

const ChangePassword: React.FC<_ChangePasswordProps> = ({oldPassword, setOldPassword, newPassword, setNewPassword}) => {
  return (
    <>
      <div className="flex flex-row gap-3">
        <Input
          value={oldPassword}
          onChange={setOldPassword}
          icon={IconPassword}
          label={trans('settings.current_password_label')}
          placeholder={trans('settings.current_password_placeholder')}
          width="relative-col-width-5"
          type="password"
        />
        <Input
          value={newPassword}
          onChange={setNewPassword}
          icon={IconPassword}
          label={trans('settings.new_password_label')}
          placeholder={trans('settings.new_password_placeholder')}
          width="relative-col-width-5"
          type="password"
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          label={trans('settings.save_changes_button')}
          width="relative-col-width-2"
          disabled={!oldPassword || !newPassword}
        />
      </div>
    </>
  );
};

interface _DeleteAccountProps {
  deleteAccountPassword: string;
  setDeleteAccountPassword: (deleteAccountPassword: string) => void;
  responsibleForOrgs: any[];
  responsibleForTeams: any[];
}

const DeleteAccount: React.FC<_DeleteAccountProps> = ({
  responsibleForTeams,
  responsibleForOrgs,
  deleteAccountPassword,
  setDeleteAccountPassword,
}) => {
  const disabled = responsibleForOrgs.length > 0 || responsibleForTeams.length > 0;
  return (
    <>
      {!disabled && (
        <span
          dangerouslySetInnerHTML={{
            __html: trans('settings.delete_account_description'),
          }}
        />
      )}

      {responsibleForOrgs.length > 0 && (
        <>
          <Typography>{trans('profile.delete_description_org_owner')}</Typography>

          <Typography>
            {responsibleForOrgs.map((org: any) => (
              <TextLink
                label={trans('base.link_to_org', {
                  name: org.name,
                })}
                href={flowRoute(FLOWS.OrganisationMembers, null, {
                  institutionId: org.id,
                })}
              />
            ))}
          </Typography>
        </>
      )}

      {responsibleForTeams.length > 0 && (
        <>
          <Typography>{trans('profile.delete_description_team_owner')}</Typography>
          <Typography>
            {responsibleForTeams.map((team: any) => (
              <TextLink
                label={trans('base.link_to_team', {
                  name: team.name,
                  orgName: team?.institution?.name,
                })}
                href={flowRoute(FLOWS.TeamMembers, team.id)}
              />
            ))}
          </Typography>
        </>
      )}

      <Input
        disabled={disabled}
        value={deleteAccountPassword}
        onChange={setDeleteAccountPassword}
        icon={IconPassword}
        label={trans('settings.current_password_label')}
        placeholder={trans('settings.current_password_placeholder')}
        width="relative-col-width-10"
        type="password"
      />
      <div className="flex justify-end">
        <Button
          label={trans('settings.delete_account')}
          disabled={disabled || !deleteAccountPassword}
          type="submit"
          width="relative-col-width-2"
        />
      </div>
    </>
  );
};

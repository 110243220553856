// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconArrowForward, IconArrowLeft, IconArrowRight} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {TeamValuesChangeChipComponent} from '@modules/Team/components/team_analysis/values/TeamValuesChangeChipComponent';
import {useTeamValuesChanges} from '@modules/Team/hooks/Analysis/Values/teamValuesChanges';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamValues} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

const titleLangPropertiesMap = {
  NEWLY_ADDED: 'personal_values.changes_modal_title.add_title',
  VALUE_CHANGED: 'personal_values.changes_modal_title.change_title',
  DELETED: 'personal_values.changes_modal_title.delete_title',
};

interface _Props {
  teamId: string;
  result: _TeamValues;
  transparencyAccepted: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  allProfiles: Record<string, _TeamProfile>;
}

export const TeamValuesChangesDialog: React.FC<_Props> = ({
  allProfiles,
  open,
  setOpen,
  teamId,
  result,
  transparencyAccepted,
}) => {
  const {
    currentIndex,
    changedValues,
    getCurrentElement,
    getNextItem,
    getPreviousItem,
    onLater,
    onDiscardChange,
    onConfirmChange,
    hasPrevious,
    hasNext,
  } = useTeamValuesChanges(teamId, result, open, setOpen);

  useEffect(() => {
    if ((changedValues || []).length === 0) {
      setOpen(false);
    }
  }, [changedValues]);

  function getChangingTeamMembersText() {
    const currentChangedValue = getCurrentElement();
    let teamMembersNamesString = '';

    if (!currentChangedValue?.diff?.profiles || !transparencyAccepted) {
      return '';
    }

    let changingProfilesList = currentChangedValue.diff.profiles;

    // Remove duplicates
    const changingProfilesListIds = Array.from(new Set(Object.keys(changingProfilesList)));

    changingProfilesList = changingProfilesListIds.map(id => allProfiles?.[id]).filter(Boolean);

    if (changingProfilesList.length > 0) {
      if (changingProfilesList.length === 1) {
        teamMembersNamesString = changingProfilesList?.[0]?.fullName;
      } else {
        changingProfilesList.forEach(function (val, index) {
          // If first item, no need to add ',' or 'and'
          if (index === 0) {
            teamMembersNamesString += val.fullName;
          }
          // If last one, add "and" to the string
          else if (index === changingProfilesList.length - 1) {
            teamMembersNamesString += ` ${trans('base.and')} ${val.fullName}`;
          } else {
            teamMembersNamesString += `,` + ` ${val.fullName}`;
          }
        });
      }
    }

    return teamMembersNamesString;
  }

  function getDescriptionText(change) {
    const changeType = change?.diff?.type;
    const teamMembersNamesString = getChangingTeamMembersText();
    const isSingleMember = Object.keys(getCurrentElement()?.diff?.profiles || {})?.length === 1;
    switch (changeType) {
      case 'VALUE_CHANGED':
        const langKey = !transparencyAccepted
          ? 'personal_values.changes.change_description_no_transparency'
          : isSingleMember
            ? 'personal_values.changes.change_description_single'
            : 'personal_values.changes.change_description';

        return trans(langKey, {
          teamMembers: teamMembersNamesString,
        });

      case 'DELETED':
        const langKeyDeleted = !transparencyAccepted
          ? 'personal_values.changes.delete_description_no_transparency'
          : isSingleMember
            ? 'personal_values.changes.delete_description_single'
            : 'personal_values.changes.delete_description';

        return trans(langKeyDeleted, {
          teamMembers: teamMembersNamesString,
        });

      case 'NEWLY_ADDED':
        const langKeyAdded = !transparencyAccepted
          ? 'personal_values.changes.add_description_no_transparency'
          : isSingleMember
            ? 'personal_values.changes.add_description_single'
            : 'personal_values.changes.add_description';
        return trans(langKeyAdded, {
          teamMembers: teamMembersNamesString,
        });

      default:
        return null;
    }
  }

  function chipComponent() {
    const currentElement = getCurrentElement();

    switch (currentElement?.diff?.type) {
      case 'VALUE_CHANGED':
        return (
          <div className="flex flex-row items-center w-100 gap-2">
            <div className="flex flex-col items-center w-full">
              {/* <Typography>{trans('base.old')}</Typography> */}
              <TeamValuesChangeChipComponent
                item={currentElement?.diff?.oldValue}
                changeType={currentElement?.diff?.type}
                original
              />
            </div>
            <div className="flex flex-row items-center h-full">
              <IconButton icon={IconArrowForward} />
            </div>
            <div className="flex flex-col items-center w-full">
              {/* <Typography>{trans('base.new')}</Typography> */}
              <TeamValuesChangeChipComponent
                item={currentElement?.diff?.newValue}
                changeType={currentElement?.diff?.type}
              />
            </div>
          </div>
        );

      case 'DELETED':
        return (
          <TeamValuesChangeChipComponent
            changeType={currentElement?.diff?.type}
            item={getCurrentElement()?.diff?.oldValue}
          />
        );

      case 'NEWLY_ADDED':
        return (
          <TeamValuesChangeChipComponent
            changeType={currentElement?.diff?.type}
            item={getCurrentElement()?.diff?.newValue}
          />
        );
    }
  }

  return (
    <Dialog
      size="lg"
      title={getCurrentElement()?.diff?.type ? trans(titleLangPropertiesMap[getCurrentElement()?.diff?.type]) : ''}
      open={open}
      onClose={() => setOpen(false)}
      showCloseButton
      hideButtons
    >
      <Typography>{getDescriptionText(getCurrentElement())}</Typography>

      {chipComponent()}

      <div className="flex flex-row justify-center w-100 gap-1">
        <Button onClick={() => onDiscardChange()}>{trans('base.no')}</Button>

        <Button onClick={() => onConfirmChange()}>{trans('base.yes')}</Button>

        <Button variant="secondary" onClick={() => onLater()}>
          {trans('personal_values.later')}
        </Button>
      </div>

      <div className="flex flex-row justify-around items-center">
        <IconButton onClick={() => getPreviousItem()} icon={IconArrowLeft} disabled={!hasPrevious()} />
        {currentIndex + 1} / {(changedValues || []).length}
        <IconButton onClick={() => getNextItem()} icon={IconArrowRight} disabled={!hasNext()} />
      </div>
    </Dialog>
  );
};

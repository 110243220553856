// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {topPerformersLengthToWidthDict} from '@modules/ContentEngine/components/items/organisation/OrganisationTopPerformersItem';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {TopPerformersCard} from '@modules/Statistics/components/team/TopPerformersCard';
import {_PerformerKey, _TeamPerformerProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  performers: Record<_PerformerKey, _TeamPerformerProfile>;
}

const LIMIT = 5;
export const TeamTopPerformersItem: React.FC<_Props> = ({performers}) => {
  if (!performers || Object.keys(performers).length === 0) {
    return (
      <PlaceholderSection
        title={trans('team_gamification.top_performers_missing.title')}
        content={trans('team_gamification.top_performers_missing.description')}
        image={getDesignImage('point_target.png')}
      />
    );
  }
  return (
    <div className="flex flex-row gap-3 w-full">
      {Object.keys(performers).map(key => {
        return (
          <div
            className={
              topPerformersLengthToWidthDict[
                Object.keys(performers).length > LIMIT ? LIMIT : Object.keys(performers).length
              ]
            }
            key={key}
          >
            <TopPerformersCard performer={performers[key]} key={key} performerKey={key} />
          </div>
        );
      })}
    </div>
  );
};

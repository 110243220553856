// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {getTranslations} from './languageAPIService';

export async function getTranslation(lang: string): Promise<any> {
  return await new Promise(resolve => {
    logger.info(`[i18nAPI] getTranslation: ${lang}`);

    getTranslations(lang)
      .then(response => {
        logger.info('[i18nSlice] getTranslation: ', response);
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch(error => {
        logger.error('[i18nSlice] getTranslation: ', error);
        resolve({});
      });
  });
}

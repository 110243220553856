// @ts-nocheck
/* eslint-disable */

import {httpClient} from '@modules/Core/services/HttpClient';
import {_JournalFilterParams} from '@modules/Profile/types/journal.model';
import {JOURNAL_CATEGORIES, JOURNAL_GROUPS, JOURNALS} from './paths';

let categoriesCache: any = null;

async function getAllCategories(): Promise<any> {
  if (!categoriesCache) {
    categoriesCache = await httpClient.get(JOURNAL_CATEGORIES);
  }
  return categoriesCache;
}

async function getAllJournals(filterParams: _JournalFilterParams, loadingIndicator: boolean = false): Promise<any> {
  return await httpClient.post(JOURNALS, filterParams, loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {});
}

async function getJournalGroups(): Promise<any> {
  return await httpClient.get(JOURNAL_GROUPS);
}

export {getAllCategories, getAllJournals, getJournalGroups};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingMetricValueEntryType} from '@modules/ContentEngine/types/metrics.model';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {_BaseSectionContentProps} from '@modules/Statistics/components/sections/types/sectionContent.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props extends _BaseSectionContentProps {}

export const StatisticSectionContent: React.FC<_Props> = ({value, type}) => {
  const {sum: latestValue} = value ?? {};

  const latestValueExists = latestValue !== undefined && latestValue !== null && !Number.isNaN(latestValue);

  logger.debug('StatisticSectionContent', {value, type, latestValue});
  return (
    <Typography variant="h4">
      {latestValueExists ? <StatisticTypeContent type={type} latestValue={latestValue} /> : '-'}
    </Typography>
  );
};
export const StatisticTypeContent: React.FC<{type: _ProfileTrackingMetricValueEntryType; latestValue: number}> = ({
  type,
  latestValue,
}) => {
  // Helper function to handle number formatting
  const formatNumber = (value: number) => {
    return Number.isInteger(value) ? value : value.toFixed(2);
  };

  switch (type) {
    case 'percentage':
      return <>{formatNumber(latestValue)}%</>;
    case 'currency':
      return <>{formatNumber(latestValue)}€</>;
    case 'time':
      // value is seconds, calculate hours + minutes
      const hours = Math.floor(latestValue / 3600);
      const minutes = Math.floor((latestValue % 3600) / 60);
      return (
        <>
          {trans('dashboard.hours_minutes_format', {
            hours,
            minutes,
          })}
        </>
      );
    default:
      return formatNumber(latestValue);
  }
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useMemo, useState} from 'react';
import {usePathname, useSearchParams} from 'next/navigation';
import {twMerge} from 'tailwind-merge';
import {CTACard} from '@modules/Core/components/base/cards/CTACard';
import {_ProfilePrompt} from '@modules/Core/types/prompts.model';
import {logger} from '@modules/Core/util/Logger';
import {areUrlsEqual} from '@modules/Core/util/strings';
import {isArray} from '@modules/Core/util/typesUtil';
import {createUrl} from '@modules/Navigation/util/navigationUtil';
import {closePromptAction} from '@modules/Profile/util/profileActionsUtil';
import {ProfileExperienceCard} from '@modules/Statistics/components/general/ProfileExperienceCard';

interface _Props {
  prompts: _ProfilePrompt[];
  experiencePoints?: number;
  isDev?: boolean;
  totalXPId?: string;
  promptsId?: string;
}

const LIMIT = 5;
export const SidebarPromptsItem: React.FC<_Props> = ({
  promptsId,
  totalXPId,
  isDev,
  experiencePoints,
  prompts: allPrompts,
}) => {
  const [currentCtaIndex, setCurrentCtaIndex] = useState(0);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [prompts, setPrompts] = useState<_ProfilePrompt[]>([]);

  const filteredPrompts = useMemo(() => {
    return (
      allPrompts
        ?.filter(prompt => {
          if (!prompt?.link) {
            return false;
          }

          const currentUrl = createUrl(pathname, searchParams);
          const linkCurrentRoute = areUrlsEqual(prompt.link, currentUrl);
          if (linkCurrentRoute) {
            return false;
          }

          logger.debug('comparing:', {currentUrl, hideOn: prompt?.data?.hideOn});

          if (prompt?.data?.hideOn && isArray(prompt?.data?.hideOn)) {
            for (const hideOnEntry of prompt?.data?.hideOn) {
              if (!hideOnEntry?.link) {
                continue;
              }
              logger.debug(`comparing: ${currentUrl} === ${hideOnEntry.link}`);
              if (areUrlsEqual(currentUrl, hideOnEntry.link)) {
                return false;
              }
            }
          }
          return true;
        })
        ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        ?.slice(0, LIMIT) || []
    );
  }, [allPrompts, pathname, searchParams]);

  useEffect(() => {
    setPrompts(filteredPrompts);
  }, [filteredPrompts]);

  const hasNext = useMemo(() => !!prompts?.length && currentCtaIndex < prompts.length - 1, [currentCtaIndex, prompts]);
  const hasPrevious = useMemo(() => currentCtaIndex > 0, [currentCtaIndex]);

  const onNextClick = (): void => {
    if (hasNext) {
      setCurrentCtaIndex(prev => prev + 1);
    }
  };

  const onPreviousClick = (): void => {
    if (hasPrevious) {
      setCurrentCtaIndex(prev => prev - 1);
    }
  };

  const onSeen = async () => {
    if (!prompts[currentCtaIndex]?.id) {
      return;
    }
    const success = await closePromptAction(prompts[currentCtaIndex].id);

    if (success) {
      setPrompts(prev => {
        const newPrompts = [...prev];
        newPrompts.splice(currentCtaIndex, 1);
        return newPrompts;
      });
    }
  };

  return (
    <div
      className={twMerge(
        'flex-grow flex w-full flex-col justify-end mb-1 gap-3 bg-white500',
        isDev ? '' : 'sticky bottom-0'
      )}
    >
      {!!prompts.length && (
        <CTACard
          id={promptsId}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          cta={prompts[currentCtaIndex]}
          onSeen={onSeen}
          isDev={isDev}
        />
      )}

      {!isDev && (
        <div id={totalXPId} className="p-2">
          <ProfileExperienceCard fullWidth experiencePoints={experiencePoints} />
        </div>
      )}
    </div>
  );
};

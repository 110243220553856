// @ts-nocheck
/* eslint-disable */

export const topPerformersLangProps: Record<string, {title: string; description: string}> = {
  userActivity: {
    title: 'gamification.team.top_performers.money_saver.title',
    description: 'gamification.team.top_performers.money_saver.description',
  },
  exercisesCompleted: {
    title: 'gamification.team.top_performers.exercise_champion.title',
    description: 'gamification.team.top_performers.exercise_champion.description',
  },
  softfactsCompleted: {
    title: 'gamification.team.top_performers.softfacts_leader.title',
    description: 'gamification.team.top_performers.softfacts_leader.description',
  },
};

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {dispatchReactEvent, reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {PROFILE_EXERCISES_FLOW} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {ExerciseCardAdvanced} from '@modules/Profile/components/exercises/cards/ExerciseCardAdvanced';
import ProfileAddToCalendarDialog from '@modules/Profile/components/exercises/dialogs/ProfileAddToCalendarDialog';
import StartExerciseDialog from '@modules/Profile/components/exercises/dialogs/ProfileExercisesStartDialog';
import UpgradePlanButton from '@modules/Profile/components/payments/UpgradePlanButton';
import {
  _ExerciseData,
  _ExerciseDataEntry,
  _FullExerciseDataEntry,
  _ProfileExercise,
  _ProfileExerciseCE,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {startServiceNextRoundAction} from '@modules/Profile/util/exercisesActionsUtil';
import {createExerciseData} from '@modules/Profile/util/exercisesUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _SingleExerciseRoundProps {
  service: string;
  currentProfileExercises: _ProfileExerciseCE | null;
  currentExerciseData: _FullExerciseDataEntry | null;
  serviceStatus: _ServiceStatus;
}

export const SingleExerciseRound: React.FC<_SingleExerciseRoundProps> = ({
  service,
  currentProfileExercises,
  currentExerciseData,
  serviceStatus,
}) => {
  const navigateToFlow = useNavigateToFlow();

  // Data
  const [profileData, setProfileData] = useState<_ProfileExercise[]>([]);
  const [exerciseData, setExerciseData] = useState<_ExerciseDataEntry | null>(null);

  // Dialogs
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showAddToCalendarDialog, setShowAddToCalendarDialog] = useState<boolean>(false);
  const [collectedData, setCollectedData] = useState<Record<string, any> | null>(null);

  // Exercises data
  const [chosenExerciseIndex, setChosenExerciseIndex] = useState<number>(0);
  const [nextRoundReady, setNextRoundReady] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);

  useEffect(() => {
    setNextRoundReady(!!serviceStatus?.next_round_ready);
  }, [serviceStatus]);

  useEffect(() => {
    if (!currentProfileExercises || !currentExerciseData) {
      return;
    }
    const {currentRound} = currentProfileExercises;
    const currentRoundData = currentProfileExercises?.rounds?.[currentRound];
    const {rounds, exercises} = createExerciseData(service, currentExerciseData, currentProfileExercises);

    const isAvailable = currentRoundData?.available;
    // Lang props and exercises lang props
    const exerciseRoundParams = rounds?.[currentRound];
    // Array of exercises holding the exercise lang props
    const exerciseParams = (exercises as _ExerciseData[][])?.[currentRound];

    // sort round data by position
    currentRoundData?.exercises?.sort((a, b) => a.position - b.position);
    setProfileData(currentRoundData?.exercises || []);
    setExerciseData({
      round: exerciseRoundParams,
      exercises: exerciseParams,
      rounds: rounds ?? [],
    });
    setAvailable(isAvailable);
  }, [currentProfileExercises, currentExerciseData]);

  const onGoToExercise = (): void => {
    if (chosenExerciseIndex === null || chosenExerciseIndex === undefined) {
      return;
    }

    navigateToFlow(PROFILE_EXERCISES_FLOW, {
      round: (profileData ?? [])[chosenExerciseIndex]?.round,
      exerciseIndex: chosenExerciseIndex,
      service: (profileData || [])[chosenExerciseIndex]?.service,
    });
  };

  const onStartNewRound = async (): Promise<void> => {
    if (serviceStatus?.current_round === undefined) {
      return;
    }
    try {
      dispatchReactEvent('navigate.start');
      const result = await startServiceNextRoundAction(service, serviceStatus.current_round + 1);
      if (!result) {
        return;
      }
      reloadContentEngine();
    } catch (e) {
      logger.error('ProfileServiceGraphItem', e);
    }
  };

  const onExerciseClick = (exerciseIndex: number): void => {
    if (exerciseIndex === null || exerciseIndex === undefined) {
      return;
    }

    const profileExercise = (profileData || [])[exerciseIndex];

    // Shouldn't happen
    if ([EXERCISE_STATUS_LOCKED].includes(profileExercise?.status)) {
      return;
    }

    // Started or done, go to it right away.
    if ([EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes(profileExercise?.status)) {
      navigateToFlow(PROFILE_EXERCISES_FLOW, {
        round: profileExercise?.round,
        exerciseIndex,
        service: profileExercise?.service,
      });
    }

    if ([EXERCISE_STATUS_OPEN].includes(profileExercise?.status)) {
      setChosenExerciseIndex(exerciseIndex);
      setShowDialog(true);
      setShowAddToCalendarDialog(false);
    }
  };

  if (!profileData || !exerciseData) {
    return null;
  }

  return (
    <>
      <ProfileAddToCalendarDialog
        open={showAddToCalendarDialog}
        onClose={data => {
          setShowAddToCalendarDialog(false);
          if (data) {
            onGoToExercise();
          }
        }}
        exerciseIndex={chosenExerciseIndex}
        round={profileData?.[chosenExerciseIndex]?.round}
        service={profileData?.[chosenExerciseIndex]?.service}
        collectedData={collectedData}
      />
      {chosenExerciseIndex != null && (
        <StartExerciseDialog
          service={service}
          open={showDialog}
          exerciseData={exerciseData}
          exerciseIndex={chosenExerciseIndex}
          profileData={profileData}
          onClose={data => {
            setShowDialog(false);
            if (data) {
              setCollectedData(data);
              setShowAddToCalendarDialog(true);
            }
          }}
        />
      )}

      <div className="flex justify-between gap-3">
        {((exerciseData.exercises as _ExerciseData[]) || []).map((singleExerciseData, index: number) => (
          <div className="flex w-1/3" key={`single-exercise-round-${index}`}>
            <ExerciseCardAdvanced
              key={singleExerciseData.intro.title}
              card={{
                totalExperience: singleExerciseData.totalExperience,
                type: (profileData || [])[index]?.status,
                available: (profileData || [])[index]?.available,
                content: [
                  {
                    title: `${trans('profile_exercises.round', {
                      roundIndex: profileData?.[index]?.round + 1,
                    })}: ${trans(singleExerciseData.intro.title)}`,
                    translated: true,
                    description: singleExerciseData.intro.goal,
                  },
                ],
                // Only show for open exercises
                action: [EXERCISE_STATUS_OPEN, EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes(
                  (profileData || [])[index]?.status
                )
                  ? {
                      title:
                        (profileData || [])[index]?.available &&
                        (profileData || [])[index]?.status === EXERCISE_STATUS_OPEN
                          ? trans('profile_exercises.select_exercise')
                          : null,
                      trigger: () => {
                        // To go to exercise, it has to be available, if not ( no membership ) then it has to be done.
                        if (
                          (profileData || [])[index]?.available ||
                          [EXERCISE_STATUS_DONE, EXERCISE_STATUS_STARTED].includes((profileData || [])[index]?.status)
                        ) {
                          onExerciseClick(index);
                        }
                      },
                    }
                  : null,
              }}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-end w-full gap-3">
        {(available && Boolean(nextRoundReady)) || !available ? (
          <div className="relative-col-width-2">
            <Button
              onClick={() => {
                navigateToFlow(PROFILE_EXERCISES_FLOW);
              }}
              variant="secondary"
            >
              {trans('profile_exercises.see_all_exercises')}
            </Button>
          </div>
        ) : (
          <div className="relative-col-width-2">
            <Button
              onClick={() => {
                navigateToFlow(PROFILE_EXERCISES_FLOW);
              }}
            >
              {trans('profile_exercises.see_all_exercises')}
            </Button>
          </div>
        )}

        {available ? (
          Boolean(nextRoundReady) && (
            <div className="relative-col-width-2">
              <Button
                onClick={() => {
                  void onStartNewRound();
                }}
              >
                {trans('profile_modules.finalise_cta')}
              </Button>
            </div>
          )
        ) : (
          <UpgradePlanButton source="profile" label={trans('profile_modules.upgrade_dialog.cta')} />
        )}
      </div>
    </>
  );
};

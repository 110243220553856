// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {logger} from '@modules/Core/util/Logger';
import {ProfileWayOfWorkingMultiSelectResult} from '@modules/Profile/components/services/result/wayOfWorking/ProfileWayOfWorkingMultiSelectResult';
import {
  _ProfileWayOfWorkingMultiSelectionResult,
  _ProfileWayOfWorkingQuestionData,
} from '@modules/Profile/types/service.model';

interface _Props {
  data: _ProfileWayOfWorkingMultiSelectionResult;
  questionData: _ProfileWayOfWorkingQuestionData;
}

const channelTypes = ['l_0', 'l_1', 'l_2'];
const origin = 'communication_channels';
export const ProfileWayOfWorkingCommunicationChannelsItem: React.FC<_Props> = ({questionData, data}) => {
  return (
    <div className="flex flex-row w-full gap-1">
      <ProfileWayOfWorkingMultiSelectResult
        questionData={questionData}
        origin={origin}
        data={data}
        channelTypes={channelTypes}
      />
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {createEvent} from 'ics';

export const generateICS = (event: {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  location?: string;
  attendeeEmail?: string;
  attendeeName?: string;
}): string | null => {
  const {value} = createEvent({
    title: event.title,
    description: event.description,
    start: [
      event.startDate.getFullYear(),
      event.startDate.getMonth() + 1,
      event.startDate.getDate(),
      event.startDate.getHours(),
      event.startDate.getMinutes(),
    ],
    end: [
      event.endDate.getFullYear(),
      event.endDate.getMonth() + 1,
      event.endDate.getDate(),
      event.endDate.getHours(),
      event.endDate.getMinutes(),
    ],
    method: 'REQUEST',
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
    organizer: {name: 'soft.fact', email: 'no-reply@softfact.works'},
    attendees: [
      {
        name: event.attendeeName,
        email: event.attendeeEmail,
        rsvp: false,
        partstat: 'ACCEPTED',
        role: 'REQ-PARTICIPANT',
      },
    ],
  });
  return value ?? null;
};

const formatDate = (date: Date): string => {
  const pad = (n: number): string => (n < 10 ? '0' : '') + n;
  return `${date.getUTCFullYear().toString() + pad(date.getUTCMonth() + 1) + pad(date.getUTCDate())}T${pad(
    date.getUTCHours()
  )}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}Z`;
};

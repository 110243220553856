// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Typography} from '@modules/Core/components/base/Typography';

const SliderInput = ({label, min = 0, max = 100, onChange, value: outerValue}) => {
  const [value, setValue] = useState(outerValue);
  const sliderRef = useRef(null);
  const thumbRef = useRef(null);

  const updateSliderValue = clientX => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      let newValue = ((clientX - rect.left) / rect.width) * (max - min) + min;
      newValue = Math.max(min, Math.min(newValue, max));
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = e => {
    updateSliderValue(e.clientX);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleTrackClick = e => {
    updateSliderValue(e.clientX);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  // TODO scaling

  return (
    <div className="flex flex-col gap-1">
      {label && <Typography className="mb-2">{label}</Typography>}
      <div ref={sliderRef} className="w-full h-[4px] bg-white700 relative" onClick={handleTrackClick}>
        <div
          ref={thumbRef}
          className="slider-thumb bg-black700"
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: `${((value - min) / (max - min)) * 100}%`,
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
          }}
          onMouseDown={handleMouseDown}
        />
      </div>
    </div>
  );
};

export default SliderInput;

// @ts-nocheck
/* eslint-disable */

import {Typography} from '@modules/Core/components/base/Typography';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {updateProfilePersonalValues} from '@modules/Profile/services/profileValuesApi';
import {_ProfileValuesEntry} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

export const useProfileValuesApiActions = (
  allItems: _ProfileValuesEntry[],
  selectedItems: _ProfileValuesEntry[],
  originalProfileValues: _ProfileValuesEntry[],
  setIsEditMode: (value: boolean) => void,
  profileId: string
): [() => Promise<void>] => {
  const dialog = useDialog();
  const snackbar = useSnackbar();

  async function updateProfileValues(): Promise<void> {
    // check if any of selectedItems has 0 points

    const selectedItemsWithZeroPoints = selectedItems.filter(item => item.points === 0);

    if (selectedItemsWithZeroPoints?.length === 0) {
      void doUpdateValues();
      return;
    }

    dialog.show({
      title: trans('personal_values.confirm_remove_zero_points_values.title', {
        value: selectedItemsWithZeroPoints[0]?.name,
      }),
      children: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans('personal_values.confirm_remove_zero_points_values.message', {
              value: selectedItemsWithZeroPoints[0]?.name,
            }),
          }}
        />
      ),
      confirmLabel: trans('personal_values.confirm_remove_zero_points_values.confirm_text'),
      cancelLabel: trans('personal_values.confirm_remove_zero_points_values.cancel_text'),

      onConfirm: () => {
        void doUpdateValues();
      },
      showCloseButton: true,
      onClose: () => {},
    });
  }

  async function doUpdateValues(): Promise<void> {
    try {
      const nonZeroSelectedItems = selectedItems.filter(item => (item.points ?? 0) > 0);

      const changedValues = calculateChangedValues();

      const createdValue = getCreatedValue();

      const response = await updateProfilePersonalValues(profileId, nonZeroSelectedItems, createdValue, changedValues);

      // TODO @Sherif
      // setData(response.data?.values);

      // await refreshReduxState();
      reloadContentEngine();

      onSaveConfirmed();
    } catch (e) {
      logger.error('updateProfileValues', e);
      onSaveFailed();
    }
  }

  function calculateChangedValues(): string[] {
    // Check for deleted values
    const deletedValues =
      originalProfileValues?.filter(originalValue => !selectedItems?.find(value => value.id === originalValue.id)) ??
      [];

    // Check for added values
    const addedValues =
      selectedItems?.filter(value => !originalProfileValues?.find(originalValue => originalValue.id === value.id)) ??
      [];

    // Check for updated values

    const updatedValues =
      selectedItems?.filter(value => {
        const originalValue = originalProfileValues?.find(originalValue => originalValue.id === value.id);

        return originalValue && originalValue.points !== value.points;
      }) ?? [];

    // concat
    return deletedValues
      .concat(addedValues)
      .concat(updatedValues)
      .map(value => value.id);
  }

  function getCreatedValue(): _ProfileValuesEntry[] {
    let createdValue = selectedItems.filter(item => !!item.created_by);

    if (createdValue.length === 0) {
      createdValue = allItems.filter(item => !!item.created_by);
    }

    return createdValue;
  }

  function onSaveConfirmed(): void {
    setIsEditMode?.(false);
    snackbar.success(trans('personal_values.save_confirmed.title'));
  }

  function onSaveFailed(): void {
    snackbar.danger(trans('base.error'));
  }

  return [updateProfileValues];
};

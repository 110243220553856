// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {MembersRolesMgmt} from '@modules/Core/components/platform/tables/MembersRolesMgmt';
import {useOrganisationRoleMgmtActions} from '@modules/Core/components/platform/tables/organisationRoleMgmtActions';
import {MANAGE_INST_ROLES_PERMISSION, rolesLangDict} from '@modules/Core/config/rolesConstants';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_InstitutionProfile, _Profile} from '@modules/Profile/types/profile.model';
import {ProfileTeamsTableView} from '@modules/Team/components/common/ProfileTeamsTableView/ProfileTeamsTableView';
import {useTeamMembersProgress} from '@modules/Team/hooks/teamMembersProgress';
import {_Team} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _InstitutionMembersRolesMgmtProps {
  institutionId: _Institution | null;
  orgMembers: Record<string, _Profile>;
  teams: _Team[];
}

export const InstitutionMembersRolesMgmt: React.FC<_InstitutionMembersRolesMgmtProps> = ({
  institutionId,
  orgMembers,
  teams,
}) => {
  const {members} = useTeamMembersProgress(orgMembers);
  const [expandedProfileTeams, setExpandedProfileTeams] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});

  const {actions, chosenProfiles, onClearSelectedProfiles, onProfileSelected} = useOrganisationRoleMgmtActions(
    institutionId,
    members,
    setDialogConfig,
    setShowDialog
  );

  return (
    <MembersRolesMgmt
      profiles={members}
      onProfileSelected={onProfileSelected}
      institutionId={institutionId}
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      dialogConfig={dialogConfig}
      setDialogConfig={setDialogConfig}
      allowSelection={can(MANAGE_INST_ROLES_PERMISSION, {
        institution_id: institutionId,
      })}
      extraColumns={{
        3: {label: trans('organisation.teams'), key: 'teams', width: 'relative-col-width-2'},
      }}
      renderExtraColumns={(profile: _InstitutionProfile) => {
        return {
          teams: (
            <ProfileTeamsTableView
              open={expandedProfileTeams === profile.id}
              onOpen={() => setExpandedProfileTeams(profile.id)}
              onClose={() => setExpandedProfileTeams(null)}
              teams={profile?.teams}
              renderTeam={team => (
                <div className="flex flex-col items-start w-full">
                  <b>{team.name}</b>
                  {trans(rolesLangDict[team?.role])}
                </div>
              )}
            />
          ),
        };
      }}
      headerProps={{
        selectedRowsIds: chosenProfiles,
        onClearSelectedRows: onClearSelectedProfiles,
        selectedRowsLabel: (count: number) =>
          count === 1
            ? trans('subscription.user_selected')
            : trans('subscription.users_selected', {
                count,
              }),
        actions,
        actionsLabel: trans('base.actions'),
        resetSelectionLabel: trans('subscription.reset_selection'),
      }}
    />

    //
    // <MembersRolesMgmt
    //

    // />
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';

export interface _DraggableProps {
  id: string;
  children: React.ReactNode;
  type?: string;
  disabled?: boolean;
  hasDragOverlay?: boolean;
}

export const Draggable: React.FC<_DraggableProps> = ({id, children, type, hasDragOverlay, disabled}) => {
  const {attributes, listeners, setNodeRef, transform, isDragging, active} = useDraggable({
    id,
    disabled,
    data: {type},
  });
  const style = {
    transform: hasDragOverlay ?? !isDragging ? 'unset' : CSS.Translate.toString(transform),
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {pascalCase} from '@modules/Core/util/strings';
import {useSelectState} from '@modules/State/hooks/selectState';
import {_AppState} from '@modules/State/types/appState.model';
import {_FlowSchema} from '../types/core.model';

/**
 * Loads the flow json.
 * This includes parsing the flow schema, loading the steps, and evaluating the flow options.
 * @param flow
 */
export function useLoadFlowJson(flow: string): _FlowSchema | null {
  const flows = useSelectState((state: _AppState) => state.flowJson?.flows) as Record<string, _FlowSchema>;
  const [flowJson, setFlowJson] = useState<_FlowSchema | null>(null);
  const [flowName, setFlowName] = useState<string | null>(flow);

  useEffect(() => {
    setFlowName(pascalCase(flow));
  }, [flow]);

  function doLoadFlowJson(): void {
    logger.info({flows, flowName}, '[useFlowJson] Loading flow...');

    if (!flowName) {
      logger.error('[useFlowJson] Flow name not provided!');
      return;
    }
    if (!flows) {
      logger.error('[useFlowJson] Flows not found in the redux state!');
      // throw new Error(`[useFlowJson] Flows not found in the redux state!`);
    }

    if (!flows[flowName]) {
      logger.error(`[useFlowJson] Flow ${flowName} not found in the redux state!`);
      // throw new Error(`[useFlowJson] Flow ${flowName} not found in the redux state!`);
    }

    setFlowJson(flows[flowName]);
  }

  useEffect(() => {
    if (flowName) {
      doLoadFlowJson();
    } else {
      setFlowJson(null);
    }
  }, [flowName, flows]);

  return flowJson;
}

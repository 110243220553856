// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ImageType, getImageByType} from '@modules/Core/util/uiActionUtil';

interface _Props {
  label?: number;
  image?: string;
  imageType: _ImageType;
  show?: boolean;
}

export const MetricPeriodReportItem: React.FC<_Props> = ({show, label, image, imageType}) => {
  if (!label) return null;
  const IconComponent = getImageByType(image, imageType, 3);
  if (!show) return null;
  return (
    <div className="flex flex-row items-center gap-1">
      <Typography variant="body">{label}</Typography>
      {IconComponent && IconComponent}
    </div>
  );
};

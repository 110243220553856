// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SHARED_WITH_YOU} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {formatDate} from '@modules/Core/util/dates';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {ThirdPersonFeedbackPlaceholder} from '@modules/Profile/components/services/thirdPersonFeedback/ThirdPersonFeedbackPlaceholder';
import {thirdPersonFeedbackReminder} from '@modules/Profile/services/profileApi';
import {
  _ThirdPersonFeedbackForSelf,
  _ThirdPersonFeedbackInvite,
} from '@modules/Profile/types/third-person-feedback.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ThirdPersonFeedbackTableProps {
  data: _ThirdPersonFeedbackForSelf[];
}

export const ThirdPersonFeedbackForYouTable: React.FC<_ThirdPersonFeedbackTableProps> = ({data}) => {
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();

  const columns: _TableColumns = [
    {label: trans('profile.third_person_feedback.sent_to_header'), key: 'name', width: 'relative-col-width-4-plus-gap'},
    {label: trans('base.date'), key: 'created_at', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.status'), key: 'status', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.action'), key: 'action', width: 'relative-col-width-2'},
  ];

  const getRowActions = (row: _ThirdPersonFeedbackForSelf): _UIAction[] => {
    let atLeastOneFeedbackDone = false;
    const actions: _UIAction[] = [];

    // loop on row invites array , showResult shown only if at least one feedback is done
    row?.invites?.forEach(invite => {
      if (invite.status === 'DONE') {
        atLeastOneFeedbackDone = true;
      }
    });

    if (atLeastOneFeedbackDone) {
      actions.push({
        label: trans('profile.third_person_feedback.see_feedback_btn'),
        onClick: () => {
          onShowResults(row);
        },
        type: 'button',
      });
    }

    actions.push({
      label: trans('profile.third_person_feedback.invite_more'),
      onClick: () => {
        onInviteMore(row);
      },
      type: 'button',
    });

    return actions;
  };

  const renderRow = (row: _ThirdPersonFeedbackInvite): _TableDataItem => ({
    name: <AvatarUser label={row.profile?.fullName} image={getProfilePic(row.profile)} />,
    created_at: <Typography>{formatDate(new Date(row.created_at))}</Typography>,
    status: trans(`profile.third_person_feedback.status.${row.status}`),
    action:
      row.status === 'DONE' ? (
        <></>
      ) : (
        <TextLink
          label={trans('profile.third_person_feedback.send_reminder')}
          onClick={async () => await sendReminder(row.id)}
        />
      ),
  });

  const onShowResults = (row: _ThirdPersonFeedbackForSelf): void => {
    navigateToFlow(FLOWS.ThirdPersonFeedback, {
      feedbackId: row.id,
      variant: SHARED_WITH_YOU,
      result: true,
    });
  };

  const onInviteMore = (row: _ThirdPersonFeedbackForSelf): void => {
    navigateToFlow(FLOWS.ThirdPersonFeedback, {feedbackId: row.id, includeInvite: true});
  };

  async function sendReminder(inviteId: string) {
    const response = await thirdPersonFeedbackReminder(inviteId);

    if (response.status === 200 && response.data) {
      snackbar.success(response.data.success.title);
    } else {
      snackbar.danger(response?.data?.error);
    }
  }

  if (!data?.length) {
    return <ThirdPersonFeedbackPlaceholder type={SHARED_WITH_YOU} />;
  }

  return (
    <>
      {data?.map(item => (
        <Section title={item.name} actions={getRowActions(item)}>
          <Table
            data={item.invites}
            columns={columns}
            showTopBorder
            renderRow={renderRow}
            filterOptions={{
              enabled: true,
              searchAttributes: ['profile.fullName', 'created_at', 'type'],
            }}
          />
        </Section>
      ))}
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

export const TEAM_OWNER_ROLE = 'Team-Owner';
export const TEAM_ADMIN_ROLE = 'Team-Admin';
export const TEAM_MEMBER_ROLE = 'Team-Member';

export const INSTITUTION_OWNER_ROLE = 'Org-Owner';
export const INSTITUTION_ADMIN_ROLE = 'Org-Admin';
export const INSTITUTION_MEMBER_ROLE = 'Org-Member';

export const SUPER_ADMIN_ROLE = 'Super-Admin';
export const MARKETING_ADMIN_ROLE = 'Marketing-Admin';
export const SALES_ADMIN_ROLE = 'Sales-Admin';

export const INSTITUTION_COACH_ROLE = 'Org-Coach';
export const TEAM_COACH_ROLE = 'Team-Coach';
export type _RoleName =
  | typeof TEAM_OWNER_ROLE
  | typeof TEAM_ADMIN_ROLE
  | typeof TEAM_MEMBER_ROLE
  | typeof INSTITUTION_OWNER_ROLE
  | typeof INSTITUTION_ADMIN_ROLE
  | typeof INSTITUTION_MEMBER_ROLE
  | typeof SUPER_ADMIN_ROLE
  | typeof MARKETING_ADMIN_ROLE
  | typeof SALES_ADMIN_ROLE
  | typeof INSTITUTION_COACH_ROLE
  | typeof TEAM_COACH_ROLE;

export interface _Role {
  id: string;
  name: _RoleName;
  pivot: {
    role_id: string;
    team_id: string;
    model_uuid: string;
    institution_id: string;
    [key: string]: any;
  };
}

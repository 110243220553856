// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {Slider} from '@modules/Core/components/base/Slider';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {ProfileResultCriteriaDevMode} from '@modules/Dev/components/ProfileResultCriteriaDevMode';
import {wayOfWorkingLangProps} from '@modules/Profile/config/servicesLangProps';
import {_ProfileWayOfWorkingSectionResult} from '@modules/Profile/types/service.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: _ProfileWayOfWorkingSectionResult;
  profilePicture: string;
}

const order = ['leading', 'deciding', 'disagreeing', 'planning'];

const MAX_SCORE = 10;
const lowRange = 3.34;
const midRange = 6.68;
const highRange = 10;

export const ProfileWayOfWorkingDimensionsItem: React.FC<_Props> = ({data, profilePicture}) => {
  logger.debug('ProfileWayOfWorkingDimensionsItem', data);
  const [resultData, setResultData] = useState<_ProfileWayOfWorkingSectionResult>({});

  useEffect(() => {
    setResultData(data);
  }, [data]);
  return (
    <Section gap={5}>
      {Object.keys(resultData ?? {})
        .filter(key => resultData[key].value !== null)
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map((key, index) => {
          const score = resultData[key].value;

          let reflection;
          let description;
          if (score <= lowRange) {
            reflection = trans(wayOfWorkingLangProps[key].first_facet_reflection);
            description = trans(wayOfWorkingLangProps[key].first_facet_description);
          } else if (score <= midRange) {
            reflection = trans(wayOfWorkingLangProps[key].equal_facet_reflection);
            description = trans(wayOfWorkingLangProps[key].equal_facet_description);
          } else if (score <= highRange) {
            reflection = trans(wayOfWorkingLangProps[key].second_facet_reflection);
            description = trans(wayOfWorkingLangProps[key].second_facet_description);
          }

          return (
            <>
              <ProfileResultCriteriaDevMode
                profileData={resultData[key]}
                setProfileData={value => {
                  setResultData({
                    ...resultData,
                    [key]: {
                      ...resultData[key],
                      value,
                    },
                  });
                }}
              />
              <Slider
                key={key}
                title={trans(wayOfWorkingLangProps[key].title)}
                leftText={trans(wayOfWorkingLangProps[key].first_facet_label)}
                rightText={trans(wayOfWorkingLangProps[key].second_facet_label)}
                leftTooltip={trans(wayOfWorkingLangProps[key].first_facet_tooltip)}
                rightTooltip={trans(wayOfWorkingLangProps[key].second_facet_tooltip)}
                leftIcon={IconInfo}
                rightIcon={IconInfo}
                bottomSubtitle={reflection}
                bottomTitle={trans('way_of_working.profile_recommendation')}
                subtitle={description}
                enableEndicator
                components={[
                  {
                    id: key,
                    progress: (score / MAX_SCORE) * 100,
                    component: <Avatar size="lg" image={profilePicture ?? getProfilePic(null)} indicator="success" />,
                  },
                ]}
                showExpand
              />
            </>
          );
        })}
    </Section>
  );
};

// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Section} from '@modules/Core/components/layout/Section';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {trans} from '@modules/Translations/util/i18n';

interface _CreateTeamItemProps {
  teamName?: string;
}

export const TeamInfoItem: React.FC<_CreateTeamItemProps> = ({teamName: originalTeamName}) => {
  const [teamName, setTeamName] = useState<string>(originalTeamName ?? '');
  const [emails, setEmails] = useState<string[]>([]);

  return (
    <>
      <Section title={trans('organisation.set_organisation_name')}>
        <Input
          onChange={value => setTeamName(value)}
          value={teamName}
          placeholder={trans('organisation.placeholder_new_organisation')}
          label={trans('organisation.label_teamname')}
        />
      </Section>

      <Section title={trans('project.invite_team')} description={trans('invite.email_description')}>
        <Input
          onChange={value => setTeamName(value)}
          value={teamName}
          placeholder={trans('base.email_placeholder')}
          label={trans('base.email_label')}
        />

        <Emails
          emails={emails}
          setEmails={setEmails}
          placeholder={trans('base.email_placeholder')}
          label={trans('base.email_label')}
          consentLabel={trans('consents.invite_team')}
        />
      </Section>
    </>
  );
};

// @ts-nocheck
/* eslint-disable */

import authTokenInterceptor from './authTokenInterceptor';
import languageEnricherInterceptor from './languageEnricherInterceptor';
import loaderInterceptor from './loaderInterceptor';

const interceptors = [authTokenInterceptor, loaderInterceptor, languageEnricherInterceptor];

export default interceptors;

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_TeamValues, _TeamValuesChanges} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

const ACCEPT_NEW = 'ACCEPT_NEW';
const ACCEPT_OLD = 'ACCEPT_OLD';
export const useTeamValuesChanges = (
  teamId: string,
  data: _TeamValues,
  open: boolean,
  setOpen: (open: boolean) => void
): {
  currentIndex: number;
  changedValues: _TeamValuesChanges[];
  getCurrentElement: () => _TeamValuesChanges;
  getNextItem: () => void;
  getPreviousItem: () => void;
  onLater: () => void;
  onDiscardChange: () => void;
  onConfirmChange: () => void;
  hasNext: () => boolean;
  hasPrevious: () => boolean;
} => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [changedValues, setChangedValues] = useState<_TeamValuesChanges[]>([]);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (Object.keys(data?.diff || {}).length === 0) {
      setChangedValues([]);
      return;
    }

    setChangedValues(
      Object.entries(data?.diff).map(([key, value]) => ({
        key,
        diff: value?.diff,
      }))
    );
  }, [data]);

  useEffect(() => {
    if (changedValues.length === 0) {
      return;
    }
    if (currentIndex > changedValues.length - 1) {
      setCurrentIndex(changedValues.length - 1);
      return;
    }

    if (currentIndex < 0) {
      setCurrentIndex(0);
    }
  }, [changedValues, currentIndex]);

  async function onPerformChange(itemId: string, action: string, callbackFn = null) {
    const method = 'team.personal-values';

    const params = {
      teamId,
      key: itemId,
      action,
    };

    try {
      await performFlowAction(
        {
          method,
          params,
        },
        {
          refetchProfile: true,
          loadingIndicator: true,
        }
      );

      // remove the item from the list
      const newChangedValues = changedValues.filter(item => item.key !== itemId);

      setChangedValues(newChangedValues);

      reloadContentEngine();

      // callbackFn && callbackFn()
    } catch (e) {
      snackbar.danger(trans('base.error'));
    }
  }

  function next(): void {
    if (currentIndex < changedValues.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  function previous(): void {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }

  function onLater(): void {
    if (!hasNext()) {
      setCurrentIndex(0);
      setOpen(false);
      return;
    }

    if (!hasPrevious()) {
      next();
      return;
    }

    next();
  }

  function onDiscardChange(): void {
    void onPerformChange(getCurrentElement().key, ACCEPT_OLD, next);
  }

  function onConfirmChange(): void {
    void onPerformChange(getCurrentElement().key, ACCEPT_NEW, next);
  }

  function getCurrentElement(): _TeamValuesChanges {
    return changedValues[currentIndex];
  }

  function getNextItem(): void {
    next();
  }

  function getPreviousItem(): void {
    previous();
  }

  function hasNext(): boolean {
    return currentIndex < changedValues.length - 1;
  }

  function hasPrevious(): boolean {
    return currentIndex > 0;
  }

  return {
    currentIndex,
    changedValues,
    getCurrentElement,
    getNextItem,
    getPreviousItem,
    onLater,
    onDiscardChange,
    onConfirmChange,
    hasNext,
    hasPrevious,
  };
};

// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';

export function useTeamDevModuleData(data: any) {
  const [subModules, setSubModules] = useState({});
  const [progressData, setProgressData] = useState({});

  useEffect(() => {
    if (!data) {
      return;
    }

    setProgressData(data?.subModules?.progress);

    // Add "key" to each subModule dict entry
    const subModulesDict = data?.subModules?.modulesData;

    Object.entries(subModulesDict ?? {}).forEach(([key, value]) => {
      subModulesDict[key].key = key;

      if (subModulesDict[key].subSubModules) {
        const subSubModulesDict = subModulesDict[key].subSubModules;
        Object.entries(subSubModulesDict ?? {}).forEach(([subKey, value]) => {
          subSubModulesDict[subKey].key = subKey;
        });
      }
    });

    // Add "key" to each subModule dict entry
    setSubModules(subModulesDict);
  }, [data]);

  return {
    subModules,
    progressData,
  };
}

// @ts-nocheck
/* eslint-disable */

export function goToCoachingSession(): void {
  const link = 'https://meet.softfact.works/meetings/kristina-pueschel/15min';
  window.open(link, '_blank');
}

export function goToMembershipCoachingSession(): void {
  const link = 'https://meetings-eu1.hubspot.com/johannes-faust';
  window.open(link, '_blank');
}

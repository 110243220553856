// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {RoleCard} from '@modules/Core/components/base/cards/RoleCard';
import {profileRolesLangProps} from '@modules/Profile/config/servicesLangProps';
import {_ProfileRoles} from '@modules/Profile/types/service.model';
import {getProfileRoleIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: _ProfileRoles;
  rolesType: 'social' | 'antiSocial';
}

export const socialOrder = [
  'critic',
  'social',
  'listener',
  'evaluator',
  'coordinator',
  'team_leader',
  'problem_solver',
  'task_completer',
  'task_motivator',
  'teamwork_support',
];

export const antiSocialOrder = ['passive', 'attention_seeker', 'power_seeker'];

export const ProfileRolesItem: React.FC<_Props> = ({data, rolesType}) => {
  const order = rolesType === 'antiSocial' ? antiSocialOrder : socialOrder;

  return (
    <div className="flex flex-row w-full justify-between flex-wrap">
      {Object.keys(data || {})
        .filter(key => data[key].value !== null && order.includes(key))
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
        .map((key, index) => {
          const Icon = getProfileRoleIcon(key);

          return (
            <div className="flex flex-col relative-col-width-5 mb-3">
              <RoleCard
                icon={Icon}
                score={data[key].value}
                description={trans(profileRolesLangProps[key].text)}
                title={trans(profileRolesLangProps[key].title)}
                showExpander
                expandedContents={[
                  {
                    description: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: trans(profileRolesLangProps[key].description),
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          );
        })}
    </div>
  );
};

// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useStep} from '../../hooks/step';
import {_BaseStepComponentOptions} from '../../types/step.model';

interface _Props {
  options: _BaseStepComponentOptions;
  children: any;
  getValueCallbackFn?: () => any;
  setValuesCallbackFn?: (values: any) => void;
  inputs?: any[];
}

export const BaseStep: React.FC<_Props> = ({options, children, getValueCallbackFn, setValuesCallbackFn, inputs}) => {
  useStep(options, options.instanceKey, getValueCallbackFn, setValuesCallbackFn, inputs);

  return children;
};

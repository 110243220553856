// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {DevModeSection} from './DevModeSection';

interface _ProfileDevModeSectionProps {
  isDevMode: boolean;
  setIsDevMode: (isDevMode: boolean) => void;
  children: React.ReactNode;
}

export const ProfileDevModeSection: React.FC<_ProfileDevModeSectionProps> = ({isDevMode, setIsDevMode, children}) => (
  <DevModeSection>
    <div className="flex flex-col">
      <div className="flex items-center justify-start mt-4 mb-4 space-x-2">
        <CheckBox onChange={e => setIsDevMode(e)} active={isDevMode} label="Is Dev Mode" />
      </div>
      {isDevMode && children}
    </div>
  </DevModeSection>
);

// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../../Core/services/HttpClient';
import {NOTIFICATIONS_READ} from '../../Core/services/paths';

async function setNotificationsRead(ids: string[]): Promise<any> {
  return await httpClient.post(NOTIFICATIONS_READ, {
    ids,
  });
}

export {setNotificationsRead};

// @ts-nocheck
/* eslint-disable */

import {isString} from './typesUtil';

export function getHours(date = new Date()): number {
  return date.getHours();
}

export function getMinutes(date = new Date()): string {
  // get minutes as 2-digit string
  return date.getMinutes().toString().padStart(2, '0');
}

export function getSeconds(date = new Date()): number {
  return date.getSeconds();
}

export function getMilliseconds(date = new Date()): number {
  return date.getMilliseconds();
}

export function getDate(date = new Date()): number {
  return date.getDate();
}

export function getMonth(date = new Date()): number {
  return date.getMonth();
}

export function getYear(date = new Date()): number {
  return date.getFullYear();
}

export function formatTime(date = new Date(), includeSeconds = false, includeMinutes = true): string {
  const hours = getHours(date);
  const minutes = getMinutes(date);
  const seconds = getSeconds(date);
  if (includeSeconds) return `${hours}:${minutes}:${seconds}`;
  if (includeMinutes) return `${hours}:${minutes}`;
  return `${hours}`;
}

export function formatDate(date = new Date()): string {
  return `${getDate(date).toString().padStart(2, '0')}.${(getMonth(date) + 1).toString().padStart(2, '0')}.${getYear(
    date
  )
    .toString()
    .slice(-2)}`;
}

export function formatDateTime(date = new Date()): string {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function getDateFromUnixTimestamp(timestamp: number | string): Date {
  if (isString(timestamp)) timestamp = parseInt(timestamp as string, 10);
  return new Date((timestamp as number) * 1000);
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {_SoftfactionaryItem} from '@modules/ContentEngine/types/softfactionaryItem.model';
import {SearchInput} from '@modules/Core/components/base/SearchInput';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import SoftfactionaryEntry from '@modules/Core/components/platform/softfactionary/SoftfactionaryEntry';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {getSoftfactionary} from '@modules/Core/services/api/softfactionary';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {highlightSearchText} from '@modules/Core/util/softfactionaryUtil';
import {trans} from '@modules/Translations/util/i18n';

export const SoftfactionaryItem: React.FC = () => {
  const [entries, setEntries] = useState<_SoftfactionaryItem[]>([]);
  const [filteredEntries, setFilteredEntries] = useState<_SoftfactionaryItem[]>([]);
  const ref = useRef<HTMLInputElement>(null);

  // Listen to ctrl+f to focus the search input
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const isCtrlFPress = (e.ctrlKey || e.metaKey) && e.key === 'f';
      if (isCtrlFPress) {
        e.preventDefault();
        ref.current?.focus();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffectAsync(async () => {
    try {
      const response = await getSoftfactionary();
      dispatchReactEvent('navigate.done');
      const softfacts = response?.data?.softfactionary;
      setEntries(softfacts ?? []);
      setFilteredEntries(softfacts ?? []);
    } catch (err) {
      logger.error(err);
    }
  }, []);

  const onSearch = (searchString: string, filteredEntries: _SoftfactionaryItem[]) => {
    const newFilteredEntries = filteredEntries.map(entry => {
      const highlightedTitle = highlightSearchText(entry.title, searchString);
      const highlightedDescription = highlightSearchText(entry.description, searchString);
      return {...entry, description: highlightedDescription};
    });
    setFilteredEntries(newFilteredEntries);
  };
  return (
    <div className="flex flex-col gap-5">
      <Typography>
        {trans('softfactionary.label')} {trans('softfactionary.text')}
      </Typography>
      <Section>
        <SearchInput
          inputRef={ref}
          onSearch={onSearch}
          entries={entries}
          setFilteredEntries={setFilteredEntries}
          searchAttributes={['title', 'description']}
          searchOptions={{
            isCaseSensitive: false,
            shouldSort: true,
          }}
        />
        {filteredEntries?.map((entry, index) => (
          <SoftfactionaryEntry key={`${entry.title}-${index}`} title={entry.title} description={entry.description} />
        ))}
      </Section>
    </div>
  );
};

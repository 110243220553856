// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef} from 'react';

/**
 * Unlike useEffect that fires on initial render, this hook fires only on updates after that initial render
 * @param fn
 * @param inputs
 */
export function useDidUpdateEffect(fn: () => any, inputs: any[]): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
}

// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {ProfileOldServiceChallengesGoalsReady} from '@modules/ContentEngine/components/items/profile/result/common/ProfileOldServiceChallengesGoalsReady';
import {ProfileMotivesGroupCard} from '@modules/ContentEngine/components/items/profile/result/msa/ProfileMotivesGroupCard';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {ProfileMotivesDevMode} from '@modules/Dev/components/ProfileMotivesDevMode';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {_ProfileMotivesGroup, _ProfileMotivesGroupResult} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';
import {ProfileMotivesChart} from './ProfileMotivesChart';

interface _Props {
  mainMotives: _ProfileMotivesGroupResult[];
  // All groups
  motiveGroups: Record<_ProfileMotivesGroup, _ProfileMotivesGroupResult[]>;
  profileId: string;
  serviceStatus: _ServiceStatus;
  profileExercises: _ProfileExerciseCE;
  view: 'shared' | 'result';
  tutorialKey: string;
  tutorialService: string;
  context: _ContentEngineContext;
}

export type _ProfileMotiveTab = 'main_motives' | 'future' | 'social' | 'self' | 'morale';

/*
  'bg-white500 hover:bg-grey100 disabled:bg-grey500',
  'border-grey300 data-active:border-blue700 disabled:border-grey300',
*/

const groupsLangKeys: Record<_ProfileMotiveTab, Record<string, string>> = {
  main_motives: {
    title: 'motive_structure.main_motives',
    description: 'motive_structure.main_intro',
    tabTitle: 'motive_structure.main_motives',
  },
  future: {
    title: 'motive_structure.future',
    description: 'motive_structure.future_intro',
    tabTitle: 'motive_structure.future_short',
    className: 'border-[#F3C677]', // hover:bg-sunrise100 data-active:border-sunrise500',
    // iconColor: 'fill-sunrise700',
  },
  self: {
    title: 'motive_structure.self',
    description: 'motive_structure.self_intro',
    tabTitle: 'motive_structure.self_short',
    className: 'border-[#F89377]', // hover:bg-blue100 data-active:border-blue500',
    // iconColor: 'fill-blue700',
  },
  morale: {
    title: 'motive_structure.morale',
    description: 'motive_structure.morale_intro',
    tabTitle: 'motive_structure.morale_short',
    className: 'border-[#7B1E7A]', // hover:bg-green100 data-active:border-green500',
    // iconColor: 'fill-green700',
  },
  social: {
    title: 'motive_structure.social',
    description: 'motive_structure.social_intro',
    tabTitle: 'motive_structure.social_short',
    className: 'border-[#A8DADC]', // hover:bg-teal100 data-active:border-teal500',
    // iconColor: 'fill-teal700',
  },
};

const motivesOrder = ['future', 'self', 'morale', 'social'];
const motiveGroupsOrder = [
  'career',
  'development',
  'selfrealization',
  'selfoptimization',
  'fun',
  'power',
  'achievement',
  'independance',
  'fairness',
  'idealism',
  'responsibility',
  'goalinternalization',
  'status',
  'security',
  'belonging',
  'acceptance',
];
export const ProfileMotivesItem: React.FC<_Props> = ({
  profileId,
  mainMotives,
  motiveGroups,
  serviceStatus,
  profileExercises,
  view,
  tutorialService,
  tutorialKey,
  context,
}) => {
  const [activeGroup, setActiveGroup] = useState<_ProfileMotiveTab | null>('main_motives');
  const [mainMotivesKeys] = mainMotives.map(motive => motive.title);
  const [graphData, setGraphData] = useState<number[]>([]);
  const [isDevMode, setIsDevMode] = useState<boolean>(false);
  const [showMainMotivesDevDetails, setShowMainMotivesDevDetails] = useState<boolean>(false);

  useEffect(() => {
    const data = [];
    if (motiveGroups) {
      const sortedMotiveGroups = Object.values(motiveGroups)
        .flat()
        .sort((a, b) => motiveGroupsOrder.indexOf(a.title) - motiveGroupsOrder.indexOf(b.title));
      for (const motiveGroup of sortedMotiveGroups) {
        // no decimal
        data.push(Math.round(motiveGroup.value));
      }
    }

    setGraphData(data);
  }, [motiveGroups]);

  const isSharedView = view === 'shared';
  logger.debug('ProfileMotivesItem', {mainMotives, motiveGroups, graphData});

  return (
    <Section>
      <Section
        title={trans('profile_modules.services.motive_structure.title')}
        description={trans('profile_modules.services.motive_structure.description')}
        journalKey={isSharedView ? null : 'soft_facts.motive_structure'}
        descriptionVariant="body1"
        tutorialProps={{
          tutorialKey,
          tutorials: context.tutorials,
          id: getTutorialTriggerId(tutorialService),
        }}
      >
        <ProfileFeedbackItem
          dialogTitle={trans('profile_result.feedback.dialog_title')}
          dialogDescription={trans('profile_result.feedback.dialog_description')}
          dialogConfirmText={trans('profile_result.feedback.dialog_confirm_text')}
          feedbackKey="softfacts:motive_structure:result"
        />

        {!isSharedView && (
          <ProfileOldServiceChallengesGoalsReady serviceStatus={serviceStatus} profileExercises={profileExercises} />
        )}
      </Section>

      <Section
        title={trans('motive_structure.motive_overview')}
        description={trans('motive_structure.overview_intro')}
        descriptionVariant="body1"
      >
        <ProfileMotivesChart width={1000} height={800} data={graphData} />
      </Section>

      <ProfileMotivesDevMode
        isDevMode={isDevMode}
        setIsDevMode={setIsDevMode}
        motivesResult={showMainMotivesDevDetails}
        setMotivesResult={setShowMainMotivesDevDetails}
      />
      <Tabs
        tabs={Object.keys(groupsLangKeys)
          .sort((a, b) => motivesOrder.indexOf(a) - motivesOrder.indexOf(b))
          .map(key => ({
            key,
            displayValue: trans(groupsLangKeys[key].tabTitle),
            // icon: activeGroup === key ? IconExpandLess : IconExpandMore,
            // iconPosition: 'right',
            iconColor: groupsLangKeys[key].iconColor,
            className: groupsLangKeys[key].className,
          }))}
        activeTab={activeGroup}
        // setActiveTab={(group: _ProfileMotiveTab) => setActiveGroup(group !== activeGroup ? group : null)}
        setActiveTab={(group: _ProfileMotiveTab) => setActiveGroup(group)}
      />

      {activeGroup && (
        <Section
          title={trans(groupsLangKeys[activeGroup].title)}
          description={trans(groupsLangKeys[activeGroup].description)}
          descriptionVariant="body1"
        />
      )}

      {activeGroup === 'main_motives' && (
        <div className="flex flex-row w-full gap-3 flex-wrap">
          {mainMotives
            .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0))
            .map(group => (
              <div className="flex flex-col relative-col-width-5">
                <ProfileMotivesGroupCard isMain mainView motivesGroup={group} key={group.title} />
              </div>
            ))}
        </div>
      )}
      {Boolean(activeGroup !== 'main_motives' && !!activeGroup) && (
        <div className="flex flex-row w-full gap-3 flex-wrap">
          {motiveGroups[activeGroup]
            .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0))
            .map(group => (
              <div className="flex flex-col relative-col-width-5">
                <ProfileMotivesGroupCard
                  isMain={mainMotivesKeys?.includes(group.title) || (showMainMotivesDevDetails && isDevMode)}
                  mainView={showMainMotivesDevDetails && isDevMode}
                  motivesGroup={group}
                  key={group.title}
                />
              </div>
            ))}
        </div>
      )}
    </Section>
  );
};

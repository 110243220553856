// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconStarEmpty, IconStarFilled} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {
  _ExerciseData,
  _ExerciseDataEntry,
  _ProfileExercise,
  EXERCISE_STATUS_DONE,
} from '@modules/Profile/types/exercises.model';
import {submitExerciseDataAction} from '@modules/Profile/util/exercisesActionsUtil';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {refreshReduxState} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

interface _ExerciseFeedbackDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  exerciseIndex: number | null;
  roundIndex: number;
  profileData: _ProfileExercise[];
  service: string;
  exerciseData: _ExerciseDataEntry | null;
}

const ExerciseFeedbackDialog: React.FC<_ExerciseFeedbackDialogProps> = ({
  open,
  onClose,
  exerciseIndex,
  roundIndex,
  profileData,
  service,
  exerciseData,
}) => {
  const snackbar = useSnackbar();

  const [currentExercise, setCurrentExercise] = useState<_ProfileExercise | null>(null);
  const [openExercises, setOpenExercises] = useState<_ProfileExercise[]>([]);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [feedbackStars, setFeedbackStars] = useState<number | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  // useEffect(() => {
  //   if (!open) {
  //     return;
  //   }
  //
  //   // If exercise is done, show popup
  //   // 2 variations possible, either this is NOT the last exercise done in the round => feedback popup
  //   // or this is the last exercise done in the round => Do survey again popup
  //   if (currentExercise?.status === EXERCISE_STATUS_DONE) {
  //     setFeedback(currentExercise?.data?.feedback);
  //     setFeedbackStars(currentExercise?.data?.feedbackStars);
  //   }
  // }, [currentExercise?.status, openExercises]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!profileData || exerciseIndex === null) {
      return;
    }
    setCurrentExercise(profileData?.[exerciseIndex]);
    const notDoneExercises = profileData?.filter(exercise => exercise?.status !== EXERCISE_STATUS_DONE);
    setOpenExercises(notDoneExercises);
  }, [profileData, exerciseIndex]);

  const onSetFeedback = (feedback: string): void => {
    setFeedback(feedback);
    setDisabled(false);
  };

  const onSetFeedbackStars = (feedbackStars: number): void => {
    setFeedbackStars(feedbackStars);
    setDisabled(false);
  };

  const handleConfirmation = async (): Promise<void> => {
    if (!service || !profileData || exerciseIndex === null) {
      return;
    }
    const feedbackData = {
      feedback,
      feedbackStars,
    };

    await submitExerciseDataAction(profileData?.[exerciseIndex]?.service, roundIndex, exerciseIndex, feedbackData);

    // void refreshReduxState();
    reloadContentEngine();
    snackbar.success(trans('profile_exercises.dialogs.feedback.success'));
    onClose(true);
  };

  const resetState = (): void => {
    setFeedback(undefined);
    setFeedbackStars(undefined);
    setCurrentExercise(null);
    setOpenExercises([]);
    setDisabled(true);
  };

  if (exerciseIndex === null) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      title={trans('profile_exercises.dialogs.feedback.title')}
      onConfirm={handleConfirmation}
      confirmLabel={trans('profile_exercises.dialogs.feedback.cta')}
      confirmProps={{
        disabled,
      }}
      showCloseButton
    >
      <FeedbackContent
        feedback={feedback}
        setFeedback={onSetFeedback}
        feedbackStars={feedbackStars}
        setFeedbackStars={onSetFeedbackStars}
        service={service}
        exerciseData={exerciseData?.exercises?.[exerciseIndex]}
      />
    </Dialog>
  );
};

interface _FeedbackContentProps {
  service: string;
  exerciseData: _ExerciseData;
  feedback: string | undefined;
  setFeedback: (feedback: string) => void;
  feedbackStars: number | undefined;
  setFeedbackStars: (feedbackStars: number) => void;
}

const FeedbackContent: React.FC<_FeedbackContentProps> = ({
  service,
  exerciseData,
  feedback,
  setFeedback,
  feedbackStars,
  setFeedbackStars,
}) => {
  const Icon = getServiceIcon(service);

  return (
    <Section gap={5}>
      <Section>
        <div className="flex w-100 flex-row items-center icon">
          {Icon && <Icon />}
          <Typography variant="body1" style={{textAlign: 'center', marginLeft: '8px'}}>
            {trans(PROFILE_SERVICES_TITLES[service])}
          </Typography>
        </div>

        <Typography variant="h6">{trans(exerciseData?.intro?.title)}</Typography>

        <Typography variant="body1">{trans('profile_exercises.dialogs.feedback.how_it_went')}</Typography>
        <Input value={feedback} onChange={setFeedback} />
      </Section>
      <Section>
        <Typography variant="body1">{trans('profile_exercises.dialogs.feedback.how_much_learned')}</Typography>
        <FeedbackStars feedbackStars={feedbackStars} setFeedbackStars={setFeedbackStars} />
      </Section>
    </Section>
  );
};

interface _FeedbackStars {
  feedbackStars: number | undefined;
  setFeedbackStars: (feedbackStars: number) => void;
}

const FeedbackStars: React.FC<_FeedbackStars> = ({feedbackStars, setFeedbackStars}) => {
  return (
    <div className="flex flex-row gap-3">
      {[1, 2, 3, 4, 5].map(star =>
        (feedbackStars ?? 0) >= star ? (
          <IconStarFilled
            size="lg"
            color="fill-brand700"
            onClick={() => {
              setFeedbackStars(star);
            }}
            key={star}
          />
        ) : (
          <IconStarEmpty
            size="lg"
            color="fill-brand700"
            onClick={() => {
              setFeedbackStars(star);
            }}
            key={star}
          />
        )
      )}
    </div>
  );
};

export default ExerciseFeedbackDialog;

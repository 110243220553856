// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';

interface ExperienceCountProps {
  experience: number | string | null;
  width?: number | null;
  imageSrc?: string | null;
  variant?: _TypographyVariants | null;
  textColor?: string;
}

const ExperienceCount: React.FC<ExperienceCountProps> = ({
  textColor,
  variant = 'body2',
  experience,
  width = 2,
  imageSrc,
}) => {
  return (
    <div className="flex flex-row items-center gap-0">
      <img src={imageSrc ?? getStatisticImage('experiencelogo')} className={`w-${width}`} />
      <Typography className={textColor} variant={variant}>
        {experience}
      </Typography>
    </div>
  );
};

export default ExperienceCount;
